@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Arvo:400,700");
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css");
/************* Modal Mixins *************/
/************* Colors ***************/
/************* Overrides ***************/
@import url(../css/animate.css);
div.evaluation_page .btn {
  border-radius: 14px;
  color: #fff;
  border-radius: 40px;
  padding: 0.2rem 1.5rem;
  font-size: 0.8rem;
  border: none; }
  div.evaluation_page .btn:hover {
    color: #fff; }
  div.evaluation_page .btn.btn-green, div.evaluation_page .btn.nav-link.active, div.evaluation_page .nav-exercises .btn.active-exercise, .nav-exercises div.evaluation_page .btn.active-exercise, div.evaluation_page .nav-exercises .btn:hover, .nav-exercises div.evaluation_page .btn:hover {
    background-image: linear-gradient(45deg, #3eb6ad, #72dcd6); }
  div.evaluation_page .btn.btn-gray {
    background-color: #b1bfcd; }
  div.evaluation_page .btn.btn-yellow, div.evaluation_page .container-cms .item-list .item-block .btn.add_item, .container-cms .item-list .item-block div.evaluation_page .btn.add_item, div.evaluation_page .item-list.list_books .item-block .btn.add_item, .item-list.list_books .item-block div.evaluation_page .btn.add_item {
    background-image: linear-gradient(258deg, #fdd271, #d5a433);
    box-shadow: 0 5px 24px 0 rgba(239, 194, 91, 0.5); }
  div.evaluation_page .btn.btn-orange {
    background-image: linear-gradient(252deg, #ff867f, #ff5f04);
    box-shadow: 0 0px 24px 0 rgba(255, 134, 125, 0.43); }

body {
  font-family: 'Montserrat', sans-serif; }

.cursor-pointer {
  cursor: pointer; }

.input-group {
  align-items: center; }

.add-input-feild {
  width: 100%;
  border-radius: 25px;
  border: none; }
  .add-input-feild:focus {
    box-shadow: none;
    border: none; }

.bar_select {
  margin-right: 5px; }
  .bar_select label.input-group-text {
    border: none;
    background-color: #fff;
    font-size: 0.8rem;
    color: #c3c3c3;
    padding-right: 0;
    padding-left: 0; }
  .bar_select .select2-container--default {
    padding-left: 1px;
    min-width: 80px;
    max-width: 120px;
    width: max-content; }
  .bar_select .assessment_select + .select2-container--default {
    min-width: 174px; }
  .bar_select select {
    border: none;
    font-size: 0.8rem;
    font-weight: 500;
    box-shadow: none; }

.bar_button .note_validation {
  color: white;
  box-shadow: rgba(90, 202, 195, 0.5) 0px 10px 20px 0px;
  background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
  border: 0px solid #51c4bc;
  padding: 5px 10px;
  display: block;
  min-width: 90px;
  font-size: 10px;
  border: 1px solid #d1dbe2;
  text-align: center;
  border-radius: 15px;
  margin: 0px 4px;
  line-height: 14px;
  font-weight: bold; }

.select2-container--default .select2-selection--single {
  border: none;
  font-size: 1rem;
  box-shadow: none !important;
  outline: none !important; }

.select2-dropdown--below {
  border: none;
  box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5); }

.custom-select-student .select2-container--focus {
  box-shadow: none;
  border: none; }

.custom-select-student .select2-selection--single {
  font-size: 0.8rem !important;
  padding: 0 !important; }

.custom-select-student .select2-results__option {
  font-size: .9rem; }

.custom-select-student .select2-container {
  width: 151px !important; }

.select2-selection__rendered {
  color: #444;
  line-height: 28px;
  font-size: 0.9rem;
  padding: 0;
  font-weight: 500; }
  .select2-selection__rendered:focus {
    box-shadow: none;
    border: none; }

.test.select2-dropdown {
  width: 55px !important; }

.test.select2-selection--single .select2-selection__rendered {
  font-size: 0.9rem !important;
  font-weight: 500;
  padding-left: 0;
  padding-right: 0; }
  .test.select2-selection--single .select2-selection__rendered:focus {
    outline: none; }

.test.select2-selection--single .select2-selection__arrow b {
  margin-left: 0; }

.test .select2-selection--single {
  font-size: .8rem !important;
  text-align: center; }

.test .select2-container--default .select2-selection--single {
  font-size: .8rem !important; }

.test .select2-results__option {
  text-align: center;
  font-size: .8rem !important;
  padding: 14px 13px !important;
  font-weight: 500; }

.test .select2-selection__arrow b {
  border-color: #c3c3c3 transparent transparent transparent !important;
  border-style: solid;
  border-width: 7px 7px 0 7px !important; }

.test2 .select2-selection__rendered {
  font-size: 0.9rem !important;
  font-weight: 500; }
  .test2 .select2-selection__rendered:focus {
    outline: none; }

.test2 .select2-container:focus {
  outline: none; }

.test2 .select2-selection--single {
  font-size: .8rem !important;
  text-align: center; }

.test2 .select2-container--default .select2-selection--single {
  font-size: .8rem !important; }

.test2 .select2-results__option {
  text-align: center;
  font-size: 0.9rem !important;
  padding: 10px;
  text-align: center; }

.test2 .select2-selection__arrow b {
  border-color: #000000 transparent transparent transparent !important;
  border-style: solid;
  border-width: 5px 5px 0 5px !important; }

@media only screen and (min-width: 1024px) {
  .test.select2-dropdown {
    left: -12px; }
  .evaluation_page .evaluation_container .e_single_table table .e_dropdown .dropdown-menu {
    left: -13px !important; } }

.select2-container,
.selection,
.select2-selection {
  outline: none !important; }

.evaluation_page .evaluation_container .e_single_table table .e_dropdown .dropdown-menu {
  min-width: 3.5rem !important;
  box-shadow: 0 0 20px rgba(51, 51, 51, 0.18);
  border: none; }

.evaluation_page .evaluation_container .e_single_table table .e_dropdown .dropdown-menu .dropdown-item {
  justify-content: center; }

/******** Common Styles **********/
.modal-backdrop {
  background: transparent; }

.mar-top-40 {
  margin-top: 40px; }

.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background-image: linear-gradient(181deg, #72dcd6, #3eb6ad) !important; }

.os-theme-dark > .os-scrollbar, .os-theme-light > .os-scrollbar {
  padding: 4px !important; }
  .os-theme-dark > .os-scrollbar.os-scrollbar-horizontal, .os-theme-light > .os-scrollbar.os-scrollbar-horizontal {
    display: none; }

.modal.mobile_table_popup .appreciation td > span.select2 {
  width: 100% !important;
  max-width: 100% !important; }

.modal.mobile_table_popup .modal-dialog {
  height: 100%;
  display: flex;
  align-items: center; }

.modal.mobile_table_popup .modal-content {
  box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
  border-radius: 20px;
  background-color: #ffffff;
  border: 0; }
  .modal.mobile_table_popup .modal-content .modal-header {
    padding-right: 50px;
    border: 0;
    padding-top: 25px;
    padding-bottom: 50px;
    position: relative; }
    .modal.mobile_table_popup .modal-content .modal-header button {
      background-image: linear-gradient(45deg, #3eb6ad, #72dcd6);
      box-shadow: 0 5px 20px 0 rgba(90, 202, 195, 0.5);
      border-radius: 50%;
      opacity: 1;
      color: #fff;
      font-size: 18px;
      font-weight: normal;
      height: 45px;
      width: 45px;
      padding: 0;
      line-height: 40px;
      outline: none;
      cursor: pointer;
      position: absolute;
      left: -1px;
      border: 0;
      top: -1px; }
      .modal.mobile_table_popup .modal-content .modal-header button.btn-right {
        left: auto;
        right: 0; }
    .modal.mobile_table_popup .modal-content .modal-header .modal-title {
      font-size: 14px;
      color: #1b2437;
      font-weight: 600;
      width: 100%;
      text-align: left; }
  .modal.mobile_table_popup .modal-content .modal-body {
    padding: 0px 35px 30px; }
    .modal.mobile_table_popup .modal-content .modal-body .circ {
      height: 22px;
      width: 22px;
      border-radius: 100%;
      margin: 0 auto;
      display: inline-block; }
      .modal.mobile_table_popup .modal-content .modal-body .circ.green {
        background-image: linear-gradient(223deg, #72dcd6, #3eb6ad); }
      .modal.mobile_table_popup .modal-content .modal-body .circ.yellow {
        background-image: linear-gradient(220deg, #fdd271, #d5a433); }
      .modal.mobile_table_popup .modal-content .modal-body .circ.orange {
        background-image: linear-gradient(41deg, #ff5f04, #ff867f); }
      .modal.mobile_table_popup .modal-content .modal-body .circ.gray {
        background-image: linear-gradient(41deg, #90a1b1, #b1bfcd); }
      .modal.mobile_table_popup .modal-content .modal-body .circ.white {
        background-image: linear-gradient(41deg, #fff, #fff); }
    .modal.mobile_table_popup .modal-content .modal-body .star {
      background-image: url("../img/evaluation/star_icon.svg");
      height: 22px;
      width: 22px;
      display: block;
      margin: 0 auto;
      background-size: contain;
      background-position: center; }
      .modal.mobile_table_popup .modal-content .modal-body .star.white {
        background-image: url("../img/evaluation/star_white_icon.svg"); }
    .modal.mobile_table_popup .modal-content .modal-body .e_single_table {
      background: rgba(245, 248, 249, 0.3);
      padding: 0px 10px 10px; }
      .modal.mobile_table_popup .modal-content .modal-body .e_single_table > label {
        font-size: 14px;
        color: #5a5a5a;
        font-weight: 600; }
      .modal.mobile_table_popup .modal-content .modal-body .e_single_table .e_single_table_wrap {
        border-radius: 0px; }
        .modal.mobile_table_popup .modal-content .modal-body .e_single_table .e_single_table_wrap table {
          border: 1px solid #d1dbe2; }
        @media (max-width: 768px) {
          .modal.mobile_table_popup .modal-content .modal-body .e_single_table .e_single_table_wrap .classe_average_tr td:first-of-type {
            width: 85% !important; } }
        @media (min-width: 768) and (max-width: 1199px) {
          .modal.mobile_table_popup .modal-content .modal-body .e_single_table .e_single_table_wrap .classe_average_tr td:first-of-type {
            width: 90% !important; } }
      .modal.mobile_table_popup .modal-content .modal-body .e_single_table table {
        border-radius: 8px;
        border: 1px solid #d1dbe2;
        font-size: 14px;
        color: rgba(27, 36, 55, 0.5);
        font-weight: 500;
        table-layout: fixed;
        border-collapse: collapse;
        width: 100%;
        border-radius: 8px; }
        .modal.mobile_table_popup .modal-content .modal-body .e_single_table table input {
          font-weight: bold;
          width: 100%;
          border: 0;
          text-align: center; }
          .modal.mobile_table_popup .modal-content .modal-body .e_single_table table input::-webkit-inner-spin-button {
            -webkit-appearance: none; }
        .modal.mobile_table_popup .modal-content .modal-body .e_single_table table tr:last-of-type td, .modal.mobile_table_popup .modal-content .modal-body .e_single_table table tr:last-of-type th {
          border-bottom: 0; }
        .modal.mobile_table_popup .modal-content .modal-body .e_single_table table tr td {
          border-right: 1px solid #d1dbe2;
          border-bottom: 1px solid #d1dbe2;
          padding: 1px 4px;
          background: #fff;
          color: #adadad;
          background-clip: padding-box;
          font-size: 0.8rem;
          height: 47px; }
          .modal.mobile_table_popup .modal-content .modal-body .e_single_table table tr td:not(:first-child):not(:last-child) {
            color: #333;
            font-weight: bold; }
          .modal.mobile_table_popup .modal-content .modal-body .e_single_table table tr td.grey_grad {
            background: #b1bfcd; }
          .modal.mobile_table_popup .modal-content .modal-body .e_single_table table tr td.yellow_grad {
            background-image: linear-gradient(258deg, #fdd271, #d5a433);
            color: #fff !important;
            text-align: center; }
          .modal.mobile_table_popup .modal-content .modal-body .e_single_table table tr td.orange_grad {
            background-image: linear-gradient(252deg, #ff867f, #ff5f04); }
          .modal.mobile_table_popup .modal-content .modal-body .e_single_table table tr td .btn-secondary {
            border: 0 !important; }
          .modal.mobile_table_popup .modal-content .modal-body .e_single_table table tr td.info_message {
            width: 100% !important;
            background: transparent !important;
            text-align: left !important;
            color: #b0bfcd !important; }
        .modal.mobile_table_popup .modal-content .modal-body .e_single_table table select {
          border: 0;
          background-color: transparent;
          padding: 0px 12px 0 0px;
          outline: none;
          background-position: right center;
          box-shadow: none !important;
          font-weight: 700;
          font-size: 17px;
          text-align: center; }
        .modal.mobile_table_popup .modal-content .modal-body .e_single_table table .e_dropdown {
          text-align: center;
          position: relative; }
          .modal.mobile_table_popup .modal-content .modal-body .e_single_table table .e_dropdown span {
            display: inline-block;
            vertical-align: middle;
            width: 22px;
            height: 22px; }
          .modal.mobile_table_popup .modal-content .modal-body .e_single_table table .e_dropdown .down_arrow {
            position: absolute;
            z-index: 90;
            right: -10px;
            top: 40%;
            width: 10px;
            height: 5px; }
          .modal.mobile_table_popup .modal-content .modal-body .e_single_table table .e_dropdown .dropdown-toggle {
            padding: 0;
            background-color: transparent; }
            .modal.mobile_table_popup .modal-content .modal-body .e_single_table table .e_dropdown .dropdown-toggle::after {
              content: unset; }
            .modal.mobile_table_popup .modal-content .modal-body .e_single_table table .e_dropdown .dropdown-toggle:focus {
              box-shadow: none; }
          .modal.mobile_table_popup .modal-content .modal-body .e_single_table table .e_dropdown .dropdown-menu {
            min-width: 2.5rem; }
            .modal.mobile_table_popup .modal-content .modal-body .e_single_table table .e_dropdown .dropdown-menu .dropdown-item {
              padding: .25rem 0;
              display: flex; }
      .modal.mobile_table_popup .modal-content .modal-body .e_single_table .table-bottom-btn a {
        color: #fff;
        background: #b1bfcd;
        padding: 7px 10px;
        display: inline-block;
        border-radius: 49px;
        font-size: 14px;
        max-width: 165px;
        margin: 12px 0 5px;
        width: 100%;
        font-weight: 600; }
        .modal.mobile_table_popup .modal-content .modal-body .e_single_table .table-bottom-btn a span {
          margin-right: 15px; }
    .modal.mobile_table_popup .modal-content .modal-body .action_evaluation_mobile {
      text-align: center;
      margin-top: 10px; }
      .modal.mobile_table_popup .modal-content .modal-body .action_evaluation_mobile button {
        background-image: linear-gradient(45deg, #3eb6ad, #72dcd6);
        box-shadow: 0 5px 20px 0 rgba(90, 202, 195, 0.5);
        color: white;
        border: 0;
        padding: 5px 15px;
        border-radius: 15px; }
    .modal.mobile_table_popup .modal-content .modal-body form label {
      color: #1b2437;
      font-size: 14px;
      margin-bottom: 7px;
      display: block;
      font-weight: 500; }
    .modal.mobile_table_popup .modal-content .modal-body form input[type='text'], .modal.mobile_table_popup .modal-content .modal-body form select {
      width: 100%;
      border-radius: 2.6px;
      background-color: rgba(245, 248, 249, 0.3);
      border: 0.6px solid #d1dbe2;
      font-size: 13px;
      height: 28px;
      padding: 2px 6px;
      outline: none !important;
      font-weight: 500; }
    .modal.mobile_table_popup .modal-content .modal-body form select {
      background-position: 98% center;
      background-repeat: no-repeat;
      -moz-appearance: none;
      -webkit-appearance: none; }
      .modal.mobile_table_popup .modal-content .modal-body form select::-ms-expand {
        display: none; }
    .modal.mobile_table_popup .modal-content .modal-body form .create_group_select_group {
      border: 1px solid rgba(151, 151, 151, 0.2);
      border-radius: 4px;
      background-color: rgba(245, 248, 249, 0.3);
      padding: 22px 15px 20px;
      display: flex;
      justify-content: space-between; }
      .modal.mobile_table_popup .modal-content .modal-body form .create_group_select_group .single-block {
        border-radius: 8px;
        box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        position: relative;
        flex-basis: 24%;
        border-bottom: 5px solid transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
        .modal.mobile_table_popup .modal-content .modal-body form .create_group_select_group .single-block input[type='checkbox'] {
          width: 100%;
          height: 100%;
          background: transparent;
          border: 0 !important;
          position: absolute;
          left: 0;
          top: 0;
          visibility: hidden; }
        .modal.mobile_table_popup .modal-content .modal-body form .create_group_select_group .single-block .checkbox-middle-block {
          display: flex;
          border-bottom: 1px solid rgba(151, 151, 151, 0.2);
          align-items: center;
          padding: 28px 12px 24px; }
          .modal.mobile_table_popup .modal-content .modal-body form .create_group_select_group .single-block .checkbox-middle-block img {
            border-radius: 50%;
            height: 30px;
            width: 31%;
            margin-right: -8px; }
          .modal.mobile_table_popup .modal-content .modal-body form .create_group_select_group .single-block .checkbox-middle-block a {
            background: #f5f8f9;
            text-align: center;
            line-height: 28px;
            border-radius: 50%;
            color: #1b2437;
            font-size: 12px;
            height: 30px;
            width: 31%;
            border: 1px solid #d1dbe2; }
        .modal.mobile_table_popup .modal-content .modal-body form .create_group_select_group .single-block .checkbox-footer {
          padding: 11px 15px; }
          .modal.mobile_table_popup .modal-content .modal-body form .create_group_select_group .single-block .checkbox-footer h4 {
            font-size: 14px;
            color: #1b2437;
            font-weight: 600;
            margin-bottom: 10px; }
          .modal.mobile_table_popup .modal-content .modal-body form .create_group_select_group .single-block .checkbox-footer h5 {
            margin: 0;
            font-size: 12px;
            color: #1b2437; }
        .modal.mobile_table_popup .modal-content .modal-body form .create_group_select_group .single-block.selected {
          border-bottom: 5px solid #ec735b; }
          .modal.mobile_table_popup .modal-content .modal-body form .create_group_select_group .single-block.selected:before {
            content: "\f00c";
            position: absolute;
            font-family: "Font Awesome 5 Free";
            left: 0;
            right: 0;
            margin: auto;
            height: 32px;
            font-size: 14px;
            width: 32px;
            top: -16px;
            font-weight: 600;
            text-align: center;
            background-image: linear-gradient(45deg, #72dcd6, #3eb6ad);
            color: #fff;
            border-radius: 50%;
            line-height: 32px;
            box-shadow: 0 4px 20px 0 rgba(90, 202, 195, 0.5); }
        .modal.mobile_table_popup .modal-content .modal-body form .create_group_select_group .single-block.btn-block button {
          margin: 0;
          min-width: 92px;
          background: #fda800;
          box-shadow: none;
          text-align: left; }
          .modal.mobile_table_popup .modal-content .modal-body form .create_group_select_group .single-block.btn-block button span {
            margin-right: 10px; }
    .modal.mobile_table_popup .modal-content .modal-body form input[type='submit'], .modal.mobile_table_popup .modal-content .modal-body form button {
      box-shadow: 0 5px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: linear-gradient(45deg, #3eb6ad, #72dcd6);
      border-radius: 49.5px;
      color: #fff;
      min-width: 106px;
      padding: 6px 15px;
      font-size: 12px;
      font-weight: 600;
      outline: none;
      border: 1px solid transparent;
      text-transform: uppercase;
      margin: 20px auto 0;
      display: block; }
      .modal.mobile_table_popup .modal-content .modal-body form input[type='submit'].grey-border-btn, .modal.mobile_table_popup .modal-content .modal-body form button.grey-border-btn {
        border-color: #d1dbe2;
        color: #3eb6ad;
        background: rgba(245, 248, 249, 0.3);
        box-shadow: none; }
  .modal.mobile_table_popup .modal-content .modal-footer {
    justify-content: center;
    border: 0;
    padding-bottom: 35px; }
    .modal.mobile_table_popup .modal-content .modal-footer button {
      box-shadow: 0 5px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: linear-gradient(45deg, #3eb6ad, #72dcd6);
      border-radius: 49.5px;
      color: #fff;
      min-width: 106px;
      padding: 6px 15px;
      font-size: 12px;
      font-weight: 600;
      outline: none;
      border: 1px solid transparent;
      text-transform: uppercase; }
      .modal.mobile_table_popup .modal-content .modal-footer button.grey-border-btn {
        border-color: #d1dbe2;
        color: #3eb6ad;
        background: none;
        box-shadow: none; }
    .modal.mobile_table_popup .modal-content .modal-footer.steps {
      position: absolute;
      left: 18px;
      bottom: 0;
      font-weight: 500;
      font-size: 11px;
      color: #a7b7bc; }

.modal.mobile_table_popup.apprs .modal-dialog {
  height: calc(100% - 3.5rem);
  min-height: 430px;
  max-width: 600px; }
  .modal.mobile_table_popup.apprs .modal-dialog .modal-content {
    height: 100%;
    max-height: 490px; }
  .modal.mobile_table_popup.apprs .modal-dialog .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 20px; }
    .modal.mobile_table_popup.apprs .modal-dialog .modal-body td .appreciation_input {
      height: 140px !important; }

/************ Create Group Challenge **************/
#create_group_challenge .modal-dialog {
  max-width: 620px; }
  #create_group_challenge .modal-dialog .modal-body {
    padding-left: 30px;
    padding-right: 30px; }
    #create_group_challenge .modal-dialog .modal-body form .create_group_first_form {
      padding: 0 15px; }
      #create_group_challenge .modal-dialog .modal-body form .create_group_first_form + .form-group {
        margin-bottom: 30px; }
        #create_group_challenge .modal-dialog .modal-body form .create_group_first_form + .form-group label {
          padding-bottom: 10px;
          padding-left: 10px; }
    #create_group_challenge .modal-dialog .modal-body .create_group_select_group {
      padding-bottom: 60px; }

/******** Schedule view**********/
#create_class_challenge .modal-body {
  padding-bottom: 1rem; }
  #create_class_challenge .modal-body form .form-row {
    justify-content: space-between;
    margin: 0; }
  #create_class_challenge .modal-body form .creat_class_upper_select {
    margin-bottom: 40px; }
  #create_class_challenge .modal-body form .select-class-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #fcfcfd;
    padding: 13px 14px 14px;
    border: 1px solid #d1dbe2;
    border-radius: 4px;
    margin-top: 11px; }
    #create_class_challenge .modal-body form .select-class-wrap .select-class {
      flex: 0 0 31.2%; }
      #create_class_challenge .modal-body form .select-class-wrap .select-class:last-of-type label, #create_class_challenge .modal-body form .select-class-wrap .select-class:nth-last-of-type(2) label, #create_class_challenge .modal-body form .select-class-wrap .select-class:nth-last-of-type(3) label {
        margin-bottom: 0; }
      #create_class_challenge .modal-body form .select-class-wrap .select-class label {
        border: 1px solid;
        padding: 5px;
        margin: 0px 0px 12px;
        display: block;
        text-align: center;
        border-radius: 2.6px;
        font-size: 0.8rem;
        border-color: #d1dbe2;
        background: #f9f9fb; }
        #create_class_challenge .modal-body form .select-class-wrap .select-class label:hover, #create_class_challenge .modal-body form .select-class-wrap .select-class label.selected {
          cursor: pointer;
          border-color: #3eb6ad; }
      #create_class_challenge .modal-body form .select-class-wrap .select-class input[type=checkbox] {
        visibility: hidden;
        position: absolute; }
        #create_class_challenge .modal-body form .select-class-wrap .select-class input[type=checkbox]:checked + label {
          background-color: #333; }
  #create_class_challenge .modal-body form .radio-btn-class input {
    margin-right: 10px;
    vertical-align: middle;
    height: 20px;
    width: 20px; }

#create_class_challenge .modal-footer {
  padding-bottom: 20px;
  margin-top: 30px; }

/******** Create Group - Select Users ********/
.create_group_challenge_two .modal-dialog {
  max-width: 620px; }

.create_group_challenge_two form input {
  width: 40% !important; }

.create_group_challenge_two .create_group_select_users {
  display: flex;
  flex-wrap: wrap;
  height: 250px;
  overflow-y: auto;
  background: rgba(245, 248, 249, 0.3);
  border: 1px solid #d1dbe2;
  padding: 10px;
  border-radius: 4px; }
  .create_group_challenge_two .create_group_select_users .os-content {
    display: flex;
    flex-wrap: wrap; }
  .create_group_challenge_two .create_group_select_users .single_user {
    flex-basis: 16.666%;
    padding: 10px;
    text-align: center;
    cursor: pointer; }
    .create_group_challenge_two .create_group_select_users .single_user .user_image {
      border-radius: 100%;
      width: 70px;
      overflow: hidden;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3); }
      .create_group_challenge_two .create_group_select_users .single_user .user_image img {
        width: 100%; }
    .create_group_challenge_two .create_group_select_users .single_user.selected .user_image {
      overflow: hidden;
      position: relative;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.3) !important; }
      .create_group_challenge_two .create_group_select_users .single_user.selected .user_image::after {
        font-size: 2em;
        font-weight: 900;
        vertical-align: middle;
        font-family: "Font Awesome 5 Free";
        content: '\f00c';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 12px;
        color: #5acac3;
        background-color: #fff; }
      .create_group_challenge_two .create_group_select_users .single_user.selected .user_image img {
        opacity: 0;
        z-index: -1; }
    .create_group_challenge_two .create_group_select_users .single_user span {
      font-size: 0.8rem;
      line-height: 1.2;
      display: block;
      margin-top: 0.5rem;
      font-weight: 500; }

.create_group_challenge_two .modal-footer {
  font-weight: 500;
  font-size: 11px;
  color: #a7b7bc;
  position: absolute;
  left: 8px;
  bottom: 0; }

/********challenge_one**********/
#challenge_one .modal-dialog {
  max-width: 708px; }
  #challenge_one .modal-dialog .modal-body {
    margin-bottom: 40px; }
  #challenge_one .modal-dialog .btn.grey-border-btn {
    border-color: #d1dbe2;
    color: #3eb6ad;
    background: rgba(245, 248, 249, 0.3);
    box-shadow: none; }
  #challenge_one .modal-dialog .challenge_one_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left; }
    #challenge_one .modal-dialog .challenge_one_wrap .challenge_one_iteam {
      flex: 0 0 48.2%;
      margin-bottom: 12px;
      border-radius: 4px;
      box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
      background: #fff; }
      #challenge_one .modal-dialog .challenge_one_wrap .challenge_one_iteam img {
        position: absolute;
        left: 21px;
        top: 0;
        bottom: 0;
        margin: auto;
        max-width: 30px; }
      #challenge_one .modal-dialog .challenge_one_wrap .challenge_one_iteam a {
        color: #1b2437;
        text-decoration: none;
        display: block;
        font-size: 14px;
        color: #1b2437cc;
        font-weight: 500;
        padding: 21px 11px;
        padding-left: 65px;
        position: relative; }

/************** Create Group Classes with chapter selection ***************/
.chapter-common-wrap .modal-dialog {
  max-width: 620px; }
  .chapter-common-wrap .modal-dialog .form-submit-btns .btn {
    display: inline-block !important;
    margin-right: 10px; }
  .chapter-common-wrap .modal-dialog .os-scrollbar-horizontal {
    display: none; }
  .chapter-common-wrap .modal-dialog .modal-footer {
    font-weight: 500;
    font-size: 11px;
    color: #a7b7bc;
    position: absolute;
    left: 8px;
    bottom: 0; }

.chapter-common-wrap .select-classes-block {
  border-radius: 4px;
  background-color: rgba(245, 248, 249, 0.3);
  border: 1px solid #d1dbe2;
  padding: 14px 22px;
  max-width: 76%;
  margin: auto;
  max-height: 145px;
  overflow-y: scroll;
  margin-top: 20px; }

.chapter-common-wrap .challenge_one_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: left; }
  .chapter-common-wrap .challenge_one_wrap .challenge_one_iteam {
    flex: 0 0 48.2%;
    margin-bottom: 8px;
    border-radius: 4px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background: #fff; }
    .chapter-common-wrap .challenge_one_wrap .challenge_one_iteam img {
      margin-right: 15px; }
    .chapter-common-wrap .challenge_one_wrap .challenge_one_iteam a {
      text-decoration: none;
      display: block;
      padding-left: 6px;
      font-size: 14px;
      color: #1b2437cc;
      font-weight: 500;
      padding: 7px 11px;
      border: 1px solid transparent;
      border-radius: 4px; }
      .chapter-common-wrap .challenge_one_wrap .challenge_one_iteam a:hover, .chapter-common-wrap .challenge_one_wrap .challenge_one_iteam a.selected {
        border-color: #3eb6ad; }

/************** Create Single Class with chapter selection ***************/
#create_class_chapter .modal-dialog {
  max-width: 470px; }
  #create_class_chapter .modal-dialog .select_classes_mar_bot label {
    margin-bottom: 15px; }
  #create_class_chapter .modal-dialog .select-classes-block {
    max-width: 100%; }
    #create_class_chapter .modal-dialog .select-classes-block .os-content {
      padding: 12px 14px !important; }

/*************** Challenge Five Modal ****************/
.challenge_five .modal-dialog {
  max-width: 70%; }
  .challenge_five .modal-dialog .modal-body {
    padding-left: 10px; }
    .challenge_five .modal-dialog .modal-body .challenge-details-wrap {
      display: flex;
      justify-content: space-between; }
      .challenge_five .modal-dialog .modal-body .challenge-details-wrap .groups-list-col, .challenge_five .modal-dialog .modal-body .challenge-details-wrap .students-list-col {
        flex-basis: 59%;
        max-height: 470px;
        overflow-y: auto; }
      .challenge_five .modal-dialog .modal-body .challenge-details-wrap .groups-col-details {
        justify-content: space-between;
        display: flex;
        width: 100%;
        align-items: center; }
      .challenge_five .modal-dialog .modal-body .challenge-details-wrap .groups-list-col {
        padding-right: 25px;
        padding-top: 20px;
        padding-left: 30px; }
        .challenge_five .modal-dialog .modal-body .challenge-details-wrap .groups-list-col .groups-col {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 14px 12px;
          position: relative;
          background: #fff;
          margin-bottom: 20px;
          border-radius: 0px; }
          .challenge_five .modal-dialog .modal-body .challenge-details-wrap .groups-list-col .groups-col.active {
            box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1); }
            .challenge_five .modal-dialog .modal-body .challenge-details-wrap .groups-list-col .groups-col.active:after {
              content: '';
              border-left: 12px solid #ffffff;
              border-bottom: 12px solid transparent;
              border-top: 12px solid transparent;
              position: absolute;
              right: -11px; }
          .challenge_five .modal-dialog .modal-body .challenge-details-wrap .groups-list-col .groups-col .number-of-students {
            background: rgba(0, 0, 0, 0.46);
            color: #fff;
            font-size: 11px;
            padding: 2px 13px;
            border-radius: 14px;
            margin-right: 5px; }
          .challenge_five .modal-dialog .modal-body .challenge-details-wrap .groups-list-col .groups-col.active .students-list-col {
            display: block; }
      .challenge_five .modal-dialog .modal-body .challenge-details-wrap .students-list-col {
        background: rgba(245, 248, 249, 0.3);
        border: 1px solid #d1dbe2;
        border-radius: 4px;
        padding: 13px 22px 13px 15px;
        flex-basis: 40%;
        min-height: 480px;
        display: none; }
        .challenge_five .modal-dialog .modal-body .challenge-details-wrap .students-list-col .os-content-glue {
          height: 600px !important; }
        .challenge_five .modal-dialog .modal-body .challenge-details-wrap .students-list-col .single-students-list {
          margin-bottom: 20px; }
        .challenge_five .modal-dialog .modal-body .challenge-details-wrap .students-list-col .students-list-header {
          display: flex;
          justify-content: space-between;
          border-bottom: 1.5px solid #e2e4e9; }
          .challenge_five .modal-dialog .modal-body .challenge-details-wrap .students-list-col .students-list-header label {
            font-size: 14px;
            color: #1b2437;
            font-weight: 600; }
        .challenge_five .modal-dialog .modal-body .challenge-details-wrap .students-list-col .students-list-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid rgba(214, 217, 224, 0.2);
          padding: 10px; }
          .challenge_five .modal-dialog .modal-body .challenge-details-wrap .students-list-col .students-list-content label {
            font-size: 14px;
            color: #1b2437;
            font-weight: 500;
            margin-bottom: 0; }
          .challenge_five .modal-dialog .modal-body .challenge-details-wrap .students-list-col .students-list-content .student-list-left-con img {
            height: 30px;
            width: 30px;
            border-radius: 5px; }
          .challenge_five .modal-dialog .modal-body .challenge-details-wrap .students-list-col .students-list-content .student-list-left-con label {
            margin-left: 15px; }
  .challenge_five .modal-dialog .checkbox-middle-block {
    display: flex;
    align-items: center; }
    .challenge_five .modal-dialog .checkbox-middle-block label {
      font-size: 14px;
      font-weight: 600;
      color: #1b2437;
      margin-left: 14px;
      margin-bottom: 0; }
    .challenge_five .modal-dialog .checkbox-middle-block img {
      border-radius: 50%;
      height: 33px;
      width: 33px;
      margin-right: -8px;
      box-shadow: 0 2px 15px 0 rgba(46, 104, 208, 0.1); }
    .challenge_five .modal-dialog .checkbox-middle-block a {
      background: #f5f8f9;
      text-align: center;
      line-height: 33px;
      border-radius: 50%;
      color: #1b2437;
      font-size: 14px;
      height: 33px;
      width: 33px;
      border: 1px solid #d1dbe2; }

/******************* Challenge Results *****************/
#challenge_results .modal-dialog .challenge-details-wrap .groups-list-col .groups-col {
  border: 1px solid transparent; }
  #challenge_results .modal-dialog .challenge-details-wrap .groups-list-col .groups-col.active:after {
    content: "";
    border-left: 9px solid #ffffff;
    border-bottom: 9px solid transparent;
    border-top: 9px solid transparent;
    position: absolute;
    right: -9px; }

/******************* Students List *****************/
#students_list_modal .modal-dialog {
  max-width: 420px; }

#students_list_modal .modal-content .modal-header {
  padding-left: 40px;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 1rem; }
  #students_list_modal .modal-content .modal-header .modal-title {
    text-align: left;
    font-size: 16px;
    font-weight: 500px; }

#students_list_modal .modal-content .modal-body {
  max-height: 430px;
  padding: 0px 35px 30px;
  overflow: auto; }
  #students_list_modal .modal-content .modal-body .os-scrollbar-vertical {
    right: 12px; }

#students_list_modal .modal-content .form-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d6d9e0;
  margin: 0;
  padding: 11px 0; }
  #students_list_modal .modal-content .form-group .students-list-left-col {
    margin: 0;
    color: #1b2437;
    font-size: 14px;
    display: block;
    font-weight: 500;
    width: 100%; }
    #students_list_modal .modal-content .form-group .students-list-left-col img {
      width: 30px;
      height: 30px;
      border-radius: 5px;
      margin-right: 14px; }
    #students_list_modal .modal-content .form-group .students-list-left-col .pretty {
      width: 100%;
      margin-right: 0; }
      #students_list_modal .modal-content .form-group .students-list-left-col .pretty input:checked ~ .state label:after {
        background-color: #51c4bc !important; }
      #students_list_modal .modal-content .form-group .students-list-left-col .pretty .state label {
        text-indent: 0;
        font-weight: 500; }
        #students_list_modal .modal-content .form-group .students-list-left-col .pretty .state label:after, #students_list_modal .modal-content .form-group .students-list-left-col .pretty .state label:before {
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          left: auto;
          background-color: #cccccc4a;
          width: 17px;
          height: 17px; }
        #students_list_modal .modal-content .form-group .students-list-left-col .pretty .state label:after {
          transform: scale(0.7); }
  #students_list_modal .modal-content .form-group .students-list-right-col {
    position: relative; }
  #students_list_modal .modal-content .form-group.round {
    position: relative;
    padding-left: 8px; }
    #students_list_modal .modal-content .form-group.round input[type="checkbox"]:checked + label {
      background-color: #51c4bc;
      border-color: #51c4bc; }

#students_list_modal .modal-content .modal-footer {
  padding-bottom: 20px;
  box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
  background-color: rgba(255, 255, 255, 0.7); }
  #students_list_modal .modal-content .modal-footer button {
    background-image: linear-gradient(258deg, #fdd271, #d5a433);
    box-shadow: 0 5px 24px 0 rgba(239, 194, 91, 0.5); }

.pretty.p-default input:checked ~ .state label:after {
  background-color: #51c4bc !important; }

/*************************/
#enter_group_name .modal-dialog {
  max-width: 840px; }
  #enter_group_name .modal-dialog .os-content {
    padding-right: 12px !important; }
  #enter_group_name .modal-dialog .enter_group_name_last_group {
    padding-bottom: 20px;
    padding-top: 20px; }
  #enter_group_name .modal-dialog .create_group_select_users {
    height: 370px;
    border: 0;
    background: transparent;
    padding: 0; }
    #enter_group_name .modal-dialog .create_group_select_users .os-content {
      display: flex;
      flex-wrap: wrap; }
    #enter_group_name .modal-dialog .create_group_select_users .single_user {
      flex-basis: 12.5%; }
  #enter_group_name .modal-dialog .modal-footer.steps {
    left: 34px; }

#creat_group_challenge_two .modal-body {
  padding: 0 30px 30px; }
  #creat_group_challenge_two .modal-body .single_user.selected .user_image::after {
    padding-top: 9px; }
  #creat_group_challenge_two .modal-body .creat_group_first_group {
    padding: 0 12px 5px; }
  #creat_group_challenge_two .modal-body .creat_group_last_group {
    margin-top: 30px; }
    #creat_group_challenge_two .modal-body .creat_group_last_group label {
      padding: 0 12px 5px; }

#creat_group_challenge_two .single_user .user_image {
  width: 64px; }

/************** Responsive **************/
@media only screen and (max-width: 991px) {
  #enter_group_name .modal-dialog {
    max-width: 750px; }
  .modal .modal-content .modal-body form input[type='text'], .modal .modal-content .modal-body form select {
    height: 38px; }
  #create_group_challenge .modal-dialog .modal-body {
    padding-right: 25px;
    padding-left: 25px; }
    #create_group_challenge .modal-dialog .modal-body .create_group_select_group {
      padding-bottom: 30px; }
  .challenge_five .modal-dialog .modal-header {
    padding-bottom: 20px; }
  .challenge_five .modal-dialog .modal-body {
    padding: 0 0 40px; }
    .challenge_five .modal-dialog .modal-body .challenge-details-wrap {
      flex-direction: column; }
      .challenge_five .modal-dialog .modal-body .challenge-details-wrap .groups-list-col {
        padding-top: 30px; }
        .challenge_five .modal-dialog .modal-body .challenge-details-wrap .groups-list-col .groups-col {
          flex-direction: column;
          border: 1px solid #b1bfcd !important;
          border-radius: 4px !important; }
          .challenge_five .modal-dialog .modal-body .challenge-details-wrap .groups-list-col .groups-col.active:after {
            display: none; }
          .challenge_five .modal-dialog .modal-body .challenge-details-wrap .groups-list-col .groups-col .students-list-col {
            flex-basis: 100%;
            width: 100%;
            margin-top: 30px;
            min-height: auto; }
            .challenge_five .modal-dialog .modal-body .challenge-details-wrap .groups-list-col .groups-col .students-list-col .os-co ntent-glue {
              height: 390px !important; } }

@media only screen and (max-width: 767px) {
  .modal.mobile_table_popup .modal-dialog {
    max-width: 100%; }
    .modal.mobile_table_popup .modal-dialog .modal-content {
      max-height: 95%;
      overflow: auto;
      height: 300px; }
      .modal.mobile_table_popup .modal-dialog .modal-content .modal-header {
        padding-bottom: 20px; }
      .modal.mobile_table_popup .modal-dialog .modal-content .modal-body {
        overflow: auto;
        padding-top: 50px !important; }
        .modal.mobile_table_popup .modal-dialog .modal-content .modal-body form select {
          height: 38px; }
        .modal.mobile_table_popup .modal-dialog .modal-content .modal-body form .create_group_select_group {
          flex-wrap: wrap; }
          .modal.mobile_table_popup .modal-dialog .modal-content .modal-body form .create_group_select_group .single-block {
            flex-basis: 47%;
            margin: 20px 0; }
      .modal.mobile_table_popup .modal-dialog .modal-content .modal-footer.steps {
        position: initial; }
  #create_class_challenge .modal-content .modal-header {
    padding-bottom: 30px; }
  #create_class_challenge .modal-content .modal-body {
    padding: 0px 15px 30px; }
    #create_class_challenge .modal-content .modal-body .creat_class_upper_select {
      padding: 0 20px; }
    #create_class_challenge .modal-content .modal-body .select-class-wrap .select-class label {
      padding: 3px 5px; }
  #create_class_challenge .modal-content .modal-footer {
    padding-bottom: 0; }
  #challenge_one .modal-content .modal-body {
    padding: 0 25px 20px;
    margin-bottom: 0; }
    #challenge_one .modal-content .modal-body .challenge_one_wrap .challenge_one_iteam {
      flex: 100%; }
  .challenge_five .modal-dialog .modal-body .challenge-details-wrap .groups-list-col {
    padding-right: 15px;
    padding-left: 15px;
    max-height: 100%; }
  .challenge_five .modal-dialog .modal-body .checkbox-middle-block img {
    height: 28px;
    width: 28px; }
  .challenge_five .modal-dialog .modal-body .checkbox-middle-block a {
    height: 28px;
    width: 28px;
    line-height: 28px; }
  .challenge_five .modal-dialog .modal-body .checkbox-middle-block label {
    font-size: 11px;
    margin-left: 6px; }
  .challenge_five .modal-dialog .modal-body .challenge-details-wrap .groups-list-col .groups-col .number-of-students {
    font-size: 9px;
    padding: 2px 9px;
    margin-right: 0px; }
  #create_class_chapter.modal .modal-content .modal-header {
    padding-bottom: 30px; }
  #create_class_chapter.modal .modal-content .modal-body {
    padding: 0px 30px 30px; }
    #create_class_chapter.modal .modal-content .modal-body .select_classes_mar_bot {
      margin-right: -15px;
      margin-left: -15px;
      margin-top: 30px; }
    #create_class_chapter.modal .modal-content .modal-body .select-classes-block {
      max-height: 230px; }
      #create_class_chapter.modal .modal-content .modal-body .select-classes-block .challenge_one_wrap .challenge_one_iteam {
        flex: 100%; }
        #create_class_chapter.modal .modal-content .modal-body .select-classes-block .challenge_one_wrap .challenge_one_iteam a {
          display: flex;
          align-items: center; }
  #students_list_modal .modal-content {
    overflow: hidden; }
    #students_list_modal .modal-content .modal-header {
      padding-top: 40px; }
    #students_list_modal .modal-content .modal-body {
      padding: 0px 35px 30px 20px;
      max-height: 320px; }
  #create_group_chapter .modal-content .modal-header {
    padding-bottom: 30px; }
  #create_group_chapter .modal-content .modal-body {
    padding: 0px 25px 30px; }
    #create_group_chapter .modal-content .modal-body .select-classes-block {
      max-width: 100%;
      max-height: 230px; }
      #create_group_chapter .modal-content .modal-body .select-classes-block .challenge_one_wrap .challenge_one_iteam {
        flex: 0 0 100%; }
  #create_group_chapter .modal-content .modal-footer {
    padding: 10px 0 20px;
    position: initial; }
  #enter_group_name .modal-content .modal-header {
    padding-bottom: 30px; }
  #enter_group_name .modal-content .modal-body {
    padding: 0 30px 30px; }
    #enter_group_name .modal-content .modal-body input {
      width: 100% !important;
      height: 38px; }
    #enter_group_name .modal-content .modal-body .create_group_select_users .single_user.selected .user_image {
      box-shadow: 0 2px 13px 0 rgba(0, 0, 0, 0.2) !important; }
    #enter_group_name .modal-content .modal-body .create_group_select_users .single_user .user_image {
      box-shadow: 0 2px 20px 0 rgba(46, 104, 208, 0.1);
      width: 70px; }
  #creat_group_challenge_two .modal-body {
    padding: 0 25px 30px; }
    #creat_group_challenge_two .modal-body .single_user {
      flex: 0 0 33%; }
      #creat_group_challenge_two .modal-body .single_user.selected .user_image::after {
        padding-top: 0px;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center; }
      #creat_group_challenge_two .modal-body .single_user .user_image {
        width: 100%; }
  .create_group_challenge_two .create_group_select_users .os-content {
    justify-content: space-between; } }

/************* Main *************/
div.evaluation_page {
  margin: 25px 30px;
  /*width: calc(100% - 95px);
    float: right;
    padding: 1.6rem;*/ }
  div.evaluation_page .top_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 5px; }
    @media (max-width: 767px) {
      div.evaluation_page .top_bar {
        flex-wrap: wrap; } }
    div.evaluation_page .top_bar .bar_left {
      display: flex;
      align-items: center; }
      div.evaluation_page .top_bar .bar_left .bar_img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background-color: #5acac3;
        padding: 0.3rem 0.4rem;
        border-radius: 5px;
        box-shadow: 0 0 20px 0 rgba(90, 202, 195, 0.5); }
        div.evaluation_page .top_bar .bar_left .bar_img img {
          width: 95%; }
      div.evaluation_page .top_bar .bar_left .nav-link {
        padding: 0;
        position: relative;
        padding-right: 20px; }
        div.evaluation_page .top_bar .bar_left .nav-link:after {
          content: '';
          position: absolute;
          width: 2px;
          top: 0;
          right: 0;
          height: 100%;
          background: #f5f6fa; }
      div.evaluation_page .top_bar .bar_left .bar_title {
        font-size: 1rem;
        margin: 0;
        margin-left: 1rem; }
    div.evaluation_page .top_bar .bar_right {
      display: flex;
      align-items: center; }
      @media (max-width: 767px) {
        div.evaluation_page .top_bar .bar_right {
          margin-top: 10px;
          flex-basis: 100% !important;
          display: flex;
          background: inherit !important;
          flex-wrap: wrap; } }
      div.evaluation_page .top_bar .bar_right .bar_search .input-group {
        border-radius: 30px;
        overflow: hidden;
        border: 1px solid #dfe3e8; }
        div.evaluation_page .top_bar .bar_right .bar_search .input-group input {
          height: 1.6rem;
          border: none;
          border-radius: 0;
          background-color: #f8f8f8;
          font-size: 0.9rem; }
          div.evaluation_page .top_bar .bar_right .bar_search .input-group input:focus {
            box-shadow: none; }
          div.evaluation_page .top_bar .bar_right .bar_search .input-group input::placeholder {
            color: #b1bfcd;
            font-size: 0.7rem;
            position: relative;
            bottom: 0.1rem; }
        div.evaluation_page .top_bar .bar_right .bar_search .input-group button {
          border-radius: 0;
          border: none;
          box-shadow: none;
          background-color: #f8f8f8;
          padding: 0px 10px; }
      div.evaluation_page .top_bar .bar_right .bar_ham {
        margin: 0 1.2rem; }
      div.evaluation_page .top_bar .bar_right .bar_filter {
        font-size: 0.9rem; }
        div.evaluation_page .top_bar .bar_right .bar_filter .bar_select {
          margin-right: 0.5rem; }
        div.evaluation_page .top_bar .bar_right .bar_filter .input-group-text {
          padding-left: 0; }
        div.evaluation_page .top_bar .bar_right .bar_filter .custom-select {
          padding-left: 0.2rem;
          cursor: pointer;
          font-size: .9rem;
          font-weight: 500;
          background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='255px' height='255px' fill='%23495057' viewBox='0 0 255 255' style='enable-background:new 0 0 255 255;' xml:space='preserve'%3E%3Cg%3E%3Cg id='arrow-drop-down'%3E%3Cpolygon points='0,63.75 127.5,191.25 255,63.75'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A"); }
      @media (max-width: 767px) {
        div.evaluation_page .top_bar .bar_right .bar_select {
          max-width: inherit !important; } }
      @media (max-width: 767px) {
        div.evaluation_page .top_bar .bar_right .bar_select .input-group {
          margin-bottom: 10px; } }
      @media (max-width: 767px) {
        div.evaluation_page .top_bar .bar_right .bar_select .input-group label {
          background-color: inherit;
          padding-left: 0; } }
  div.evaluation_page .bar_search_mobile,
  div.evaluation_page .challenge_mobile_filter {
    display: none; }
  div.evaluation_page .footer_btns {
    margin-top: 2rem;
    text-align: center; }
    div.evaluation_page .footer_btns .btn-yellow, div.evaluation_page .footer_btns .container-cms .item-list .item-block .add_item, .container-cms .item-list .item-block div.evaluation_page .footer_btns .add_item, div.evaluation_page .footer_btns .item-list.list_books .item-block .add_item, .item-list.list_books .item-block div.evaluation_page .footer_btns .add_item {
      margin-right: 2rem;
      padding: 0.7rem 2rem;
      font-size: 0.7rem; }
      div.evaluation_page .footer_btns .btn-yellow span, div.evaluation_page .footer_btns .container-cms .item-list .item-block .add_item span, .container-cms .item-list .item-block div.evaluation_page .footer_btns .add_item span, div.evaluation_page .footer_btns .item-list.list_books .item-block .add_item span, .item-list.list_books .item-block div.evaluation_page .footer_btns .add_item span {
        margin-left: 0.5rem; }

div.annual_reviews_page {
  margin: 25px 30px;
  /*width: calc(100% - 95px);
    float: right;
    padding: 1.6rem;*/ }
  div.annual_reviews_page .top_bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #fff;
    border-radius: 5px; }
    @media (max-width: 767px) {
      div.annual_reviews_page .top_bar {
        flex-wrap: wrap; } }
    div.annual_reviews_page .top_bar .bar_right {
      display: flex;
      align-items: center; }
      @media (max-width: 767px) {
        div.annual_reviews_page .top_bar .bar_right {
          margin-top: 10px;
          flex-basis: 100% !important;
          display: flex;
          background: inherit !important;
          flex-wrap: wrap; } }
      @media (max-width: 767px) {
        div.annual_reviews_page .top_bar .bar_right .bar_select {
          max-width: inherit !important; } }
      @media (max-width: 767px) {
        div.annual_reviews_page .top_bar .bar_right .bar_select .input-group {
          margin-bottom: 10px; } }
      @media (max-width: 767px) {
        div.annual_reviews_page .top_bar .bar_right .bar_select .input-group label {
          background-color: inherit;
          padding-left: 0; } }
  div.annual_reviews_page .bar_search_mobile {
    display: none; }
  div.annual_reviews_page .footer_btns {
    margin-top: 2rem;
    text-align: center; }
    div.annual_reviews_page .footer_btns .btn-yellow, div.annual_reviews_page .footer_btns .container-cms .item-list .item-block .add_item, .container-cms .item-list .item-block div.annual_reviews_page .footer_btns .add_item, div.annual_reviews_page .footer_btns .item-list.list_books .item-block .add_item, .item-list.list_books .item-block div.annual_reviews_page .footer_btns .add_item {
      margin-right: 2rem;
      padding: 0.7rem 2rem;
      font-size: 0.7rem; }
      div.annual_reviews_page .footer_btns .btn-yellow span, div.annual_reviews_page .footer_btns .container-cms .item-list .item-block .add_item span, .container-cms .item-list .item-block div.annual_reviews_page .footer_btns .add_item span, div.annual_reviews_page .footer_btns .item-list.list_books .item-block .add_item span, .item-list.list_books .item-block div.annual_reviews_page .footer_btns .add_item span {
        margin-left: 0.5rem; }

/************* Floating Sidebar *************/
.float_side {
  position: fixed;
  right: 0;
  height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .float_side .f_side_item {
    width: 100%;
    box-shadow: 0 0 20px 0 rgba(90, 202, 195, 0.5);
    background-image: linear-gradient(45deg, #3eb6ad, #72dcd6);
    margin-bottom: 0.3rem;
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0.3rem;
    color: #fff;
    align-items: center;
    border-radius: 5px 0 0 5px; }
    .float_side .f_side_item img {
      width: 40%;
      margin-bottom: 0.5rem; }
    .float_side .f_side_item span {
      font-size: 0.6rem; }

@media only screen and (max-width: 1024px) {
  div.main.challenges-page .challenge_grid .challenge {
    flex-basis: 23%; } }

@media only screen and (max-width: 991px) {
  .select2-container--default {
    max-width: 70px; }
    .select2-container--default .select2-selection--single {
      background: transparent; }
      .select2-container--default .select2-selection--single .select2-selection__rendered {
        font-size: 0.8rem !important; }
  div.main.challenges-page .top_bar .bar_left {
    display: flex;
    align-items: center;
    flex-basis: 44%;
    justify-content: space-between;
    padding-right: 5px; }
    div.main.challenges-page .top_bar .bar_left > div {
      display: flex;
      align-items: center; }
      div.main.challenges-page .top_bar .bar_left > div.download_upload_icons a {
        margin-right: 10px; }
  div.main.challenges-page .top_bar .bar_right .bar_filter .bar_select {
    margin-right: 0; }
    div.main.challenges-page .top_bar .bar_right .bar_filter .bar_select label {
      font-size: 0.8rem; }
  div.main.challenges-page .challenge_grid .challenge {
    padding-top: 0; }
    div.main.challenges-page .challenge_grid .challenge:nth-of-type(4) {
      margin-right: 0; }
    div.main.challenges-page .challenge_grid .challenge .c_title {
      margin-bottom: 3px; }
    div.main.challenges-page .challenge_grid .challenge .grades {
      font-size: 0.7rem;
      margin-bottom: 0.5rem; }
    div.main.challenges-page .challenge_grid .challenge .c_footer .c_icons img {
      max-width: 15px;
      width: auto; }
    div.main.challenges-page .challenge_grid .challenge .c_footer .c_btn button {
      font-size: 9px;
      padding: 2px 15px 3px; }
    div.main.challenges-page .challenge_grid .challenge .all_btn {
      margin: 0.5rem 0; }
      div.main.challenges-page .challenge_grid .challenge .all_btn .btn {
        padding: 2px 11px;
        font-size: 10px; }
  #create_class_group_modal {
    background: rgba(177, 191, 205, 0.84); }
    #create_class_group_modal .modal-dialog {
      max-width: 280px;
      margin: auto; }
      #create_class_group_modal .modal-dialog .modal-content .modal-body {
        padding: 20px;
        text-align: center; }
        #create_class_group_modal .modal-dialog .modal-content .modal-body .footer_btns a {
          margin: 10px 0;
          padding: 13px 23px; }
          #create_class_group_modal .modal-dialog .modal-content .modal-body .footer_btns a * {
            color: #fff; }
          #create_class_group_modal .modal-dialog .modal-content .modal-body .footer_btns a i {
            margin-right: 10px;
            font-size: 17px; } }

@media only screen and (max-width: 767px) {
  div.main.challenges-page {
    padding-top: 0; }
    div.main.challenges-page .top_bar {
      background: transparent; }
      div.main.challenges-page .top_bar .bar_right .bar_search .input-group {
        border: 0; }
        div.main.challenges-page .top_bar .bar_right .bar_search .input-group button {
          padding: 0;
          padding-left: 4px;
          font-size: larger; }
      div.main.challenges-page .top_bar .bar_right .bar_select label.input-group-text {
        background: transparent; }
      div.main.challenges-page .top_bar .bar_right .challenge_mobile_filter {
        position: absolute;
        width: 120px;
        right: 0;
        font-size: 12px;
        background: #fff;
        z-index: 11;
        border-radius: 4px;
        top: 105%;
        box-shadow: 0 0 10px #bbb;
        padding: 10px 0; }
        div.main.challenges-page .top_bar .bar_right .challenge_mobile_filter.show_mobile_filter {
          display: block; }
        div.main.challenges-page .top_bar .bar_right .challenge_mobile_filter ul {
          padding: 0;
          margin: 0; }
          div.main.challenges-page .top_bar .bar_right .challenge_mobile_filter ul li {
            display: block; }
            div.main.challenges-page .top_bar .bar_right .challenge_mobile_filter ul li a {
              color: #333;
              font-weight: 500;
              padding: 7px 17px;
              display: block; }
    div.main.challenges-page .bar_search_mobile.show_mobile_search {
      display: block; }
    div.main.challenges-page .bar_search_mobile .input-group {
      border-radius: 30px;
      overflow: hidden;
      border: 1px solid #dfe3e8;
      background: #f8f8f8;
      padding: 0;
      max-width: 90%;
      margin: 20px auto; }
      div.main.challenges-page .bar_search_mobile .input-group input {
        font-size: 12px;
        height: 28px;
        padding: 0 16px;
        background: transparent;
        border: 0; }
    div.main.challenges-page .bar_search_mobile button {
      padding: 0;
      width: 40px;
      background: transparent; }
      div.main.challenges-page .bar_search_mobile button img {
        max-width: 14px; }
    div.main.challenges-page .challenge_grid {
      justify-content: space-between; }
      div.main.challenges-page .challenge_grid .challenge {
        flex-basis: 48%;
        margin-right: 0; }
        div.main.challenges-page .challenge_grid .challenge img.medal {
          height: auto;
          margin: 30px 0 20px;
          max-height: 75px; } }

.evaluation_page .top_bar .bar_left > div {
  align-items: center; }

.evaluation_page .bar_left .nav {
  width: 100%; }
  .evaluation_page .bar_left .nav .nav-item {
    margin-right: 5px;
    margin-top: 2.5px;
    margin-bottom: 2.5px; }
    .evaluation_page .bar_left .nav .nav-item a {
      padding-right: 5px; }
      .evaluation_page .bar_left .nav .nav-item a > div {
        align-items: center; }
        .evaluation_page .bar_left .nav .nav-item a > div .bar_title {
          margin: 0;
          float: left;
          font-family: Montserrat;
          font-size: 13px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          -webkit-letter-spacing: normal;
          -moz-letter-spacing: normal;
          -ms-letter-spacing: normal;
          letter-spacing: normal;
          color: #1b2437;
          margin: 0 0 0 5px;
          text-decoration: none;
          background-color: transparent; }
      .evaluation_page .bar_left .nav .nav-item a img {
        background: #b1bfcd;
        padding: 6px 3px;
        width: 28px;
        height: 35px;
        float: left;
        border-radius: 4px;
        vertical-align: middle;
        text-align: center;
        line-height: 35px; }
      .evaluation_page .bar_left .nav .nav-item a.active img {
        background-color: #5acac3;
        box-shadow: 0 0 20px 0 rgba(90, 202, 195, 0.5); }
      .evaluation_page .bar_left .nav .nav-item a.active .bar_title {
        color: #80c9ca; }

.evaluation_page .evaluation_container {
  background-color: #fff;
  margin-top: 1rem;
  padding: 1rem 1.9rem 20px;
  border-radius: 5px; }
  @media only screen and (max-width: 1199px) {
    .evaluation_page .evaluation_container.parent_view {
      padding-bottom: 60px !important; } }
  .evaluation_page .evaluation_container .e_top_bar {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    flex-wrap: wrap; }
    .evaluation_page .evaluation_container .e_top_bar .choose_view {
      width: 100%;
      margin-bottom: 10px; }
      .evaluation_page .evaluation_container .e_top_bar .choose_view > div {
        width: 100%;
        display: flex;
        flex-direction: inherit;
        justify-content: center; }
        .evaluation_page .evaluation_container .e_top_bar .choose_view > div .legend {
          width: auto;
          line-height: 30px; }
        .evaluation_page .evaluation_container .e_top_bar .choose_view > div .choices {
          margin-left: 15px;
          flex-direction: row;
          display: flex;
          flex-wrap: wrap;
          border: 2px solid #d7d5d5;
          border-radius: 9px;
          width: 250px; }
          .evaluation_page .evaluation_container .e_top_bar .choose_view > div .choices > span {
            padding: 5px;
            display: block;
            max-width: 124px;
            width: 50%;
            color: #51c4bc;
            background-color: white;
            text-align: center;
            cursor: pointer;
            border-radius: 6px; }
            .evaluation_page .evaluation_container .e_top_bar .choose_view > div .choices > span.active {
              color: white;
              background-color: #51c4bc; }
          .evaluation_page .evaluation_container .e_top_bar .choose_view > div .choices.control {
            width: 320px; }
            .evaluation_page .evaluation_container .e_top_bar .choose_view > div .choices.control > span {
              padding: 5px;
              max-width: 106.6px;
              width: 33.33%; }
        .evaluation_page .evaluation_container .e_top_bar .choose_view > div * {
          margin-bottom: 0; }
    .evaluation_page .evaluation_container .e_top_bar .e_top_left {
      min-width: 100px;
      display: flex;
      align-items: center; }
      .evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info {
        position: relative; }
        .evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info .student_label {
          color: black; }
        .evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info img {
          margin: 0 0.6rem;
          width: 30px;
          height: 30px;
          border-radius: 4px; }
        .evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info .student_name {
          font-weight: 500;
          border: none;
          width: 50%; }
        .evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info .student-name {
          font-size: 0.9rem;
          font-weight: 500;
          box-shadow: none;
          cursor: pointer; }
        .evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info .custom-dropdown {
          position: absolute;
          border-radius: 10px;
          background-color: #ffffff;
          margin-top: 1rem;
          display: none;
          width: max-content;
          left: 15%;
          z-index: 20; }
          .evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown {
            padding: 1rem;
            box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5);
            position: relative;
            background-color: #ffffff;
            border-radius: 10px;
            overflow: auto; }
            .evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background-color: #F5F5F5; }
            .evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown::-webkit-scrollbar {
              width: 5px;
              height: 3px;
              background-color: #F5F5F5; }
            .evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown::-webkit-scrollbar-thumb {
              background-color: #3eb6ad; }
            .evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown .form-group {
              position: relative; }
              .evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown .form-group input {
                border-radius: 25px;
                border: 1px solid #c2c2c2;
                width: 100%;
                height: 1.6rem;
                background-color: rgba(177, 191, 205, 0.1); }
                .evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown .form-group input::placeholder {
                  font-size: 10px; }
              .evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown .form-group span {
                position: absolute;
                top: 10px;
                right: 12px; }
            .evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown .student-detail {
              display: flex;
              align-items: center;
              border-bottom: 1px solid rgba(195, 195, 195, 0.3);
              padding-bottom: 10px;
              padding-top: 10px;
              cursor: pointer;
              min-width: 80px; }
              .evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown .student-detail p {
                font-size: .9rem;
                padding-left: 1rem;
                margin-bottom: 0;
                font-weight: 500; }
              .evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown .student-detail:focus {
                box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5); }
              .evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown .student-detail:hover {
                box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5);
                border-radius: 10px;
                border: 1px solid #c2c2c2; }
          .evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown:before {
            content: "";
            border-bottom: 15px solid #fff;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            position: absolute;
            top: -15px;
            left: 45%;
            box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5); }
    .evaluation_page .evaluation_container .e_top_bar .e_top_right {
      display: flex;
      margin-right: 2rem; }
      .evaluation_page .evaluation_container .e_top_bar .e_top_right .btn {
        display: flex;
        align-items: center; }
      .evaluation_page .evaluation_container .e_top_bar .e_top_right .checkbox_style .pretty .state label {
        display: block;
        font-family: Montserrat;
        font-size: 15px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1b2437;
        margin-bottom: 5px; }
        .evaluation_page .evaluation_container .e_top_bar .e_top_right .checkbox_style .pretty .state label::after, .evaluation_page .evaluation_container .e_top_bar .e_top_right .checkbox_style .pretty .state label::before {
          top: 0; }
  .evaluation_page .evaluation_container .circ {
    height: 22px;
    width: 22px;
    border-radius: 100%;
    margin: 0 auto;
    display: inline-block; }
    .evaluation_page .evaluation_container .circ.green {
      background-image: linear-gradient(223deg, #72dcd6, #3eb6ad); }
    .evaluation_page .evaluation_container .circ.yellow {
      background-image: linear-gradient(220deg, #fdd271, #d5a433); }
    .evaluation_page .evaluation_container .circ.orange {
      background-image: linear-gradient(41deg, #ff5f04, #ff867f); }
    .evaluation_page .evaluation_container .circ.gray {
      background-image: linear-gradient(41deg, #90a1b1, #b1bfcd); }
    .evaluation_page .evaluation_container .circ.white {
      background-image: linear-gradient(41deg, #fff, #fff); }
  .evaluation_page .evaluation_container .star {
    background-image: url("../img/evaluation/star_icon.svg");
    height: 22px;
    width: 22px;
    display: block;
    margin: 0 auto;
    background-size: contain;
    background-position: center; }
    .evaluation_page .evaluation_container .star.white {
      background-image: url("../img/evaluation/star_white_icon.svg"); }
  .evaluation_page .evaluation_container .e_single_table {
    background: rgba(245, 248, 249, 0.3);
    border-radius: 8px;
    border: 1px solid #d1dbe2;
    padding: 10px;
    margin-top: 30px; }
    .evaluation_page .evaluation_container .e_single_table > label {
      font-size: 14px;
      color: #5a5a5a;
      font-weight: 600; }
    .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap {
      border-radius: 0px;
      border-right-width: 0px; }
      .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap table {
        border: 1px solid #d1dbe2; }
      @media (max-width: 1199px) {
        .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .classe_average_tr td:first-of-type {
          width: 95% !important; } }
      @media (max-width: 767px) {
        .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .classe_average_tr td:first-of-type {
          width: 85% !important; } }
      .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .classe_average_tr.MC td.mc {
        font-size: 10px; }
      @media (max-width: 1199px) {
        .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .classe_average_tr.MC td:first-of-type {
          width: 95% !important; } }
      @media (max-width: 767px) {
        .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .classe_average_tr.MC td:first-of-type {
          width: 85% !important; } }
      @media (max-width: 767px) {
        .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .classe_average_tr.student_view td:first-of-type {
          width: 80% !important; } }
      @media (max-width: 1199px) {
        .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap tr.std_view td:first-of-type {
          width: 95% !important; } }
      @media (max-width: 767px) {
        .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap tr.std_view td:first-of-type {
          width: 80% !important; } }
      @media (max-width: 1199px) {
        .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap tr.std_view.MC td:first-of-type {
          width: 95% !important; } }
      @media (max-width: 767px) {
        .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap tr.std_view.MC td:first-of-type {
          width: 80% !important; } }
      .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .code_massar, .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .std_name {
        position: relative;
        display: flex;
        justify-content: space-between; }
        .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .code_massar .down_up, .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .std_name .down_up {
          position: absolute;
          right: 5px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          top: 50%;
          transform: translateY(-50%); }
          .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .code_massar .down_up i, .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .std_name .down_up i {
            cursor: pointer;
            font-weight: bold;
            color: black; }
            .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .code_massar .down_up i.white, .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .std_name .down_up i.white {
              color: white; }
            .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .code_massar .down_up i.deactivated, .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .std_name .down_up i.deactivated {
              color: gray;
              cursor: not-allowed; }
      .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .std_name {
        width: 210px; }
        .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .std_name.head {
          width: 250px;
          text-align: center;
          justify-content: center;
          font-weight: bold;
          color: black; }
      .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .code_massar {
        font-weight: normal;
        width: 130px; }
        .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap .code_massar.head {
          font-weight: bold;
          text-align: center;
          line-height: 15px; }
    .evaluation_page .evaluation_container .e_single_table table {
      font-size: 14px;
      color: rgba(27, 36, 55, 0.5);
      font-weight: 500;
      table-layout: fixed;
      border-collapse: collapse;
      border-radius: 8px; }
      .evaluation_page .evaluation_container .e_single_table table:not(.min_w_100):not(.all_competencies_table):not(.all_objectifs_table) {
        width: 100%; }
      .evaluation_page .evaluation_container .e_single_table table td,
      .evaluation_page .evaluation_container .e_single_table table th {
        border-right: 1px solid #d1dbe2;
        border-bottom: 1px solid #d1dbe2;
        padding: 1px 4px;
        background: #fff;
        color: black;
        font-size: 0.8rem;
        background-clip: padding-box;
        height: 47px; }
        .evaluation_page .evaluation_container .e_single_table table td > .down_up,
        .evaluation_page .evaluation_container .e_single_table table th > .down_up {
          position: absolute;
          right: 5px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          top: 50%;
          transform: translateY(-50%); }
          .evaluation_page .evaluation_container .e_single_table table td > .down_up i,
          .evaluation_page .evaluation_container .e_single_table table th > .down_up i {
            cursor: pointer;
            font-weight: bold;
            color: black; }
            .evaluation_page .evaluation_container .e_single_table table td > .down_up i.white,
            .evaluation_page .evaluation_container .e_single_table table th > .down_up i.white {
              color: white; }
            .evaluation_page .evaluation_container .e_single_table table td > .down_up i.deactivated,
            .evaluation_page .evaluation_container .e_single_table table th > .down_up i.deactivated {
              color: gray;
              cursor: not-allowed; }
        .evaluation_page .evaluation_container .e_single_table table td > .show_hide_obj,
        .evaluation_page .evaluation_container .e_single_table table th > .show_hide_obj {
          position: absolute;
          left: 5px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          top: 50%;
          transform: translateY(-50%); }
          .evaluation_page .evaluation_container .e_single_table table td > .show_hide_obj i,
          .evaluation_page .evaluation_container .e_single_table table th > .show_hide_obj i {
            cursor: pointer;
            font-weight: bold;
            color: black; }
            .evaluation_page .evaluation_container .e_single_table table td > .show_hide_obj i.white,
            .evaluation_page .evaluation_container .e_single_table table th > .show_hide_obj i.white {
              color: white; }
            .evaluation_page .evaluation_container .e_single_table table td > .show_hide_obj i.deactivated,
            .evaluation_page .evaluation_container .e_single_table table th > .show_hide_obj i.deactivated {
              color: gray;
              cursor: not-allowed; }
        .evaluation_page .evaluation_container .e_single_table table td > .control_name,
        .evaluation_page .evaluation_container .e_single_table table th > .control_name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          white-space: unset !important;
          margin-top: -9px;
          color: white;
          padding-right: 7px;
          padding-left: 7px;
          font-size: 10px;
          line-height: 10px;
          height: 20px;
          margin-top: 5px;
          margin-bottom: -5px; }
        .evaluation_page .evaluation_container .e_single_table table td > .control_name2,
        .evaluation_page .evaluation_container .e_single_table table th > .control_name2 {
          line-height: 10px; }
        .evaluation_page .evaluation_container .e_single_table table td:not(:first-child):not(:last-child),
        .evaluation_page .evaluation_container .e_single_table table th:not(:first-child):not(:last-child) {
          color: #333;
          font-weight: bold;
          width: inherit; }
      .evaluation_page .evaluation_container .e_single_table table td:not(:first-child):not(:last-child) {
        text-align: center; }
        .evaluation_page .evaluation_container .e_single_table table td:not(:first-child):not(:last-child) select {
          text-align: center; }
      .evaluation_page .evaluation_container .e_single_table table input {
        font-weight: bold;
        width: 100%;
        border: 0;
        text-align: center;
        -moz-appearance: textfield;
        background: transparent; }
        .evaluation_page .evaluation_container .e_single_table table input::-webkit-inner-spin-button {
          -webkit-appearance: none; }
        .evaluation_page .evaluation_container .e_single_table table input:focus {
          outline: 2px solid #a9f3ed; }
      .evaluation_page .evaluation_container .e_single_table table tr:last-of-type td, .evaluation_page .evaluation_container .e_single_table table tr:last-of-type th {
        border-bottom: 0; }
      .evaluation_page .evaluation_container .e_single_table table tr td {
        line-height: 30px;
        position: relative; }
        .evaluation_page .evaluation_container .e_single_table table tr td:first-of-type {
          width: 90%; }
        .evaluation_page .evaluation_container .e_single_table table tr td:not(:first-of-type) {
          text-align: center; }
        .evaluation_page .evaluation_container .e_single_table table tr td:last-of-type, .evaluation_page .evaluation_container .e_single_table table tr td.gradient_block {
          text-align: center;
          background-image: linear-gradient(45deg, #3eb6ad, #72dcd6) !important;
          color: #fff !important;
          border-right: 0; }
          .evaluation_page .evaluation_container .e_single_table table tr td:last-of-type.w_0, .evaluation_page .evaluation_container .e_single_table table tr td.gradient_block.w_0 {
            width: 0 !important; }
          .evaluation_page .evaluation_container .e_single_table table tr td:last-of-type.gradient_block2, .evaluation_page .evaluation_container .e_single_table table tr td.gradient_block.gradient_block2 {
            background-image: linear-gradient(45deg, #9e3eb6, #d7a3e4) !important; }
        .evaluation_page .evaluation_container .e_single_table table tr td.back_inh {
          background-image: inherit !important;
          color: black !important;
          font-weight: bold !important; }
        .evaluation_page .evaluation_container .e_single_table table tr td.grey_grad {
          background: #b1bfcd; }
        .evaluation_page .evaluation_container .e_single_table table tr td.yellow_grad {
          background-image: linear-gradient(258deg, #fdd271, #d5a433);
          color: #fff !important;
          text-align: center; }
        .evaluation_page .evaluation_container .e_single_table table tr td.orange_grad {
          background-image: linear-gradient(252deg, #ff867f, #ff5f04); }
        .evaluation_page .evaluation_container .e_single_table table tr td.info_message {
          width: 100% !important;
          background: transparent !important;
          text-align: left !important;
          color: #000000 !important; }
        @media (max-width: 1199px) {
          .evaluation_page .evaluation_container .e_single_table table tr td:not(:first-of-type) {
            pointer-events: none; } }
        .evaluation_page .evaluation_container .e_single_table table tr td .error {
          display: none; }
          .evaluation_page .evaluation_container .e_single_table table tr td .error .span_err {
            position: absolute;
            right: 123%;
            width: max-content;
            color: red;
            top: 50%;
            transform: translateY(-50%); }
        .evaluation_page .evaluation_container .e_single_table table tr td:hover .error {
          display: block; }
      .evaluation_page .evaluation_container .e_single_table table tr.control td:not(.td_action):last-of-type {
        width: inherit; }
      .evaluation_page .evaluation_container .e_single_table table tr.control td.td_action {
        background: transparent; }
        .evaluation_page .evaluation_container .e_single_table table tr.control td.td_action.disabled {
          background-color: #c4c4c4; }
      .evaluation_page .evaluation_container .e_single_table table tr.control td.parent_view_mc {
        background: #b1bfcd;
        width: inherit !important; }
      .evaluation_page .evaluation_container .e_single_table table tr.control td.child_note {
        text-align: center;
        background-image: linear-gradient(45deg, #3eb6ad, #72dcd6);
        color: #fff !important;
        border-right: 0; }
      .evaluation_page .evaluation_container .e_single_table table tr.control .delete_cotrol_prof {
        position: absolute;
        right: 0; }
      .evaluation_page .evaluation_container .e_single_table table tr.appreciation td:first-of-type {
        width: 30% !important; }
        @media (max-width: 1199px) {
          .evaluation_page .evaluation_container .e_single_table table tr.appreciation td:first-of-type {
            width: 3% !important;
            padding: 0px !important;
            padding-left: 5px !important; }
            .evaluation_page .evaluation_container .e_single_table table tr.appreciation td:first-of-type div {
              float: none !important; } }
      .evaluation_page .evaluation_container .e_single_table table tr.appreciation td.td_appr_types {
        width: auto !important; }
      .evaluation_page .evaluation_container .e_single_table table tr.appreciation .appreciation_mobile {
        position: relative; }
        .evaluation_page .evaluation_container .e_single_table table tr.appreciation .appreciation_mobile .appreciation_input {
          text-align: left; }
          .evaluation_page .evaluation_container .e_single_table table tr.appreciation .appreciation_mobile .appreciation_input.no_100 {
            width: calc(100% - 13px); }
        .evaluation_page .evaluation_container .e_single_table table tr.appreciation .appreciation_mobile a {
          display: none;
          position: absolute;
          right: -2px;
          top: 50%;
          transform: translateY(-50%); }
          @media (max-width: 1199px) {
            .evaluation_page .evaluation_container .e_single_table table tr.appreciation .appreciation_mobile a {
              display: block; } }
        .evaluation_page .evaluation_container .e_single_table table tr.appreciation .appreciation_mobile span.inline {
          display: inline-block !important; }
      .evaluation_page .evaluation_container .e_single_table table tr.appreciation td:last-of-type {
        width: 5%; }
      @media (max-width: 767px) {
        .evaluation_page .evaluation_container .e_single_table table tr.competency td:first-of-type {
          width: 85% !important; } }
      .evaluation_page .evaluation_container .e_single_table table tr.mobile_appr td:last-of-type {
        color: black !important; }
      .evaluation_page .evaluation_container .e_single_table table select {
        border: 0;
        background-color: transparent;
        padding: 0px 12px 0 0px;
        outline: none;
        background-position: right center;
        box-shadow: none !important;
        font-weight: 700;
        font-size: 17px; }
        .evaluation_page .evaluation_container .e_single_table table select:focus {
          outline: 2px solid #a9f3ed; }
      .evaluation_page .evaluation_container .e_single_table table .e_dropdown {
        text-align: center;
        position: relative; }
        .evaluation_page .evaluation_container .e_single_table table .e_dropdown span {
          display: inline-block;
          vertical-align: middle;
          width: 22px;
          height: 22px; }
        .evaluation_page .evaluation_container .e_single_table table .e_dropdown .down_arrow {
          position: absolute;
          z-index: 90;
          right: -10px;
          top: 40%;
          width: 10px;
          height: 5px; }
        .evaluation_page .evaluation_container .e_single_table table .e_dropdown .dropdown-toggle {
          padding: 0;
          background-color: transparent; }
          .evaluation_page .evaluation_container .e_single_table table .e_dropdown .dropdown-toggle::after {
            content: unset; }
          .evaluation_page .evaluation_container .e_single_table table .e_dropdown .dropdown-toggle:focus {
            box-shadow: none; }
        .evaluation_page .evaluation_container .e_single_table table .e_dropdown .dropdown-menu {
          min-width: 2.5rem; }
          .evaluation_page .evaluation_container .e_single_table table .e_dropdown .dropdown-menu .dropdown-item {
            padding: .25rem 0;
            display: flex; }
      .evaluation_page .evaluation_container .e_single_table table.all_competencies_table tr td, .evaluation_page .evaluation_container .e_single_table table.all_objectifs_table tr td, .evaluation_page .evaluation_container .e_single_table table.all_controls_notes tr td {
        pointer-events: inherit; }
    .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap.appriciations table {
      table-layout: inherit; }
    .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap.appriciations.td_w_23 table tr.appreciation td:first-of-type {
      width: 23% !important; }
    .evaluation_page .evaluation_container .e_single_table .e_single_table_wrap.appriciations.td_w_23 table tr.appreciation td.td_appr_types {
      width: auto !important; }
    .evaluation_page .evaluation_container .e_single_table .table-bottom-btn a {
      color: #fff;
      background: #b1bfcd;
      padding: 7px 10px;
      display: inline-block;
      border-radius: 49px;
      font-size: 14px;
      max-width: 165px;
      margin: 12px 0 5px;
      width: 100%;
      font-weight: 600; }
      .evaluation_page .evaluation_container .e_single_table .table-bottom-btn a.saved-control {
        background: #4ec2ba; }
      .evaluation_page .evaluation_container .e_single_table .table-bottom-btn a span {
        margin-right: 15px; }
  .evaluation_page .evaluation_container .evaluation-bottom-notice {
    text-align: center;
    margin-top: 40px; }
    .evaluation_page .evaluation_container .evaluation-bottom-notice ul {
      margin-bottom: 0; }
      .evaluation_page .evaluation_container .evaluation-bottom-notice ul li {
        padding: 0 10px;
        display: inline-block;
        color: #7f8291;
        font-size: 14px; }
        .evaluation_page .evaluation_container .evaluation-bottom-notice ul li label {
          font-weight: 600;
          margin-bottom: 0; }
  .evaluation_page .evaluation_container.evaluation_with_sidebar .e_single_table table tr td:first-of-type {
    width: 68%; }
  .evaluation_page .evaluation_container.score_assessment .e_single_table table tr td {
    text-align: center;
    padding: 12px 15px; }
    .evaluation_page .evaluation_container.score_assessment .e_single_table table tr td:first-of-type {
      text-align: left; }

.evaluation_page.print_view_page {
  width: 100%;
  float: none;
  padding: 0.5rem;
  margin: 2rem auto; }
  .evaluation_page.print_view_page .ph_top_left {
    background-color: #b1bfcd;
    border-radius: 100%;
    padding: 0;
    color: #fff;
    height: 35px;
    width: 35px;
    line-height: 35px;
    margin-top: 0.3rem;
    text-align: center;
    position: absolute; }
  .evaluation_page.print_view_page .print_container {
    padding: 25px; }
    .evaluation_page.print_view_page .print_container table {
      border: 1px solid #d1dbe2; }
      .evaluation_page.print_view_page .print_container table .header_table td {
        border: 0;
        border-bottom: 1px solid #d1dbe2; }
  .evaluation_page.print_view_page .print_view_header {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .evaluation_page.print_view_page .print_view_header .print_header_top {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 100%; }
      .evaluation_page.print_view_page .print_view_header .print_header_top .ph_top_left {
        background-color: transparent;
        border-radius: 100%;
        padding: 0;
        color: #fff;
        height: 35px;
        width: 35px;
        line-height: 35px;
        margin-top: 0.3rem;
        text-align: center; }
      .evaluation_page.print_view_page .print_view_header .print_header_top .ph_top_middle > label {
        background: #fff;
        padding: 0.4rem 4rem;
        font-weight: 500;
        font-size: 18px;
        border-radius: 4px; }
      .evaluation_page.print_view_page .print_view_header .print_header_top .ph_top_middle .ph_top_middle_bottom {
        display: flex;
        justify-content: space-around;
        font-size: 0.8rem;
        padding-right: 0;
        margin-top: 8px;
        font-weight: 500; }
        .evaluation_page.print_view_page .print_view_header .print_header_top .ph_top_middle .ph_top_middle_bottom label {
          color: #a7a7a7; }
      .evaluation_page.print_view_page .print_view_header .print_header_top .ph_top_right {
        margin-top: 0.4rem; }
        .evaluation_page.print_view_page .print_view_header .print_header_top .ph_top_right .btn {
          padding: 0.4em 2rem; }
  .evaluation_page.print_view_page .evaluation_container {
    background-color: transparent;
    padding: 0; }
    .evaluation_page.print_view_page .evaluation_container .e_single_table {
      background: transparent;
      border: 0;
      padding: 0; }
      .evaluation_page.print_view_page .evaluation_container .e_single_table table td {
        text-align: center; }
        .evaluation_page.print_view_page .evaluation_container .e_single_table table td:first-of-type {
          text-align: left; }

.annual_reviews_page .annual_reviews_container {
  background-color: #fff;
  margin-top: 1rem;
  padding: 1rem 1.9rem 20px;
  border-radius: 5px; }
  @media only screen and (max-width: 1199px) {
    .annual_reviews_page .annual_reviews_container.parent_view {
      padding-bottom: 60px !important; } }
  .annual_reviews_page .annual_reviews_container .e_top_bar {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    flex-wrap: wrap; }
    .annual_reviews_page .annual_reviews_container .e_top_bar .choose_view {
      width: 100%;
      margin-bottom: 10px; }
      .annual_reviews_page .annual_reviews_container .e_top_bar .choose_view > div {
        width: 100%;
        display: flex;
        flex-direction: inherit;
        justify-content: center; }
        .annual_reviews_page .annual_reviews_container .e_top_bar .choose_view > div .legend {
          width: auto;
          line-height: 30px; }
        .annual_reviews_page .annual_reviews_container .e_top_bar .choose_view > div .choices {
          margin-left: 15px;
          flex-direction: row;
          display: flex;
          flex-wrap: wrap;
          border: 2px solid #d7d5d5;
          border-radius: 9px;
          width: 250px; }
          .annual_reviews_page .annual_reviews_container .e_top_bar .choose_view > div .choices > span {
            padding: 5px;
            display: block;
            max-width: 124px;
            width: 50%;
            color: #51c4bc;
            background-color: white;
            text-align: center;
            cursor: pointer;
            border-radius: 6px; }
            .annual_reviews_page .annual_reviews_container .e_top_bar .choose_view > div .choices > span.active {
              color: white;
              background-color: #51c4bc; }
          .annual_reviews_page .annual_reviews_container .e_top_bar .choose_view > div .choices > div .react-switch-bg {
            height: 22px;
            width: 51px; }
            .annual_reviews_page .annual_reviews_container .e_top_bar .choose_view > div .choices > div .react-switch-bg > div {
              height: 22px; }
          .annual_reviews_page .annual_reviews_container .e_top_bar .choose_view > div .choices > div .react-switch-handle {
            height: 20px;
            width: 20px; }
        .annual_reviews_page .annual_reviews_container .e_top_bar .choose_view > div * {
          margin-bottom: 0; }
    .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left {
      min-width: 100px;
      display: flex;
      align-items: center; }
      .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info {
        position: relative; }
        .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info .student_label {
          color: black; }
        .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info img {
          margin: 0 0.6rem;
          width: 30px;
          height: 30px;
          border-radius: 4px; }
        .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info .student_name {
          font-weight: 500;
          border: none;
          width: 50%; }
        .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info .student-name {
          font-size: 0.9rem;
          font-weight: 500;
          box-shadow: none;
          cursor: pointer; }
        .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info .custom-dropdown {
          position: absolute;
          border-radius: 10px;
          background-color: #ffffff;
          margin-top: 1rem;
          display: none;
          width: max-content;
          left: 15%;
          z-index: 20; }
          .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown {
            padding: 1rem;
            box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5);
            position: relative;
            background-color: #ffffff;
            border-radius: 10px;
            overflow: auto; }
            .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background-color: #F5F5F5; }
            .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown::-webkit-scrollbar {
              width: 5px;
              height: 3px;
              background-color: #F5F5F5; }
            .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown::-webkit-scrollbar-thumb {
              background-color: #3eb6ad; }
            .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown .form-group {
              position: relative; }
              .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown .form-group input {
                border-radius: 25px;
                border: 1px solid #c2c2c2;
                width: 100%;
                height: 1.6rem;
                background-color: rgba(177, 191, 205, 0.1); }
                .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown .form-group input::placeholder {
                  font-size: 10px; }
              .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown .form-group span {
                position: absolute;
                top: 10px;
                right: 12px; }
            .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown .student-detail {
              display: flex;
              align-items: center;
              border-bottom: 1px solid rgba(195, 195, 195, 0.3);
              padding-bottom: 10px;
              padding-top: 10px;
              cursor: pointer;
              min-width: 80px; }
              .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown .student-detail p {
                font-size: .9rem;
                padding-left: 1rem;
                margin-bottom: 0;
                font-weight: 500; }
              .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown .student-detail:focus {
                box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5); }
              .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown .student-detail:hover {
                box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5);
                border-radius: 10px;
                border: 1px solid #c2c2c2; }
          .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info .custom-dropdown .student-names-dropdown:before {
            content: "";
            border-bottom: 15px solid #fff;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            position: absolute;
            top: -15px;
            left: 45%;
            box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5); }
    .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_right {
      display: flex;
      margin-right: 2rem; }
      .annual_reviews_page .annual_reviews_container .e_top_bar .e_top_right .btn {
        display: flex;
        align-items: center; }
  .annual_reviews_page .annual_reviews_container .circ {
    height: 22px;
    width: 22px;
    border-radius: 100%;
    margin: 0 auto;
    display: inline-block; }
    .annual_reviews_page .annual_reviews_container .circ.green {
      background-image: linear-gradient(223deg, #72dcd6, #3eb6ad); }
    .annual_reviews_page .annual_reviews_container .circ.yellow {
      background-image: linear-gradient(220deg, #fdd271, #d5a433); }
    .annual_reviews_page .annual_reviews_container .circ.orange {
      background-image: linear-gradient(41deg, #ff5f04, #ff867f); }
    .annual_reviews_page .annual_reviews_container .circ.gray {
      background-image: linear-gradient(41deg, #90a1b1, #b1bfcd); }
    .annual_reviews_page .annual_reviews_container .circ.white {
      background-image: linear-gradient(41deg, #fff, #fff); }
  .annual_reviews_page .annual_reviews_container .star {
    background-image: url("../img/evaluation/star_icon.svg");
    height: 22px;
    width: 22px;
    display: block;
    margin: 0 auto;
    background-size: contain;
    background-position: center; }
    .annual_reviews_page .annual_reviews_container .star.white {
      background-image: url("../img/evaluation/star_white_icon.svg"); }
  .annual_reviews_page .annual_reviews_container .a_r_single_table {
    background: rgba(245, 248, 249, 0.3);
    border-radius: 8px;
    border: 1px solid #d1dbe2;
    padding: 10px;
    margin-top: 30px; }
    .annual_reviews_page .annual_reviews_container .a_r_single_table > label {
      font-size: 14px;
      color: #5a5a5a;
      font-weight: 600; }
    .annual_reviews_page .annual_reviews_container .a_r_single_table .a_r_single_table_wrap {
      border-radius: 0px;
      border-right-width: 0px; }
      .annual_reviews_page .annual_reviews_container .a_r_single_table .a_r_single_table_wrap table {
        border: 1px solid #d1dbe2; }
    .annual_reviews_page .annual_reviews_container .a_r_single_table table {
      font-size: 14px;
      color: rgba(27, 36, 55, 0.5);
      font-weight: 500;
      table-layout: fixed;
      border-collapse: collapse;
      width: 100%;
      border-radius: 8px; }
      .annual_reviews_page .annual_reviews_container .a_r_single_table table td,
      .annual_reviews_page .annual_reviews_container .a_r_single_table table th {
        border-right: 1px solid #d1dbe2;
        border-bottom: 1px solid #d1dbe2;
        padding: 1px 4px;
        background: #fff;
        font-size: 0.8rem;
        background-clip: padding-box;
        height: 47px;
        color: black; }
        .annual_reviews_page .annual_reviews_container .a_r_single_table table td.criteria_name,
        .annual_reviews_page .annual_reviews_container .a_r_single_table table th.criteria_name {
          color: #adadad; }
      .annual_reviews_page .annual_reviews_container .a_r_single_table table td:not(:first-child):not(:last-child) {
        text-align: center; }
        .annual_reviews_page .annual_reviews_container .a_r_single_table table td:not(:first-child):not(:last-child) select {
          text-align: center; }
      .annual_reviews_page .annual_reviews_container .a_r_single_table table input {
        font-weight: bold;
        width: 100%;
        border: 0;
        text-align: center;
        -moz-appearance: textfield;
        background: transparent; }
        .annual_reviews_page .annual_reviews_container .a_r_single_table table input::-webkit-inner-spin-button {
          -webkit-appearance: none; }
        .annual_reviews_page .annual_reviews_container .a_r_single_table table input:focus {
          outline: 2px solid #a9f3ed; }
      .annual_reviews_page .annual_reviews_container .a_r_single_table table tr td {
        line-height: 30px;
        position: relative; }
        .annual_reviews_page .annual_reviews_container .a_r_single_table table tr td:not(:first-of-type) {
          text-align: center; }
        .annual_reviews_page .annual_reviews_container .a_r_single_table table tr td.info_message {
          width: 100% !important;
          background: transparent !important;
          text-align: left !important;
          color: #b0bfcd !important; }
        @media (max-width: 1199px) {
          .annual_reviews_page .annual_reviews_container .a_r_single_table table tr td:not(:first-of-type) {
            pointer-events: none; } }
        .annual_reviews_page .annual_reviews_container .a_r_single_table table tr td .error {
          display: none; }
          .annual_reviews_page .annual_reviews_container .a_r_single_table table tr td .error .span_err {
            position: absolute;
            right: 123%;
            width: max-content;
            color: red;
            top: 50%;
            transform: translateY(-50%); }
        .annual_reviews_page .annual_reviews_container .a_r_single_table table tr td:hover .error {
          display: block; }
      .annual_reviews_page .annual_reviews_container .a_r_single_table table thead td {
        font-weight: bold;
        color: black;
        text-align: center; }
    .annual_reviews_page .annual_reviews_container .a_r_single_table .table-bottom-btn a {
      color: #fff;
      background: #b1bfcd;
      padding: 7px 10px;
      display: inline-block;
      border-radius: 49px;
      font-size: 14px;
      max-width: 165px;
      margin: 12px 0 5px;
      width: 100%;
      font-weight: 600; }
      .annual_reviews_page .annual_reviews_container .a_r_single_table .table-bottom-btn a.saved-control {
        background: #4ec2ba; }
      .annual_reviews_page .annual_reviews_container .a_r_single_table .table-bottom-btn a span {
        margin-right: 15px; }
  .annual_reviews_page .annual_reviews_container .evaluation-bottom-notice {
    text-align: center;
    margin-top: 40px; }
    .annual_reviews_page .annual_reviews_container .evaluation-bottom-notice ul {
      margin-bottom: 0; }
      .annual_reviews_page .annual_reviews_container .evaluation-bottom-notice ul li {
        padding: 0 10px;
        display: inline-block;
        color: #7f8291;
        font-size: 14px; }
        .annual_reviews_page .annual_reviews_container .evaluation-bottom-notice ul li label {
          font-weight: 600;
          margin-bottom: 0; }

.annual_reviews_page.print_view_page {
  width: 100%;
  float: none;
  padding: 0.5rem;
  margin: 2rem auto; }
  .annual_reviews_page.print_view_page .ph_top_left {
    background-color: #b1bfcd;
    border-radius: 100%;
    padding: 0;
    color: #fff;
    height: 35px;
    width: 35px;
    line-height: 35px;
    margin-top: 0.3rem;
    text-align: center;
    position: absolute; }
  .annual_reviews_page.print_view_page .print_container {
    padding: 25px; }
    .annual_reviews_page.print_view_page .print_container table {
      border: 1px solid #d1dbe2; }
      .annual_reviews_page.print_view_page .print_container table .header_table td {
        border: 0;
        border-bottom: 1px solid #d1dbe2; }
  .annual_reviews_page.print_view_page .print_view_header {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .annual_reviews_page.print_view_page .print_view_header .print_header_top {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 100%; }
      .annual_reviews_page.print_view_page .print_view_header .print_header_top .ph_top_left {
        background-color: transparent;
        border-radius: 100%;
        padding: 0;
        color: #fff;
        height: 35px;
        width: 35px;
        line-height: 35px;
        margin-top: 0.3rem;
        text-align: center; }
      .annual_reviews_page.print_view_page .print_view_header .print_header_top .ph_top_middle > label {
        background: #fff;
        padding: 0.4rem 4rem;
        font-weight: 500;
        font-size: 18px;
        border-radius: 4px; }
      .annual_reviews_page.print_view_page .print_view_header .print_header_top .ph_top_middle .ph_top_middle_bottom {
        display: flex;
        justify-content: space-around;
        font-size: 0.8rem;
        padding-right: 0;
        margin-top: 8px;
        font-weight: 500; }
        .annual_reviews_page.print_view_page .print_view_header .print_header_top .ph_top_middle .ph_top_middle_bottom label {
          color: #a7a7a7; }
      .annual_reviews_page.print_view_page .print_view_header .print_header_top .ph_top_right {
        margin-top: 0.4rem; }
        .annual_reviews_page.print_view_page .print_view_header .print_header_top .ph_top_right .btn {
          padding: 0.4em 2rem; }
  .annual_reviews_page.print_view_page .annual_reviews_container {
    background-color: transparent;
    padding: 0; }
    .annual_reviews_page.print_view_page .annual_reviews_container .a_r_single_table {
      background: transparent;
      border: 0;
      padding: 0; }
      .annual_reviews_page.print_view_page .annual_reviews_container .a_r_single_table table td {
        text-align: center; }
        .annual_reviews_page.print_view_page .annual_reviews_container .a_r_single_table table td:first-of-type {
          text-align: left; }

#exam_content .e_single_table table tr td:first-of-type {
  width: 95%; }
  @media (max-width: 1199px) {
    #exam_content .e_single_table table tr td:first-of-type {
      width: 90%; } }

/*******************modal- student list ************************/
.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle {
  background-image: linear-gradient(181deg, #72dcd6, #3eb6ad) !important; }

.os-theme-dark > .os-scrollbar, .os-theme-light > .os-scrollbar {
  padding: 3px !important; }

#e_student_list .modal-dialog {
  max-width: 422px; }
  #e_student_list .modal-dialog .modal-content {
    border-radius: 12px;
    box-shadow: 0 5px 45px 0 rgba(47, 81, 144, 0.18);
    background-color: #ffffff; }
    #e_student_list .modal-dialog .modal-content .modal-body {
      padding: 0px 25px 30px; }
      #e_student_list .modal-dialog .modal-content .modal-body .bar_search {
        padding-bottom: 1.8rem; }
        #e_student_list .modal-dialog .modal-content .modal-body .bar_search .input-group {
          border-radius: 30px;
          overflow: hidden;
          border: 1px solid #b1bfcd; }
          #e_student_list .modal-dialog .modal-content .modal-body .bar_search .input-group input {
            height: 2.1rem;
            border: none;
            border-radius: 0;
            background-color: #f8f8f8;
            font-size: 0.9rem; }
          #e_student_list .modal-dialog .modal-content .modal-body .bar_search .input-group .input-group-append {
            margin-left: -1px; }
            #e_student_list .modal-dialog .modal-content .modal-body .bar_search .input-group .input-group-append button {
              border-radius: 0;
              border: none;
              box-shadow: none;
              background-color: #f8f8f8;
              padding: 0px 10px; }
      #e_student_list .modal-dialog .modal-content .modal-body .e_list_wrap {
        padding: 13px 22px 13px 15px;
        height: 426px; }
        #e_student_list .modal-dialog .modal-content .modal-body .e_list_wrap .os-content-glue {
          height: 600px !important; }
        #e_student_list .modal-dialog .modal-content .modal-body .e_list_wrap .os-content {
          padding: 0px 9px 13px 0px !important; }
        #e_student_list .modal-dialog .modal-content .modal-body .e_list_wrap ul {
          list-style-type: none;
          padding-left: 0px; }
          #e_student_list .modal-dialog .modal-content .modal-body .e_list_wrap ul .e_list_student_item li {
            padding: 0.6rem 8px 0.6rem;
            border: 1px solid transparent; }
            #e_student_list .modal-dialog .modal-content .modal-body .e_list_wrap ul .e_list_student_item li img {
              border-radius: 7px; }
            #e_student_list .modal-dialog .modal-content .modal-body .e_list_wrap ul .e_list_student_item li span {
              font-size: 14px;
              color: #1b2437cc;
              margin-left: 19px; }
            #e_student_list .modal-dialog .modal-content .modal-body .e_list_wrap ul .e_list_student_item li:hover {
              cursor: pointer;
              box-shadow: 0 5px 45px 0 rgba(47, 81, 144, 0.18);
              border: 1px solid rgba(47, 81, 144, 0.18);
              border-radius: 7px; }
              #e_student_list .modal-dialog .modal-content .modal-body .e_list_wrap ul .e_list_student_item li:hover span {
                color: #1b2437;
                font-weight: 500; }

@media only screen and (max-width: 991px) {
  .mobile_table_popup {
    background: rgba(177, 191, 205, 0.5); }
    .mobile_table_popup .modal-content .modal-header {
      padding-left: 30px;
      padding-bottom: 20px; }
      .mobile_table_popup .modal-content .modal-header .modal-title {
        text-align: left; }
    .mobile_table_popup .modal-content .modal-body {
      padding: 0px 35px 30px; }
    .mobile_table_popup .modal-content table tr td {
      padding: 5px 14px; }
      .mobile_table_popup .modal-content table tr td:first-of-type {
        width: auto; }
  div.main {
    /*width: calc(100% - 95px);
        padding:1.2rem;*/ }
    div.main.evaluation_page .top_bar {
      background: transparent; }
      div.main.evaluation_page .top_bar .bar_right {
        background: #fff;
        flex-basis: 58%;
        justify-content: space-between; }
        div.main.evaluation_page .top_bar .bar_right .bar_select {
          max-width: 150px;
          margin-right: 0.5rem;
          padding-left: 5px; }
      div.main.evaluation_page .top_bar .bar_left {
        flex-basis: 42%; }
        div.main.evaluation_page .top_bar .bar_left .nav-item {
          margin: 0;
          flex-basis: 50%; }
          div.main.evaluation_page .top_bar .bar_left .nav-item a {
            padding: 0; }
          div.main.evaluation_page .top_bar .bar_left .nav-item:last-of-type img {
            margin-left: 20px; }
          div.main.evaluation_page .top_bar .bar_left .nav-item img {
            height: auto;
            width: auto;
            max-width: 33px; }
        div.main.evaluation_page .top_bar .bar_left div {
          align-items: center;
          flex-basis: 67%;
          background: #fff;
          margin-right: 3px; }
          div.main.evaluation_page .top_bar .bar_left div .bar_title {
            font-size: 1rem;
            margin: 0;
            padding: 5px 10px; }
    div.main.evaluation_page .evaluation_container {
      background-color: transparent;
      margin-top: 1.5rem;
      padding: 0;
      border-radius: 0; }
      div.main.evaluation_page .evaluation_container#exam_content .e_single_table table tr td:first-of-type {
        width: 90%; }
      div.main.evaluation_page .evaluation_container .e_top_bar select,
      div.main.evaluation_page .evaluation_container .e_top_bar label {
        background-color: transparent;
        box-shadow: none; }
      div.main.evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info .student_name {
        padding-left: 0; }
      div.main.evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info .custom-dropdown {
        width: 100%;
        left: 0%; }
      div.main.evaluation_page .evaluation_container .e_top_bar .e_top_right {
        margin-right: 0;
        align-items: center; }
        div.main.evaluation_page .evaluation_container .e_top_bar .e_top_right .btn {
          padding: 0.3rem 0.5rem;
          margin-left: 4px; }
        div.main.evaluation_page .evaluation_container .e_top_bar .e_top_right button {
          padding: 0.4rem 1.2rem; }
      div.main.evaluation_page .evaluation_container .e_single_table table tr td:first-of-type {
        width: 60%; }
    div.main.annual_reviews_page .top_bar {
      background: transparent; }
      div.main.annual_reviews_page .top_bar .bar_right {
        background: #fff;
        flex-basis: 58%;
        justify-content: space-between; }
        div.main.annual_reviews_page .top_bar .bar_right .bar_select {
          max-width: 150px;
          margin-right: 0.5rem;
          padding-left: 5px; }
    div.main.annual_reviews_page .annual_reviews_container {
      background-color: transparent;
      margin-top: 1.5rem;
      padding: 0;
      border-radius: 0; }
      div.main.annual_reviews_page .annual_reviews_container .e_top_bar select,
      div.main.annual_reviews_page .annual_reviews_container .e_top_bar label {
        background-color: transparent;
        box-shadow: none; }
      div.main.annual_reviews_page .annual_reviews_container .e_top_bar .e_top_right {
        margin-right: 0;
        align-items: center; }
        div.main.annual_reviews_page .annual_reviews_container .e_top_bar .e_top_right .btn {
          padding: 0.3rem 0.5rem;
          margin-left: 4px; }
        div.main.annual_reviews_page .annual_reviews_container .e_top_bar .e_top_right button {
          padding: 0.4rem 1.2rem; }
  .float-raise-btn a {
    position: fixed;
    bottom: 8%;
    left: 20px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-image: linear-gradient(258deg, #fdd271, #d5a433);
    text-align: center;
    line-height: 55px;
    box-shadow: 0 15px 30px rgba(73, 73, 73, 0.5); }
    .float-raise-btn a.float_right_plus {
      left: auto;
      right: 20px; } }

@media only screen and (max-width: 767px) {
  .test.select2-selection--single .select2-selection__arrow b {
    left: 150%; }
  .e_top_right .bar_select {
    margin: 0 !important; }
  .select2-container--default {
    max-width: 100%;
    min-width: unset !important;
    width: fit-content !important; }
    .select2-container--default .select2-selection--single .select2-selection__rendered {
      font-size: 1rem !important; }
  div.main.evaluation_page .top_bar .bar_left {
    flex-basis: 100%; }
    div.main.evaluation_page .top_bar .bar_left .nav {
      width: 100%;
      display: flex;
      flex-wrap: nowrap; }
      div.main.evaluation_page .top_bar .bar_left .nav div {
        background-color: transparent; }
  div.main.evaluation_page .evaluation_container .e_top_bar {
    display: block; } }
  @media only screen and (max-width: 767px) and (max-width: 767px) {
    div.main.evaluation_page .evaluation_container .e_top_bar {
      display: flex; }
      div.main.evaluation_page .evaluation_container .e_top_bar .subject_select {
        display: none !important; } }

@media only screen and (max-width: 767px) {
    div.main.evaluation_page .evaluation_container .e_top_bar .bar_select {
      margin-right: 0rem;
      background: transparent;
      margin: 15px 0; }
      div.main.evaluation_page .evaluation_container .e_top_bar .bar_select .select2-container--default .select2-selection--single {
        background: transparent; }
    div.main.evaluation_page .evaluation_container .e_top_bar .e_top_left {
      display: block; }
      div.main.evaluation_page .evaluation_container .e_top_bar .e_top_left .student_info {
        padding: 6px 0; }
      div.main.evaluation_page .evaluation_container .e_top_bar .e_top_left select {
        flex: 0 150px; }
    div.main.evaluation_page .evaluation_container .e_top_bar label {
      padding: 0; }
    div.main.evaluation_page .evaluation_container .e_top_bar .e_top_right .select2-container--default {
      max-width: 100px;
      min-width: unset;
      width: fit-content; }
    div.main.evaluation_page .evaluation_container .e_top_bar .e_top_right button {
      padding: 0.4rem 0.5rem;
      max-width: 106px;
      width: 100%; }
  div.main.evaluation_page .evaluation_container#exam_content .e_single_table table tr td:first-of-type {
    width: 85% !important; }
  div.main.evaluation_page .evaluation_container#exam_content .e_single_table table tr.std_view td:first-of-type {
    width: 80% !important; }
  div.main.evaluation_page .evaluation_container .e_single_table table td,
  div.main.evaluation_page .evaluation_container .evaluation_page .evaluation_container .e_single_table table th {
    padding: 15px 12px; }
  div.main.evaluation_page .evaluation_container .e_single_table table tr td:first-of-type {
    width: 85%;
    position: relative; }
    div.main.evaluation_page .evaluation_container .e_single_table table tr td:first-of-type a {
      position: absolute;
      right: -2px;
      top: 50%;
      transform: translateY(-50%); }
  div.main.evaluation_page .evaluation_container .e_single_table table tr td:last-of-type {
    /*width: 17%;*/
    font-size: 1rem;
    padding: 10px;
    position: relative; } }
  @media only screen and (max-width: 767px) and (max-width: 767px) and (min-width: 500px) {
    div.main.evaluation_page .evaluation_container .e_single_table table tr td:last-of-type {
      width: inherit; } }

@media only screen and (max-width: 767px) {
  div.main.evaluation_page .evaluation_container .e_single_table table tr.show_slide_cells td:first-of-type {
    font-size: 10px; }
  div.main.evaluation_page .evaluation_container .e_single_table table tr.show_slide_cells td.d-none {
    display: table-cell !important;
    width: 42%; }
  div.main.evaluation_page .evaluation_container .e_single_table table tr.show_slide_cells td:last-of-type {
    width: 32%; }
  div.main.evaluation_page .evaluation_container.parent_view .e_single_table table tr td:first-of-type {
    width: 85%; }
  div.main.evaluation_page.print_view_page {
    width: 100%;
    padding: 1.2rem; }
    div.main.evaluation_page.print_view_page .print_view_header .print_header_top .ph_top_right .btn {
      padding: 0.4em 1rem; }
    div.main.evaluation_page.print_view_page .print_view_header .print_header_top .ph_top_middle > label {
      padding: 0.3rem 1.5rem;
      font-size: 17px; }
    div.main.evaluation_page.print_view_page table td {
      color: #000; }
  div.main.annual_reviews_page .annual_reviews_container .e_top_bar {
    display: block; } }
  @media only screen and (max-width: 767px) and (max-width: 767px) {
    div.main.annual_reviews_page .annual_reviews_container .e_top_bar {
      display: flex; }
      div.main.annual_reviews_page .annual_reviews_container .e_top_bar .subject_select {
        display: none !important; } }

@media only screen and (max-width: 767px) {
    div.main.annual_reviews_page .annual_reviews_container .e_top_bar .bar_select {
      margin-right: 0rem;
      background: transparent;
      margin: 15px 0; }
      div.main.annual_reviews_page .annual_reviews_container .e_top_bar .bar_select .select2-container--default .select2-selection--single {
        background: transparent; }
    div.main.annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left {
      display: block; }
      div.main.annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left .student_info {
        padding: 6px 0; }
      div.main.annual_reviews_page .annual_reviews_container .e_top_bar .e_top_left select {
        flex: 0 150px; }
    div.main.annual_reviews_page .annual_reviews_container .e_top_bar label {
      padding: 0; }
    div.main.annual_reviews_page .annual_reviews_container .e_top_bar .e_top_right .select2-container--default {
      max-width: 100px;
      min-width: unset;
      width: fit-content; }
    div.main.annual_reviews_page .annual_reviews_container .e_top_bar .e_top_right button {
      padding: 0.4rem 0.5rem;
      max-width: 106px;
      width: 100%; }
  div.main.annual_reviews_page .annual_reviews_container .a_r_single_table table td,
  div.main.annual_reviews_page .annual_reviews_container .annual_reviews_page .annual_reviews_container .a_r_single_table table th {
    padding: 15px 12px; }
  div.main.annual_reviews_page .annual_reviews_container .a_r_single_table table tr td:first-of-type {
    position: relative; }
  div.main.annual_reviews_page .annual_reviews_container .a_r_single_table table tr td:last-of-type {
    /*width: 17%;*/
    font-size: 1rem;
    padding: 10px;
    position: relative; } }
  @media only screen and (max-width: 767px) and (max-width: 767px) and (min-width: 500px) {
    div.main.annual_reviews_page .annual_reviews_container .a_r_single_table table tr td:last-of-type {
      width: inherit; } }

@media only screen and (max-width: 767px) {
  div.main.annual_reviews_page .annual_reviews_container .a_r_single_table table tr.show_slide_cells td:first-of-type {
    font-size: 10px; }
  div.main.annual_reviews_page .annual_reviews_container .a_r_single_table table tr.show_slide_cells td.d-none {
    display: table-cell !important; }
  div.main.annual_reviews_page.print_view_page {
    width: 100%;
    padding: 1.2rem; }
    div.main.annual_reviews_page.print_view_page .print_view_header .print_header_top .ph_top_right .btn {
      padding: 0.4em 1rem; }
    div.main.annual_reviews_page.print_view_page .print_view_header .print_header_top .ph_top_middle > label {
      padding: 0.3rem 1.5rem;
      font-size: 17px; }
    div.main.annual_reviews_page.print_view_page table td {
      color: #000; }
  header + aside.show_mobile_menu .side_items ul li {
    flex-basis: 50%;
    margin: 13px 0; } }

@media only screen and (max-width: 1199px) and (min-width: 950px) {
  div.main.evaluation_page .top_bar {
    flex-wrap: wrap; }
    div.main.evaluation_page .top_bar .bar_right {
      background: inherit;
      flex-basis: 60%;
      margin-left: 40%;
      margin-top: 10px; }
      div.main.evaluation_page .top_bar .bar_right .bar_select {
        max-width: 33%; }
        div.main.evaluation_page .top_bar .bar_right .bar_select label {
          background: inherit; }
  div.main.annual_reviews_page .top_bar {
    flex-wrap: wrap; }
    div.main.annual_reviews_page .top_bar .bar_right {
      background: inherit;
      flex-basis: 60%;
      margin-left: 40%;
      margin-top: 10px; }
      div.main.annual_reviews_page .top_bar .bar_right .bar_select {
        max-width: 33%; }
        div.main.annual_reviews_page .top_bar .bar_right .bar_select label {
          background: inherit; } }

@media only screen and (max-width: 950px) and (min-width: 768px) {
  div.main.evaluation_page .top_bar {
    flex-wrap: wrap; }
    div.main.evaluation_page .top_bar .bar_right {
      margin-top: 10px;
      background: inherit;
      flex-basis: 73%;
      margin-left: 27%; }
      div.main.evaluation_page .top_bar .bar_right .bar_select {
        max-width: 33%; }
        div.main.evaluation_page .top_bar .bar_right .bar_select label {
          background: inherit; }
  div.main.annual_reviews_page .top_bar {
    flex-wrap: wrap; }
    div.main.annual_reviews_page .top_bar .bar_right {
      margin-top: 10px;
      background: inherit;
      flex-basis: 73%;
      margin-left: 27%; }
      div.main.annual_reviews_page .top_bar .bar_right .bar_select {
        max-width: 33%; }
        div.main.annual_reviews_page .top_bar .bar_right .bar_select label {
          background: inherit; } }

span.select2-dropdown {
  width: max-content !important;
  min-width: 100px !important; }

td.mobile_appreciations {
  padding: 0 !important;
  background: white; }
  td.mobile_appreciations table td {
    background: white !important;
    color: black !important; }
    td.mobile_appreciations table td.img {
      width: 10px !important;
      padding: 5px !important; }
  td.mobile_appreciations table a {
    top: 0 !important; }

.custom_checkbox_style {
  padding-top: 5px; }
  .custom_checkbox_style .pretty .state label {
    display: block;
    font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1b2437;
    margin-bottom: 5px; }
    .custom_checkbox_style .pretty .state label::after, .custom_checkbox_style .pretty .state label::before {
      top: 0; }

/************ Evaluation Sidebar *************/
body.side_opened .evaluation_with_sidebar .e_single_table table tr td:first-of-type {
  width: 62% !important; }

.evaluation_main_grid {
  display: flex;
  justify-content: space-between; }
  .evaluation_main_grid .evaluation-table-grid {
    flex-basis: 70%; }
  .evaluation_main_grid .evaluation-sidebar {
    flex-basis: 28%;
    background: #fff;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    position: relative;
    max-height: 676px; }
    .evaluation_main_grid .evaluation-sidebar .sidebar_sliding_close {
      height: 50px;
      width: 50px;
      line-height: 50px;
      position: absolute;
      font-weight: 500;
      background: #b1bfcd;
      right: -22px;
      text-align: right;
      padding-right: 8px;
      border-radius: 100px;
      top: 0;
      bottom: 0;
      z-index: -1;
      margin: auto;
      color: #fff; }
    .evaluation_main_grid .evaluation-sidebar .nav-tabs {
      border: 0; }
      .evaluation_main_grid .evaluation-sidebar .nav-tabs .nav-link {
        border-radius: 3px;
        border: 0px;
        font-size: 0.8rem;
        color: #1b2437;
        padding: 10px; }
        .evaluation_main_grid .evaluation-sidebar .nav-tabs .nav-link.active {
          background-color: #fff;
          box-shadow: 0 3px 17px 0 rgba(90, 202, 195, 0.5);
          background-image: linear-gradient(253deg, #72dcd6, #3eb6ad);
          color: #fff; }
        .evaluation_main_grid .evaluation-sidebar .nav-tabs .nav-link img {
          margin-right: 5px;
          margin-top: -2px; }
    .evaluation_main_grid .evaluation-sidebar .tab-content {
      padding: 20px !important; }
      .evaluation_main_grid .evaluation-sidebar .tab-content .single-statistics-block {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
        padding: 20px; }
        .evaluation_main_grid .evaluation-sidebar .tab-content .single-statistics-block .statistic-label {
          font-size: 0.9rem;
          font-weight: 600;
          text-transform: uppercase; }
          .evaluation_main_grid .evaluation-sidebar .tab-content .single-statistics-block .statistic-label span {
            display: block;
            font-size: 0.8rem;
            font-weight: normal;
            text-transform: none; }
        .evaluation_main_grid .evaluation-sidebar .tab-content .single-statistics-block .statistic-count {
          width: 45px;
          height: 45px;
          line-height: 45px;
          text-align: center;
          border-radius: 50%;
          font-size: 18px;
          font-weight: 500;
          color: #fff;
          background-image: linear-gradient(45deg, #3eb6ad, #72dcd6);
          box-shadow: 0 0 20px 0 rgba(90, 202, 195, 0.5); }
          .evaluation_main_grid .evaluation-sidebar .tab-content .single-statistics-block .statistic-count.orange_grad {
            background-image: linear-gradient(252deg, #ff867f, #ff5f04);
            box-shadow: 0 0px 24px 0 rgba(255, 134, 125, 0.43); }
  .evaluation_main_grid .evaluation-status-sidebar {
    padding: 10px; }
    .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .eval-status-header {
      padding-top: 10px;
      padding-left: 60px;
      position: relative;
      padding-bottom: 20px; }
      .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .eval-status-header button {
        background-image: linear-gradient(45deg, #3eb6ad, #72dcd6);
        box-shadow: 0 5px 20px 0 rgba(90, 202, 195, 0.5);
        border-radius: 50%;
        opacity: 1;
        color: #fff;
        font-size: 18px;
        font-weight: normal;
        height: 45px;
        width: 45px;
        padding: 0;
        line-height: 40px;
        outline: none;
        cursor: pointer;
        position: absolute;
        left: 0;
        border: 0;
        top: 0; }
      .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .eval-status-header h4 {
        font-size: 14px;
        color: #5a5a5a;
        width: 100%; }
    .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .bar_search .input-group {
      border-radius: 30px;
      overflow: hidden;
      border: 1px solid #b1bfcd; }
      .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .bar_search .input-group input {
        height: 1.7rem;
        border: none;
        border-radius: 0;
        background-color: #f8f8f8;
        font-size: 0.9rem; }
        .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .bar_search .input-group input:focus {
          box-shadow: none; }
        .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .bar_search .input-group input::placeholder {
          color: #b1bfcd;
          font-size: 0.9rem; }
      .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .bar_search .input-group button {
        border-radius: 0;
        border: none;
        box-shadow: none;
        background-color: #f8f8f8;
        padding: 0px 10px; }
    .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .bar_ham {
      margin: 0 1.4rem; }
    .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .eval_sidebar_top_select {
      display: flex;
      justify-content: space-between; }
      .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .eval_sidebar_top_select .bar_select label.input-group-text {
        border: none;
        background-color: #fff;
        font-size: 0.8rem;
        color: #c3c3c3;
        padding-right: 0; }
      .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .eval_sidebar_top_select .bar_select select {
        border: none;
        font-size: 0.8rem;
        font-weight: 600;
        box-shadow: none;
        padding: 0 13px 0 6px;
        background-position: right center;
        text-align: center; }
    .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .students-list-col {
      border-radius: 4px;
      padding: 0px 22px 0px 15px;
      margin-top: 30px;
      max-height: 430px; }
      .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .students-list-col .os-content-glue {
        height: 600px !important; }
      .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .students-list-col .single-students-list {
        margin-bottom: 20px; }
      .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .students-list-col .students-list-header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d6d9e0; }
        .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .students-list-col .students-list-header label {
          font-size: 14px;
          color: #5a5a5a;
          font-weight: 600; }
      .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .students-list-col .students-list-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(214, 217, 224, 0.2);
        padding: 9px 0; }
        .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .students-list-col .students-list-content label {
          font-size: 14px;
          color: #5a5a5a;
          font-weight: 500;
          margin-bottom: 0; }
        .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .students-list-col .students-list-content .student-list-left-con img {
          height: 30px;
          width: 30px;
          border-radius: 5px; }
        .evaluation_main_grid .evaluation-status-sidebar .evaluation-status-content .students-list-col .students-list-content .student-list-left-con label {
          margin-left: 15px; }

.btn {
  text-align: center;
  border: 1px solid #d1dbe2;
  cursor: pointer; }
  .btn.btn-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 0px !important;
    padding: 0px; }
    .btn.btn-circle * {
      font-size: 15px; }
    .btn.btn-circle.btn-sm, .btn-group-sm > .btn.btn-circle {
      width: 30px;
      height: 30px;
      line-height: 30px; }
    .btn.btn-circle.btn-xs {
      width: 20px;
      height: 20px;
      line-height: 20px; }
      .btn.btn-circle.btn-xs * {
        font-size: 13px !important; }
  .btn.btn-rounded {
    border-radius: 20px;
    padding: 0px 10px;
    font-size: 14px;
    font-weight: 600;
    height: 30px;
    min-width: 100px;
    color: #3eb6ad;
    background: #fff;
    border: 1px solid #d1dbe2; }
    .btn.btn-rounded:hover, .btn.btn-rounded.active {
      color: #3eb6ad;
      border: 1px solid #d1dbe2;
      background: #fff; }
  .btn.btn-yellow, .container-cms .item-list .item-block .btn.add_item, .item-list.list_books .item-block .btn.add_item {
    box-shadow: 0 18px 36px 0 rgba(239, 194, 91, 0.5);
    background-image: linear-gradient(225deg, #fdd271, #d5a433);
    border: none;
    color: #fff; }
    .btn.btn-yellow:hover, .container-cms .item-list .item-block .btn.add_item:hover, .item-list.list_books .item-block .btn.add_item:hover, .btn.btn-yellow.active, .container-cms .item-list .item-block .btn.active.add_item, .item-list.list_books .item-block .btn.active.add_item {
      box-shadow: 0 18px 36px 0 rgba(239, 194, 91, 0.9);
      color: #fff;
      border: none;
      background-image: linear-gradient(225deg, #fdd271, #d5a433); }
  .btn.btn-default {
    color: #3eb6ad;
    background: #fff;
    border: 1px solid #d1dbe2; }
    .btn.btn-default.btn-circle {
      border: 1px solid #3eb6ad !important; }
    .btn.btn-default:hover, .btn.btn-default.active {
      color: #3eb6ad;
      border: 1px solid #d1dbe2;
      background: #fff; }
  .btn.btn-danger {
    box-shadow: 0 10px 20px 0 rgba(220, 53, 69, 0.5);
    background-image: linear-gradient(225deg, #dc3545, #dc3545);
    color: #fff;
    border: none; }
    .btn.btn-danger:hover, .btn.btn-danger.active {
      box-shadow: 0 10px 20px 0 rgba(220, 53, 69, 0.5);
      background-image: linear-gradient(225deg, #dc3545, #dc3545);
      color: #fff;
      border: none; }
  .btn.btn-green, .btn.nav-link.active, .nav-exercises .btn.active-exercise, .nav-exercises .btn:hover {
    box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
    background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
    color: #fff;
    border: none; }
    .btn.btn-green:hover, .btn.nav-link.active:hover, .nav-exercises .btn:hover, .btn.btn-green.active, .btn.active.nav-link, .nav-exercises .btn.active.active-exercise, .nav-exercises .btn.active:hover {
      box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.9);
      background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
      color: #fff;
      border: none; }
  .btn.btn-green2 {
    height: 34px;
    border-radius: 17px;
    box-shadow: 4px 12px 14px 0 rgba(91, 128, 126, 0.12);
    background-image: linear-gradient(152deg, #3eb6ad 18%, #4acfc5 78%);
    color: #fff;
    border: none;
    font-size: 12px;
    font-weight: 600;
    text-align: center; }
    .btn.btn-green2:hover, .btn.btn-green2.active {
      background-image: linear-gradient(150deg, #2ea79e, #5dc5c0);
      color: #fff;
      border: none; }
  .btn.btn-default2 {
    height: 34px;
    border-radius: 17px;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5);
    background: #edf3f6;
    color: #3eb6ad;
    border: none;
    font-size: 12px;
    font-weight: 600;
    text-align: center; }
    .btn.btn-default2:hover, .btn.btn-default2.active {
      background-color: #e6f7ff;
      border: none; }
  .btn.btn-default3 {
    height: 34px;
    border-radius: 17px;
    box-shadow: 4px 12px 14px 0 rgba(91, 128, 126, 0.12);
    background: #edf3f6;
    border: solid 1px #43c0b7;
    color: #3db5ac;
    font-size: 12px;
    font-weight: 600;
    text-align: center; }
    .btn.btn-default3:hover, .btn.btn-default3.active {
      background-color: #e6f7ff; }
  .btn.btn-default-perp {
    height: 34px;
    border-radius: 17px;
    border: solid 1px #6170be;
    background-image: linear-gradient(93deg, rgba(226, 153, 232, 0.18) 18%, rgba(255, 255, 255, 0) 78%);
    color: #6170be;
    font-size: 12px;
    font-weight: 600;
    text-align: center; }
    .btn.btn-default-perp:hover, .btn.btn-default-perp.active {
      box-shadow: 0px 0px 2px 0 black;
      color: #6170be;
      border: solid 1px #6170be;
      background-image: linear-gradient(93deg, rgba(226, 153, 232, 0.18) 18%, rgba(255, 255, 255, 0) 78%); }
  .btn.btn-gray {
    box-shadow: 0 10px 20px 0 rgba(210, 211, 211, 0.5);
    background-image: linear-gradient(225deg, #e4e8ec, #b1bfcd);
    color: #fff;
    border: 1px solid transparent; }
    .btn.btn-gray:hover, .btn.btn-gray.active {
      box-shadow: 0 10px 20px 0 rgba(210, 211, 211, 0.9);
      color: #fff;
      border: 1px solid transparent;
      background-image: linear-gradient(225deg, #e4e8ec, #b1bfcd); }
  .btn.btn-red {
    box-shadow: 0 10px 20px 0 rgba(251, 208, 185, 0.5);
    background-image: linear-gradient(140deg, #ffdac8, #ff6818);
    color: #fff;
    border: none; }
    .btn.btn-red:hover {
      box-shadow: 0 10px 20px 0 rgba(251, 208, 185, 0.9);
      color: #fff;
      border: none;
      background-image: linear-gradient(140deg, #ffdac8, #ff6818); }
  .btn.btn-red2 {
    height: 34px;
    border-radius: 17px;
    background-image: linear-gradient(140deg, #f66e7f, #ea3e5d);
    color: #fff;
    border: none; }
    .btn.btn-red2:hover {
      box-shadow: 0 0 20px 0 rgba(251, 208, 185, 0.9);
      color: #fff;
      border: none;
      background-image: linear-gradient(140deg, #f66e7f, #ea3e5d); }
  .btn.btn-close-modal {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 5; }
  .btn .close_ico {
    font-family: montserratRegular;
    font-size: 38px;
    font-weight: normal;
    line-height: 1;
    text-shadow: 0 1px 0 #fff; }

.text-green {
  color: #5acac3; }

.dropdown .btn-rounded {
  color: inherit;
  position: relative;
  line-height: 15px;
  padding: 5px 10px;
  width: 100%;
  text-align: left; }
  .dropdown .btn-rounded:hover {
    color: inherit; }
  .dropdown .btn-rounded:after {
    content: none; }
  .dropdown .btn-rounded i.fa {
    position: absolute;
    right: 5px;
    background: #cdd6dc;
    color: #fff;
    border-radius: 50%;
    padding: 2px;
    margin-top: -2px; }

.search_container {
  width: 100%;
  margin-top: 15px;
  display: flex;
  background-color: #f8f8f8;
  border: solid 0.5px #b1bfcd;
  border-radius: 10em;
  padding: 0.15em 0.8em 0.15em 0.1em; }
  .search_container input {
    width: 100%;
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0;
    vertical-align: top;
    padding-left: 12px;
    font-size: 13px; }

.no-border {
  border: none !important; }

.form-control {
  height: 30px; }
  .form-control.is-invalid {
    background: none; }

form .form-group {
  position: relative; }
  form .form-group .error_form {
    bottom: 0px;
    transform: translateY(-6px); }

form .error_form {
  position: absolute;
  left: 100%;
  bottom: 1rem;
  z-index: 1000;
  object-fit: contain;
  box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5);
  background-color: #ffffff;
  border-radius: 5px;
  padding: 5px 10px;
  width: 12vw; }
  form .error_form.left {
    left: 0px;
    transform: translateX(-100%); }
    form .error_form.left:after {
      transform: scaleX(-1);
      right: -0.8vw; }
  form .error_form.bottom {
    bottom: unset;
    left: 50%;
    transform: translateX(-50%);
    top: 100%; }
    form .error_form.bottom:after {
      right: 50%;
      transform: translateX(50%);
      bottom: 100%;
      border-color: transparent transparent #ffffff transparent; }
  form .error_form.top {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%); }
    form .error_form.top:after {
      right: 50%;
      transform: translateX(50%);
      bottom: -11px;
      border-color: #ffffff transparent transparent transparent; }
  form .error_form:after {
    content: "";
    position: absolute;
    right: 100%;
    margin-top: -6px;
    border-width: 0.4vw;
    border-style: solid;
    border-color: transparent #ffffff transparent transparent;
    bottom: 7px; }
  form .error_form span {
    opacity: 0.8;
    font-size: 13px;
    color: #f45656; }

label.btn {
  line-height: 30px; }
  label.btn input[type=file] {
    position: absolute; }

.bootstrap-select .btn.dropdown-toggle:after {
  vertical-align: .255em; }

.text_overflow, .container-cms .item-list .item-block .item_name, .container-cms .item-list .item-block .item_n, .container-cms .item-list .item-block .item_info, .item-list.list_books .item-block .item_name, .item-list.list_books .item-block .item_n, .item-list.list_books .item-block .item_info {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.statistics_exam .modal-dialog {
  max-width: 90%; }

.container-cms {
  /*height: calc(100vh - 200px);*/
  /*&.parent{
        margin-top: -30px !important;
        height: 100vh;
    }*/ }
  @media (min-width: 767px) {
    .container-cms {
      margin: 20px 20px !important; } }
  @media (min-width: 1200px) {
    .container-cms {
      margin: 20px 30px !important; } }
  @media (max-width: 767px) {
    .container-cms {
      height: calc( 100vh - 144px); } }
  .container-cms .optiscroll-vtrack, .container-cms .optiscroll-htrack {
    background: rgba(25, 86, 197, 0.3); }
  .container-cms .item-list {
    margin: 0px; }
    .container-cms .item-list * {
      font-size: 0.625vw; }
    .container-cms .item-list h4 {
      font-size: 1vw; }
    @media (min-width: 1200px) {
      .container-cms .item-list.items-8 .item-block {
        max-width: 11.8%;
        flex: 11.8%;
        margin: 0px 0.4% 12px 0.4%; }
        .container-cms .item-list.items-8 .item-block:nth-child(-n+1), .container-cms .item-list.items-8 .item-block:nth-child(8n+1) {
          margin-left: 0px; }
        .container-cms .item-list.items-8 .item-block:nth-child(7n+1):not(:first-child) {
          margin-right: 0px; } }
    @media (min-width: 768px) and (max-width: 1200px) {
      .container-cms .item-list.items-8 .item-block {
        max-width: 19.2%;
        flex: 19.2%;
        margin: 0px 0.5% 12px 0.5%; }
        .container-cms .item-list.items-8 .item-block:nth-child(-n+1), .container-cms .item-list.items-8 .item-block:nth-child(6n+1) {
          margin-left: 0px; }
        .container-cms .item-list.items-8 .item-block:nth-child(5n+1):not(:first-child) {
          margin-right: 0px; } }
    @media (max-width: 768px) {
      .container-cms .item-list.items-8 .item-block {
        max-width: 49%;
        flex: 49%;
        margin-right: 1%; } }
    @media (min-width: 1200px) {
      .container-cms .item-list.items-6 .item-block {
        max-width: 16%;
        flex: 16%;
        max-height: 239px;
        margin: 0px 0.4% 12px 0.4%; }
        .container-cms .item-list.items-6 .item-block:nth-child(6n+1) {
          margin-left: 0px; }
        .container-cms .item-list.items-6 .item-block:nth-child(6n) {
          margin-right: 0px; } }
    @media (min-width: 768px) and (max-width: 1200px) {
      .container-cms .item-list.items-6 .item-block {
        max-width: 23.5%;
        flex: 23.5%;
        margin: 0px 1% 12px 1%; }
        .container-cms .item-list.items-6 .item-block:nth-child(4n+1) {
          margin-left: 0px; }
        .container-cms .item-list.items-6 .item-block:nth-child(4n) {
          margin-right: 0px; } }
    @media (max-width: 768px) {
      .container-cms .item-list.items-6 .item-block {
        max-width: 49%;
        flex: 49%;
        margin: 0px 1% 12px 1%; }
        .container-cms .item-list.items-6 .item-block:nth-child(2n+1) {
          margin-left: 0px; }
        .container-cms .item-list.items-6 .item-block:nth-child(2n) {
          margin-right: 0px; } }
    .container-cms .item-list .item-block {
      min-height: 230px;
      object-fit: contain;
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0 5px 10px 0 rgba(46, 104, 208, 0.1);
      text-align: center;
      padding-top: 70px;
      margin-bottom: 25px;
      position: relative; }
      .container-cms .item-list .item-block .item-img {
        margin: auto; }
        .container-cms .item-list .item-block .item-img img {
          width: 80px;
          height: 80px;
          border-radius: 50%; }
          .container-cms .item-list .item-block .item-img img.noradius {
            box-shadow: 0 5px 10px 0 rgba(46, 104, 208, 0.1) !important; }
      .container-cms .item-list .item-block .item-img-journal {
        margin: auto; }
        .container-cms .item-list .item-block .item-img-journal img {
          width: 80px;
          height: 80px; }
          .container-cms .item-list .item-block .item-img-journal img.noradius {
            box-shadow: 0 5px 10px 0 rgba(46, 104, 208, 0.1) !important; }
      .container-cms .item-list .item-block.exam_item {
        padding-top: 40px; }
        .container-cms .item-list .item-block.exam_item.exam_parent_item {
          border: 1px solid red; }
        .container-cms .item-list .item-block.exam_item .stat_icon {
          position: absolute;
          top: 15px;
          right: 15px; }
          .container-cms .item-list .item-block.exam_item .stat_icon.mr {
            right: 40px; }
          .container-cms .item-list .item-block.exam_item .stat_icon i {
            color: #b6c3d0;
            font-size: 16px;
            cursor: pointer; }
        .container-cms .item-list .item-block.exam_item .item-img img {
          width: 120px; }
        .container-cms .item-list .item-block.exam_item .corrected {
          color: #5acac3;
          margin-top: 20px;
          display: block;
          font-weight: bold; }
        .container-cms .item-list .item-block.exam_item .not_passed {
          color: #e92727;
          margin-top: 20px;
          display: block;
          font-weight: bold; }
      .container-cms .item-list .item-block .btn-share-accept, .container-cms .item-list .item-block .btn-share-refuse {
        position: absolute;
        bottom: 5px;
        /*display:none;*/ }
      .container-cms .item-list .item-block .btn-share-accept {
        left: 25%; }
      .container-cms .item-list .item-block .btn-share-refuse {
        right: 25%; }
      .container-cms .item-list .item-block.challenge_item {
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background-color: #fff;
        padding: 0.5rem 0.7rem;
        border-radius: 5px;
        box-shadow: 0 5px 48px 0 rgba(46, 104, 208, 0.1);
        height: 255px; }
        .container-cms .item-list .item-block.challenge_item img.medal {
          width: 40%;
          height: 80px; }
        .container-cms .item-list .item-block.challenge_item .c_title {
          display: block;
          font-size: 0.85rem;
          font-weight: 600;
          margin-bottom: 0.2rem; }
        .container-cms .item-list .item-block.challenge_item .c_level {
          font-size: 0.8rem;
          display: block;
          width: 100%;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
          .container-cms .item-list .item-block.challenge_item .c_level .level {
            color: #b1bfcd; }
        .container-cms .item-list .item-block.challenge_item .grades {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          text-align: center;
          display: flex;
          flex-direction: column;
          font-size: 0.75rem;
          text-align: center; }
          .container-cms .item-list .item-block.challenge_item .grades.title {
            display: block; }
        .container-cms .item-list .item-block.challenge_item .all_btn {
          margin: 0.8rem 0; }
          .container-cms .item-list .item-block.challenge_item .all_btn .btn {
            text-transform: uppercase; }
        .container-cms .item-list .item-block.challenge_item .c_footer {
          width: 100%;
          text-align: right;
          position: absolute;
          bottom: 10px;
          padding: 0 0.5rem; }
          .container-cms .item-list .item-block.challenge_item .c_footer .c_btn button {
            font-size: 0.75rem; }
        .container-cms .item-list .item-block.challenge_item.all .all_btn, .container-cms .item-list .item-block.challenge_item.group .all_btn {
          margin: 0.8rem 0; }
          .container-cms .item-list .item-block.challenge_item.all .all_btn .btn, .container-cms .item-list .item-block.challenge_item.group .all_btn .btn {
            text-transform: uppercase;
            font-size: 0.75rem; }
        .container-cms .item-list .item-block.challenge_item.school .c_title {
          margin-top: 15px; }
        .container-cms .item-list .item-block.challenge_item.school .c_level {
          margin-top: 10px; }
        .container-cms .item-list .item-block.challenge_item.school .grades {
          margin-top: 15px; }
      .container-cms .item-list .item-block.exercise_item {
        padding-top: 50px;
        border-bottom: 5px solid transparent; }
        .container-cms .item-list .item-block.exercise_item.exercise_kls_item {
          padding-top: 20px; }
          .container-cms .item-list .item-block.exercise_item.exercise_kls_item .item_name {
            height: 115px;
            margin-top: 10px; }
        .container-cms .item-list .item-block.exercise_item .badge {
          position: absolute;
          top: 10px;
          left: 10px;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          line-height: 20px;
          padding: 0px; }
        .container-cms .item-list .item-block.exercise_item .item_name {
          white-space: pre-wrap;
          height: 40px;
          font-size: 11px; }
        .container-cms .item-list .item-block.exercise_item .exercise-img {
          width: 90px;
          height: 90px;
          border: 1px solid transparent;
          border-radius: 50%;
          margin: auto; }
          .container-cms .item-list .item-block.exercise_item .exercise-img img {
            width: 80px;
            height: 80px;
            margin-top: 4px; }
          .container-cms .item-list .item-block.exercise_item .exercise-img.act {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            box-shadow: 1px 1px 9px 1px #adabab;
            margin-bottom: 10px; }
            .container-cms .item-list .item-block.exercise_item .exercise-img.act img {
              width: 100%;
              height: 100%;
              padding: 0px;
              margin: 0px; }
        .container-cms .item-list .item-block.exercise_item.activity_theme_item .item_name {
          height: inherit !important; }
      .container-cms .item-list .item-block.asset_item {
        border-bottom: 5px solid transparent;
        padding-top: 60px; }
        .container-cms .item-list .item-block.asset_item .item_name {
          margin-top: 10px; }
        .container-cms .item-list .item-block.asset_item .item_namee {
          margin-top: 20px; }
          .container-cms .item-list .item-block.asset_item .item_namee:hover {
            display: block; }
        .container-cms .item-list .item-block.asset_item.selected {
          opacity: 0.5; }
      .container-cms .item-list .item-block.report_item {
        border-bottom: 5px solid transparent;
        padding-top: 60px; }
        .container-cms .item-list .item-block.report_item .report_icon {
          font-size: 30px;
          margin-bottom: 5px;
          color: #5acac3; }
          .container-cms .item-list .item-block.report_item .report_icon i {
            font-size: 30px; }
        .container-cms .item-list .item-block.report_item .report_date {
          margin-top: 10px;
          font-size: 13px; }
        .container-cms .item-list .item-block.report_item .report_object {
          margin-top: 5px;
          font-size: 13px; }
        .container-cms .item-list .item-block.report_item .report_creator {
          margin-top: 5px;
          font-size: 13px; }
      .container-cms .item-list .item-block.behavior_item {
        border-bottom: 5px solid transparent;
        padding-top: 60px; }
        .container-cms .item-list .item-block.behavior_item .behavior_icon {
          font-size: 30px;
          margin-bottom: 5px;
          color: #5acac3; }
          .container-cms .item-list .item-block.behavior_item .behavior_icon i {
            font-size: 30px; }
        .container-cms .item-list .item-block.behavior_item .behavior_date {
          margin-top: 10px;
          font-size: 13px; }
        .container-cms .item-list .item-block.behavior_item .behavior_object {
          margin-top: 5px;
          font-size: 13px; }
        .container-cms .item-list .item-block.behavior_item .behavior_creator {
          margin-top: 5px;
          font-size: 13px; }
        .container-cms .item-list .item-block.behavior_item .assurance_status {
          border-radius: 10px;
          color: white;
          font-weight: bold;
          margin: 10px; }
      .container-cms .item-list .item-block.folder_item .item_name {
        margin-top: 10px; }
      .container-cms .item-list .item-block.course_item, .container-cms .item-list .item-block.formation_item {
        padding-top: 40px; }
        .container-cms .item-list .item-block.course_item.notifred, .container-cms .item-list .item-block.formation_item.notifred {
          position: absolute;
          right: -7px;
          top: -7px;
          background: #d33f29;
          width: 20px;
          height: 20px;
          border-radius: 12px;
          font-size: 10px;
          font-weight: 500;
          line-height: 20px;
          color: black; }
        .container-cms .item-list .item-block.course_item .item_name, .container-cms .item-list .item-block.formation_item .item_name {
          white-space: pre-wrap;
          margin-top: 10px; }
      .container-cms .item-list .item-block.book_item {
        padding-top: 40px; }
        .container-cms .item-list .item-block.book_item.notifred {
          position: absolute;
          right: -7px;
          top: -7px;
          background: #d33f29;
          width: 20px;
          height: 20px;
          border-radius: 12px;
          font-size: 10px;
          font-weight: 500;
          line-height: 20px;
          color: black; }
        .container-cms .item-list .item-block.book_item .item_name {
          white-space: pre-wrap; }
      .container-cms .item-list .item-block .item_name {
        width: 90%;
        margin: auto;
        font-size: 0.875vw;
        font-weight: 600;
        line-height: 20px; }
      .container-cms .item-list .item-block .item_n {
        width: 90%;
        margin: auto;
        font-size: 10px;
        font-weight: 600; }
      .container-cms .item-list .item-block .item_info {
        position: absolute;
        bottom: 3px;
        left: 0px;
        width: 100%;
        padding: 0% 5%; }
        .container-cms .item-list .item-block .item_info.top {
          bottom: 17px; }
      .container-cms .item-list .item-block .add_item {
        width: 70px;
        height: 70px;
        line-height: 70px;
        font-size: 60px;
        margin-bottom: 20px; }

.item-list.list_books {
  margin: 0px; }
  .item-list.list_books * {
    font-size: 0.625vw; }
  .item-list.list_books h4 {
    font-size: 1vw; }
  .item-list.list_books .items-8 {
    width: 100%;
    display: flex; }
    @media (min-width: 1200px) {
      .item-list.list_books .items-8 .item-block {
        max-width: 11.8%;
        flex: 11.8%;
        margin: 0px 0.4% 12px 0.4%; }
        .item-list.list_books .items-8 .item-block:nth-child(-n+1), .item-list.list_books .items-8 .item-block:nth-child(8n+1) {
          margin-left: 0px; }
        .item-list.list_books .items-8 .item-block:nth-child(7n+1):not(:first-child) {
          margin-right: 0px; } }
    @media (min-width: 768px) and (max-width: 1200px) {
      .item-list.list_books .items-8 .item-block {
        max-width: 19.2%;
        flex: 19.2%;
        margin: 0px 0.5% 12px 0.5%; }
        .item-list.list_books .items-8 .item-block:nth-child(-n+1), .item-list.list_books .items-8 .item-block:nth-child(6n+1) {
          margin-left: 0px; }
        .item-list.list_books .items-8 .item-block:nth-child(5n+1):not(:first-child) {
          margin-right: 0px; } }
    @media (max-width: 768px) {
      .item-list.list_books .items-8 .item-block {
        max-width: 49%;
        flex: 49%;
        margin-right: 1%; } }
  .item-list.list_books .items-6 {
    width: 100%;
    display: flex; }
    @media (min-width: 1200px) {
      .item-list.list_books .items-6 .item-block {
        max-width: 16%;
        flex: 16%;
        max-height: 239px;
        margin: 0px 0.4% 12px 0.4%; }
        .item-list.list_books .items-6 .item-block:nth-child(6n+1) {
          margin-left: 0px; }
        .item-list.list_books .items-6 .item-block:nth-child(6n) {
          margin-right: 0px; } }
    @media (min-width: 768px) and (max-width: 1200px) {
      .item-list.list_books .items-6 .item-block {
        max-width: 23.5%;
        flex: 23.5%;
        margin: 0px 1% 12px 1%; }
        .item-list.list_books .items-6 .item-block:nth-child(4n+1) {
          margin-left: 0px; }
        .item-list.list_books .items-6 .item-block:nth-child(4n) {
          margin-right: 0px; } }
    @media (max-width: 768px) {
      .item-list.list_books .items-6 .item-block {
        max-width: 49%;
        flex: 49%;
        margin: 0px 1% 12px 1%; }
        .item-list.list_books .items-6 .item-block:nth-child(2n+1) {
          margin-left: 0px; }
        .item-list.list_books .items-6 .item-block:nth-child(2n) {
          margin-right: 0px; } }
  .item-list.list_books .item-block {
    min-height: 230px;
    object-fit: contain;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 5px 10px 0 rgba(46, 104, 208, 0.1);
    text-align: center;
    padding-top: 70px;
    margin-bottom: 25px;
    position: relative; }
    .item-list.list_books .item-block .item-img {
      margin: auto; }
      .item-list.list_books .item-block .item-img img {
        width: 80px;
        height: 80px;
        border-radius: 50%; }
        .item-list.list_books .item-block .item-img img.noradius {
          box-shadow: 0 5px 10px 0 rgba(46, 104, 208, 0.1) !important; }
    .item-list.list_books .item-block .item-img-journal {
      margin: auto; }
      .item-list.list_books .item-block .item-img-journal img {
        width: 80px;
        height: 80px; }
        .item-list.list_books .item-block .item-img-journal img.noradius {
          box-shadow: 0 5px 10px 0 rgba(46, 104, 208, 0.1) !important; }
    .item-list.list_books .item-block.exam_item {
      padding-top: 40px; }
      .item-list.list_books .item-block.exam_item .stat_icon {
        position: absolute;
        top: 15px;
        right: 15px; }
        .item-list.list_books .item-block.exam_item .stat_icon.mr {
          right: 40px; }
        .item-list.list_books .item-block.exam_item .stat_icon i {
          color: #b6c3d0;
          font-size: 16px;
          cursor: pointer; }
      .item-list.list_books .item-block.exam_item .item-img img {
        width: 120px; }
      .item-list.list_books .item-block.exam_item .corrected {
        color: #5acac3;
        margin-top: 20px;
        display: block;
        font-weight: bold; }
      .item-list.list_books .item-block.exam_item .not_passed {
        color: #e92727;
        margin-top: 20px;
        display: block;
        font-weight: bold; }
    .item-list.list_books .item-block .btn-share-accept, .item-list.list_books .item-block .btn-share-refuse {
      position: absolute;
      bottom: 5px;
      /*display:none;*/ }
    .item-list.list_books .item-block .btn-share-accept {
      left: 25%; }
    .item-list.list_books .item-block .btn-share-refuse {
      right: 25%; }
    .item-list.list_books .item-block.challenge_item {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      background-color: #fff;
      padding: 0.5rem 0.7rem;
      border-radius: 5px;
      box-shadow: 0 5px 48px 0 rgba(46, 104, 208, 0.1);
      height: 255px; }
      .item-list.list_books .item-block.challenge_item img.medal {
        width: 40%;
        height: 80px; }
      .item-list.list_books .item-block.challenge_item .c_title {
        display: block;
        font-size: 0.85rem;
        font-weight: 600;
        margin-bottom: 0.2rem; }
      .item-list.list_books .item-block.challenge_item .c_level {
        font-size: 0.8rem;
        display: block;
        width: 100%;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
        .item-list.list_books .item-block.challenge_item .c_level .level {
          color: #b1bfcd; }
      .item-list.list_books .item-block.challenge_item .grades {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        font-size: 0.75rem;
        text-align: center; }
        .item-list.list_books .item-block.challenge_item .grades.title {
          display: block; }
      .item-list.list_books .item-block.challenge_item .all_btn {
        margin: 0.8rem 0; }
        .item-list.list_books .item-block.challenge_item .all_btn .btn {
          text-transform: uppercase; }
      .item-list.list_books .item-block.challenge_item .c_footer {
        width: 100%;
        text-align: right;
        position: absolute;
        bottom: 10px;
        padding: 0 0.5rem; }
        .item-list.list_books .item-block.challenge_item .c_footer .c_btn button {
          font-size: 0.75rem; }
      .item-list.list_books .item-block.challenge_item.all .all_btn, .item-list.list_books .item-block.challenge_item.group .all_btn {
        margin: 0.8rem 0; }
        .item-list.list_books .item-block.challenge_item.all .all_btn .btn, .item-list.list_books .item-block.challenge_item.group .all_btn .btn {
          text-transform: uppercase;
          font-size: 0.75rem; }
      .item-list.list_books .item-block.challenge_item.school .c_title {
        margin-top: 15px; }
      .item-list.list_books .item-block.challenge_item.school .c_level {
        margin-top: 10px; }
      .item-list.list_books .item-block.challenge_item.school .grades {
        margin-top: 15px; }
    .item-list.list_books .item-block.exercise_item {
      padding-top: 50px;
      border-bottom: 5px solid transparent; }
      .item-list.list_books .item-block.exercise_item.exercise_kls_item {
        padding-top: 20px; }
        .item-list.list_books .item-block.exercise_item.exercise_kls_item .item_name {
          height: 115px;
          margin-top: 10px; }
      .item-list.list_books .item-block.exercise_item .badge {
        position: absolute;
        top: 10px;
        left: 10px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        line-height: 20px;
        padding: 0px; }
      .item-list.list_books .item-block.exercise_item .item_name {
        white-space: pre-wrap;
        height: 40px;
        font-size: 11px; }
      .item-list.list_books .item-block.exercise_item .exercise-img {
        width: 90px;
        height: 90px;
        border: 1px solid transparent;
        border-radius: 50%;
        margin: auto; }
        .item-list.list_books .item-block.exercise_item .exercise-img img {
          width: 80px;
          height: 80px;
          margin-top: 4px; }
        .item-list.list_books .item-block.exercise_item .exercise-img.act {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          box-shadow: 1px 1px 9px 1px #adabab;
          margin-bottom: 10px; }
          .item-list.list_books .item-block.exercise_item .exercise-img.act img {
            width: 90px;
            height: 90px;
            padding: 10px; }
      .item-list.list_books .item-block.exercise_item.activity_theme_item .item_name {
        height: inherit !important; }
    .item-list.list_books .item-block.asset_item {
      border-bottom: 5px solid transparent;
      padding-top: 60px; }
      .item-list.list_books .item-block.asset_item .item_name {
        margin-top: 10px; }
      .item-list.list_books .item-block.asset_item .item_namee {
        margin-top: 20px; }
        .item-list.list_books .item-block.asset_item .item_namee:hover {
          display: block; }
      .item-list.list_books .item-block.asset_item.selected {
        opacity: 0.5; }
    .item-list.list_books .item-block.report_item {
      border-bottom: 5px solid transparent;
      padding-top: 60px; }
      .item-list.list_books .item-block.report_item .report_icon {
        font-size: 30px;
        margin-bottom: 5px;
        color: #5acac3; }
        .item-list.list_books .item-block.report_item .report_icon i {
          font-size: 30px; }
      .item-list.list_books .item-block.report_item .report_date {
        margin-top: 10px;
        font-size: 13px; }
      .item-list.list_books .item-block.report_item .report_object {
        margin-top: 5px;
        font-size: 13px; }
      .item-list.list_books .item-block.report_item .report_creator {
        margin-top: 5px;
        font-size: 13px; }
    .item-list.list_books .item-block.behavior_item {
      border-bottom: 5px solid transparent;
      padding-top: 60px; }
      .item-list.list_books .item-block.behavior_item .behavior_icon {
        font-size: 30px;
        margin-bottom: 5px;
        color: #5acac3; }
        .item-list.list_books .item-block.behavior_item .behavior_icon i {
          font-size: 30px; }
      .item-list.list_books .item-block.behavior_item .behavior_date {
        margin-top: 10px;
        font-size: 13px; }
      .item-list.list_books .item-block.behavior_item .behavior_object {
        margin-top: 5px;
        font-size: 13px; }
      .item-list.list_books .item-block.behavior_item .behavior_creator {
        margin-top: 5px;
        font-size: 13px; }
      .item-list.list_books .item-block.behavior_item .assurance_status {
        border-radius: 10px;
        color: white;
        font-weight: bold;
        margin: 10px; }
    .item-list.list_books .item-block.folder_item .item_name {
      margin-top: 10px; }
    .item-list.list_books .item-block.course_item, .item-list.list_books .item-block.formation_item {
      padding-top: 40px; }
      .item-list.list_books .item-block.course_item.notifred, .item-list.list_books .item-block.formation_item.notifred {
        position: absolute;
        right: -7px;
        top: -7px;
        background: #d33f29;
        width: 20px;
        height: 20px;
        border-radius: 12px;
        font-size: 10px;
        font-weight: 500;
        line-height: 20px;
        color: black; }
      .item-list.list_books .item-block.course_item .item_name, .item-list.list_books .item-block.formation_item .item_name {
        white-space: pre-wrap;
        margin-top: 10px; }
    .item-list.list_books .item-block.book_item {
      padding-top: 40px; }
      .item-list.list_books .item-block.book_item.notifred {
        position: absolute;
        right: -7px;
        top: -7px;
        background: #d33f29;
        width: 20px;
        height: 20px;
        border-radius: 12px;
        font-size: 10px;
        font-weight: 500;
        line-height: 20px;
        color: black; }
      .item-list.list_books .item-block.book_item .item_name {
        white-space: pre-wrap; }
    .item-list.list_books .item-block .item_name {
      width: 90%;
      margin: auto;
      font-size: 0.875vw;
      font-weight: 600; }
    .item-list.list_books .item-block .item_n {
      width: 90%;
      margin: auto;
      font-size: 10px;
      font-weight: 600; }
    .item-list.list_books .item-block .item_info {
      position: absolute;
      bottom: 3px;
      left: 0px;
      width: 100%;
      padding: 0% 5%; }
      .item-list.list_books .item-block .item_info.top {
        bottom: 17px; }
    .item-list.list_books .item-block .add_item {
      width: 70px;
      height: 70px;
      line-height: 70px;
      font-size: 60px;
      margin-bottom: 20px; }

@media (max-width: 767.98px) {
  .custom_modal .modal-dialog {
    height: calc(100% - 1rem); }
    .custom_modal .modal-dialog .modal-content {
      height: 100%; } }

.responsive_modal .modal-dialog {
  height: 80%; }
  .responsive_modal .modal-dialog .modal-content {
    height: 100%; }

.responsive_modal.concened_persons_discipline {
  z-index: 1111050;
  background: #7076839e; }

.custom_modal.simulate_modal .modal-dialog-centered {
  width: 1000px;
  max-width: 1000px !important; }

.custom_modal.bulletins_params > div {
  max-width: 400px !important; }

.custom_modal.diagnostic_test_report > div {
  max-width: 100% !important;
  width: 80%;
  height: 90%; }
  .custom_modal.diagnostic_test_report > div .modal-content {
    height: 100%; }
    .custom_modal.diagnostic_test_report > div .modal-content > div {
      overflow: hidden; }

.custom_modal.diagnostic_test_report_students > div {
  max-width: 500px !important;
  width: 500px;
  height: 90%; }
  .custom_modal.diagnostic_test_report_students > div .modal-content {
    height: 100%; }
    .custom_modal.diagnostic_test_report_students > div .modal-content > div {
      overflow: hidden; }

.custom_modal.top_modal {
  z-index: 100000; }

.custom_modal.math_keyboard {
  z-index: 100000000000; }

.custom_modal .modal-content {
  border: 0px;
  border-radius: 20px;
  box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1); }

.custom_modal h2 {
  font-size: 18px;
  font-weight: 500;
  margin: 0 0 28px; }

.custom_modal .modal-dialog-centered {
  height: 80%; }

.custom_modal.modal-preschool {
  z-index: 100000 !important; }

.custom_modal.full-width .modal-dialog-centered {
  max-width: 90%; }
  .custom_modal.full-width .modal-dialog-centered .modal-content {
    height: 100%; }

.custom_modal.add_exam2 .modal-body {
  overflow: initial; }

.custom_modal.add_exam .modal-content, .custom_modal.statistics_exam .modal-content {
  height: 100%; }
  .custom_modal.add_exam .modal-content .modal-body, .custom_modal.statistics_exam .modal-content .modal-body {
    height: calc(100% - 75px); }
    .custom_modal.add_exam .modal-content .modal-body::-webkit-scrollbar-track, .custom_modal.statistics_exam .modal-content .modal-body::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5; }
    .custom_modal.add_exam .modal-content .modal-body::-webkit-scrollbar, .custom_modal.statistics_exam .modal-content .modal-body::-webkit-scrollbar {
      width: 5px;
      height: 3px;
      background-color: #F5F5F5; }
    .custom_modal.add_exam .modal-content .modal-body::-webkit-scrollbar-thumb, .custom_modal.statistics_exam .modal-content .modal-body::-webkit-scrollbar-thumb {
      background-color: #3eb6ad; }
    .custom_modal.add_exam .modal-content .modal-body form .school-ligne-classes .error_form, .custom_modal.statistics_exam .modal-content .modal-body form .school-ligne-classes .error_form {
      display: inline-block; }
    .custom_modal.add_exam .modal-content .modal-body form .school-ligne-classes .RadioLegend, .custom_modal.statistics_exam .modal-content .modal-body form .school-ligne-classes .RadioLegend {
      width: 30%;
      margin: 0 0 15px;
      float: left;
      display: flex; }
      .custom_modal.add_exam .modal-content .modal-body form .school-ligne-classes .RadioLegend > span, .custom_modal.statistics_exam .modal-content .modal-body form .school-ligne-classes .RadioLegend > span {
        padding: 0;
        margin-right: 5px; }
      .custom_modal.add_exam .modal-content .modal-body form .school-ligne-classes .RadioLegend legend, .custom_modal.statistics_exam .modal-content .modal-body form .school-ligne-classes .RadioLegend legend {
        margin-bottom: 0;
        line-height: 24px; }

@media screen and (max-width: 768px) {
  .custom_modal.add_exam, .custom_modal.statistics_exam {
    max-width: calc(var(--vw, 1vw) * 100);
    margin: 0; }
    .custom_modal.add_exam > div, .custom_modal.statistics_exam > div {
      max-width: calc(var(--vw, 1vw) * 100);
      height: calc(var(--vh, 1vh) * 100);
      margin: 0; }
      .custom_modal.add_exam > div .modal-content, .custom_modal.statistics_exam > div .modal-content {
        border-radius: 0; } }

.custom_modal.comment_content {
  z-index: 100000000000; }
  .custom_modal.comment_content > div {
    margin: auto;
    height: 50%;
    top: 25%; }
    .custom_modal.comment_content > div .modal-content {
      height: 100%; }

.custom_modal.edit_course_modal .modal-dialog {
  max-width: 700px; }
  .custom_modal.edit_course_modal .modal-dialog .modal-body {
    overflow-x: visible; }

.custom_modal.edit_course_modal .all_classes {
  width: 98%; }

.custom_modal.edit_purchase_modal .modal-dialog {
  max-width: 700px; }
  .custom_modal.edit_purchase_modal .modal-dialog .modal-body {
    overflow-x: visible; }

.custom_modal.edit_purchase_modal .all_classes {
  width: 98%; }

.custom_modal.edit_book_modal .modal-dialog {
  max-width: 800px; }
  .custom_modal.edit_book_modal .modal-dialog .modal-content {
    height: max-content;
    max-height: 100%;
    padding: 10px 2px; }
    .custom_modal.edit_book_modal .modal-dialog .modal-content .modal-body {
      max-height: calc(100% - 63px);
      height: max-content; }
      .custom_modal.edit_book_modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5; }
      .custom_modal.edit_book_modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar {
        width: 5px;
        height: 3px;
        background-color: #F5F5F5; }
      .custom_modal.edit_book_modal .modal-dialog .modal-content .modal-body::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }

.custom_modal.books_modal_list {
  z-index: 100000; }

.custom_modal.event_users_list {
  width: 1000px;
  max-width: 1000px !important;
  left: 50%;
  transform: translateX(-50%); }
  .custom_modal.event_users_list > div {
    max-width: 100%; }
    .custom_modal.event_users_list > div .user-search-container {
      display: flex;
      width: 100%;
      height: 34px;
      position: relative;
      margin-bottom: 10px;
      border-radius: 17px;
      border: 1px solid #cbd2da;
      font-size: 12px;
      padding: 0px 15px;
      background-color: white;
      box-shadow: none;
      -webkit-box-align: center;
      align-items: center; }
      .custom_modal.event_users_list > div .user-search-container input {
        width: max-content;
        height: 30px;
        flex: 1 1 0%;
        border-radius: 17px;
        border: transparent;
        font-size: 12px;
        padding-left: 0px;
        margin-left: 5px;
        background: transparent; }
    .custom_modal.event_users_list > div .row .col {
      z-index: 111; }
      .custom_modal.event_users_list > div .row .col label {
        margin: 0px;
        font-weight: bold;
        display: inline-block; }
      .custom_modal.event_users_list > div .row .col > div {
        width: 100%;
        z-index: 111; }
      .custom_modal.event_users_list > div .row .col .rs__menu-list {
        z-index: 111; }
        .custom_modal.event_users_list > div .row .col .rs__menu-list::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #F5F5F5;
          border-radius: 5px; }
        .custom_modal.event_users_list > div .row .col .rs__menu-list::-webkit-scrollbar {
          width: 10px;
          /*height: 3px;*/
          background-color: #F5F5F5;
          border-radius: 5px; }
        .custom_modal.event_users_list > div .row .col .rs__menu-list::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
          border-radius: 5px; }
        .custom_modal.event_users_list > div .row .col .rs__menu-list .rs__option {
          border-bottom: 1px dashed #ccc;
          cursor: pointer; }
        .custom_modal.event_users_list > div .row .col .rs__menu-list .rs__group .rs__group-heading {
          padding: 8px 12px; }
          .custom_modal.event_users_list > div .row .col .rs__menu-list .rs__group .rs__group-heading:hover {
            background-color: #86e6df4a;
            cursor: pointer; }
        .custom_modal.event_users_list > div .row .col .rs__menu-list .rs__group .rs__option {
          padding-left: 25px;
          cursor: pointer; }

.custom_modal.replacement_professors .modal-dialog {
  max-width: 1000px !important;
  width: 100% !important;
  overflow: hidden; }
  .custom_modal.replacement_professors .modal-dialog .modal-content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; }
  .custom_modal.replacement_professors .modal-dialog .modal-header {
    display: flex;
    flex-wrap: wrap; }
    .custom_modal.replacement_professors .modal-dialog .modal-header .title {
      width: 100%;
      text-align: center;
      margin-bottom: 15px; }
    .custom_modal.replacement_professors .modal-dialog .modal-header .header {
      width: 100%; }
      .custom_modal.replacement_professors .modal-dialog .modal-header .header .order_type .btn-default {
        color: inherit;
        background: #fff;
        border: 0px solid #d1dbe2; }
        .custom_modal.replacement_professors .modal-dialog .modal-header .header .order_type .btn-default i {
          margin-left: 5px; }
      .custom_modal.replacement_professors .modal-dialog .modal-header .header .dropdown-menu {
        padding: 8px; }
        .custom_modal.replacement_professors .modal-dialog .modal-header .header .dropdown-menu li {
          font-size: 12px;
          border-bottom: 1px solid #d8d8d8;
          margin-bottom: 2px;
          cursor: pointer; }
  .custom_modal.replacement_professors .modal-dialog .modal-body {
    height: calc(100% - 120px);
    background: white;
    border-radius: 15px;
    margin-bottom: 15px; }
  @media (max-width: 1000px) {
    .custom_modal.replacement_professors .modal-dialog {
      margin: 0;
      border-radius: 0; }
      .custom_modal.replacement_professors .modal-dialog .modal-content {
        border-radius: 0; }
        .custom_modal.replacement_professors .modal-dialog .modal-content .btn-close-modal {
          right: 10px; } }
  .custom_modal.replacement_professors .modal-dialog .list_professors_replacement {
    min-height: 200px;
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 55px);
    overflow-y: auto; }
    .custom_modal.replacement_professors .modal-dialog .list_professors_replacement::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5; }
    .custom_modal.replacement_professors .modal-dialog .list_professors_replacement::-webkit-scrollbar {
      width: 5px;
      height: 3px;
      background-color: #F5F5F5; }
    .custom_modal.replacement_professors .modal-dialog .list_professors_replacement::-webkit-scrollbar-thumb {
      background-color: #3eb6ad; }
  .custom_modal.replacement_professors .modal-dialog .actions_modal {
    margin-top: 15px; }

@media (max-width: 1000px) {
  .custom_modal.replacement_professors {
    width: 93%; } }

.custom_modal.replacement_classrooms .modal-dialog {
  max-width: 1000px !important;
  width: 100% !important;
  overflow: hidden; }
  .custom_modal.replacement_classrooms .modal-dialog .modal-content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%; }
  .custom_modal.replacement_classrooms .modal-dialog .modal-header {
    display: flex;
    flex-wrap: wrap; }
    .custom_modal.replacement_classrooms .modal-dialog .modal-header .title {
      width: 100%;
      text-align: center;
      margin-bottom: 15px; }
    .custom_modal.replacement_classrooms .modal-dialog .modal-header .header {
      width: 100%; }
      .custom_modal.replacement_classrooms .modal-dialog .modal-header .header .order_type .btn-default {
        color: inherit;
        background: #fff;
        border: 0px solid #d1dbe2; }
        .custom_modal.replacement_classrooms .modal-dialog .modal-header .header .order_type .btn-default i {
          margin-left: 5px; }
      .custom_modal.replacement_classrooms .modal-dialog .modal-header .header .dropdown-menu {
        padding: 8px; }
        .custom_modal.replacement_classrooms .modal-dialog .modal-header .header .dropdown-menu li {
          font-size: 12px;
          border-bottom: 1px solid #d8d8d8;
          margin-bottom: 2px;
          cursor: pointer; }
  .custom_modal.replacement_classrooms .modal-dialog .modal-body {
    height: calc(100% - 120px);
    background: white;
    border-radius: 15px;
    margin-bottom: 15px; }
  @media (max-width: 1000px) {
    .custom_modal.replacement_classrooms .modal-dialog {
      margin: 0;
      border-radius: 0; }
      .custom_modal.replacement_classrooms .modal-dialog .modal-content {
        border-radius: 0; }
        .custom_modal.replacement_classrooms .modal-dialog .modal-content .btn-close-modal {
          right: 10px; } }
  .custom_modal.replacement_classrooms .modal-dialog .list_classrooms_replacement {
    min-height: 200px;
    display: flex;
    flex-wrap: wrap;
    height: calc(100% - 55px);
    overflow-y: auto; }
    .custom_modal.replacement_classrooms .modal-dialog .list_classrooms_replacement::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5; }
    .custom_modal.replacement_classrooms .modal-dialog .list_classrooms_replacement::-webkit-scrollbar {
      width: 5px;
      height: 3px;
      background-color: #F5F5F5; }
    .custom_modal.replacement_classrooms .modal-dialog .list_classrooms_replacement::-webkit-scrollbar-thumb {
      background-color: #3eb6ad; }
  .custom_modal.replacement_classrooms .modal-dialog .actions_modal {
    margin-top: 15px; }

@media (max-width: 1000px) {
  .custom_modal.replacement_classrooms {
    width: 93%; } }

.fixed_modal .modal-dialog {
  height: 90%; }
  .fixed_modal .modal-dialog .modal-content {
    height: 100%; }
    .fixed_modal .modal-dialog .modal-content .modal-body {
      overflow: auto;
      display: flex;
      flex-wrap: wrap; }

.modal-body {
  padding: 2rem; }

.gray_div {
  border-radius: 8px;
  background-color: #fcfcfd;
  border: solid 0.6px #d1dbe2;
  padding: 1.5em; }

.arabic_keyboard_modal {
  width: fit-content !important;
  height: fit-content !important; }
  .arabic_keyboard_modal .modal-dialog {
    margin: 0px; }
    .arabic_keyboard_modal .modal-dialog .modal-content {
      background: transparent;
      border: none; }
    .arabic_keyboard_modal .modal-dialog .modal-body {
      padding: 0px; }

.fullscreen_modal {
  padding: 0px !important;
  overflow-y: hidden !important; }
  .fullscreen_modal .modal-dialog {
    max-width: 100vw;
    height: 100%;
    margin: 0px; }
    .fullscreen_modal .modal-dialog .modal-content {
      width: 100vw;
      height: 100%;
      border-radius: 0px; }
  .fullscreen_modal.zzz_index {
    z-index: 99999; }

@media (max-width: 768px) {
  .fullscreen_modal_xs {
    padding: 0px !important;
    overflow-y: hidden !important; }
    .fullscreen_modal_xs .modal-dialog {
      max-width: 100vw;
      height: 100%;
      margin: 0px; }
      .fullscreen_modal_xs .modal-dialog .modal-content {
        width: 100vw;
        height: 100%;
        border-radius: 0px; } }

.providers_payments .modal-content {
  height: 100%; }

.internal_calls_visits .modal-content {
  height: 100%; }
  .internal_calls_visits .modal-content div.error {
    color: red; }

.test_invitation .modal-content {
  height: 100%; }

.bootstrap-select.dropup .dropdown-toggle:after, .bootstrap-select .dropdown-toggle:after {
  font: normal normal normal 14px/1 FontAwesome;
  border-radius: 50%;
  padding: 2px;
  background-color: #ccc;
  color: #fff;
  width: 18px;
  height: 18px;
  line-height: 15px;
  text-align: center;
  font-size: 18px;
  border: none !important;
  position: initial !important;
  margin: 0px !important;
  vertical-align: middle;
  content: '\f107'; }

.nav-exercises {
  flex-wrap: nowrap;
  display: flex;
  overflow: auto; }
  .nav-exercises::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5; }
  .nav-exercises::-webkit-scrollbar {
    width: 5px;
    height: 3px;
    background-color: #F5F5F5; }
  .nav-exercises::-webkit-scrollbar-thumb {
    background-color: #3eb6ad; }

.nav-exercises .btn {
  position: relative;
  top: 100%;
  transform: translateY(-100%);
  margin: 0px 2px;
  height: 30px;
  width: 30px;
  min-width: 30px;
  padding: 0px; }

.dragandrophandler {
  background-image: none;
  border: 2px dotted #b1c1cf;
  width: 100%;
  color: #92AAB0;
  text-align: left;
  vertical-align: middle;
  text-align: center;
  padding: 15px 0;
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  position: relative; }
  .dragandrophandler strong {
    color: #303c54; }
  .dragandrophandler input {
    position: absolute;
    opacity: 0;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px; }

.modal_create_groupe .modal-dialog {
  max-width: 620px; }
  .modal_create_groupe .modal-dialog .modal-header {
    padding-bottom: 20px; }
  .modal_create_groupe .modal-dialog .date_start_end {
    display: flex; }
    .modal_create_groupe .modal-dialog .date_start_end .date__challenge {
      width: 49%; }
      .modal_create_groupe .modal-dialog .date_start_end .date__challenge:first-of-type {
        margin-right: 2%; }
  @media (max-width: 1199px) {
    .modal_create_groupe .modal-dialog {
      margin: 0 auto; }
      .modal_create_groupe .modal-dialog .modal-content {
        border-radius: 0; } }
  @media (max-width: 767px) {
    .modal_create_groupe .modal-dialog {
      margin: 0 auto;
      height: calc(100%); } }

@keyframes lds-spinner {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

body > .chapter_sortable {
  display: block;
  min-height: 190px;
  margin-top: 10px;
  /* height: 156px; */
  object-fit: contain;
  border-radius: 10px;
  border: solid 1px #d1dbe2;
  background-color: #FFF;
  border-bottom: 6px solid #f2985a;
  padding: 6px 20px 0;
  position: relative;
  z-index: 11111111; }
  body > .chapter_sortable > div {
    background: transparent;
    border: 0;
    box-shadow: none; }
  body > .chapter_sortable .img {
    width: 96px;
    height: 96px;
    display: block;
    text-align: center;
    line-height: 96px;
    margin: auto; }
    body > .chapter_sortable .img img {
      width: 96px;
      height: 96px; }
  body > .chapter_sortable h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: normal;
    text-align: center;
    color: #1b2437;
    margin: 16px 0 0; }

body > .top-comp {
  display: block;
  margin-top: 6px;
  padding-left: 25px;
  object-fit: contain;
  margin-left: 20px;
  position: relative; }
  body > .top-comp i {
    cursor: pointer;
    top: 50%;
    position: absolute;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #adadad; }
  body > .top-comp h4 {
    float: left;
    opacity: 0.5;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    -webkit-letter-spacing: normal;
    -moz-letter-spacing: normal;
    -ms-letter-spacing: normal;
    letter-spacing: normal;
    color: #1b2437;
    margin: 0;
    margin-left: 20px; }

body > tr.competency {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit; }
  body > tr.competency td {
    line-height: 30px;
    border-right: 1px solid #d1dbe2;
    border-bottom: 1px solid #d1dbe2;
    padding: 1px 4px;
    color: #adadad;
    background-color: #FFF;
    font-size: 0.8rem;
    height: 47px;
    min-width: 50px;
    position: relative; }
    body > tr.competency td.m_w_20_p {
      min-width: 300px;
      width: 20%; }
    body > tr.competency td.b_t {
      border-top: 1px solid #d1dbe2 !important; }
    body > tr.competency td.b_l {
      border-left: 1px solid #d1dbe2 !important; }
    body > tr.competency td.b_b {
      border-bottom: 1px solid #d1dbe2 !important; }
    body > tr.competency td.b_r {
      border-right: 1px solid #d1dbe2 !important; }
    body > tr.competency td .competency_name_container {
      display: inline-block;
      width: calc(100% - 13px); }
    body > tr.competency td > div {
      padding: 6px;
      background: transparent; }
    body > tr.competency td:not(:first-child) {
      text-align: center;
      color: #333;
      font-weight: bold;
      width: inherit; }
    body > tr.competency td.d-md-table-cell {
      display: table-cell !important; }
    body > tr.competency td .custom-select {
      position: relative;
      color: rgba(0, 0, 0, 0.87);
      cursor: text;
      display: inline-flex;
      font-size: 1rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      line-height: 1.1875em;
      align-items: center;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      font-weight: 400;
      vertical-align: middle;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      appearance: none; }

body > li {
  display: block; }
  body > li > div {
    width: 100%;
    visibility: visible !important;
    opacity: 1 !important;
    display: flex;
    justify-content: space-between;
    border: 1px solid #b0becc;
    padding: 5px;
    margin-bottom: 15px;
    border-radius: 10px;
    box-shadow: 5px 5px 15px -2px;
    line-height: 27px; }
    body > li > div .exercice_name {
      display: flex; }
      body > li > div .exercice_name .exercice_logo {
        margin-right: 10px; }
        body > li > div .exercice_name .exercice_logo img {
          width: 30px;
          height: 30px; }
    body > li > div .actions {
      display: none;
      padding: 0;
      width: 100px;
      display: flex;
      justify-content: space-between; }
      body > li > div .actions i {
        padding: 8px;
        cursor: pointer; }
  body > li.field_drag {
    z-index: 99999999;
    position: relative;
    width: max-content;
    display: flex;
    min-width: max-content;
    padding: 5px 10px;
    border: 2px solid #d1dbe2;
    background: white;
    font-weight: bold;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 39px;
    position: relative; }
    body > li.field_drag .field {
      border: 0px solid #e2e3e4;
      box-shadow: none;
      text-align: left;
      width: max-content;
      padding: 0;
      margin: 0; }
    body > li.field_drag i.input_checkbox {
      width: 24px;
      height: 24px;
      font-family: fontawesome,Montserrat, sans-serif;
      border: 1px solid;
      border-radius: 50%;
      padding: 3px;
      padding-right: 5px;
      font-size: 15px;
      color: #b7b6b6;
      position: relative; }
      body > li.field_drag i.input_checkbox.checked {
        color: white;
        background: #58a182; }
      body > li.field_drag i.input_checkbox::before {
        height: 20px;
        width: 20px;
        margin-top: -5px;
        position: absolute;
        left: 2px; }
    body > li.field_drag .fa-arrows {
      visibility: hidden; }
  body > li.item_tree {
    z-index: 99999999;
    position: relative; }
    body > li.item_tree .item_header {
      background-color: #f4f9ff;
      padding: 0.5vw 0.8vw !important;
      position: relative;
      cursor: pointer;
      text-align: left;
      font-size: 0.75vw;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; }
      body > li.item_tree .item_header img {
        margin-right: 3%;
        width: 2.19vw;
        height: 2.19vw; }
      body > li.item_tree .item_header .name-container {
        margin-right: 80px;
        display: inline-flex;
        flex: 1; }
        body > li.item_tree .item_header .name-container span {
          white-space: unset !important;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          display: -webkit-box;
          width: 100%;
          top: 22%;
          margin: auto; }
      body > li.item_tree .item_header i {
        top: 50%;
        right: 10px;
        position: absolute;
        transform: translateY(-50%);
        cursor: pointer;
        color: #fff;
        background-color: #d1dbe2;
        padding: 4px;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        text-align: center;
        font-size: 0.875vw;
        left: unset; }
    body > li.item_tree.chapter_item:not(.open) .child_items, body > li.item_tree.chapter_item:not(.open) .actions {
      display: none; }
    body > li.item_tree.chapter_item .actions {
      border-top: 1px solid #dee4ec;
      margin: -4px; }
      body > li.item_tree.chapter_item .actions .add_chapter_btn {
        border: none;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 6px;
        align-items: center;
        cursor: pointer;
        font-size: 2.19vw;
        display: flex;
        border-radius: 10px;
        flex-basis: 100%; }
        body > li.item_tree.chapter_item .actions .add_chapter_btn .btn-circle.btn-yellow, body > li.item_tree.chapter_item .actions .add_chapter_btn .container-cms .item-list .item-block .btn-circle.add_item, .container-cms .item-list .item-block body > li.item_tree.chapter_item .actions .add_chapter_btn .btn-circle.add_item, body > li.item_tree.chapter_item .actions .add_chapter_btn .item-list.list_books .item-block .btn-circle.add_item, .item-list.list_books .item-block body > li.item_tree.chapter_item .actions .add_chapter_btn .btn-circle.add_item {
          font-size: 2.19vw;
          margin: 2px 5px;
          min-width: 2.19vw;
          min-height: 2.19vw;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          -webkit-align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -webkit-justify-content: center;
          -ms-flex-pack: center;
          justify-content: center;
          box-shadow: 0 18px 36px 0 rgba(239, 194, 91, 0.5);
          background-image: linear-gradient(225deg, #fdd271, #d5a433);
          color: #fff;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          border: 0px !important;
          padding: 0px;
          font-weight: 400;
          text-align: center;
          vertical-align: middle;
          user-select: none;
          background-color: transparent; }
        body > li.item_tree.chapter_item .actions .add_chapter_btn .title {
          font-size: 0.875vw;
          font-weight: bold; }
    body > li.item_tree.chapter_item .item_header i.remove_xblock {
      right: 70px; }
    body > li.item_tree.chapter_item .item_header i.edit_xblock {
      right: 40px; }
    body > li.item_tree.chapter_item .item_header i.clone_xblock {
      right: 100px; }
    body > li.item_tree.chapter_item .item_header i.sort_item {
      right: 130px; }
    body > li.item_tree.slide_item .item_header i.remove_xblock {
      right: 72px; }
    body > li.item_tree.slide_item .item_header i.edit_xblock {
      right: 42px; }
    body > li.item_tree.slide_item .item_header i.clone_xblock {
      right: 102px; }
    body > li.item_tree.slide_item .item_header i.sort_item {
      right: 132px; }
  body > li.subject_details_sort > div {
    background: transparent;
    border: 0;
    box-shadow: none; }
  body > li.payment_type_sort > div {
    background: transparent;
    border: 0;
    box-shadow: none; }
  body > li .stepTbl {
    width: 100%;
    position: relative;
    border-bottom: solid 0.6px #d1dbe2;
    padding-top: 5px;
    opacity: 1 !important;
    height: 37px;
    background-color: #ffffff; }
    body > li .stepTbl .name {
      width: calc(100% - 100px);
      text-align: left;
      margin-top: 5px;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      -webkit-letter-spacing: normal;
      -moz-letter-spacing: normal;
      -ms-letter-spacing: normal;
      letter-spacing: normal;
      color: #1b2437;
      display: inline-block;
      vertical-align: middle;
      padding: 0 3px;
      height: 100%; }
    body > li .stepTbl .actions {
      display: inline-block;
      width: 100px; }
      body > li .stepTbl .actions span {
        margin-left: 0px; }
        body > li .stepTbl .actions span i {
          color: #a1a9b3;
          font-size: 18px;
          cursor: pointer; }

body > .survey_item_choice {
  display: flex;
  position: relative;
  z-index: 99999999;
  padding-left: 15px; }
  body > .survey_item_choice .fa-arrows {
    display: block;
    position: absolute;
    left: 0;
    cursor: pointer;
    top: 50%;
    transform: translate(-15%, -50%);
    height: 100%;
    line-height: 35px;
    font-size: 14px;
    color: #bdbdbd; }
  body > .survey_item_choice .survey_item_choice_label {
    margin-bottom: 7px;
    padding: 0 !important;
    width: 100%; }
    body > .survey_item_choice .survey_item_choice_label input {
      font-size: 20px;
      height: inherit;
      float: left;
      width: 100%;
      border-radius: 2.6px;
      border: solid 0.6px #d1dbe2;
      background-color: rgba(245, 248, 249, 0.3);
      font-weight: 400;
      color: #1b2437 !important; }
    body > .survey_item_choice .survey_item_choice_label .error {
      display: none; }
  body > .survey_item_choice .survey_questions_type_icon {
    width: 20px;
    height: 100%;
    margin-right: 5px; }
    body > .survey_item_choice .survey_questions_type_icon i {
      font-size: 20px;
      line-height: 35px;
      color: #bdbdbd; }
  body > .survey_item_choice .remove_survey_item_choice {
    width: 20px;
    height: 100%;
    margin-left: 5px; }
    body > .survey_item_choice .remove_survey_item_choice i {
      font-size: 20px;
      line-height: 35px;
      color: #bdbdbd;
      cursor: pointer; }

body > .survey_part {
  border-radius: 10px;
  border-left: 5px solid transparent;
  position: relative;
  background: white;
  padding: 15px 2px;
  padding-top: 20px;
  z-index: 99999999; }
  body > .survey_part .error {
    display: none; }
  body > .survey_part .fa-arrows.item {
    display: block;
    position: absolute;
    left: 50%;
    cursor: pointer;
    top: 0%;
    transform: translate(-50%, -15%);
    line-height: 35px;
    font-size: 14px;
    color: #bdbdbd; }
  body > .survey_part .survey_actions_list {
    display: none;
    position: absolute;
    right: 10px;
    top: 20px;
    width: 40px;
    padding: 5px;
    border-radius: 5px;
    background: #f0ebf8; }
    body > .survey_part .survey_actions_list > div {
      width: 30px;
      height: 30px;
      text-align: center;
      margin-bottom: 5px; }
      body > .survey_part .survey_actions_list > div span {
        display: block;
        border-radius: 50%;
        border: 2px solid gray;
        color: gray;
        padding: 3px;
        cursor: pointer;
        font-weight: bold;
        font-size: 21px;
        line-height: 20px; }
  body > .survey_part .survey_item_footer {
    display: none;
    justify-content: end;
    float: left;
    padding: 10px 15px;
    width: 100%; }
    body > .survey_part .survey_item_footer > div {
      margin-left: 10px; }
      body > .survey_part .survey_item_footer > div i {
        cursor: pointer;
        color: gray; }
  body > .survey_part.focused {
    border-left: 5px solid #efc15b;
    padding-right: 50px; }
    body > .survey_part.focused .survey_actions_list {
      display: block; }
    body > .survey_part.focused .survey_item_footer {
      display: flex; }
  body > .survey_part > .survey_item_name {
    margin-bottom: 7px;
    width: 100%;
    padding: 0 12px; }
    body > .survey_part > .survey_item_name input, body > .survey_part > .survey_item_name textarea {
      float: left;
      width: 100%;
      height: 28px;
      border-radius: 2.6px;
      border: solid 0.6px #d1dbe2;
      background-color: rgba(245, 248, 249, 0.3);
      font-size: 14px;
      font-weight: 400;
      color: #1b2437 !important; }
    body > .survey_part > .survey_item_name input {
      font-size: 20px;
      height: inherit;
      width: 100%;
      padding: 0 12px; }
  body > .survey_part .name_img .survey_item_name {
    margin-bottom: 7px;
    width: 100%;
    padding: 0 12px; }
    body > .survey_part .name_img .survey_item_name input, body > .survey_part .name_img .survey_item_name textarea {
      float: left;
      width: 100%;
      height: 28px;
      border-radius: 2.6px;
      border: solid 0.6px #d1dbe2;
      background-color: rgba(245, 248, 249, 0.3);
      font-size: 14px;
      font-weight: 400;
      color: #1b2437 !important; }
    body > .survey_part .name_img .survey_item_name input {
      font-size: 25px;
      font-weight: bold;
      height: inherit;
      width: 100%;
      padding: 0 12px; }
  body > .survey_part .name_img .survey_item_image_select {
    height: 100%;
    line-height: 40px;
    padding-right: 10px; }
    body > .survey_part .name_img .survey_item_image_select > i {
      cursor: pointer;
      color: gray;
      height: 100%;
      font-size: 20px; }
  body > .survey_part .survey_item_image {
    width: 100%;
    height: 300px;
    margin: 16px 0;
    display: flex; }
    body > .survey_part .survey_item_image.legend {
      margin-bottom: 50px; }
    body > .survey_part .survey_item_image > div {
      width: max-content;
      height: max-content;
      max-width: 100%;
      max-height: 100%;
      position: relative; }
      body > .survey_part .survey_item_image > div > .action {
        display: none; }
      body > .survey_part .survey_item_image > div img {
        max-width: 100%;
        max-height: 300px;
        width: 100%; }
    body > .survey_part .survey_item_image .legend_image {
      position: absolute;
      top: 102%;
      left: 0;
      width: max-content;
      min-width: 100%; }
  body > .survey_part .survey_item_description {
    margin-bottom: 7px; }
    body > .survey_part .survey_item_description textarea {
      height: 40px;
      float: left;
      width: 100%;
      border-radius: 2.6px;
      border: solid 0.6px #d1dbe2;
      background-color: rgba(245, 248, 249, 0.3);
      font-size: 14px;
      font-weight: 400;
      color: #1b2437 !important; }
  body > .survey_part.question .name_img {
    display: flex; }
  body > .survey_part.paragraphe .name_img {
    display: flex; }
  body > .survey_part .survey_item_choices {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 12px; }
  body > .survey_part .survey_item_choice {
    display: flex;
    position: relative;
    flex-wrap: wrap; }
    body > .survey_part .survey_item_choice > div {
      display: flex;
      width: 100%; }
    body > .survey_part .survey_item_choice .fa-arrows {
      display: none;
      position: absolute;
      left: 0;
      cursor: pointer;
      top: 50%;
      -webkit-transform: translate(-15%, -50%);
      transform: translate(-15%, -50%);
      height: 100%;
      line-height: 35px;
      font-size: 14px;
      color: #bdbdbd; }
    body > .survey_part .survey_item_choice .survey_item_choice_label {
      margin-bottom: 7px;
      padding: 0 !important;
      width: 100%; }
      body > .survey_part .survey_item_choice .survey_item_choice_label input {
        float: left;
        width: 100%;
        height: 28px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        font-size: 14px;
        font-weight: 400;
        color: #1b2437 !important;
        font-size: 20px;
        height: inherit; }
    body > .survey_part .survey_item_choice .survey_questions_type_icon {
      width: 20px;
      height: 100%;
      margin-right: 5px; }
      body > .survey_part .survey_item_choice .survey_questions_type_icon i {
        font-size: 20px;
        line-height: 35px;
        color: #bdbdbd; }
    body > .survey_part .survey_item_choice .remove_survey_item_choice {
      width: 20px;
      height: 100%;
      margin-left: 5px; }
      body > .survey_part .survey_item_choice .remove_survey_item_choice i {
        font-size: 20px;
        line-height: 35px;
        color: #bdbdbd;
        cursor: pointer; }
    body > .survey_part .survey_item_choice .survey_item_choice_image {
      width: 100%;
      height: 100px;
      margin: 16px 0;
      display: flex; }
      body > .survey_part .survey_item_choice .survey_item_choice_image > div {
        width: max-content;
        height: max-content;
        max-width: 100%;
        max-height: 100%;
        position: relative; }
        body > .survey_part .survey_item_choice .survey_item_choice_image > div img {
          max-width: 100%;
          max-height: 100px;
          width: 100%; }
        body > .survey_part .survey_item_choice .survey_item_choice_image > div .remove_survey_item_choice_image {
          display: none; }
    body > .survey_part .survey_item_choice.add_options {
      flex-wrap: nowrap; }
      body > .survey_part .survey_item_choice.add_options .survey_item_choice_label {
        display: flex;
        justify-content: start;
        margin-bottom: 0;
        line-height: 30px; }
        body > .survey_part .survey_item_choice.add_options .survey_item_choice_label span {
          color: gray; }
          body > .survey_part .survey_item_choice.add_options .survey_item_choice_label span.add_option {
            color: #1a73e8;
            cursor: pointer;
            margin-right: 5px; }
          body > .survey_part .survey_item_choice.add_options .survey_item_choice_label span.add_other {
            color: #1a73e8;
            cursor: pointer;
            margin-left: 5px; }
  body > .survey_part.focused {
    padding-left: 8px; }
    body > .survey_part.focused .survey_item_choice {
      padding-left: 15px;
      margin-left: -15px; }

.w_600 {
  width: 600px !important; }

.w_500 {
  width: 500px !important; }

.m_t_80_im {
  margin-top: 80px !important; }

.h_100 {
  height: 100%; }
  .h_100 p.p_render {
    height: 100%;
    overflow-y: auto; }
  .h_100 .tox-toolbar__primary {
    width: 100%;
    padding-right: 20px; }

.modal_activities.modal_activities_preschools .modal-content {
  height: 100%; }

.modal_activities > div {
  max-width: 700px;
  border-radius: 15px;
  height: 90%;
  max-height: 90%; }
  .modal_activities > div .modal-content {
    border-radius: 15px;
    height: max-content;
    max-height: 100%;
    padding-bottom: 15px; }
    .modal_activities > div .modal-content .btn-close {
      z-index: 111; }
    .modal_activities > div .modal-content .loading {
      position: absolute;
      width: 100%;
      height: 100%; }
      .modal_activities > div .modal-content .loading .loading_img {
        position: absolute;
        width: 100%;
        height: 100%;
        background: black;
        z-index: 111;
        opacity: 0.2;
        border-radius: 10px; }
        .modal_activities > div .modal-content .loading .loading_img img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          max-width: 50%;
          max-height: 50%; }
    .modal_activities > div .modal-content > div {
      overflow: auto; }
      .modal_activities > div .modal-content > div::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5; }
      .modal_activities > div .modal-content > div::-webkit-scrollbar {
        width: 5px;
        height: 3px;
        background-color: #F5F5F5; }
      .modal_activities > div .modal-content > div::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
    .modal_activities > div .modal-content .modal-header > div {
      display: flex;
      width: 100%;
      justify-content: center; }
      .modal_activities > div .modal-content .modal-header > div button {
        margin-right: 0 !important;
        background: #00b3a9;
        background-image: none;
        position: absolute;
        top: -23px;
        right: -23px;
        border: 5px solid #fff !important;
        width: 40px;
        height: 40px;
        text-align: center;
        color: #fff;
        border-radius: 50%;
        font-size: 31px;
        line-height: 28px;
        box-shadow: none;
        background-image: none;
        font-weight: bold; }
        .modal_activities > div .modal-content .modal-header > div button > span {
          font-size: 35px !important;
          font-weight: normal !important;
          text-shadow: 0 1px 0 #fff !important;
          line-height: 30px;
          font-family: inherit; }
    .modal_activities > div .modal-content .modal-body {
      height: max-content;
      max-height: calc(100% - 56px);
      overflow: auto;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px; }
      .modal_activities > div .modal-content .modal-body::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5; }
      .modal_activities > div .modal-content .modal-body::-webkit-scrollbar {
        width: 5px;
        height: 3px;
        background-color: #F5F5F5; }
      .modal_activities > div .modal-content .modal-body::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
      .modal_activities > div .modal-content .modal-body .params .line {
        display: flex; }
        .modal_activities > div .modal-content .modal-body .params .line > div {
          width: 100%; }
        .modal_activities > div .modal-content .modal-body .params .line .textErrors {
          color: red; }
        .modal_activities > div .modal-content .modal-body .params .line .radioLevels {
          display: flex;
          flex-wrap: wrap; }
          .modal_activities > div .modal-content .modal-body .params .line .radioLevels .RadioLegend {
            width: 33%;
            display: flex; }
            .modal_activities > div .modal-content .modal-body .params .line .radioLevels .RadioLegend > span {
              padding: 0;
              margin-right: 5px;
              height: max-content; }
            .modal_activities > div .modal-content .modal-body .params .line .radioLevels .RadioLegend legend {
              margin-bottom: 0;
              padding-top: 3px; }
        .modal_activities > div .modal-content .modal-body .params .line.image > div {
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center; }
          .modal_activities > div .modal-content .modal-body .params .line.image > div img {
            max-width: 100px;
            max-height: 100px;
            margin: auto;
            border: 1px solid #b7b7b7;
            padding: 5px;
            border-radius: 5px; }
          .modal_activities > div .modal-content .modal-body .params .line.image > div span {
            width: max-content;
            margin: auto;
            background: #00b3a9;
            color: white;
            cursor: pointer;
            padding: 5px 10px;
            border-radius: 15px; }
        .modal_activities > div .modal-content .modal-body .params .line .react-datepicker-wrapper {
          margin-left: 0; }
        .modal_activities > div .modal-content .modal-body .params .line .auto_textarea textarea {
          padding: 5px;
          border-radius: 2.6px;
          border: 0.6px solid #d1dbe2;
          background-color: rgba(245, 248, 249, 0.3);
          font-size: 14px;
          font-weight: 400;
          color: #1b2437 !important;
          margin-top: 3px;
          width: 100%; }
      .modal_activities > div .modal-content .modal-body .params .templates .drag_div {
        position: relative;
        width: 100%;
        margin: 20px 0px; }
        .modal_activities > div .modal-content .modal-body .params .templates .drag_div .template {
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between;
          padding: 0px 5px; }
          .modal_activities > div .modal-content .modal-body .params .templates .drag_div .template .name {
            width: calc(100% - 15px);
            text-align: left;
            display: block; }
          .modal_activities > div .modal-content .modal-body .params .templates .drag_div .template .remove_template {
            color: red; }
      .modal_activities > div .modal-content .modal-body .params .video_url, .modal_activities > div .modal-content .modal-body .params .link_url {
        display: flex;
        flex-wrap: wrap; }
        .modal_activities > div .modal-content .modal-body .params .video_url label, .modal_activities > div .modal-content .modal-body .params .link_url label {
          margin-right: 10px;
          width: 80px; }
        .modal_activities > div .modal-content .modal-body .params .video_url input, .modal_activities > div .modal-content .modal-body .params .link_url input {
          width: calc(100% - 90px);
          margin-bottom: 10px; }
        .modal_activities > div .modal-content .modal-body .params .video_url button, .modal_activities > div .modal-content .modal-body .params .link_url button {
          margin: auto;
          margin-bottom: 10px; }
  .modal_activities > div .mb-36 {
    margin-bottom: 36px !important; }
  .modal_activities > div .fileUpload {
    display: inline-block;
    min-width: 105px;
    height: 30px;
    object-fit: contain;
    border-radius: 49.5px;
    text-align: center;
    font-family: Montserrat;
    line-height: 30px;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
    background-image: linear-gradient(54deg, #3eb6ad, #72dcd6);
    color: #fff;
    border: none;
    float: left;
    margin-bottom: 0px; }
    .modal_activities > div .fileUpload input {
      max-width: 95px;
      visibility: hidden;
      position: absolute;
      width: auto;
      z-index: 999;
      padding-left: 10px;
      float: left;
      height: 26.5px;
      border-radius: 2.6px;
      border: solid 0.6px #d1dbe2;
      background-color: rgba(245, 248, 249, 0.3);
      font-size: 14px;
      font-weight: 500; }
    .modal_activities > div .fileUpload i {
      margin-right: 3px;
      font-size: 15px; }

.modal_activities.modal_bibliotheque .modal-content {
  padding-bottom: 0px;
  border: 0; }

.modal_extra_scolaire_activities > div {
  min-width: 600px;
  width: 70%;
  max-width: 100%;
  border-radius: 35px;
  height: max-content;
  min-height: 500px;
  max-height: 90%; }
  .modal_extra_scolaire_activities > div .modal-content {
    border-radius: 30px;
    height: 100%;
    max-height: 100%;
    padding-bottom: 15px; }
    .modal_extra_scolaire_activities > div .modal-content .btn-close {
      z-index: 111; }
    .modal_extra_scolaire_activities > div .modal-content .loading {
      position: absolute;
      width: 100%;
      height: 100%; }
      .modal_extra_scolaire_activities > div .modal-content .loading .loading_img {
        position: absolute;
        width: 100%;
        height: 100%;
        background: black;
        z-index: 111;
        opacity: 0.2;
        border-radius: 10px; }
        .modal_extra_scolaire_activities > div .modal-content .loading .loading_img img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          max-width: 50%;
          max-height: 50%; }
    .modal_extra_scolaire_activities > div .modal-content .bootstrap-select > button.bs-placeholder > span.filter-option {
      color: black; }
    .modal_extra_scolaire_activities > div .modal-content > div {
      overflow: auto; }
      .modal_extra_scolaire_activities > div .modal-content > div::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5; }
      .modal_extra_scolaire_activities > div .modal-content > div::-webkit-scrollbar {
        width: 5px;
        height: 3px;
        background-color: #F5F5F5; }
      .modal_extra_scolaire_activities > div .modal-content > div::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
    .modal_extra_scolaire_activities > div .modal-content .modal-header {
      border-top-right-radius: 29px;
      border-top-left-radius: 29px; }
      .modal_extra_scolaire_activities > div .modal-content .modal-header > div {
        display: flex;
        width: 100%;
        justify-content: center; }
        .modal_extra_scolaire_activities > div .modal-content .modal-header > div button {
          margin-right: 0 !important;
          background: #00b3a9;
          background-image: none;
          position: absolute;
          top: -15px;
          right: -15px;
          border: 5px solid #fff !important;
          width: 40px;
          height: 40px;
          text-align: center;
          color: #fff;
          border-radius: 50%;
          font-size: 31px;
          line-height: 28px;
          box-shadow: none;
          background-image: none;
          font-weight: bold; }
          .modal_extra_scolaire_activities > div .modal-content .modal-header > div button > span {
            font-size: 35px !important;
            font-weight: normal !important;
            text-shadow: 0 1px 0 #fff !important;
            line-height: 30px;
            font-family: inherit; }
    .modal_extra_scolaire_activities > div .modal-content .modal-body {
      height: 100%;
      max-height: calc(100% - 56px);
      overflow: auto;
      padding: 30px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px; }
      .modal_extra_scolaire_activities > div .modal-content .modal-body::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5; }
      .modal_extra_scolaire_activities > div .modal-content .modal-body::-webkit-scrollbar {
        width: 5px;
        height: 3px;
        background-color: #F5F5F5; }
      .modal_extra_scolaire_activities > div .modal-content .modal-body::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
      .modal_extra_scolaire_activities > div .modal-content .modal-body .rows {
        height: 100%; }
        .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .col-md-4 {
          padding: 20px; }
        .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line {
          display: flex; }
          .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line > div {
            width: 100%; }
            .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line > div .checkbox-form {
              height: 42px; }
              .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line > div .checkbox-form .p-info > label {
                margin: 0; }
                .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line > div .checkbox-form .p-info > label:before {
                  top: 50% !important;
                  transform: translateY(-50%) !important; }
                .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line > div .checkbox-form .p-info > label:after {
                  top: 50% !important;
                  transform: translateY(-50%) scale(0.6) !important; }
                .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line > div .checkbox-form .p-info > label > label {
                  text-indent: 0;
                  margin-top: 12px; }
                  .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line > div .checkbox-form .p-info > label > label:before, .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line > div .checkbox-form .p-info > label > label::after {
                    content: "";
                    display: none; }
          .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line .textErrors {
            color: red; }
          .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line .dropdown-menu {
            max-height: 300px !important; }
          .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line .radioLevels {
            display: flex;
            flex-wrap: wrap; }
            .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line .radioLevels .RadioLegend {
              width: 33%;
              display: flex; }
              .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line .radioLevels .RadioLegend > span {
                padding: 0;
                margin-right: 5px;
                height: max-content; }
              .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line .radioLevels .RadioLegend legend {
                margin-bottom: 0;
                padding-top: 3px; }
          .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line.image > div {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center; }
            .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line.image > div img {
              max-width: 250px;
              max-height: 250px;
              width: 250px;
              height: 250px;
              margin: auto;
              border: 1px solid #b7b7b7;
              padding: 5px;
              border-radius: 50%;
              margin: auto; }
          .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line.browse_btn {
            margin-top: 25px; }
            .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line.browse_btn > div {
              text-align: center;
              display: flex;
              flex-direction: column;
              justify-content: center; }
              .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line.browse_btn > div span {
                width: max-content;
                margin: auto;
                background: #00b3a9;
                color: white;
                cursor: pointer;
                padding: 5px 10px;
                border-radius: 15px; }
          .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) {
            height: 90px; }
            .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div {
              width: 50%;
              margin: 0 10px; }
              .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div label {
                font-size: 20px; }
              .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div input {
                height: 42px;
                font-size: 20px; }
              .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div .selectInput {
                height: 42px;
                font-size: 20px; }
              .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div .react-datepicker-wrapper {
                height: 42px;
                font-size: 20px; }
                .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div .react-datepicker-wrapper .react-datepicker__input-container {
                  height: 42px;
                  font-size: 20px; }
                  .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div .react-datepicker-wrapper .react-datepicker__input-container:before {
                    line-height: 42px; }
                  .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div .react-datepicker-wrapper .react-datepicker__input-container input {
                    height: 42px;
                    font-size: 20px; }
              .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div .bootstrap-select {
                width: 100%;
                height: 42px; }
          .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line .react-datepicker-wrapper {
            margin-left: 0; }
          .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .line .auto_textarea textarea {
            padding: 5px;
            border-radius: 2.6px;
            border: 0.6px solid #d1dbe2;
            background-color: rgba(245, 248, 249, 0.3);
            font-size: 14px;
            font-weight: 400;
            color: #1b2437 !important;
            margin-top: 3px;
            width: 100%; }
        .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .templates .drag_div {
          position: relative;
          width: 100%;
          margin: 20px 0px; }
          .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .templates .drag_div .template {
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            padding: 0px 5px; }
            .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .templates .drag_div .template .name {
              width: calc(100% - 15px);
              text-align: left;
              display: block; }
            .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .templates .drag_div .template .remove_template {
              color: red; }
        .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .video_url, .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .link_url {
          display: flex;
          flex-wrap: wrap; }
          .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .video_url label, .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .link_url label {
            margin-right: 10px;
            width: 80px; }
          .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .video_url input, .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .link_url input {
            width: calc(100% - 90px);
            margin-bottom: 10px; }
          .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .video_url button, .modal_extra_scolaire_activities > div .modal-content .modal-body .rows .link_url button {
            margin: auto;
            margin-bottom: 10px; }
  .modal_extra_scolaire_activities > div .dropdown-menu.inner {
    padding-bottom: 15px !important; }

.modal_extra_scolaire_activities.modal_bibliotheque .modal-content {
  padding-bottom: 0px;
  border: 0; }

.modal_students_extra_scolaire_activities > div {
  min-width: 600px;
  width: 70%;
  max-width: 100%;
  border-radius: 35px;
  height: 90%;
  min-height: 500px;
  max-height: 90%; }
  .modal_students_extra_scolaire_activities > div .modal-content {
    border-radius: 30px;
    height: 100%;
    max-height: 100%;
    padding-bottom: 15px; }
    .modal_students_extra_scolaire_activities > div .modal-content .btn-close {
      z-index: 111; }
    .modal_students_extra_scolaire_activities > div .modal-content .loading {
      position: absolute;
      width: 100%;
      height: 100%; }
      .modal_students_extra_scolaire_activities > div .modal-content .loading .loading_img {
        position: absolute;
        width: 100%;
        height: 100%;
        background: black;
        z-index: 111;
        opacity: 0.2;
        border-radius: 10px; }
        .modal_students_extra_scolaire_activities > div .modal-content .loading .loading_img img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          max-width: 50%;
          max-height: 50%; }
    .modal_students_extra_scolaire_activities > div .modal-content > div {
      overflow: auto; }
      .modal_students_extra_scolaire_activities > div .modal-content > div::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5; }
      .modal_students_extra_scolaire_activities > div .modal-content > div::-webkit-scrollbar {
        width: 5px;
        height: 3px;
        background-color: #F5F5F5; }
      .modal_students_extra_scolaire_activities > div .modal-content > div::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
    .modal_students_extra_scolaire_activities > div .modal-content .modal-header {
      border-top-right-radius: 29px;
      border-top-left-radius: 29px; }
      .modal_students_extra_scolaire_activities > div .modal-content .modal-header > div {
        display: flex;
        width: 100%;
        justify-content: center; }
        .modal_students_extra_scolaire_activities > div .modal-content .modal-header > div button {
          margin-right: 0 !important;
          background: #00b3a9;
          background-image: none;
          position: absolute;
          top: -15px;
          right: -15px;
          border: 5px solid #fff !important;
          width: 40px;
          height: 40px;
          text-align: center;
          color: #fff;
          border-radius: 50%;
          font-size: 31px;
          line-height: 28px;
          box-shadow: none;
          background-image: none;
          font-weight: bold; }
          .modal_students_extra_scolaire_activities > div .modal-content .modal-header > div button > span {
            font-size: 35px !important;
            font-weight: normal !important;
            text-shadow: 0 1px 0 #fff !important;
            line-height: 30px;
            font-family: inherit; }
    .modal_students_extra_scolaire_activities > div .modal-content .modal-body {
      height: 100%;
      max-height: calc(100% - 56px);
      overflow: auto;
      padding: 30px;
      padding-bottom: 0;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px; }
      .modal_students_extra_scolaire_activities > div .modal-content .modal-body::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5; }
      .modal_students_extra_scolaire_activities > div .modal-content .modal-body::-webkit-scrollbar {
        width: 5px;
        height: 3px;
        background-color: #F5F5F5; }
      .modal_students_extra_scolaire_activities > div .modal-content .modal-body::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
      .modal_students_extra_scolaire_activities > div .modal-content .modal-body .filters.row {
        padding: 0 20px; }
        .modal_students_extra_scolaire_activities > div .modal-content .modal-body .filters.row label {
          margin-right: 5px; }
        .modal_students_extra_scolaire_activities > div .modal-content .modal-body .filters.row > div {
          margin-right: 15px;
          margin-bottom: 10px; }
          .modal_students_extra_scolaire_activities > div .modal-content .modal-body .filters.row > div button:after {
            position: absolute !important;
            top: 8px; }
          .modal_students_extra_scolaire_activities > div .modal-content .modal-body .filters.row > div > select {
            width: 220px;
            position: relative;
            display: inline-flex;
            vertical-align: middle;
            color: #3a3a3a;
            height: 38px;
            padding-right: 25px;
            z-index: 1;
            background: #fff;
            border: 1px solid #d1dbe2; }
      .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows {
        height: calc(100% - 60px); }
        .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .col-md-4 {
          padding: 20px; }
        .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .students_list {
          height: calc(100% - 60px);
          width: 100%;
          overflow-y: auto;
          padding-left: 20px; }
          .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .students_list::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #F5F5F5; }
          .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .students_list::-webkit-scrollbar {
            width: 5px;
            height: 3px;
            background-color: #F5F5F5; }
          .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .students_list::-webkit-scrollbar-thumb {
            background-color: #3eb6ad; }
          .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .students_list .students_list_table {
            width: 100%; }
            .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .students_list .students_list_table tr.is_child_of_staff {
              background-color: #ffe599; }
            .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .students_list .students_list_table tr.header th {
              background-color: #e7e6e6;
              border: 1px solid #7f7f7f;
              text-align: center;
              font-size: 18px;
              padding: 5px; }
              .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .students_list .students_list_table tr.header th.order {
                border: 0 solid #7f7f7f;
                background-color: transparent; }
            .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .students_list .students_list_table tr.header.classes {
              background-color: #e7e6e6; }
              .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .students_list .students_list_table tr.header.classes th {
                background-color: transparent; }
            .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .students_list .students_list_table tr.total {
              background-color: #6afae2;
              font-weight: bold;
              font-size: 18px; }
              .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .students_list .students_list_table tr.total.classes {
                background-color: #07cb98; }
              .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .students_list .students_list_table tr.total td {
                height: 50px; }
            .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .students_list .students_list_table tr td {
              border: 1px solid #7f7f7f;
              text-align: center;
              height: 40px;
              padding: 5px; }
              .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .students_list .students_list_table tr td.payed .react-switch-bg {
                height: 17px !important;
                width: 46px !important; }
                .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .students_list .students_list_table tr td.payed .react-switch-bg > div {
                  height: 17px !important;
                  width: 28px !important; }
              .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .students_list .students_list_table tr td.payed .react-switch-handle {
                height: 15px !important;
                width: 15px !important; }
              .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .students_list .students_list_table tr td.payed > span {
                display: block; }
        .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line {
          display: flex; }
          .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line > div {
            width: 100%; }
          .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line .textErrors {
            color: red; }
          .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line .radioLevels {
            display: flex;
            flex-wrap: wrap; }
            .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line .radioLevels .RadioLegend {
              width: 33%;
              display: flex; }
              .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line .radioLevels .RadioLegend > span {
                padding: 0;
                margin-right: 5px;
                height: max-content; }
              .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line .radioLevels .RadioLegend legend {
                margin-bottom: 0;
                padding-top: 3px; }
          .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line.image > div {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center; }
            .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line.image > div img {
              max-width: 250px;
              max-height: 250px;
              width: 250px;
              height: 250px;
              margin: auto;
              border: 1px solid #b7b7b7;
              padding: 5px;
              border-radius: 50%;
              margin: auto; }
          .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line.browse_btn {
            margin-top: 25px; }
            .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line.browse_btn > div {
              text-align: center;
              display: flex;
              flex-direction: column;
              justify-content: center; }
              .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line.browse_btn > div span {
                width: max-content;
                margin: auto;
                background: #00b3a9;
                color: white;
                cursor: pointer;
                padding: 5px 10px;
                border-radius: 15px; }
          .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) {
            height: 90px; }
            .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div {
              width: 50%;
              margin: 0 10px; }
              .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div label {
                font-size: 20px; }
              .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div input {
                height: 42px;
                font-size: 20px; }
              .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div .selectInput {
                height: 42px;
                font-size: 20px; }
              .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div .react-datepicker-wrapper {
                height: 42px;
                font-size: 20px; }
                .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div .react-datepicker-wrapper .react-datepicker__input-container {
                  height: 42px;
                  font-size: 20px; }
                  .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div .react-datepicker-wrapper .react-datepicker__input-container:before {
                    line-height: 42px; }
                  .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div .react-datepicker-wrapper .react-datepicker__input-container input {
                    height: 42px;
                    font-size: 20px; }
              .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div .bootstrap-select {
                width: 100%;
                height: 42px; }
          .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line .react-datepicker-wrapper {
            margin-left: 0; }
          .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .line .auto_textarea textarea {
            padding: 5px;
            border-radius: 2.6px;
            border: 0.6px solid #d1dbe2;
            background-color: rgba(245, 248, 249, 0.3);
            font-size: 14px;
            font-weight: 400;
            color: #1b2437 !important;
            margin-top: 3px;
            width: 100%; }
        .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .templates .drag_div {
          position: relative;
          width: 100%;
          margin: 20px 0px; }
          .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .templates .drag_div .template {
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            padding: 0px 5px; }
            .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .templates .drag_div .template .name {
              width: calc(100% - 15px);
              text-align: left;
              display: block; }
            .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .templates .drag_div .template .remove_template {
              color: red; }
        .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .video_url, .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .link_url {
          display: flex;
          flex-wrap: wrap; }
          .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .video_url label, .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .link_url label {
            margin-right: 10px;
            width: 80px; }
          .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .video_url input, .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .link_url input {
            width: calc(100% - 90px);
            margin-bottom: 10px; }
          .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .video_url button, .modal_students_extra_scolaire_activities > div .modal-content .modal-body .rows .link_url button {
            margin: auto;
            margin-bottom: 10px; }

.modal_students_extra_scolaire_activities.modal_bibliotheque .modal-content {
  padding-bottom: 0px;
  border: 0; }

.modal_students_extra_scolaire_activities_export > div {
  min-width: 600px;
  width: 70%;
  max-width: 100%;
  border-radius: 35px;
  height: 90%;
  min-height: 500px;
  max-height: 90%; }
  .modal_students_extra_scolaire_activities_export > div .modal-content {
    border-radius: 30px;
    height: 100%;
    max-height: 100%;
    padding-bottom: 15px; }
    .modal_students_extra_scolaire_activities_export > div .modal-content .btn-close {
      z-index: 111; }
    .modal_students_extra_scolaire_activities_export > div .modal-content .loading {
      position: absolute;
      width: 100%;
      height: 100%; }
      .modal_students_extra_scolaire_activities_export > div .modal-content .loading .loading_img {
        position: absolute;
        width: 100%;
        height: 100%;
        background: black;
        z-index: 111;
        opacity: 0.2;
        border-radius: 10px; }
        .modal_students_extra_scolaire_activities_export > div .modal-content .loading .loading_img img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          max-width: 50%;
          max-height: 50%; }
    .modal_students_extra_scolaire_activities_export > div .modal-content > div {
      overflow: auto; }
      .modal_students_extra_scolaire_activities_export > div .modal-content > div::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5; }
      .modal_students_extra_scolaire_activities_export > div .modal-content > div::-webkit-scrollbar {
        width: 5px;
        height: 3px;
        background-color: #F5F5F5; }
      .modal_students_extra_scolaire_activities_export > div .modal-content > div::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
    .modal_students_extra_scolaire_activities_export > div .modal-content .modal-header {
      border-top-right-radius: 29px;
      border-top-left-radius: 29px; }
      .modal_students_extra_scolaire_activities_export > div .modal-content .modal-header > div {
        display: flex;
        width: 100%;
        justify-content: center; }
        .modal_students_extra_scolaire_activities_export > div .modal-content .modal-header > div button {
          margin-right: 0 !important;
          background: #00b3a9;
          background-image: none;
          position: absolute;
          top: -15px;
          right: -15px;
          border: 5px solid #fff !important;
          width: 40px;
          height: 40px;
          text-align: center;
          color: #fff;
          border-radius: 50%;
          font-size: 31px;
          line-height: 28px;
          box-shadow: none;
          background-image: none;
          font-weight: bold; }
          .modal_students_extra_scolaire_activities_export > div .modal-content .modal-header > div button > span {
            font-size: 35px !important;
            font-weight: normal !important;
            text-shadow: 0 1px 0 #fff !important;
            line-height: 30px;
            font-family: inherit; }
    .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body {
      height: 100%;
      max-height: calc(100% - 56px);
      overflow: auto;
      padding: 30px;
      padding-bottom: 0;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px; }
      .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5; }
      .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body::-webkit-scrollbar {
        width: 5px;
        height: 3px;
        background-color: #F5F5F5; }
      .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
      .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .filters.row {
        padding: 0 20px; }
        .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .filters.row label {
          margin-right: 5px; }
        .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .filters.row > div {
          margin-right: 15px;
          margin-bottom: 10px; }
          .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .filters.row > div button:after {
            position: absolute !important;
            top: 8px; }
          .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .filters.row > div > select {
            width: 220px;
            position: relative;
            display: inline-flex;
            vertical-align: middle;
            color: #3a3a3a;
            height: 38px;
            padding-right: 25px;
            z-index: 1;
            background: #fff;
            border: 1px solid #d1dbe2; }
      .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows {
        height: calc(100% - 100px); }
        .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .col-md-4 {
          padding: 20px; }
        .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .students_list {
          height: calc(100% - 55px);
          width: 100%;
          overflow-y: auto;
          padding-left: 20px; }
          .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .students_list::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #F5F5F5; }
          .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .students_list::-webkit-scrollbar {
            width: 5px;
            height: 3px;
            background-color: #F5F5F5; }
          .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .students_list::-webkit-scrollbar-thumb {
            background-color: #3eb6ad; }
          .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .students_list .students_list_table {
            width: 100%; }
            .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .students_list .students_list_table tr.is_child_of_staff {
              background-color: #ffe599; }
            .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .students_list .students_list_table tr.header th {
              background-color: #e7e6e6;
              border: 1px solid #7f7f7f;
              text-align: center;
              font-size: 18px;
              padding: 5px; }
              .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .students_list .students_list_table tr.header th.order {
                border: 0 solid #7f7f7f;
                background-color: transparent; }
            .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .students_list .students_list_table tr.header.classes {
              background-color: #e7e6e6; }
              .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .students_list .students_list_table tr.header.classes th {
                background-color: transparent; }
            .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .students_list .students_list_table tr.total {
              background-color: #6afae2;
              font-weight: bold;
              font-size: 18px; }
              .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .students_list .students_list_table tr.total.classes {
                background-color: #07cb98; }
              .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .students_list .students_list_table tr.total td {
                height: 50px; }
            .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .students_list .students_list_table tr td {
              border: 1px solid #7f7f7f;
              text-align: center;
              height: 40px;
              padding: 5px; }
              .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .students_list .students_list_table tr td.payed .react-switch-bg {
                height: 17px !important;
                width: 46px !important; }
                .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .students_list .students_list_table tr td.payed .react-switch-bg > div {
                  height: 17px !important;
                  width: 28px !important; }
              .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .students_list .students_list_table tr td.payed .react-switch-handle {
                height: 15px !important;
                width: 15px !important; }
              .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .students_list .students_list_table tr td.payed > span {
                display: block; }
        .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line {
          display: flex; }
          .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line > div {
            width: 100%; }
          .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line .textErrors {
            color: red; }
          .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line .radioLevels {
            display: flex;
            flex-wrap: wrap; }
            .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line .radioLevels .RadioLegend {
              width: 33%;
              display: flex; }
              .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line .radioLevels .RadioLegend > span {
                padding: 0;
                margin-right: 5px;
                height: max-content; }
              .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line .radioLevels .RadioLegend legend {
                margin-bottom: 0;
                padding-top: 3px; }
          .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line.image > div {
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center; }
            .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line.image > div img {
              max-width: 250px;
              max-height: 250px;
              width: 250px;
              height: 250px;
              margin: auto;
              border: 1px solid #b7b7b7;
              padding: 5px;
              border-radius: 50%;
              margin: auto; }
          .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line.browse_btn {
            margin-top: 25px; }
            .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line.browse_btn > div {
              text-align: center;
              display: flex;
              flex-direction: column;
              justify-content: center; }
              .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line.browse_btn > div span {
                width: max-content;
                margin: auto;
                background: #00b3a9;
                color: white;
                cursor: pointer;
                padding: 5px 10px;
                border-radius: 15px; }
          .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) {
            height: 90px; }
            .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div {
              width: 50%;
              margin: 0 10px; }
              .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div label {
                font-size: 20px; }
              .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div input {
                height: 42px;
                font-size: 20px; }
              .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div .selectInput {
                height: 42px;
                font-size: 20px; }
              .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div .react-datepicker-wrapper {
                height: 42px;
                font-size: 20px; }
                .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div .react-datepicker-wrapper .react-datepicker__input-container {
                  height: 42px;
                  font-size: 20px; }
                  .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div .react-datepicker-wrapper .react-datepicker__input-container:before {
                    line-height: 42px; }
                  .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div .react-datepicker-wrapper .react-datepicker__input-container input {
                    height: 42px;
                    font-size: 20px; }
              .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line:not(.image):not(.browse_btn) > div .bootstrap-select {
                width: 100%;
                height: 42px; }
          .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line .react-datepicker-wrapper {
            margin-left: 0; }
          .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .line .auto_textarea textarea {
            padding: 5px;
            border-radius: 2.6px;
            border: 0.6px solid #d1dbe2;
            background-color: rgba(245, 248, 249, 0.3);
            font-size: 14px;
            font-weight: 400;
            color: #1b2437 !important;
            margin-top: 3px;
            width: 100%; }
        .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .templates .drag_div {
          position: relative;
          width: 100%;
          margin: 20px 0px; }
          .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .templates .drag_div .template {
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            padding: 0px 5px; }
            .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .templates .drag_div .template .name {
              width: calc(100% - 15px);
              text-align: left;
              display: block; }
            .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .templates .drag_div .template .remove_template {
              color: red; }
        .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .video_url, .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .link_url {
          display: flex;
          flex-wrap: wrap; }
          .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .video_url label, .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .link_url label {
            margin-right: 10px;
            width: 80px; }
          .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .video_url input, .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .link_url input {
            width: calc(100% - 90px);
            margin-bottom: 10px; }
          .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .video_url button, .modal_students_extra_scolaire_activities_export > div .modal-content .modal-body .rows .link_url button {
            margin: auto;
            margin-bottom: 10px; }

.modal_students_extra_scolaire_activities_export.modal_bibliotheque .modal-content {
  padding-bottom: 0px;
  border: 0; }

.export_params.global > div {
  max-width: 600px;
  max-width: 98%;
  height: 94%;
  margin: 1% 1%; }

.export_params.global .modal-content {
  height: 100%; }
  .export_params.global .modal-content .modal-header {
    justify-content: center;
    font-weight: bold; }
  .export_params.global .modal-content .modal-body.global {
    height: calc(100% - 110px);
    margin-top: 0;
    padding: 5px;
    width: 98%;
    margin-left: 1%;
    margin-bottom: 10px;
    overflow: unset; }
    .export_params.global .modal-content .modal-body.global > .row {
      margin: 0; }
      .export_params.global .modal-content .modal-body.global > .row .w12 .RadioLegend {
        width: max-content;
        padding: 0 5px; }
      .export_params.global .modal-content .modal-body.global > .row .select_ {
        min-width: 195px;
        padding: 0px 10px; }
        .export_params.global .modal-content .modal-body.global > .row .select_ label {
          width: 100%;
          text-align: left;
          display: block;
          font-family: Montserrat;
          font-size: 15px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          margin-bottom: 5px;
          margin-top: 5px; }
      .export_params.global .modal-content .modal-body.global > .row.d_f {
        padding: 0 0px; }
        .export_params.global .modal-content .modal-body.global > .row.d_f > label {
          width: 100%;
          text-align: left;
          display: block;
          font-family: Montserrat;
          font-size: 15px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          margin-bottom: 5px;
          margin-top: 5px; }
      .export_params.global .modal-content .modal-body.global > .row .rs3__control {
        border: 0px;
        background: transparent;
        box-shadow: none; }
        .export_params.global .modal-content .modal-body.global > .row .rs3__control:hover {
          border: 0px; }
      .export_params.global .modal-content .modal-body.global > .row .rs3__single-value {
        background: transparent;
        color: #FFF;
        text-align: center; }
      .export_params.global .modal-content .modal-body.global > .row .rs3__indicators {
        display: none; }
      .export_params.global .modal-content .modal-body.global > .row .rs__menu {
        box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5);
        border: 0px;
        border-radius: 8px;
        padding: 5px;
        z-index: 1000;
        width: max-content;
        min-width: 100%; }
        .export_params.global .modal-content .modal-body.global > .row .rs__menu .rs__menu-list::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #F5F5F5;
          border-radius: 5px; }
        .export_params.global .modal-content .modal-body.global > .row .rs__menu .rs__menu-list::-webkit-scrollbar {
          width: 10px;
          /*height: 3px;*/
          background-color: #F5F5F5;
          border-radius: 5px; }
        .export_params.global .modal-content .modal-body.global > .row .rs__menu .rs__menu-list::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
          border-radius: 5px; }
        .export_params.global .modal-content .modal-body.global > .row .rs__menu .rs__menu-list .rs__option {
          border-bottom: 1px dashed #ccc;
          cursor: pointer; }
        .export_params.global .modal-content .modal-body.global > .row .rs__menu .rs__menu-list .rs__group .rs__group-heading {
          padding: 8px 12px; }
          .export_params.global .modal-content .modal-body.global > .row .rs__menu .rs__menu-list .rs__group .rs__group-heading:hover {
            background-color: #86e6df4a;
            cursor: pointer; }
        .export_params.global .modal-content .modal-body.global > .row .rs__menu .rs__menu-list .rs__group .rs__option {
          padding-left: 25px;
          cursor: pointer; }
    .export_params.global .modal-content .modal-body.global .viewer {
      margin-top: 10px;
      padding: 15px;
      backdrop-filter: blur(8px);
      background-color: #dee6eaa3;
      margin: 0;
      margin-top: 10px;
      padding-inline-end: 15px;
      border-radius: 6px;
      border: 1px solid #d1dbe2;
      border-image: initial;
      width: 100%;
      height: calc(100% - 130px); }
      .export_params.global .modal-content .modal-body.global .viewer .table_viewer {
        height: calc(100%);
        overflow: auto;
        padding-right: 5px;
        background-color: transparent; }
        .export_params.global .modal-content .modal-body.global .viewer .table_viewer::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #F5F5F5;
          border-radius: 5px;
          position: absolute;
          right: -50;
          /* Position the scrollbar on the right of the element*/ }
        .export_params.global .modal-content .modal-body.global .viewer .table_viewer::-webkit-scrollbar {
          width: 10px;
          height: 10px;
          background-color: #F5F5F5;
          border-radius: 5px;
          position: absolute;
          right: -50;
          /* Position the scrollbar on the right of the element*/ }
        .export_params.global .modal-content .modal-body.global .viewer .table_viewer::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
          border-radius: 5px;
          position: absolute;
          right: -50;
          /* Position the scrollbar on the right of the element*/ }
        .export_params.global .modal-content .modal-body.global .viewer .table_viewer.freeze_columns {
          background: transparent; }
          .export_params.global .modal-content .modal-body.global .viewer .table_viewer.freeze_columns table thead tr th {
            position: sticky !important;
            top: 0;
            z-index: 2; }
            .export_params.global .modal-content .modal-body.global .viewer .table_viewer.freeze_columns table thead tr th.volet_cne {
              z-index: 3; }
            .export_params.global .modal-content .modal-body.global .viewer .table_viewer.freeze_columns table thead tr th.volet_name {
              z-index: 3; }
            .export_params.global .modal-content .modal-body.global .viewer .table_viewer.freeze_columns table thead tr th.volet_birthday {
              z-index: 3; }
            .export_params.global .modal-content .modal-body.global .viewer .table_viewer.freeze_columns table thead tr th.volet_classe_name {
              z-index: 3; }
          .export_params.global .modal-content .modal-body.global .viewer .table_viewer.freeze_columns table tbody tr td.volet {
            position: sticky !important;
            left: 0;
            z-index: 1; }
          .export_params.global .modal-content .modal-body.global .viewer .table_viewer.freeze_columns table tbody tr td.empty_td.volet {
            background-color: #eaeff1; }
      .export_params.global .modal-content .modal-body.global .viewer .warning {
        text-align: left;
        color: red; }
      .export_params.global .modal-content .modal-body.global .viewer table {
        width: max-content;
        position: relative;
        border-collapse: initial;
        border-spacing: 1px; }
        .export_params.global .modal-content .modal-body.global .viewer table thead tr {
          font-weight: 700;
          text-align: center; }
          .export_params.global .modal-content .modal-body.global .viewer table thead tr th {
            position: relative;
            background-image: linear-gradient(180deg, #cad3f2 3%, #b5c4e3 96%);
            font-size: 13px;
            font-weight: 600;
            line-height: 1.64;
            color: #202726;
            width: auto;
            white-space: nowrap;
            background-color: #fff;
            height: 30px;
            border-image: initial;
            border: 1px solid #e8e8e8;
            border-right-width: 0;
            padding: 5px 22px;
            min-width: 110px; }
            .export_params.global .modal-content .modal-body.global .viewer table thead tr th:first-child {
              border-top-left-radius: 6px;
              border-bottom-left-radius: 6px; }
            .export_params.global .modal-content .modal-body.global .viewer table thead tr th:last-child {
              border-right-width: 1px;
              border-top-right-radius: 6px;
              border-bottom-right-radius: 6px; }
            .export_params.global .modal-content .modal-body.global .viewer table thead tr th.zindex3 {
              z-index: 3 !important; }
            .export_params.global .modal-content .modal-body.global .viewer table thead tr th .down_up {
              position: absolute;
              right: 5px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              top: 50%;
              transform: translateY(-50%); }
              .export_params.global .modal-content .modal-body.global .viewer table thead tr th .down_up i {
                cursor: pointer;
                font-weight: bold;
                color: black; }
                .export_params.global .modal-content .modal-body.global .viewer table thead tr th .down_up i.deactivated {
                  color: gray;
                  cursor: not-allowed; }
        .export_params.global .modal-content .modal-body.global .viewer table tbody tr {
          text-align: center; }
          .export_params.global .modal-content .modal-body.global .viewer table tbody tr td {
            border-radius: 6px;
            font-size: 12px;
            font-weight: 500;
            line-height: 1.45;
            color: black;
            width: auto;
            white-space: nowrap;
            background-color: #fff;
            height: 50px;
            border-image: initial;
            border: 1px solid #e8e8e8;
            border-right-width: 0;
            padding: 10px; }
            .export_params.global .modal-content .modal-body.global .viewer table tbody tr td.empty_td {
              border-color: transparent;
              background-color: transparent; }
            .export_params.global .modal-content .modal-body.global .viewer table tbody tr td:first-child {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px; }
          .export_params.global .modal-content .modal-body.global .viewer table tbody tr.spacer {
            height: 10px; }
          .export_params.global .modal-content .modal-body.global .viewer table tbody tr.stats_tr.total td:not(.empty_td) {
            background-image: linear-gradient(180deg, #cad3f2 3%, #b5c4e3 96%); }
          .export_params.global .modal-content .modal-body.global .viewer table tbody tr.stats_tr.total td.empty_td {
            background-image: none; }

.modal.export_students_tutors_massar {
  z-index: 111050;
  background: #06060645; }

.text-decoration-line-through {
  text-decoration: line-through; }

@media (min-width: 1300px) {
  .col-25 {
    flex: 0 0 20% !important;
    max-width: 20% !important; } }

.scholar_years_students.modal .modal-xl {
  max-width: 1400px; }

/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #f27046;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #80c9ca;
  --secondary: #3eb6ad;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 10px; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #80c9ca;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #4bb1b3;
    text-decoration: underline; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1170px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #dbf0f0; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #bde3e3; }

.table-hover .table-primary:hover {
  background-color: #c9e9e9; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #c9e9e9; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c9ebe8; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #9bd9d4; }

.table-hover .table-secondary:hover {
  background-color: #b6e4e0; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #b6e4e0; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #daeff0;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(128, 201, 202, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(128, 201, 202, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #212529;
  background-color: #80c9ca;
  border-color: #80c9ca; }
  .btn-primary:hover {
    color: #212529;
    background-color: #65bebf;
    border-color: #5cbabb; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(114, 176, 178, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #212529;
    background-color: #80c9ca;
    border-color: #80c9ca; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #212529;
    background-color: #5cbabb;
    border-color: #53b6b7; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(114, 176, 178, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #3eb6ad;
  border-color: #3eb6ad; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #349992;
    border-color: #319089; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(91, 193, 185, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #3eb6ad;
    border-color: #3eb6ad; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #319089;
    border-color: #2e8680; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(91, 193, 185, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #80c9ca;
  border-color: #80c9ca; }
  .btn-outline-primary:hover {
    color: #212529;
    background-color: #80c9ca;
    border-color: #80c9ca; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(128, 201, 202, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #80c9ca;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #80c9ca;
    border-color: #80c9ca; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(128, 201, 202, 0.5); }

.btn-outline-secondary {
  color: #3eb6ad;
  border-color: #3eb6ad; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #3eb6ad;
    border-color: #3eb6ad; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(62, 182, 173, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #3eb6ad;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #3eb6ad;
    border-color: #3eb6ad; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(62, 182, 173, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #80c9ca;
  text-decoration: none; }
  .btn-link:hover {
    color: #4bb1b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.fix-position.dropdown-menu.show {
  position: fixed !important; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #80c9ca; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #80c9ca;
    background-color: #80c9ca; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(128, 201, 202, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #daeff0; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #feffff;
    border-color: #feffff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #80c9ca;
  background-color: #80c9ca; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(128, 201, 202, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(128, 201, 202, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(128, 201, 202, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(128, 201, 202, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #daeff0;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(128, 201, 202, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #daeff0;
    box-shadow: 0 0 0 0.2rem rgba(128, 201, 202, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(128, 201, 202, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(128, 201, 202, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(128, 201, 202, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #80c9ca;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #feffff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #80c9ca;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #feffff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #80c9ca;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #feffff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #80c9ca; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #80c9ca;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #4bb1b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(128, 201, 202, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #80c9ca;
  border-color: #80c9ca; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #212529;
  background-color: #80c9ca; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #212529;
    background-color: #5cbabb; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(128, 201, 202, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #3eb6ad; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #319089; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(62, 182, 173, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #436969;
  background-color: #e6f4f4;
  border-color: #dbf0f0; }
  .alert-primary hr {
    border-top-color: #c9e9e9; }
  .alert-primary .alert-link {
    color: #2f4a4a; }

.alert-secondary {
  color: #205f5a;
  background-color: #d8f0ef;
  border-color: #c9ebe8; }
  .alert-secondary hr {
    border-top-color: #b6e4e0; }
  .alert-secondary .alert-link {
    color: #133936; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 10px 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 10px;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }
  .progress:after {
    content: "";
    display: block;
    background: #82d4d0;
    height: 100%;
    border-radius: 9px; }
  .progress::-webkit-progress-bar {
    background: #ccc; }
  .progress::-webkit-progress-value {
    background: #82d4d0; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #80c9ca;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 10px 10px; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #80c9ca;
    border-color: #80c9ca; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #436969;
  background-color: #dbf0f0; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #436969;
    background-color: #c9e9e9; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #436969;
    border-color: #436969; }

.list-group-item-secondary {
  color: #205f5a;
  background-color: #c9ebe8; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #205f5a;
    background-color: #b6e4e0; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #205f5a;
    border-color: #205f5a; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 1000px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #80c9ca !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #5cbabb !important; }

.bg-secondary {
  background-color: #3eb6ad !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #319089 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #80c9ca !important; }

.border-secondary {
  border-color: #3eb6ad !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #80c9ca !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #4bb1b3 !important; }

.text-secondary {
  color: #3eb6ad !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #2b7d77 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.pretty * {
  box-sizing: border-box; }

.pretty input:not([type='checkbox']):not([type='radio']) {
  display: none; }

.pretty {
  position: relative;
  display: inline-block;
  margin-right: 1em;
  white-space: nowrap;
  line-height: 1; }
  .pretty input {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 1em;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0;
    margin: 0;
    padding: 0;
    cursor: pointer; }
  .pretty .state label {
    position: initial;
    display: inline-block;
    font-weight: normal;
    margin: 0;
    text-indent: 1.5em;
    min-width: calc(1em + 2px); }
    .pretty .state label:before, .pretty .state label:after {
      content: '';
      width: calc(1em + 2px);
      height: calc(1em + 2px);
      display: block;
      box-sizing: border-box;
      border-radius: 0;
      border: 1px solid transparent;
      z-index: 0;
      position: absolute;
      left: 0;
      top: calc((0% - (100% - 1em)) - 8%);
      background-color: transparent; }
    .pretty .state label:before {
      border-color: #bdc3c7; }
  .pretty .state.p-is-hover, .pretty .state.p-is-indeterminate {
    display: none; }

@keyframes zoom {
  0% {
    opacity: 0;
    transform: scale(0); } }

@keyframes tada {
  0% {
    animation-timing-function: ease-in;
    opacity: 0;
    transform: scale(7); }
  38% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: scale(1); }
  55% {
    animation-timing-function: ease-in;
    transform: scale(1.5); }
  72% {
    animation-timing-function: ease-out;
    transform: scale(1); }
  81% {
    animation-timing-function: ease-in;
    transform: scale(1.24); }
  89% {
    animation-timing-function: ease-out;
    transform: scale(1); }
  95% {
    animation-timing-function: ease-in;
    transform: scale(1.04); }
  100% {
    animation-timing-function: ease-out;
    transform: scale(1); } }

@keyframes jelly {
  0% {
    transform: scale3d(1, 1, 1); }
  30% {
    transform: scale3d(0.75, 1.25, 1); }
  40% {
    transform: scale3d(1.25, 0.75, 1); }
  50% {
    transform: scale3d(0.85, 1.15, 1); }
  65% {
    transform: scale3d(1.05, 0.95, 1); }
  75% {
    transform: scale3d(0.95, 1.05, 1); }
  100% {
    transform: scale3d(1, 1, 1); } }

@keyframes rotate {
  0% {
    opacity: 0;
    transform: translateZ(-200px) rotate(-45deg); }
  100% {
    opacity: 1;
    transform: translateZ(0) rotate(0); } }

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 0px 0px #bdc3c7; }
  100% {
    box-shadow: 0px 0px 0px 1.5em rgba(189, 195, 199, 0); } }

.pretty.p-default.p-fill .state label:after {
  transform: scale(1); }

.pretty.p-default .state label:after {
  transform: scale(0.6); }

.pretty.p-default input:checked ~ .state label:after {
  background-color: #bdc3c7 !important; }

.pretty.p-default.p-thick .state label:before, .pretty.p-default.p-thick .state label:after {
  border-width: calc(1em / 7); }

.pretty.p-default.p-thick .state label:after {
  transform: scale(0.4) !important; }

.pretty.p-icon .state .icon {
  position: absolute;
  font-size: 1em;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  left: 0;
  z-index: 1;
  text-align: center;
  line-height: normal;
  top: calc((0% - (100% - 1em)) - 8%);
  border: 1px solid transparent;
  opacity: 0; }

.pretty.p-icon .state .icon:before {
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  line-height: 1; }

.pretty.p-icon input:checked ~ .state .icon {
  opacity: 1; }

.pretty.p-icon input:checked ~ .state label:before {
  border-color: #5a656b; }

.pretty.p-svg .state .svg {
  position: absolute;
  font-size: 1em;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  left: 0;
  z-index: 1;
  text-align: center;
  line-height: normal;
  top: calc((0% - (100% - 1em)) - 8%);
  border: 1px solid transparent;
  opacity: 0; }

.pretty.p-svg .state svg {
  margin: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  line-height: 1; }

.pretty.p-svg input:checked ~ .state .svg {
  opacity: 1; }

.pretty.p-image .state img {
  opacity: 0;
  position: absolute;
  width: calc(1em + 2px);
  height: calc(1em + 2px);
  top: 0;
  top: calc((0% - (100% - 1em)) - 8%);
  left: 0;
  z-index: 0;
  text-align: center;
  line-height: normal;
  transform: scale(0.8); }

.pretty.p-image input:checked ~ .state img {
  opacity: 1; }

.pretty.p-switch input {
  min-width: 2em; }

.pretty.p-switch .state {
  position: relative; }
  .pretty.p-switch .state:before {
    content: '';
    border: 1px solid #bdc3c7;
    border-radius: 60px;
    width: 2em;
    box-sizing: unset;
    height: calc(1em + 2px);
    position: absolute;
    top: 0;
    top: calc((0% - (100% - 1em)) - 16%);
    z-index: 0;
    transition: all 0.5s ease; }
  .pretty.p-switch .state label {
    text-indent: 2.5em; }
    .pretty.p-switch .state label:before, .pretty.p-switch .state label:after {
      transition: all 0.5s ease;
      border-radius: 100%;
      left: 0;
      border-color: transparent;
      transform: scale(0.8); }
    .pretty.p-switch .state label:after {
      background-color: #bdc3c7 !important; }

.pretty.p-switch input:checked ~ .state:before {
  border-color: #5a656b; }

.pretty.p-switch input:checked ~ .state label:before {
  opacity: 0; }

.pretty.p-switch input:checked ~ .state label:after {
  background-color: #5a656b !important;
  left: 1em; }

.pretty.p-switch.p-fill input:checked ~ .state:before {
  border-color: #5a656b;
  background-color: #5a656b !important; }

.pretty.p-switch.p-fill input:checked ~ .state label:before {
  opacity: 0; }

.pretty.p-switch.p-fill input:checked ~ .state label:after {
  background-color: #fff !important;
  left: 1em; }

.pretty.p-switch.p-slim .state:before {
  height: 0.1em;
  background: #bdc3c7 !important;
  top: calc(50% - 0.1em); }

.pretty.p-switch.p-slim input:checked ~ .state:before {
  border-color: #5a656b;
  background-color: #5a656b !important; }

.pretty.p-has-hover input:hover ~ .state:not(.p-is-hover) {
  display: none; }

.pretty.p-has-hover input:hover ~ .state.p-is-hover {
  display: block; }
  .pretty.p-has-hover input:hover ~ .state.p-is-hover .icon {
    display: block; }

.pretty.p-has-focus input:focus ~ .state label:before {
  box-shadow: 0px 0px 3px 0px #bdc3c7; }

.pretty.p-has-indeterminate input[type='checkbox']:indeterminate ~ .state:not(.p-is-indeterminate) {
  display: none; }

.pretty.p-has-indeterminate input[type='checkbox']:indeterminate ~ .state.p-is-indeterminate {
  display: block; }
  .pretty.p-has-indeterminate input[type='checkbox']:indeterminate ~ .state.p-is-indeterminate .icon {
    display: block;
    opacity: 1; }

.pretty.p-toggle .state.p-on {
  opacity: 0;
  display: none; }

.pretty.p-toggle .state.p-off,
.pretty.p-toggle .state .icon,
.pretty.p-toggle .state .svg,
.pretty.p-toggle .state img {
  opacity: 1;
  display: inherit; }

.pretty.p-toggle .state.p-off .icon {
  color: #bdc3c7; }

.pretty.p-toggle input:checked ~ .state.p-on {
  opacity: 1;
  display: inherit; }

.pretty.p-toggle input:checked ~ .state.p-off {
  opacity: 0;
  display: none; }

.pretty.p-plain input:checked ~ .state label:before,
.pretty.p-plain.p-toggle .state label:before {
  content: none; }

.pretty.p-plain.p-plain .icon {
  transform: scale(1.1); }

.pretty.p-round .state label:before, .pretty.p-round .state label:after {
  border-radius: 100%; }

.pretty.p-round.p-icon .state .icon {
  border-radius: 100%;
  overflow: hidden; }
  .pretty.p-round.p-icon .state .icon:before {
    transform: scale(0.8); }

.pretty.p-curve .state label:before, .pretty.p-curve .state label:after {
  border-radius: 20%; }

.pretty.p-smooth label:before,
.pretty.p-smooth label:after,
.pretty.p-smooth .icon,
.pretty.p-smooth .svg {
  transition: all 0.5s ease; }

.pretty.p-smooth input:checked + .state label:after {
  transition: all 0.3s ease; }

.pretty.p-smooth input:checked + .state .icon,
.pretty.p-smooth input:checked + .state .svg,
.pretty.p-smooth input:checked + .state img {
  animation: zoom 0.2s ease; }

.pretty.p-smooth.p-default input:checked + .state label:after {
  animation: zoom 0.2s ease; }

.pretty.p-smooth.p-plain input:checked + .state label:before {
  content: '';
  transform: scale(0);
  transition: all 0.5s ease; }

.pretty.p-tada:not(.p-default) input:checked + .state .icon,
.pretty.p-tada:not(.p-default) input:checked + .state .svg,
.pretty.p-tada:not(.p-default) input:checked + .state img,
.pretty.p-tada:not(.p-default) input:checked + .state label:before,
.pretty.p-tada:not(.p-default) input:checked + .state label:after {
  animation: tada 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1 alternate;
  opacity: 1; }

.pretty.p-jelly:not(.p-default) input:checked + .state .icon,
.pretty.p-jelly:not(.p-default) input:checked + .state .svg,
.pretty.p-jelly:not(.p-default) input:checked + .state img,
.pretty.p-jelly:not(.p-default) input:checked + .state label:before,
.pretty.p-jelly:not(.p-default) input:checked + .state label:after {
  animation: jelly 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }

.pretty.p-jelly:not(.p-default) input:checked + .state label:before {
  border-color: transparent; }

.pretty.p-rotate:not(.p-default) input:checked ~ .state .icon,
.pretty.p-rotate:not(.p-default) input:checked ~ .state .svg,
.pretty.p-rotate:not(.p-default) input:checked ~ .state img,
.pretty.p-rotate:not(.p-default) input:checked ~ .state label:before,
.pretty.p-rotate:not(.p-default) input:checked ~ .state label:after {
  animation: rotate 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  opacity: 1; }

.pretty.p-rotate:not(.p-default) input:checked ~ .state label:before {
  border-color: transparent; }

.pretty.p-pulse:not(.p-switch) input:checked ~ .state label:before {
  animation: pulse 1s; }

.pretty input[disabled] {
  cursor: not-allowed;
  display: none; }
  .pretty input[disabled] ~ * {
    opacity: .5; }

.pretty.p-locked input {
  display: none;
  cursor: not-allowed; }

.pretty input:checked ~ .state.p-primary label:after,
.pretty.p-toggle .state.p-primary label:after {
  background-color: #428bca !important; }

.pretty input:checked ~ .state.p-primary .icon,
.pretty input:checked ~ .state.p-primary .svg,
.pretty.p-toggle .state.p-primary .icon,
.pretty.p-toggle .state.p-primary .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-primary-o label:before,
.pretty.p-toggle .state.p-primary-o label:before {
  border-color: #428bca; }

.pretty input:checked ~ .state.p-primary-o label:after,
.pretty.p-toggle .state.p-primary-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-primary-o .icon,
.pretty input:checked ~ .state.p-primary-o .svg,
.pretty input:checked ~ .state.p-primary-o svg,
.pretty.p-toggle .state.p-primary-o .icon,
.pretty.p-toggle .state.p-primary-o .svg,
.pretty.p-toggle .state.p-primary-o svg {
  color: #428bca;
  stroke: #428bca; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-primary-o label:after {
  background-color: #428bca !important; }

.pretty.p-switch input:checked ~ .state.p-primary:before {
  border-color: #428bca; }

.pretty.p-switch.p-fill input:checked ~ .state.p-primary:before {
  background-color: #428bca !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-primary:before {
  border-color: #245682;
  background-color: #245682 !important; }

.pretty input:checked ~ .state.p-info label:after,
.pretty.p-toggle .state.p-info label:after {
  background-color: #5bc0de !important; }

.pretty input:checked ~ .state.p-info .icon,
.pretty input:checked ~ .state.p-info .svg,
.pretty.p-toggle .state.p-info .icon,
.pretty.p-toggle .state.p-info .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-info-o label:before,
.pretty.p-toggle .state.p-info-o label:before {
  border-color: #5bc0de; }

.pretty input:checked ~ .state.p-info-o label:after,
.pretty.p-toggle .state.p-info-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-info-o .icon,
.pretty input:checked ~ .state.p-info-o .svg,
.pretty input:checked ~ .state.p-info-o svg,
.pretty.p-toggle .state.p-info-o .icon,
.pretty.p-toggle .state.p-info-o .svg,
.pretty.p-toggle .state.p-info-o svg {
  color: #5bc0de;
  stroke: #5bc0de; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-info-o label:after {
  background-color: #5bc0de !important; }

.pretty.p-switch input:checked ~ .state.p-info:before {
  border-color: #5bc0de; }

.pretty.p-switch.p-fill input:checked ~ .state.p-info:before {
  background-color: #5bc0de !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-info:before {
  border-color: #2390b0;
  background-color: #2390b0 !important; }

.pretty input:checked ~ .state.p-success label:after,
.pretty.p-toggle .state.p-success label:after {
  background-color: #5cb85c !important; }

.pretty input:checked ~ .state.p-success .icon,
.pretty input:checked ~ .state.p-success .svg,
.pretty.p-toggle .state.p-success .icon,
.pretty.p-toggle .state.p-success .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-success-o label:before,
.pretty.p-toggle .state.p-success-o label:before {
  border-color: #5cb85c; }

.pretty input:checked ~ .state.p-success-o label:after,
.pretty.p-toggle .state.p-success-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-success-o .icon,
.pretty input:checked ~ .state.p-success-o .svg,
.pretty input:checked ~ .state.p-success-o svg,
.pretty.p-toggle .state.p-success-o .icon,
.pretty.p-toggle .state.p-success-o .svg,
.pretty.p-toggle .state.p-success-o svg {
  color: #5cb85c;
  stroke: #5cb85c; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-success-o label:after {
  background-color: #5cb85c !important; }

.pretty.p-switch input:checked ~ .state.p-success:before {
  border-color: #5cb85c; }

.pretty.p-switch.p-fill input:checked ~ .state.p-success:before {
  background-color: #5cb85c !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-success:before {
  border-color: #357935;
  background-color: #357935 !important; }

.pretty input:checked ~ .state.p-warning label:after,
.pretty.p-toggle .state.p-warning label:after {
  background-color: #f0ad4e !important; }

.pretty input:checked ~ .state.p-warning .icon,
.pretty input:checked ~ .state.p-warning .svg,
.pretty.p-toggle .state.p-warning .icon,
.pretty.p-toggle .state.p-warning .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-warning-o label:before,
.pretty.p-toggle .state.p-warning-o label:before {
  border-color: #f0ad4e; }

.pretty input:checked ~ .state.p-warning-o label:after,
.pretty.p-toggle .state.p-warning-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-warning-o .icon,
.pretty input:checked ~ .state.p-warning-o .svg,
.pretty input:checked ~ .state.p-warning-o svg,
.pretty.p-toggle .state.p-warning-o .icon,
.pretty.p-toggle .state.p-warning-o .svg,
.pretty.p-toggle .state.p-warning-o svg {
  color: #f0ad4e;
  stroke: #f0ad4e; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-warning-o label:after {
  background-color: #f0ad4e !important; }

.pretty.p-switch input:checked ~ .state.p-warning:before {
  border-color: #f0ad4e; }

.pretty.p-switch.p-fill input:checked ~ .state.p-warning:before {
  background-color: #f0ad4e !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-warning:before {
  border-color: #c77c11;
  background-color: #c77c11 !important; }

.pretty input:checked ~ .state.p-danger label:after,
.pretty.p-toggle .state.p-danger label:after {
  background-color: #d9534f !important; }

.pretty input:checked ~ .state.p-danger .icon,
.pretty input:checked ~ .state.p-danger .svg,
.pretty.p-toggle .state.p-danger .icon,
.pretty.p-toggle .state.p-danger .svg {
  color: #fff;
  stroke: #fff; }

.pretty input:checked ~ .state.p-danger-o label:before,
.pretty.p-toggle .state.p-danger-o label:before {
  border-color: #d9534f; }

.pretty input:checked ~ .state.p-danger-o label:after,
.pretty.p-toggle .state.p-danger-o label:after {
  background-color: transparent; }

.pretty input:checked ~ .state.p-danger-o .icon,
.pretty input:checked ~ .state.p-danger-o .svg,
.pretty input:checked ~ .state.p-danger-o svg,
.pretty.p-toggle .state.p-danger-o .icon,
.pretty.p-toggle .state.p-danger-o .svg,
.pretty.p-toggle .state.p-danger-o svg {
  color: #d9534f;
  stroke: #d9534f; }

.pretty.p-default:not(.p-fill) input:checked ~ .state.p-danger-o label:after {
  background-color: #d9534f !important; }

.pretty.p-switch input:checked ~ .state.p-danger:before {
  border-color: #d9534f; }

.pretty.p-switch.p-fill input:checked ~ .state.p-danger:before {
  background-color: #d9534f !important; }

.pretty.p-switch.p-slim input:checked ~ .state.p-danger:before {
  border-color: #a02622;
  background-color: #a02622 !important; }

.pretty.p-bigger label:before,
.pretty.p-bigger label:after,
.pretty.p-bigger .icon,
.pretty.p-bigger .svg,
.pretty.p-bigger .img {
  font-size: 1.2em !important;
  top: calc((0% - (100% - 1em)) - 35%) !important; }

.pretty.p-bigger label {
  text-indent: 1.7em; }

@media print {
  .pretty .state:before,
  .pretty .state label:before,
  .pretty .state label:after,
  .pretty .state .icon {
    color-adjust: exact;
    /* stylelint-disable */
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact; } }

@font-face {
  font-family: 'bahnschrift';
  src: url("../fonts/bahnschrift/BAHNSCHRIFT.TTF");
  font-weight: normal; }

@font-face {
  font-family: "revicons";
  font-style: normal;
  font-weight: 400;
  src: local("revicons"), local("revicons"), url("../fonts/revicons.woff") format("woff2"); }

@font-face {
  font-family: "arabic_font";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Tajawal-Regular.ttf"); }

@font-face {
  font-family: 'Janna LT';
  src: url("../fonts/Janna-LT-Regular.ttf");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "arabic_font_bold";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Tajawal-Bold.ttf"); }

@font-face {
  font-family: "DejaVu Sans";
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype"); }

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype"); }

@font-face {
  font-family: "DejaVu Sans";
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype"); }

@font-face {
  font-family: "DejaVu Sans";
  font-weight: bold;
  font-style: italic;
  src: url("https://kendo.cdn.telerik.com/2017.2.621/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype"); }

@font-face {
  font-family: 'SourceSansRoman';
  src: url("../fonts/SourceSans3-VariableFont_wght.ttf") format("truetype"); }

@font-face {
  font-family: 'QuicksandLight';
  src: url("../fonts/Quicksand-VariableFont_wght.ttf") format("truetype"); }

#menu-level {
  z-index: 100000 !important; }

#menu-country, #menu-city {
  z-index: 100000 !important; }

*,
a,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: Montserrat, sans-serif;
  outline: none; }

input, textarea {
  -webkit-appearance: none;
  appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  -o-appearance: none; }

body {
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff; }

.h4, .h5, .h6, h4, h5, h6 {
  margin-top: 10px;
  margin-bottom: 10px; }

button {
  outline-style: none;
  box-shadow: none;
  border-color: transparent; }
  button:focus, button:hover {
    outline-style: none;
    box-shadow: none;
    border-color: transparent; }

textarea {
  resize: none; }

.w12 {
  width: 100%;
  float: left; }

.Exercies {
  width: 69px;
  height: 15px;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark); }

a {
  cursor: pointer; }

.tox-notifications-container {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
  height: 0px !important;
  width: 0px !important; }
  .tox-notifications-container .tox-notification {
    display: none !important;
    visibility: hidden !important;
    opacity: 0 !important;
    height: 0px !important;
    width: 0px !important; }

.hidden {
  display: none !important; }

.visibility_hidden {
  visibility: hidden !important; }

.react-datepicker-popper:not(.origin) {
  z-index: 99; }
  .react-datepicker-popper:not(.origin) .react-datepicker__day--selected,
  .react-datepicker-popper:not(.origin) .react-datepicker__day--in-selecting-range,
  .react-datepicker-popper:not(.origin) .react-datepicker__day--in-range,
  .react-datepicker-popper:not(.origin) .react-datepicker__month-text--selected,
  .react-datepicker-popper:not(.origin) .react-datepicker__month-text--in-selecting-range,
  .react-datepicker-popper:not(.origin) .react-datepicker__month-text--in-range {
    background-color: #4f5df5;
    border-radius: 50%;
    box-shadow: #949cf9 0px 0px 7px 1px;
    font-weight: 500; }
  .react-datepicker-popper:not(.origin) .react-datepicker__day--selected:hover,
  .react-datepicker-popper:not(.origin) .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker-popper:not(.origin) .react-datepicker__day--in-range:hover,
  .react-datepicker-popper:not(.origin) .react-datepicker__month-text--selected:hover,
  .react-datepicker-popper:not(.origin) .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker-popper:not(.origin) .react-datepicker__month-text--in-range:hover {
    background-color: #4f5df5;
    border-radius: 50%;
    box-shadow: #949cf9 0px 0px 7px 1px;
    font-weight: 500; }
  .react-datepicker-popper:not(.origin) .react-datepicker__day-name,
  .react-datepicker-popper:not(.origin) .react-datepicker__day,
  .react-datepicker-popper:not(.origin) .react-datepicker__time-name {
    width: 17px;
    line-height: 14px;
    font-size: 10px; }
  .react-datepicker-popper:not(.origin) .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
    margin-bottom: 5px; }
    .react-datepicker-popper:not(.origin) .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll .react-datepicker__month-read-view--selected-month,
    .react-datepicker-popper:not(.origin) .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll .react-datepicker__year-read-view--selected-year {
      font-size: 10px; }
  .react-datepicker-popper:not(.origin) .react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
    font-size: 10px; }
  .react-datepicker-popper:not(.origin) .react-datepicker__week div {
    color: #8f8f8f; }
  .react-datepicker-popper:not(.origin) .react-datepicker__navigation--next,
  .react-datepicker-popper:not(.origin) .react-datepicker__navigation--prev {
    color: #d8dfe5; }
  .react-datepicker-popper:not(.origin) .react-datepicker__navigation.react-datepicker__navigation--next {
    border-left-color: #ccc; }
  .react-datepicker-popper:not(.origin) .react-datepicker__navigation.react-datepicker__navigation--previous {
    border-right-color: #ccc; }
  .react-datepicker-popper:not(.origin) .react-datepicker__header {
    background-color: #fff;
    border-bottom: none; }
  .react-datepicker-popper:not(.origin) .react-datepicker__triangle {
    display: none; }
  .react-datepicker-popper:not(.origin) .react-datepicker__current-month,
  .react-datepicker-popper:not(.origin) .react-datepicker-time__header,
  .react-datepicker-popper:not(.origin) .react-datepicker-year-header {
    font-weight: 400;
    text-transform: uppercase; }
  .react-datepicker-popper:not(.origin) .react-datepicker__current-month {
    margin-bottom: 6px;
    font-size: 10px; }
  .react-datepicker-popper:not(.origin) .react-datepicker__day-names {
    border-bottom: 1px solid #cbe5f6; }
    .react-datepicker-popper:not(.origin) .react-datepicker__day-names div {
      font-weight: bold; }

.react-datepicker-wrapper {
  width: 100%;
  margin-left: 5px; }
  .react-datepicker-wrapper .react-datepicker__input-container {
    width: 100%;
    position: relative; }
    .react-datepicker-wrapper .react-datepicker__input-container:before {
      content: "\F073";
      font-family: FontAwesome;
      font-size: 18px;
      color: #b7c4d2;
      position: absolute;
      left: 7px !important;
      top: 50% !important;
      transform: translateY(-50%); }
    .react-datepicker-wrapper .react-datepicker__input-container input {
      padding-left: 30px !important;
      /*color: #bbc7d3;*/ }
      .react-datepicker-wrapper .react-datepicker__input-container input.reregistration_date {
        font-size: 11px;
        position: relative;
        z-index: 99;
        height: 36px;
        border-radius: 2.6px;
        border: .6px solid #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        font-weight: 400;
        color: #1b2437 !important; }
      .react-datepicker-wrapper .react-datepicker__input-container input:not(.form-control) {
        font-size: 11px;
        position: relative;
        z-index: 99;
        float: left;
        width: 100%;
        height: 28px;
        border-radius: 2.6px;
        border: .6px solid #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        font-weight: 400;
        color: #1b2437 !important; }
      .react-datepicker-wrapper .react-datepicker__input-container input.zoom_datetime {
        caret-color: transparent; }
      .react-datepicker-wrapper .react-datepicker__input-container input.debut {
        padding: 5px;
        font-size: 13px;
        border: 1px solid #ced4da;
        border-radius: 6px; }

.react-datepicker-popper:not(.origin) {
  margin-top: 0;
  z-index: 1000; }
  .react-datepicker-popper:not(.origin) .react-datepicker--time-only {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    background-color: #fff;
    color: #000;
    border: none;
    border-radius: 5px;
    display: inline-block;
    position: relative;
    box-shadow: 0 0 9px 0px rgba(0, 0, 0, 0.2);
    text-align: left;
    position: relative; }
    .react-datepicker-popper:not(.origin) .react-datepicker--time-only:before {
      content: "";
      position: absolute;
      top: -5px;
      left: 50%;
      margin-left: -5px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent #fff transparent; }
    .react-datepicker-popper:not(.origin) .react-datepicker--time-only .react-datepicker__time-container {
      width: 130px;
      padding: 20px 10px 0; }
      .react-datepicker-popper:not(.origin) .react-datepicker--time-only .react-datepicker__time-container .react-datepicker__header {
        display: none; }
      .react-datepicker-popper:not(.origin) .react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
        width: 100%; }
        .react-datepicker-popper:not(.origin) .react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
          padding: 5px 0; }
          .react-datepicker-popper:not(.origin) .react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar {
            width: 8px; }
          .react-datepicker-popper:not(.origin) .react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar-thumb {
            background-color: #3eb6ad;
            height: 50px; }
          .react-datepicker-popper:not(.origin) .react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
            height: 40px;
            padding: 0 10px;
            text-align: left;
            font-family: "Montserrat", sans-serif;
            font-weight: 500;
            font-size: 14px;
            color: #767676; }
            .react-datepicker-popper:not(.origin) .react-datepicker--time-only .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item.react-datepicker__time-list-item--selected {
              background: transparent;
              color: #4c5362;
              font-weight: 600; }

a {
  cursor: pointer; }

.container-koolskools {
  max-width: 940px;
  text-align: center; }

body {
  overflow-x: hidden; }

.selectInput {
  height: 28px;
  border-radius: 2.6px;
  border: solid 0.6px #d1dbe2;
  background-color: rgba(245, 248, 249, 0.3);
  width: 100%;
  padding-left: 10px; }
  .selectInput:before, .selectInput:after {
    display: none; }
  .selectInput div {
    background-color: transparent !important; }

a:hover,
a:focus {
  text-decoration: none; }

ul li {
  list-style-type: none; }

header {
  width: 100%;
  float: left;
  background: #80c9ca;
  padding: 10px 0 13px; }

.navigation {
  position: relative;
  text-align: center; }

nav {
  opacity: 0.9;
  border-radius: 17.5px;
  background-color: #3eb6ad;
  display: inline-block;
  padding: 2px 3px;
  margin-top: 20px; }
  nav ul {
    padding: 0;
    margin: 0; }
    nav ul li {
      display: inline-flex;
      margin-left: 30px; }
      nav ul li:first-child {
        margin-left: 0; }
      nav ul li a {
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        text-transform: uppercase;
        letter-spacing: 1.4px;
        color: rgba(255, 255, 255, 0.54);
        border-radius: 30px;
        padding: 5px 10px;
        border: solid 2px transparent;
        cursor: pointer; }
        nav ul li a:hover, nav ul li a:focus, nav ul li a.active {
          background: #80c9ca;
          border: 2px solid #fff;
          font-weight: 600;
          color: #fff; }

.lang {
  position: absolute;
  right: -50px;
  top: -10px; }
  .lang .btn-group > button {
    border-radius: 0;
    border: none;
    color: #fff;
    font-family: "Montserrat", sans-serif; }
    .lang .btn-group > button.button-first {
      opacity: 0.9;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      color: #fff;
      background: #3eb6ad;
      border: none;
      padding: 16px 12px 14px;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
      font-family: "Montserrat", sans-serif;
      color: rgba(255, 255, 255, 0.9);
      font-size: 13px;
      font-weight: 700;
      letter-spacing: 1.3px;
      text-transform: uppercase; }
    .lang .btn-group > button.dropdown-toggle {
      background: transparent;
      padding: 0 3px; }
  .lang .btn-group .caret {
    margin-left: 0; }

.signin {
  text-align: right;
  margin: 15px 0 0; }
  .signin li {
    cursor: pointer;
    display: inline-block;
    text-align: center; }
    .signin li a {
      opacity: 0.9;
      font-family: "Montserrat", sans-serif;
      font-size: 11px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2.5px;
      text-align: right;
      color: #fff;
      cursor: pointer; }

section {
  width: 100%;
  float: left;
  overflow-x: hidden; }
  section.one {
    position: relative;
    padding-bottom: 0;
    min-height: 500px; }
    section.one .before-one {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0; }
    section.one .slider {
      position: relative;
      margin-top: 8px;
      border-radius: 10px;
      margin-right: 20px;
      box-shadow: -4px 40px 53px 0 rgba(0, 0, 0, 0.15); }
      section.one .slider img {
        border-radius: 10px;
        width: 100%; }
      section.one .slider .carousel {
        border-radius: 10px;
        overflow: hidden;
        position: relative; }
        section.one .slider .carousel:before {
          content: "";
          background-image: linear-gradient(180deg, transparent, #000);
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          z-index: 999; }
        section.one .slider .carousel h3 {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 20px;
          -webkit-text-stroke: 0 #f27046;
          font-size: 27px;
          font-weight: 700;
          font-style: normal;
          font-stretch: normal;
          line-height: 3;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
          margin: 0;
          z-index: 999; }
        section.one .slider .carousel .banner-anim {
          position: relative;
          min-height: 435px; }
          section.one .slider .carousel .banner-anim:before {
            content: "";
            background-image: linear-gradient(180deg, transparent, #000);
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            z-index: 999; }
        section.one .slider .carousel .banner-anim-elem {
          min-height: 430px; }
          section.one .slider .carousel .banner-anim-elem .bg {
            min-height: 430px; }
      section.one .slider .slider-bottom {
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        text-align: center;
        z-index: 999; }
        section.one .slider .slider-bottom a {
          cursor: pointer;
          font-family: Montserrat, sans-serif;
          font-size: 12px;
          text-transform: uppercase;
          font-weight: 700;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 2.4px;
          color: rgba(255, 255, 255, 0.9);
          background: #ffa827;
          padding: 13px 28px;
          border: 5px solid #fff;
          border-radius: 40px;
          margin: 0 23px;
          display: inline-block;
          width: 250px; }
          section.one .slider .slider-bottom a img {
            max-width: 22px;
            max-height: 22px;
            vertical-align: bottom;
            margin-right: 8px; }
    section.one .scrolling {
      position: absolute;
      bottom: -60px;
      left: 50%;
      margin-left: -26px;
      z-index: 999;
      text-align: center;
      opacity: 0.9;
      font-family: Montserrat, sans-serif;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 1.2px;
      line-height: 16px;
      color: #6c6c6c;
      font-size: 12px;
      font-weight: 700; }
      section.one .scrolling a {
        opacity: 0.9;
        font-family: Montserrat, sans-serif;
        font-size: 9px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 1.2px;
        text-align: center;
        line-height: 16px;
        color: #6c6c6c; }
        section.one .scrolling a i {
          font-size: 12px;
          font-weight: 700; }
    section.one .news-feed h3 {
      opacity: 1;
      font-family: "Montserrat", sans-serif;
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1.8px;
      text-align: left;
      color: #fff;
      text-transform: uppercase;
      margin: 0; }
    section.one .news-feed p {
      font-size: 11px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 14px;
      letter-spacing: normal;
      text-align: left;
      color: rgba(84, 84, 84, 0.71); }
      section.one .news-feed p.link {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 0; }
        section.one .news-feed p.link.link-planete a {
          color: #0dafc4; }
          section.one .news-feed p.link.link-planete a:before {
            border-color: transparent transparent transparent #0dafc4; }
        section.one .news-feed p.link.link-monde a {
          color: #d43c2f; }
          section.one .news-feed p.link.link-monde a:before {
            border-color: transparent transparent transparent #d43c2f; }
        section.one .news-feed p.link.link-sport a {
          color: #8b92ff; }
          section.one .news-feed p.link.link-sport a:before {
            border-color: transparent transparent transparent #8b92ff; }
        section.one .news-feed p.link a {
          position: relative;
          padding-right: 15px; }
          section.one .news-feed p.link a:before {
            content: "";
            right: 0;
            top: 4px;
            position: absolute;
            border-style: solid;
            border-width: 4px 0 4px 6px;
            border-color: transparent transparent transparent #0dafc4; }
  section.two {
    padding: 0 0 35px;
    text-align: center; }
    section.two .container-subjects {
      max-width: 780px;
      margin: auto; }
      section.two .container-subjects > div {
        margin-top: 20px; }
        section.two .container-subjects > div h2 {
          margin-bottom: 30px; }
    section.two h2 {
      font-family: "Arvo", serif;
      font-size: 23px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 1.3px;
      text-align: center;
      text-transform: uppercase;
      color: #fff;
      background-color: #80c9ca;
      padding: 12px 60px 10px;
      border-radius: 25px;
      display: inline-block; }
      section.two h2.home {
        padding-left: 15px;
        padding-right: 15px;
        width: 250px; }
    section.two .subjects .subjects-bg {
      position: relative;
      max-width: 810px;
      background: url("../img/subjects-bg.svg") no-repeat scroll;
      background-size: cover;
      -webkit-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
      -moz-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
      -ms-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
      -o-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
      filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
      margin: 20px auto; }
      section.two .subjects .subjects-bg .subjects-bbg {
        min-height: 260px;
        padding: 50px 0 50px 10px;
        max-width: 640px;
        border-radius: 150px;
        margin: auto;
        overflow: hidden;
        position: relative; }
        section.two .subjects .subjects-bg .subjects-bbg img {
          max-width: 158px;
          display: inline-block; }
    section.two .subjects .arrow-next,
    section.two .subjects .arrow-prev {
      background-color: #46bfb6;
      width: 36px;
      height: 36px;
      position: absolute;
      border-radius: 50%;
      top: 50%;
      margin-top: -18px;
      text-align: center;
      cursor: pointer; }
      section.two .subjects .arrow-next i,
      section.two .subjects .arrow-prev i {
        color: #fff;
        line-height: 36px;
        font-size: 25px; }
    section.two .subjects .arrow-prev {
      left: 10px; }
    section.two .subjects .arrow-next {
      right: 10px; }
  section.section-news-feeds {
    background-color: #80c9ca;
    padding: 100px 0 145px;
    position: relative;
    overflow: hidden; }
    section.section-news-feeds:before {
      content: "";
      background: #3eb6ad;
      border-radius: 100%;
      position: absolute;
      top: -50px;
      bottom: -50px;
      width: 70%;
      right: -400px; }
    section.section-news-feeds h3 {
      font-family: "Arvo", sans-serif;
      font-size: 18px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      text-transform: uppercase;
      letter-spacing: 1.3px;
      text-align: right;
      color: white;
      padding: 21px 45px;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
      background-color: rgba(255, 255, 255, 0.31);
      position: absolute;
      right: 0;
      top: 74px;
      z-index: 999;
      margin: 0; }
    section.section-news-feeds .section-feeds {
      position: relative;
      z-index: 999;
      padding: 0 110px; }
      section.section-news-feeds .section-feeds .section-feed {
        max-width: 45%;
        box-shadow: 1px 3px 10px 0px rgba(0, 0, 0, 0.2);
        margin-top: -20px;
        border-radius: 7px; }
        section.section-news-feeds .section-feeds .section-feed .news-img {
          width: 53%;
          height: 160px;
          overflow: hidden; }
          section.section-news-feeds .section-feeds .section-feed .news-img img {
            height: 100%; }
        section.section-news-feeds .section-feeds .section-feed.feed-left {
          float: left;
          margin-right: 60px; }
          section.section-news-feeds .section-feeds .section-feed.feed-left .news-img {
            float: right;
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px; }
          section.section-news-feeds .section-feeds .section-feed.feed-left .news-article {
            float: left;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px; }
            section.section-news-feeds .section-feeds .section-feed.feed-left .news-article p {
              text-align: left; }
              section.section-news-feeds .section-feeds .section-feed.feed-left .news-article p.link a {
                float: left; }
              section.section-news-feeds .section-feeds .section-feed.feed-left .news-article p.link i {
                margin-left: 0;
                float: right; }
        section.section-news-feeds .section-feeds .section-feed.feed-right {
          float: right;
          margin-left: 60px; }
          section.section-news-feeds .section-feeds .section-feed.feed-right .news-img {
            float: left;
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px; }
          section.section-news-feeds .section-feeds .section-feed.feed-right .news-article {
            float: right;
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px; }
            section.section-news-feeds .section-feeds .section-feed.feed-right .news-article p {
              text-align: right; }
              section.section-news-feeds .section-feeds .section-feed.feed-right .news-article p.link a {
                float: right; }
              section.section-news-feeds .section-feeds .section-feed.feed-right .news-article p.link i {
                float: left;
                margin-right: 0;
                transform: rotate(180deg); }
        section.section-news-feeds .section-feeds .section-feed .news-article {
          height: 160px;
          background-color: #fff;
          position: relative;
          width: 47%;
          padding: 9px 12px; }
          section.section-news-feeds .section-feeds .section-feed .news-article:before {
            content: "";
            position: absolute;
            bottom: -87px;
            left: 0;
            background: url("../../assets/img/shape-news-feed.svg");
            background-size: cover;
            width: 100%;
            min-height: 120px;
            z-index: 0;
            -webkit-filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.15));
            -moz-filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.15));
            -ms-filter: drop-shadow(0px -3px 1px rgba(0, 0, 0, 0.15));
            -o-filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.15));
            filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.15)); }
          section.section-news-feeds .section-feeds .section-feed .news-article:after {
            content: "";
            position: absolute;
            bottom: 0;
            background: #fff;
            left: 0;
            right: 0;
            height: 14px;
            border-radius: 10px;
            z-index: 999; }
          section.section-news-feeds .section-feeds .section-feed .news-article p {
            opacity: 0.9;
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 15px;
            letter-spacing: normal;
            color: #000000;
            margin: 0; }
            section.section-news-feeds .section-feeds .section-feed .news-article p.news-date {
              font-size: 10px;
              margin-top: 5px;
              font-weight: 500;
              letter-spacing: 0.6px;
              color: #b9b9b9; }
            section.section-news-feeds .section-feeds .section-feed .news-article p.link {
              opacity: 0.9;
              font-family: "Montserrat", sans-serif;
              font-size: 12px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: 2.09;
              letter-spacing: 0.2px;
              color: #fff;
              position: absolute;
              bottom: 9px;
              left: 12px;
              right: 12px;
              z-index: 99999; }
              section.section-news-feeds .section-feeds .section-feed .news-article p.link a {
                color: #fff;
                padding: 0 12px;
                border-radius: 25px;
                line-height: 24px;
                font-size: 11.5px;
                min-width: 127px;
                text-align: center;
                text-transform: uppercase; }
              section.section-news-feeds .section-feeds .section-feed .news-article p.link i {
                margin-top: 9px;
                font-size: 22px;
                content: "";
                width: 39px;
                height: 8px;
                display: inline-block;
                -webkit-mask: url("../../assets/img/news_arrow.svg") no-repeat 50% 50%;
                mask: url("../../assets/img/news_arrow.svg") no-repeat 50% 50%;
                -webkit-mask-size: cover;
                mask-size: cover;
                position: relative; }
              section.section-news-feeds .section-feeds .section-feed .news-article p.link.planete a,
              section.section-news-feeds .section-feeds .section-feed .news-article p.link.planete i {
                background-color: #00b0c4; }
              section.section-news-feeds .section-feeds .section-feed .news-article p.link.monde a,
              section.section-news-feeds .section-feeds .section-feed .news-article p.link.monde i {
                background-color: #e62721; }
              section.section-news-feeds .section-feeds .section-feed .news-article p.link.science a,
              section.section-news-feeds .section-feeds .section-feed .news-article p.link.science i {
                background-color: #ff6735; }
              section.section-news-feeds .section-feeds .section-feed .news-article p.link.sport a,
              section.section-news-feeds .section-feeds .section-feed .news-article p.link.sport i {
                background-color: #9172ff; }
              section.section-news-feeds .section-feeds .section-feed .news-article p.link.cinema a,
              section.section-news-feeds .section-feeds .section-feed .news-article p.link.cinema i {
                background-color: #ffa400; }
  section.koolskools-notes {
    background: #f7f7f7;
    padding: 70px 200px 150px;
    background: url("../../assets/img/graphics-copy.svg") no-repeat scroll center; }
    section.koolskools-notes h3 {
      opacity: 0.9;
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      line-height: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.9px;
      text-align: center;
      color: #3eb6ad;
      margin-bottom: 70px; }
      section.koolskools-notes h3 span {
        position: relative; }
        section.koolskools-notes h3 span:before {
          content: "";
          position: absolute;
          right: -200px;
          top: -8px;
          width: 130px;
          height: 157px;
          background: url("../../assets/img/home_rocket1.svg") no-repeat scroll center; }
    section.koolskools-notes .alice-carousel .alice-carousel__stage-item img {
      border: 20px solid #fff;
      box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.5); }
    section.koolskools-notes .current {
      transform: scale(1.5);
      transition: 0.3s;
      z-index: 999999;
      position: relative; }
    section.koolskools-notes main {
      position: relative; }
      section.koolskools-notes main .pager-controls .pager-control--prev,
      section.koolskools-notes main .pager-controls .pager-control--next {
        display: none; }
      section.koolskools-notes main:before {
        content: "";
        background: url("../../assets/img/home_rocket2.svg") no-repeat scroll;
        width: 133px;
        height: 130px;
        position: absolute;
        left: -70px;
        top: 50%; }
    section.koolskools-notes .frame {
      margin: 10px;
      position: relative; }
      section.koolskools-notes .frame .track {
        padding: 75px 3px 75px; }
        section.koolskools-notes .frame .track img {
          -webkit-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
          -moz-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
          -ms-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
          -o-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.15));
          border: 20px solid #fff; }
    section.koolskools-notes .pager-controls span {
      width: 9px;
      height: 9px;
      background: rgba(216, 216, 216, 0.4);
      border-radius: 50%;
      display: inline-block;
      margin: 0 3.5px; }
      section.koolskools-notes .pager-controls span.current-nav {
        background: rgba(216, 216, 216, 0.9); }

footer {
  width: 100%;
  float: left;
  background: #f7f7f7;
  border-top: 10px solid #fff;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.15);
  padding-bottom: 30px;
  position: relative;
  z-index: 9; }
  footer .footer-logo {
    text-align: center;
    margin-bottom: 30px; }
    footer .footer-logo a {
      background: url("../../assets/img/footer-logo-cloud.svg") no-repeat scroll;
      width: 294px;
      height: 143px;
      display: block;
      margin: -115px auto 0;
      padding-top: 27px;
      -webkit-filter: drop-shadow(0 -1px 8px rgba(0, 0, 0, 0.15));
      -moz-filter: drop-shadow(0 -1px 8px rgba(0, 0, 0, 0.15));
      -ms-filter: drop-shadow(0 -1px 8px rgba(0, 0, 0, 0.15));
      -o-filter: drop-shadow(0 -1px 8px rgba(0, 0, 0, 0.15));
      filter: drop-shadow(0 -1px 8px rgba(0, 0, 0, 0.15)); }
      footer .footer-logo a img {
        height: 108px; }
  footer .bloc-footer {
    float: left;
    width: 20%;
    text-align: left; }
    footer .bloc-footer h3 {
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2.4px;
      margin-bottom: 20px;
      color: #292929;
      opacity: 0.63; }
    footer .bloc-footer ul {
      margin: 0;
      padding: 0; }
      footer .bloc-footer ul li {
        list-style-type: none; }
        footer .bloc-footer ul li a {
          opacity: 0.63;
          font-family: "Montserrat", sans-serif;
          font-size: 15px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.57;
          letter-spacing: normal;
          color: #6c6c6c; }

.copyright {
  width: 100%;
  float: left;
  background: #46bfb6;
  text-align: center;
  padding: 10px; }
  .copyright p {
    font-family: "Montserrat", sans-serif;
    font-size: 10.5px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    margin: 0; }

body .MuiBackdrop-root-17 {
  background-color: rgba(110, 125, 146, 0.72); }

body .MuiPaper-root-19 {
  border-radius: 10px; }

body .MuiDialog-paper-5 {
  overflow-y: inherit;
  border-radius: 10px; }

.table_kls {
  width: max-content;
  height: 100%;
  min-width: 100%;
  margin: 0px;
  backdrop-filter: blur(8px);
  background-color: rgba(222, 230, 234, 0.64);
  border-radius: 16px;
  padding: 10px 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #d1dbe2;
  border-image: initial; }
  .table_kls table tr.spacer {
    height: 10px; }
  .table_kls table tr.header td:not(.spacer_td), .table_kls table tr.header th {
    height: 50px;
    border-style: solid;
    border-color: #e8e8e8;
    border-image: initial;
    border-width: 1px 0px 1px 1px;
    padding: 10px;
    background-image: linear-gradient(to bottom, #cad3f2 3%, #b5c4e3 96%);
    font-size: 13px;
    font-weight: 600;
    line-height: 1.64;
    color: #202726;
    width: auto;
    white-space: nowrap; }
    .table_kls table tr.header td:not(.spacer_td):first-child, .table_kls table tr.header th:first-child {
      border-top-left-radius: 14px;
      border-bottom-left-radius: 14px; }
    .table_kls table tr.header td:not(.spacer_td):last-child, .table_kls table tr.header th:last-child {
      border-right-width: 1px;
      border-top-right-radius: 14px;
      border-bottom-right-radius: 14px; }
  .table_kls table tr.header.header2 td:not(.spacer_td), .table_kls table tr.header.header2 th {
    background-image: linear-gradient(to bottom, #cad3f2aa 3%, #b5c4e3aa 96%); }
  .table_kls table tr.header2 td {
    border-radius: 6px;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.45;
    color: #6e6e6e;
    height: 50px;
    padding: 10px 5px;
    background-color: white;
    border-style: solid;
    border-color: #e8e8e8;
    border-image: initial;
    border-width: 1px 0px 1px 1px;
    width: auto;
    white-space: nowrap; }
    .table_kls table tr.header2 td.header_level {
      background-image: linear-gradient(to bottom, #cad3f2 3%, #b5c4e3 96%);
      font-size: 11px;
      font-weight: 600;
      line-height: 1.64;
      color: #202726; }
      .table_kls table tr.header2 td.header_level:first-child {
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px; }
      .table_kls table tr.header2 td.header_level:last-child {
        border-right-width: 1px;
        border-top-right-radius: 14px;
        border-bottom-right-radius: 14px; }
  .table_kls table tr.header_s_t td:not(.spacer_td), .table_kls table tr.header_s_t th {
    height: 30px;
    padding: 5px; }
    .table_kls table tr.header_s_t td:not(.spacer_td):first-child, .table_kls table tr.header_s_t th:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px; }
    .table_kls table tr.header_s_t td:not(.spacer_td):last-child, .table_kls table tr.header_s_t th:last-child {
      border-right-width: 1px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px; }
  .table_kls table tr.student:not(.spacer) td:not(.spacer_td) {
    border-radius: 6px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.45;
    color: #6e6e6e;
    width: auto;
    white-space: nowrap;
    background-color: white;
    height: 50px;
    border-style: solid;
    border-color: #e8e8e8;
    border-image: initial;
    border-width: 1px 0px 1px 1px; }

.dialog {
  border: solid 1px #979797;
  border-left: 10px solid #00b3a9;
  padding: 35px 0 30px 50px;
  border-radius: 10px;
  /*max-width: 700px;*/ }
  .dialog.dialogLaraki {
    border-left: 10px solid #2d408f !important; }
    .dialog.dialogLaraki.dialogSuccess .closeModal {
      background: #2d408f !important; }
    .dialog.dialogLaraki.dialogSuccess h5 {
      color: #2d408f !important; }
  .dialog.dialogSanady {
    border-left: 10px solid #ff5a00 !important; }
    .dialog.dialogSanady.dialogSuccess .closeModal {
      background: #ff5a00 !important; }
    .dialog.dialogSanady.dialogSuccess h5 {
      color: #ff5a00 !important; }
  .dialog.dialogPont {
    border-left: 10px solid #2aa8c4 !important; }
    .dialog.dialogPont.dialogSuccess .closeModal {
      background: #2aa8c4 !important; }
    .dialog.dialogPont.dialogSuccess h5 {
      color: #2aa8c4 !important; }
  .dialog.dialogFMPS {
    border-left: 10px solid #f49235 !important; }
    .dialog.dialogFMPS.dialogSuccess .closeModal {
      background: #f49235 !important; }
    .dialog.dialogFMPS.dialogSuccess h5 {
      color: #f49235 !important; }
  .dialog.dialogPrint {
    max-width: 1000px;
    width: 1000px; }
  .dialog.dialogAssetList {
    max-width: 900px; }
  .dialog.dialogAssurance {
    max-width: none; }
    .dialog.dialogAssurance h3 {
      opacity: 0.9;
      -webkit-text-stroke: 0 #f27046;
      font-family: "Montserrat", sans-serif;
      font-size: 16.5px;
      letter-spacing: 0.3px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.6;
      color: black;
      width: 180px; }
    .dialog.dialogAssurance h2 {
      opacity: 0.9;
      -webkit-text-stroke: 0 #f27046;
      font-family: "Montserrat", sans-serif;
      font-size: 11.5px !important;
      letter-spacing: 0.3px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.6;
      color: black;
      margin: 10px;
      width: 29%; }
    .dialog.dialogAssurance .table-costum {
      border: 1px solid #6bd6d0;
      padding: 1%;
      border-radius: 10px;
      margin-bottom: 10px; }
    .dialog.dialogAssurance .top-assurance {
      display: flex;
      justify-content: space-between;
      color: black; }
    .dialog.dialogAssurance.top {
      display: flex;
      justify-content: space-between; }
  .dialog.dialogBuyPack {
    width: 1087px;
    height: 480px;
    max-width: 1087px;
    border-left: none;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #FFF;
    padding: 23px 42px 32px;
    overflow: hidden;
    position: relative; }
    .dialog.dialogBuyPack.dialogBuyPackage {
      width: 1250px;
      height: auto;
      max-width: 1250px;
      padding: 55px 50px 40px; }
      .dialog.dialogBuyPack.dialogBuyPackage .packageTop {
        position: relative;
        z-index: 99;
        float: left;
        width: 100%;
        margin-bottom: 60px; }
        .dialog.dialogBuyPack.dialogBuyPackage .packageTop h2 {
          -webkit-text-stroke: 0 #f27046;
          font-size: 50px;
          line-height: 90px;
          font-weight: bold;
          color: #b1bfcd;
          position: relative;
          float: left; }
          .dialog.dialogBuyPack.dialogBuyPackage .packageTop h2 img {
            display: none; }
          .dialog.dialogBuyPack.dialogBuyPackage .packageTop h2::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 73px;
            height: 2px;
            background: #b1bfcd; }
        .dialog.dialogBuyPack.dialogBuyPackage .packageTop a {
          float: right;
          width: 229px;
          height: 54px;
          object-fit: contain;
          border-radius: 40px;
          box-shadow: -1px 3px 17px 0 rgba(0, 0, 0, 0.16);
          border: solid 4px #fff;
          background-color: #ffa727;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.03;
          text-align: center;
          color: #fff;
          line-height: 46px;
          margin-top: 20px; }
      .dialog.dialogBuyPack.dialogBuyPackage .packages {
        position: relative;
        z-index: 99;
        margin: 0 -20px; }
    .dialog.dialogBuyPack.dialogPayments {
      width: 365px;
      max-width: 365px;
      height: 372px;
      padding: 26px 32px 30px; }
      .dialog.dialogBuyPack.dialogPayments h3 {
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        color: #1b2437;
        text-align: left;
        opacity: 1; }
      .dialog.dialogBuyPack.dialogPayments .payments .blocPayment {
        width: 100%;
        float: left;
        height: 75px;
        border-radius: 4px;
        border: solid 0.9px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        padding: 10px;
        margin-bottom: 15px;
        display: flex;
        align-items: center; }
        .dialog.dialogBuyPack.dialogPayments .payments .blocPayment img {
          float: left;
          width: 85px;
          height: 55px; }
        .dialog.dialogBuyPack.dialogPayments .payments .blocPayment h4 {
          float: left;
          opacity: 0.8;
          font-size: 18px;
          font-weight: 500;
          color: #1b2437;
          margin: 0 0 0 23px; }
    .dialog.dialogBuyPack .buyClose {
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
      color: #fff;
      font-size: 40px;
      line-height: 40px;
      text-align: center;
      z-index: 999; }
      .dialog.dialogBuyPack .buyClose i {
        color: #fff;
        font-size: 21px;
        line-height: 40px; }
    .dialog.dialogBuyPack .buyBack {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
      color: #fff;
      text-align: center;
      z-index: 999; }
      .dialog.dialogBuyPack .buyBack i {
        color: #fff;
        line-height: 39px;
        font-size: 32px; }
    .dialog.dialogBuyPack h3 {
      opacity: 0.8;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #1b2437;
      margin: 0 0 40px;
      letter-spacing: normal; }
    .dialog.dialogBuyPack .buyLeft {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 382px;
      height: 355px;
      object-fit: contain;
      background: url("../img/parents/fill1.svg") no-repeat scroll transparent;
      background-size: contain; }
    .dialog.dialogBuyPack .buyRight {
      position: absolute;
      right: 0;
      top: 0;
      width: 438px;
      height: 402px;
      object-fit: contain;
      background: url("../img/parents/fill2.svg") no-repeat scroll transparent;
      background-size: contain; }
    .dialog.dialogBuyPack .buyPack {
      position: relative; }
  .dialog.dialogAddLevel {
    border: none; }
  .dialog.dialogMessagerie {
    max-width: 1100px;
    width: 1100px;
    border-radius: 0;
    padding: 0;
    border: none;
    border-bottom-right-radius: 10px !important; }
    .dialog.dialogMessagerie .closeModal {
      position: absolute;
      top: -15px;
      right: -17px;
      border: 6px solid #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 37px;
      line-height: 28px;
      background-color: #47bfb6;
      z-index: 99;
      box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3); }
    .dialog.dialogMessagerie.dialogAddGroup {
      max-width: 385px;
      padding: 25px 25px 20px;
      object-fit: contain;
      opacity: 0.9;
      border-radius: 10px;
      box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
      background-color: #ffffff; }
      .dialog.dialogMessagerie.dialogAddGroup.dialogAddPhoto {
        max-width: 442px;
        padding: 25px 25px 35px; }
        .dialog.dialogMessagerie.dialogAddGroup.dialogAddPhoto .switch {
          width: 340px;
          margin: 0 auto 30px;
          height: 45px;
          opacity: 0.9;
          border-radius: 27px;
          border: solid 1px #4caeae; }
          .dialog.dialogMessagerie.dialogAddGroup.dialogAddPhoto .switch a {
            float: left;
            width: 50%;
            height: 43px;
            font-family: Montserrat;
            font-size: 11px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #4caeae;
            border-radius: 27px;
            text-align: center;
            border: 3px solid #fff;
            padding: 12px 0; }
            .dialog.dialogMessagerie.dialogAddGroup.dialogAddPhoto .switch a.active {
              color: #fff;
              background: #4caeae; }
        .dialog.dialogMessagerie.dialogAddGroup.dialogAddPhoto .fileDrop {
          height: 49px;
          width: 100%;
          border-radius: 4px;
          border: dashed 1px #b1bfcd;
          background-color: #ffffff;
          text-align: center;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 49px;
          letter-spacing: normal;
          color: #9b9ea7;
          margin: 40px 0; }
          .dialog.dialogMessagerie.dialogAddGroup.dialogAddPhoto .fileDrop b {
            color: #1b2437;
            font-weight: 500; }
        .dialog.dialogMessagerie.dialogAddGroup.dialogAddPhoto .listPhoto {
          min-height: 140px;
          float: left;
          width: 100%;
          /*display:flex;
          flex-direction: column;
          justify-content: center*/ }
          .dialog.dialogMessagerie.dialogAddGroup.dialogAddPhoto .listPhoto .dragandrophandler {
            margin-top: 20px; }
          .dialog.dialogMessagerie.dialogAddGroup.dialogAddPhoto .listPhoto img {
            width: 51px;
            height: 51px;
            border-radius: 5px;
            margin: 0 6px 15px;
            border: 1px solid transparent; }
            .dialog.dialogMessagerie.dialogAddGroup.dialogAddPhoto .listPhoto img.active {
              border: 1px solid #47bdb4; }
        .dialog.dialogMessagerie.dialogAddGroup.dialogAddPhoto .button {
          float: left;
          width: 100%;
          margin-top: 15px;
          text-align: center; }
          .dialog.dialogMessagerie.dialogAddGroup.dialogAddPhoto .button button {
            width: 106px;
            height: 30px;
            object-fit: contain;
            opacity: 0.9;
            border-radius: 49.5px;
            background-color: #4caeae;
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: normal;
            color: #ffffff;
            text-align: center;
            border: solid 1px #fff;
            margin: 0 5px; }
            .dialog.dialogMessagerie.dialogAddGroup.dialogAddPhoto .button button.cancel {
              color: #4caeae;
              border: solid 1px #4caeae;
              background: #fff; }
      .dialog.dialogMessagerie.dialogAddGroup h3 {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1b2437;
        margin-bottom: 24px; }
      .dialog.dialogMessagerie.dialogAddGroup .form .addGroupHeader {
        float: left;
        width: 100%;
        margin-bottom: 15px; }
        .dialog.dialogMessagerie.dialogAddGroup .form .addGroupHeader img {
          float: left;
          width: 50px;
          margin-right: 15px; }
        .dialog.dialogMessagerie.dialogAddGroup .form .addGroupHeader input {
          border: none;
          font-family: Montserrat;
          font-size: 10px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #848586;
          border-bottom: solid 1px #5cbfce;
          float: left;
          width: calc(100% - 65px);
          height: 30px;
          margin-top: 7px; }
      .dialog.dialogMessagerie.dialogAddGroup .form h4 {
        font-size: 18px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 35px;
        letter-spacing: normal;
        color: #1b2437; }
        .dialog.dialogMessagerie.dialogAddGroup .form h4 img {
          float: right; }
      .dialog.dialogMessagerie.dialogAddGroup .form label {
        padding: 10px 0;
        float: left;
        width: 100%;
        border-bottom: solid 1px rgba(214, 217, 224, 0.2);
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1b2437;
        position: relative;
        margin: 0; }
        .dialog.dialogMessagerie.dialogAddGroup .form label img {
          width: 30px;
          height: 30px;
          border-radius: 30px;
          margin-right: 19px; }
        .dialog.dialogMessagerie.dialogAddGroup .form label input {
          visibility: hidden;
          position: absolute;
          right: 0;
          top: 13px;
          width: 16px;
          height: 16px; }
          .dialog.dialogMessagerie.dialogAddGroup .form label input:checked + span:before {
            content: "";
            content: "";
            position: absolute;
            top: 1px;
            left: 1px;
            right: 1px;
            bottom: 1px;
            background: #5cbfce;
            border-radius: 16px; }
        .dialog.dialogMessagerie.dialogAddGroup .form label span {
          width: 16px;
          height: 16px;
          object-fit: contain;
          opacity: 0.9;
          border-radius: 16px;
          border: solid 1px #b1bfcd;
          float: right;
          margin-top: 8px;
          position: relative; }
      .dialog.dialogMessagerie.dialogAddGroup .form .button {
        float: left;
        width: 100%;
        margin-top: 15px;
        text-align: center; }
        .dialog.dialogMessagerie.dialogAddGroup .form .button button {
          width: 106px;
          height: 30px;
          object-fit: contain;
          opacity: 0.9;
          border-radius: 49.5px;
          background-color: #4caeae;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          color: #ffffff;
          text-align: center;
          border: solid 1px #fff;
          margin: 0 5px; }
          .dialog.dialogMessagerie.dialogAddGroup .form .button button.cancel {
            color: #4caeae;
            border: solid 1px #4caeae;
            background: #fff; }
  .dialog.dialogCalendar {
    max-width: 1150px;
    width: 1150px;
    border-left: none;
    border-radius: 20px 20px 5px 5px;
    padding: 20px 0 0px; }
    .dialog.dialogCalendar .calendarHeader {
      padding: 5px 25px 25px;
      position: relative;
      width: 100%;
      float: left; }
      .dialog.dialogCalendar .calendarHeader .react-datepicker-wrapper {
        width: auto;
        float: left; }
      .dialog.dialogCalendar .calendarHeader input {
        width: 223px;
        float: left;
        height: 30px;
        border-radius: 2px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        color: #434b5b;
        font-size: 13px;
        font-weight: 500; }
      .dialog.dialogCalendar .calendarHeader .calendar-switch {
        float: left;
        width: 650px;
        height: 30px;
        margin-left: 30px;
        border-radius: 15px;
        border: solid 1px #d1dbe2;
        background-color: #f5f8f9; }
        .dialog.dialogCalendar .calendarHeader .calendar-switch a {
          width: 50%;
          height: 28px;
          float: left;
          font-family: "Montserrat";
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 28px;
          letter-spacing: normal;
          text-align: center;
          color: #474e5e;
          border-radius: 15px; }
          .dialog.dialogCalendar .calendarHeader .calendar-switch a.active {
            color: #fff;
            object-fit: contain;
            border-radius: 15px;
            background-image: linear-gradient(263deg, #fdd271, #d5a433); }
      .dialog.dialogCalendar .calendarHeader .choose_view {
        width: max-content;
        margin-top: 3px;
        float: right;
        margin-right: 15px; }
        .dialog.dialogCalendar .calendarHeader .choose_view > div {
          width: 100%;
          display: flex;
          flex-direction: inherit;
          justify-content: center;
          height: 22px; }
          .dialog.dialogCalendar .calendarHeader .choose_view > div .choices {
            margin-left: 15px;
            flex-direction: row;
            display: flex;
            flex-wrap: wrap;
            border: 0px solid #d7d5d5;
            border-radius: 9px;
            width: max-content;
            height: 22px;
            margin: auto; }
            .dialog.dialogCalendar .calendarHeader .choose_view > div .choices > span {
              padding: 5px 10px;
              display: block;
              min-width: 90px;
              font-size: 10px;
              border: 1px solid #d1dbe2;
              color: #51c4bc;
              background-color: white;
              text-align: center;
              cursor: pointer;
              border-radius: 15px;
              margin: 0px 4px;
              height: 100%;
              line-height: 14px;
              font-weight: bold; }
              .dialog.dialogCalendar .calendarHeader .choose_view > div .choices > span.active {
                color: white;
                box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
                background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
                border: 0px solid #51c4bc; }
      .dialog.dialogCalendar .calendarHeader .onClose {
        position: absolute;
        cursor: pointer;
        top: -20px;
        right: 0;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
        text-align: center;
        color: #fff;
        font-size: 40px;
        line-height: 40px; }
    .dialog.dialogCalendar .calendar {
      border: solid 1px rgba(140, 151, 178, 0.2);
      float: left;
      width: 100%; }
      .dialog.dialogCalendar .calendar header {
        width: 100%;
        float: left;
        padding: 0;
        height: 50px;
        object-fit: contain;
        border-bottom: solid 1px rgba(140, 151, 178, 0.2);
        border-top: solid 1px rgba(140, 151, 178, 0.2);
        background-color: rgba(177, 191, 205, 0.31); }
        .dialog.dialogCalendar .calendar header.header-week {
          height: 60px; }
        .dialog.dialogCalendar .calendar header .month-display {
          display: none; }
        .dialog.dialogCalendar .calendar header .day-names .day {
          float: left;
          width: 14.28%;
          opacity: 0.8;
          font-family: Montserrat;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 50px;
          -webkit-letter-spacing: normal;
          -moz-letter-spacing: normal;
          -ms-letter-spacing: normal;
          letter-spacing: normal;
          text-align: center;
          color: #1b2437;
          text-transform: uppercase;
          border-left: solid 1px rgba(140, 151, 178, 0.2); }
          .dialog.dialogCalendar .calendar header .day-names .day:first-child {
            border-left: none; }
        .dialog.dialogCalendar .calendar header .day-names.day-names-week .day {
          width: 16.66%;
          line-height: 20px;
          padding-top: 11px;
          font-size: 13px; }
          .dialog.dialogCalendar .calendar header .day-names.day-names-week .day b {
            display: block;
            font-weight: 600;
            color: #858b97;
            font-size: 11px; }
      .dialog.dialogCalendar .calendar .weekDetails {
        float: left;
        width: 100%; }
        .dialog.dialogCalendar .calendar .weekDetails .weekDetail {
          width: 16.66%;
          float: left;
          border-left: solid 1px rgba(140, 151, 178, 0.2);
          padding: 14px 6px;
          height: 500px; }
          .dialog.dialogCalendar .calendar .weekDetails .weekDetail:first-child {
            border-left: none; }
      .dialog.dialogCalendar .calendar .week {
        float: left;
        width: 100%;
        margin: 0; }
        .dialog.dialogCalendar .calendar .week .day {
          float: left;
          width: 14.28%;
          height: 100px;
          border-bottom: solid 1px rgba(140, 151, 178, 0.2);
          border-left: solid 1px rgba(140, 151, 178, 0.2);
          position: relative;
          padding: 40px 4px 4px; }
          .dialog.dialogCalendar .calendar .week .day span {
            font-family: "Montserrat";
            font-size: 17px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: 0.52px;
            color: #9b9b9b;
            position: absolute;
            top: 2px;
            right: 2px;
            width: 32px;
            height: 32px;
            border-radius: 36px;
            line-height: 32px;
            text-align: center; }
          .dialog.dialogCalendar .calendar .week .day b {
            font-family: "Montserrat";
            font-size: 17px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.52px;
            color: #1b2437;
            position: absolute;
            left: 8px;
            top: 8px; }
          .dialog.dialogCalendar .calendar .week .day .event {
            object-fit: contain;
            border-radius: 4px;
            border: solid 1px rgba(132, 177, 228, 0.2);
            background-color: rgba(132, 177, 228, 0.2);
            padding: 4px;
            width: 100%;
            margin-bottom: 2px; }
          .dialog.dialogCalendar .calendar .week .day.selected span {
            color: #fff;
            box-shadow: 0 3px 17px 0 rgba(70, 85, 246, 0.45);
            background-image: linear-gradient(225deg, #6975f4, #4151f5); }
          .dialog.dialogCalendar .calendar .week .day.different-month span {
            opacity: 0.5; }
          .dialog.dialogCalendar .calendar .week .day.today span {
            color: #fff;
            box-shadow: 0 3px 17px 0 rgba(70, 85, 246, 0.45);
            background-image: linear-gradient(225deg, #6975f4, #4151f5); }
    .dialog.dialogCalendar .to_print .schedular_header .school_info {
      display: flex;
      justify-content: space-between; }
      .dialog.dialogCalendar .to_print .schedular_header .school_info .school img {
        width: 100px;
        height: 100px;
        margin-right: 15px; }
      .dialog.dialogCalendar .to_print .schedular_header .school_info .school span {
        margin-left: 10px;
        font-weight: bold; }
      .dialog.dialogCalendar .to_print .schedular_header .school_info .scholar_year {
        line-height: 100px;
        font-weight: bold; }
    .dialog.dialogCalendar .to_print .schedular_header .classe {
      text-align: center;
      padding-bottom: 10px;
      margin-top: -12px; }
      .dialog.dialogCalendar .to_print .schedular_header .classe span {
        margin-left: 10px;
        font-weight: bold; }
    .dialog.dialogCalendar .to_print .day {
      line-height: 20px !important; }
  .dialog.dialogAnnualReviews {
    height: 100%;
    overflow: hidden;
    max-width: 80%;
    width: 80%; }
    .dialog.dialogAnnualReviews .reviewsHeader {
      padding: 5px 25px 5px 5px;
      position: relative;
      width: 100%;
      float: left; }
      .dialog.dialogAnnualReviews .reviewsHeader .choose_view {
        width: max-content;
        margin-top: 3px;
        float: right;
        margin-right: 15px; }
        .dialog.dialogAnnualReviews .reviewsHeader .choose_view > div {
          width: 100%;
          display: flex;
          flex-direction: inherit;
          justify-content: center;
          height: 22px; }
          .dialog.dialogAnnualReviews .reviewsHeader .choose_view > div .choices {
            margin-left: 15px;
            flex-direction: row;
            display: flex;
            flex-wrap: wrap;
            border: 0px solid #d7d5d5;
            border-radius: 9px;
            width: max-content;
            height: 22px;
            margin: auto; }
            .dialog.dialogAnnualReviews .reviewsHeader .choose_view > div .choices > span {
              padding: 5px 10px;
              display: block;
              min-width: 90px;
              font-size: 10px;
              border: 1px solid #d1dbe2;
              color: #51c4bc;
              background-color: white;
              text-align: center;
              cursor: pointer;
              border-radius: 15px;
              margin: 0px 4px;
              height: 100%;
              line-height: 14px;
              font-weight: bold; }
              .dialog.dialogAnnualReviews .reviewsHeader .choose_view > div .choices > span.active {
                color: white;
                box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
                background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
                border: 0px solid #51c4bc; }
            .dialog.dialogAnnualReviews .reviewsHeader .choose_view > div .choices > div .react-switch-bg {
              height: 22px !important;
              width: 51px !important; }
              .dialog.dialogAnnualReviews .reviewsHeader .choose_view > div .choices > div .react-switch-bg > div {
                height: 22px !important; }
            .dialog.dialogAnnualReviews .reviewsHeader .choose_view > div .choices > div .react-switch-handle {
              height: 20px !important;
              width: 20px !important; }
      .dialog.dialogAnnualReviews .reviewsHeader .onClose {
        position: absolute;
        cursor: pointer;
        top: -20px;
        right: 0;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
        text-align: center;
        color: #fff;
        font-size: 40px;
        line-height: 40px; }
    .dialog.dialogAnnualReviews .reviews {
      border: solid 1px rgba(140, 151, 178, 0.2);
      float: left;
      width: 100%;
      min-height: calc(100% - 35px); }
      .dialog.dialogAnnualReviews .reviews.not_print {
        overflow: auto;
        height: calc(100% - 35px); }
      .dialog.dialogAnnualReviews .reviews header {
        width: 100%;
        float: left;
        padding: 0;
        height: 50px;
        object-fit: contain;
        border-bottom: solid 1px rgba(140, 151, 178, 0.2);
        border-top: solid 1px rgba(140, 151, 178, 0.2);
        background-color: rgba(177, 191, 205, 0.31); }
      .dialog.dialogAnnualReviews .reviews > div {
        height: 100%; }
        .dialog.dialogAnnualReviews .reviews > div .annual_reviews_page {
          width: 100%;
          height: 100%;
          margin: 0; }
          .dialog.dialogAnnualReviews .reviews > div .annual_reviews_page .annual_reviews_container {
            margin-top: 0; }
    .dialog.dialogAnnualReviews .to_print {
      background: white;
      width: 8.1in; }
      .dialog.dialogAnnualReviews .to_print .annual_reviews_container {
        padding: 1rem 10px 20px; }
      .dialog.dialogAnnualReviews .to_print .print_reviews_header .school_info {
        display: flex;
        justify-content: space-between; }
        .dialog.dialogAnnualReviews .to_print .print_reviews_header .school_info .school img {
          width: 100px;
          height: 100px;
          margin-right: 15px; }
        .dialog.dialogAnnualReviews .to_print .print_reviews_header .school_info .school span {
          margin-left: 10px;
          font-weight: bold; }
        .dialog.dialogAnnualReviews .to_print .print_reviews_header .school_info .scholar_year {
          line-height: 100px;
          font-weight: bold; }
      .dialog.dialogAnnualReviews .to_print .print_reviews_header .classe {
        text-align: right;
        padding-bottom: 10px;
        margin-top: -60px;
        padding-right: 10px; }
        .dialog.dialogAnnualReviews .to_print .print_reviews_header .classe span {
          margin-left: 10px;
          font-weight: bold; }
      .dialog.dialogAnnualReviews .to_print .reviews {
        margin-top: 40px;
        height: calc(100% - 164px);
        position: relative;
        min-height: unset;
        border: 0; }
        .dialog.dialogAnnualReviews .to_print .reviews .date_div {
          position: absolute;
          bottom: 0;
          left: 4px;
          width: calc(100% - 8px);
          justify-content: flex-end; }
          .dialog.dialogAnnualReviews .to_print .reviews .date_div .key {
            display: none; }
          .dialog.dialogAnnualReviews .to_print .reviews .date_div .val {
            text-align: right;
            font-size: 9px; }
      .dialog.dialogAnnualReviews .to_print .annual_reviews_page .a_r_single_table {
        border: 0;
        margin-top: 0; }
      .dialog.dialogAnnualReviews .to_print .rev_footer {
        padding: 0 40px;
        height: unset; }
        .dialog.dialogAnnualReviews .to_print .rev_footer > div {
          width: 100%;
          display: flex;
          justify-content: flex-end; }
          .dialog.dialogAnnualReviews .to_print .rev_footer > div .key {
            font-weight: bold;
            width: 150px; }
          .dialog.dialogAnnualReviews .to_print .rev_footer > div .val {
            font-weight: bold;
            width: 150px; }
          .dialog.dialogAnnualReviews .to_print .rev_footer > div.signature {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end; }
            .dialog.dialogAnnualReviews .to_print .rev_footer > div.signature > div {
              width: 30%;
              margin-left: 70%;
              text-align: center;
              font-weight: bold; }
              .dialog.dialogAnnualReviews .to_print .rev_footer > div.signature > div.sign {
                border: 1px solid black;
                border-radius: 5px;
                height: 100px; }
      .dialog.dialogAnnualReviews .to_print .rev_head {
        padding: 0 40px;
        padding-right: 0;
        height: unset; }
        .dialog.dialogAnnualReviews .to_print .rev_head > div {
          width: 100%;
          display: flex;
          justify-content: flex-end; }
          .dialog.dialogAnnualReviews .to_print .rev_head > div .key {
            font-weight: bold;
            width: 150px; }
          .dialog.dialogAnnualReviews .to_print .rev_head > div .val {
            font-weight: bold;
            width: 90px; }
          .dialog.dialogAnnualReviews .to_print .rev_head > div.signature {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end; }
            .dialog.dialogAnnualReviews .to_print .rev_head > div.signature > div {
              width: 30%;
              margin-left: 70%;
              text-align: center;
              font-weight: bold; }
              .dialog.dialogAnnualReviews .to_print .rev_head > div.signature > div.sign {
                border: 1px solid black;
                border-radius: 5px;
                height: 100px; }
      .dialog.dialogAnnualReviews .to_print .rev_footer > div {
        justify-content: flex-start; }
      .dialog.dialogAnnualReviews .to_print .footer-revs_print {
        position: relative;
        height: calc(100% - 70px); }
        .dialog.dialogAnnualReviews .to_print .footer-revs_print .ctt {
          font-weight: bold;
          color: black;
          width: 100%;
          border-top: 1px solid;
          left: 0;
          right: 0;
          text-align: center;
          top: unset;
          bottom: 0;
          position: absolute; }
          .dialog.dialogAnnualReviews .to_print .footer-revs_print .ctt > div {
            width: 100%;
            line-height: 26px;
            display: block; }
  .dialog.dialogStudentsDocuments {
    max-width: 100%;
    width: 100%;
    border-left: none;
    border-radius: 20px;
    padding: 15px;
    min-height: 100%; }
    .dialog.dialogStudentsDocuments.dialogStudentsPayments {
      display: flex;
      flex-direction: column; }
    @media (max-width: 1199px) {
      .dialog.dialogStudentsDocuments {
        padding: 0px; } }
  .dialog.dialogStudentSchedule .student_schedule {
    height: 100%; }
    .dialog.dialogStudentSchedule .student_schedule .schedule {
      height: 100%;
      overflow-y: auto; }
  .dialog.CourbeDetails {
    max-width: 90%;
    width: 90%;
    border-left: none;
    border-radius: 20px;
    padding: 15px;
    height: 90%;
    min-height: 440px; }
    @media (max-width: 1199px) {
      .dialog.CourbeDetails {
        padding: 0px; } }
  .dialog.dialogPermissions {
    max-width: 900px;
    width: 900px;
    border-left: none;
    border-radius: 20px;
    padding: 15px;
    height: 100%;
    min-height: 440px; }
    .dialog.dialogPermissions.autoHeight {
      object-fit: contain;
      height: auto;
      min-height: auto; }
    @media (max-width: 1199px) {
      .dialog.dialogPermissions {
        padding: 0px; } }
  .dialog.dialogSignatures {
    max-width: 98%;
    width: 98%;
    border-left: none;
    border-radius: 20px;
    padding: 15px;
    height: 98%;
    min-height: 98%;
    display: flex;
    flex-direction: column; }
    .dialog.dialogSignatures.autoHeight {
      object-fit: contain;
      height: auto;
      min-height: auto; }
    @media (max-width: 1199px) {
      .dialog.dialogSignatures {
        padding: 0px; } }
  .dialog.dialogPaymentDetails {
    width: 400px;
    border: none;
    border-radius: 20px;
    padding: 40px; }
    @media (max-width: 1199px) {
      .dialog.dialogPaymentDetails {
        padding: 0px; } }
  .dialog.dialogPaymentDetailsDuplicate {
    width: 80%;
    border: none;
    border-radius: 20px;
    padding: 40px; }
    @media (max-width: 1199px) {
      .dialog.dialogPaymentDetailsDuplicate {
        padding: 0px; } }
  .dialog.dialogDeleteSchool {
    width: 440px;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 25px;
    border-left: none;
    position: relative; }
    .dialog.dialogDeleteSchool.dialogPrintConvoc {
      width: auto; }
      .dialog.dialogDeleteSchool.dialogPrintConvoc section {
        width: auto; }
    .dialog.dialogDeleteSchool h2 {
      margin: 0 0 25px;
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1b2437;
      margin-bottom: 28px; }
    .dialog.dialogDeleteSchool p {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1b2437;
      margin: 0; }
    .dialog.dialogDeleteSchool .button-delete {
      margin-top: 30px;
      text-align: center; }
      .dialog.dialogDeleteSchool .button-delete button {
        width: 106px;
        height: 30px;
        object-fit: contain;
        border-radius: 49.5px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3eb6ad;
        margin: 0 5px; }
        .dialog.dialogDeleteSchool .button-delete button.yes {
          box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
          background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
          color: #fff;
          border: none; }
  .dialog.dialogTypeUpdate {
    width: 540px;
    min-height: 300px;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 25px;
    border-left: none;
    position: relative; }
    .dialog.dialogTypeUpdate h2 {
      margin: 0 0 25px;
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1b2437;
      margin-bottom: 28px; }
    .dialog.dialogTypeUpdate .content_types {
      height: 100%; }
      .dialog.dialogTypeUpdate .content_types .list_types {
        width: 100%;
        height: calc(100% - 110px);
        display: flex;
        flex-direction: column; }
    .dialog.dialogTypeUpdate .between_dates_dates .ligne {
      display: flex;
      justify-content: space-between; }
      .dialog.dialogTypeUpdate .between_dates_dates .ligne .w50 {
        width: 49%; }
        .dialog.dialogTypeUpdate .between_dates_dates .ligne .w50 label {
          margin-bottom: 0rem;
          margin-top: 0.5rem; }
        .dialog.dialogTypeUpdate .between_dates_dates .ligne .w50 .react-datepicker-wrapper {
          margin: 0; }
    .dialog.dialogTypeUpdate .between_dates_dates.disabled {
      opacity: 0.7; }
    .dialog.dialogTypeUpdate .button-delete {
      margin-top: 30px;
      text-align: center; }
      .dialog.dialogTypeUpdate .button-delete button {
        width: 106px;
        height: 30px;
        object-fit: contain;
        border-radius: 49.5px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3eb6ad;
        margin: 0 5px; }
        .dialog.dialogTypeUpdate .button-delete button.yes {
          box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
          background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
          color: #fff;
          border: none; }
  .dialog.dialogTypeDelete {
    width: 540px;
    min-height: 300px;
    height: max-content;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 25px;
    border-left: none;
    position: relative; }
    .dialog.dialogTypeDelete h2 {
      margin: 0 0 25px;
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1b2437;
      margin-bottom: 28px; }
    .dialog.dialogTypeDelete .content_types {
      height: 100%; }
      .dialog.dialogTypeDelete .content_types .list_types {
        width: 100%;
        height: calc(100% - 110px);
        display: flex;
        flex-direction: column; }
    .dialog.dialogTypeDelete .delete_seance_periods_select {
      margin-top: 15px; }
    .dialog.dialogTypeDelete .button-delete {
      margin-top: 30px;
      text-align: center; }
      .dialog.dialogTypeDelete .button-delete button {
        width: 106px;
        height: 30px;
        object-fit: contain;
        border-radius: 49.5px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3eb6ad;
        margin: 0 5px; }
        .dialog.dialogTypeDelete .button-delete button.yes {
          box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
          background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
          color: #fff;
          border: none; }
  .dialog.dialogConnexion {
    width: 580px;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 50px 80px 55px;
    border-left: none;
    position: relative; }
    .dialog.dialogConnexion .resend_activation_data {
      padding-bottom: 15px;
      padding-top: 15px; }
      .dialog.dialogConnexion .resend_activation_data .resend_activation_data_btn {
        width: 106px;
        height: 25px;
        float: right;
        object-fit: contain;
        border-radius: 49.5px;
        box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: -webkit-linear-gradient(54deg, #96d5f2, #0ea2cc);
        background-image: linear-gradient(54deg, #96d5f2, #0ea2cc);
        color: #fff;
        border: none;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 25px;
        letter-spacing: normal;
        margin: 0; }
    .dialog.dialogConnexion .resend_activation_data_message {
      color: green;
      padding: 10px 0; }
    .dialog.dialogConnexion .modalConnexion .modalConnexionTop .textErrors {
      color: #f13434;
      font-size: 10px;
      font-family: "Montserrat", sans-serif; }
    .dialog.dialogConnexion .modalConnexion .modalConnexionTop h2 {
      color: #3eb6ad; }
    .dialog.dialogConnexion .modalConnexion .modalConnexionTop p {
      float: right;
      font-family: Montserrat;
      font-size: 10.6px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #a7b7bc;
      margin: 5px 21px 0 0; }
    .dialog.dialogConnexion .modalConnexion.modalform {
      overflow-y: scroll;
      height: 100%;
      overflow-x: hidden; }
      .dialog.dialogConnexion .modalConnexion.modalform::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5; }
      .dialog.dialogConnexion .modalConnexion.modalform::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5; }
      .dialog.dialogConnexion .modalConnexion.modalform::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
    .dialog.dialogConnexion.dialogRegister {
      /*width: 480px;*/
      min-width: 480px;
      padding: 25px 30px; }
      .dialog.dialogConnexion.dialogRegister .resend_activation_data {
        padding-bottom: 15px; }
        .dialog.dialogConnexion.dialogRegister .resend_activation_data .resend_activation_data_btn {
          width: 106px;
          height: 25px;
          float: right;
          object-fit: contain;
          border-radius: 49.5px;
          box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: -webkit-linear-gradient(54deg, #96d5f2, #0ea2cc);
          background-image: linear-gradient(54deg, #96d5f2, #0ea2cc);
          color: #fff;
          border: none;
          text-align: center;
          font-family: Montserrat;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 25px;
          letter-spacing: normal;
          margin: 0; }
      .dialog.dialogConnexion.dialogRegister .resend_activation_data_message {
        color: green;
        padding: 10px 0; }
      .dialog.dialogConnexion.dialogRegister.dialogAddStudents .modalConnexionForm .connexionLigne.connexionLigneCenter {
        text-align: center; }
      .dialog.dialogConnexion.dialogRegister.dialogAddStudents .modalConnexionForm .connexionLigne button.add {
        float: none;
        display: inline-block;
        margin: 0 4.5px; }
      .dialog.dialogConnexion.dialogRegister:before {
        display: none; }
      .dialog.dialogConnexion.dialogRegister .modalConnexionTop h2 {
        color: #3eb6ad;
        float: left; }
      .dialog.dialogConnexion.dialogRegister .modalConnexionTop p {
        float: right;
        font-family: Montserrat;
        font-size: 10.6px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #a7b7bc;
        margin: 5px 21px 0 0; }
      .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne.w12 {
        margin-bottom: 25px; }
      .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne.connexionGender label {
        display: inline-block;
        margin-right: 8px; }
      .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne a.terms_link {
        float: none; }
      .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .connexionW33 {
        padding: 0 10px;
        width: 33.33%;
        float: left; }
      .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .gender {
        display: inline-block; }
        .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .gender > div {
          display: inline-block;
          position: relative; }
          .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .gender > div input {
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            left: 0px;
            cursor: pointer; }
          .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .gender > div span {
            float: left;
            width: 27px;
            height: 27px; }
          .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .gender > div b {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 27px;
            margin-left: 7px;
            letter-spacing: normal;
            color: rgba(27, 36, 55, 0.8); }
        .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .gender .choiseSituation {
          display: inline-block; }
          .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .gender .choiseSituation span {
            background: url("../img/iconMale.svg") no-repeat scroll transparent;
            background-size: contain; }
          .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .gender .choiseSituation input:checked + span {
            background: url("../img/iconMaleActive.svg") no-repeat scroll transparent; }
        .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .gender .genderMale {
          display: inline-block; }
          .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .gender .genderMale span {
            background: url("../img/iconMale.svg") no-repeat scroll transparent;
            background-size: contain; }
          .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .gender .genderMale input:checked + span {
            background: url("../img/iconMaleActive.svg") no-repeat scroll transparent; }
        .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .gender .genderFemale {
          display: inline-block;
          margin-left: 18px; }
          .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .gender .genderFemale span {
            background: url("../img/iconFemale.svg") no-repeat scroll transparent;
            background-size: contain; }
          .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .gender .genderFemale input:checked + span {
            background: url("../img/iconFemaleActive.svg") no-repeat scroll transparent; }
      .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .RadioLegend {
        float: left;
        margin-right: 25px; }
        .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .RadioLegend:last-child {
          margin-right: 0; }
        .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .RadioLegend > span {
          padding: 0;
          float: left;
          flex: none;
          display: initial; }
        .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .RadioLegend > legend {
          float: left;
          margin: 0;
          font-family: Montserrat;
          font-size: 13px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          margin-left: 5px;
          margin-top: 3px; }
      .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne textarea {
        float: left;
        width: 100%;
        height: 50px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        resize: none; }
      .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne a {
        color: #3eb6ac;
        font-size: 11px;
        font-weight: 600;
        float: left;
        margin-top: 7px; }
      .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .select {
        float: left;
        width: 100%;
        height: 26.5px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        position: relative;
        padding-left: 3px; }
        .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .select select {
          float: left;
          width: 100%;
          font-size: 12px;
          height: 25.5px;
          border: none;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          text-indent: 1px;
          text-overflow: "";
          background: transparent;
          outline: 0;
          position: relative;
          z-index: 9;
          border-radius: 4px !important;
          border: none !important;
          font-weight: 500;
          font-family: Montserrat;
          font-size: 14px;
          color: #1b2437;
          padding: 0 10px; }
          .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .select select::-ms-expand {
            display: none; }
        .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne .select i {
          color: #d2dbe2;
          font-size: 16px;
          position: absolute;
          right: 5px;
          top: 5px; }
      .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne button.add {
        float: right;
        min-width: 106px;
        height: 30px;
        object-fit: contain;
        border-radius: 49.5px;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: normal;
        box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
        background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
        color: #fff;
        border: none; }
        .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne button.add.cancel {
          background: #fff;
          color: #3eb6ad;
          box-shadow: none;
          border: solid 0.6px #d1dbe2; }
        .dialog.dialogConnexion.dialogRegister .modalConnexionForm .connexionLigne button.add.newStudents {
          width: 155px; }
    .dialog.dialogConnexion.dialogCandid {
      /*width: 480px;*/
      min-width: 480px;
      height: 695px;
      padding: 25px 15px 25px 30px; }
      .dialog.dialogConnexion.dialogCandid::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5; }
      .dialog.dialogConnexion.dialogCandid::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5; }
      .dialog.dialogConnexion.dialogCandid::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
      .dialog.dialogConnexion.dialogCandid .resend_activation_data {
        padding-bottom: 15px; }
        .dialog.dialogConnexion.dialogCandid .resend_activation_data .resend_activation_data_btn {
          width: 106px;
          height: 25px;
          float: right;
          object-fit: contain;
          border-radius: 49.5px;
          box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: -webkit-linear-gradient(54deg, #96d5f2, #0ea2cc);
          background-image: linear-gradient(54deg, #96d5f2, #0ea2cc);
          color: #fff;
          border: none;
          text-align: center;
          font-family: Montserrat;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 25px;
          letter-spacing: normal;
          margin: 0; }
      .dialog.dialogConnexion.dialogCandid .resend_activation_data_message {
        color: green;
        padding: 10px 0; }
      .dialog.dialogConnexion.dialogCandid.dialogAddStudents .modalConnexionForm .connexionLigne.connexionLigneCenter {
        text-align: center; }
      .dialog.dialogConnexion.dialogCandid.dialogAddStudents .modalConnexionForm .connexionLigne button.add {
        float: none;
        display: inline-block;
        margin: 0 4.5px; }
      .dialog.dialogConnexion.dialogCandid:before {
        display: none; }
      .dialog.dialogConnexion.dialogCandid .modalConnexionTop h2 {
        color: #3eb6ad;
        float: left; }
      .dialog.dialogConnexion.dialogCandid .modalConnexionTop p {
        float: right;
        font-family: Montserrat;
        font-size: 10.6px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #a7b7bc;
        margin: 5px 21px 0 0; }
      .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne.w12 {
        margin-bottom: 25px; }
      .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne.connexionGender label {
        display: inline-block;
        margin-right: 8px; }
      .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne a.terms_link {
        float: none; }
      .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .connexionW33 {
        padding: 0 10px;
        width: 33.33%;
        float: left; }
      .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .gender {
        display: inline-block; }
        .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .gender > div {
          display: inline-block;
          position: relative; }
          .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .gender > div input {
            position: absolute;
            opacity: 0;
            width: 100%;
            height: 100%;
            left: 0px;
            cursor: pointer; }
          .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .gender > div span {
            float: left;
            width: 27px;
            height: 27px; }
          .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .gender > div b {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 27px;
            margin-left: 7px;
            letter-spacing: normal;
            color: rgba(27, 36, 55, 0.8); }
        .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .gender .choiseSituation {
          display: inline-block; }
          .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .gender .choiseSituation span {
            background: url("../img/iconMale.svg") no-repeat scroll transparent;
            background-size: contain; }
          .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .gender .choiseSituation input:checked + span {
            background: url("../img/iconMaleActive.svg") no-repeat scroll transparent; }
        .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .gender .genderMale {
          display: inline-block; }
          .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .gender .genderMale span {
            background: url("../img/iconMale.svg") no-repeat scroll transparent;
            background-size: contain; }
          .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .gender .genderMale input:checked + span {
            background: url("../img/iconMaleActive.svg") no-repeat scroll transparent; }
        .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .gender .genderFemale {
          display: inline-block;
          margin-left: 18px; }
          .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .gender .genderFemale span {
            background: url("../img/iconFemale.svg") no-repeat scroll transparent;
            background-size: contain; }
          .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .gender .genderFemale input:checked + span {
            background: url("../img/iconFemaleActive.svg") no-repeat scroll transparent; }
      .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .RadioLegend {
        float: left;
        margin-right: 25px; }
        .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .RadioLegend:last-child {
          margin-right: 0; }
        .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .RadioLegend > span {
          padding: 0;
          float: left;
          flex: none;
          display: initial; }
        .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .RadioLegend > legend {
          float: left;
          margin: 0;
          font-family: Montserrat;
          font-size: 13px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          margin-left: 5px;
          margin-top: 3px; }
      .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne textarea {
        float: left;
        width: 100%;
        height: 50px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        resize: none; }
      .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne a {
        color: #3eb6ac;
        font-size: 11px;
        font-weight: 600;
        float: left;
        margin-top: 7px; }
      .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .select {
        float: left;
        width: 100%;
        height: 26.5px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        position: relative;
        padding-left: 3px; }
        .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .select select {
          float: left;
          width: 100%;
          font-size: 12px;
          height: 25.5px;
          border: none;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          text-indent: 1px;
          text-overflow: "";
          background: transparent;
          outline: 0;
          position: relative;
          z-index: 9;
          border-radius: 4px !important;
          border: none !important;
          font-weight: 500;
          font-family: Montserrat;
          font-size: 14px;
          color: #1b2437;
          padding: 0 10px; }
          .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .select select::-ms-expand {
            display: none; }
        .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne .select i {
          color: #d2dbe2;
          font-size: 16px;
          position: absolute;
          right: 5px;
          top: 5px; }
      .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne button.add {
        float: right;
        min-width: 106px;
        height: 30px;
        object-fit: contain;
        border-radius: 49.5px;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: normal;
        box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
        background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
        color: #fff;
        border: none; }
        .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne button.add.cancel {
          background: #fff;
          color: #3eb6ad;
          box-shadow: none;
          border: solid 0.6px #d1dbe2; }
        .dialog.dialogConnexion.dialogCandid .modalConnexionForm .connexionLigne button.add.newStudents {
          width: 155px; }
    .dialog.dialogConnexion.dialogInscription {
      min-width: 80%;
      height: 100%;
      padding: 0;
      padding-bottom: 30px; }
      .dialog.dialogConnexion.dialogInscription .modalConnexion {
        overflow: auto;
        overflow-x: hidden; }
        .dialog.dialogConnexion.dialogInscription .modalConnexion > div > .row {
          margin: auto;
          margin-top: 10px; }
      .dialog.dialogConnexion.dialogInscription .modalConnexionTop .schoolClose {
        background: #1c4381;
        position: absolute;
        top: -23px;
        right: -23px;
        border: 5px solid #fff;
        width: 40px;
        height: 40px;
        text-align: center;
        color: #fff;
        border-radius: 50%;
        font-size: 31px;
        line-height: 28px;
        box-shadow: none;
        background-image: none;
        font-weight: bold; }
      .dialog.dialogConnexion.dialogInscription .modalConnexionTop .menu {
        width: 100%;
        background: #1c4381;
        padding: 0px 25px;
        padding-top: 10px;
        height: 54px;
        border-radius: 10px 10px 0 0; }
        .dialog.dialogConnexion.dialogInscription .modalConnexionTop .menu .slick-slider .slick-list .slick-slide {
          height: 44px; }
          .dialog.dialogConnexion.dialogInscription .modalConnexionTop .menu .slick-slider .slick-list .slick-slide > div {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative; }
          .dialog.dialogConnexion.dialogInscription .modalConnexionTop .menu .slick-slider .slick-list .slick-slide a {
            color: white;
            text-align: center;
            padding-bottom: 10px;
            opacity: 0.54; }
            .dialog.dialogConnexion.dialogInscription .modalConnexionTop .menu .slick-slider .slick-list .slick-slide a.active {
              font-weight: bold;
              opacity: 1; }
              .dialog.dialogConnexion.dialogInscription .modalConnexionTop .menu .slick-slider .slick-list .slick-slide a.active:before {
                content: "";
                position: absolute;
                left: 50%;
                margin-left: -10px;
                bottom: 0;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 8px solid #fff; }
            .dialog.dialogConnexion.dialogInscription .modalConnexionTop .menu .slick-slider .slick-list .slick-slide a span {
              display: block;
              font-family: Montserrat,sans-serif;
              text-transform: uppercase; }
            .dialog.dialogConnexion.dialogInscription .modalConnexionTop .menu .slick-slider .slick-list .slick-slide a.disabled {
              cursor: not-allowed; }
        .dialog.dialogConnexion.dialogInscription .modalConnexionTop .menu .slick-slider .slick-arrow:before {
          font: normal normal normal 23px/1 FontAwesome;
          line-height: normal;
          color: #fff; }
        .dialog.dialogConnexion.dialogInscription .modalConnexionTop .menu .slick-slider .slick-arrow.slick-prev:before {
          content: "\f104"; }
        .dialog.dialogConnexion.dialogInscription .modalConnexionTop .menu .slick-slider .slick-arrow.slick-next:before {
          content: "\f105"; }
        .dialog.dialogConnexion.dialogInscription .modalConnexionTop .menu ul {
          padding: 0;
          border: 2px solid gray;
          border-radius: 15px;
          display: flex;
          margin: 0; }
          .dialog.dialogConnexion.dialogInscription .modalConnexionTop .menu ul li {
            cursor: pointer;
            padding: 5px;
            background: #bdbcbc;
            border-right: 1px solid gray; }
            .dialog.dialogConnexion.dialogInscription .modalConnexionTop .menu ul li.active {
              font-weight: bold;
              color: white;
              background: #3eb6ad; }
            .dialog.dialogConnexion.dialogInscription .modalConnexionTop .menu ul li.disabled {
              cursor: not-allowed; }
            .dialog.dialogConnexion.dialogInscription .modalConnexionTop .menu ul li:first-of-type {
              border-radius: 12px 0 0 12px; }
            .dialog.dialogConnexion.dialogInscription .modalConnexionTop .menu ul li:last-of-type {
              border-radius: 0 12px 12px 0;
              border-right: 0; }
      .dialog.dialogConnexion.dialogInscription .modalConnexionTop.format_2 .schoolClose {
        background: #06dbb8;
        position: absolute;
        top: -23px;
        right: -23px;
        border: 5px solid #fff;
        width: 40px;
        height: 40px;
        text-align: center;
        color: #fff;
        border-radius: 50%;
        font-size: 31px;
        line-height: 28px;
        box-shadow: none;
        background-image: none;
        font-weight: bold; }
      .dialog.dialogConnexion.dialogInscription .modalConnexionTop.format_2 .menu_page {
        padding: 0px 25px;
        padding-top: 10px;
        height: 54px;
        width: max-content;
        margin: auto;
        border-radius: 10px;
        height: 40px;
        margin-top: 14px;
        background: #06dbb8; }
        .dialog.dialogConnexion.dialogInscription .modalConnexionTop.format_2 .menu_page .page {
          line-height: 23px;
          font-weight: bold;
          color: black;
          font-size: 20px; }
      .dialog.dialogConnexion.dialogInscription .modalInscriptionContent {
        display: flex;
        width: 100%;
        height: calc(100% - 94px);
        flex-wrap: wrap;
        padding: 10px 25px; }
        .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form {
          width: 100%;
          padding-right: 10px;
          height: calc(100% - 0px);
          overflow-y: auto;
          overflow-x: hidden; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .title {
            font-weight: bold;
            border: 1px solid #56729f;
            background-color: #56729f;
            border-radius: 27px;
            padding: 9px !important;
            color: #fff !important;
            font-size: 12px;
            font-weight: 700;
            align-items: center;
            text-align: center; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .title2 {
            font-weight: bold;
            border-radius: 27px;
            padding: 0 25px;
            color: black !important;
            font-size: 12px;
            font-weight: 700;
            text-align: left;
            margin-top: 5px;
            margin-bottom: -5px; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .all_children {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: 8px;
            border: 1px solid #d1dbe2;
            overflow: hidden; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .all_children table {
              width: 100%; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .all_children table tr th {
                text-align: center;
                padding: 10px;
                background-color: white;
                border: 1px solid #e8e8e8;
                border-width: 0 1px 0 0; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .all_children table tr th:last-of-type {
                  border-right: 0; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .all_children table tr td {
                background-color: white;
                border: 1px solid #e8e8e8;
                border-width: 1px 1px 1px 1px;
                height: 50px;
                padding: 10px; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .all_children table tr td:first-of-type {
                  border-left: 0; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .all_children table tr td:last-of-type {
                  border-right: 0; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .all_children table tr.current {
                font-weight: bold; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .all_children table tr:last-of-type td {
                border-bottom: 0; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .child_name {
            display: flex;
            padding: 9px !important;
            justify-content: center;
            padding-top: 0 !important;
            font-size: 20px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .child_name > div {
              width: max-content;
              display: flex;
              justify-content: flex-start;
              margin: 0 1%; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .child_name > div:first-of-type {
                justify-content: flex-end; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .child_name > div span {
                font-weight: bold; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .child_name > div span.label {
                  margin-right: 10px; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .radios {
            width: 110px;
            display: inline-block;
            margin-left: 5px;
            height: 25px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .radios.empty_legend .RadioLegend {
              margin: 0;
              margin-bottom: 26px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .radios.sms_notif .RadioLegend legend {
              margin: auto;
              margin-top: 6px;
              margin-left: 5px;
              float: left; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .radios.sms_notif .RadioLegend > span {
              float: right; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .radios .RadioLegend > span {
              padding: 0; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line {
            margin-top: 10px;
            padding: 0 10px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line label {
              margin-bottom: 0; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div input {
              float: left;
              width: 100%;
              height: 28px;
              border-radius: 2.6px;
              border: .6px solid #d1dbe2;
              background-color: rgba(245, 248, 249, 0.3);
              font-size: 14px;
              font-weight: 400;
              color: #1b2437 !important;
              padding-left: 10px;
              padding-right: 10px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div input.disabled {
                opacity: 0.5; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div textarea {
              width: 100%;
              height: 100px;
              border-radius: 2.6px;
              border: .6px solid #d1dbe2;
              background-color: rgba(245, 248, 249, 0.3);
              font-size: 14px;
              font-weight: 400;
              color: #1b2437 !important;
              padding: 10px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div textarea:disabled {
                opacity: 0.5; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div .relation {
              display: flex;
              padding-top: 20px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div .relation > div {
                padding-top: 5px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div .relation label::before {
                top: 5px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div .relation label::after {
                top: 5px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div .relation .selectInput {
                padding-left: 0; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div .relation .radios {
                width: max-content;
                min-width: 200px;
                padding-top: 0;
                margin-left: 0; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div .relation .radios legend {
                  margin-top: 8px;
                  margin-right: 10px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div .label.radios {
              margin: 0;
              width: max-content; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div .label.radios .RadioLegend {
                display: flex; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div .label.radios .RadioLegend legend {
                  margin: 7px 0px 0 10px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div.custom_checkbox > div input {
              height: 100%; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div.custom_checkbox > div input:checked ~ .state.p-info label:after {
                transform: translate(2%, -50%) scale(0.6);
                background-color: #51c4bc !important; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div.custom_checkbox > div label, .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div.custom_checkbox > div .label {
              display: block;
              font-family: Montserrat;
              font-size: 15px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: 55px;
              letter-spacing: normal;
              color: #1b2437;
              margin-bottom: 5px;
              white-space: break-spaces;
              margin: auto;
              line-height: 50px;
              padding-left: 37px;
              text-indent: 0; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div.custom_checkbox > div label:before, .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div.custom_checkbox > div label:after, .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div.custom_checkbox > div .label:before, .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div.custom_checkbox > div .label:after {
                top: 50% !important;
                transform: translateY(-50%);
                width: 23px;
                height: 23px;
                left: 5px;
                border: 2px solid #767373; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div.phone label {
              width: 100%; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div.phone .intl-tel-input {
              width: calc(100% - 115px); }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div.phone .intl-tel-input input {
                padding-left: 45px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div.phone .checkbox-phone {
              margin: 0;
              margin-left: 14px;
              line-height: inherit; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div.phone2 label {
              width: 100%; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div.phone2 .intl-tel-input {
              width: calc(100%); }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div.phone2 .intl-tel-input input {
                padding-left: 45px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line > div .react-datepicker-wrapper {
              margin: 0; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.step_history .s_history {
              width: 100%; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.title_row {
              border-bottom: 1px solid #ccc;
              box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
              width: calc(50% - 30px);
              margin: 0 15px;
              margin-top: 15px;
              border: 1px solid #ccc;
              border-radius: 5px 5px 0 0;
              padding: 0; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.title_row .col-4, .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.title_row .col-6 {
                border-right: 1px solid #ccc; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.doc_row {
              box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
              margin: 0 15px;
              width: calc(50% - 30px);
              border: 1px solid #ccc;
              border-top: 0;
              padding: 0; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.doc_row .col-4, .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.doc_row .col-6 {
                border-right: 1px solid #ccc; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.doc_row .col-2 button {
                position: relative;
                padding: 0 10px;
                min-width: max-content;
                font-size: 11px;
                margin-right: 5px; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.doc_row .col-2 button input {
                  cursor: pointer;
                  opacity: 0;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0px;
                  left: 0px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.doc_row .col-2 .actions button {
                margin: 0 5px;
                padding: 0 5px;
                width: 20px !important;
                height: 20px !important;
                font-size: 12px;
                line-height: 20px; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.doc_row .col-2 .actions button i {
                  font-size: 13px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.doc_row .col-2 .actions .remove_document {
                color: red;
                margin: 0 5px;
                cursor: pointer;
                border-radius: 50%;
                border: 1px solid red;
                display: block;
                width: 20px;
                height: 20px;
                line-height: 19px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.doc_row > div {
                padding: 10px 0px;
                height: 50px; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.doc_row > div .doc {
                  margin: 0; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.doc_row:last-of-type {
                border-radius: 0 0 5px 5px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.w_60 {
              width: 60%; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.bulletin_row {
              border-top: 0; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.bulletin_row > div {
                text-align: left; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.bulletin_row > div label {
                  min-width: max-content;
                  padding-right: 10px;
                  line-height: 28px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.bulletin_row > div button {
                position: relative;
                padding: 0 10px;
                min-width: max-content;
                font-size: 11px; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.bulletin_row > div button input {
                  cursor: pointer;
                  opacity: 0;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0px;
                  left: 0px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.bulletin_row > div .actions button {
                margin: 0 5px;
                padding: 0 5px;
                width: 20px !important;
                height: 20px !important;
                font-size: 12px;
                line-height: 20px;
                margin-top: 5px; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.bulletin_row > div .actions button i {
                  font-size: 13px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.bulletin_row > div .actions .remove_document {
                color: red;
                margin: 0 5px;
                cursor: pointer;
                border-radius: 50%;
                border: 1px solid red;
                display: block;
                width: 20px;
                height: 20px;
                line-height: 19px;
                text-align: center;
                margin-top: 4px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.bulletin_row > div {
                display: flex; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.bulletin_row > div .doc {
                  margin: 0;
                  width: calc(100% - 130px); }
                  .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.bulletin_row > div .doc .name {
                    line-height: 28px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.bulletin_row:last-of-type {
                border-radius: 0 0 5px 5px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .bulletin_radio {
              margin-bottom: -7px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.subjects_test .table_container {
              background-color: rgba(255, 255, 255, 0.3);
              border-radius: 8px;
              padding: 10px 5px;
              border: 1px solid #d1dbe2; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.subjects_test .table_container table {
                width: 100%; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.subjects_test .table_container table tr th {
                  text-align: center;
                  padding-bottom: 20px; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.subjects_test .table_container table tr td {
                  background-color: white;
                  border: 1px solid #e8e8e8;
                  border-width: 1px 1px 1px 1px;
                  height: 50px;
                  padding: 10px; }
                  .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.subjects_test .table_container table tr td .radios .RadioLegend {
                    display: flex; }
                    .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line.subjects_test .table_container table tr td .radios .RadioLegend legend {
                      margin-top: 6px;
                      margin-left: 5px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line button.download_file {
              margin-left: 40px;
              height: 35px !important;
              width: 35px !important; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line button.download_file i {
                font-size: 20px;
                cursor: pointer; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .textErrors {
              color: red; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .actions {
              display: flex;
              justify-content: center;
              max-width: 100%; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .actions .radios {
                width: 30px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .actions .addChild {
                display: inline-block;
                width: 30px;
                height: 30px;
                border-radius: 15px;
                box-shadow: rgba(239, 194, 91, 0.5) 0px 18px 36px 0px;
                background-image: linear-gradient(225deg, #fdd271, #d5a433);
                font-size: 16px;
                text-align: center;
                line-height: 30px;
                font-weight: bold;
                color: white;
                margin-top: -10px;
                margin-left: 10px;
                margin-right: 10px;
                cursor: pointer; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .admission .RadioLegend {
              display: flex; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .admission .RadioLegend legend {
                margin-top: 6px;
                margin-left: 6px;
                min-width: max-content; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .add_file {
              text-align: right; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .add_file span {
                float: right;
                width: 105px;
                height: 27px;
                border-radius: 49.5px;
                box-shadow: #3eb6ad82 0px 6px 24px 0px;
                background-image: linear-gradient(254deg, #6df3e9, #3eb6ad);
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: 27px;
                letter-spacing: normal;
                text-align: center;
                color: white;
                cursor: pointer; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .doc {
              display: flex;
              -webkit-box-pack: justify;
              justify-content: space-between;
              padding: 0px 5px;
              margin-bottom: 5px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .doc .name {
                width: calc(100%);
                text-align: left;
                display: block; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .doc > div {
                display: flex;
                justify-content: space-between;
                width: 35px; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .doc > div .download_file {
                  margin: 0;
                  width: 20px !important;
                  height: 20px !important;
                  font-size: 12px;
                  line-height: 20px; }
                  .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .doc > div .download_file i {
                    font-size: 13px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .doc .remove_document {
                color: red; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .image-profile .fa-user {
              width: 100px;
              height: 100px;
              font-size: 100px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .image-profile img {
              width: 100px;
              height: 100px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .image-profile button input {
              cursor: pointer; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .image-profile button input[type=file] {
              cursor: pointer; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .line .file_uploading {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%); }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .sms_email {
            width: max-content;
            margin-left: 0;
            margin-right: 15px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .sms_email .RadioLegend legend {
              margin: 0;
              line-height: 24px;
              margin-left: 5px;
              font-size: 15px; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .row.responsable_row > .col-12, .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .row.responsable_row > .col-6 {
            margin-bottom: 15px; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .child {
            padding-bottom: 15px;
            margin-bottom: 10px;
            position: relative; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .child:last-of-type {
              border-bottom: 0px;
              margin-bottom: 0px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .child.styled {
              border: 2px solid #56729f;
              border-radius: 10px;
              margin-top: 20px;
              padding-bottom: 10px;
              padding-top: 0; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .child.styled .child_title {
                position: absolute;
                bottom: 100%;
                transform: translateY(50%);
                background: white;
                padding: 5px;
                font-weight: bold;
                left: 8px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .child.styled .removeChild {
                display: inline-block;
                width: 22px;
                height: 22px;
                border-radius: 15px;
                box-shadow: #f7090945 0px 18px 36px 0px;
                background-image: linear-gradient(225deg, #f52b2b, #f36464);
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                line-height: 22px;
                color: white;
                cursor: pointer;
                position: absolute;
                left: 100%;
                bottom: 100%;
                transform: translate(-50%, 50%);
                margin: 0; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .child > .row {
              margin: auto;
              margin-top: 10px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .child > .row > .col-12, .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .child > .row > .col-6 {
                margin-bottom: 15px; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .files .drag_div {
            position: relative;
            width: 100%;
            margin: 0px 0px 20px 0; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .files .drag_div .template {
              display: flex;
              -webkit-box-pack: justify;
              justify-content: space-between;
              padding: 0px 5px;
              margin-bottom: 5px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .files .drag_div .template .name {
                width: calc(100% - 35px);
                text-align: left;
                display: block; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .files .drag_div .template > div {
                display: flex;
                justify-content: space-between;
                width: 35px; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .files .drag_div .template > div .download_file {
                  margin: 0;
                  width: 20px !important;
                  height: 20px !important;
                  font-size: 12px;
                  line-height: 20px; }
                  .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .files .drag_div .template > div .download_file i {
                    font-size: 13px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .files .drag_div .template .remove_document {
                color: red; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .files .drag_div .dragandrophandler {
              min-height: 150px;
              height: max-content; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .react-datepicker__time-list-item {
            color: black !important; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .react-datepicker__time-list-item.react-datepicker__time-list-item--disabled {
              color: #ccc !important; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form > .row {
            margin: auto;
            margin-top: 10px; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form::-webkit-scrollbar-thumb {
            background-color: #3eb6ad; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form div.date_inside_td {
            padding: 0 !important;
            display: flex !important; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form div.date_inside_td label {
              line-height: 25px !important;
              margin-right: 5px !important;
              margin-left: -5px !important; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form div.date_inside_td > div.react-datepicker-wrapper {
              width: 80px !important; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .form .date_container_td {
            width: 100px !important; }
        .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .notes_files {
          text-align: center; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .notes_files > button {
            position: relative;
            padding: 0 10px;
            min-width: -webkit-max-content;
            min-width: max-content;
            font-size: 11px;
            margin-right: 5px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .notes_files > button input {
              cursor: pointer;
              opacity: 0;
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .notes_files .actions button.download_file {
            margin: 0 5px;
            padding: 0 5px;
            width: 22px !important;
            height: 20px !important;
            font-size: 12px;
            line-height: 20px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .notes_files .actions button.download_file i {
              font-size: 13px; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent .notes_files .actions .remove_document {
            color: red;
            margin: 0 5px;
            cursor: pointer;
            border-radius: 50%;
            border: 1px solid red;
            display: block;
            width: 20px;
            height: 20px;
            line-height: 19px;
            text-align: center; }
        .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form {
          width: 100%;
          padding-right: 10px;
          height: calc(100% - 0px);
          overflow-y: auto;
          overflow-x: hidden; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .all_children {
            width: 100%;
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: 8px;
            border: 1px solid #d1dbe2;
            overflow: hidden; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .all_children table {
              width: 100%; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .all_children table tr th {
                text-align: center;
                padding: 10px;
                background-color: white;
                border: 1px solid #e8e8e8;
                border-width: 0 1px 0 0; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .all_children table tr th:last-of-type {
                  border-right: 0; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .all_children table tr td {
                background-color: white;
                border: 1px solid #e8e8e8;
                border-width: 1px 1px 1px 1px;
                height: 50px;
                padding: 10px; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .all_children table tr td:first-of-type {
                  border-left: 0; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .all_children table tr td:last-of-type {
                  border-right: 0; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .all_children table tr.current {
                font-weight: bold; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .all_children table tr:last-of-type td {
                border-bottom: 0; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .child_name {
            display: flex;
            padding: 9px !important;
            justify-content: center;
            padding-top: 0 !important;
            font-size: 20px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .child_name > div {
              width: max-content;
              display: flex;
              justify-content: flex-start;
              margin: 0 1%; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .child_name > div:first-of-type {
                justify-content: flex-end; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .child_name > div span {
                font-weight: bold; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .child_name > div span.label {
                  margin-right: 10px; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .radios {
            width: 25px;
            display: inline-block;
            margin-left: 5px;
            height: 25px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .radios.empty_legend .RadioLegend {
              margin: 0;
              margin-bottom: 26px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .radios .RadioLegend > span {
              padding: 0; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line {
            margin-top: 10px;
            padding: 0 10px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line label {
              margin-bottom: 0; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line > div input {
              float: left;
              width: 100%;
              height: 28px;
              border-radius: 2.6px;
              border: .6px solid #d1dbe2;
              background-color: rgba(245, 248, 249, 0.3);
              font-size: 14px;
              font-weight: 400;
              color: #1b2437 !important;
              padding-left: 10px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line > div input.disabled {
                opacity: 0.5; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line > div textarea {
              width: 100%;
              height: 100px;
              border-radius: 2.6px;
              border: .6px solid #d1dbe2;
              background-color: rgba(245, 248, 249, 0.3);
              font-size: 14px;
              font-weight: 400;
              color: #1b2437 !important;
              padding: 10px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line > div textarea:disabled {
                opacity: 0.5; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line > div .relation {
              display: flex;
              padding-top: 20px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line > div .relation > div {
                padding-top: 5px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line > div .relation label::before {
                top: 5px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line > div .relation label::after {
                top: 5px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line > div .relation .selectInput {
                padding-left: 0; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line > div .relation .radios {
                width: max-content;
                min-width: 200px;
                padding-top: 0;
                margin-left: 0; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line > div .relation .radios legend {
                  margin-top: 8px;
                  margin-right: 10px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line > div .label.radios {
              margin: 0;
              width: max-content; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line > div .label.radios .RadioLegend {
                display: flex; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line > div .label.radios .RadioLegend legend {
                  margin: 7px 0px 0 10px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line > div.phone label {
              width: 100%; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line > div.phone .intl-tel-input {
              width: calc(100% - 30px); }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line > div.phone .intl-tel-input input {
                padding-left: 45px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line > div.phone .checkbox-phone {
              margin: 0;
              margin-left: 14px;
              line-height: inherit; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line > div .react-datepicker-wrapper {
              margin: 0; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.title_row {
              border-bottom: 1px solid #ccc;
              box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
              width: calc(50% - 30px);
              margin: 0 15px;
              margin-top: 15px;
              border: 1px solid #ccc;
              border-radius: 5px 5px 0 0;
              padding: 0; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.title_row .col-4, .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.title_row .col-6 {
                border-right: 1px solid #ccc; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.doc_row {
              box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
              margin: 0 15px;
              width: calc(50% - 30px);
              border: 1px solid #ccc;
              border-top: 0;
              padding: 0; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.doc_row .col-4, .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.doc_row .col-6 {
                border-right: 1px solid #ccc; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.doc_row .col-2 button {
                position: relative;
                padding: 0 10px;
                min-width: max-content;
                font-size: 11px; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.doc_row .col-2 button input {
                  cursor: pointer;
                  opacity: 0;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0px;
                  left: 0px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.doc_row .col-2 .actions button {
                margin: 0 5px;
                padding: 0 5px;
                width: 20px !important;
                height: 20px !important;
                font-size: 12px;
                line-height: 20px; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.doc_row .col-2 .actions button i {
                  font-size: 13px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.doc_row .col-2 .actions .remove_document {
                color: red;
                margin: 0 5px;
                cursor: pointer;
                border-radius: 50%;
                border: 1px solid red;
                display: block;
                width: 20px;
                height: 20px;
                line-height: 19px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.doc_row > div {
                padding: 10px 0px;
                height: 50px; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.doc_row > div .doc {
                  margin: 0; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.doc_row:last-of-type {
                border-radius: 0 0 5px 5px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.bulletin_row {
              border-top: 0; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.bulletin_row > div {
                text-align: left; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.bulletin_row > div label {
                  min-width: max-content;
                  padding-right: 10px;
                  line-height: 28px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.bulletin_row > div button {
                position: relative;
                padding: 0 10px;
                min-width: max-content;
                font-size: 11px; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.bulletin_row > div button input {
                  cursor: pointer;
                  opacity: 0;
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0px;
                  left: 0px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.bulletin_row > div .actions button {
                margin: 0 5px;
                padding: 0 5px;
                width: 20px !important;
                height: 20px !important;
                font-size: 12px;
                line-height: 20px;
                margin-top: 5px; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.bulletin_row > div .actions button i {
                  font-size: 13px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.bulletin_row > div .actions .remove_document {
                color: red;
                margin: 0 5px;
                cursor: pointer;
                border-radius: 50%;
                border: 1px solid red;
                display: block;
                width: 20px;
                height: 20px;
                line-height: 19px;
                text-align: center;
                margin-top: 4px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.bulletin_row > div {
                display: flex; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.bulletin_row > div .doc {
                  margin: 0;
                  width: calc(100% - 130px); }
                  .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.bulletin_row > div .doc .name {
                    line-height: 28px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.bulletin_row:last-of-type {
                border-radius: 0 0 5px 5px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .bulletin_radio {
              margin-bottom: -7px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.subjects_test .table_container {
              background-color: rgba(255, 255, 255, 0.3);
              border-radius: 8px;
              padding: 10px 5px;
              border: 1px solid #d1dbe2; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.subjects_test .table_container table {
                width: 100%; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.subjects_test .table_container table tr th {
                  text-align: center;
                  padding-bottom: 20px; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.subjects_test .table_container table tr td {
                  background-color: white;
                  border: 1px solid #e8e8e8;
                  border-width: 1px 1px 1px 1px;
                  height: 50px;
                  padding: 10px; }
                  .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.subjects_test .table_container table tr td .radios .RadioLegend {
                    display: flex; }
                    .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line.subjects_test .table_container table tr td .radios .RadioLegend legend {
                      margin-top: 6px;
                      margin-left: 5px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line button.download_file {
              margin-left: 40px;
              height: 35px !important;
              width: 35px !important; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line button.download_file i {
                font-size: 20px;
                cursor: pointer; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .textErrors {
              color: red; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .actions {
              display: flex;
              justify-content: center; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .actions .addChild {
                display: inline-block;
                width: 30px;
                height: 30px;
                border-radius: 15px;
                box-shadow: rgba(239, 194, 91, 0.5) 0px 18px 36px 0px;
                background-image: linear-gradient(225deg, #fdd271, #d5a433);
                font-size: 16px;
                text-align: center;
                line-height: 30px;
                font-weight: bold;
                color: white;
                margin-top: -10px;
                margin-left: 10px;
                margin-right: 10px;
                cursor: pointer; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .admission .RadioLegend {
              display: flex; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .admission .RadioLegend legend {
                margin-top: 6px;
                margin-left: 6px;
                min-width: max-content; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .add_file {
              text-align: right; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .add_file span {
                float: right;
                width: 105px;
                height: 27px;
                border-radius: 49.5px;
                box-shadow: #3eb6ad82 0px 6px 24px 0px;
                background-image: linear-gradient(254deg, #6df3e9, #3eb6ad);
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: 27px;
                letter-spacing: normal;
                text-align: center;
                color: white;
                cursor: pointer; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .doc {
              display: flex;
              -webkit-box-pack: justify;
              justify-content: space-between;
              padding: 0px 5px;
              margin-bottom: 5px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .doc .name {
                width: calc(100%);
                text-align: left;
                display: block; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .doc > div {
                display: flex;
                justify-content: space-between;
                width: 35px; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .doc > div .download_file {
                  margin: 0;
                  width: 20px !important;
                  height: 20px !important;
                  font-size: 12px;
                  line-height: 20px; }
                  .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .doc > div .download_file i {
                    font-size: 13px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .doc .remove_document {
                color: red; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .image-profile .fa-user {
              width: 150px;
              height: 150px;
              font-size: 135px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .image-profile img {
              width: 150px;
              height: 150px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .image-profile button input {
              cursor: pointer; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .image-profile button input[type=file] {
              cursor: pointer; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .file_uploading {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%); }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .line .connexion-custom-select > div:last-of-type {
              z-index: 1111111111111111; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .child {
            padding-bottom: 15px;
            margin-bottom: 10px;
            position: relative; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .child:last-of-type {
              border-bottom: 0px;
              margin-bottom: 0px; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .child.styled {
              border: 2px solid #56729f;
              border-radius: 10px;
              margin-top: 20px;
              padding-bottom: 10px;
              padding-top: 0; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .child.styled .child_title {
                position: absolute;
                bottom: 100%;
                transform: translateY(50%);
                background: white;
                padding: 5px;
                font-weight: bold;
                left: 8px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .child.styled .removeChild {
                display: inline-block;
                width: 22px;
                height: 22px;
                border-radius: 15px;
                box-shadow: #f7090945 0px 18px 36px 0px;
                background-image: linear-gradient(225deg, #f52b2b, #f36464);
                font-size: 16px;
                font-weight: bold;
                text-align: center;
                line-height: 22px;
                color: white;
                cursor: pointer;
                position: absolute;
                left: 100%;
                bottom: 100%;
                transform: translate(-50%, 50%);
                margin: 0; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .child > .row {
              margin: auto;
              margin-top: 10px; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .radio_content {
            width: 100%;
            display: flex; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .radio_content .opt {
              margin-left: 5px;
              width: 100%; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .radio_content:first-of-type {
              margin-bottom: 7px; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .schools_types {
            padding-left: 20px;
            z-index: 111111111111;
            margin-top: 28px; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .levels_to {
            z-index: 11111111111; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .school_type_input {
            margin-bottom: 7px; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .files .drag_div {
            position: relative;
            width: 100%;
            margin: 0px 0px 20px 0; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .files .drag_div .template {
              display: flex;
              -webkit-box-pack: justify;
              justify-content: space-between;
              padding: 0px 5px;
              margin-bottom: 5px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .files .drag_div .template .name {
                width: calc(100% - 35px);
                text-align: left;
                display: block; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .files .drag_div .template > div {
                display: flex;
                justify-content: space-between;
                width: 35px; }
                .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .files .drag_div .template > div .download_file {
                  margin: 0;
                  width: 20px !important;
                  height: 20px !important;
                  font-size: 12px;
                  line-height: 20px; }
                  .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .files .drag_div .template > div .download_file i {
                    font-size: 13px; }
              .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .files .drag_div .template .remove_document {
                color: red; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .files .drag_div .dragandrophandler {
              min-height: 150px;
              height: max-content; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .react-datepicker__time-list-item {
            color: black !important; }
            .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form .react-datepicker__time-list-item.react-datepicker__time-list-item--disabled {
              color: #ccc !important; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionContent.format_2 .form > .row {
            margin: auto;
            margin-top: 10px; }
      .dialog.dialogConnexion.dialogInscription .modalInscriptionBottom .actions {
        justify-content: flex-end;
        padding: 0 45px; }
        .dialog.dialogConnexion.dialogInscription .modalInscriptionBottom .actions > button {
          margin: 0 5px; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionBottom .actions > button i {
            margin: 0 5px; }
          .dialog.dialogConnexion.dialogInscription .modalInscriptionBottom .actions > button.disabled {
            opacity: 0.5; }
      .dialog.dialogConnexion.dialogInscription.dialogExport {
        min-width: 94%;
        padding: 2em 2em 0px;
        padding-bottom: 2rem; }
        .dialog.dialogConnexion.dialogInscription.dialogExport .schoolClose {
          position: absolute;
          cursor: pointer;
          top: 0;
          right: 0;
          width: 40px;
          height: 40px;
          border-radius: 100px;
          box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: -webkit-linear-gradient(225deg, #72dcd6, #3eb6ad);
          background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
          color: #fff;
          font-size: 40px;
          line-height: 40px;
          text-align: center; }
        .dialog.dialogConnexion.dialogInscription.dialogExport .pageClasses {
          margin: 0 !important; }
          .dialog.dialogConnexion.dialogInscription.dialogExport .pageClasses .row label {
            display: block;
            font-family: Montserrat;
            font-size: 15px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1b2437;
            margin-top: 5px;
            margin-bottom: 2px; }
          .dialog.dialogConnexion.dialogInscription.dialogExport .pageClasses .row .radios .RadioLegend > span {
            padding-top: 5px; }
          .dialog.dialogConnexion.dialogInscription.dialogExport .pageClasses .row .radios .RadioLegend legend {
            margin-top: 11px; }
          .dialog.dialogConnexion.dialogInscription.dialogExport .pageClasses .select_ .select {
            float: left;
            width: 100%;
            height: 29px;
            border-radius: 2.6px;
            border: 0.6px solid #d1dbe2;
            background-color: rgba(245, 248, 249, 0.3);
            position: relative;
            padding-left: 3px; }
            .dialog.dialogConnexion.dialogInscription.dialogExport .pageClasses .select_ .select select {
              float: left;
              width: 100%;
              font-size: 12px;
              height: 28px;
              appearance: none;
              text-indent: 1px;
              background: transparent;
              outline: 0px;
              position: relative;
              z-index: 9;
              font-weight: 500;
              font-family: Montserrat;
              color: #1b2437;
              padding: 0px 5px;
              border-radius: 4px !important;
              border: none !important; }
            .dialog.dialogConnexion.dialogInscription.dialogExport .pageClasses .select_ .select i {
              color: #d2dbe2;
              font-size: 14px;
              position: absolute;
              right: 5px;
              top: 6px; }
          .dialog.dialogConnexion.dialogInscription.dialogExport .pageClasses .react-datepicker-wrapper .input-group {
            height: 29px; }
            .dialog.dialogConnexion.dialogInscription.dialogExport .pageClasses .react-datepicker-wrapper .input-group input {
              height: 29px; }
          .dialog.dialogConnexion.dialogInscription.dialogExport .pageClasses .name_search input {
            height: 29px; }
          .dialog.dialogConnexion.dialogInscription.dialogExport .pageClasses .item-list {
            height: calc(100% - 200px);
            margin-right: -15px;
            margin-left: -15px; }
            .dialog.dialogConnexion.dialogInscription.dialogExport .pageClasses .item-list .cycle_data {
              padding: 12px; }
    .dialog.dialogConnexion:before:not(.dialogChildren) {
      content: "";
      width: 101px;
      height: 116px;
      position: absolute;
      left: 10px;
      bottom: 30px;
      background: url("../../assets/img/iconFuseeCnx.png") no-repeat scroll transparent; }
    .dialog.dialogConnexion h2 {
      margin: 0 0 25px;
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1b2437; }
    .dialog.dialogConnexion .schoolClose {
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
      color: #fff;
      font-size: 40px;
      line-height: 40px;
      text-align: center; }
      .dialog.dialogConnexion .schoolClose i {
        color: #fff;
        font-size: 21px;
        line-height: 40px; }
    .dialog.dialogConnexion .modalConnexionForm .row .col-12 {
      margin: 0 0 24px 0; }
    .dialog.dialogConnexion .modalConnexionForm .row label {
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1b2437;
      margin-bottom: 2px; }
    .dialog.dialogConnexion .modalConnexionForm .row .form-control {
      height: 26.5px; }
    .dialog.dialogConnexion .modalConnexionForm .row .RadioLegend > legend {
      font-family: Montserrat;
      font-size: 13px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1b2437;
      margin: 16px 0 0 5px; }
    .dialog.dialogConnexion .modalConnexionForm .row .intl-tel-input {
      width: 100%; }
    .dialog.dialogConnexion .modalConnexionForm .connexionLigne {
      float: left;
      width: calc(100% + 20px);
      margin: 0 -10px 25px; }
      .dialog.dialogConnexion .modalConnexionForm .connexionLigne .textErrors {
        color: #f13434;
        font-size: 10px;
        font-family: "Montserrat", sans-serif;
        display: flex;
        width: 100%; }
      .dialog.dialogConnexion .modalConnexionForm .connexionLigne.row {
        margin: 0px; }
        .dialog.dialogConnexion .modalConnexionForm .connexionLigne.row > div {
          margin: 0 -10px 25px; }
          .dialog.dialogConnexion .modalConnexionForm .connexionLigne.row > div .intl-tel-input {
            width: 100%; }
      .dialog.dialogConnexion .modalConnexionForm .connexionLigne.w12 {
        margin: 0;
        width: 100%; }
      .dialog.dialogConnexion .modalConnexionForm .connexionLigne .RadioLegend {
        float: left;
        margin-right: 25px; }
        .dialog.dialogConnexion .modalConnexionForm .connexionLigne .RadioLegend:last-child {
          margin-right: 0; }
        .dialog.dialogConnexion .modalConnexionForm .connexionLigne .RadioLegend > span {
          padding: 0;
          float: left;
          flex: none;
          display: initial;
          position: relative; }
          .dialog.dialogConnexion .modalConnexionForm .connexionLigne .RadioLegend > span:before {
            content: "";
            position: absolute;
            left: 3px;
            right: 3px;
            top: 3px;
            bottom: 3px;
            border-radius: 50%; }
        .dialog.dialogConnexion .modalConnexionForm .connexionLigne .RadioLegend > legend {
          float: left;
          margin: 0;
          font-family: Montserrat;
          font-size: 13px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          margin-left: 5px;
          margin-top: 3px; }
      .dialog.dialogConnexion .modalConnexionForm .connexionLigne .connexionW50 {
        float: left;
        width: 50%;
        padding: 0 10px; }
      .dialog.dialogConnexion .modalConnexionForm .connexionLigne .connexionW100 {
        float: left;
        width: 100%;
        padding: 0 10px; }
        .dialog.dialogConnexion .modalConnexionForm .connexionLigne .connexionW100 .textErrors {
          color: #f13434;
          font-size: 10px;
          font-family: "Montserrat", sans-serif; }
      .dialog.dialogConnexion .modalConnexionForm .connexionLigne .captcha {
        display: flex;
        width: 100%;
        padding: 0 10px; }
        .dialog.dialogConnexion .modalConnexionForm .connexionLigne .captcha .pCaptcha {
          min-width: 40px;
          line-height: 25px;
          font-family: Montserrat;
          font-size: 11px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          color: #3e3e3e;
          margin: 0 6px 0 0; }
        .dialog.dialogConnexion .modalConnexionForm .connexionLigne .captcha .captchaInput {
          object-fit: contain;
          opacity: 0.9;
          font-family: "Montserrat", sans-serif;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: center;
          /*color: transparent;*/
          text-shadow: 0 0 0 #0c1312; }
          .dialog.dialogConnexion .modalConnexionForm .connexionLigne .captcha .captchaInput:focus {
            outline: none; }
      .dialog.dialogConnexion .modalConnexionForm .connexionLigne .flagSelect {
        float: left;
        width: 100%;
        height: 26.5px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3); }
        .dialog.dialogConnexion .modalConnexionForm .connexionLigne .flagSelect .select {
          float: left;
          width: 70px;
          height: 25.5px;
          position: relative;
          padding-left: 3px;
          border: none; }
          .dialog.dialogConnexion .modalConnexionForm .connexionLigne .flagSelect .select:before {
            content: "";
            position: absolute;
            right: 0;
            top: 4px;
            bottom: 4px;
            width: 1px;
            background: #b1bfcd; }
          .dialog.dialogConnexion .modalConnexionForm .connexionLigne .flagSelect .select select {
            float: left;
            width: 50px;
            font-size: 12px;
            height: 25.5px;
            border: none;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            text-indent: 1px;
            text-overflow: "";
            background: transparent;
            outline: 0;
            position: relative;
            z-index: 9;
            border-radius: 4px !important;
            border: none !important;
            font-weight: 500;
            font-family: Montserrat;
            color: #1b2437;
            padding: 0 5px; }
            .dialog.dialogConnexion .modalConnexionForm .connexionLigne .flagSelect .select select::-ms-expand {
              display: none; }
          .dialog.dialogConnexion .modalConnexionForm .connexionLigne .flagSelect .select i {
            color: #d2dbe2;
            font-size: 16px;
            position: absolute;
            right: 5px;
            top: 5px; }
        .dialog.dialogConnexion .modalConnexionForm .connexionLigne .flagSelect input {
          border: none;
          width: calc(100% - 70px);
          padding-left: 6px; }
      .dialog.dialogConnexion .modalConnexionForm .connexionLigne label {
        display: block;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1b2437;
        margin-bottom: 2px; }
      .dialog.dialogConnexion .modalConnexionForm .connexionLigne .select {
        float: left;
        width: 100%;
        height: 26.5px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        position: relative;
        padding-left: 3px; }
        .dialog.dialogConnexion .modalConnexionForm .connexionLigne .select select {
          float: left;
          width: 100%;
          font-size: 12px;
          height: 25.5px;
          border: none;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          text-indent: 1px;
          text-overflow: "";
          background: transparent;
          outline: 0;
          position: relative;
          z-index: 9;
          border-radius: 4px !important;
          border: none !important;
          font-weight: 500;
          font-family: Montserrat;
          font-size: 14px;
          color: #1b2437;
          padding: 0 5px; }
          .dialog.dialogConnexion .modalConnexionForm .connexionLigne .select select::-ms-expand {
            display: none; }
        .dialog.dialogConnexion .modalConnexionForm .connexionLigne .select i {
          color: #d2dbe2;
          font-size: 16px;
          position: absolute;
          right: 5px;
          top: 5px; }
      .dialog.dialogConnexion .modalConnexionForm .connexionLigne input {
        float: left;
        width: 100%;
        height: 26.5px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500; }
        .dialog.dialogConnexion .modalConnexionForm .connexionLigne input:not([type='tel']) {
          padding-left: 10px; }
      .dialog.dialogConnexion .modalConnexionForm .connexionLigne .forgot {
        font-family: Montserrat;
        font-size: 10.6px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #a7b7bc;
        float: left; }
      .dialog.dialogConnexion .modalConnexionForm .connexionLigne .back_btn {
        font-family: Montserrat;
        font-size: 10.6px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #a7b7bc;
        float: left;
        margin-left: 40px; }
      .dialog.dialogConnexion .modalConnexionForm .connexionLigne .signin {
        width: 106px;
        height: 25px;
        float: right;
        object-fit: contain;
        border-radius: 49.5px;
        box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
        background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
        color: #fff;
        border: none;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 25px;
        letter-spacing: normal;
        margin: 0; }
  .dialog.dialogCommonConnexion {
    width: calc(100% - 24%);
    height: 36vw;
    max-height: 480px;
    max-width: unset;
    position: relative;
    border-radius: 4vw;
    padding: 3vw 2vw; }
    .dialog.dialogCommonConnexion.web {
      background-image: url("../img/login_bg.png");
      background-size: 100% 100%;
      width: calc(100% - 22%);
      height: 38vw;
      max-height: 540px; }
    .dialog.dialogCommonConnexion.mobile {
      width: calc(100% - 0%);
      height: calc(100% - 26%);
      max-height: unset;
      padding: 5vw;
      border-radius: 10vw; }
      .dialog.dialogCommonConnexion.mobile .connexion_button .home_btn_connexion {
        line-height: 7vw !important;
        font-size: 4vw !important;
        width: 30vw !important;
        height: 7vw !important; }
    .dialog.dialogCommonConnexion > div.row {
      height: 100%; }
      .dialog.dialogCommonConnexion > div.row .col {
        padding: 2vw 3.5vw; }
        .dialog.dialogCommonConnexion > div.row .col.form_ .connexion_form h2 {
          color: #ffa827;
          font-size: 1.8vw;
          text-align: center;
          margin-bottom: 1.5vw;
          font-family: QuicksandBold;
          font-weight: 600; }
        .dialog.dialogCommonConnexion > div.row .col.form_ .connexion_form .connexionLigne {
          margin-bottom: 0; }
          .dialog.dialogCommonConnexion > div.row .col.form_ .connexion_form .connexionLigne .connexionW100 input {
            margin-bottom: 2vw;
            padding: 14px 164px 17px 26px;
            border-radius: 5.4px;
            box-shadow: -2px -0.1px 4.3px 1.7px rgba(5, 99, 169, 0.1);
            border: solid 0.1px #09369b78;
            padding: 1.5vw;
            background-color: #fff !important; }
          .dialog.dialogCommonConnexion > div.row .col.form_ .connexion_form .connexionLigne .connexionW100 input::-ms-input-placeholder,
          .dialog.dialogCommonConnexion > div.row .col.form_ .connexion_form .connexionLigne .connexionW100 input::-webkit-input-placeholder,
          .dialog.dialogCommonConnexion > div.row .col.form_ .connexion_form .connexionLigne .connexionW100 input:-ms-input-placeholder,
          .dialog.dialogCommonConnexion > div.row .col.form_ .connexion_form .connexionLigne .connexionW100 input::-moz-placeholder,
          .dialog.dialogCommonConnexion > div.row .col.form_ .connexion_form .connexionLigne .connexionW100 input::placeholder {
            opacity: 1;
            font-family: QuicksandBook;
            font-size: 1.2vw;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.57;
            letter-spacing: -0.42px;
            text-align: center;
            color: rgba(255, 0, 0, 0.6); }
          .dialog.dialogCommonConnexion > div.row .col.form_ .connexion_form .connexionLigne a.forgot {
            display: block;
            width: 100%;
            opacity: 0.8;
            font-family: Poppins;
            font-size: 1vw;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1;
            letter-spacing: -0.42px;
            text-align: right;
            color: rgba(255, 168, 39, 0.8);
            margin-bottom: 10px; }
          .dialog.dialogCommonConnexion > div.row .col.form_ .connexion_form .connexionLigne .connexion_button {
            text-align: center; }
            .dialog.dialogCommonConnexion > div.row .col.form_ .connexion_form .connexionLigne .connexion_button button {
              color: #fff;
              background-color: #ffa827;
              border-radius: 5.4px;
              box-shadow: unset;
              background-image: unset;
              padding: 0.4vw 2vw;
              height: unset;
              width: unset;
              font-family: QuicksandBook;
              padding: 0.4vw 2vw;
              font-size: 5vw; }
      .dialog.dialogCommonConnexion > div.row.mobile {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: max-content;
        width: calc(90%);
        margin: 0;
        height: calc(100% - 30%); }
        .dialog.dialogCommonConnexion > div.row.mobile .form_ {
          width: 100%; }
          .dialog.dialogCommonConnexion > div.row.mobile .form_ .connexion_form {
            height: 100%; }
            .dialog.dialogCommonConnexion > div.row.mobile .form_ .connexion_form h2 {
              font-size: 7vw;
              margin-top: -5vw;
              margin-bottom: 10vw; }
            .dialog.dialogCommonConnexion > div.row.mobile .form_ .connexion_form .form {
              height: 85%; }
            .dialog.dialogCommonConnexion > div.row.mobile .form_ .connexion_form .modalConnexionForm {
              width: 100%;
              height: 100%; }
              .dialog.dialogCommonConnexion > div.row.mobile .form_ .connexion_form .modalConnexionForm .connexionLigne .connexionW100 input {
                padding: 25px;
                margin-bottom: 30px; }
              .dialog.dialogCommonConnexion > div.row.mobile .form_ .connexion_form .modalConnexionForm .connexionLigne .forgot {
                font-size: 16px;
                margin-bottom: 6vh; }
              .dialog.dialogCommonConnexion > div.row.mobile .form_ .connexion_form .modalConnexionForm .connexionLigne .connexion_button button {
                padding: 10px 40px;
                font-weight: normal;
                font-size: 6vw;
                border-radius: 2vw; }
        .dialog.dialogCommonConnexion > div.row.mobile > :not(.form_) {
          display: none; }
  .dialog.dialogSubscribedStudents {
    width: 84%;
    height: 100%;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 2em;
    border-left: none;
    position: relative;
    max-width: unset;
    padding-bottom: 0; }
    .dialog.dialogSubscribedStudents.forExport {
      max-width: 400px;
      height: auto; }
    .dialog.dialogSubscribedStudents .btn-close {
      position: absolute;
      top: 11px;
      right: 11px;
      width: 24px !important;
      height: 24px !important;
      background-image: linear-gradient(to right, #67b0aa, #39817c);
      color: #FFF;
      box-shadow: none; }
    @media (max-width: 1399px) and (min-width: 1200px) {
      .dialog.dialogSubscribedStudents.dialogLargeGraphe {
        width: 100%; } }
    @media (max-width: 1199px) {
      .dialog.dialogSubscribedStudents {
        width: 100%;
        padding: 2em 0;
        border: 0; } }
    @media (max-width: 768px) {
      .dialog.dialogSubscribedStudents {
        height: 100%; }
        .dialog.dialogSubscribedStudents .pdf-container {
          height: 160%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1; }
          .dialog.dialogSubscribedStudents .pdf-container > div {
            flex: 1;
            height: 100%; }
            .dialog.dialogSubscribedStudents .pdf-container > div .pdf-body {
              height: 100%; }
              .dialog.dialogSubscribedStudents .pdf-container > div .pdf-body .students-account {
                height: inherit !important; }
              .dialog.dialogSubscribedStudents .pdf-container > div .pdf-body .students-trombine {
                height: inherit !important; } }
  .dialog.dialogSubscribedStudentsForInscriptions {
    padding: 0; }
    .dialog.dialogSubscribedStudentsForInscriptions .schoolClose {
      background: #1c4381;
      position: absolute;
      top: -23px;
      right: -23px;
      border: 5px solid #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 31px;
      line-height: 28px;
      box-shadow: none;
      background-image: none;
      font-weight: bold; }
  .dialog.dialogClosedPayments {
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 2em;
    border-left: none; }
  .dialog.dialogClosedMonthlyPayments {
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 2em;
    border-left: none;
    width: 400px; }
  .dialog.dialogChooseExport {
    width: max-content;
    min-width: 50%;
    height: max-content;
    min-height: 50%;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 2em;
    border-left: none;
    position: relative;
    max-width: unset;
    padding-bottom: 0; }
    .dialog.dialogChooseExport.dialogChooseExport2 {
      min-height: auto; }
    .dialog.dialogChooseExport.dialogListStudentsPayments {
      width: 94%;
      height: 100%;
      padding-bottom: 2em; }
      .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer {
        width: 100%;
        height: 100%;
        padding: 20px 25px 20px 20px; }
      .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer2 {
        width: 1000px;
        padding: 0cm;
        color: #000; }
      .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewerInsuranceAndDelegationList2 {
        min-width: 12in; }
      .dialog.dialogChooseExport.dialogListStudentsPayments .sms_sent {
        position: absolute;
        top: 0;
        left: 0;
        padding: 20px;
        background: gray;
        width: 100%;
        border-top-right-radius: 18px;
        border-top-left-radius: 18px;
        z-index: 11; }
      .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students {
        margin: 0px; }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #F5F5F5;
          border-radius: 5px;
          position: absolute;
          right: -50;
          /* Position the scrollbar on the right of the element*/ }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students::-webkit-scrollbar {
          width: 10px;
          height: 10px;
          background-color: #F5F5F5;
          border-radius: 5px;
          position: absolute;
          right: -50;
          /* Position the scrollbar on the right of the element*/ }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
          border-radius: 5px;
          position: absolute;
          right: -50;
          /* Position the scrollbar on the right of the element*/ }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students [data-test-id]::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #F5F5F5;
          border-radius: 5px;
          position: absolute;
          right: -50;
          /* Position the scrollbar on the right of the element*/ }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students [data-test-id]::-webkit-scrollbar {
          width: 10px;
          height: 10px;
          background-color: #F5F5F5;
          border-radius: 5px;
          position: absolute;
          right: -50;
          /* Position the scrollbar on the right of the element*/ }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students [data-test-id]::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
          border-radius: 5px;
          position: absolute;
          right: -50;
          /* Position the scrollbar on the right of the element*/ }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students .header_slip {
          display: flex;
          justify-content: space-between;
          width: 100%; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students .header_slip > div {
            /*border:1px solid #000;*/
            height: 100px;
            width: 300px;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students .header_slip > div:first-child {
              justify-content: flex-start; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students .header_slip > div img {
              max-width: 100%;
              max-height: 100%; }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students .header_slip_title {
          border: 2px solid #000;
          padding: 10px;
          margin: 10px 0px;
          text-align: center;
          font-weight: bold; }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students.viewer .header_slip > div {
          height: 150px; }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students.viewer .header_slip_title {
          margin: 10px auto;
          width: max-content; }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students.viewer .table_payments .header_slip_title {
          margin: 10px auto;
          width: max-content; }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students.viewer .table_payments .header_line {
          background-color: #b5c4e3; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students.viewer .table_payments .header_line td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students.viewer .table_payments .header_line th {
            background-image: none;
            background-color: #b5c4e3;
            font-size: 12px;
            font-weight: 600; }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students.viewer .table_payments th, .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students.viewer .table_payments td {
          font-size: 12px;
          color: #000; }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportViewer .export_content .payments_students.students_by_new_registration .header_slip_title {
          padding: 10px 20px !important;
          color: black !important;
          border-radius: 6px; }
      .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list {
        padding-right: 15px;
        margin-bottom: 15px; }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list .header .school_info {
          margin-bottom: 1px;
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list .header .school_info .school {
            display: flex;
            flex-direction: column;
            width: 250px;
            height: 130px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list .header .school_info .school img {
              margin: auto;
              max-width: 250px;
              max-height: 100px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list .header .school_info .school > span {
              margin: auto; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list .header .school_info .right {
            display: flex;
            flex-direction: column;
            width: 280px !important;
            font-weight: 700;
            font-size: 13px;
            text-align: right; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list .header .school_info .right img {
              margin: auto;
              max-width: 250px;
              max-height: 100px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list .header .school_info .right > span {
              margin: auto; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list .header .school_info .center {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            width: calc(100% - 201px); }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list .header .school_info .center .scholar_year {
              text-align: center;
              font-weight: bold;
              line-height: unset; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list .header .school_info .center .title {
              text-align: center;
              font-weight: bold;
              text-transform: uppercase;
              border: 3px solid #424344;
              box-shadow: 1px 1px 8px 5px black;
              border-radius: 1px;
              width: max-content;
              margin: auto;
              padding: 10px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list .header .school_info .center .classe {
              text-align: center; }
              .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list .header .school_info .center .classe span {
                font-weight: bold;
                margin: 0;
                font-size: 20px;
                color: black; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list .header .school_info .right {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 5px 10px;
            width: 100px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list .header .school_info .right .date {
              text-align: center;
              font-weight: bold; }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list .header .classe {
          padding-bottom: 2px; }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list table {
          border: 2px solid #c7c5c5;
          background-color: white;
          font-weight: 700;
          margin-top: 30px;
          width: 100%;
          border-collapse: collapse;
          font-family: "Janna LT" !important; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list table thead {
            background-color: white;
            border-bottom: 2px solid #c7c5c5;
            border-top: 2px solid black;
            height: 30px;
            object-fit: contain;
            opacity: 1;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #1b2437; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list table thead tr {
              font-size: 12px;
              height: 65px !important; }
              .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list table thead tr th {
                border-left: 2px solid #c7c5c5;
                color: black;
                border-top: 2px solid #c7c5c5;
                width: unset;
                padding: 2px;
                text-align: center;
                font-size: 12px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list table thead tr th .col_sort {
                  justify-content: center !important; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list table thead tr th .col_sort > div {
                    display: none; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list table tbody {
            height: 65px !important;
            font-size: 12px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list table tbody td {
              text-align: left;
              border-left: 2px solid #e2e6ef;
              border-bottom: 2px solid #e2e6ef;
              padding: 2.5px;
              vertical-align: super;
              border-spacing: 10px;
              border-collapse: initial;
              position: relative;
              border-left: 2px solid #c7c5c5 !important;
              border-bottom: 2px solid #c7c5c5;
              padding: 2px;
              vertical-align: middle;
              text-align: center;
              word-break: break-all;
              font-size: 11px;
              height: 70px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list table tbody td > span {
                display: block;
                text-align: left;
                padding-left: 7px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list table tbody td > span span {
                  position: absolute;
                  left: 2px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list table tbody td.first {
                border-bottom: 2px solid #c7c5c5;
                color: #000;
                vertical-align: middle;
                font-size: 11px;
                font-weight: bold; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list table td.first, .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list table th {
            text-decoration: underline; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportTextbooksViewer .classe_textbooks_list table td.first {
            background-image: linear-gradient(180deg, #cad3f2 3%, #b5c4e3 96%); }
      .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list {
        padding-right: 15px;
        margin-bottom: 15px; }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list .header .school_info {
          margin-bottom: 1px;
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list .header .school_info .school {
            display: flex;
            flex-direction: column;
            width: 250px;
            height: 130px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list .header .school_info .school img {
              margin: auto;
              max-width: 250px;
              max-height: 100px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list .header .school_info .school > span {
              margin: auto; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list .header .school_info .right {
            display: flex;
            flex-direction: column;
            width: 280px !important;
            font-weight: 700;
            font-size: 13px;
            text-align: right; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list .header .school_info .right img {
              margin: auto;
              max-width: 250px;
              max-height: 100px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list .header .school_info .right > span {
              margin: auto; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list .header .school_info .center {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            width: calc(100% - 201px); }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list .header .school_info .center .scholar_year {
              text-align: center;
              font-weight: bold;
              line-height: unset; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list .header .school_info .center .title {
              text-align: center;
              font-weight: bold;
              text-transform: uppercase;
              border: 3px solid #424344;
              box-shadow: 1px 1px 8px 5px black;
              border-radius: 1px;
              width: max-content;
              margin: auto;
              padding: 10px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list .header .school_info .center .classe {
              text-align: center; }
              .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list .header .school_info .center .classe span {
                font-weight: bold;
                margin: 0;
                font-size: 20px;
                color: black; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list .header .school_info .right {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 5px 10px;
            width: 100px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list .header .school_info .right .date {
              text-align: center;
              font-weight: bold; }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list .header .classe {
          padding-bottom: 2px; }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list table {
          border: 2px solid #c7c5c5;
          background-color: white;
          font-weight: 700;
          margin-top: 30px;
          width: 100%;
          border-collapse: collapse;
          font-family: "Janna LT" !important; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list table thead {
            background-color: white;
            border-bottom: 2px solid #c7c5c5;
            border-top: 2px solid black;
            height: 30px;
            object-fit: contain;
            opacity: 1;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #1b2437; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list table thead tr {
              font-size: 12px;
              height: 65px !important; }
              .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list table thead tr th {
                border-left: 2px solid #c7c5c5;
                color: black;
                border-top: 2px solid #c7c5c5;
                width: unset;
                padding: 2px;
                text-align: center;
                font-size: 12px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list table thead tr th .col_sort {
                  justify-content: center !important; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list table thead tr th .col_sort > div {
                    display: none; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list table tbody {
            height: 65px !important;
            font-size: 12px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list table tbody td {
              text-align: left;
              border-left: 2px solid #e2e6ef;
              border-bottom: 2px solid #e2e6ef;
              padding: 2.5px;
              vertical-align: super;
              border-spacing: 10px;
              border-collapse: initial;
              position: relative;
              border-left: 2px solid #c7c5c5 !important;
              border-bottom: 2px solid #c7c5c5;
              padding: 2px;
              vertical-align: middle;
              text-align: center;
              word-break: break-all;
              font-size: 11px;
              height: 70px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list table tbody td > span {
                display: block;
                text-align: left;
                padding-left: 7px;
                cursor: pointer; }
                .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list table tbody td > span:hover {
                  color: #1a0dab; }
                .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list table tbody td > span span {
                  position: absolute;
                  left: 2px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list table tbody td.first {
                border-bottom: 2px solid #c7c5c5;
                color: #000;
                vertical-align: middle;
                font-size: 11px;
                font-weight: bold; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list table td.first, .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list table th {
            text-decoration: underline; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportCoursesViewer .classe_courses_list table td.first {
            background-image: linear-gradient(180deg, #cad3f2 3%, #b5c4e3 96%); }
      .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list {
        padding-right: 15px;
        margin-bottom: 15px; }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list .header .school_info {
          margin-bottom: 1px;
          display: flex;
          -webkit-box-pack: justify;
          justify-content: space-between; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list .header .school_info .school {
            display: flex;
            flex-direction: column;
            width: 250px;
            height: 130px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list .header .school_info .school img {
              margin: auto;
              max-width: 250px;
              max-height: 100px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list .header .school_info .school > span {
              margin: auto; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list .header .school_info .right {
            display: flex;
            flex-direction: column;
            width: 280px !important;
            font-weight: 700;
            font-size: 13px;
            text-align: right; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list .header .school_info .right img {
              margin: auto;
              max-width: 250px;
              max-height: 100px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list .header .school_info .right > span {
              margin: auto; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list .header .school_info .center {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            width: calc(100% - 201px); }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list .header .school_info .center .scholar_year {
              text-align: center;
              font-weight: bold;
              line-height: unset; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list .header .school_info .center .title {
              text-align: center;
              font-weight: bold;
              text-transform: uppercase;
              border: 3px solid #424344;
              box-shadow: 1px 1px 8px 5px black;
              border-radius: 1px;
              width: max-content;
              margin: auto;
              padding: 10px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list .header .school_info .center .classe {
              text-align: center; }
              .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list .header .school_info .center .classe span {
                font-weight: bold;
                margin: 0;
                font-size: 20px;
                color: black; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list .header .school_info .right {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 5px 10px;
            width: 100px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list .header .school_info .right .date {
              text-align: center;
              font-weight: bold; }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list .header .classe {
          padding-bottom: 2px; }
        .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list table {
          border: 2px solid #c7c5c5;
          background-color: white;
          font-weight: 700;
          margin-top: 30px;
          width: 100%;
          border-collapse: collapse;
          font-family: "Janna LT" !important; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list table thead {
            background-color: white;
            border-bottom: 2px solid #c7c5c5;
            border-top: 2px solid black;
            height: 30px;
            object-fit: contain;
            opacity: 1;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #1b2437; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list table thead tr {
              font-size: 12px;
              height: 65px !important; }
              .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list table thead tr th {
                border-left: 2px solid #c7c5c5;
                color: black;
                border-top: 2px solid #c7c5c5;
                width: unset;
                padding: 2px;
                text-align: center;
                font-size: 12px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list table thead tr th .col_sort {
                  justify-content: center !important; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list table thead tr th .col_sort > div {
                    display: none; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list table tbody {
            height: 65px !important;
            font-size: 12px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list table tbody td {
              text-align: left;
              border-left: 2px solid #e2e6ef;
              border-bottom: 2px solid #e2e6ef;
              padding: 2.5px;
              vertical-align: super;
              border-spacing: 10px;
              border-collapse: initial;
              position: relative;
              border-left: 2px solid #c7c5c5 !important;
              border-bottom: 2px solid #c7c5c5;
              padding: 2px;
              vertical-align: middle;
              text-align: center;
              word-break: break-all;
              font-size: 11px;
              height: 70px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list table tbody td > span {
                display: block;
                text-align: left;
                padding-left: 7px;
                cursor: pointer; }
                .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list table tbody td > span:hover {
                  color: #1a0dab; }
                .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list table tbody td > span span {
                  position: absolute;
                  left: 2px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list table tbody td.first {
                border-bottom: 2px solid #c7c5c5;
                color: #000;
                vertical-align: middle;
                font-size: 11px;
                font-weight: bold; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list table td.first, .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list table th {
            text-decoration: underline; }
          .dialog.dialogChooseExport.dialogListStudentsPayments.dialogExportWeeklyPlanViewer .classe_weekly_plan_list table td.first {
            background-image: linear-gradient(180deg, #cad3f2 3%, #b5c4e3 96%); }
      .dialog.dialogChooseExport.dialogListStudentsPayments .export_content {
        height: calc(100% - 40px);
        padding-bottom: 40px; }
        .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students {
          height: calc(100% - 166px); }
          .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.effectifs_students_by_project, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.effectifs_students_by_school, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.effectifs_students_by_cycle, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.effectifs_students_by_level, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.effectifs_students_by_classe {
            height: calc(100% - 100px) !important; }
          .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #F5F5F5;
            border-radius: 5px;
            position: absolute;
            right: -50;
            /* Position the scrollbar on the right of the element*/ }
          .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            background-color: #F5F5F5;
            border-radius: 5px;
            position: absolute;
            right: -50;
            /* Position the scrollbar on the right of the element*/ }
          .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students::-webkit-scrollbar-thumb {
            background-color: #3eb6ad;
            border-radius: 5px;
            position: absolute;
            right: -50;
            /* Position the scrollbar on the right of the element*/ }
          .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students [data-test-id]::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #F5F5F5;
            border-radius: 5px;
            position: absolute;
            right: -50;
            /* Position the scrollbar on the right of the element*/ }
          .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students [data-test-id]::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            background-color: #F5F5F5;
            border-radius: 5px;
            position: absolute;
            right: -50;
            /* Position the scrollbar on the right of the element*/ }
          .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students [data-test-id]::-webkit-scrollbar-thumb {
            background-color: #3eb6ad;
            border-radius: 5px;
            position: absolute;
            right: -50;
            /* Position the scrollbar on the right of the element*/ }
          .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list {
            height: 100%;
            width: 100%;
            background-color: #f5f6fa;
            margin: 18px 15px;
            border-radius: 16px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: #d1dbe2;
            border-image: initial; }
            .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 {
              flex: 1;
              width: max-content;
              min-width: 100%;
              margin: 0px;
              backdrop-filter: blur(8px);
              background-color: rgba(222, 230, 234, 0.64); }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr th.checkbox_col, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr td.checkbox_col {
                width: 10px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.header td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.header th {
                background-image: linear-gradient(to bottom, #cad3f2 3%, #b5c4e3 96%);
                font-size: 13px;
                font-weight: 600;
                line-height: 1.64;
                color: #202726;
                width: auto;
                white-space: nowrap; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.header td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.header th:first-child {
                  border-top-left-radius: 14px;
                  border-bottom-left-radius: 14px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.header td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.header th:last-child {
                  border-right-width: 1px;
                  border-top-right-radius: 14px;
                  border-bottom-right-radius: 14px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.header.header2 td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.header.header2 th {
                background-image: linear-gradient(to bottom, #cad3f2aa 3%, #b5c4e3aa 96%); }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.header2 td {
                border-radius: 6px;
                font-size: 13px;
                font-weight: 500;
                line-height: 1.45;
                color: #6e6e6e;
                height: 50px;
                padding: 10px 5px;
                background-color: white;
                border-style: solid;
                border-color: #e8e8e8;
                border-image: initial;
                border-width: 1px 0px 1px 1px;
                width: auto;
                white-space: nowrap; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.header2 td.header_level {
                  background-image: linear-gradient(to bottom, #cad3f2 3%, #b5c4e3 96%);
                  font-size: 11px;
                  font-weight: 600;
                  line-height: 1.64;
                  color: #202726; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.header2 td.header_level:first-child {
                    border-top-left-radius: 14px;
                    border-bottom-left-radius: 14px; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.header2 td.header_level:last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 14px;
                    border-bottom-right-radius: 14px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.header_s_t td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.header_s_t th {
                height: 30px;
                padding: 5px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.header_s_t td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.header_s_t th:first-child {
                  border-top-left-radius: 10px;
                  border-bottom-left-radius: 10px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.header_s_t td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.header_s_t th:last-child {
                  border-right-width: 1px;
                  border-top-right-radius: 10px;
                  border-bottom-right-radius: 10px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.student:not(.spacer) td:not(.spacer_td) {
                border-radius: 6px;
                font-size: 12px;
                font-weight: 500;
                line-height: 1.45;
                color: #6e6e6e;
                width: auto;
                white-space: nowrap; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.is_child_of_staff td {
                background-color: #fbe1a4 !important; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list2 table tr.is_partner_group td {
                background-color: #f1cdcd !important; }
            .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 {
              flex: 1;
              width: max-content;
              min-width: 100%;
              margin: 0px;
              backdrop-filter: blur(8px);
              background-color: rgba(222, 230, 234, 0.64); }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr th.checkbox_col, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr td.checkbox_col {
                width: 10px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr.header td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr.header th {
                background-image: linear-gradient(to bottom, #cad3f2 3%, #b5c4e3 96%);
                font-size: 12px;
                font-weight: 600;
                line-height: 1.64;
                color: #000;
                width: auto;
                white-space: nowrap; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr.header td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr.header th:first-child {
                  border-top-left-radius: 14px;
                  border-bottom-left-radius: 14px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr.header td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr.header th:last-child {
                  border-right-width: 1px;
                  border-top-right-radius: 14px;
                  border-bottom-right-radius: 14px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr.header.header2 td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr.header.header2 th {
                background-image: linear-gradient(to bottom, #cad3f2aa 3%, #b5c4e3aa 96%); }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr.header2 td {
                border-radius: 6px;
                font-size: 12px;
                font-weight: 500;
                line-height: 1.45;
                color: #000;
                height: 50px;
                padding: 10px 5px;
                background-color: white;
                border-style: solid;
                border-color: #e8e8e8;
                border-image: initial;
                border-width: 1px 0px 1px 1px;
                width: auto;
                white-space: nowrap; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr.header2 td.header_level {
                  background-image: linear-gradient(to bottom, #cad3f2 3%, #b5c4e3 96%);
                  font-size: 13px;
                  font-weight: 600;
                  line-height: 1.64;
                  color: #000; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr.header2 td.header_level:first-child {
                    border-top-left-radius: 14px;
                    border-bottom-left-radius: 14px; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr.header2 td.header_level:last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 14px;
                    border-bottom-right-radius: 14px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr.header_s_t td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr.header_s_t th {
                height: 30px;
                padding: 5px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr.header_s_t td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr.header_s_t th:first-child {
                  border-top-left-radius: 10px;
                  border-bottom-left-radius: 10px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr.header_s_t td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr.header_s_t th:last-child {
                  border-right-width: 1px;
                  border-top-right-radius: 10px;
                  border-bottom-right-radius: 10px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_list3 table tr.student:not(.spacer) td:not(.spacer_td) {
                border-radius: 6px;
                font-size: 12px;
                font-weight: 500;
                line-height: 1.45;
                color: #000;
                width: auto;
                white-space: nowrap; }
            .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_tarl_notes table tr.header td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_tarl_notes table tr.header th {
              height: 30px;
              padding: 5px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_tarl_notes table tr.header td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_tarl_notes table tr.header th:first-child {
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_tarl_notes table tr.header td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.students_tarl_notes table tr.header th:last-child {
                border-right-width: 1px;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.cantine_garde {
              overflow: overlay !important; }
            .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table {
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 1px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr {
                text-align: center; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr td:not(.spacer_td) {
                  width: 6%;
                  padding: 3px;
                  font-size: 10px; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr td:not(.spacer_td).large {
                    width: 6.46%; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr td:not(.spacer_td).name {
                    width: calc(16% - 20px); }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.header {
                  font-weight: bold; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.header td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.header th {
                    padding-bottom: 20px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.spacer {
                  height: 10px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.student:not(.spacer) td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.student:not(.spacer) th, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.header td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.header th {
                  background-color: white;
                  height: 50px;
                  border-style: solid;
                  border-color: #e8e8e8;
                  border-image: initial;
                  border-width: 1px 0px 1px 1px;
                  padding: 10px; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.student:not(.spacer) td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.student:not(.spacer) th:first-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.header td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.header th:first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.student:not(.spacer) td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.student:not(.spacer) th:last-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.header td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.header th:last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.student:not(.spacer) td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.student:not(.spacer) th.gray, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.header td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.header th.gray {
                    background: #F5F5F5; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.student:not(.spacer) td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.student:not(.spacer) th.red, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.header td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.header th.red {
                    background: #FF0000; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.student:not(.spacer) td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.student:not(.spacer) th.greeen, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.header td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.header th.greeen {
                    background: #00FF00; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.student:not(.spacer) td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.student:not(.spacer) th.gray2, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.header td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.header th.gray2 {
                    background: gray; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.student:not(.spacer) td:not(.spacer_td).p_em, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.student:not(.spacer) th.p_em, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.header td:not(.spacer_td).p_em, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr.header th.p_em {
                    max-width: 100px;
                    word-break: break-all; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr td.spacer_td {
                  width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr td.name_ {
                  background-color: #f5f6fa !important; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table tr td.name.header_level {
                  background-color: #e8e8e8 !important; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table.mobile {
                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table.mobile tr {
                  text-align: center; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table.mobile tr td:not(.spacer_td) {
                    width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table.mobile tr td:not(.spacer_td).large {
                      width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list table.mobile tr td:not(.spacer_td).name {
                      width: calc(50% - 10px); }
            .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .students_list.no_scroll {
              overflow: unset; }
          .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list {
            height: 100%;
            width: 100%;
            background-color: #f5f6fa;
            margin: 18px 15px;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: #d1dbe2;
            border-image: initial; }
            .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 {
              flex: 1;
              width: max-content;
              min-width: 100%;
              margin: 0px;
              backdrop-filter: none;
              background-color: #FFF;
              border: 0px;
              border-radius: 0px;
              padding: 0px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 .parent_body {
                border: 3px solid #000; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 table tr th.checkbox_col, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 table tr td.checkbox_col {
                width: 10px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 table tr.header td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 table tr.header th {
                background-image: none;
                background-color: #b5c4e3;
                font-size: 12px;
                font-weight: 600;
                color: #000;
                width: auto;
                white-space: nowrap;
                border-color: #000;
                padding: 2px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 table tr.header td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 table tr.header th:first-child {
                  border-radius: 0px;
                  border-bottom-left-radius: 0px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 table tr.header td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 table tr.header th:last-child {
                  border-right-width: 1px;
                  border-top-right-radius: 0px;
                  border-bottom-right-radius: 0px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 table tr.header2 td {
                border-radius: 0px;
                font-size: 10px;
                font-weight: 500;
                color: #000;
                width: auto;
                height: auto;
                white-space: nowrap;
                border-color: #000;
                padding: 2px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 table tr.header2 td.header_level {
                  background-image: none;
                  background-color: #b5c4e3;
                  font-size: 12px;
                  font-weight: 600;
                  color: #000;
                  width: auto;
                  white-space: nowrap;
                  border-color: #000;
                  padding: 2px; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 table tr.header2 td.header_level:first-child {
                    border-radius: 0px;
                    border-bottom-left-radius: 0px; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 table tr.header2 td.header_level:last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 table tr.student:not(.spacer) th {
                background-image: none;
                background-color: #b5c4e3;
                font-size: 12px;
                font-weight: 600;
                color: #000;
                width: auto;
                white-space: nowrap;
                border-color: #000;
                padding: 2px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 table tr.student:not(.spacer) th:first-child {
                  border-radius: 0px;
                  border-bottom-left-radius: 0px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 table tr.student:not(.spacer) th:last-child {
                  border-right-width: 1px;
                  border-top-right-radius: 0px;
                  border-bottom-right-radius: 0px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 table tr.student:not(.spacer) td:not(.spacer_td) {
                border-radius: 0px;
                font-size: 10px;
                font-weight: 500;
                color: #000;
                width: auto;
                white-space: nowrap;
                border-color: #000;
                padding: 2px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 table tr.is_child_of_staff td {
                background-color: #fbe1a4 !important; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list2 table tr.is_partner_group td {
                background-color: #f1cdcd !important; }
            .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list4 .students_box {
              border: 1px solid #000; }
            .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list4 table {
              border: 0px !important;
              border-collapse: separate !important;
              border-spacing: 2px !important; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list4 table tr td, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list4 table tr th {
                border: 0px !important; }
            .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list5 td, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list5 th {
              font-size: 16.5px !important;
              padding: 15px 2px !important; }
            .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 {
              flex: 1;
              width: max-content;
              min-width: 100%;
              margin: 0px;
              backdrop-filter: none;
              background-color: #FFF;
              border: 0px;
              border-radius: 0px;
              padding: 0px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr th.checkbox_col, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr td.checkbox_col {
                width: 10px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.header td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.header th {
                background-image: none;
                background-color: #b5c4e3;
                font-size: 16px;
                font-weight: 600;
                color: #000;
                width: auto;
                white-space: nowrap;
                border-color: #000;
                padding: 2px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.header td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.header th:first-child {
                  border-radius: 0px;
                  border-bottom-left-radius: 0px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.header td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.header th:last-child {
                  border-right-width: 1px;
                  border-top-right-radius: 0px;
                  border-bottom-right-radius: 0px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.header td:not(.spacer_td).black_back, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.header th.black_back {
                  background-color: #000;
                  border-color: #FFF;
                  color: #FFF;
                  font-weight: bold; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.header td:not(.spacer_td).gray_back, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.header th.gray_back {
                  background-color: #D3D3D3;
                  color: #FFF;
                  font-weight: bold; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.header.blue_back td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.header.blue_back th {
                background-color: #ADD8E6;
                color: #000;
                font-weight: bold; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.header2 td {
                border-radius: 0px;
                font-size: 16px;
                font-weight: 500;
                color: #000;
                width: auto;
                height: auto;
                white-space: nowrap;
                border-color: #000;
                padding: 2px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.header2 td.header_level {
                  background-image: none;
                  background-color: #b5c4e3;
                  font-size: 16px;
                  font-weight: 600;
                  color: #000;
                  width: auto;
                  white-space: nowrap;
                  border-color: #000;
                  padding: 2px; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.header2 td.header_level:first-child {
                    border-radius: 0px;
                    border-bottom-left-radius: 0px; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.header2 td.header_level:last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.header2 td.header_level.gray_back {
                    background-color: #D3D3D3;
                    color: #000;
                    font-weight: bold; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.student:not(.spacer) th {
                background-image: none;
                background-color: #b5c4e3;
                font-size: 16px;
                font-weight: 600;
                color: #000;
                width: auto;
                white-space: nowrap;
                border-color: #000;
                padding: 2px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.student:not(.spacer) th:first-child {
                  border-radius: 0px;
                  border-bottom-left-radius: 0px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.student:not(.spacer) th:last-child {
                  border-right-width: 1px;
                  border-top-right-radius: 0px;
                  border-bottom-right-radius: 0px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.student:not(.spacer) td:not(.spacer_td) {
                border-radius: 0px;
                font-size: 16px;
                font-weight: 500;
                color: #000;
                width: auto;
                white-space: nowrap;
                border-color: #000;
                padding: 2px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_list3 table tr.student:not(.spacer) td:not(.spacer_td):last-child {
                  border-right-width: 1px;
                  border-top-right-radius: 0px;
                  border-bottom-right-radius: 0px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_tarl_notes {
              width: max-content;
              min-width: 100%;
              margin: 0px;
              backdrop-filter: none;
              background-color: #FFF;
              border: 0px;
              border-radius: 0px;
              padding: 0px; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_tarl_notes table tr.header td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_tarl_notes table tr.header th {
                background-image: none;
                background-color: #b5c4e3;
                font-size: 13px;
                font-weight: 600;
                color: #000;
                width: auto;
                white-space: nowrap;
                border-color: #000;
                height: 30px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_tarl_notes table tr.header td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_tarl_notes table tr.header th:first-child {
                  border-radius: 0px;
                  border-bottom-left-radius: 0px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_tarl_notes table tr.header td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.students_tarl_notes table tr.header th:last-child {
                  border-right-width: 0px;
                  border-top-right-radius: 10px;
                  border-bottom-right-radius: 10px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.cantine_garde {
              overflow: overlay !important; }
            .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table {
              margin: auto;
              width: 100%;
              border-collapse: collapse;
              border-spacing: 0px;
              border: 1px solid #000; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr {
                text-align: center; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr td:not(.spacer_td) {
                  width: 6%;
                  padding: 3px;
                  font-size: 10px; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr td:not(.spacer_td).large {
                    width: 6.46%; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr td:not(.spacer_td).name {
                    width: calc(16% - 20px); }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.header {
                  font-weight: bold; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.header td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.header th {
                    padding-bottom: 20px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.spacer {
                  height: 10px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.student:not(.spacer) td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.student:not(.spacer) th, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.header td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.header th {
                  background-color: white;
                  height: auto;
                  border-style: solid;
                  border-color: #e8e8e8;
                  border-image: initial;
                  border-width: 1px 0px 1px 1px;
                  padding: 10px; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.student:not(.spacer) td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.student:not(.spacer) th:first-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.header td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.header th:first-child {
                    border-top-left-radius: 0px;
                    border-bottom-left-radius: 0px; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.student:not(.spacer) td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.student:not(.spacer) th:last-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.header td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.header th:last-child {
                    border-right-width: 0px;
                    border-top-right-radius: 0px;
                    border-bottom-right-radius: 0px; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.student:not(.spacer) td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.student:not(.spacer) th.gray, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.header td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.header th.gray {
                    background: #F5F5F5; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.student:not(.spacer) td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.student:not(.spacer) th.red, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.header td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.header th.red {
                    background: #FF0000; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.student:not(.spacer) td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.student:not(.spacer) th.greeen, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.header td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.header th.greeen {
                    background: #00FF00; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.student:not(.spacer) td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.student:not(.spacer) th.gray2, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.header td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.header th.gray2 {
                    background: gray; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.student:not(.spacer) td:not(.spacer_td).p_em, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.student:not(.spacer) th.p_em, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.header td:not(.spacer_td).p_em, .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr.header th.p_em {
                    max-width: 100px;
                    word-break: break-all; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr td.spacer_td {
                  width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr td.name_ {
                  background-color: #f5f6fa !important; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table tr td.name.header_level {
                  background-color: #e8e8e8 !important; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table.mobile {
                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px; }
                .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table.mobile tr {
                  text-align: center; }
                  .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table.mobile tr td:not(.spacer_td) {
                    width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table.mobile tr td:not(.spacer_td).large {
                      width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list table.mobile tr td:not(.spacer_td).name {
                      width: calc(50% - 10px); }
            .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students.viewer .students_list.no_scroll {
              overflow: unset; }
          .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .loading {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px; }
            .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .loading .loading_img {
              position: relative;
              width: 100%;
              height: 100%; }
              .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .payments_students .loading .loading_img img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%; }
        .dialog.dialogChooseExport.dialogListStudentsPayments .export_content .act_export {
          margin: 0 !important; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogListStudentsPayments {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
    .dialog.dialogChooseExport.dialogListInternalCallsVisits {
      width: 94%;
      height: 100%;
      padding-bottom: 2em; }
      .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content {
        height: calc(100% - 40px); }
        .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits {
          height: calc(100% - 156px); }
          .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            padding: 10px 15px;
            padding-bottom: 0;
            background-color: #f5f6fa;
            margin: 18px 15px;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: #d1dbe2;
            border-image: initial; }
            .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table {
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px; }
              .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr {
                text-align: center; }
                .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr td:not(.spacer_td) {
                  padding: 3px;
                  font-size: 10px; }
                .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.header {
                  font-weight: bold; }
                  .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.header td:not(.spacer_td), .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.header th {
                    padding-bottom: 20px; }
                .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.spacer {
                  height: 10px; }
                .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.call_visit:not(.spacer) td:not(.spacer_td), .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.call_visit:not(.spacer) th, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.header td:not(.spacer_td), .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.header th {
                  background-color: white;
                  height: 50px;
                  border-style: solid;
                  border-color: #e8e8e8;
                  border-image: initial;
                  border-width: 1px 0px 1px 1px;
                  padding: 10px 5px; }
                  .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.call_visit:not(.spacer) td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.call_visit:not(.spacer) th:first-child, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.header td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.header th:first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.call_visit:not(.spacer) td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.call_visit:not(.spacer) th:last-child, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.header td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.header th:last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.call_visit:not(.spacer) td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.call_visit:not(.spacer) th.gray, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.header td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.header th.gray {
                    background: #F5F5F5; }
                  .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.call_visit:not(.spacer) td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.call_visit:not(.spacer) th.red, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.header td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.header th.red {
                    background: #FF0000; }
                  .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.call_visit:not(.spacer) td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.call_visit:not(.spacer) th.greeen, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.header td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.header th.greeen {
                    background: #00FF00; }
                  .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.call_visit:not(.spacer) td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.call_visit:not(.spacer) th.gray2, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.header td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.header th.gray2 {
                    background: gray; }
                  .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.call_visit:not(.spacer) td:not(.spacer_td).p_em, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.call_visit:not(.spacer) th.p_em, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.header td:not(.spacer_td).p_em, .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr.header th.p_em {
                    max-width: 100px;
                    word-break: break-all; }
                .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr td.spacer_td {
                  width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8; }
                .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr td.name_ {
                  background-color: #f5f6fa !important; }
                .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table tr td.name.header_level {
                  background-color: #e8e8e8 !important; }
              .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table.mobile {
                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px; }
                .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table.mobile tr {
                  text-align: center; }
                  .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table.mobile tr td:not(.spacer_td) {
                    width: 25%; }
                    .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table.mobile tr td:not(.spacer_td).large {
                      width: 25%; }
                    .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list table.mobile tr td:not(.spacer_td).name {
                      width: calc(50% - 10px); }
            .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .calls_visits_list.no_scroll {
              overflow: unset; }
          .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .loading {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px; }
            .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .loading .loading_img {
              position: relative;
              width: 100%;
              height: 100%; }
              .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .calls_visits .loading .loading_img img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%; }
        .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .act_export {
          margin: 0 !important; }
          .dialog.dialogChooseExport.dialogListInternalCallsVisits .export_content .act_export .actions_export {
            margin-top: 20px !important; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogListInternalCallsVisits {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
    .dialog.dialogChooseExport.dialogListResponsables {
      max-width: 400px;
      margin: 1.75rem auto;
      height: max-content;
      max-height: 90%;
      min-height: unset;
      padding-bottom: 2em;
      min-width: unset !important;
      width: 100%; }
      .dialog.dialogChooseExport.dialogListResponsables .export_content {
        height: calc(100% - 45px); }
        .dialog.dialogChooseExport.dialogListResponsables .export_content .responsables_select {
          height: calc(100% - 156px); }
          .dialog.dialogChooseExport.dialogListResponsables .export_content .responsables_select .row {
            width: 100%;
            margin: 0; }
            .dialog.dialogChooseExport.dialogListResponsables .export_content .responsables_select .row .search {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              border-radius: 20px;
              background: #f8f8f8;
              height: 30px;
              border: 1px solid #b1bfcd;
              padding: 0px 10px;
              margin-bottom: 10px;
              width: 100%; }
              .dialog.dialogChooseExport.dialogListResponsables .export_content .responsables_select .row .search input {
                border: none;
                background: #f8f8f8;
                border-radius: 10px;
                flex: 1 1 0%; }
          .dialog.dialogChooseExport.dialogListResponsables .export_content .responsables_select .responsables {
            max-height: calc(100vh - 300px);
            overflow: auto; }
            .dialog.dialogChooseExport.dialogListResponsables .export_content .responsables_select .responsables::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background-color: #F5F5F5; }
            .dialog.dialogChooseExport.dialogListResponsables .export_content .responsables_select .responsables::-webkit-scrollbar {
              width: 5px;
              height: 10px; }
            .dialog.dialogChooseExport.dialogListResponsables .export_content .responsables_select .responsables::-webkit-scrollbar-thumb {
              background-color: #3eb6ad;
              border-radius: 8px; }
            .dialog.dialogChooseExport.dialogListResponsables .export_content .responsables_select .responsables .responsable {
              padding: 10px 0px;
              border-bottom: 1px solid #cccccc;
              position: relative;
              display: inline-block;
              white-space: nowrap;
              line-height: 1;
              display: flex;
              cursor: pointer; }
              .dialog.dialogChooseExport.dialogListResponsables .export_content .responsables_select .responsables .responsable > span {
                width: calc(100% - 30px);
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 24px; }
              .dialog.dialogChooseExport.dialogListResponsables .export_content .responsables_select .responsables .responsable .user_img {
                width: 24px;
                height: 24px;
                margin-right: 5px;
                border-radius: 50%; }
        .dialog.dialogChooseExport.dialogListResponsables .export_content .act_export {
          margin: 0 !important; }
          .dialog.dialogChooseExport.dialogListResponsables .export_content .act_export .actions_export {
            margin-top: 20px !important; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogListResponsables {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
    .dialog.dialogChooseExport.dialogListTags {
      max-width: 400px;
      margin: 1.75rem auto;
      height: max-content;
      max-height: 90%;
      min-height: unset;
      padding-bottom: 2em;
      min-width: unset !important;
      width: 100%; }
      .dialog.dialogChooseExport.dialogListTags .export_content {
        height: calc(100% - 45px); }
        .dialog.dialogChooseExport.dialogListTags .export_content .tags_select {
          height: calc(100% - 156px); }
          .dialog.dialogChooseExport.dialogListTags .export_content .tags_select .row {
            width: 100%;
            margin: 0; }
            .dialog.dialogChooseExport.dialogListTags .export_content .tags_select .row .search {
              display: flex;
              -webkit-box-align: center;
              align-items: center;
              border-radius: 20px;
              background: #f8f8f8;
              height: 30px;
              border: 1px solid #b1bfcd;
              padding: 0px 10px;
              margin-bottom: 10px;
              width: 100%; }
              .dialog.dialogChooseExport.dialogListTags .export_content .tags_select .row .search input {
                border: none;
                background: #f8f8f8;
                border-radius: 10px;
                flex: 1 1 0%; }
          .dialog.dialogChooseExport.dialogListTags .export_content .tags_select .selected_tags {
            width: 100%;
            display: flex;
            margin-bottom: 10px;
            display: none; }
            .dialog.dialogChooseExport.dialogListTags .export_content .tags_select .selected_tags .tag {
              padding: 0px 10px;
              margin-right: 10px;
              width: max-content;
              background: gray;
              border-radius: 15px; }
              .dialog.dialogChooseExport.dialogListTags .export_content .tags_select .selected_tags .tag > span {
                width: 24px;
                height: 24px;
                cursor: pointer;
                text-align: center;
                line-height: 24px;
                margin-left: 5px; }
          .dialog.dialogChooseExport.dialogListTags .export_content .tags_select .tags {
            max-height: calc(100vh - 300px);
            overflow: auto;
            cursor: pointer; }
            .dialog.dialogChooseExport.dialogListTags .export_content .tags_select .tags::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background-color: #F5F5F5; }
            .dialog.dialogChooseExport.dialogListTags .export_content .tags_select .tags::-webkit-scrollbar {
              width: 5px;
              height: 10px; }
            .dialog.dialogChooseExport.dialogListTags .export_content .tags_select .tags::-webkit-scrollbar-thumb {
              background-color: #3eb6ad;
              border-radius: 8px; }
            .dialog.dialogChooseExport.dialogListTags .export_content .tags_select .tags .tag {
              padding: 10px 0px;
              border-bottom: 1px solid #cccccc;
              position: relative;
              display: inline-block;
              white-space: nowrap;
              line-height: 1;
              display: flex; }
              .dialog.dialogChooseExport.dialogListTags .export_content .tags_select .tags .tag > span {
                width: calc(100% - 30px);
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 24px; }
        .dialog.dialogChooseExport.dialogListTags .export_content .act_export {
          margin: 0 !important; }
          .dialog.dialogChooseExport.dialogListTags .export_content .act_export .actions_export {
            margin-top: 20px !important; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogListTags {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
    .dialog.dialogChooseExport.dialogProfessorsTasksStats {
      width: 94%;
      height: 100%;
      padding-bottom: 2em; }
      .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content {
        height: calc(100% - 40px); }
        .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students {
          height: calc(100% - 166px); }
          .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list {
            height: 100%;
            width: 100%;
            overflow: auto !important;
            width: max-content;
            min-width: 100%;
            margin: 18px 0;
            overflow-y: auto;
            padding: 10px 15px;
            padding-bottom: 0;
            background-color: #f5f6fa;
            margin: 18px 0;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: #d1dbe2;
            border-image: initial; }
            .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table {
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px; }
              .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr {
                text-align: center; }
                .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr td:not(.spacer_td) {
                  padding: 3px;
                  font-size: 13px; }
                  .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr td:not(.spacer_td).large {
                    width: 6.46%; }
                  .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr td:not(.spacer_td).gray {
                    background: gray; }
                  .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr td:not(.spacer_td).spans span {
                    display: block; }
                    .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr td:not(.spacer_td).spans span:last-of-type {
                      font-size: 10px; }
                .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr.header {
                  font-weight: bold; }
                  .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr.header td:not(.spacer_td) {
                    padding-bottom: 20px; }
                .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr.spacer {
                  height: 10px; }
                .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr.student:not(.spacer) td:not(.spacer_td), .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr.header td:not(.spacer_td) {
                  background-color: white;
                  height: 50px;
                  border-style: solid;
                  border-color: #e8e8e8;
                  border-image: initial;
                  border-width: 1px 0px 1px 1px;
                  padding: 10px;
                  min-width: 100px; }
                  .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr.student:not(.spacer) td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr.header td:not(.spacer_td):first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px; }
                  .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr.student:not(.spacer) td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr.header td:not(.spacer_td):last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px; }
                  .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr.student:not(.spacer) td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr.header td:not(.spacer_td).gray {
                    background: #F5F5F5; }
                  .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr.student:not(.spacer) td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr.header td:not(.spacer_td).red {
                    background: #FF0000; }
                  .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr.student:not(.spacer) td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr.header td:not(.spacer_td).greeen {
                    background: #00FF00; }
                  .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr.student:not(.spacer) td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr.header td:not(.spacer_td).gray2 {
                    background: gray; }
                .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr td.spacer_td {
                  width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8; }
                .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr td.name_ {
                  background-color: #f5f6fa !important; }
                .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table tr td.name.header_level {
                  background-color: #e8e8e8 !important; }
              .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table.mobile {
                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px; }
                .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table.mobile tr {
                  text-align: center; }
                  .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table.mobile tr td:not(.spacer_td) {
                    width: 25%; }
                    .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table.mobile tr td:not(.spacer_td).large {
                      width: 25%; }
                    .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list table.mobile tr td:not(.spacer_td).name {
                      width: calc(50% - 10px); }
            .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list.no_scroll {
              overflow: unset; }
            .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background-color: #F5F5F5; }
            .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list::-webkit-scrollbar {
              width: 5px;
              height: 10px; }
            .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .professors_tasks_list::-webkit-scrollbar-thumb {
              background-color: #3eb6ad;
              border-radius: 8px; }
          .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .loading {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px; }
            .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .loading .loading_img {
              position: relative;
              width: 100%;
              height: 100%; }
              .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .payments_students .loading .loading_img img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%; }
        .dialog.dialogChooseExport.dialogProfessorsTasksStats .export_content .act_export {
          margin: 0 !important; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogProfessorsTasksStats {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
    .dialog.dialogChooseExport.dialogListProfessorsAbsences {
      width: 94%;
      height: 100%;
      padding-bottom: 2em; }
      .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content {
        height: calc(100% - 40px); }
        .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students {
          height: calc(100% - 166px); }
          .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            padding: 10px 15px;
            padding-bottom: 0;
            background-color: #f5f6fa;
            margin: 18px 15px;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: #d1dbe2;
            border-image: initial; }
            .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table {
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px; }
              .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr {
                text-align: center; }
                .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr td:not(.spacer_td), .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr th {
                  width: 6%;
                  padding: 3px;
                  font-size: 12px; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr td:not(.spacer_td).large, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr th.large {
                    width: 6.46%; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr td:not(.spacer_td).name, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr th.name {
                    width: 100%; }
                .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.header {
                  font-weight: bold; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.header td:not(.spacer_td), .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.header th {
                    padding-bottom: 20px; }
                .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.spacer {
                  height: 10px; }
                .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.student:not(.spacer) td:not(.spacer_td), .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.student:not(.spacer) th, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.header td:not(.spacer_td), .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.header th {
                  background-color: white;
                  height: 50px;
                  border-style: solid;
                  border-color: #e8e8e8;
                  border-image: initial;
                  border-width: 1px 0px 1px 1px;
                  padding: 10px; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.student:not(.spacer) td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.student:not(.spacer) th:first-child, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.header td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.header th:first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.student:not(.spacer) td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.student:not(.spacer) th:last-child, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.header td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.header th:last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.student:not(.spacer) td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.student:not(.spacer) th.gray, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.header td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.header th.gray {
                    background: #F5F5F5; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.student:not(.spacer) td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.student:not(.spacer) th.red, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.header td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.header th.red {
                    background: #FF0000; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.student:not(.spacer) td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.student:not(.spacer) th.greeen, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.header td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.header th.greeen {
                    background: #00FF00; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.student:not(.spacer) td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.student:not(.spacer) th.gray2, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.header td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr.header th.gray2 {
                    background: gray; }
                .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr td.spacer_td {
                  width: 10px;
                  max-width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8; }
                .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr td.list_profs {
                  word-break: break-all; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr td.list_profs.name {
                    width: calc(8%) !important; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr td.list_profs.phone {
                    width: 7% !important; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr td.list_profs.email {
                    width: 7% !important; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr td.list_profs.classes {
                    width: calc(25% - 100px) !important; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr td.list_profs.classes_ {
                    width: calc(10%) !important; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr td.list_profs.levels_ {
                    width: calc(10%) !important; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr td.list_profs.subjects_ {
                    width: calc(10%) !important; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr td.list_profs.levels {
                    width: calc(22%) !important; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr td.list_profs.subjects {
                    width: calc(22%) !important; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table tr td.list_profs.status {
                    width: 6% !important; }
              .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table.mobile {
                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px; }
                .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table.mobile tr {
                  text-align: center; }
                  .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table.mobile tr td:not(.spacer_td) {
                    width: 25%; }
                    .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table.mobile tr td:not(.spacer_td).large {
                      width: 25%; }
                    .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table.mobile tr td:not(.spacer_td).name {
                      width: calc(50% - 10px); }
              .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table td:not(.w50) {
                width: inherit !important; }
              .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list table .professor_absences td {
                width: inherit !important; }
            .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .professors_list.no_scroll {
              overflow: unset; }
          .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .loading {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px; }
            .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .loading .loading_img {
              position: relative;
              width: 100%;
              height: 100%; }
              .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .payments_students .loading .loading_img img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%; }
        .dialog.dialogChooseExport.dialogListProfessorsAbsences .export_content .act_export {
          margin: 0 !important; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogListProfessorsAbsences {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
    .dialog.dialogChooseExport.dialogListFieldsExport {
      width: max-content;
      height: 500px;
      max-height: 100%;
      min-height: 320px;
      padding-bottom: 2em;
      min-width: 600px;
      max-width: 100%; }
      .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content {
        height: calc(100% - 50px); }
        .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .header_ {
          height: 40px; }
          .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .header_.header_2 {
            display: flex; }
            .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .header_.header_2 > div {
              width: 32%;
              max-width: 300px;
              margin-right: 1%; }
              .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .header_.header_2 > div > button {
                border-radius: 20px;
                height: 30px;
                line-height: 16px;
                width: max-content;
                padding: 0 10px; }
              .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .header_.header_2 > div.input > span {
                margin-right: 5px; }
              .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .header_.header_2 > div.input input {
                width: max-content;
                height: 30px;
                border-radius: 50px;
                border: 1px solid #cbd2da;
                font-size: 12px;
                padding-left: 12px;
                background-color: white;
                box-shadow: none; }
              .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .header_.header_2 > div.input .errors_ {
                color: red; }
          .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .header_.header3_ {
            display: flex;
            flex-wrap: wrap;
            height: unset; }
            .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .header_.header3_ .RadioLegend {
              width: 100%;
              display: flex; }
              .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .header_.header3_ .RadioLegend legend {
                font-size: 14px;
                font-weight: bold; }
            .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .header_.header3_ .predefined_lists .predefined_list {
              width: 100%;
              display: flex;
              font-weight: bold;
              margin-bottom: 5px; }
              .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .header_.header3_ .predefined_lists .predefined_list .fa-check {
                width: 20px;
                height: 20px;
                cursor: pointer;
                font-family: fontawesome, Montserrat, sans-serif;
                border: 1px solid;
                border-radius: 50%;
                padding: 3px 5px 3px 3px;
                font-size: 15px;
                color: #b7b6b6;
                position: relative;
                margin-right: 10px; }
                .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .header_.header3_ .predefined_lists .predefined_list .fa-check:before {
                  left: 10%;
                  top: 10%;
                  width: 80%;
                  height: 80%;
                  position: absolute;
                  font-size: 11px; }
                .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .header_.header3_ .predefined_lists .predefined_list .fa-check.checked {
                  color: white;
                  background: #58a182; }
        .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .contents_ {
          height: calc(100% - 260px); }
          .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .contents_ .fields_ {
            overflow-x: auto; }
            .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .contents_ .fields_::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
              background-color: #F5F5F5 !important; }
            .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .contents_ .fields_::-webkit-scrollbar {
              width: 5px !important;
              height: 10px !important;
              background-color: #F5F5F5 !important; }
            .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .contents_ .fields_::-webkit-scrollbar-thumb {
              background-color: #3eb6ad !important;
              border-radius: 15px; }
        .dialog.dialogChooseExport.dialogListFieldsExport .fields_exports_content .footer_ {
          height: 40px; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogListFieldsExport {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
    .dialog.dialogChooseExport.dialogListSubjectsExport {
      width: max-content;
      height: 40%;
      min-height: 320px;
      padding-bottom: 2em;
      min-width: 600px;
      max-width: 100%; }
      .dialog.dialogChooseExport.dialogListSubjectsExport .subjects_exports_content {
        height: calc(100% - 50px); }
        .dialog.dialogChooseExport.dialogListSubjectsExport .subjects_exports_content .header_ {
          height: 40px; }
        .dialog.dialogChooseExport.dialogListSubjectsExport .subjects_exports_content .contents_ {
          height: calc(100% - 80px); }
          .dialog.dialogChooseExport.dialogListSubjectsExport .subjects_exports_content .contents_ .subjects_ {
            overflow-x: auto; }
            .dialog.dialogChooseExport.dialogListSubjectsExport .subjects_exports_content .contents_ .subjects_::-webkit-scrollbar-track {
              -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
              background-color: #F5F5F5 !important; }
            .dialog.dialogChooseExport.dialogListSubjectsExport .subjects_exports_content .contents_ .subjects_::-webkit-scrollbar {
              width: 5px !important;
              height: 10px !important;
              background-color: #F5F5F5 !important; }
            .dialog.dialogChooseExport.dialogListSubjectsExport .subjects_exports_content .contents_ .subjects_::-webkit-scrollbar-thumb {
              background-color: #3eb6ad !important;
              border-radius: 15px; }
        .dialog.dialogChooseExport.dialogListSubjectsExport .subjects_exports_content .footer_ {
          height: 40px; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogListSubjectsExport {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
    .dialog.dialogChooseExport.dialogListSMS {
      width: 94%;
      height: 100%;
      padding-bottom: 2em; }
      .dialog.dialogChooseExport.dialogListSMS .export_content {
        height: calc(100% - 40px); }
        .dialog.dialogChooseExport.dialogListSMS .export_content .sms {
          height: calc(100% - 166px); }
          .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            padding: 10px 15px;
            padding-bottom: 0;
            background-color: #f5f6fa;
            margin: 18px 15px;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: #d1dbe2;
            border-image: initial; }
            .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table {
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px; }
              .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr {
                text-align: center; }
                .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr td:not(.spacer_td) {
                  padding: 3px;
                  font-size: 10px; }
                  .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr td:not(.spacer_td).name {
                    min-width: 150px; }
                    .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr td:not(.spacer_td).name.name_2 {
                      padding-left: 40px !important; }
                    .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr td:not(.spacer_td).name i {
                      color: orange;
                      cursor: pointer;
                      margin-right: 5px;
                      font-size: 14px; }
                .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr.header {
                  font-weight: bold; }
                  .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr.header td:not(.spacer_td) {
                    padding-bottom: 20px; }
                .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr.spacer {
                  height: 10px; }
                .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr.student:not(.spacer) td:not(.spacer_td), .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr.header td:not(.spacer_td) {
                  background-color: white;
                  height: 50px;
                  border-style: solid;
                  border-color: #e8e8e8;
                  border-image: initial;
                  border-width: 1px 0px 1px 1px;
                  padding: 10px; }
                  .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr.student:not(.spacer) td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr.header td:not(.spacer_td):first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr.student:not(.spacer) td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr.header td:not(.spacer_td):last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr.student:not(.spacer) td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr.header td:not(.spacer_td).gray {
                    background: #F5F5F5; }
                  .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr.student:not(.spacer) td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr.header td:not(.spacer_td).red {
                    background: #FF0000; }
                  .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr.student:not(.spacer) td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr.header td:not(.spacer_td).greeen {
                    background: #00FF00; }
                  .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr.student:not(.spacer) td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr.header td:not(.spacer_td).gray2 {
                    background: gray; }
                .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table tr td.spacer_td {
                  width: 10px;
                  max-width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8; }
              .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table.mobile {
                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px; }
                .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table.mobile tr {
                  text-align: center; }
                  .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table.mobile tr td:not(.spacer_td) {
                    width: 25%; }
                    .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table.mobile tr td:not(.spacer_td).large {
                      width: 25%; }
                    .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table.mobile tr td:not(.spacer_td).name {
                      width: calc(50% - 10px); }
              .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table td {
                width: inherit !important; }
              .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list table .professor_absences td {
                width: inherit !important; }
            .dialog.dialogChooseExport.dialogListSMS .export_content .sms .sms_list.no_scroll {
              overflow: unset; }
          .dialog.dialogChooseExport.dialogListSMS .export_content .sms .loading {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px; }
            .dialog.dialogChooseExport.dialogListSMS .export_content .sms .loading .loading_img {
              position: relative;
              width: 100%;
              height: 100%; }
              .dialog.dialogChooseExport.dialogListSMS .export_content .sms .loading .loading_img img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%; }
        .dialog.dialogChooseExport.dialogListSMS .export_content .d_f_v {
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding-top: 16px; }
          .dialog.dialogChooseExport.dialogListSMS .export_content .d_f_v > div {
            display: flex; }
            .dialog.dialogChooseExport.dialogListSMS .export_content .d_f_v > div label {
              margin-right: 5px; }
        .dialog.dialogChooseExport.dialogListSMS .export_content .act_export {
          margin: 0 !important; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogListSMS {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
      .dialog.dialogChooseExport.dialogListSMS.dialogInfos {
        width: 50%;
        height: 250px;
        max-height: 250px; }
        .dialog.dialogChooseExport.dialogListSMS.dialogInfos .row.act_export {
          padding: 10px;
          text-align: center; }
          .dialog.dialogChooseExport.dialogListSMS.dialogInfos .row.act_export.error {
            color: red; }
          .dialog.dialogChooseExport.dialogListSMS.dialogInfos .row.act_export.warning {
            color: orange; }
          .dialog.dialogChooseExport.dialogListSMS.dialogInfos .row.act_export .message {
            padding: 20px 10px; }
          .dialog.dialogChooseExport.dialogListSMS.dialogInfos .row.act_export .actions_export .button {
            min-width: 100px;
            text-align: center; }
    .dialog.dialogChooseExport.dialogListControlsGraded {
      width: 94%;
      height: 100%;
      padding-bottom: 2em; }
      .dialog.dialogChooseExport.dialogListControlsGraded .export_content {
        height: calc(100% - 40px); }
        .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded {
          height: calc(100% - 166px); }
          .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            padding: 10px 15px;
            padding-bottom: 0;
            background-color: #f5f6fa;
            margin: 18px 15px;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: #d1dbe2;
            border-image: initial; }
            .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table {
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px; }
              .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr {
                text-align: center; }
                .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr td:not(.spacer_td) {
                  padding: 3px;
                  font-size: 10px; }
                  .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr td:not(.spacer_td).name {
                    min-width: 150px; }
                    .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr td:not(.spacer_td).name.name_2 {
                      padding-left: 40px !important; }
                    .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr td:not(.spacer_td).name i {
                      color: orange;
                      cursor: pointer;
                      margin-right: 5px;
                      font-size: 14px; }
                  .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr td:not(.spacer_td).spans span {
                    display: block; }
                  .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr td:not(.spacer_td) .percent {
                    width: 45px;
                    color: white;
                    font-weight: bold;
                    margin: auto;
                    border-radius: 10px;
                    display: block;
                    font-size: 10px;
                    height: 20px;
                    padding: 0.25em 0.6em;
                    line-height: 16px; }
                    .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr td:not(.spacer_td) .percent.red {
                      background: red; }
                    .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr td:not(.spacer_td) .percent.orange {
                      background: orange; }
                    .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr td:not(.spacer_td) .percent.green {
                      background: green; }
                    .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr td:not(.spacer_td) .percent.gray {
                      background: gray; }
                .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr.header {
                  font-weight: bold; }
                  .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr.header td:not(.spacer_td) {
                    padding-bottom: 20px; }
                .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr.spacer {
                  height: 10px; }
                .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr.student:not(.spacer) td:not(.spacer_td), .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr.header td:not(.spacer_td) {
                  background-color: white;
                  height: 50px;
                  border-style: solid;
                  border-color: #e8e8e8;
                  border-image: initial;
                  border-width: 1px 0px 1px 1px;
                  padding: 10px; }
                  .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr.student:not(.spacer) td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr.header td:not(.spacer_td):first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr.student:not(.spacer) td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr.header td:not(.spacer_td):last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr.student:not(.spacer) td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr.header td:not(.spacer_td).gray {
                    background: #F5F5F5; }
                  .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr.student:not(.spacer) td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr.header td:not(.spacer_td).red {
                    background: #FF0000; }
                  .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr.student:not(.spacer) td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr.header td:not(.spacer_td).greeen {
                    background: #00FF00; }
                  .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr.student:not(.spacer) td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr.header td:not(.spacer_td).gray2 {
                    background: gray; }
                .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table tr td.spacer_td {
                  width: 10px;
                  max-width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8; }
              .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table.mobile {
                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px; }
                .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table.mobile tr {
                  text-align: center; }
                  .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table.mobile tr td:not(.spacer_td) {
                    width: 25%; }
                    .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table.mobile tr td:not(.spacer_td).large {
                      width: 25%; }
                    .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table.mobile tr td:not(.spacer_td).name {
                      width: calc(50% - 10px); }
              .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table td {
                width: inherit !important; }
              .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list table .professor_absences td {
                width: inherit !important; }
            .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .controls_graded_list.no_scroll {
              overflow: unset; }
          .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .loading {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px; }
            .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .loading .loading_img {
              position: relative;
              width: 100%;
              height: 100%; }
              .dialog.dialogChooseExport.dialogListControlsGraded .export_content .controls_graded .loading .loading_img img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%; }
        .dialog.dialogChooseExport.dialogListControlsGraded .export_content .d_f_v {
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding-top: 16px; }
          .dialog.dialogChooseExport.dialogListControlsGraded .export_content .d_f_v > div {
            display: flex; }
            .dialog.dialogChooseExport.dialogListControlsGraded .export_content .d_f_v > div label {
              margin-right: 5px; }
        .dialog.dialogChooseExport.dialogListControlsGraded .export_content .act_export {
          margin: 0 !important; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogListControlsGraded {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
      .dialog.dialogChooseExport.dialogListControlsGraded.dialogInfos {
        width: 50%;
        height: 250px;
        max-height: 250px; }
        .dialog.dialogChooseExport.dialogListControlsGraded.dialogInfos .row.act_export {
          padding: 10px;
          text-align: center; }
          .dialog.dialogChooseExport.dialogListControlsGraded.dialogInfos .row.act_export.error {
            color: red; }
          .dialog.dialogChooseExport.dialogListControlsGraded.dialogInfos .row.act_export.warning {
            color: orange; }
          .dialog.dialogChooseExport.dialogListControlsGraded.dialogInfos .row.act_export .message {
            padding: 20px 10px; }
          .dialog.dialogChooseExport.dialogListControlsGraded.dialogInfos .row.act_export .actions_export .button {
            min-width: 100px;
            text-align: center; }
    .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded {
      width: 94%;
      height: 100%;
      padding-bottom: 2em; }
      .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content {
        height: calc(100% - 40px); }
        .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded {
          height: calc(100% - 166px); }
          .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            padding: 10px 15px;
            padding-bottom: 0;
            background-color: #f5f6fa;
            margin: 18px 15px;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: #d1dbe2;
            border-image: initial; }
            .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table {
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px; }
              .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr {
                text-align: center; }
                .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr td:not(.spacer_td) {
                  padding: 3px;
                  font-size: 10px; }
                  .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr td:not(.spacer_td).name {
                    min-width: 150px; }
                    .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr td:not(.spacer_td).name.name_2 {
                      padding-left: 40px !important; }
                    .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr td:not(.spacer_td).name i {
                      color: orange;
                      cursor: pointer;
                      margin-right: 5px;
                      font-size: 14px; }
                  .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr td:not(.spacer_td).spans span {
                    display: block; }
                  .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr td:not(.spacer_td) .percent {
                    width: 45px;
                    color: white;
                    font-weight: bold;
                    margin: auto;
                    border-radius: 10px;
                    display: block;
                    font-size: 10px;
                    height: 20px;
                    padding: 0.25em 0.6em;
                    line-height: 16px; }
                    .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr td:not(.spacer_td) .percent.red {
                      background: red; }
                    .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr td:not(.spacer_td) .percent.orange {
                      background: orange; }
                    .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr td:not(.spacer_td) .percent.green {
                      background: green; }
                    .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr td:not(.spacer_td) .percent.gray {
                      background: gray; }
                .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr.header {
                  font-weight: bold; }
                  .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr.header td:not(.spacer_td) {
                    padding-bottom: 20px; }
                .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr.spacer {
                  height: 10px; }
                .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr.student:not(.spacer) td:not(.spacer_td), .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr.header td:not(.spacer_td) {
                  background-color: white;
                  height: 50px;
                  border-style: solid;
                  border-color: #e8e8e8;
                  border-image: initial;
                  border-width: 1px 0px 1px 1px;
                  padding: 10px; }
                  .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr.student:not(.spacer) td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr.header td:not(.spacer_td):first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr.student:not(.spacer) td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr.header td:not(.spacer_td):last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr.student:not(.spacer) td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr.header td:not(.spacer_td).gray {
                    background: #F5F5F5; }
                  .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr.student:not(.spacer) td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr.header td:not(.spacer_td).red {
                    background: #FF0000; }
                  .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr.student:not(.spacer) td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr.header td:not(.spacer_td).greeen {
                    background: #00FF00; }
                  .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr.student:not(.spacer) td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr.header td:not(.spacer_td).gray2 {
                    background: gray; }
                .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table tr td.spacer_td {
                  width: 10px;
                  max-width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8; }
              .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table.mobile {
                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px; }
                .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table.mobile tr {
                  text-align: center; }
                  .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table.mobile tr td:not(.spacer_td) {
                    width: 25%; }
                    .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table.mobile tr td:not(.spacer_td).large {
                      width: 25%; }
                    .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table.mobile tr td:not(.spacer_td).name {
                      width: calc(50% - 10px); }
              .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table td {
                width: inherit !important; }
              .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list table .professor_absences td {
                width: inherit !important; }
            .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .tests_diagnostic_graded_list.no_scroll {
              overflow: unset; }
          .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .loading {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px; }
            .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .loading .loading_img {
              position: relative;
              width: 100%;
              height: 100%; }
              .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .tests_diagnostic_graded .loading .loading_img img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%; }
        .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .d_f_v {
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding-top: 16px; }
          .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .d_f_v > div {
            display: flex; }
            .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .d_f_v > div label {
              margin-right: 5px; }
        .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded .export_content .act_export {
          margin: 0 !important; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
      .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded.dialogInfos {
        width: 50%;
        height: 250px;
        max-height: 250px; }
        .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded.dialogInfos .row.act_export {
          padding: 10px;
          text-align: center; }
          .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded.dialogInfos .row.act_export.error {
            color: red; }
          .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded.dialogInfos .row.act_export.warning {
            color: orange; }
          .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded.dialogInfos .row.act_export .message {
            padding: 20px 10px; }
          .dialog.dialogChooseExport.dialogListTestsDiagnosticGraded.dialogInfos .row.act_export .actions_export .button {
            min-width: 100px;
            text-align: center; }
    .dialog.dialogChooseExport.dialogGeneralDirection {
      width: 94%;
      height: 100%;
      padding-bottom: 2em; }
      .dialog.dialogChooseExport.dialogGeneralDirection .export_content {
        height: calc(100% - 40px); }
        .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction {
          height: calc(100% - 166px);
          width: 100%;
          overflow-y: auto;
          padding: 10px 15px;
          padding-bottom: 0;
          margin: 18px 15px;
          border-radius: 8px;
          padding: 10px 5px;
          border-width: 1px;
          border-style: solid;
          border-color: #d1dbe2;
          border-image: initial;
          margin-left: 0; }
          .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .loading {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px; }
            .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .loading .loading_img {
              position: relative;
              width: 100%;
              height: 100%; }
              .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .loading .loading_img img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%; }
          .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card {
            padding-top: 5px;
            padding-bottom: 5px;
            height: 100px; }
            .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card.azure_card > div {
              flex-direction: row;
              justify-content: center;
              align-items: center; }
            .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 100%;
              height: 100%;
              border-radius: 3px;
              background: white;
              border-radius: 10px;
              box-shadow: 1px 1px 6px 2px #b5adad; }
              .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div.green {
                border-left: 6px solid #1ae820; }
              .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div.green2 {
                border-left: 6px solid #53842a; }
              .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div.green3 {
                border-left: 6px solid #009688; }
              .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div.yellow2 {
                border-left: 6px solid #cddc39; }
              .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div.pink {
                border-left: 6px solid #e66bef; }
              .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div.orange {
                border-left: 6px solid #f36422; }
              .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div.yellow {
                border-left: 6px solid #ffee02; }
              .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div.yellow2 {
                border-left: 6px solid #cddc39; }
              .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div.blue {
                border-left: 6px solid #00adef; }
              .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div.blue2 {
                border-left: 6px solid #607d8b; }
              .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div.blue3 {
                border-left: 6px solid #001294; }
              .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div.blue4 {
                border-left: 6px solid #18d1e8; }
              .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div.red {
                border-left: 6px solid #f91004; }
              .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div.red2 {
                border-left: 6px solid #b50008; }
              .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div.gray {
                border-left: 6px solid #9e9e9e; }
              .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div.brown {
                border-left: 6px solid #795548; }
              .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div .title {
                margin: 0;
                padding: 5px 10px;
                display: flex;
                justify-content: space-between; }
                .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div .title .main_title {
                  color: black;
                  font-weight: normal;
                  font-size: 14px;
                  text-align: left; }
                .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div .title .second_title {
                  text-align: center;
                  color: black;
                  font-weight: normal;
                  font-size: 13px; }
                  .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div .title .second_title .select {
                    width: max-content;
                    height: 24px;
                    border-radius: 10px;
                    border: solid 0.6px #d1dbe2;
                    background-color: rgba(224, 227, 228, 0.3);
                    position: relative;
                    padding-left: 3px;
                    margin: auto; }
                    .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div .title .second_title .select select {
                      width: 100%;
                      font-size: 12px;
                      height: 22px;
                      border: none;
                      outline: none;
                      -webkit-appearance: none;
                      -moz-appearance: none;
                      appearance: none;
                      text-indent: 1px;
                      text-overflow: "";
                      background: transparent;
                      outline: 0;
                      position: relative;
                      z-index: 9;
                      border-radius: 4px !important;
                      border: none !important;
                      font-weight: 500;
                      font-family: Montserrat;
                      color: #1b2437;
                      padding: 0 5px;
                      cursor: pointer;
                      padding-right: 20px; }
                    .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div .title .second_title .select i {
                      color: #d2dbe2;
                      font-size: 14px;
                      position: absolute;
                      right: 5px;
                      top: 4px; }
              .dialog.dialogChooseExport.dialogGeneralDirection .export_content .general_direction .general_direction_card > div .value {
                padding-bottom: 10px;
                text-align: center;
                font-size: 18px; }
        .dialog.dialogChooseExport.dialogGeneralDirection .export_content .d_f_v {
          display: flex;
          justify-content: center;
          flex-direction: column;
          padding-top: 16px; }
          .dialog.dialogChooseExport.dialogGeneralDirection .export_content .d_f_v > div {
            display: flex; }
            .dialog.dialogChooseExport.dialogGeneralDirection .export_content .d_f_v > div label {
              margin-right: 5px; }
        .dialog.dialogChooseExport.dialogGeneralDirection .export_content .act_export {
          margin: 0 !important; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogGeneralDirection {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
      .dialog.dialogChooseExport.dialogGeneralDirection.dialogInfos {
        width: 50%;
        height: 250px;
        max-height: 250px; }
        .dialog.dialogChooseExport.dialogGeneralDirection.dialogInfos .row.act_export {
          padding: 10px;
          text-align: center; }
          .dialog.dialogChooseExport.dialogGeneralDirection.dialogInfos .row.act_export.error {
            color: red; }
          .dialog.dialogChooseExport.dialogGeneralDirection.dialogInfos .row.act_export.warning {
            color: orange; }
          .dialog.dialogChooseExport.dialogGeneralDirection.dialogInfos .row.act_export .message {
            padding: 20px 10px; }
          .dialog.dialogChooseExport.dialogGeneralDirection.dialogInfos .row.act_export .actions_export .button {
            min-width: 100px;
            text-align: center; }
    .dialog.dialogChooseExport.dialogListStudentCantineDates {
      width: 40%;
      height: 100%;
      padding-bottom: 2em;
      min-width: 400px; }
      .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content {
        height: calc(100% - 40px); }
        .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines {
          height: calc(100% - 103px);
          padding: 0 15px;
          padding-top: 15px; }
          .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            padding: 10px 15px;
            padding-bottom: 0;
            background-color: #f5f6fa;
            margin: 0;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: #d1dbe2;
            border-image: initial; }
            .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table {
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px; }
              .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr {
                text-align: center; }
                .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr td:not(.spacer_td) {
                  padding: 3px;
                  font-size: 10px;
                  border: 0.5px solid #e8e8e8; }
                  .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr td:not(.spacer_td) span {
                    width: 124px;
                    height: 30px;
                    object-fit: contain;
                    border-radius: 49.5px;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 500;
                    color: #fff;
                    text-align: center;
                    line-height: 30px;
                    text-align: center;
                    -webkit-letter-spacing: 0.5px;
                    -moz-letter-spacing: 0.5px;
                    -ms-letter-spacing: 0.5px;
                    letter-spacing: 0.5px;
                    padding: 2px 10px; }
                    .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr td:not(.spacer_td) span.present {
                      background-image: linear-gradient(127deg, #72dcd6, #3eb6ad);
                      box-shadow: 0 3px 17px 0 rgba(90, 202, 195, 0.5); }
                    .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr td:not(.spacer_td) span.absent {
                      background-image: linear-gradient(127deg, #d6474c, #ff0606);
                      box-shadow: 0px 3px 17px 0px rgba(202, 90, 90, 0.5); }
                .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.header {
                  font-weight: bold; }
                  .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.header td:not(.spacer_td) {
                    padding-bottom: 20px; }
                .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.spacer {
                  height: 10px; }
                .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.student:not(.spacer) td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.header td:not(.spacer_td) {
                  background-color: white;
                  height: 50px;
                  border-style: solid;
                  border-color: #e8e8e8;
                  border-image: initial;
                  border-width: 1px 0px 1px 1px;
                  padding: 10px;
                  padding: 3px;
                  font-size: 10px;
                  border: 0.5px solid #e8e8e8; }
                  .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.student:not(.spacer) td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.header td:not(.spacer_td):first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.student:not(.spacer) td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.header td:not(.spacer_td):last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.student:not(.spacer) td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.header td:not(.spacer_td).gray {
                    background: #F5F5F5; }
                  .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.student:not(.spacer) td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.header td:not(.spacer_td).red {
                    background: #FF0000; }
                  .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.student:not(.spacer) td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.header td:not(.spacer_td).greeen {
                    background: #00FF00; }
                  .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.student:not(.spacer) td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.header td:not(.spacer_td).gray2 {
                    background: gray; }
                .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr td.spacer_td {
                  width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8; }
              .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table.mobile {
                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px; }
                .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table.mobile tr {
                  text-align: center; }
                  .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table.mobile tr td:not(.spacer_td) {
                    width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table.mobile tr td:not(.spacer_td).large {
                      width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table.mobile tr td:not(.spacer_td).name {
                      width: calc(50% - 10px); }
            .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates.no_scroll {
              overflow: unset; }
          .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .loading {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px; }
            .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .loading .loading_img {
              position: relative;
              width: 100%;
              height: 100%; }
              .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .loading .loading_img img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%; }
        .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .act_export {
          margin: 0 !important; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogListStudentCantineDates {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
    .dialog.dialogChooseExport.dialogListStudentGardeDates {
      width: 40%;
      height: 100%;
      padding-bottom: 2em;
      min-width: 400px; }
      .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content {
        height: calc(100% - 40px); }
        .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes {
          height: calc(100% - 103px);
          padding: 0 15px;
          padding-top: 15px; }
          .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            padding: 10px 15px;
            padding-bottom: 0;
            background-color: #f5f6fa;
            margin: 0;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: #d1dbe2;
            border-image: initial; }
            .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table {
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px; }
              .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr {
                text-align: center; }
                .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr td:not(.spacer_td) {
                  padding: 3px;
                  font-size: 10px;
                  border: 0.5px solid #e8e8e8; }
                  .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr td:not(.spacer_td) span {
                    width: 124px;
                    height: 30px;
                    object-fit: contain;
                    border-radius: 49.5px;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 500;
                    color: #fff;
                    text-align: center;
                    line-height: 30px;
                    text-align: center;
                    -webkit-letter-spacing: 0.5px;
                    -moz-letter-spacing: 0.5px;
                    -ms-letter-spacing: 0.5px;
                    letter-spacing: 0.5px;
                    padding: 2px 10px; }
                    .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr td:not(.spacer_td) span.present {
                      background-image: linear-gradient(127deg, #72dcd6, #3eb6ad);
                      box-shadow: 0 3px 17px 0 rgba(90, 202, 195, 0.5); }
                    .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr td:not(.spacer_td) span.absent {
                      background-image: linear-gradient(127deg, #d6474c, #ff0606);
                      box-shadow: 0px 3px 17px 0px rgba(202, 90, 90, 0.5); }
                .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.header {
                  font-weight: bold; }
                  .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.header td:not(.spacer_td) {
                    padding-bottom: 20px; }
                .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.spacer {
                  height: 10px; }
                .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.student:not(.spacer) td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.header td:not(.spacer_td) {
                  background-color: white;
                  height: 50px;
                  border-style: solid;
                  border-color: #e8e8e8;
                  border-image: initial;
                  border-width: 1px 0px 1px 1px;
                  padding: 10px;
                  padding: 3px;
                  font-size: 10px;
                  border: 0.5px solid #e8e8e8; }
                  .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.student:not(.spacer) td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.header td:not(.spacer_td):first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.student:not(.spacer) td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.header td:not(.spacer_td):last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.student:not(.spacer) td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.header td:not(.spacer_td).gray {
                    background: #F5F5F5; }
                  .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.student:not(.spacer) td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.header td:not(.spacer_td).red {
                    background: #FF0000; }
                  .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.student:not(.spacer) td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.header td:not(.spacer_td).greeen {
                    background: #00FF00; }
                  .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.student:not(.spacer) td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.header td:not(.spacer_td).gray2 {
                    background: gray; }
                .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr td.spacer_td {
                  width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8; }
              .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table.mobile {
                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px; }
                .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table.mobile tr {
                  text-align: center; }
                  .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table.mobile tr td:not(.spacer_td) {
                    width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table.mobile tr td:not(.spacer_td).large {
                      width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table.mobile tr td:not(.spacer_td).name {
                      width: calc(50% - 10px); }
            .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates.no_scroll {
              overflow: unset; }
          .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .loading {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px; }
            .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .loading .loading_img {
              position: relative;
              width: 100%;
              height: 100%; }
              .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .loading .loading_img img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%; }
        .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .act_export {
          margin: 0 !important; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogListStudentGardeDates {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
    .dialog.dialogChooseExport.dialogListStudentGardeSoirDates {
      width: 40%;
      height: 100%;
      padding-bottom: 2em;
      min-width: 400px; }
      .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content {
        height: calc(100% - 40px); }
        .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir {
          height: calc(100% - 103px);
          padding: 0 15px;
          padding-top: 15px; }
          .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            padding: 10px 15px;
            padding-bottom: 0;
            background-color: #f5f6fa;
            margin: 0;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: #d1dbe2;
            border-image: initial; }
            .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table {
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px; }
              .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr {
                text-align: center; }
                .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr td:not(.spacer_td) {
                  padding: 3px;
                  font-size: 10px;
                  border: 0.5px solid #e8e8e8; }
                  .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr td:not(.spacer_td) span {
                    width: 124px;
                    height: 30px;
                    object-fit: contain;
                    border-radius: 49.5px;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 500;
                    color: #fff;
                    text-align: center;
                    line-height: 30px;
                    text-align: center;
                    -webkit-letter-spacing: 0.5px;
                    -moz-letter-spacing: 0.5px;
                    -ms-letter-spacing: 0.5px;
                    letter-spacing: 0.5px;
                    padding: 2px 10px; }
                    .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr td:not(.spacer_td) span.present {
                      background-image: linear-gradient(127deg, #72dcd6, #3eb6ad);
                      box-shadow: 0 3px 17px 0 rgba(90, 202, 195, 0.5); }
                    .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr td:not(.spacer_td) span.absent {
                      background-image: linear-gradient(127deg, #d6474c, #ff0606);
                      box-shadow: 0px 3px 17px 0px rgba(202, 90, 90, 0.5); }
                .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.header {
                  font-weight: bold; }
                  .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.header td:not(.spacer_td) {
                    padding-bottom: 20px; }
                .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.spacer {
                  height: 10px; }
                .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.student:not(.spacer) td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.header td:not(.spacer_td) {
                  background-color: white;
                  height: 50px;
                  border-style: solid;
                  border-color: #e8e8e8;
                  border-image: initial;
                  border-width: 1px 0px 1px 1px;
                  padding: 10px;
                  padding: 3px;
                  font-size: 10px;
                  border: 0.5px solid #e8e8e8; }
                  .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.student:not(.spacer) td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.header td:not(.spacer_td):first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.student:not(.spacer) td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.header td:not(.spacer_td):last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.student:not(.spacer) td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.header td:not(.spacer_td).gray {
                    background: #F5F5F5; }
                  .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.student:not(.spacer) td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.header td:not(.spacer_td).red {
                    background: #FF0000; }
                  .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.student:not(.spacer) td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.header td:not(.spacer_td).greeen {
                    background: #00FF00; }
                  .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.student:not(.spacer) td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.header td:not(.spacer_td).gray2 {
                    background: gray; }
                .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr td.spacer_td {
                  width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8; }
              .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table.mobile {
                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px; }
                .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table.mobile tr {
                  text-align: center; }
                  .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table.mobile tr td:not(.spacer_td) {
                    width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table.mobile tr td:not(.spacer_td).large {
                      width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table.mobile tr td:not(.spacer_td).name {
                      width: calc(50% - 10px); }
            .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates.no_scroll {
              overflow: unset; }
          .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .loading {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px; }
            .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .loading .loading_img {
              position: relative;
              width: 100%;
              height: 100%; }
              .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .loading .loading_img img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%; }
        .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .act_export {
          margin: 0 !important; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogListStudentGardeSoirDates {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
    .dialog.dialogChooseExport.dialogListStudentGardeRepasDates {
      width: 40%;
      height: 100%;
      padding-bottom: 2em;
      min-width: 400px; }
      .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content {
        height: calc(100% - 40px); }
        .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas {
          height: calc(100% - 103px);
          padding: 0 15px;
          padding-top: 15px; }
          .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            padding: 10px 15px;
            padding-bottom: 0;
            background-color: #f5f6fa;
            margin: 0;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: #d1dbe2;
            border-image: initial; }
            .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table {
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px; }
              .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr {
                text-align: center; }
                .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr td:not(.spacer_td) {
                  padding: 3px;
                  font-size: 10px;
                  border: 0.5px solid #e8e8e8; }
                  .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr td:not(.spacer_td) span {
                    width: 124px;
                    height: 30px;
                    object-fit: contain;
                    border-radius: 49.5px;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 500;
                    color: #fff;
                    text-align: center;
                    line-height: 30px;
                    text-align: center;
                    -webkit-letter-spacing: 0.5px;
                    -moz-letter-spacing: 0.5px;
                    -ms-letter-spacing: 0.5px;
                    letter-spacing: 0.5px;
                    padding: 2px 10px; }
                    .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr td:not(.spacer_td) span.present {
                      background-image: linear-gradient(127deg, #72dcd6, #3eb6ad);
                      box-shadow: 0 3px 17px 0 rgba(90, 202, 195, 0.5); }
                    .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr td:not(.spacer_td) span.absent {
                      background-image: linear-gradient(127deg, #d6474c, #ff0606);
                      box-shadow: 0px 3px 17px 0px rgba(202, 90, 90, 0.5); }
                .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.header {
                  font-weight: bold; }
                  .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.header td:not(.spacer_td) {
                    padding-bottom: 20px; }
                .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.spacer {
                  height: 10px; }
                .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.student:not(.spacer) td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.header td:not(.spacer_td) {
                  background-color: white;
                  height: 50px;
                  border-style: solid;
                  border-color: #e8e8e8;
                  border-image: initial;
                  border-width: 1px 0px 1px 1px;
                  padding: 10px;
                  padding: 3px;
                  font-size: 10px;
                  border: 0.5px solid #e8e8e8; }
                  .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.student:not(.spacer) td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.header td:not(.spacer_td):first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.student:not(.spacer) td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.header td:not(.spacer_td):last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.student:not(.spacer) td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.header td:not(.spacer_td).gray {
                    background: #F5F5F5; }
                  .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.student:not(.spacer) td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.header td:not(.spacer_td).red {
                    background: #FF0000; }
                  .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.student:not(.spacer) td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.header td:not(.spacer_td).greeen {
                    background: #00FF00; }
                  .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.student:not(.spacer) td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.header td:not(.spacer_td).gray2 {
                    background: gray; }
                .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr td.spacer_td {
                  width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8; }
              .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table.mobile {
                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px; }
                .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table.mobile tr {
                  text-align: center; }
                  .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table.mobile tr td:not(.spacer_td) {
                    width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table.mobile tr td:not(.spacer_td).large {
                      width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table.mobile tr td:not(.spacer_td).name {
                      width: calc(50% - 10px); }
            .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates.no_scroll {
              overflow: unset; }
          .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .loading {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px; }
            .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .loading .loading_img {
              position: relative;
              width: 100%;
              height: 100%; }
              .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .loading .loading_img img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%; }
        .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .act_export {
          margin: 0 !important; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogListStudentGardeRepasDates {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
    .dialog.dialogChooseExport.dialogListStudentCantineDates {
      width: 40%;
      height: 100%;
      padding-bottom: 2em;
      min-width: 400px; }
      .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content {
        height: calc(100% - 40px); }
        .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines {
          height: calc(100% - 103px);
          padding: 0 15px;
          padding-top: 15px; }
          .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            padding: 10px 15px;
            padding-bottom: 0;
            background-color: #f5f6fa;
            margin: 0;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: #d1dbe2;
            border-image: initial; }
            .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table {
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px; }
              .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr {
                text-align: center; }
                .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr td:not(.spacer_td) {
                  padding: 3px;
                  font-size: 10px;
                  border: 0.5px solid #e8e8e8; }
                  .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr td:not(.spacer_td) span {
                    width: 124px;
                    height: 30px;
                    object-fit: contain;
                    border-radius: 49.5px;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 500;
                    color: #fff;
                    text-align: center;
                    line-height: 30px;
                    text-align: center;
                    -webkit-letter-spacing: 0.5px;
                    -moz-letter-spacing: 0.5px;
                    -ms-letter-spacing: 0.5px;
                    letter-spacing: 0.5px;
                    padding: 2px 10px; }
                    .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr td:not(.spacer_td) span.present {
                      background-image: linear-gradient(127deg, #72dcd6, #3eb6ad);
                      box-shadow: 0 3px 17px 0 rgba(90, 202, 195, 0.5); }
                    .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr td:not(.spacer_td) span.absent {
                      background-image: linear-gradient(127deg, #d6474c, #ff0606);
                      box-shadow: 0px 3px 17px 0px rgba(202, 90, 90, 0.5); }
                .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.header {
                  font-weight: bold; }
                  .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.header td:not(.spacer_td) {
                    padding-bottom: 20px; }
                .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.spacer {
                  height: 10px; }
                .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.student:not(.spacer) td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.header td:not(.spacer_td) {
                  background-color: white;
                  height: 50px;
                  border-style: solid;
                  border-color: #e8e8e8;
                  border-image: initial;
                  border-width: 1px 0px 1px 1px;
                  padding: 10px;
                  padding: 3px;
                  font-size: 10px;
                  border: 0.5px solid #e8e8e8; }
                  .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.student:not(.spacer) td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.header td:not(.spacer_td):first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.student:not(.spacer) td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.header td:not(.spacer_td):last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.student:not(.spacer) td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.header td:not(.spacer_td).gray {
                    background: #F5F5F5; }
                  .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.student:not(.spacer) td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.header td:not(.spacer_td).red {
                    background: #FF0000; }
                  .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.student:not(.spacer) td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.header td:not(.spacer_td).greeen {
                    background: #00FF00; }
                  .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.student:not(.spacer) td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr.header td:not(.spacer_td).gray2 {
                    background: gray; }
                .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table tr td.spacer_td {
                  width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8; }
              .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table.mobile {
                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px; }
                .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table.mobile tr {
                  text-align: center; }
                  .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table.mobile tr td:not(.spacer_td) {
                    width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table.mobile tr td:not(.spacer_td).large {
                      width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates table.mobile tr td:not(.spacer_td).name {
                      width: calc(50% - 10px); }
            .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .cantine_dates.no_scroll {
              overflow: unset; }
          .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .loading {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px; }
            .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .loading .loading_img {
              position: relative;
              width: 100%;
              height: 100%; }
              .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .student_cantines .loading .loading_img img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%; }
        .dialog.dialogChooseExport.dialogListStudentCantineDates .export_content .act_export {
          margin: 0 !important; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogListStudentCantineDates {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
    .dialog.dialogChooseExport.dialogListStudentGardeDates {
      width: 40%;
      height: 100%;
      padding-bottom: 2em;
      min-width: 400px; }
      .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content {
        height: calc(100% - 40px); }
        .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes {
          height: calc(100% - 103px);
          padding: 0 15px;
          padding-top: 15px; }
          .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            padding: 10px 15px;
            padding-bottom: 0;
            background-color: #f5f6fa;
            margin: 0;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: #d1dbe2;
            border-image: initial; }
            .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table {
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px; }
              .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr {
                text-align: center; }
                .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr td:not(.spacer_td) {
                  padding: 3px;
                  font-size: 10px;
                  border: 0.5px solid #e8e8e8; }
                  .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr td:not(.spacer_td) span {
                    width: 124px;
                    height: 30px;
                    object-fit: contain;
                    border-radius: 49.5px;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 500;
                    color: #fff;
                    text-align: center;
                    line-height: 30px;
                    text-align: center;
                    -webkit-letter-spacing: 0.5px;
                    -moz-letter-spacing: 0.5px;
                    -ms-letter-spacing: 0.5px;
                    letter-spacing: 0.5px;
                    padding: 2px 10px; }
                    .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr td:not(.spacer_td) span.present {
                      background-image: linear-gradient(127deg, #72dcd6, #3eb6ad);
                      box-shadow: 0 3px 17px 0 rgba(90, 202, 195, 0.5); }
                    .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr td:not(.spacer_td) span.absent {
                      background-image: linear-gradient(127deg, #d6474c, #ff0606);
                      box-shadow: 0px 3px 17px 0px rgba(202, 90, 90, 0.5); }
                .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.header {
                  font-weight: bold; }
                  .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.header td:not(.spacer_td) {
                    padding-bottom: 20px; }
                .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.spacer {
                  height: 10px; }
                .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.student:not(.spacer) td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.header td:not(.spacer_td) {
                  background-color: white;
                  height: 50px;
                  border-style: solid;
                  border-color: #e8e8e8;
                  border-image: initial;
                  border-width: 1px 0px 1px 1px;
                  padding: 10px;
                  padding: 3px;
                  font-size: 10px;
                  border: 0.5px solid #e8e8e8; }
                  .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.student:not(.spacer) td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.header td:not(.spacer_td):first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.student:not(.spacer) td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.header td:not(.spacer_td):last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.student:not(.spacer) td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.header td:not(.spacer_td).gray {
                    background: #F5F5F5; }
                  .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.student:not(.spacer) td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.header td:not(.spacer_td).red {
                    background: #FF0000; }
                  .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.student:not(.spacer) td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.header td:not(.spacer_td).greeen {
                    background: #00FF00; }
                  .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.student:not(.spacer) td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr.header td:not(.spacer_td).gray2 {
                    background: gray; }
                .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table tr td.spacer_td {
                  width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8; }
              .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table.mobile {
                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px; }
                .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table.mobile tr {
                  text-align: center; }
                  .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table.mobile tr td:not(.spacer_td) {
                    width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table.mobile tr td:not(.spacer_td).large {
                      width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates table.mobile tr td:not(.spacer_td).name {
                      width: calc(50% - 10px); }
            .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .garde_dates.no_scroll {
              overflow: unset; }
          .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .loading {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px; }
            .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .loading .loading_img {
              position: relative;
              width: 100%;
              height: 100%; }
              .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .student_gardes .loading .loading_img img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%; }
        .dialog.dialogChooseExport.dialogListStudentGardeDates .export_content .act_export {
          margin: 0 !important; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogListStudentGardeDates {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
    .dialog.dialogChooseExport.dialogListStudentGardeSoirDates {
      width: 40%;
      height: 100%;
      padding-bottom: 2em;
      min-width: 400px; }
      .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content {
        height: calc(100% - 40px); }
        .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir {
          height: calc(100% - 103px);
          padding: 0 15px;
          padding-top: 15px; }
          .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            padding: 10px 15px;
            padding-bottom: 0;
            background-color: #f5f6fa;
            margin: 0;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: #d1dbe2;
            border-image: initial; }
            .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table {
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px; }
              .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr {
                text-align: center; }
                .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr td:not(.spacer_td) {
                  padding: 3px;
                  font-size: 10px;
                  border: 0.5px solid #e8e8e8; }
                  .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr td:not(.spacer_td) span {
                    width: 124px;
                    height: 30px;
                    object-fit: contain;
                    border-radius: 49.5px;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 500;
                    color: #fff;
                    text-align: center;
                    line-height: 30px;
                    text-align: center;
                    -webkit-letter-spacing: 0.5px;
                    -moz-letter-spacing: 0.5px;
                    -ms-letter-spacing: 0.5px;
                    letter-spacing: 0.5px;
                    padding: 2px 10px; }
                    .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr td:not(.spacer_td) span.present {
                      background-image: linear-gradient(127deg, #72dcd6, #3eb6ad);
                      box-shadow: 0 3px 17px 0 rgba(90, 202, 195, 0.5); }
                    .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr td:not(.spacer_td) span.absent {
                      background-image: linear-gradient(127deg, #d6474c, #ff0606);
                      box-shadow: 0px 3px 17px 0px rgba(202, 90, 90, 0.5); }
                .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.header {
                  font-weight: bold; }
                  .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.header td:not(.spacer_td) {
                    padding-bottom: 20px; }
                .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.spacer {
                  height: 10px; }
                .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.student:not(.spacer) td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.header td:not(.spacer_td) {
                  background-color: white;
                  height: 50px;
                  border-style: solid;
                  border-color: #e8e8e8;
                  border-image: initial;
                  border-width: 1px 0px 1px 1px;
                  padding: 10px;
                  padding: 3px;
                  font-size: 10px;
                  border: 0.5px solid #e8e8e8; }
                  .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.student:not(.spacer) td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.header td:not(.spacer_td):first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.student:not(.spacer) td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.header td:not(.spacer_td):last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.student:not(.spacer) td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.header td:not(.spacer_td).gray {
                    background: #F5F5F5; }
                  .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.student:not(.spacer) td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.header td:not(.spacer_td).red {
                    background: #FF0000; }
                  .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.student:not(.spacer) td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.header td:not(.spacer_td).greeen {
                    background: #00FF00; }
                  .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.student:not(.spacer) td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr.header td:not(.spacer_td).gray2 {
                    background: gray; }
                .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table tr td.spacer_td {
                  width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8; }
              .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table.mobile {
                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px; }
                .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table.mobile tr {
                  text-align: center; }
                  .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table.mobile tr td:not(.spacer_td) {
                    width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table.mobile tr td:not(.spacer_td).large {
                      width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates table.mobile tr td:not(.spacer_td).name {
                      width: calc(50% - 10px); }
            .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .garde_soir_dates.no_scroll {
              overflow: unset; }
          .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .loading {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px; }
            .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .loading .loading_img {
              position: relative;
              width: 100%;
              height: 100%; }
              .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .student_gardes_soir .loading .loading_img img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%; }
        .dialog.dialogChooseExport.dialogListStudentGardeSoirDates .export_content .act_export {
          margin: 0 !important; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogListStudentGardeSoirDates {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
    .dialog.dialogChooseExport.dialogListStudentGardeRepasDates {
      width: 40%;
      height: 100%;
      padding-bottom: 2em;
      min-width: 400px; }
      .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content {
        height: calc(100% - 40px); }
        .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas {
          height: calc(100% - 103px);
          padding: 0 15px;
          padding-top: 15px; }
          .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates {
            height: 100%;
            width: 100%;
            overflow-y: auto;
            padding: 10px 15px;
            padding-bottom: 0;
            background-color: #f5f6fa;
            margin: 0;
            border-radius: 8px;
            padding: 10px 5px;
            border-width: 1px;
            border-style: solid;
            border-color: #d1dbe2;
            border-image: initial; }
            .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table {
              margin: auto;
              width: 100%;
              border-collapse: separate;
              border-spacing: 0px; }
              .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr {
                text-align: center; }
                .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr td:not(.spacer_td) {
                  padding: 3px;
                  font-size: 10px;
                  border: 0.5px solid #e8e8e8; }
                  .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr td:not(.spacer_td) span {
                    width: 124px;
                    height: 30px;
                    object-fit: contain;
                    border-radius: 49.5px;
                    font-family: Montserrat;
                    font-size: 12px;
                    font-weight: 500;
                    color: #fff;
                    text-align: center;
                    line-height: 30px;
                    text-align: center;
                    -webkit-letter-spacing: 0.5px;
                    -moz-letter-spacing: 0.5px;
                    -ms-letter-spacing: 0.5px;
                    letter-spacing: 0.5px;
                    padding: 2px 10px; }
                    .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr td:not(.spacer_td) span.present {
                      background-image: linear-gradient(127deg, #72dcd6, #3eb6ad);
                      box-shadow: 0 3px 17px 0 rgba(90, 202, 195, 0.5); }
                    .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr td:not(.spacer_td) span.absent {
                      background-image: linear-gradient(127deg, #d6474c, #ff0606);
                      box-shadow: 0px 3px 17px 0px rgba(202, 90, 90, 0.5); }
                .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.header {
                  font-weight: bold; }
                  .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.header td:not(.spacer_td) {
                    padding-bottom: 20px; }
                .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.spacer {
                  height: 10px; }
                .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.student:not(.spacer) td:not(.spacer_td), .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.header td:not(.spacer_td) {
                  background-color: white;
                  height: 50px;
                  border-style: solid;
                  border-color: #e8e8e8;
                  border-image: initial;
                  border-width: 1px 0px 1px 1px;
                  padding: 10px;
                  padding: 3px;
                  font-size: 10px;
                  border: 0.5px solid #e8e8e8; }
                  .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.student:not(.spacer) td:not(.spacer_td):first-child, .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.header td:not(.spacer_td):first-child {
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.student:not(.spacer) td:not(.spacer_td):last-child, .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.header td:not(.spacer_td):last-child {
                    border-right-width: 1px;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px; }
                  .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.student:not(.spacer) td:not(.spacer_td).gray, .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.header td:not(.spacer_td).gray {
                    background: #F5F5F5; }
                  .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.student:not(.spacer) td:not(.spacer_td).red, .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.header td:not(.spacer_td).red {
                    background: #FF0000; }
                  .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.student:not(.spacer) td:not(.spacer_td).greeen, .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.header td:not(.spacer_td).greeen {
                    background: #00FF00; }
                  .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.student:not(.spacer) td:not(.spacer_td).gray2, .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr.header td:not(.spacer_td).gray2 {
                    background: gray; }
                .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table tr td.spacer_td {
                  width: 10px;
                  border-left: 1px;
                  border-left-style: solid;
                  border-left-color: #e8e8e8; }
              .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table.mobile {
                margin: auto;
                width: 100%;
                border-collapse: separate;
                border-spacing: 0px; }
                .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table.mobile tr {
                  text-align: center; }
                  .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table.mobile tr td:not(.spacer_td) {
                    width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table.mobile tr td:not(.spacer_td).large {
                      width: 25%; }
                    .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates table.mobile tr td:not(.spacer_td).name {
                      width: calc(50% - 10px); }
            .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .garde_repas_dates.no_scroll {
              overflow: unset; }
          .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .loading {
            position: relative;
            width: 100%;
            height: 100%;
            padding: 15px; }
            .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .loading .loading_img {
              position: relative;
              width: 100%;
              height: 100%; }
              .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .student_gardes_repas .loading .loading_img img {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                max-width: 50%;
                max-height: 50%; }
        .dialog.dialogChooseExport.dialogListStudentGardeRepasDates .export_content .act_export {
          margin: 0 !important; }
      @media (max-width: 768px) {
        .dialog.dialogChooseExport.dialogListStudentGardeRepasDates {
          height: 100%;
          width: 100%;
          margin: 0;
          padding: 2em; } }
    @media (max-width: 1199px) {
      .dialog.dialogChooseExport {
        width: 100%;
        padding: 2em 0;
        border: 0; } }
    @media (max-width: 768px) {
      .dialog.dialogChooseExport {
        height: 100%; } }
  .dialog.dialogInfoCandidate {
    width: 400px;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 25px;
    border-left: none;
    position: relative; }
  .dialog.dialogPrinView {
    width: 1000px;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 25px;
    border-left: none;
    position: relative; }
  .dialog.dialogAddResource {
    width: 500px;
    min-height: 500px;
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 25px;
    border-left: none;
    position: relative; }
  .dialog.dialogAddSchool {
    object-fit: contain;
    border-radius: 20px;
    box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
    background-color: #fff;
    padding: 25px;
    border-left: none;
    position: relative; }
    .dialog.dialogAddSchool.dialogAddGallery {
      height: 100%;
      min-width: 500px; }
      @media (max-width: 767px) {
        .dialog.dialogAddSchool.dialogAddGallery {
          margin: 0px;
          border-radius: 0;
          padding-right: 20px;
          padding-left: 20px; } }
    .dialog.dialogAddSchool.dialogAddSubject {
      max-width: 100%; }
      .dialog.dialogAddSchool.dialogAddSubject.dialogEditSubjectImage .image {
        width: 200px;
        height: 200px;
        margin: 0 auto; }
        .dialog.dialogAddSchool.dialogAddSubject.dialogEditSubjectImage .image img {
          max-width: 100%;
          max-height: 100%; }
          .dialog.dialogAddSchool.dialogAddSubject.dialogEditSubjectImage .image img.default {
            background: #423c3c; }
      .dialog.dialogAddSchool.dialogAddSubject.dialogEditSubjectImage .browse {
        text-align: center;
        position: relative;
        overflow: hidden;
        width: 150px;
        margin: 0 auto;
        cursor: pointer;
        border-radius: 49.5px;
        background-color: #3eb6ad;
        padding: 5px; }
        .dialog.dialogAddSchool.dialogAddSubject.dialogEditSubjectImage .browse input {
          width: 100%;
          height: 100%;
          position: absolute;
          opacity: 0;
          left: 0;
          cursor: pointer; }
        .dialog.dialogAddSchool.dialogAddSubject.dialogEditSubjectImage .browse strong {
          cursor: pointer;
          color: white; }
    .dialog.dialogAddSchool.dialogBritishBarem {
      width: 80%;
      height: 90%; }
      .dialog.dialogAddSchool.dialogBritishBarem > div {
        height: 100%;
        max-height: calc(100% - 40px); }
        .dialog.dialogAddSchool.dialogBritishBarem > div.text-align-right {
          height: 40px; }
        .dialog.dialogAddSchool.dialogBritishBarem > div .subjects_test_modal {
          height: 100%; }
          .dialog.dialogAddSchool.dialogBritishBarem > div .subjects_test_modal .div_british_bulletin_barem {
            height: 100%; }
      .dialog.dialogAddSchool.dialogBritishBarem .div_british_bulletin_barem {
        width: 100%;
        height: calc(100% - 45px); }
        .dialog.dialogAddSchool.dialogBritishBarem .div_british_bulletin_barem > div {
          width: 100%; }
          .dialog.dialogAddSchool.dialogBritishBarem .div_british_bulletin_barem > div.title {
            text-align: center;
            font-weight: bold;
            margin-bottom: 15px; }
        .dialog.dialogAddSchool.dialogBritishBarem .div_british_bulletin_barem .british_bulletin_barem_content {
          height: calc(100% - 145px);
          min-height: calc(100% - 110px); }
          .dialog.dialogAddSchool.dialogBritishBarem .div_british_bulletin_barem .british_bulletin_barem_content .title label {
            width: 100px; }
          .dialog.dialogAddSchool.dialogBritishBarem .div_british_bulletin_barem .british_bulletin_barem_content .title input {
            background-color: #e9ecef;
            opacity: 1;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            width: calc(100% - 100px);
            padding-left: 10px !important; }
          .dialog.dialogAddSchool.dialogBritishBarem .div_british_bulletin_barem .british_bulletin_barem_content .editor {
            margin-bottom: 10px;
            height: calc(100% - 40px); }
            .dialog.dialogAddSchool.dialogBritishBarem .div_british_bulletin_barem .british_bulletin_barem_content .editor > div {
              height: 100% !important; }
            .dialog.dialogAddSchool.dialogBritishBarem .div_british_bulletin_barem .british_bulletin_barem_content .editor .tox {
              border-radius: 15px 15px 0 0; }
              .dialog.dialogAddSchool.dialogBritishBarem .div_british_bulletin_barem .british_bulletin_barem_content .editor .tox .tox-statusbar {
                display: none; }
              .dialog.dialogAddSchool.dialogBritishBarem .div_british_bulletin_barem .british_bulletin_barem_content .editor .tox .tox-toolbar--scrolling {
                flex-wrap: wrap;
                display: flex;
                justify-content: space-between; }
        .dialog.dialogAddSchool.dialogBritishBarem .div_british_bulletin_barem .schools_table {
          padding: 5px;
          border: 1px solid #cccccc;
          border-radius: 10px;
          width: max-content;
          margin: auto; }
      .dialog.dialogAddSchool.dialogBritishBarem .div_french_competency_bulletin_colors_barem {
        width: 100%;
        height: calc(100% - 45px); }
        .dialog.dialogAddSchool.dialogBritishBarem .div_french_competency_bulletin_colors_barem > div {
          width: 100%; }
          .dialog.dialogAddSchool.dialogBritishBarem .div_french_competency_bulletin_colors_barem > div.title {
            text-align: center;
            font-weight: bold;
            margin-bottom: 15px; }
        .dialog.dialogAddSchool.dialogBritishBarem .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content {
          height: calc(100% - 145px); }
          .dialog.dialogAddSchool.dialogBritishBarem .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .title label {
            width: 100px; }
          .dialog.dialogAddSchool.dialogBritishBarem .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .title input {
            background-color: #e9ecef;
            opacity: 1;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            width: calc(100% - 100px);
            padding-left: 10px !important; }
          .dialog.dialogAddSchool.dialogBritishBarem .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor {
            margin-bottom: 10px;
            height: calc(100% - 40px); }
            .dialog.dialogAddSchool.dialogBritishBarem .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor > div {
              height: 100% !important; }
            .dialog.dialogAddSchool.dialogBritishBarem .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor .tox {
              border-radius: 15px 15px 0 0; }
              .dialog.dialogAddSchool.dialogBritishBarem .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor .tox .tox-statusbar {
                display: none; }
              .dialog.dialogAddSchool.dialogBritishBarem .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor .tox .tox-toolbar--scrolling {
                flex-wrap: wrap;
                display: flex;
                justify-content: space-between; }
        .dialog.dialogAddSchool.dialogBritishBarem .div_french_competency_bulletin_colors_barem .schools_table {
          padding: 5px;
          border: 1px solid #cccccc;
          border-radius: 10px;
          width: max-content;
          margin: auto; }
    .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback {
      width: 80%;
      height: 90%; }
      .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback > div {
        height: 100%;
        max-height: calc(100% - 40px); }
        .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback > div.text-align-right {
          height: 40px; }
        .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback > div .subjects_test_modal {
          height: 100%; }
          .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback > div .subjects_test_modal .div_bulletin_anglo_parents_feedbacks {
            height: 100%; }
      .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback .div_bulletin_anglo_parents_feedbacks {
        width: 100%;
        height: calc(100% - 45px); }
        .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback .div_bulletin_anglo_parents_feedbacks > div {
          width: 100%; }
          .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback .div_bulletin_anglo_parents_feedbacks > div.title {
            text-align: center;
            font-weight: bold;
            margin-bottom: 15px; }
        .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback .div_bulletin_anglo_parents_feedbacks .bulletin_anglo_parents_feedbacks_content {
          height: calc(100% - 145px);
          min-height: calc(100% - 110px); }
          .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback .div_bulletin_anglo_parents_feedbacks .bulletin_anglo_parents_feedbacks_content .editor {
            margin-bottom: 10px;
            height: calc(100% - 40px);
            max-height: 230px; }
            .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback .div_bulletin_anglo_parents_feedbacks .bulletin_anglo_parents_feedbacks_content .editor > div {
              height: 100% !important; }
            .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback .div_bulletin_anglo_parents_feedbacks .bulletin_anglo_parents_feedbacks_content .editor .tox {
              border-radius: 15px 15px 0 0; }
              .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback .div_bulletin_anglo_parents_feedbacks .bulletin_anglo_parents_feedbacks_content .editor .tox .tox-statusbar {
                display: none; }
              .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback .div_bulletin_anglo_parents_feedbacks .bulletin_anglo_parents_feedbacks_content .editor .tox .tox-toolbar--scrolling {
                flex-wrap: wrap;
                display: flex;
                justify-content: space-between; }
            .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback .div_bulletin_anglo_parents_feedbacks .bulletin_anglo_parents_feedbacks_content .editor > img {
              max-width: 100px;
              max-height: 100px;
              margin: 0;
              margin-right: 25px !important; }
            .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback .div_bulletin_anglo_parents_feedbacks .bulletin_anglo_parents_feedbacks_content .editor.qrcode {
              display: flex;
              position: relative;
              height: 100px; }
              .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback .div_bulletin_anglo_parents_feedbacks .bulletin_anglo_parents_feedbacks_content .editor.qrcode label {
                position: relative;
                object-fit: contain;
                border-radius: 49.5px;
                box-shadow: rgba(90, 202, 195, 0.5) 0px 6px 20px 0px;
                background-image: linear-gradient(261deg, #72dcd6, #3eb6ad);
                width: 180px;
                height: 30px;
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: 30px;
                letter-spacing: normal;
                text-align: center;
                color: white;
                margin-right: 25px;
                cursor: pointer; }
                .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback .div_bulletin_anglo_parents_feedbacks .bulletin_anglo_parents_feedbacks_content .editor.qrcode label input {
                  visibility: hidden;
                  position: absolute;
                  right: 0px;
                  top: 0px;
                  bottom: 0px; }
              .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback .div_bulletin_anglo_parents_feedbacks .bulletin_anglo_parents_feedbacks_content .editor.qrcode .loading-spinner {
                height: unset !important;
                margin: auto !important;
                left: 50% !important;
                top: 50% !important;
                transform: translate(-50%, -50%) !important; }
              .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback .div_bulletin_anglo_parents_feedbacks .bulletin_anglo_parents_feedbacks_content .editor.qrcode > * {
                margin: 0;
                margin-top: auto;
                margin-bottom: auto; }
                .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback .div_bulletin_anglo_parents_feedbacks .bulletin_anglo_parents_feedbacks_content .editor.qrcode > *.disabled {
                  opacity: 0.5; }
              .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback .div_bulletin_anglo_parents_feedbacks .bulletin_anglo_parents_feedbacks_content .editor.qrcode > a {
                border-radius: 49.5px;
                border: 0.6px solid #d1dbe2;
                background-color: rgba(245, 248, 249, 0.3);
                font-family: Montserrat;
                font-size: 12px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                letter-spacing: normal;
                text-align: center;
                color: #3eb6ad;
                width: 180px;
                height: 30px;
                line-height: 30px;
                display: inline-block; }
        .dialog.dialogAddSchool.dialogAngloBulletinParentsFeedback .div_bulletin_anglo_parents_feedbacks .schools_table {
          padding: 5px;
          border: 1px solid #cccccc;
          border-radius: 10px;
          width: max-content;
          margin: auto; }
    .dialog.dialogAddSchool.dialogBritishBulletin {
      width: 80%;
      height: 90%; }
      .dialog.dialogAddSchool.dialogBritishBulletin > div {
        height: 100%; }
      .dialog.dialogAddSchool.dialogBritishBulletin .div_british_bulletin_schools {
        width: 100%;
        height: calc(100% - 45px); }
        .dialog.dialogAddSchool.dialogBritishBulletin .div_british_bulletin_schools > div {
          width: 100%; }
          .dialog.dialogAddSchool.dialogBritishBulletin .div_british_bulletin_schools > div.title {
            text-align: center;
            font-weight: bold;
            margin-bottom: 15px; }
        .dialog.dialogAddSchool.dialogBritishBulletin .div_british_bulletin_schools .british_bulletin_schools_content {
          height: calc(100% - 145px); }
          .dialog.dialogAddSchool.dialogBritishBulletin .div_british_bulletin_schools .british_bulletin_schools_content .title label {
            width: 100px; }
          .dialog.dialogAddSchool.dialogBritishBulletin .div_british_bulletin_schools .british_bulletin_schools_content .title input {
            background-color: #e9ecef;
            opacity: 1;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            width: calc(100% - 100px);
            padding-left: 10px !important; }
          .dialog.dialogAddSchool.dialogBritishBulletin .div_british_bulletin_schools .british_bulletin_schools_content .editor {
            margin-bottom: 10px;
            height: calc(100% - 40px); }
            .dialog.dialogAddSchool.dialogBritishBulletin .div_british_bulletin_schools .british_bulletin_schools_content .editor > div {
              height: 100% !important; }
            .dialog.dialogAddSchool.dialogBritishBulletin .div_british_bulletin_schools .british_bulletin_schools_content .editor .tox {
              border-radius: 15px 15px 0 0; }
              .dialog.dialogAddSchool.dialogBritishBulletin .div_british_bulletin_schools .british_bulletin_schools_content .editor .tox .tox-statusbar {
                display: none; }
              .dialog.dialogAddSchool.dialogBritishBulletin .div_british_bulletin_schools .british_bulletin_schools_content .editor .tox .tox-toolbar--scrolling {
                flex-wrap: wrap;
                display: flex;
                justify-content: space-between; }
        .dialog.dialogAddSchool.dialogBritishBulletin .div_british_bulletin_schools .schools_table {
          padding: 5px;
          border: 1px solid #cccccc;
          border-radius: 10px;
          width: max-content;
          margin: auto; }
      .dialog.dialogAddSchool.dialogBritishBulletin .div_french_competency_bulletin_colors_barem {
        width: 100%;
        height: calc(100% - 45px); }
        .dialog.dialogAddSchool.dialogBritishBulletin .div_french_competency_bulletin_colors_barem > div {
          width: 100%; }
          .dialog.dialogAddSchool.dialogBritishBulletin .div_french_competency_bulletin_colors_barem > div.title {
            text-align: center;
            font-weight: bold;
            margin-bottom: 15px; }
        .dialog.dialogAddSchool.dialogBritishBulletin .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content {
          height: calc(100% - 145px); }
          .dialog.dialogAddSchool.dialogBritishBulletin .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .title label {
            width: 100px; }
          .dialog.dialogAddSchool.dialogBritishBulletin .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .title input {
            background-color: #e9ecef;
            opacity: 1;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            width: calc(100% - 100px);
            padding-left: 10px !important; }
          .dialog.dialogAddSchool.dialogBritishBulletin .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor {
            margin-bottom: 10px;
            height: calc(100% - 40px); }
            .dialog.dialogAddSchool.dialogBritishBulletin .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor > div {
              height: 100% !important; }
            .dialog.dialogAddSchool.dialogBritishBulletin .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor .tox {
              border-radius: 15px 15px 0 0; }
              .dialog.dialogAddSchool.dialogBritishBulletin .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor .tox .tox-statusbar {
                display: none; }
              .dialog.dialogAddSchool.dialogBritishBulletin .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor .tox .tox-toolbar--scrolling {
                flex-wrap: wrap;
                display: flex;
                justify-content: space-between; }
        .dialog.dialogAddSchool.dialogBritishBulletin .div_french_competency_bulletin_colors_barem .schools_table {
          padding: 5px;
          border: 1px solid #cccccc;
          border-radius: 10px;
          width: max-content;
          margin: auto; }
    .dialog.dialogAddSchool.dialogBritishBulletin2 {
      width: 80%;
      height: 90%; }
      .dialog.dialogAddSchool.dialogBritishBulletin2 > div {
        height: 100%; }
      .dialog.dialogAddSchool.dialogBritishBulletin2 .div_british_bulletin_2_schools {
        width: 100%;
        height: calc(100% - 45px); }
        .dialog.dialogAddSchool.dialogBritishBulletin2 .div_british_bulletin_2_schools > div {
          width: 100%; }
          .dialog.dialogAddSchool.dialogBritishBulletin2 .div_british_bulletin_2_schools > div.title {
            text-align: center;
            font-weight: bold;
            margin-bottom: 15px; }
        .dialog.dialogAddSchool.dialogBritishBulletin2 .div_british_bulletin_2_schools .british_bulletin_2_schools_content {
          height: calc(100% - 145px); }
          .dialog.dialogAddSchool.dialogBritishBulletin2 .div_british_bulletin_2_schools .british_bulletin_2_schools_content .title label {
            width: 100px; }
          .dialog.dialogAddSchool.dialogBritishBulletin2 .div_british_bulletin_2_schools .british_bulletin_2_schools_content .title input {
            background-color: #e9ecef;
            opacity: 1;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            width: calc(100% - 100px);
            padding-left: 10px !important; }
          .dialog.dialogAddSchool.dialogBritishBulletin2 .div_british_bulletin_2_schools .british_bulletin_2_schools_content .editor {
            margin-bottom: 10px;
            height: calc(100% - 40px); }
            .dialog.dialogAddSchool.dialogBritishBulletin2 .div_british_bulletin_2_schools .british_bulletin_2_schools_content .editor > div {
              height: 100% !important; }
            .dialog.dialogAddSchool.dialogBritishBulletin2 .div_british_bulletin_2_schools .british_bulletin_2_schools_content .editor .tox {
              border-radius: 15px 15px 0 0; }
              .dialog.dialogAddSchool.dialogBritishBulletin2 .div_british_bulletin_2_schools .british_bulletin_2_schools_content .editor .tox .tox-statusbar {
                display: none; }
              .dialog.dialogAddSchool.dialogBritishBulletin2 .div_british_bulletin_2_schools .british_bulletin_2_schools_content .editor .tox .tox-toolbar--scrolling {
                flex-wrap: wrap;
                display: flex;
                justify-content: space-between; }
        .dialog.dialogAddSchool.dialogBritishBulletin2 .div_british_bulletin_2_schools .schools_table {
          padding: 5px;
          border: 1px solid #cccccc;
          border-radius: 10px;
          width: max-content;
          margin: auto; }
      .dialog.dialogAddSchool.dialogBritishBulletin2 .div_white_exams_schools {
        width: 100%;
        height: calc(100% - 45px); }
        .dialog.dialogAddSchool.dialogBritishBulletin2 .div_white_exams_schools > div {
          width: 100%; }
          .dialog.dialogAddSchool.dialogBritishBulletin2 .div_white_exams_schools > div.title {
            text-align: center;
            font-weight: bold;
            margin-bottom: 15px; }
        .dialog.dialogAddSchool.dialogBritishBulletin2 .div_white_exams_schools .white_exams_schools_content {
          height: calc(100% - 145px); }
          .dialog.dialogAddSchool.dialogBritishBulletin2 .div_white_exams_schools .white_exams_schools_content .title label {
            width: 100px; }
          .dialog.dialogAddSchool.dialogBritishBulletin2 .div_white_exams_schools .white_exams_schools_content .title input {
            background-color: #e9ecef;
            opacity: 1;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            width: calc(100% - 100px);
            padding-left: 10px !important; }
          .dialog.dialogAddSchool.dialogBritishBulletin2 .div_white_exams_schools .white_exams_schools_content .editor {
            margin-bottom: 10px;
            height: calc(100% - 40px); }
            .dialog.dialogAddSchool.dialogBritishBulletin2 .div_white_exams_schools .white_exams_schools_content .editor > div {
              height: 100% !important; }
            .dialog.dialogAddSchool.dialogBritishBulletin2 .div_white_exams_schools .white_exams_schools_content .editor .tox {
              border-radius: 15px 15px 0 0; }
              .dialog.dialogAddSchool.dialogBritishBulletin2 .div_white_exams_schools .white_exams_schools_content .editor .tox .tox-statusbar {
                display: none; }
              .dialog.dialogAddSchool.dialogBritishBulletin2 .div_white_exams_schools .white_exams_schools_content .editor .tox .tox-toolbar--scrolling {
                flex-wrap: wrap;
                display: flex;
                justify-content: space-between; }
        .dialog.dialogAddSchool.dialogBritishBulletin2 .div_white_exams_schools .schools_table {
          padding: 5px;
          border: 1px solid #cccccc;
          border-radius: 10px;
          width: max-content;
          margin: auto; }
      .dialog.dialogAddSchool.dialogBritishBulletin2 .div_french_competency_bulletin_colors_barem {
        width: 100%;
        height: calc(100% - 45px); }
        .dialog.dialogAddSchool.dialogBritishBulletin2 .div_french_competency_bulletin_colors_barem > div {
          width: 100%; }
          .dialog.dialogAddSchool.dialogBritishBulletin2 .div_french_competency_bulletin_colors_barem > div.title {
            text-align: center;
            font-weight: bold;
            margin-bottom: 15px; }
        .dialog.dialogAddSchool.dialogBritishBulletin2 .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content {
          height: calc(100% - 145px); }
          .dialog.dialogAddSchool.dialogBritishBulletin2 .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .title label {
            width: 100px; }
          .dialog.dialogAddSchool.dialogBritishBulletin2 .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .title input {
            background-color: #e9ecef;
            opacity: 1;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            width: calc(100% - 100px);
            padding-left: 10px !important; }
          .dialog.dialogAddSchool.dialogBritishBulletin2 .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor {
            margin-bottom: 10px;
            height: calc(100% - 40px); }
            .dialog.dialogAddSchool.dialogBritishBulletin2 .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor > div {
              height: 100% !important; }
            .dialog.dialogAddSchool.dialogBritishBulletin2 .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor .tox {
              border-radius: 15px 15px 0 0; }
              .dialog.dialogAddSchool.dialogBritishBulletin2 .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor .tox .tox-statusbar {
                display: none; }
              .dialog.dialogAddSchool.dialogBritishBulletin2 .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor .tox .tox-toolbar--scrolling {
                flex-wrap: wrap;
                display: flex;
                justify-content: space-between; }
        .dialog.dialogAddSchool.dialogBritishBulletin2 .div_french_competency_bulletin_colors_barem .schools_table {
          padding: 5px;
          border: 1px solid #cccccc;
          border-radius: 10px;
          width: max-content;
          margin: auto; }
    .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor {
      width: 80%;
      height: 90%; }
      .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor > div {
        height: 100%; }
      .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem {
        width: 100%;
        height: calc(100% - 45px); }
        .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem > div {
          width: 100%; }
          .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem > div.title {
            text-align: center;
            font-weight: bold;
            margin-bottom: 15px; }
        .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content {
          height: calc(100% - 145px); }
          .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .title label {
            width: 100px; }
          .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .title input {
            background-color: #e9ecef;
            opacity: 1;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            width: calc(100% - 100px);
            padding-left: 10px !important; }
          .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor {
            margin-bottom: 10px;
            height: calc(100% - 40px); }
            .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor > div {
              height: 100% !important; }
            .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor .tox {
              border-radius: 15px 15px 0 0; }
              .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor .tox .tox-statusbar {
                display: none; }
              .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor .tox .tox-toolbar--scrolling {
                flex-wrap: wrap;
                display: flex;
                justify-content: space-between; }
        .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem .schools_table {
          padding: 5px;
          border: 1px solid #cccccc;
          border-radius: 10px;
          width: max-content;
          margin: auto; }
      .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem {
        width: 100%;
        height: calc(100% - 45px); }
        .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem > div {
          width: 100%; }
          .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem > div.title {
            text-align: center;
            font-weight: bold;
            margin-bottom: 15px; }
        .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content {
          height: calc(100% - 145px); }
          .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .title label {
            width: 100px; }
          .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .title input {
            background-color: #e9ecef;
            opacity: 1;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            width: calc(100% - 100px);
            padding-left: 10px !important; }
          .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor {
            margin-bottom: 10px;
            height: calc(100% - 40px); }
            .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor > div {
              height: 100% !important; }
            .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor .tox {
              border-radius: 15px 15px 0 0; }
              .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor .tox .tox-statusbar {
                display: none; }
              .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem .french_competency_bulletin_colors_barem_content .editor .tox .tox-toolbar--scrolling {
                flex-wrap: wrap;
                display: flex;
                justify-content: space-between; }
        .dialog.dialogAddSchool.dialogFrenchCompetencyBulletinColor .div_french_competency_bulletin_colors_barem .schools_table {
          padding: 5px;
          border: 1px solid #cccccc;
          border-radius: 10px;
          width: max-content;
          margin: auto; }
    .dialog.dialogAddSchool.dialogAppreciationGeneralThresholds {
      width: 100%;
      max-width: 600px; }
      .dialog.dialogAddSchool.dialogAppreciationGeneralThresholds > div {
        height: 100%; }
        .dialog.dialogAddSchool.dialogAppreciationGeneralThresholds > div .grade_thresholds {
          width: 100%; }
          .dialog.dialogAddSchool.dialogAppreciationGeneralThresholds > div .grade_thresholds .school_cycles .cycles {
            margin-bottom: 46px;
            margin-top: 15px; }
            .dialog.dialogAddSchool.dialogAppreciationGeneralThresholds > div .grade_thresholds .school_cycles .cycles .radioLevels {
              margin-top: -10px; }
    .dialog.dialogAddSchool.dialogLevelsNotesLabels {
      width: 100%;
      max-width: 800px; }
      .dialog.dialogAddSchool.dialogLevelsNotesLabels > div {
        height: 100%; }
        .dialog.dialogAddSchool.dialogLevelsNotesLabels > div .competencies_notes_labels {
          width: 100%; }
          .dialog.dialogAddSchool.dialogLevelsNotesLabels > div .competencies_notes_labels .school_cycles .cycles {
            margin-bottom: 46px;
            margin-top: 15px; }
            .dialog.dialogAddSchool.dialogLevelsNotesLabels > div .competencies_notes_labels .school_cycles .cycles .radioLevels {
              margin-top: -10px; }
          .dialog.dialogAddSchool.dialogLevelsNotesLabels > div .competencies_notes_labels .competencies_notes_labels_cards {
            margin-top: 15px; }
    .dialog.dialogAddSchool.dialogTableConfigurations {
      max-height: 90%; }
    .dialog.dialogAddSchool .image-profile {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #919191; }
      .dialog.dialogAddSchool .image-profile i {
        width: 150px;
        height: 150px;
        font-size: 150px;
        border-radius: 50%;
        border: 1px solid #919191;
        text-align: center;
        overflow: hidden; }
      .dialog.dialogAddSchool .image-profile img {
        width: 150px;
        height: 150px;
        border-radius: 50%; }
      .dialog.dialogAddSchool .image-profile .btn {
        position: relative;
        padding: 0px 20px;
        margin-top: 10px; }
        .dialog.dialogAddSchool .image-profile .btn input {
          opacity: 0;
          position: absolute;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%; }
    .dialog.dialogAddSchool.dialogAddCircuitStep {
      width: 30%; }
    .dialog.dialogAddSchool.dialogLate {
      width: 346px;
      height: 289px;
      object-fit: contain;
      border-radius: 20px; }
      .dialog.dialogAddSchool.dialogLate .add-school .add-school-form {
        margin-top: 0 !important; }
        .dialog.dialogAddSchool.dialogLate .add-school .add-school-form .school-ligne .school-ligneLate input {
          width: 190px;
          display: inline-block; }
        .dialog.dialogAddSchool.dialogLate .add-school .add-school-form .school-ligne .school-ligneLate span {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 400;
          color: rgba(27, 36, 55, 0.8);
          display: inline-block;
          line-height: 28px;
          margin-left: 10px; }
      .dialog.dialogAddSchool.dialogLate .bloc-next {
        text-align: center;
        margin-top: 25px; }
        .dialog.dialogAddSchool.dialogLate .bloc-next a {
          width: 124px;
          height: 30px;
          display: inline-block;
          object-fit: contain;
          line-height: 28px;
          text-align: center;
          border-radius: 49.5px;
          box-shadow: 0 3px 17px 0 rgba(90, 202, 195, 0.5);
          background-image: linear-gradient(157deg, #72dcd6, #3eb6ad);
          color: rgba(255, 255, 255, 0.9);
          font-size: 12px;
          font-weight: 500; }
    .dialog.dialogAddSchool.dialogChapter {
      width: 334px;
      max-width: 334px; }
      .dialog.dialogAddSchool.dialogChapter.dialogChapterrefentiel {
        width: auto;
        max-width: unset; }
      .dialog.dialogAddSchool.dialogChapter .school-ligne .upload img {
        width: 50px;
        height: 50px; }
      .dialog.dialogAddSchool.dialogChapter .school-ligne .upload span {
        width: 115px;
        display: inline-block;
        margin-left: 8px;
        height: 30px;
        object-fit: contain;
        border-radius: 49.5px;
        background-color: #3eb6ad;
        line-height: 30px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8); }
    .dialog.dialogAddSchool.dialogAddChapter {
      width: 742px;
      max-width: 742px;
      /*height: 438px;*/
      padding: 25px 12.5px;
      height: 100%; }
      .dialog.dialogAddSchool.dialogAddChapter.dialogAddDiscount {
        width: 80%;
        max-width: 80%; }
      .dialog.dialogAddSchool.dialogAddChapter .add-school .add-school-top {
        padding-left: 12.5px; }
      .dialog.dialogAddSchool.dialogAddChapter .add-school .top-assurance {
        display: flex;
        justify-content: space-between; }
      .dialog.dialogAddSchool.dialogAddChapter .add-school .chapters {
        margin-top: 25px; }
        .dialog.dialogAddSchool.dialogAddChapter .add-school .chapters .chapterAdd {
          /*float:left;
            width: calc(50% - 25px);
            margin: 0 12.5px 25px;*/
          margin-top: 10px;
          /*height: 156px;*/
          min-height: 190px;
          object-fit: contain;
          border-radius: 10px;
          box-shadow: 0 5px 48px 0 rgba(46, 104, 208, 0.1), 0 8px 30px 0 rgba(0, 0, 0, 0.07);
          background-color: #FFF;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center; }
          .dialog.dialogAddSchool.dialogAddChapter .add-school .chapters .chapterAdd span {
            width: 60px;
            height: 60px;
            border-radius: 150px;
            box-shadow: 13px 0px 30px 3px rgba(239, 194, 91, 0.5);
            background-image: linear-gradient(225deg, #fdd271, #d5a433);
            transform: rotate(45deg);
            color: #FFF;
            font-size: 47px;
            line-height: 60px;
            text-align: center; }
          .dialog.dialogAddSchool.dialogAddChapter .add-school .chapters .chapterAdd b {
            font-family: Montserrat;
            font-size: 16px;
            font-weight: 600;
            line-height: 18px;
            text-align: center;
            color: #1b2437;
            margin-top: 11px; }
        .dialog.dialogAddSchool.dialogAddChapter .add-school .chapters .cardChapter {
          /*float:left;
            width: calc(50% - 25px);
            margin: 0 12.5px 25px;*/
          min-height: 190px;
          margin-top: 10px;
          /* height: 156px; */
          object-fit: contain;
          border-radius: 10px;
          border: solid 1px #d1dbe2;
          background-color: #FFF;
          border-bottom: 6px solid #f2985a;
          padding: 6px 20px 0;
          position: relative; }
          .dialog.dialogAddSchool.dialogAddChapter .add-school .chapters .cardChapter .img {
            width: 96px;
            height: 96px;
            display: block;
            text-align: center;
            line-height: 96px;
            margin: auto; }
            .dialog.dialogAddSchool.dialogAddChapter .add-school .chapters .cardChapter .img img {
              width: 96px;
              height: 96px; }
          .dialog.dialogAddSchool.dialogAddChapter .add-school .chapters .cardChapter h4 {
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: normal;
            text-align: center;
            color: #1b2437;
            margin: 16px 0 0; }
        .dialog.dialogAddSchool.dialogAddChapter .add-school .chapters .cardChapterGroupe {
          /*float:left;
            width: calc(50% - 25px);
            margin: 0 12.5px 25px;*/
          min-height: 190px;
          margin-top: 10px;
          /* height: 156px; */
          object-fit: contain;
          border-radius: 10px;
          border: solid 1px #d1dbe2;
          background-color: #FFF;
          border-bottom: 6px solid #f2985a;
          padding: 6px 20px 0;
          position: relative; }
          .dialog.dialogAddSchool.dialogAddChapter .add-school .chapters .cardChapterGroupe .img {
            width: 96px;
            height: 96px;
            display: block;
            text-align: center;
            line-height: 96px;
            margin: auto; }
            .dialog.dialogAddSchool.dialogAddChapter .add-school .chapters .cardChapterGroupe .img img {
              width: 96px;
              height: 96px; }
          .dialog.dialogAddSchool.dialogAddChapter .add-school .chapters .cardChapterGroupe .names {
            position: absolute;
            width: calc(100% - 40px);
            top: 50%;
            transform: translateY(-50%); }
          .dialog.dialogAddSchool.dialogAddChapter .add-school .chapters .cardChapterGroupe h4 {
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            letter-spacing: normal;
            text-align: center;
            color: #1b2437;
            margin: 16px 0 0; }
      .dialog.dialogAddSchool.dialogAddChapter .sort_container_element {
        overflow-y: auto;
        width: 100%;
        height: 100%; }
        .dialog.dialogAddSchool.dialogAddChapter .sort_container_element .chapters {
          margin-right: 0 !important;
          margin-left: 0 !important; }
        .dialog.dialogAddSchool.dialogAddChapter .sort_container_element::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #F5F5F5; }
        .dialog.dialogAddSchool.dialogAddChapter .sort_container_element::-webkit-scrollbar {
          width: 5px;
          height: 3px;
          background-color: #F5F5F5; }
        .dialog.dialogAddSchool.dialogAddChapter .sort_container_element::-webkit-scrollbar-thumb {
          background-color: #3eb6ad; }
    .dialog.dialogAddSchool.dialogAddRegion {
      width: 30%;
      min-width: 500px;
      padding: 25px 12.5px;
      height: max-content;
      min-height: max-content; }
      .dialog.dialogAddSchool.dialogAddRegion .add-school .add-school-top {
        padding-left: 12.5px; }
        .dialog.dialogAddSchool.dialogAddRegion .add-school .add-school-top div.btn {
          box-shadow: none;
          background-image: none;
          color: #3eb6ad;
          border: none;
          font-size: 28px;
          margin-bottom: 15px;
          cursor: default; }
      .dialog.dialogAddSchool.dialogAddRegion .add-school .top-assurance {
        display: flex;
        justify-content: space-between; }
      .dialog.dialogAddSchool.dialogAddRegion .add-school .regions {
        margin: 0;
        margin-top: 25px; }
      .dialog.dialogAddSchool.dialogAddRegion .sort_container_element {
        overflow-y: auto;
        width: 100%;
        height: 100%; }
        .dialog.dialogAddSchool.dialogAddRegion .sort_container_element .chapters {
          margin-right: 0 !important;
          margin-left: 0 !important; }
        .dialog.dialogAddSchool.dialogAddRegion .sort_container_element::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #F5F5F5; }
        .dialog.dialogAddSchool.dialogAddRegion .sort_container_element::-webkit-scrollbar {
          width: 5px;
          height: 3px;
          background-color: #F5F5F5; }
        .dialog.dialogAddSchool.dialogAddRegion .sort_container_element::-webkit-scrollbar-thumb {
          background-color: #3eb6ad; }
    .dialog.dialogAddSchool.dialogAddProvince {
      width: 30%;
      min-width: 500px;
      padding: 25px 12.5px;
      height: max-content;
      min-height: max-content; }
      .dialog.dialogAddSchool.dialogAddProvince .add-school .add-school-top {
        padding-left: 12.5px; }
        .dialog.dialogAddSchool.dialogAddProvince .add-school .add-school-top div.btn {
          box-shadow: none;
          background-image: none;
          color: #3eb6ad;
          border: none;
          font-size: 28px;
          margin-bottom: 15px;
          cursor: default; }
      .dialog.dialogAddSchool.dialogAddProvince .add-school .top-assurance {
        display: flex;
        justify-content: space-between; }
      .dialog.dialogAddSchool.dialogAddProvince .add-school .provinces {
        margin: 0;
        margin-top: 25px; }
      .dialog.dialogAddSchool.dialogAddProvince .sort_container_element {
        overflow-y: auto;
        width: 100%;
        height: 100%; }
        .dialog.dialogAddSchool.dialogAddProvince .sort_container_element .chapters {
          margin-right: 0 !important;
          margin-left: 0 !important; }
        .dialog.dialogAddSchool.dialogAddProvince .sort_container_element::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #F5F5F5; }
        .dialog.dialogAddSchool.dialogAddProvince .sort_container_element::-webkit-scrollbar {
          width: 5px;
          height: 3px;
          background-color: #F5F5F5; }
        .dialog.dialogAddSchool.dialogAddProvince .sort_container_element::-webkit-scrollbar-thumb {
          background-color: #3eb6ad; }
    .dialog.dialogAddSchool.dialogAddCity {
      width: 30%;
      min-width: 500px;
      padding: 25px 12.5px;
      height: max-content;
      min-height: max-content; }
      .dialog.dialogAddSchool.dialogAddCity .add-school .add-school-top {
        padding-left: 12.5px; }
        .dialog.dialogAddSchool.dialogAddCity .add-school .add-school-top div.btn {
          box-shadow: none;
          background-image: none;
          color: #3eb6ad;
          border: none;
          font-size: 28px;
          margin-bottom: 15px;
          cursor: default; }
      .dialog.dialogAddSchool.dialogAddCity .add-school .top-assurance {
        display: flex;
        justify-content: space-between; }
      .dialog.dialogAddSchool.dialogAddCity .add-school .cities {
        margin: 0;
        margin-top: 25px; }
      .dialog.dialogAddSchool.dialogAddCity .sort_container_element {
        overflow-y: auto;
        width: 100%;
        height: 100%; }
        .dialog.dialogAddSchool.dialogAddCity .sort_container_element .chapters {
          margin-right: 0 !important;
          margin-left: 0 !important; }
        .dialog.dialogAddSchool.dialogAddCity .sort_container_element::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #F5F5F5; }
        .dialog.dialogAddSchool.dialogAddCity .sort_container_element::-webkit-scrollbar {
          width: 5px;
          height: 3px;
          background-color: #F5F5F5; }
        .dialog.dialogAddSchool.dialogAddCity .sort_container_element::-webkit-scrollbar-thumb {
          background-color: #3eb6ad; }
    .dialog.dialogAddSchool.dialogAddCommune {
      width: 30%;
      min-width: 500px;
      padding: 25px 12.5px;
      height: max-content;
      min-height: max-content; }
      .dialog.dialogAddSchool.dialogAddCommune .add-school .add-school-top {
        padding-left: 12.5px; }
        .dialog.dialogAddSchool.dialogAddCommune .add-school .add-school-top div.btn {
          box-shadow: none;
          background-image: none;
          color: #3eb6ad;
          border: none;
          font-size: 28px;
          margin-bottom: 15px;
          cursor: default; }
      .dialog.dialogAddSchool.dialogAddCommune .add-school .top-assurance {
        display: flex;
        justify-content: space-between; }
      .dialog.dialogAddSchool.dialogAddCommune .add-school .cities {
        margin: 0;
        margin-top: 25px; }
      .dialog.dialogAddSchool.dialogAddCommune .sort_container_element {
        overflow-y: auto;
        width: 100%;
        height: 100%; }
        .dialog.dialogAddSchool.dialogAddCommune .sort_container_element .chapters {
          margin-right: 0 !important;
          margin-left: 0 !important; }
        .dialog.dialogAddSchool.dialogAddCommune .sort_container_element::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #F5F5F5; }
        .dialog.dialogAddSchool.dialogAddCommune .sort_container_element::-webkit-scrollbar {
          width: 5px;
          height: 3px;
          background-color: #F5F5F5; }
        .dialog.dialogAddSchool.dialogAddCommune .sort_container_element::-webkit-scrollbar-thumb {
          background-color: #3eb6ad; }
    .dialog.dialogAddSchool.dialogAddZone {
      width: 30%;
      min-width: 500px;
      padding: 25px 12.5px;
      height: max-content;
      min-height: max-content; }
      .dialog.dialogAddSchool.dialogAddZone .add-school .add-school-top {
        padding-left: 12.5px; }
        .dialog.dialogAddSchool.dialogAddZone .add-school .add-school-top div.btn {
          box-shadow: none;
          background-image: none;
          color: #3eb6ad;
          border: none;
          font-size: 28px;
          margin-bottom: 15px;
          cursor: default; }
      .dialog.dialogAddSchool.dialogAddZone .add-school .top-assurance {
        display: flex;
        justify-content: space-between; }
      .dialog.dialogAddSchool.dialogAddZone .add-school .cities {
        margin: 0;
        margin-top: 25px; }
      .dialog.dialogAddSchool.dialogAddZone .sort_container_element {
        overflow-y: auto;
        width: 100%;
        height: 100%; }
        .dialog.dialogAddSchool.dialogAddZone .sort_container_element .chapters {
          margin-right: 0 !important;
          margin-left: 0 !important; }
        .dialog.dialogAddSchool.dialogAddZone .sort_container_element::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #F5F5F5; }
        .dialog.dialogAddSchool.dialogAddZone .sort_container_element::-webkit-scrollbar {
          width: 5px;
          height: 3px;
          background-color: #F5F5F5; }
        .dialog.dialogAddSchool.dialogAddZone .sort_container_element::-webkit-scrollbar-thumb {
          background-color: #3eb6ad; }
    .dialog.dialogAddSchool.dialogAddCompany {
      width: 30%;
      min-width: 500px;
      padding: 25px 12.5px;
      height: max-content;
      min-height: max-content; }
      .dialog.dialogAddSchool.dialogAddCompany .add-school .add-school-top {
        padding-left: 12.5px; }
        .dialog.dialogAddSchool.dialogAddCompany .add-school .add-school-top div.btn {
          box-shadow: none;
          background-image: none;
          color: #3eb6ad;
          border: none;
          font-size: 28px;
          margin-bottom: 15px;
          cursor: default; }
      .dialog.dialogAddSchool.dialogAddCompany .add-school .top-assurance {
        display: flex;
        justify-content: space-between; }
      .dialog.dialogAddSchool.dialogAddCompany .add-school .cities {
        margin: 0;
        margin-top: 25px; }
      .dialog.dialogAddSchool.dialogAddCompany .sort_container_element {
        overflow-y: auto;
        width: 100%;
        height: 100%; }
        .dialog.dialogAddSchool.dialogAddCompany .sort_container_element .chapters {
          margin-right: 0 !important;
          margin-left: 0 !important; }
        .dialog.dialogAddSchool.dialogAddCompany .sort_container_element::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #F5F5F5; }
        .dialog.dialogAddSchool.dialogAddCompany .sort_container_element::-webkit-scrollbar {
          width: 5px;
          height: 3px;
          background-color: #F5F5F5; }
        .dialog.dialogAddSchool.dialogAddCompany .sort_container_element::-webkit-scrollbar-thumb {
          background-color: #3eb6ad; }
    .dialog.dialogAddSchool.dialogParents {
      width: 650px;
      max-width: 650px;
      padding: 35px 80px 70px;
      position: relative; }
      .dialog.dialogAddSchool.dialogParents.dialogParentsInformations {
        max-width: 500px;
        padding: 25px; }
        .dialog.dialogAddSchool.dialogParents.dialogParentsInformations:before {
          display: none; }
        .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form {
          margin: 0 !important; }
          .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .upload {
            padding: 0 10px 30px;
            display: block;
            position: relative; }
            .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .upload input {
              visibility: hidden;
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0; }
            .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .upload img {
              width: 50px;
              height: 50px; }
            .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .upload span {
              width: 120px;
              height: 30px;
              object-fit: contain;
              border-radius: 49.5px;
              border: solid 2px #FFF;
              background-color: #3eb6ad;
              font-family: Montserrat;
              font-size: 12px;
              font-weight: 400;
              text-transform: uppercase;
              text-align: center;
              line-height: 28px;
              color: rgba(255, 255, 255, 0.8);
              display: inline-block;
              margin-left: 6px; }
          .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .gender {
            display: inline-block; }
            .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .gender > div {
              display: inline-block;
              position: relative; }
              .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .gender > div input {
                position: absolute;
                opacity: 0;
                width: 100%;
                height: 100%;
                left: 0px;
                cursor: pointer; }
              .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .gender > div span {
                float: left;
                width: 27px;
                height: 27px; }
              .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .gender > div b {
                font-family: Montserrat;
                font-size: 14px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 27px;
                margin-left: 7px;
                letter-spacing: normal;
                color: rgba(27, 36, 55, 0.8); }
            .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .gender .genderMale {
              display: inline-block; }
              .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .gender .genderMale span {
                background: url("../img/iconMale.svg") no-repeat scroll transparent;
                background-size: contain; }
              .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .gender .genderMale input:checked + span {
                background: url("../img/iconMaleActive.svg") no-repeat scroll transparent; }
            .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .gender .choiseSituation {
              display: inline-block; }
              .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .gender .choiseSituation span {
                background: url("../img/iconMale.svg") no-repeat scroll transparent;
                background-size: contain; }
              .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .gender .choiseSituation input:checked + span {
                background: url("../img/iconMaleActive.svg") no-repeat scroll transparent; }
            .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .gender .genderFemale {
              display: inline-block;
              margin-left: 18px; }
              .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .gender .genderFemale span {
                background: url("../img/iconFemale.svg") no-repeat scroll transparent;
                background-size: contain; }
              .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .gender .genderFemale input:checked + span {
                background: url("../img/iconFemaleActive.svg") no-repeat scroll transparent; }
      .dialog.dialogAddSchool.dialogParents:before {
        content: "";
        width: 101px;
        height: 116px;
        position: absolute;
        right: 10px;
        bottom: 35px;
        background: url("../../assets/img/iconFuseeCnx.png") no-repeat scroll transparent;
        -moz-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        transform: scaleX(-1);
        -ms-filter: fliph;
        /*IE*/
        filter: fliph;
        /*IE*/
        opacity: .7; }
      .dialog.dialogAddSchool.dialogParents .add-school .add-school-form {
        margin: 0 -10px; }
        .dialog.dialogAddSchool.dialogParents .add-school .add-school-form .school-ligne {
          float: left;
          width: 100%;
          margin-bottom: 25px; }
          .dialog.dialogAddSchool.dialogParents .add-school .add-school-form .school-ligne .school-ligne-w50 {
            width: 50%;
            float: left;
            padding: 0 10px; }
            .dialog.dialogAddSchool.dialogParents .add-school .add-school-form .school-ligne .school-ligne-w50 .intl-tel-input {
              width: 100%; }
          .dialog.dialogAddSchool.dialogParents .add-school .add-school-form .school-ligne .school-ligne-w100 {
            width: 100%;
            float: left;
            padding: 0 10px; }
          .dialog.dialogAddSchool.dialogParents .add-school .add-school-form .school-ligne .field_error {
            color: red; }
        .dialog.dialogAddSchool.dialogParents .add-school .add-school-form .bloc-next {
          text-align: right;
          float: left;
          width: 100%;
          margin-top: 5px; }
          .dialog.dialogAddSchool.dialogParents .add-school .add-school-form .bloc-next a {
            width: 120px;
            height: 35px;
            object-fit: contain;
            border-radius: 49.5px;
            box-shadow: 0 7px 20px 0 rgba(90, 202, 195, 0.5);
            background-image: linear-gradient(254deg, #72dcd6, #3eb6ad);
            font-size: 12px;
            font-weight: 600;
            color: #fff;
            text-align: center;
            line-height: 35px;
            display: inline-block; }
      .dialog.dialogAddSchool.dialogParents .add-school.persons_to_contact .person_to_conatct {
        position: relative;
        border: 2px solid #c8c8c8;
        border-radius: 7px;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 15px !important; }
        .dialog.dialogAddSchool.dialogParents .add-school.persons_to_contact .person_to_conatct .title {
          position: absolute;
          bottom: calc(100% - 5px);
          background-color: white;
          padding: 0 5px;
          color: gray;
          font-weight: bold; }
        .dialog.dialogAddSchool.dialogParents .add-school.persons_to_contact .person_to_conatct .display_flex label {
          width: 100%; }
        .dialog.dialogAddSchool.dialogParents .add-school.persons_to_contact .person_to_conatct .input_choice {
          width: 31%;
          margin: 1%; }
          .dialog.dialogAddSchool.dialogParents .add-school.persons_to_contact .person_to_conatct .input_choice input {
            width: 100%; }
        @media (max-width: 767px) {
          .dialog.dialogAddSchool.dialogParents .add-school.persons_to_contact .person_to_conatct .input_choice {
            width: 48%; } }
      .dialog.dialogAddSchool.dialogParents .add-school.persons_to_contact .school-ligne-w100 .intl-tel-input {
        width: 100%; }
      .dialog.dialogAddSchool.dialogParents .add-school.additional_information_form .main_label {
        width: 100%; }
      .dialog.dialogAddSchool.dialogParents .add-school.additional_information_form .input_choice {
        width: 31%;
        margin: 1%;
        min-width: max-content; }
        .dialog.dialogAddSchool.dialogParents .add-school.additional_information_form .input_choice.custm_label label {
          white-space: break-spaces; }
        .dialog.dialogAddSchool.dialogParents .add-school.additional_information_form .input_choice input {
          width: 100%; }
      @media (max-width: 767px) {
        .dialog.dialogAddSchool.dialogParents .add-school.additional_information_form .input_choice {
          width: 48%; } }
    .dialog.dialogAddSchool.dialogSignature {
      padding: 20px;
      min-width: 540px;
      min-height: 440px;
      /*@media(min-width: 1200px){
        min-width: 540px;
      }*/ }
    .dialog.dialogAddSchool.dialogAddProfessor {
      padding: 25px 20px 20px; }
      @media (min-width: 1200px) {
        .dialog.dialogAddSchool.dialogAddProfessor {
          min-width: 640px; } }
      .dialog.dialogAddSchool.dialogAddProfessor .add-school .add-school-form .school-ligne {
        margin-bottom: 0; }
        .dialog.dialogAddSchool.dialogAddProfessor .add-school .add-school-form .school-ligne.school-ligne-diploma {
          margin-bottom: 25px; }
          .dialog.dialogAddSchool.dialogAddProfessor .add-school .add-school-form .school-ligne.school-ligne-diploma .newClasseTop img.file {
            width: 50px;
            margin-right: 10px; }
          .dialog.dialogAddSchool.dialogAddProfessor .add-school .add-school-form .school-ligne.school-ligne-diploma .newClasseTop span {
            font-size: 15px;
            font-weight: 500; }
        .dialog.dialogAddSchool.dialogAddProfessor .add-school .add-school-form .school-ligne.school-ligne-margin {
          width: calc(100% + 50px);
          margin-left: -25px;
          margin-right: -25px;
          margin-bottom: 15px; }
          .dialog.dialogAddSchool.dialogAddProfessor .add-school .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 {
            padding: 0 25px; }
          .dialog.dialogAddSchool.dialogAddProfessor .add-school .add-school-form .school-ligne.school-ligne-margin .react-datepicker-wrapper {
            margin: 0px; }
        .dialog.dialogAddSchool.dialogAddProfessor .add-school .add-school-form .school-ligne .RadioLegend {
          /* width: 25%;*/
          width: 30%;
          margin: 0 0 15px; }
        .dialog.dialogAddSchool.dialogAddProfessor .add-school .add-school-form .school-ligne .radioLevels .RadioLegend > legend {
          text-transform: uppercase; }
        .dialog.dialogAddSchool.dialogAddProfessor .add-school .add-school-form .school-ligne.school-ligne-classes {
          border: 2px dotted #d8dfe6;
          margin: 0 -20px 15px;
          width: calc(100% + 40px);
          padding: 10px 20px 0; }
      .dialog.dialogAddSchool.dialogAddProfessor .error {
        color: red; }
    .dialog.dialogAddSchool.dialogAddProject {
      padding: 25px 20px 20px; }
      @media (min-width: 1200px) {
        .dialog.dialogAddSchool.dialogAddProject {
          min-width: 640px; } }
      .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne {
        margin-bottom: 0; }
        .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne.school-ligne-diploma {
          margin-bottom: 25px; }
          .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne.school-ligne-diploma .newProjectTop {
            text-align: center; }
            .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne.school-ligne-diploma .newProjectTop img {
              margin: auto;
              display: block; }
              .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne.school-ligne-diploma .newProjectTop img.file {
                width: 100px;
                margin-bottom: 10px; }
            .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne.school-ligne-diploma .newProjectTop .loading {
              width: 100px;
              height: 100px;
              margin: auto; }
              .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne.school-ligne-diploma .newProjectTop .loading .loading-spinner {
                display: flex;
                justify-content: center;
                flex-direction: column; }
            .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne.school-ligne-diploma .newProjectTop span {
              font-size: 15px;
              font-weight: 500; }
            .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne.school-ligne-diploma .newProjectTop > span {
              height: 34px;
              border-radius: 17px;
              background-color: #edf3f6;
              position: relative;
              display: flex;
              -webkit-box-pack: center;
              justify-content: center;
              -webkit-box-align: center;
              align-items: center;
              font-size: 13px;
              font-weight: 500;
              line-height: 1.38;
              color: #3eb6ad;
              margin: 0px 10px 0px 0px;
              cursor: pointer;
              box-shadow: #00000080 0px 2px 3px 0px;
              background-image: unset;
              width: max-content;
              margin: auto;
              padding: 5px 15px; }
        .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne.school-ligne-margin {
          width: calc(100% + 50px);
          margin-left: -25px;
          margin-right: -25px;
          margin-bottom: 15px; }
          .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 {
            padding: 0 25px; }
          .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne.school-ligne-margin .react-datepicker-wrapper {
            margin: 0px; }
        .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne .collaborators {
          position: relative;
          width: calc(100% - 50px);
          margin-bottom: 25px; }
          .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne .collaborators .colaborator {
            width: 24px;
            height: 24px;
            position: absolute;
            position: absolute;
            left: 0;
            border-radius: 50%;
            overflow: hidden; }
            .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne .collaborators .colaborator:hover {
              border: 2px solid #b8bcc1; }
            .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne .collaborators .colaborator img {
              width: 100%;
              height: 100%; }
            .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne .collaborators .colaborator.empty {
              text-align: center;
              background: #bfbdbd;
              line-height: 27px;
              padding-left: 2px;
              font-size: 12px; }
        .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne .supervisors {
          position: relative;
          width: calc(100% - 50px);
          margin-bottom: 25px; }
          .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne .supervisors .supervisor {
            width: 24px;
            height: 24px;
            position: absolute;
            position: absolute;
            left: 0;
            border-radius: 50%;
            overflow: hidden; }
            .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne .supervisors .supervisor:hover {
              border: 2px solid #b8bcc1; }
            .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne .supervisors .supervisor img {
              width: 100%;
              height: 100%; }
            .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne .supervisors .supervisor.empty {
              text-align: center;
              background: #bfbdbd;
              line-height: 27px;
              padding-left: 2px;
              font-size: 12px; }
        .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne.schoolBottom {
          display: flex;
          justify-content: center; }
        .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne button.add {
          height: 34px;
          border-radius: 17px;
          background-color: #edf3f6;
          position: relative;
          display: flex;
          -webkit-box-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          align-items: center;
          font-size: 13px;
          font-weight: 500;
          line-height: 1.38;
          color: #3eb6ad;
          margin: 0px 10px 0px 0px;
          cursor: pointer;
          box-shadow: #00000080 0px 2px 3px 0px;
          background-image: unset; }
        .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne .coll_label {
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px; }
          .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne .coll_label .custom_add_button {
            position: relative;
            width: max-content;
            padding: 5px 10px;
            margin-bottom: 0;
            cursor: pointer;
            margin-top: -5px; }
        .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne .supervisor_label {
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px; }
          .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne .supervisor_label .custom_add_button {
            position: relative;
            width: max-content;
            padding: 5px 10px;
            margin-bottom: 0;
            cursor: pointer;
            margin-top: -5px; }
        .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne .RadioLegend {
          /* width: 25%;*/
          width: 30%;
          margin: 0 0 15px; }
        .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne .radioLevels .RadioLegend > legend {
          text-transform: uppercase; }
        .dialog.dialogAddSchool.dialogAddProject .add-school .add-school-form .school-ligne.school-ligne-classes {
          border: 2px dotted #d8dfe6;
          margin: 0 -20px 15px;
          width: calc(100% + 40px);
          padding: 10px 20px 0; }
      .dialog.dialogAddSchool.dialogAddProject .error {
        color: red; }
    .dialog.dialogAddSchool.dialogProfessorAbscences {
      height: 100%;
      max-width: 80%;
      width: 80%; }
      .dialog.dialogAddSchool.dialogProfessorAbscences .add-school {
        height: calc(100% - 57px); }
      @media (max-width: 1199px) {
        .dialog.dialogAddSchool.dialogProfessorAbscences {
          max-width: 100%;
          width: 94% !important;
          padding: 25px 20px !important;
          margin: 0; } }
    .dialog.dialogAddSchool.dialogAssistantAbscences {
      height: 100%;
      max-width: 80%;
      width: 80%; }
      .dialog.dialogAddSchool.dialogAssistantAbscences .add-school {
        height: calc(100% - 57px); }
    .dialog.dialogAddSchool.dialogEstablishments {
      height: 100%;
      max-width: 80%;
      width: 80%; }
      .dialog.dialogAddSchool.dialogEstablishments .add-school {
        height: calc(100% - 57px); }
      .dialog.dialogAddSchool.dialogEstablishments .add_establishment {
        position: absolute;
        bottom: 5px;
        right: 10px;
        cursor: pointer;
        width: 105px;
        height: 30px;
        border-radius: 49.5px;
        box-shadow: 0 6px 24px 0 rgba(239, 194, 91, 0.5);
        background-image: linear-gradient(254deg, #fdd271, #d5a433);
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 30px;
        -webkit-letter-spacing: normal;
        -moz-letter-spacing: normal;
        -ms-letter-spacing: normal;
        -webkit-letter-spacing: normal;
        -moz-letter-spacing: normal;
        -ms-letter-spacing: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff; }
      .dialog.dialogAddSchool.dialogEstablishments .rs__menu {
        box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5);
        border: 0px;
        border-radius: 8px;
        padding: 5px;
        z-index: 1000;
        width: max-content;
        min-width: 100%; }
        .dialog.dialogAddSchool.dialogEstablishments .rs__menu .rs__menu-list::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #F5F5F5;
          border-radius: 5px; }
        .dialog.dialogAddSchool.dialogEstablishments .rs__menu .rs__menu-list::-webkit-scrollbar {
          width: 10px;
          /*height: 3px;*/
          background-color: #F5F5F5;
          border-radius: 5px; }
        .dialog.dialogAddSchool.dialogEstablishments .rs__menu .rs__menu-list::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
          border-radius: 5px; }
        .dialog.dialogAddSchool.dialogEstablishments .rs__menu .rs__menu-list .rs__option {
          border-bottom: 1px dashed #ccc; }
    .dialog.dialogAddSchool.dialogOriginEstablishment {
      height: 210px;
      max-width: 50%;
      width: 400px; }
      .dialog.dialogAddSchool.dialogOriginEstablishment .school-ligne-center {
        text-align: center;
        margin-top: 12px;
        margin-bottom: 0;
        width: 100%;
        float: left; }
      .dialog.dialogAddSchool.dialogOriginEstablishment .school-ligne-center button {
        width: 130px;
        height: 30px;
        object-fit: contain;
        border-radius: 49.5px;
        border: 0.6px solid #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3eb6ad;
        margin: 0 5px; }
        .dialog.dialogAddSchool.dialogOriginEstablishment .school-ligne-center button.add {
          box-shadow: 0 6px 20px 0 #5acac380;
          background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
          color: #fff;
          border: none; }
    .dialog.dialogAddSchool.dialogAddStudent .friends-switch-block {
      text-align: center; }
      @media (max-width: 767.98px) {
        .dialog.dialogAddSchool.dialogAddStudent .friends-switch-block {
          margin-top: 25px; } }
      .dialog.dialogAddSchool.dialogAddStudent .friends-switch-block .friends-switch {
        border-radius: 49.5px;
        border: .6px solid #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        height: 32px;
        display: inline-block;
        margin: 0 auto 20px;
        overflow: hidden; }
        .dialog.dialogAddSchool.dialogAddStudent .friends-switch-block .friends-switch a {
          font-family: Montserrat,sans-serif;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #3eb6ad;
          padding: 8px 20px;
          float: left;
          border-radius: 49.5px; }
          .dialog.dialogAddSchool.dialogAddStudent .friends-switch-block .friends-switch a.active {
            box-shadow: 0 -10px 20px 0 rgba(90, 202, 195, 0.5);
            background-image: -webkit-linear-gradient(191deg, #72dcd6, #3eb6ad);
            background-image: linear-gradient(259deg, #72dcd6, #3eb6ad);
            color: #fff;
            padding: 8px 30px; }
    .dialog.dialogAddSchool.dialogAddStudent .title-block {
      text-align: center; }
      @media (max-width: 767.98px) {
        .dialog.dialogAddSchool.dialogAddStudent .title-block {
          margin-top: 25px; } }
      .dialog.dialogAddSchool.dialogAddStudent .title-block .title {
        border-radius: 49.5px;
        border: .6px solid #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        height: 32px;
        display: inline-block;
        margin: 0 auto 20px;
        overflow: hidden;
        padding: 5px 10px;
        font-weight: bold; }
    .dialog.dialogAddSchool.dialogAddStudent .exist_students .friends-top, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friends-top {
      /*margin-top: 30px;*/
      float: left;
      width: 100%; }
      .dialog.dialogAddSchool.dialogAddStudent .exist_students .friends-top .friend-search, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friends-top .friend-search {
        float: left;
        width: 228px;
        position: relative; }
        @media (max-width: 767.98px) {
          .dialog.dialogAddSchool.dialogAddStudent .exist_students .friends-top .friend-search, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friends-top .friend-search {
            float: none;
            margin: auto; } }
        .dialog.dialogAddSchool.dialogAddStudent .exist_students .friends-top .friend-search input, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friends-top .friend-search input {
          height: 28px;
          border-radius: 19px;
          border: solid 1px #b1bfcd;
          background-color: #f8f8f8;
          width: 100%;
          padding-left: 16px; }
        .dialog.dialogAddSchool.dialogAddStudent .exist_students .friends-top .friend-search span, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friends-top .friend-search span {
          position: absolute;
          right: 11px;
          top: 3px; }
      .dialog.dialogAddSchool.dialogAddStudent .exist_students .friends-top .friend-right, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friends-top .friend-right {
        float: right;
        display: flex; }
        @media (max-width: 767.98px) {
          .dialog.dialogAddSchool.dialogAddStudent .exist_students .friends-top .friend-right, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friends-top .friend-right {
            float: none;
            justify-content: center; } }
        .dialog.dialogAddSchool.dialogAddStudent .exist_students .friends-top .friend-right .filter-objects .filter_dropdown ul, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friends-top .friend-right .filter-objects .filter_dropdown ul {
          padding-left: 10px; }
        .dialog.dialogAddSchool.dialogAddStudent .exist_students .friends-top .friend-right .filter-objects .filter_dropdown button, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friends-top .friend-right .filter-objects .filter_dropdown button {
          background: transparent;
          border: none; }
          .dialog.dialogAddSchool.dialogAddStudent .exist_students .friends-top .friend-right .filter-objects .filter_dropdown button:after, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friends-top .friend-right .filter-objects .filter_dropdown button:after {
            border-top: .35em solid;
            border-right: .35em solid transparent;
            border-bottom: 0;
            border-left: .35em solid transparent;
            color: #1b2437;
            vertical-align: 0.2em; }
          .dialog.dialogAddSchool.dialogAddStudent .exist_students .friends-top .friend-right .filter-objects .filter_dropdown button .subject-caret, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friends-top .friend-right .filter-objects .filter_dropdown button .subject-caret {
            display: none; }
          .dialog.dialogAddSchool.dialogAddStudent .exist_students .friends-top .friend-right .filter-objects .filter_dropdown button .icon_filter, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friends-top .friend-right .filter-objects .filter_dropdown button .icon_filter {
            background-image: url(../img/icon_classe.svg);
            width: 16px;
            height: 16px;
            display: inline-block;
            vertical-align: middle;
            background-size: contain;
            background-repeat: no-repeat; }
          .dialog.dialogAddSchool.dialogAddStudent .exist_students .friends-top .friend-right .filter-objects .filter_dropdown button .selected_text, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friends-top .friend-right .filter-objects .filter_dropdown button .selected_text {
            font-size: 14px;
            font-weight: 400;
            line-height: 1.42857143;
            text-align: center;
            white-space: nowrap;
            cursor: pointer;
            color: #333; }
        .dialog.dialogAddSchool.dialogAddStudent .exist_students .friends-top .friend-right .filter-objects .filter_dropdown.levels_dropdown button .icon_filter, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friends-top .friend-right .filter-objects .filter_dropdown.levels_dropdown button .icon_filter {
          background-image: url(../img/icon_level.svg); }
    .dialog.dialogAddSchool.dialogAddStudent .exist_students .row.filters .search2, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .row.filters .search2 {
      display: flex;
      min-width: 150px;
      width: 100%;
      height: 30px;
      position: relative;
      margin: 0 0 0 4px;
      border-radius: 8px;
      border: solid 1px #cbd2da;
      font-size: 12px;
      padding: 0px;
      background-color: #fff;
      box-shadow: none; }
      .dialog.dialogAddSchool.dialogAddStudent .exist_students .row.filters .search2 input, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .row.filters .search2 input {
        width: max-content;
        /*height: 30px;*/
        border-radius: 17px;
        border: transparent;
        font-size: 12px;
        padding-left: 12px;
        box-shadow: none; }
        .dialog.dialogAddSchool.dialogAddStudent .exist_students .row.filters .search2 input::placeholder, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .row.filters .search2 input::placeholder {
          color: #000;
          opacity: 1; }
        .dialog.dialogAddSchool.dialogAddStudent .exist_students .row.filters .search2 input::-ms-input-placeholder, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .row.filters .search2 input::-ms-input-placeholder {
          color: #000; }
        .dialog.dialogAddSchool.dialogAddStudent .exist_students .row.filters .search2 input::-ms-input-placeholder, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .row.filters .search2 input::-ms-input-placeholder {
          color: #000; }
    .dialog.dialogAddSchool.dialogAddStudent .exist_students .row.filters .select_, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .row.filters .select_ {
      min-width: 150px;
      padding: 0px 10px; }
    .dialog.dialogAddSchool.dialogAddStudent .exist_students .row.filters .rs__menu, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .row.filters .rs__menu {
      box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5);
      border: 0px;
      border-radius: 8px;
      padding: 5px;
      z-index: 1000;
      width: max-content;
      min-width: 100%; }
      .dialog.dialogAddSchool.dialogAddStudent .exist_students .row.filters .rs__menu .rs__menu-list::-webkit-scrollbar-track, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .row.filters .rs__menu .rs__menu-list::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5;
        border-radius: 5px; }
      .dialog.dialogAddSchool.dialogAddStudent .exist_students .row.filters .rs__menu .rs__menu-list::-webkit-scrollbar, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .row.filters .rs__menu .rs__menu-list::-webkit-scrollbar {
        width: 10px;
        /*height: 3px;*/
        background-color: #F5F5F5;
        border-radius: 5px; }
      .dialog.dialogAddSchool.dialogAddStudent .exist_students .row.filters .rs__menu .rs__menu-list::-webkit-scrollbar-thumb, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .row.filters .rs__menu .rs__menu-list::-webkit-scrollbar-thumb {
        background-color: #3eb6ad;
        border-radius: 5px; }
      .dialog.dialogAddSchool.dialogAddStudent .exist_students .row.filters .rs__menu .rs__menu-list .rs__option, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .row.filters .rs__menu .rs__menu-list .rs__option {
        border-bottom: 1px dashed #ccc;
        cursor: pointer; }
    .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content {
      float: left;
      width: 100%;
      margin-top: 20px;
      overflow-y: auto; }
      .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .content-scroll, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .content-scroll {
        height: 280px; }
        .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .content-scroll::-webkit-scrollbar-track, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .content-scroll::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5; }
        .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .content-scroll::-webkit-scrollbar, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .content-scroll::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5; }
        .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .content-scroll::-webkit-scrollbar-thumb, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .content-scroll::-webkit-scrollbar-thumb {
          background-color: #3eb6ad; }
      .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc {
        height: 100%;
        margin-bottom: 15px;
        float: left;
        width: 100%;
        margin: 10px 0 0; }
        .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors {
          height: 260px;
          overflow-y: auto;
          padding-right: 10px;
          padding-top: 10px;
          padding-left: 5px; }
          .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students::-webkit-scrollbar-track, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors::-webkit-scrollbar-track, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students::-webkit-scrollbar-track, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5; }
          .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students::-webkit-scrollbar, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors::-webkit-scrollbar, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students::-webkit-scrollbar, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5; }
          .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students::-webkit-scrollbar-thumb, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors::-webkit-scrollbar-thumb, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students::-webkit-scrollbar-thumb, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors::-webkit-scrollbar-thumb {
            background-color: #3eb6ad; }
          .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .optiscroll-content, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .optiscroll-content, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .optiscroll-content, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .optiscroll-content {
            height: 100%;
            padding-top: 15px;
            overflow-x: hidden; }
          .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-student, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-professor, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-student, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-professor, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-student, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-professor, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-student, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-professor {
            width: 12.5%;
            float: left;
            padding: 0 10px;
            text-align: center;
            margin-bottom: 25px;
            position: relative; }
            @media (max-width: 1199px) {
              .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-student, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-professor, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-student, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-professor, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-student, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-professor, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-student, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-professor {
                width: 33.33%; } }
            .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-student .friend-img, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-professor .friend-img, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-student .friend-img, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-professor .friend-img, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-student .friend-img, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-professor .friend-img, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-student .friend-img, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-professor .friend-img {
              margin-bottom: 10px !important; }
            .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-student img, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-professor img, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-student img, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-professor img, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-student img, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-professor img, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-student img, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-professor img {
              width: 72px;
              height: 72px;
              box-shadow: 0 7px 24px -4px #a9adb1;
              border-radius: 72px;
              margin: auto;
              margin-bottom: 10px; }
            .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-student h5, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-professor h5, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-student h5, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-professor h5, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-student h5, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-professor h5, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-student h5, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-professor h5 {
              font-size: 11.5px;
              font-weight: 400;
              font-style: normal;
              font-stretch: normal;
              line-height: 1.27;
              letter-spacing: normal;
              text-align: center;
              color: #1b2437;
              margin-top: 10px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis; }
            .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-student.selected img, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-professor.selected img, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-student.selected img, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-professor.selected img, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-student.selected img, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-professor.selected img, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-student.selected img, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-professor.selected img {
              visibility: hidden; }
            .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-student.selected:before, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-professor.selected:before, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-student.selected:before, .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-professor.selected:before, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-student.selected:before, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-students .friend-bloc.exist-professor.selected:before, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-student.selected:before, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .friend-content-bloc .friend-content-bloc-scroll.exist-professors .friend-bloc.exist-professor.selected:before {
              content: "";
              width: 80px;
              height: 80px;
              border-radius: 76px;
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: -2px;
              background: url(../img/item_selected.svg) no-repeat scroll transparent;
              background-position: 0 0;
              background-size: contain;
              box-shadow: 0 7px 24px -4px #a9adb1; }
      .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .bloc-next, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .bloc-next {
        margin-top: 16px;
        text-align: center;
        width: 100%;
        float: left; }
        .dialog.dialogAddSchool.dialogAddStudent .exist_students .friend-content .bloc-next a, .dialog.dialogAddSchool.dialogAddStudent .exist_professors .friend-content .bloc-next a {
          box-shadow: 0 1px 20px -1px rgba(90, 202, 195, 0.5);
          background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
          background-image: -webkit-linear-gradient(36deg, #72dcd6, #3eb6ad);
          background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
          color: #fff;
          padding: 5px 40px;
          display: inline-block;
          border-radius: 20px;
          margin-bottom: 15px;
          font-weight: 600; }
    .dialog.dialogAddSchool.dialogAddStudent .add-school .select {
      float: left;
      width: 100%;
      height: 26.5px;
      border-radius: 2.6px;
      border: .6px solid #d1dbe2;
      background-color: rgba(245, 248, 249, 0.3);
      position: relative; }
      .dialog.dialogAddSchool.dialogAddStudent .add-school .select select {
        float: left;
        width: 100%;
        font-size: 12px;
        height: 25.5px;
        border: none;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        text-indent: 1px;
        text-overflow: "";
        background: transparent;
        outline: 0;
        position: relative;
        z-index: 9;
        border-radius: 4px !important;
        border: none !important;
        font-weight: 500;
        font-family: Montserrat;
        color: #1b2437;
        padding: 0 5px; }
      .dialog.dialogAddSchool.dialogAddStudent .add-school .select i {
        color: #d2dbe2;
        font-size: 16px;
        position: absolute;
        right: 5px;
        top: 5px; }
    .dialog.dialogAddSchool.dialogAddStudent .add-school .intl-tel-input {
      width: 100%; }
    .dialog.dialogAddSchool.dialogAddBehavior .d_f {
      display: flex; }
      .dialog.dialogAddSchool.dialogAddBehavior .d_f .react-datepicker-wrapper {
        margin-left: 0; }
    .dialog.dialogAddSchool.dialogAddBehavior .input_file {
      cursor: pointer;
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
    .dialog.dialogAddSchool.dialogAddBehavior .author_report {
      display: flex;
      justify-content: space-between; }
      .dialog.dialogAddSchool.dialogAddBehavior .author_report .edit_author {
        cursor: pointer;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 0px !important;
        padding: 0px;
        box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: -webkit-linear-gradient(225deg, #72dcd6, #3eb6ad);
        background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
        color: #fff;
        text-align: center; }
    .dialog.dialogAddSchool.dialogAddBehavior .input_choice {
      height: 30px;
      line-height: 13px; }
    .dialog.dialogAddSchool.dialogAddBehavior .document {
      display: flex; }
      .dialog.dialogAddSchool.dialogAddBehavior .document .doc {
        width: calc(100% - 60px); }
        .dialog.dialogAddSchool.dialogAddBehavior .document .doc .name {
          text-align: left;
          display: block; }
      .dialog.dialogAddSchool.dialogAddBehavior .document .actions {
        display: flex;
        justify-content: center;
        width: 60px; }
        .dialog.dialogAddSchool.dialogAddBehavior .document .actions button {
          margin: 0 5px;
          padding: 0 5px;
          width: 20px !important;
          height: 20px !important;
          font-size: 12px;
          line-height: 20px; }
          .dialog.dialogAddSchool.dialogAddBehavior .document .actions button i {
            font-size: 13px; }
        .dialog.dialogAddSchool.dialogAddBehavior .document .actions .remove_document {
          color: red;
          margin: 0 5px;
          cursor: pointer;
          border-radius: 50%;
          border: 1px solid red;
          display: block;
          width: 20px;
          height: 20px;
          line-height: 19px; }
          .dialog.dialogAddSchool.dialogAddBehavior .document .actions .remove_document i {
            margin-left: 4px; }
    .dialog.dialogAddSchool.dialogAddCantineMenue {
      min-width: 400px;
      width: 400px; }
      .dialog.dialogAddSchool.dialogAddCantineMenue .add-school-top {
        text-align: center; }
      .dialog.dialogAddSchool.dialogAddCantineMenue .school-ligne {
        margin-bottom: 15px !important; }
        .dialog.dialogAddSchool.dialogAddCantineMenue .school-ligne .image {
          width: 200px;
          margin: auto; }
          .dialog.dialogAddSchool.dialogAddCantineMenue .school-ligne .image img {
            width: 100%; }
        .dialog.dialogAddSchool.dialogAddCantineMenue .school-ligne .buttons {
          margin-top: 15px;
          display: flex;
          justify-content: space-evenly; }
        .dialog.dialogAddSchool.dialogAddCantineMenue .school-ligne .select.datepicker {
          padding: 0;
          border: unset; }
          .dialog.dialogAddSchool.dialogAddCantineMenue .school-ligne .select.datepicker .react-datepicker-wrapper {
            margin: 0; }
    .dialog.dialogAddSchool h2 {
      margin: 0 0 25px;
      font-family: "Montserrat", sans-serif;
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1b2437; }
    .dialog.dialogAddSchool .schoolClose2 {
      position: absolute;
      top: 11px;
      right: 11px;
      width: 24px !important;
      height: 24px !important;
      background-image: linear-gradient(to right, #67b0aa, #39817c);
      color: #FFF;
      box-shadow: none; }
    .dialog.dialogAddSchool .schoolClose {
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
      text-align: center;
      color: #fff;
      font-size: 40px;
      line-height: 40px; }
      .dialog.dialogAddSchool .schoolClose i {
        color: #fff;
        font-size: 21px;
        line-height: 40px; }
    .dialog.dialogAddSchool .add-school-form {
      float: left;
      width: 100%; }
      @media (max-width: 1199px) {
        .dialog.dialogAddSchool .add-school-form {
          margin-top: 13%; } }
      .dialog.dialogAddSchool .add-school-form .textErrors {
        color: #f13434;
        font-size: 10px;
        font-family: "Montserrat", sans-serif; }
      .dialog.dialogAddSchool .add-school-form .school-ligne {
        width: 100%;
        float: left;
        margin-bottom: 15px; }
        .dialog.dialogAddSchool .add-school-form .school-ligne.row {
          width: auto;
          float: none; }
        .dialog.dialogAddSchool .add-school-form .school-ligne.checkbox_style .state label:before {
          top: 0; }
        .dialog.dialogAddSchool .add-school-form .school-ligne.checkbox_style .state label:after {
          top: 0; }
        .dialog.dialogAddSchool .add-school-form .school-ligne .schoolBrowse {
          position: relative;
          width: 100px;
          height: 22px;
          object-fit: contain;
          border-radius: 11px;
          border: solid 1px #d1dbe2;
          background-color: rgba(245, 248, 249, 0.3);
          text-align: center; }
          .dialog.dialogAddSchool .add-school-form .school-ligne .schoolBrowse span {
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 22px;
            letter-spacing: normal;
            color: #3eb6ad; }
          .dialog.dialogAddSchool .add-school-form .school-ligne .schoolBrowse input {
            position: absolute;
            visibility: hidden; }
        .dialog.dialogAddSchool .add-school-form .school-ligne .schoolLibrary {
          margin: 7px -3px; }
          .dialog.dialogAddSchool .add-school-form .school-ligne .schoolLibrary img {
            width: 35px;
            height: 35px;
            border-radius: 4px;
            margin: 0 3px; }
        .dialog.dialogAddSchool .add-school-form .school-ligne .w12 {
          margin-top: 4px; }
        .dialog.dialogAddSchool .add-school-form .school-ligne .w50 {
          width: 50% !important;
          padding: 0 12px !important; }
        .dialog.dialogAddSchool .add-school-form .school-ligne .colors {
          margin: 0 -6px;
          float: left; }
          .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color {
            width: 30px;
            height: 30px;
            border-radius: 4px;
            float: left;
            margin: 0 6px 15px;
            border: 1px solid transparent;
            padding: 2px;
            position: relative;
            cursor: pointer; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.selected {
              border: 2px solid #92bc92 !important;
              opacity: 1; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color:before {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              height: 2px;
              background: #fff; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.active {
              border: 1px solid #9f9f9f; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color1 {
              background: #d33f29; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color2 {
              background: #fa5656; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color3 {
              background: #ff774f; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color4 {
              background: #ffa726; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color5 {
              background: #e4ba5b; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color6 {
              background: #f7cc68; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color7 {
              background: #f9c499; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color8 {
              background: #fbc3c4; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color9 {
              background: #e6effa; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color10 {
              background: #b1bfcd; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color11 {
              background: #3e4ca0; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color12 {
              background: #4d58a8; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color13 {
              background: #5765f5; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color14 {
              background: #56a0fa; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color15 {
              background: #53c5be; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color16 {
              background: #5eaad7; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color17 {
              background: #a1c1e6; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color18 {
              background: #d9dcfd; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color19 {
              background: #fff;
              border: solid 1px #d9dce2; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .colors .color.color20 {
              background: #000; }
        .dialog.dialogAddSchool .add-school-form .school-ligne .school-ligne-w50 {
          margin-bottom: 0 !important; }
        .dialog.dialogAddSchool .add-school-form .school-ligne.smargin {
          margin: 0 -12px 25px !important;
          width: calc(100% + 24px) !important; }
        .dialog.dialogAddSchool .add-school-form .school-ligne.scenter {
          text-align: center; }
        .dialog.dialogAddSchool .add-school-form .school-ligne button.add {
          display: inline-block;
          min-width: 106px;
          height: 40px;
          object-fit: contain;
          border-radius: 49.5px;
          text-align: center;
          font-family: Montserrat;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
          background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
          color: #fff;
          border: none;
          margin: 0 7px;
          cursor: pointer; }
          .dialog.dialogAddSchool .add-school-form .school-ligne button.add.cancel {
            background: #fff;
            color: #3eb6ad;
            box-shadow: none;
            border: solid 0.6px #d1dbe2; }
          .dialog.dialogAddSchool .add-school-form .school-ligne button.add.new {
            width: 100%;
            margin-bottom: 20px; }
        .dialog.dialogAddSchool .add-school-form .school-ligne button.save {
          min-width: 130px; }
        .dialog.dialogAddSchool .add-school-form .school-ligne label {
          display: block;
          font-family: Montserrat;
          font-size: 15px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          margin-bottom: 5px; }
        .dialog.dialogAddSchool .add-school-form .school-ligne div.group-add-input {
          display: flex; }
          .dialog.dialogAddSchool .add-school-form .school-ligne div.group-add-input button {
            height: 28px;
            line-height: 14px; }
        .dialog.dialogAddSchool .add-school-form .school-ligne input, .dialog.dialogAddSchool .add-school-form .school-ligne textarea {
          float: left;
          width: 100%;
          height: 28px;
          border-radius: 2.6px;
          border: solid 0.6px #d1dbe2;
          background-color: rgba(245, 248, 249, 0.3);
          font-size: 14px;
          font-weight: 400;
          color: #1b2437 !important; }
          .dialog.dialogAddSchool .add-school-form .school-ligne input:not([type='tel']), .dialog.dialogAddSchool .add-school-form .school-ligne textarea:not([type='tel']) {
            padding-left: 10px; }
          .dialog.dialogAddSchool .add-school-form .school-ligne input::placeholder, .dialog.dialogAddSchool .add-school-form .school-ligne textarea::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            opacity: 1;
            /* Firefox */
            color: #1b2437;
            font-weight: 400; }
          .dialog.dialogAddSchool .add-school-form .school-ligne input:-ms-input-placeholder, .dialog.dialogAddSchool .add-school-form .school-ligne textarea:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #1b2437;
            font-weight: 400; }
          .dialog.dialogAddSchool .add-school-form .school-ligne input::-ms-input-placeholder, .dialog.dialogAddSchool .add-school-form .school-ligne textarea::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #1b2437;
            font-weight: 400; }
        .dialog.dialogAddSchool .add-school-form .school-ligne textarea {
          height: 70px;
          resize: none; }
        .dialog.dialogAddSchool .add-school-form .school-ligne .select {
          float: left;
          width: 100%;
          height: 30px;
          border-radius: 2.6px;
          border: solid 0.6px #d1dbe2;
          background-color: rgba(245, 248, 249, 0.3);
          position: relative;
          padding-left: 3px; }
          .dialog.dialogAddSchool .add-school-form .school-ligne .select select {
            float: left;
            width: 100%;
            font-size: 12px;
            height: 28px;
            border: none;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            text-indent: 1px;
            text-overflow: "";
            background: transparent;
            outline: 0;
            position: relative;
            z-index: 9;
            border-radius: 4px !important;
            border: none !important;
            font-weight: 500;
            font-family: Montserrat;
            color: #1b2437;
            padding: 0 5px; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .select select::-ms-expand {
              display: none; }
          .dialog.dialogAddSchool .add-school-form .school-ligne .select i {
            color: #d2dbe2;
            font-size: 14px;
            position: absolute;
            right: 5px;
            top: 6px; }
          .dialog.dialogAddSchool .add-school-form .school-ligne .select .multiclasses_select {
            height: 100%;
            width: 100%;
            border: 0; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .select .multiclasses_select > button {
              padding-left: 6px;
              border: 0; }
              .dialog.dialogAddSchool .add-school-form .school-ligne .select .multiclasses_select > button > span {
                line-height: 1; }
                .dialog.dialogAddSchool .add-school-form .school-ligne .select .multiclasses_select > button > span:after {
                  margin-top: -1px !important; }
              .dialog.dialogAddSchool .add-school-form .school-ligne .select .multiclasses_select > button:focus {
                box-shadow: none !important;
                outline: none !important;
                background-color: transparent !important; }
        .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin {
          margin: 0 -12px 15px;
          width: calc(100% + 24px); }
          .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 {
            float: left;
            width: 50%;
            padding: 0 12px; }
            .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 .flagSelect {
              float: left;
              width: 100%;
              height: 26.5px;
              border-radius: 2.6px;
              border: solid 0.6px #d1dbe2;
              background-color: rgba(245, 248, 249, 0.3); }
              .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 .flagSelect .select {
                float: left;
                width: 70px;
                height: 25.5px;
                position: relative;
                padding-left: 3px;
                border: none; }
                .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 .flagSelect .select:before {
                  content: "";
                  position: absolute;
                  right: 0;
                  top: 4px;
                  bottom: 4px;
                  width: 1px;
                  background: #b1bfcd; }
                .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 .flagSelect .select select {
                  float: left;
                  width: 50px;
                  font-size: 12px;
                  height: 25.5px;
                  border: none;
                  outline: none;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  text-indent: 1px;
                  text-overflow: "";
                  background: transparent;
                  outline: 0;
                  position: relative;
                  z-index: 9;
                  border-radius: 4px !important;
                  border: none !important;
                  font-weight: 500;
                  font-family: Montserrat;
                  color: #1b2437;
                  padding: 0 5px; }
                  .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 .flagSelect .select select::-ms-expand {
                    display: none; }
                .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 .flagSelect .select i {
                  color: #d2dbe2;
                  font-size: 16px;
                  position: absolute;
                  right: 5px;
                  top: 5px; }
              .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 .flagSelect input {
                border: none;
                width: calc(100% - 70px);
                padding-left: 6px; }
          .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w100 {
            float: left;
            width: 100%;
            padding: 0 12px; }
            .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w100 .flagSelect {
              float: left;
              width: 100%;
              height: 26.5px;
              border-radius: 2.6px;
              border: solid 0.6px #d1dbe2;
              background-color: rgba(245, 248, 249, 0.3); }
              .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w100 .flagSelect .select {
                float: left;
                width: 70px;
                height: 25.5px;
                position: relative;
                border: none; }
                .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w100 .flagSelect .select:before {
                  content: "";
                  position: absolute;
                  right: 0;
                  top: 4px;
                  bottom: 4px;
                  width: 1px;
                  background: #b1bfcd; }
                .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w100 .flagSelect .select select {
                  float: left;
                  width: 50px;
                  font-size: 12px;
                  height: 25.5px;
                  border: none;
                  outline: none;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  text-indent: 1px;
                  text-overflow: "";
                  background: transparent;
                  outline: 0;
                  position: relative;
                  z-index: 9;
                  border-radius: 4px !important;
                  border: none !important;
                  font-weight: 500;
                  font-family: Montserrat;
                  font-size: 14px;
                  color: #1b2437;
                  padding: 0 10px; }
                  .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w100 .flagSelect .select select::-ms-expand {
                    display: none; }
                .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w100 .flagSelect .select i {
                  color: #d2dbe2;
                  font-size: 16px;
                  position: absolute;
                  right: 5px;
                  top: 5px; }
              .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w100 .flagSelect input {
                border: none;
                width: calc(100% - 70px);
                padding-left: 6px; }
        .dialog.dialogAddSchool .add-school-form .school-ligne .RadioLegend {
          float: left;
          margin-right: 25px; }
          .dialog.dialogAddSchool .add-school-form .school-ligne .RadioLegend:last-child {
            margin-right: 0; }
          .dialog.dialogAddSchool .add-school-form .school-ligne .RadioLegend > span {
            padding: 0;
            float: left;
            flex: none;
            display: initial;
            position: relative; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .RadioLegend > span:before {
              content: "";
              position: absolute;
              left: 3px;
              right: 3px;
              top: 3px;
              bottom: 3px;
              border-radius: 50%; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .RadioLegend > span[class*="checked"]:before {
              display: none; }
            .dialog.dialogAddSchool .add-school-form .school-ligne .RadioLegend > span[class*="checked"] svg {
              color: #00b3a9; }
          .dialog.dialogAddSchool .add-school-form .school-ligne .RadioLegend > legend {
            float: left;
            margin: 0;
            font-family: Montserrat;
            font-size: 13px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1b2437;
            margin-left: 5px;
            margin-top: 3px; }
        .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-center {
          text-align: center;
          margin-top: 12px; }
          .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-center button {
            /* width: 106px;*/
            width: 130px;
            height: 30px;
            object-fit: contain;
            border-radius: 49.5px;
            border: solid 0.6px #d1dbe2;
            background-color: rgba(245, 248, 249, 0.3);
            text-align: center;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #3eb6ad;
            margin: 0 5px; }
            .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-center button.add {
              box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
              background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
              background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
              color: #fff;
              border: none; }
    .dialog.dialogAddSchool.dialogProviders .d_flex {
      display: flex; }
    .dialog.dialogAddSchool.dialogProviders .jc_c {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1; }
    .dialog.dialogAddSchool.dialogProviders a.closeModal {
      position: absolute;
      cursor: pointer;
      top: 0px;
      right: 0px;
      width: 40px;
      height: 40px;
      box-shadow: rgba(90, 202, 195, 0.5) 0px 10px 20px 0px;
      background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
      text-align: center;
      color: white;
      font-size: 40px;
      line-height: 40px;
      border-radius: 100px; }
    .dialog.dialogAddSchool.dialogProviders > h2 {
      text-align: center; }
    .dialog.dialogAddSchool.dialogSchoolBadges {
      width: 90%;
      height: 90%; }
      .dialog.dialogAddSchool.dialogSchoolBadges > div {
        height: 100%; }
      .dialog.dialogAddSchool.dialogSchoolBadges .badges_content {
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        width: 100%;
        justify-content: center; }
        .dialog.dialogAddSchool.dialogSchoolBadges .badges_content .badges {
          width: 50%;
          margin-bottom: 10px;
          height: 351px; }
          .dialog.dialogAddSchool.dialogSchoolBadges .badges_content .badges .title {
            text-align: center;
            font-weight: bold;
            font-size: 16px; }
          .dialog.dialogAddSchool.dialogSchoolBadges .badges_content .badges .std-badge {
            margin: auto;
            border: 2px solid #8ca0be; }
          .dialog.dialogAddSchool.dialogSchoolBadges .badges_content .badges.abs {
            position: relative; }
            .dialog.dialogAddSchool.dialogSchoolBadges .badges_content .badges.abs .select_badge {
              top: 50%;
              position: absolute;
              left: 50%;
              transform: translate(-50%, -50%); }
        .dialog.dialogAddSchool.dialogSchoolBadges .badges_content .select_badge {
          position: relative;
          padding: 0 20px;
          margin-top: 10px;
          text-align: center; }
          .dialog.dialogAddSchool.dialogSchoolBadges .badges_content .select_badge input {
            float: left;
            width: 100%;
            height: 28px;
            border-radius: 2.6px;
            border: .6px solid #d1dbe2;
            background-color: rgba(245, 248, 249, 0.3);
            font-size: 14px;
            font-weight: 400;
            color: #1b2437 !important;
            padding-left: 10px;
            cursor: pointer;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0; }
          .dialog.dialogAddSchool.dialogSchoolBadges .badges_content .select_badge .loading-spinner {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 100%; }
            .dialog.dialogAddSchool.dialogSchoolBadges .badges_content .select_badge .loading-spinner .lds-spinner {
              width: 30px;
              height: 30px; }
              .dialog.dialogAddSchool.dialogSchoolBadges .badges_content .select_badge .loading-spinner .lds-spinner > div:after {
                top: 16px;
                left: 29px;
                width: 2px;
                height: 10px; }
        .dialog.dialogAddSchool.dialogSchoolBadges .badges_content::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5; }
        .dialog.dialogAddSchool.dialogSchoolBadges .badges_content::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5; }
        .dialog.dialogAddSchool.dialogSchoolBadges .badges_content::-webkit-scrollbar-thumb {
          background-color: #3eb6ad; }
  .dialog.dialogTerms {
    padding: 20px; }
    .dialog.dialogTerms h3 {
      text-align: center; }
  .dialog.dialogSuccess {
    width: 368px;
    height: 220px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: solid 1px #979797;
    border-left: 10px solid #00b3a9;
    background-color: #ffffff;
    position: relative;
    padding: 30px; }
    .dialog.dialogSuccess .closeModal {
      position: absolute;
      top: -15px;
      right: -17px;
      border: 5px solid #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 17px;
      line-height: 28px;
      background: #00b3a9;
      box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3); }
      .dialog.dialogSuccess .closeModal img {
        max-width: 15px; }
    .dialog.dialogSuccess h5 {
      opacity: 0.9;
      -webkit-text-stroke: 0 #f27046;
      font-family: Montserrat;
      font-size: 16.3px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.32;
      letter-spacing: normal;
      color: #3db1a9;
      max-width: 250px; }
    .dialog.dialogSuccess .code_input .textErrors {
      color: red; }
    .dialog.dialogSuccess .code_input .form-control {
      border: 0;
      padding-left: 0; }
      .dialog.dialogSuccess .code_input .form-control.submit {
        text-align: center;
        margin-top: 7px; }
      .dialog.dialogSuccess .code_input .form-control .activate_phone {
        min-width: 106px;
        height: 30px;
        object-fit: contain;
        border-radius: 49.5px;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: normal;
        box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
        background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
        color: #fff;
        border: none; }
  .dialog.dialogExistStudents {
    max-width: 1020px;
    width: 1020px; }
    .dialog.dialogExistStudents .friend-content-bloc .exist-students .div_optiscroll {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start; }
  .dialog .MuiInput-underline-72:before {
    border-bottom: 1px solid #5cbfce; }
  .dialog .MuiInput-underline-72:after {
    border-bottom: 1px solid #5cbfce; }
  .dialog .textInput {
    width: 100%; }
    .dialog .textInput label {
      opacity: 0.9;
      font-family: "Montserrat", sans-serif;
      font-size: 11px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #d3dbde; }
  .dialog h3 {
    opacity: 0.9;
    -webkit-text-stroke: 0 #f27046;
    font-family: "Montserrat", sans-serif;
    font-size: 16.5px;
    letter-spacing: 0.3px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.6;
    color: #3db1a9;
    margin: 0; }
  .dialog .dialog-left,
  .dialog .dialog-right {
    width: 50%;
    float: left; }
  .dialog .RadioLegend > span {
    padding-left: 0;
    padding-right: 5px;
    padding-bottom: 0;
    float: left; }
  .dialog .RadioLegend legend {
    width: auto;
    float: left;
    display: inline-block;
    border: none;
    margin: 18px 25px 0 0;
    font-size: 12px; }
  .dialog .RadioLegend_gr legend {
    margin: 18px 5px 0 0; }
  .dialog .dialog-left {
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-right: 50px; }
    .dialog .dialog-left .MuiFormControl-marginNormal-47 {
      margin: 0; }
    .dialog .dialog-left button {
      background: url("../../assets/img/cta.svg") no-repeat scroll transparent;
      font-family: "Montserrat", sans-serif;
      font-size: 10px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0.7px;
      color: rgba(255, 255, 255, 0.9);
      width: 130px;
      padding: 8px 0 57px;
      background-size: cover;
      margin: 20px 0 0;
      border: none; }
    .dialog .dialog-left .password-forgot {
      margin-top: 20px; }
      .dialog .dialog-left .password-forgot a {
        opacity: 0.9;
        font-family: "Montserrat", sans-serif;
        font-size: 10.5px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #9ca9ad;
        text-transform: uppercase; }
    .dialog .dialog-left .dialog-form .textErrors {
      color: #f13434;
      font-size: 10px;
      font-family: "Montserrat", sans-serif;
      width: 100%;
      float: left; }
      .dialog .dialog-left .dialog-form .textErrors.captcha {
        padding-left: 52px;
        margin: 4px 0 0; }
    .dialog .dialog-left .dialog-form .captcha {
      margin-top: 30px; }
      .dialog .dialog-left .dialog-form .captcha p {
        display: inline-block;
        font-family: Montserrat;
        font-size: 11px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 0.8;
        letter-spacing: normal;
        color: #3e3e3e;
        margin-right: 6px; }
      .dialog .dialog-left .dialog-form .captcha .captchaInput {
        width: 106px;
        height: 30px;
        object-fit: contain;
        opacity: 0.9;
        border-radius: 49.5px;
        border: solid 1px #979797;
        background-color: #ffffff;
        text-align: center;
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #3eb6ad; }
    .dialog .dialog-left .dialog-form .textInput {
      margin-top: 5px;
      min-height: 43px; }
      .dialog .dialog-left .dialog-form .textInput input {
        line-height: 17px;
        font-size: 15px; }
      .dialog .dialog-left .dialog-form .textInput label {
        color: #949494; }
      .dialog .dialog-left .dialog-form .textInput.textInputError > div:before {
        border-bottom: 1px solid #f13434; }
      .dialog .dialog-left .dialog-form .textInput.textInputError > div:after {
        border-bottom: 1px solid #f13434; }
      .dialog .dialog-left .dialog-form .textInput.textInputError > div:hover:before, .dialog .dialog-left .dialog-form .textInput.textInputError > div:focus:before {
        border-bottom: 1px solid #f13434; }
      .dialog .dialog-left .dialog-form .textInput.textInputError > div:hover:after, .dialog .dialog-left .dialog-form .textInput.textInputError > div:focus:after {
        border-bottom: 1px solid #f13434; }
      .dialog .dialog-left .dialog-form .textInput > div:before {
        border-bottom: 1px solid rgba(0, 179, 169, 0.3); }
      .dialog .dialog-left .dialog-form .textInput > div:after {
        border-bottom: 1px solid rgba(0, 179, 169, 0.3); }
      .dialog .dialog-left .dialog-form .textInput > div:hover:before, .dialog .dialog-left .dialog-form .textInput > div:focus:before {
        border-bottom: 1px solid rgba(0, 179, 169, 0.3); }
      .dialog .dialog-left .dialog-form .textInput > div:hover:after, .dialog .dialog-left .dialog-form .textInput > div:focus:after {
        border-bottom: 1px solid rgba(0, 179, 169, 0.3); }
  .dialog .dialog-right {
    margin-top: -76px;
    margin-bottom: -70px;
    margin-right: -36px; }
    .dialog .dialog-right span {
      position: relative;
      display: block;
      margin-right: -60px; }
      .dialog .dialog-right span:before {
        content: "";
        background: url("../../assets/img/rocket.png") no-repeat scroll;
        width: 70px;
        height: 90px;
        position: absolute;
        top: -49px;
        left: 48px; }
  .dialog.dialogSignIn {
    min-width: 700px; }
    .dialog.dialogSignIn.dialogChoixCnx {
      max-width: 480px;
      min-width: auto;
      width: 100%;
      height: 250px;
      border-radius: 10px;
      border: solid 1px #979797;
      background-color: rgba(255, 255, 255, 0.85);
      padding: 40px;
      border-left: 10px solid #3db1a9; }
      .dialog.dialogSignIn.dialogChoixCnx a {
        width: 250px;
        height: 34px;
        object-fit: contain;
        border-radius: 49.5px;
        border: solid 1px #979797;
        background-color: #ffffff;
        font-family: Montserrat;
        font-size: 13px;
        font-weight: 600;
        color: #3eb6ad;
        display: block;
        text-align: center;
        line-height: 32px;
        margin-bottom: 20px; }
        .dialog.dialogSignIn.dialogChoixCnx a.active {
          background-color: #3db1a9;
          border: 1px solid #3db1a9;
          color: #ffffff; }
    .dialog.dialogSignIn.dialogOubliePassword {
      min-width: 520px;
      max-width: 520px;
      padding: 45px 30px 25px 40px; }
      .dialog.dialogSignIn.dialogOubliePassword .closeRegister {
        display: none; }
      .dialog.dialogSignIn.dialogOubliePassword .textErrors {
        color: #f13434;
        font-size: 10px;
        font-family: "Montserrat", sans-serif; }
      .dialog.dialogSignIn.dialogOubliePassword h3 {
        opacity: 0.9;
        -webkit-text-stroke: 0 #f27046;
        font-size: 16.3px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.32;
        letter-spacing: normal;
        color: #3db1a9;
        margin-bottom: 17px; }
      .dialog.dialogSignIn.dialogOubliePassword p {
        opacity: 0.9;
        -webkit-text-stroke: 0 #f27046;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.8;
        letter-spacing: normal;
        color: #818181; }
      .dialog.dialogSignIn.dialogOubliePassword .textInput {
        max-width: 300px;
        margin-top: 0px;
        margin-bottom: 8px; }
        .dialog.dialogSignIn.dialogOubliePassword .textInput > div:before {
          border-bottom: 1px solid #55b9b1; }
        .dialog.dialogSignIn.dialogOubliePassword .textInput > div:after {
          border-bottom: 2px solid #55b9b1; }
        .dialog.dialogSignIn.dialogOubliePassword .textInput > div:hover:before {
          border-bottom: 2px solid #55b9b1; }
        .dialog.dialogSignIn.dialogOubliePassword .textInput > div input {
          padding: 11px 0 11px; }
      .dialog.dialogSignIn.dialogOubliePassword button {
        font-size: 11px;
        width: 170px;
        padding: 12px 0 80px;
        background-size: 170px 110px; }
    .dialog.dialogSignIn.dialogSignUp {
      max-width: 900px;
      width: max-content;
      height: 100%;
      padding: 15px 15px 14px 15px; }
      .dialog.dialogSignIn.dialogSignUp .closeRegister {
        display: block;
        position: absolute;
        right: 0;
        top: 0;
        background: white;
        width: 28px;
        height: 28px;
        text-align: center;
        line-height: 20px;
        font-weight: bold;
        color: #ff5a00;
        border: 3px solid #ff5a00;
        border-radius: 14px;
        transform: translate(50%, -50%);
        cursor: pointer; }
      .dialog.dialogSignIn.dialogSignUp h3 {
        text-align: center;
        font-size: 20px; }
      .dialog.dialogSignIn.dialogSignUp .formular {
        width: 100%;
        height: calc(100% - 40px);
        overflow-y: auto;
        text-align: center; }
        .dialog.dialogSignIn.dialogSignUp .formular .rows {
          min-height: calc(100% - 110px); }
          .dialog.dialogSignIn.dialogSignUp .formular .rows .row {
            margin: 0; }
            .dialog.dialogSignIn.dialogSignUp .formular .rows .row .field {
              margin-bottom: 10px; }
              .dialog.dialogSignIn.dialogSignUp .formular .rows .row .field > div label {
                color: black; }
              .dialog.dialogSignIn.dialogSignUp .formular .rows .row .field > div.textErrors {
                color: red; }
          .dialog.dialogSignIn.dialogSignUp .formular .rows .response_message {
            color: red;
            text-align: left; }
        .dialog.dialogSignIn.dialogSignUp .formular::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5; }
        .dialog.dialogSignIn.dialogSignUp .formular::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5; }
        .dialog.dialogSignIn.dialogSignUp .formular::-webkit-scrollbar-thumb {
          background-color: #ff5a00; }
      .dialog.dialogSignIn.dialogSignUp.dialogSignUpDone {
        max-width: 900px;
        width: max-content;
        height: 230px;
        min-height: max-content;
        padding: 15px 15px 14px 15px; }
        .dialog.dialogSignIn.dialogSignUp.dialogSignUpDone .creation_account_message {
          margin-top: 30px;
          text-align: center; }
  .dialog.dialogRegister {
    padding: 25px 0 30px 50px;
    min-width: 700px; }
    .dialog.dialogRegister.dialogAbs {
      min-width: 1200px;
      padding: 16px 18px;
      border-radius: 20px;
      max-height: 533px;
      max-width: 100%;
      width: 100%;
      height: 100%;
      overflow: hidden; }
      .dialog.dialogRegister.dialogAbs.dialogAbsFile > div:not(.filters):not(.btn_actions) {
        height: calc(100% - 110px);
        overflow: auto;
        margin-bottom: 10px; }
        .dialog.dialogRegister.dialogAbs.dialogAbsFile > div:not(.filters):not(.btn_actions) .table {
          overflow: unset; }
        .dialog.dialogRegister.dialogAbs.dialogAbsFile > div:not(.filters):not(.btn_actions)::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5; }
        .dialog.dialogRegister.dialogAbs.dialogAbsFile > div:not(.filters):not(.btn_actions)::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5; }
        .dialog.dialogRegister.dialogAbs.dialogAbsFile > div:not(.filters):not(.btn_actions)::-webkit-scrollbar-thumb {
          background-color: #3eb6ad; }
        .dialog.dialogRegister.dialogAbs.dialogAbsFile > div:not(.filters):not(.btn_actions).logo_green::-webkit-scrollbar-thumb {
          background-color: #8dcfd0; }
      .dialog.dialogRegister.dialogAbs .top {
        border-radius: 17px;
        float: left;
        width: 100%;
        margin-bottom: 40px; }
        .dialog.dialogRegister.dialogAbs .top.top-assurance {
          display: flex;
          justify-content: space-between;
          border: 1px solid #13bbb3;
          padding: 22px;
          border-radius: 17px; }
        .dialog.dialogRegister.dialogAbs .top img {
          width: 28px;
          height: 28px;
          border-radius: 28px;
          float: left;
          margin-right: 10px; }
        .dialog.dialogRegister.dialogAbs .top h4 {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          color: rgba(27, 36, 55, 0.8);
          line-height: 28px;
          margin: 0; }
      .dialog.dialogRegister.dialogAbs .table {
        float: left;
        width: 100%;
        float: left;
        width: 100%;
        overflow: auto;
        height: 415px;
        margin: 0; }
        .dialog.dialogRegister.dialogAbs .table#absences_table_print {
          top: 0;
          position: absolute;
          z-index: -1; }
        .dialog.dialogRegister.dialogAbs .table#absences_table {
          height: calc(100% - 98px - 0.5rem); }
        .dialog.dialogRegister.dialogAbs .table::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5; }
        .dialog.dialogRegister.dialogAbs .table::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5; }
        .dialog.dialogRegister.dialogAbs .table::-webkit-scrollbar-thumb {
          background-color: #3eb6ad; }
        .dialog.dialogRegister.dialogAbs .table table {
          width: 100%; }
          .dialog.dialogRegister.dialogAbs .table table thead tr th {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 600;
            color: rgba(27, 36, 55, 0.8);
            border: none;
            padding: 0 15px 10px; }
            .dialog.dialogRegister.dialogAbs .table table thead tr th:nth-child(1) {
              width: 110px; }
            .dialog.dialogRegister.dialogAbs .table table thead tr th:nth-child(3) {
              width: 140px; }
            .dialog.dialogRegister.dialogAbs .table table thead tr th:nth-child(4) {
              width: 152px; }
            .dialog.dialogRegister.dialogAbs .table table thead tr th:nth-child(5) {
              width: 205px; }
          .dialog.dialogRegister.dialogAbs .table table tbody tr {
            width: 100%;
            height: 60px;
            object-fit: contain;
            border-radius: 3px;
            box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
            background-color: #ffffff;
            margin-bottom: 10px; }
            .dialog.dialogRegister.dialogAbs .table table tbody tr .button-custom {
              width: 100%;
              height: 60px;
              /* margin-left: 101px; */
              padding-left: 37%;
              object-fit: contain;
              border-radius: 3px;
              margin-bottom: 10px; }
            .dialog.dialogRegister.dialogAbs .table table tbody tr td {
              font-family: Montserrat;
              font-size: 14px;
              font-weight: 500;
              color: rgba(27, 36, 55, 0.8);
              vertical-align: middle;
              border: none;
              padding: 0 15px;
              margin-bottom: 10px; }
              .dialog.dialogRegister.dialogAbs .table table tbody tr td img {
                width: 24px;
                height: 24px;
                border-radius: 24px;
                margin-right: 10px; }
              .dialog.dialogRegister.dialogAbs .table table tbody tr td span {
                width: 90px;
                min-height: 24px;
                border-radius: 4px;
                display: inline-block;
                text-align: center; }
                .dialog.dialogRegister.dialogAbs .table table tbody tr td span.absent {
                  border: solid 1px #d33f29; }
                .dialog.dialogRegister.dialogAbs .table table tbody tr td span.late {
                  border: solid 1px #edbd4f; }
                .dialog.dialogRegister.dialogAbs .table table tbody tr td span span {
                  font-size: 10px;
                  display: block;
                  min-height: unset; }
            .dialog.dialogRegister.dialogAbs .table table tbody tr .school_comment {
              min-width: 200px; }
              .dialog.dialogRegister.dialogAbs .table table tbody tr .school_comment textarea {
                border: 1px solid #e6e5e5;
                width: 100%;
                min-height: 100%;
                border-radius: 5px;
                overflow: hidden; }
              .dialog.dialogRegister.dialogAbs .table table tbody tr .school_comment.for_print {
                width: 250px;
                max-width: 250px; }
                .dialog.dialogRegister.dialogAbs .table table tbody tr .school_comment.for_print div {
                  width: 100%;
                  overflow-wrap: break-word; }
            .dialog.dialogRegister.dialogAbs .table table tbody tr .absence_monitoring {
              position: relative;
              z-index: 11; }
              .dialog.dialogRegister.dialogAbs .table table tbody tr .absence_monitoring select {
                border: none;
                outline: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                text-indent: 1px;
                text-overflow: "";
                background: transparent;
                outline: 0;
                position: relative;
                z-index: 9;
                border-radius: 4px !important;
                border: none !important;
                font-weight: 500;
                font-family: Montserrat;
                color: #1b2437;
                padding: 0 5px;
                padding-right: 15px;
                cursor: pointer;
                width: 100%; }
                .dialog.dialogRegister.dialogAbs .table table tbody tr .absence_monitoring select.empty {
                  opacity: 0.5; }
              .dialog.dialogRegister.dialogAbs .table table tbody tr .absence_monitoring i {
                color: #d2dbe2;
                font-size: 14px;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                z-index: -1; }
            .dialog.dialogRegister.dialogAbs .table table tbody tr .absence_reason {
              position: relative;
              z-index: 11; }
              .dialog.dialogRegister.dialogAbs .table table tbody tr .absence_reason select {
                border: none;
                outline: none;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                text-indent: 1px;
                text-overflow: "";
                background: transparent;
                outline: 0;
                position: relative;
                z-index: 9;
                border-radius: 4px !important;
                border: none !important;
                font-weight: 500;
                font-family: Montserrat;
                color: #1b2437;
                padding: 0 5px;
                padding-right: 15px;
                cursor: pointer;
                width: 100%; }
                .dialog.dialogRegister.dialogAbs .table table tbody tr .absence_reason select.empty {
                  opacity: 0.5; }
              .dialog.dialogRegister.dialogAbs .table table tbody tr .absence_reason i {
                color: #d2dbe2;
                font-size: 14px;
                position: absolute;
                right: 15px;
                top: 50%;
                transform: translateY(-50%);
                z-index: -1; }
            .dialog.dialogRegister.dialogAbs .table table tbody tr .justified .pretty, .dialog.dialogRegister.dialogAbs .table table tbody tr .regul .pretty {
              margin: auto !important; }
            .dialog.dialogRegister.dialogAbs .table table tbody tr.space {
              height: 10px;
              box-shadow: none; }
          .dialog.dialogRegister.dialogAbs .table table.absences_table td, .dialog.dialogRegister.dialogAbs .table table.absences_table th {
            padding-left: 2px;
            padding-right: 2px;
            text-align: center; }
      .dialog.dialogRegister.dialogAbs .top_body_table_print {
        position: absolute;
        width: 98%;
        left: 1%;
        top: 2%;
        height: calc(96% - 68px);
        padding: 10px;
        background: white;
        z-index: -1; }
      .dialog.dialogRegister.dialogAbs .print_button {
        margin-left: calc(50% - 50px); }
    .dialog.dialogRegister.dialogAbsFile {
      max-height: 100%; }
      .dialog.dialogRegister.dialogAbsFile .top {
        margin-bottom: 0; }
        .dialog.dialogRegister.dialogAbsFile .top table {
          width: 100%; }
          .dialog.dialogRegister.dialogAbsFile .top table tr td {
            text-align: center; }
            .dialog.dialogRegister.dialogAbsFile .top table tr td span {
              width: 100%;
              display: block; }
            .dialog.dialogRegister.dialogAbsFile .top table tr td .fs {
              font-size: 25px; }
            .dialog.dialogRegister.dialogAbsFile .top table tr td.image {
              width: 15%;
              text-align: center; }
              .dialog.dialogRegister.dialogAbsFile .top table tr td.image img {
                width: 200px;
                height: 200px;
                border-radius: 28px;
                float: none;
                margin-right: 0;
                margin: auto; }
            .dialog.dialogRegister.dialogAbsFile .top table tr td.ttle {
              width: 70%;
              border: 1px solid black; }
            .dialog.dialogRegister.dialogAbsFile .top table tr td.ttle, .dialog.dialogRegister.dialogAbsFile .top table tr td.ttle2 {
              font-weight: bold; }
      .dialog.dialogRegister.dialogAbsFile .filters {
        margin-bottom: 15px; }
        .dialog.dialogRegister.dialogAbsFile .filters > div {
          width: 25%;
          min-width: 250px;
          max-width: 300px; }
      .dialog.dialogRegister.dialogAbsFile .sec_title {
        font-weight: bold;
        margin-top: 25px;
        margin-bottom: 10px;
        text-decoration: underline;
        font-size: 20px; }
      .dialog.dialogRegister.dialogAbsFile .table {
        height: calc(100% - 300px - 0.5rem);
        padding: 15px; }
      .dialog.dialogRegister.dialogAbsFile .absences_table {
        width: 100% !important; }
        .dialog.dialogRegister.dialogAbsFile .absences_table tr {
          box-shadow: none !important; }
          .dialog.dialogRegister.dialogAbsFile .absences_table tr th {
            font-weight: bold !important;
            border: 1px solid black !important;
            text-align: center !important;
            background-color: #d9d9d9;
            padding: 10px !important;
            color: black !important;
            width: max-content !important; }
          .dialog.dialogRegister.dialogAbsFile .absences_table tr td {
            width: max-content !important;
            border: 1px solid black !important;
            text-align: center !important;
            padding: 10px !important;
            color: black !important; }
            .dialog.dialogRegister.dialogAbsFile .absences_table tr td.total {
              color: red; }
            .dialog.dialogRegister.dialogAbsFile .absences_table tr td.dets_td {
              width: 300px !important; }
              .dialog.dialogRegister.dialogAbsFile .absences_table tr td.dets_td .semi_day {
                display: inline-block;
                margin: 0 10px; }
                .dialog.dialogRegister.dialogAbsFile .absences_table tr td.dets_td .semi_day > span {
                  display: block; }
                  .dialog.dialogRegister.dialogAbsFile .absences_table tr td.dets_td .semi_day > span.case {
                    margin-top: 5px;
                    width: 20px;
                    height: 20px;
                    border: 1px solid black;
                    margin: auto;
                    font-size: 24px;
                    line-height: 15px;
                    text-align: center;
                    color: transparent; }
                    .dialog.dialogRegister.dialogAbsFile .absences_table tr td.dets_td .semi_day > span.case.coched {
                      color: black; }
          .dialog.dialogRegister.dialogAbsFile .absences_table tr.total td {
            background-color: #f2f2f2;
            font-weight: bold !important; }
    .dialog.dialogRegister.std .table {
      border: 1px solid #13bbb3;
      padding: 22px;
      border-radius: 17px; }
      .dialog.dialogRegister.std .table > h4 {
        margin-top: -10px;
        font-size: 17px; }
    .dialog.dialogRegister span.btn.btn-circle.add_item {
      /* margin-left: 162%; */
      display: inline-table;
      vertical-align: inherit;
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 43px;
      /* margin: 0px; */
      /* display: inline-block; */
      width: 50px;
      height: 50px;
      box-shadow: rgba(239, 194, 91, 0.5) 0px 18px 36px 0px;
      background-image: linear-gradient(225deg, #fdd271, #d5a433);
      font-size: 50px;
      text-align: center;
      line-height: 50px;
      color: white;
      /* margin-bottom: 15px; */
      /* border-radius: 150px; */ }
    .dialog.dialogRegister .dialog-left .dialog-form .textInput {
      margin-bottom: 2px;
      margin-top: 0; }
      .dialog.dialogRegister .dialog-left .dialog-form .textInput label + div {
        margin-top: 10px;
        min-height: 33px; }
      .dialog.dialogRegister .dialog-left .dialog-form .textInput input {
        line-height: 20px;
        font-size: 12px; }
    .dialog.dialogRegister .dialog-left .radioLegend > span {
      padding-left: 0;
      padding-right: 5px;
      padding-bottom: 0;
      float: left; }
    .dialog.dialogRegister .dialog-left .radioLegend legend {
      width: auto;
      float: left;
      display: inline-block;
      border: none;
      margin: 18px 39px 0 0; }
    .dialog.dialogRegister .dialog-right {
      margin-top: -12px; }
  .dialog.dialogCandid {
    padding: 25px 0 30px 50px;
    min-width: 700px; }
    .dialog.dialogCandid.dialogAbs {
      min-width: 1200px;
      padding: 16px 18px;
      border-radius: 20px;
      max-height: 533px; }
      .dialog.dialogCandid.dialogAbs .top {
        border-radius: 17px;
        float: left;
        width: 100%;
        margin-bottom: 40px; }
        .dialog.dialogCandid.dialogAbs .top.top-assurance {
          display: flex;
          justify-content: space-between;
          border: 1px solid #13bbb3;
          padding: 22px;
          border-radius: 17px; }
        .dialog.dialogCandid.dialogAbs .top img {
          width: 28px;
          height: 28px;
          border-radius: 28px;
          float: left;
          margin-right: 10px; }
        .dialog.dialogCandid.dialogAbs .top h4 {
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 500;
          color: rgba(27, 36, 55, 0.8);
          line-height: 28px;
          margin: 0; }
        .dialog.dialogCandid.dialogAbs .top.for_print {
          position: absolute;
          top: 0;
          left: 0;
          padding: 10px;
          z-index: -1; }
      .dialog.dialogCandid.dialogAbs .table {
        float: left;
        width: 100%;
        float: left;
        width: 100%;
        overflow: auto;
        height: 415px;
        margin: 0; }
        .dialog.dialogCandid.dialogAbs .table::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5; }
        .dialog.dialogCandid.dialogAbs .table::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5; }
        .dialog.dialogCandid.dialogAbs .table::-webkit-scrollbar-thumb {
          background-color: #3eb6ad; }
        .dialog.dialogCandid.dialogAbs .table table {
          width: 100%; }
          .dialog.dialogCandid.dialogAbs .table table thead tr th {
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 600;
            color: rgba(27, 36, 55, 0.8);
            border: none;
            padding: 0 15px 10px; }
            .dialog.dialogCandid.dialogAbs .table table thead tr th:nth-child(1) {
              width: 110px; }
            .dialog.dialogCandid.dialogAbs .table table thead tr th:nth-child(3) {
              width: 140px; }
            .dialog.dialogCandid.dialogAbs .table table thead tr th:nth-child(4) {
              width: 152px; }
            .dialog.dialogCandid.dialogAbs .table table thead tr th:nth-child(5) {
              width: 205px; }
          .dialog.dialogCandid.dialogAbs .table table tbody tr {
            width: 100%;
            height: 60px;
            object-fit: contain;
            border-radius: 3px;
            box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
            background-color: #ffffff;
            margin-bottom: 10px; }
            .dialog.dialogCandid.dialogAbs .table table tbody tr .button-custom {
              width: 100%;
              height: 60px;
              /* margin-left: 101px; */
              padding-left: 37%;
              object-fit: contain;
              border-radius: 3px;
              margin-bottom: 10px; }
            .dialog.dialogCandid.dialogAbs .table table tbody tr td {
              font-family: Montserrat;
              font-size: 14px;
              font-weight: 500;
              color: rgba(27, 36, 55, 0.8);
              vertical-align: middle;
              border: none;
              padding: 0 15px;
              margin-bottom: 10px; }
              .dialog.dialogCandid.dialogAbs .table table tbody tr td img {
                width: 24px;
                height: 24px;
                border-radius: 24px;
                margin-right: 10px; }
              .dialog.dialogCandid.dialogAbs .table table tbody tr td span {
                width: 90px;
                height: 24px;
                border-radius: 4px;
                display: inline-block;
                text-align: center; }
                .dialog.dialogCandid.dialogAbs .table table tbody tr td span.absent {
                  border: solid 1px #d33f29; }
                .dialog.dialogCandid.dialogAbs .table table tbody tr td span.late {
                  border: solid 1px #edbd4f; }
            .dialog.dialogCandid.dialogAbs .table table tbody tr.space {
              height: 10px;
              box-shadow: none; }
    .dialog.dialogCandid span.btn.btn-circle.add_item {
      /* margin-left: 162%; */
      display: inline-table;
      vertical-align: inherit;
      width: 40px;
      height: 40px;
      line-height: 40px;
      font-size: 43px;
      /* margin: 0px; */
      /* display: inline-block; */
      width: 50px;
      height: 50px;
      box-shadow: rgba(239, 194, 91, 0.5) 0px 18px 36px 0px;
      background-image: linear-gradient(225deg, #fdd271, #d5a433);
      font-size: 50px;
      text-align: center;
      line-height: 50px;
      color: white;
      /* margin-bottom: 15px; */
      /* border-radius: 150px; */ }
    .dialog.dialogCandid .dialog-left .dialog-form .textInput {
      margin-bottom: 2px;
      margin-top: 0; }
      .dialog.dialogCandid .dialog-left .dialog-form .textInput label + div {
        margin-top: 10px;
        min-height: 33px; }
      .dialog.dialogCandid .dialog-left .dialog-form .textInput input {
        line-height: 20px;
        font-size: 12px; }
    .dialog.dialogCandid .dialog-left .radioLegend > span {
      padding-left: 0;
      padding-right: 5px;
      padding-bottom: 0;
      float: left; }
    .dialog.dialogCandid .dialog-left .radioLegend legend {
      width: auto;
      float: left;
      display: inline-block;
      border: none;
      margin: 18px 39px 0 0; }
    .dialog.dialogCandid .dialog-right {
      margin-top: -12px; }

.dialogOverlay {
  background: rgba(100, 115, 140, 0.9);
  z-index: 99999; }
  .dialogOverlay .dialogPredefinedAppreciationBlock {
    width: 900px; }
  .dialogOverlay.dialogMaxExamsByDay {
    z-index: 1000; }
  .dialogOverlay.dialogCreateChapterFormOverlay .upload {
    display: flex; }
    .dialogOverlay.dialogCreateChapterFormOverlay .upload img {
      width: 100px !important;
      height: 100px !important; }
    .dialogOverlay.dialogCreateChapterFormOverlay .upload span {
      width: max-content !important;
      margin-top: 35px;
      padding: 0 10px;
      display: inline-block; }
  .dialogOverlay.dialogCreateChapterFormOverlay.dialogCreateChapterFormOverlayChaptersGroups .select {
    width: 100%;
    height: 29px;
    border-radius: 2.6px;
    border: .6px solid #d1dbe2;
    background-color: rgba(245, 248, 249, 0.3);
    position: relative;
    padding-left: 3px; }
    .dialogOverlay.dialogCreateChapterFormOverlay.dialogCreateChapterFormOverlayChaptersGroups .select select {
      width: 100%;
      font-size: 12px;
      height: 28px;
      border: none;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      text-indent: 1px;
      text-overflow: "";
      background: transparent;
      outline: 0;
      position: relative;
      z-index: 9;
      border-radius: 4px !important;
      border: none !important;
      font-weight: 500;
      font-family: Montserrat;
      color: #1b2437;
      padding: 0 5px; }
    .dialogOverlay.dialogCreateChapterFormOverlay.dialogCreateChapterFormOverlayChaptersGroups .select i {
      color: #d2dbe2;
      font-size: 14px;
      position: absolute;
      right: 5px;
      top: 6px; }
  @media (max-width: 767px) {
    .dialogOverlay.dialogCandidate {
      padding: 0px; }
      .dialogOverlay.dialogCandidate > div {
        border-radius: 0px;
        min-width: auto !important; } }
  .dialogOverlay.hight_z_index {
    z-index: 11111199999; }
  .dialogOverlay.dialogStudentsDocumentsOverlay {
    z-index: 1040 !important;
    height: 100vh; }
    .dialogOverlay.dialogStudentsDocumentsOverlay .back_to_folders {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: -webkit-linear-gradient(225deg, #72dcd6, #3eb6ad);
      background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
      text-align: center;
      color: #fff;
      font-size: 40px;
      line-height: 40px; }
    .dialogOverlay.dialogStudentsDocumentsOverlay .folder_name {
      position: absolute;
      left: 50px;
      top: 25px;
      font-size: 15px;
      font-weight: bold; }
      @media (max-width: 767px) {
        .dialogOverlay.dialogStudentsDocumentsOverlay .folder_name {
          top: 60px;
          left: 10px; } }
    .dialogOverlay.dialogStudentsDocumentsOverlay .student_info2 {
      position: absolute;
      right: 50%;
      transform: translateX(50%);
      top: 16px;
      font-size: 15px;
      font-weight: bold;
      text-align: right;
      width: max-content;
      display: flex;
      line-height: 70px; }
      @media (max-width: 767px) {
        .dialogOverlay.dialogStudentsDocumentsOverlay .student_info2 {
          top: 60px;
          right: 10px; } }
      .dialogOverlay.dialogStudentsDocumentsOverlay .student_info2 .img-detail {
        margin-right: 10px; }
    .dialogOverlay.dialogStudentsDocumentsOverlay .close_modal {
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 0;
      z-index: 111; }
      .dialogOverlay.dialogStudentsDocumentsOverlay .close_modal span {
        font-size: 30px;
        line-height: 30px;
        font-weight: 500; }
      @media (max-width: 1199px) {
        .dialogOverlay.dialogStudentsDocumentsOverlay .close_modal {
          background: #d8dfe5;
          box-shadow: none;
          width: 35px;
          height: 35px;
          line-height: 35px;
          top: 5px;
          right: 10px; }
          .dialogOverlay.dialogStudentsDocumentsOverlay .close_modal span {
            font-size: 23px; } }
    @media (max-width: 1199px) {
      .dialogOverlay.dialogStudentsDocumentsOverlay {
        height: calc(var(--vh, 1vh) * 100); } }
  .dialogOverlay.dialogUserReclamationsOverlay {
    z-index: 1040 !important;
    height: 100vh; }
    .dialogOverlay.dialogUserReclamationsOverlay .student_info2 {
      position: absolute;
      right: 50%;
      transform: translateX(50%);
      top: 16px;
      font-size: 15px;
      font-weight: bold;
      text-align: right;
      width: max-content;
      display: flex;
      line-height: 70px; }
      @media (max-width: 767px) {
        .dialogOverlay.dialogUserReclamationsOverlay .student_info2 {
          top: 60px;
          right: 10px; } }
      .dialogOverlay.dialogUserReclamationsOverlay .student_info2 .img-detail {
        margin-right: 10px; }
    .dialogOverlay.dialogUserReclamationsOverlay .close_modal {
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 0;
      z-index: 111; }
      .dialogOverlay.dialogUserReclamationsOverlay .close_modal span {
        font-size: 30px;
        line-height: 30px;
        font-weight: 500; }
      @media (max-width: 1199px) {
        .dialogOverlay.dialogUserReclamationsOverlay .close_modal {
          background: #d8dfe5;
          box-shadow: none;
          width: 35px;
          height: 35px;
          line-height: 35px;
          top: 5px;
          right: 10px; }
          .dialogOverlay.dialogUserReclamationsOverlay .close_modal span {
            font-size: 23px; } }
    @media (max-width: 1199px) {
      .dialogOverlay.dialogUserReclamationsOverlay {
        height: calc(var(--vh, 1vh) * 100); } }
  .dialogOverlay.dialogStudentsNotesOverlay {
    z-index: 1040 !important;
    height: 100vh; }
    .dialogOverlay.dialogStudentsNotesOverlay.z111040 {
      z-index: 11040 !important; }
    .dialogOverlay.dialogStudentsNotesOverlay .dialog .close_modal {
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 0;
      z-index: 111; }
      .dialogOverlay.dialogStudentsNotesOverlay .dialog .close_modal span {
        font-size: 30px;
        line-height: 30px;
        font-weight: 500; }
      @media (max-width: 1199px) {
        .dialogOverlay.dialogStudentsNotesOverlay .dialog .close_modal {
          background: #d8dfe5;
          box-shadow: none;
          width: 35px;
          height: 35px;
          line-height: 35px;
          top: 5px;
          right: 10px; }
          .dialogOverlay.dialogStudentsNotesOverlay .dialog .close_modal span {
            font-size: 23px; } }
    .dialogOverlay.dialogStudentsNotesOverlay .dialog.dialogStudentsNotes {
      max-width: 100%;
      width: 100%;
      border-left: none;
      border-radius: 20px;
      padding: 15px;
      min-height: 100%;
      height: 100%; }
      @media (max-width: 1199px) {
        .dialogOverlay.dialogStudentsNotesOverlay .dialog.dialogStudentsNotes {
          padding: 0px; } }
    @media (max-width: 1199px) {
      .dialogOverlay.dialogStudentsNotesOverlay {
        height: calc(var(--vh, 1vh) * 100); } }
  .dialogOverlay .account_activated {
    color: green;
    font-size: 12px;
    padding-bottom: 20px; }
  .dialogOverlay.dialogCompetencies .dialog.dialogAddSchool {
    width: 425px; }
  .dialogOverlay.successDialog {
    z-index: 99999; }

.Group {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  z-index: 9990;
  cursor: pointer; }
  .Group img {
    -webkit-filter: drop-shadow(-5px 12px 20px rgba(0, 0, 0, 0.65));
    -moz-filter: drop-shadow(-5px 12px 20px rgba(0, 0, 0, 0.65));
    -ms-filter: drop-shadow(-5px 12px 20px rgba(0, 0, 0, 0.65));
    -o-filter: drop-shadow(-5px 12px 20px rgba(0, 0, 0, 0.65));
    filter: drop-shadow(-5px 12px 20px rgba(0, 0, 0, 0.65)); }

.dashboardChild {
  padding-top: 25px; }
  .dashboardChild section {
    padding-top: 25px; }
    .dashboardChild section .blocs-textbook {
      background: url("../../assets/img/Circles_TextBook_BG.svg") no-repeat scroll transparent;
      border-radius: 10px;
      background-color: #f9fafb;
      box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.4);
      position: relative;
      min-height: 500px;
      padding: 25px 0 60px 100px; }
      .dashboardChild section .blocs-textbook h3 {
        background: url("../../assets/img/TextBook_Heading_BG.svg") no-repeat scroll transparent;
        writing-mode: vertical-rl;
        text-orientation: sideways;
        position: absolute;
        left: -24px;
        top: 100px;
        width: 152px;
        height: 188px;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        padding-top: 22px;
        line-height: 246px;
        margin: 0;
        -webkit-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.05));
        -moz-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.05));
        -ms-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.05));
        -o-filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.05));
        filter: drop-shadow(0 -1px 4px rgba(0, 0, 0, 0.05)); }
        .dashboardChild section .blocs-textbook h3.zoom {
          background: url("../../assets/img/COURSES_LIVE_Heading_BG.svg") no-repeat scroll transparent; }
        .dashboardChild section .blocs-textbook h3.courses {
          background: url("../../assets/img/COURSES_Heading_BG.svg") no-repeat scroll transparent; }
      .dashboardChild section .blocs-textbook .container-textbook .textbook-period {
        float: right;
        padding-right: 100px; }
        .dashboardChild section .blocs-textbook .container-textbook .textbook-period p {
          color: #3eb6ac;
          font-weight: 500;
          font-size: 12px;
          padding-right: 8px;
          margin-bottom: 6px; }
        .dashboardChild section .blocs-textbook .container-textbook .textbook-period ul.list-period > li {
          position: relative;
          padding-bottom: 15px; }
          .dashboardChild section .blocs-textbook .container-textbook .textbook-period ul.list-period > li > a {
            color: #3eb6ac;
            border: 2px solid #3eb6ac;
            padding: 4px 17px;
            border-radius: 20px;
            font-weight: 600;
            font-size: 12px; }
            .dashboardChild section .blocs-textbook .container-textbook .textbook-period ul.list-period > li > a i {
              font-size: 11px; }
          .dashboardChild section .blocs-textbook .container-textbook .textbook-period ul.list-period > li > ul {
            z-index: 9;
            opacity: 0.9;
            border-radius: 9px;
            border: solid 2px #3eb6ad;
            background-color: #fdfdfd;
            text-align: center;
            padding: 3px 0;
            overflow: hidden;
            cursor: pointer;
            position: absolute;
            left: 50%;
            top: 30px;
            margin-left: -64px;
            display: none; }
            .dashboardChild section .blocs-textbook .container-textbook .textbook-period ul.list-period > li > ul li {
              width: 123px;
              opacity: 0.9;
              font-family: "Montserrat", sans-serif;
              padding: 5px 10px;
              font-size: 11px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 0.8px;
              text-align: center;
              color: #3eb6ad;
              display: block;
              transition: 0.3s; }
              .dashboardChild section .blocs-textbook .container-textbook .textbook-period ul.list-period > li > ul li:hover {
                background: #ffffff;
                box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.4); }
          .dashboardChild section .blocs-textbook .container-textbook .textbook-period ul.list-period > li:hover > ul {
            display: block; }
      .dashboardChild section .blocs-textbook .textbooks {
        padding-right: 90px;
        padding-left: 100px; }
        .dashboardChild section .blocs-textbook .textbooks .bloc-textbook {
          border-radius: 7.2px;
          border: 10px solid #fff;
          justify-content: center;
          align-items: center;
          align-self: center;
          position: relative;
          margin-bottom: 30px;
          float: left;
          width: 100%;
          box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.17); }
          .dashboardChild section .blocs-textbook .textbooks .bloc-textbook span {
            position: absolute;
            right: 0;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 2.8em;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;
            border: 6px solid #ffffff;
            border-radius: 100%;
            top: -18%;
            width: 40px;
            height: 40px;
            padding-top: 0px;
            z-index: 999;
            font-size: 11px;
            left: 90%;
            -webkit-box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.17);
            -moz-box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.17);
            box-shadow: 0 -1px 8px 0 rgba(0, 0, 0, 0.17); }
          .dashboardChild section .blocs-textbook .textbooks .bloc-textbook .content-textbook {
            border-radius: 7px;
            background-color: rgba(237, 189, 79, 0.9);
            float: left;
            width: 100%;
            height: 120px; }

.dialogMobile .dialog .closeRegister {
  display: none; }

.OverlayTextbook {
  background-color: rgba(100, 115, 140, 0.9);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999; }
  .OverlayTextbook .courses_list {
    float: left;
    margin: 20px 0px;
    width: 100%;
    overflow: auto;
    height: calc(100% - 50px); }
    @media (orientation: landscape) and (max-width: 1224px) {
      .OverlayTextbook .courses_list {
        overflow: visible; } }
    .OverlayTextbook .courses_list .course_item, .OverlayTextbook .courses_list .formation_item, .OverlayTextbook .courses_list .book_item {
      margin: 10px 0px;
      max-height: 170px;
      cursor: pointer; }
      .OverlayTextbook .courses_list .course_item > div, .OverlayTextbook .courses_list .formation_item > div, .OverlayTextbook .courses_list .book_item > div {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
        padding: 10px;
        text-align: center;
        height: 100%;
        justify-content: space-between; }
        .OverlayTextbook .courses_list .course_item > div img, .OverlayTextbook .courses_list .formation_item > div img, .OverlayTextbook .courses_list .book_item > div img {
          width: 80px;
          height: 80px;
          border-radius: 50%; }
        .OverlayTextbook .courses_list .course_item > div p.notifred, .OverlayTextbook .courses_list .formation_item > div p.notifred, .OverlayTextbook .courses_list .book_item > div p.notifred {
          position: absolute;
          right: 9px;
          top: -7px;
          background: #d33f29;
          width: 20px;
          height: 20px;
          border-radius: 12px;
          font-size: 10px;
          font-weight: 500;
          line-height: 20px;
          color: black; }
      .OverlayTextbook .courses_list .course_item .not_viewed_course, .OverlayTextbook .courses_list .formation_item .not_viewed_course, .OverlayTextbook .courses_list .book_item .not_viewed_course {
        position: absolute;
        top: 0;
        right: 15px;
        background: red;
        border-radius: 50%;
        width: 16px;
        height: 16px; }
    .OverlayTextbook .courses_list.chapters_list {
      align-content: flex-start; }
      .OverlayTextbook .courses_list.chapters_list .course_item {
        min-height: 170px; }
  .OverlayTextbook.OverlayActivity {
    margin-bottom: 0px; }
    .OverlayTextbook.OverlayActivity > div {
      top: 0px !important;
      left: 0px !important;
      max-width: 100%;
      width: 100%;
      margin: 0px;
      bottom: auto !important;
      padding: 0px !important;
      border: none !important;
      border-radius: 0px !important;
      overflow: inherit !important;
      box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
      opacity: 0.9;
      height: 100%; }
      .OverlayTextbook.OverlayActivity > div .header {
        border-radius: 0px; }
    .OverlayTextbook.OverlayActivity .closeModal {
      position: absolute;
      top: 4px;
      right: 4px;
      border: 5px solid #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 17px;
      line-height: 28px;
      z-index: 2000; }
      .OverlayTextbook.OverlayActivity .closeModal img {
        max-width: 13px; }
  .OverlayTextbook > div {
    top: 70px !important;
    left: 30% !important;
    margin-left: -15%;
    max-width: 70%;
    bottom: auto !important;
    padding: 0 0 30px !important;
    border: none !important;
    border-radius: 10px !important;
    overflow: inherit !important;
    box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
    opacity: 0.9;
    height: 80%; }
    .OverlayTextbook > div > div {
      height: 100%;
      display: flex;
      flex-direction: column; }
      .OverlayTextbook > div > div.modal_textbook {
        z-index: 1;
        position: relative; }
    .OverlayTextbook > div .closeModal {
      position: absolute;
      top: -23px;
      right: -23px;
      border: 5px solid #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 17px;
      line-height: 28px; }
      .OverlayTextbook > div .closeModal img {
        max-width: 13px; }
    .OverlayTextbook > div .header {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 0;
      width: 100%;
      float: left; }
      .OverlayTextbook > div .header .slick-list {
        height: 50px; }
      .OverlayTextbook > div .header .slick-dots {
        display: none !important; }
      .OverlayTextbook > div .header .slick-arrow {
        position: absolute;
        top: 50%;
        font-size: 0;
        background: transparent;
        border: none;
        margin-top: -4px; }
        .OverlayTextbook > div .header .slick-arrow:before {
          font: normal normal normal 23px/1 FontAwesome;
          line-height: normal;
          color: #fff; }
        .OverlayTextbook > div .header .slick-arrow.slick-prev {
          left: -6px; }
          .OverlayTextbook > div .header .slick-arrow.slick-prev:before {
            content: "\f104"; }
        .OverlayTextbook > div .header .slick-arrow.slick-next {
          right: -6px; }
          .OverlayTextbook > div .header .slick-arrow.slick-next:before {
            content: "\f105"; }
      .OverlayTextbook > div .header a {
        opacity: 0.54;
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 1.5px;
        text-align: center;
        color: #ffffff;
        text-transform: uppercase;
        line-height: 50px;
        display: block; }
        .OverlayTextbook > div .header a.active {
          opacity: 1;
          font-weight: 700;
          position: relative;
          outline: none; }
          .OverlayTextbook > div .header a.active:before {
            content: "";
            position: absolute;
            left: 50%;
            margin-left: -10px;
            bottom: 0;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 8px solid #fff; }
      .OverlayTextbook > div .header ul {
        display: flex;
        justify-content: space-evenly;
        margin: 0;
        padding: 0; }
        .OverlayTextbook > div .header ul li {
          display: inline-block; }
          .OverlayTextbook > div .header ul li a {
            opacity: 0.54;
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: 1.5px;
            text-align: center;
            color: #ffffff;
            text-transform: uppercase;
            line-height: 50px;
            display: block; }
            .OverlayTextbook > div .header ul li a.active {
              opacity: 1;
              font-weight: 700;
              position: relative; }
              .OverlayTextbook > div .header ul li a.active:before {
                content: "";
                position: absolute;
                left: 50%;
                margin-left: -10px;
                bottom: 0;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 8px solid #fff; }
        .OverlayTextbook > div .header ul.activities {
          justify-content: space-evenly; }
      .OverlayTextbook > div .header .web_slider_subjects .slick-list {
        padding: 0 20px; }
      .OverlayTextbook > div .header .web_slider_subjects .slick-prev {
        left: 0px !important;
        z-index: 10; }
      .OverlayTextbook > div .header .web_slider_subjects .slick-next {
        right: 0px !important;
        z-index: 10; }
    .OverlayTextbook > div .todoCompleted {
      float: left;
      width: 100%;
      padding: 30px 40px;
      text-align: center; }
      .OverlayTextbook > div .todoCompleted span.not_done_tasks_count {
        position: absolute;
        right: 10px;
        top: 15px;
        background: red;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        line-height: 16px;
        color: white;
        font-weight: bold;
        font-size: 10px;
        text-align: center;
        display: block; }
      .OverlayTextbook > div .todoCompleted div {
        display: inline-block;
        border-radius: 27px;
        border: solid 1px transparent;
        position: relative;
        overflow: hidden;
        height: 50px; }
        .OverlayTextbook > div .todoCompleted div a {
          font-family: "Montserrat", sans-serif;
          font-size: 11.4px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 1.2px;
          padding: 17px 35px;
          display: inline-block;
          position: relative;
          border-radius: 27px;
          overflow: hidden; }
          .OverlayTextbook > div .todoCompleted div a.active {
            color: #fff !important; }
            .OverlayTextbook > div .todoCompleted div a.active:before {
              content: "";
              position: absolute;
              left: 0;
              right: -2px;
              top: 0;
              bottom: 0;
              border-radius: 31px;
              border: 2px solid #fff; }
    .OverlayTextbook > div .exercices-table {
      /*float: left;*/
      width: calc(100% - 20px);
      padding: 0 20px;
      margin: 0 10px;
      height: 100%;
      overflow: auto; }
      @media screen and (min-width: 480px) {
        .OverlayTextbook > div .exercices-table {
          overflow: auto; } }
      .OverlayTextbook > div .exercices-table::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5; }
      .OverlayTextbook > div .exercices-table::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5; }
      .OverlayTextbook > div .exercices-table::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
      .OverlayTextbook > div .exercices-table table {
        width: 100%; }
        .OverlayTextbook > div .exercices-table table tr td:nth-child(1),
        .OverlayTextbook > div .exercices-table table tr th:nth-child(1) {
          width: 25%; }
        .OverlayTextbook > div .exercices-table table tr td:nth-child(3),
        .OverlayTextbook > div .exercices-table table tr th:nth-child(3) {
          width: 10%; }
        .OverlayTextbook > div .exercices-table table tr th {
          padding-bottom: 20px;
          text-align: left; }
        .OverlayTextbook > div .exercices-table table thead {
          margin-bottom: 20px; }
          .OverlayTextbook > div .exercices-table table thead tr th {
            font-family: "Montserrat", sans-serif;
            font-size: 11.5px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.6px;
            text-align: left;
            color: #797979; }
            .OverlayTextbook > div .exercices-table table thead tr th:nth-child(3) {
              text-align: right; }
            .OverlayTextbook > div .exercices-table table thead tr th.correction {
              text-align: center;
              padding-left: 15px;
              padding-right: 10px; }
        .OverlayTextbook > div .exercices-table table tbody tr {
          border-bottom: 0.5px solid rgba(62, 182, 173, 0.45); }
          .OverlayTextbook > div .exercices-table table tbody tr td {
            padding: 10px 0; }
            .OverlayTextbook > div .exercices-table table tbody tr td:nth-child(1) {
              font-family: "Montserrat", sans-serif;
              font-size: 10.5px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 0.5px;
              color: #3eb6ad;
              padding-left: 10px; }
            .OverlayTextbook > div .exercices-table table tbody tr td:nth-child(2) {
              font-family: "Montserrat", sans-serif;
              font-size: 13.5px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: 0.6px;
              color: #3eb6ad;
              display: flex;
              justify-content: space-between;
              align-items: center; }
            .OverlayTextbook > div .exercices-table table tbody tr td:nth-child(3) {
              font-family: "Montserrat", sans-serif;
              font-size: 10.5px;
              font-weight: 600;
              font-style: normal;
              font-stretch: normal;
              line-height: 2.3;
              letter-spacing: 0.6px;
              text-align: right;
              color: #ffffff;
              text-transform: uppercase; }
              .OverlayTextbook > div .exercices-table table tbody tr td:nth-child(3) .expired,
              .OverlayTextbook > div .exercices-table table tbody tr td:nth-child(3) .visit {
                color: #fff;
                background: #bf3938;
                border-radius: 13.5px;
                padding: 3px 8px 1px;
                line-height: 18px;
                min-width: 69px;
                display: inline-block;
                text-align: center; }
              .OverlayTextbook > div .exercices-table table tbody tr td:nth-child(3) .done {
                font-family: "Montserrat", sans-serif;
                font-size: 11px;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: 2.3;
                letter-spacing: 0.6px;
                text-align: center; }
                .OverlayTextbook > div .exercices-table table tbody tr td:nth-child(3) .done i {
                  font-size: 17px;
                  float: right;
                  margin-top: 3px;
                  margin-left: 5px;
                  margin-right: 7px; }
              .OverlayTextbook > div .exercices-table table tbody tr td:nth-child(3) .expired {
                background: #bf3938; }
            .OverlayTextbook > div .exercices-table table tbody tr td .visit_corrections {
              color: #fff;
              background: #3eb6ad !important;
              border-radius: 13.5px;
              padding: 3px 12px 1px;
              line-height: 18px;
              min-width: 69px;
              display: inline-block;
              text-align: center;
              padding-top: 0;
              margin-right: 20px;
              padding-bottom: 0;
              line-height: 22px;
              position: relative; }
              .OverlayTextbook > div .exercices-table table tbody tr td .visit_corrections .not_viewed_corrections {
                position: absolute;
                top: 6px;
                right: 1px;
                background: red;
                border-radius: 50%;
                width: 10px;
                height: 10px; }
            .OverlayTextbook > div .exercices-table table tbody tr td.correction {
              text-align: center;
              padding-left: 15px;
              padding-right: 10px; }
              .OverlayTextbook > div .exercices-table table tbody tr td.correction .visit {
                color: #fff;
                background: #3eb6ad !important;
                border-radius: 13.5px;
                padding: 3px 8px 1px;
                line-height: 18px;
                min-width: 69px;
                display: inline-block;
                text-align: center; }
          .OverlayTextbook > div .exercices-table table tbody tr:last-child {
            border-bottom: none; }
    .OverlayTextbook > div .maths .closeModal {
      background-color: #56729f; }
    .OverlayTextbook > div .maths .header {
      background-color: #56729f; }
    .OverlayTextbook > div .maths .textbook-period ul.list-period > li > a {
      color: #56729f;
      border: 2px solid #56729f; }
    .OverlayTextbook > div .maths .textbook-period ul.list-period > li > ul {
      border: 2px solid #56729f; }
      .OverlayTextbook > div .maths .textbook-period ul.list-period > li > ul li {
        color: #56729f; }
    .OverlayTextbook > div .maths .textbook-period .searched_name {
      border: 2px solid #56729f; }
    .OverlayTextbook > div .maths .textbook-period.bibliotheque > ul li.active a {
      background: #56729f;
      color: white; }
    .OverlayTextbook > div .maths .todoCompleted div {
      border-color: #56729f; }
      .OverlayTextbook > div .maths .todoCompleted div a {
        color: #56729f; }
        .OverlayTextbook > div .maths .todoCompleted div a.active {
          background-color: #56729f; }
    .OverlayTextbook > div .maths .exercices-table table {
      border-collapse: unset; }
      .OverlayTextbook > div .maths .exercices-table table tbody tr.border_txt {
        border: none; }
      .OverlayTextbook > div .maths .exercices-table table tbody tr td.datefor {
        background: #56729f;
        min-height: 35px; }
      .OverlayTextbook > div .maths .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayTextbook > div .maths .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #56729f;
        background: #1c4381;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayTextbook > div .maths .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #56729f; }
      .OverlayTextbook > div .maths .exercices-table table tbody tr td:nth-child(3) .done {
        color: #56729f; }
    .OverlayTextbook > div .french .closeModal {
      background-color: #e7c26a; }
    .OverlayTextbook > div .french .header {
      background-color: #e7c26a; }
    .OverlayTextbook > div .french .textbook-period ul.list-period > li > a {
      color: #e7c26a;
      border: 2px solid #e7c26a; }
    .OverlayTextbook > div .french .textbook-period ul.list-period > li > ul {
      border: 2px solid #e7c26a; }
      .OverlayTextbook > div .french .textbook-period ul.list-period > li > ul li {
        color: #e7c26a; }
    .OverlayTextbook > div .french .textbook-period .searched_name {
      border: 2px solid #e7c26a; }
    .OverlayTextbook > div .french .textbook-period.bibliotheque > ul li.active a {
      background: #e7c26a;
      color: white; }
    .OverlayTextbook > div .french .todoCompleted div {
      border-color: #e7c26a; }
      .OverlayTextbook > div .french .todoCompleted div a {
        color: #e7c26a; }
        .OverlayTextbook > div .french .todoCompleted div a.active {
          background-color: #e7c26a; }
    .OverlayTextbook > div .french .exercices-table table {
      border-collapse: unset; }
      .OverlayTextbook > div .french .exercices-table table tbody tr.border_txt {
        border: none; }
      .OverlayTextbook > div .french .exercices-table table tbody tr td.datefor {
        background: #e7c26a;
        min-height: 35px; }
      .OverlayTextbook > div .french .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayTextbook > div .french .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #e7c26a;
        background: #dd9e0c;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayTextbook > div .french .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #e7c26a; }
      .OverlayTextbook > div .french .exercices-table table tbody tr td:nth-child(3) .done {
        color: #e7c26a; }
    .OverlayTextbook > div .autre .closeModal {
      background-color: #80c9ca; }
    .OverlayTextbook > div .autre .header {
      background-color: #80c9ca; }
    .OverlayTextbook > div .autre .textbook-period ul.list-period > li > a {
      color: #80c9ca;
      border: 2px solid #80c9ca; }
    .OverlayTextbook > div .autre .textbook-period ul.list-period > li > ul {
      border: 2px solid #80c9ca; }
      .OverlayTextbook > div .autre .textbook-period ul.list-period > li > ul li {
        color: #80c9ca; }
    .OverlayTextbook > div .autre .textbook-period .searched_name {
      border: 2px solid #80c9ca; }
    .OverlayTextbook > div .autre .textbook-period.bibliotheque > ul li.active a {
      background: #80c9ca;
      color: white; }
    .OverlayTextbook > div .autre .todoCompleted div {
      border-color: #80c9ca; }
      .OverlayTextbook > div .autre .todoCompleted div a {
        color: #80c9ca; }
        .OverlayTextbook > div .autre .todoCompleted div a.active {
          background-color: #80c9ca; }
    .OverlayTextbook > div .autre .exercices-table table {
      border-collapse: unset; }
      .OverlayTextbook > div .autre .exercices-table table tbody tr td.border_txt {
        border: none; }
      .OverlayTextbook > div .autre .exercices-table table tbody tr td.datefor {
        background: #80c9ca;
        min-height: 35px; }
      .OverlayTextbook > div .autre .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayTextbook > div .autre .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #80c9ca;
        background: #19abad;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayTextbook > div .autre .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #80c9ca; }
      .OverlayTextbook > div .autre .exercices-table table tbody tr td:nth-child(3) .done {
        color: #80c9ca; }
    .OverlayTextbook > div .arabic .closeModal {
      background-color: #7cb9de; }
    .OverlayTextbook > div .arabic .header {
      background-color: #7cb9de; }
    .OverlayTextbook > div .arabic .textbook-period ul.list-period > li > a {
      color: #7cb9de;
      border: 2px solid #7cb9de; }
    .OverlayTextbook > div .arabic .textbook-period ul.list-period > li > ul {
      border: 2px solid #7cb9de; }
      .OverlayTextbook > div .arabic .textbook-period ul.list-period > li > ul li {
        color: #7cb9de; }
    .OverlayTextbook > div .arabic .textbook-period .searched_name {
      border: 2px solid #7cb9de; }
    .OverlayTextbook > div .arabic .textbook-period.bibliotheque > ul li.active a {
      background: #7cb9de;
      color: white; }
    .OverlayTextbook > div .arabic .todoCompleted div {
      border-color: #7cb9de; }
      .OverlayTextbook > div .arabic .todoCompleted div a {
        color: #7cb9de; }
        .OverlayTextbook > div .arabic .todoCompleted div a.active {
          background-color: #7cb9de; }
    .OverlayTextbook > div .arabic .exercices-table table {
      border-collapse: unset; }
      .OverlayTextbook > div .arabic .exercices-table table tbody tr.border_txt {
        border: none; }
      .OverlayTextbook > div .arabic .exercices-table table tbody tr td.datefor {
        background: #7cb9de;
        min-height: 35px; }
      .OverlayTextbook > div .arabic .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayTextbook > div .arabic .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #7cb9de;
        background: #1f7db8;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayTextbook > div .arabic .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #7cb9de; }
      .OverlayTextbook > div .arabic .exercices-table table tbody tr td:nth-child(3) .done {
        color: #7cb9de; }
    .OverlayTextbook > div .extracurricular_activity .closeModal {
      background-color: #80c9ca; }
    .OverlayTextbook > div .extracurricular_activity .header {
      background-color: #80c9ca; }
    .OverlayTextbook > div .extracurricular_activity .textbook-period ul.list-period > li > a {
      color: #80c9ca;
      border: 2px solid #80c9ca; }
    .OverlayTextbook > div .extracurricular_activity .textbook-period ul.list-period > li > ul {
      border: 2px solid #80c9ca; }
      .OverlayTextbook > div .extracurricular_activity .textbook-period ul.list-period > li > ul li {
        color: #80c9ca; }
    .OverlayTextbook > div .extracurricular_activity .textbook-period .searched_name {
      border: 2px solid #80c9ca; }
    .OverlayTextbook > div .extracurricular_activity .textbook-period.bibliotheque > ul li.active a {
      background: #80c9ca;
      color: white; }
    .OverlayTextbook > div .extracurricular_activity .todoCompleted div {
      border-color: #80c9ca; }
      .OverlayTextbook > div .extracurricular_activity .todoCompleted div a {
        color: #80c9ca; }
        .OverlayTextbook > div .extracurricular_activity .todoCompleted div a.active {
          background-color: #80c9ca; }
    .OverlayTextbook > div .extracurricular_activity .exercices-table table {
      border-collapse: unset; }
      .OverlayTextbook > div .extracurricular_activity .exercices-table table tbody tr.border_txt {
        border: none; }
      .OverlayTextbook > div .extracurricular_activity .exercices-table table tbody tr td.datefor {
        background: #80c9ca;
        min-height: 35px; }
      .OverlayTextbook > div .extracurricular_activity .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayTextbook > div .extracurricular_activity .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #80c9ca;
        background: #19abad;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayTextbook > div .extracurricular_activity .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #80c9ca; }
      .OverlayTextbook > div .extracurricular_activity .exercices-table table tbody tr td:nth-child(3) .done {
        color: #80c9ca; }
    .OverlayTextbook > div .anglais .closeModal {
      background-color: #e7c26a; }
    .OverlayTextbook > div .anglais .header {
      background-color: #e7c26a; }
    .OverlayTextbook > div .anglais .textbook-period ul.list-period > li > a {
      color: #e7c26a;
      border: 2px solid #e7c26a; }
    .OverlayTextbook > div .anglais .textbook-period ul.list-period > li > ul {
      border: 2px solid #e7c26a; }
      .OverlayTextbook > div .anglais .textbook-period ul.list-period > li > ul li {
        color: #e7c26a; }
    .OverlayTextbook > div .anglais .textbook-period .searched_name {
      border: 2px solid #e7c26a; }
    .OverlayTextbook > div .anglais .textbook-period.bibliotheque > ul li.active a {
      background: #e7c26a;
      color: white; }
    .OverlayTextbook > div .anglais .todoCompleted div {
      border-color: #e7c26a; }
      .OverlayTextbook > div .anglais .todoCompleted div a {
        color: #e7c26a; }
        .OverlayTextbook > div .anglais .todoCompleted div a.active {
          background-color: #e7c26a; }
    .OverlayTextbook > div .anglais .exercices-table table {
      border-collapse: unset; }
      .OverlayTextbook > div .anglais .exercices-table table tbody tr.border_txt {
        border: none; }
      .OverlayTextbook > div .anglais .exercices-table table tbody tr td.datefor {
        background: #e7c26a;
        min-height: 35px; }
      .OverlayTextbook > div .anglais .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayTextbook > div .anglais .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #e7c26a;
        background: #c5931f;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayTextbook > div .anglais .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #e7c26a; }
      .OverlayTextbook > div .anglais .exercices-table table tbody tr td:nth-child(3) .done {
        color: #e7c26a; }
    .OverlayTextbook > div .science .closeModal {
      background-color: #81b7ad; }
    .OverlayTextbook > div .science .header {
      background-color: #81b7ad; }
    .OverlayTextbook > div .science .textbook-period ul.list-period > li > a {
      color: #81b7ad;
      border: 2px solid #81b7ad; }
    .OverlayTextbook > div .science .textbook-period ul.list-period > li > ul {
      border: 2px solid #81b7ad; }
      .OverlayTextbook > div .science .textbook-period ul.list-period > li > ul li {
        color: #81b7ad; }
    .OverlayTextbook > div .science .textbook-period .searched_name {
      border: 2px solid #81b7ad; }
    .OverlayTextbook > div .science .textbook-period.bibliotheque > ul li.active a {
      background: #81b7ad;
      color: white; }
    .OverlayTextbook > div .science .todoCompleted div {
      border-color: #81b7ad; }
      .OverlayTextbook > div .science .todoCompleted div a {
        color: #81b7ad; }
        .OverlayTextbook > div .science .todoCompleted div a.active {
          background-color: #81b7ad; }
    .OverlayTextbook > div .science .exercices-table table {
      border-collapse: unset; }
      .OverlayTextbook > div .science .exercices-table table tbody tr.border_txt {
        border: none; }
      .OverlayTextbook > div .science .exercices-table table tbody tr td.datefor {
        background: #81b7ad;
        min-height: 35px; }
      .OverlayTextbook > div .science .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayTextbook > div .science .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #81b7ad;
        background: #3c9787;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayTextbook > div .science .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #81b7ad; }
      .OverlayTextbook > div .science .exercices-table table tbody tr td:nth-child(3) .done {
        color: #81b7ad; }
    .OverlayTextbook > div .géographie .closeModal {
      background-color: #81b7ad; }
    .OverlayTextbook > div .géographie .header {
      background-color: #81b7ad; }
    .OverlayTextbook > div .géographie .textbook-period ul.list-period > li > a {
      color: #81b7ad;
      border: 2px solid #81b7ad; }
    .OverlayTextbook > div .géographie .textbook-period ul.list-period > li > ul {
      border: 2px solid #81b7ad; }
      .OverlayTextbook > div .géographie .textbook-period ul.list-period > li > ul li {
        color: #81b7ad; }
    .OverlayTextbook > div .géographie .textbook-period .searched_name {
      border: 2px solid #81b7ad; }
    .OverlayTextbook > div .géographie .textbook-period.bibliotheque > ul li.active a {
      background: #81b7ad;
      color: white; }
    .OverlayTextbook > div .géographie .todoCompleted div {
      border-color: #81b7ad; }
      .OverlayTextbook > div .géographie .todoCompleted div a {
        color: #81b7ad; }
        .OverlayTextbook > div .géographie .todoCompleted div a.active {
          background-color: #81b7ad; }
    .OverlayTextbook > div .géographie .exercices-table table {
      border-collapse: unset; }
      .OverlayTextbook > div .géographie .exercices-table table tbody tr.border_txt {
        border: none; }
      .OverlayTextbook > div .géographie .exercices-table table tbody tr td.datefor {
        background: #81b7ad;
        min-height: 35px; }
      .OverlayTextbook > div .géographie .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayTextbook > div .géographie .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #81b7ad;
        background: #37a08d;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayTextbook > div .géographie .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #81b7ad; }
      .OverlayTextbook > div .géographie .exercices-table table tbody tr td:nth-child(3) .done {
        color: #81b7ad; }
    .OverlayTextbook > div .histoire .closeModal {
      background-color: #bf9592; }
    .OverlayTextbook > div .histoire .header {
      background-color: #bf9592; }
    .OverlayTextbook > div .histoire .textbook-period ul.list-period > li > a {
      color: #bf9592;
      border: 2px solid #bf9592; }
    .OverlayTextbook > div .histoire .textbook-period ul.list-period > li > ul {
      border: 2px solid #bf9592; }
      .OverlayTextbook > div .histoire .textbook-period ul.list-period > li > ul li {
        color: #bf9592; }
    .OverlayTextbook > div .histoire .textbook-period .searched_name {
      border: 2px solid #bf9592; }
    .OverlayTextbook > div .histoire .textbook-period.bibliotheque > ul li.active a {
      background: #bf9592;
      color: white; }
    .OverlayTextbook > div .histoire .todoCompleted div {
      border-color: #bf9592; }
      .OverlayTextbook > div .histoire .todoCompleted div a {
        color: #bf9592; }
        .OverlayTextbook > div .histoire .todoCompleted div a.active {
          background-color: #bf9592; }
    .OverlayTextbook > div .histoire .exercices-table table {
      border-collapse: unset; }
      .OverlayTextbook > div .histoire .exercices-table table tbody tr.border_txt {
        border: none; }
      .OverlayTextbook > div .histoire .exercices-table table tbody tr td.datefor {
        background: #bf3938;
        min-height: 35px; }
      .OverlayTextbook > div .histoire .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayTextbook > div .histoire .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #bf3938;
        background: #661212;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayTextbook > div .histoire .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #bf9592; }
      .OverlayTextbook > div .histoire .exercices-table table tbody tr td:nth-child(3) .done {
        color: #bf9592; }
    .OverlayTextbook > div .sciences .closeModal {
      background-color: #80b0a0; }
    .OverlayTextbook > div .sciences .header {
      background-color: #80b0a0; }
    .OverlayTextbook > div .sciences .textbook-period ul.list-period > li > a {
      color: #80b0a0;
      border: 2px solid #80b0a0; }
    .OverlayTextbook > div .sciences .textbook-period ul.list-period > li > ul {
      border: 2px solid #80b0a0; }
      .OverlayTextbook > div .sciences .textbook-period ul.list-period > li > ul li {
        color: #80b0a0; }
    .OverlayTextbook > div .sciences .textbook-period .searched_name {
      border: 2px solid #80b0a0; }
    .OverlayTextbook > div .sciences .textbook-period.bibliotheque > ul li.active a {
      background: #80b0a0;
      color: white; }
    .OverlayTextbook > div .sciences .todoCompleted div {
      border-color: #80b0a0; }
      .OverlayTextbook > div .sciences .todoCompleted div a {
        color: #80b0a0; }
        .OverlayTextbook > div .sciences .todoCompleted div a.active {
          background-color: #80b0a0; }
    .OverlayTextbook > div .sciences .exercices-table table {
      border-collapse: unset; }
      .OverlayTextbook > div .sciences .exercices-table table tbody tr.border_txt {
        border: none; }
      .OverlayTextbook > div .sciences .exercices-table table tbody tr td.datefor {
        background: #80b0a0;
        min-height: 35px; }
      .OverlayTextbook > div .sciences .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayTextbook > div .sciences .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #80b0a0;
        background: #2c8f6e;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayTextbook > div .sciences .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #80b0a0; }
      .OverlayTextbook > div .sciences .exercices-table table tbody tr td:nth-child(3) .done {
        color: #80b0a0; }
    .OverlayTextbook > div .arabe .closeModal {
      background-color: #7abade; }
    .OverlayTextbook > div .arabe .header {
      background-color: #7abade; }
    .OverlayTextbook > div .arabe .textbook-period ul.list-period > li > a {
      color: #7abade;
      border: 2px solid #7abade; }
    .OverlayTextbook > div .arabe .textbook-period ul.list-period > li > ul {
      border: 2px solid #7abade; }
      .OverlayTextbook > div .arabe .textbook-period ul.list-period > li > ul li {
        color: #7abade; }
    .OverlayTextbook > div .arabe .textbook-period .searched_name {
      border: 2px solid #7abade; }
    .OverlayTextbook > div .arabe .textbook-period.bibliotheque > ul li.active a {
      background: #7abade;
      color: white; }
    .OverlayTextbook > div .arabe .todoCompleted div {
      border-color: #7abade; }
      .OverlayTextbook > div .arabe .todoCompleted div a {
        color: #7abade; }
        .OverlayTextbook > div .arabe .todoCompleted div a.active {
          background-color: #7abade; }
    .OverlayTextbook > div .arabe .exercices-table table {
      border-collapse: unset; }
      .OverlayTextbook > div .arabe .exercices-table table tbody tr.border_txt {
        border: none; }
      .OverlayTextbook > div .arabe .exercices-table table tbody tr td.datefor {
        background: #7abade;
        min-height: 35px; }
      .OverlayTextbook > div .arabe .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayTextbook > div .arabe .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #7abade;
        background: #2f739b;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayTextbook > div .arabe .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #7abade; }
      .OverlayTextbook > div .arabe .exercices-table table tbody tr td:nth-child(3) .done {
        color: #7abade; }
  .OverlayTextbook.OverlayActivities > div {
    min-height: 500px;
    max-width: 90%;
    width: 90%;
    left: 5% !important;
    margin-left: 0; }
    @media (max-width: 1199px) {
      .OverlayTextbook.OverlayActivities > div {
        max-width: 100%;
        width: 100%;
        left: 0% !important; } }
  .OverlayTextbook.OverlayActivities .closeModal {
    background-color: #8a78f8; }
    @media (max-width: 1199px) {
      .OverlayTextbook.OverlayActivities .closeModal {
        top: 0;
        right: 0; } }
  .OverlayTextbook.OverlayActivities .row.back {
    margin: 5px 20px; }
    .OverlayTextbook.OverlayActivities .row.back button {
      border: 2px solid #2ae2d780;
      min-width: 57px;
      box-shadow: none;
      background: white; }
  .OverlayTextbook.OverlayActivities .detailBack {
    width: 100%;
    float: left;
    padding: 5px 20px; }
    .OverlayTextbook.OverlayActivities .detailBack .icon {
      float: left;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      box-shadow: rgba(90, 202, 195, 0.5) 0px 10px 20px 0px;
      background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
      text-align: center; }
      .OverlayTextbook.OverlayActivities .detailBack .icon i {
        color: white;
        font-size: 27px;
        line-height: 40px; }
  .OverlayTextbook.OverlayActivities .activities_list {
    float: left;
    margin: 0px 0px;
    padding-bottom: 25px;
    margin-bottom: 0;
    width: 100%;
    overflow: auto;
    height: calc(100% - 50px);
    justify-content: start;
    padding: 10px; }
    @media screen and (max-width: 1200px) {
      .OverlayTextbook.OverlayActivities .activities_list {
        height: calc(100% - 205px); } }
    .OverlayTextbook.OverlayActivities .activities_list .activity_item {
      margin: 10px 0px;
      max-height: 170px;
      max-width: 270px;
      cursor: pointer; }
      .OverlayTextbook.OverlayActivities .activities_list .activity_item > div {
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
        padding: 10px;
        text-align: center;
        height: 100%;
        justify-content: space-between;
        position: relative; }
        .OverlayTextbook.OverlayActivities .activities_list .activity_item > div img {
          width: 80px;
          height: 80px;
          border-radius: 50%; }
        .OverlayTextbook.OverlayActivities .activities_list .activity_item > div i {
          position: absolute;
          color: gray;
          cursor: pointer;
          right: 7px;
          top: 7px; }
          .OverlayTextbook.OverlayActivities .activities_list .activity_item > div i:hover {
            color: #3eb6ad; }
      .OverlayTextbook.OverlayActivities .activities_list .activity_item .activities_likes {
        margin: 0px;
        padding: 5px 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 18px; }
        .OverlayTextbook.OverlayActivities .activities_list .activity_item .activities_likes button {
          position: relative;
          margin-right: 3px !important;
          width: max-content;
          min-width: inherit;
          padding-left: 25px;
          border: 0; }
          .OverlayTextbook.OverlayActivities .activities_list .activity_item .activities_likes button:focus {
            box-shadow: unset; }
          .OverlayTextbook.OverlayActivities .activities_list .activity_item .activities_likes button i {
            left: 10px;
            width: max-content;
            color: #ef475a;
            font-size: 18px; }
          .OverlayTextbook.OverlayActivities .activities_list .activity_item .activities_likes button:not(.liked) i {
            color: #80808099;
            font-size: 18px; }
        .OverlayTextbook.OverlayActivities .activities_list .activity_item .activities_likes .info-category {
          font-size: 12px;
          line-height: 30px;
          color: black;
          position: relative; }
          .OverlayTextbook.OverlayActivities .activities_list .activity_item .activities_likes .info-category a {
            font-weight: bold;
            color: black;
            margin: 0 !important; }
          .OverlayTextbook.OverlayActivities .activities_list .activity_item .activities_likes .info-category span {
            color: black;
            margin-left: 5px; }
    .OverlayTextbook.OverlayActivities .activities_list .activity_koolskools_item {
      margin: 10px 10px;
      max-height: 200px;
      min-width: calc(20% - 20px);
      max-width: calc(20% - 20px);
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
      padding: 10px;
      text-align: center;
      height: 100%;
      justify-content: space-between;
      position: relative; }
      .OverlayTextbook.OverlayActivities .activities_list .activity_koolskools_item .exercise-img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        box-shadow: 0px 1px 12px 1px #b1acac;
        overflow: hidden;
        margin-bottom: 5px; }
      .OverlayTextbook.OverlayActivities .activities_list .activity_koolskools_item img {
        width: 90px;
        height: 90px;
        padding: 10px; }
      .OverlayTextbook.OverlayActivities .activities_list .activity_koolskools_item i {
        position: absolute;
        color: gray;
        cursor: pointer;
        right: 7px;
        top: 7px; }
        .OverlayTextbook.OverlayActivities .activities_list .activity_koolskools_item i:hover {
          color: #3eb6ad; }
      .OverlayTextbook.OverlayActivities .activities_list .activity_koolskools_item .desc {
        height: calc(100% - 25px); }
      .OverlayTextbook.OverlayActivities .activities_list .activity_koolskools_item .activities_likes {
        margin: 0px;
        padding: 5px 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 18px; }
        .OverlayTextbook.OverlayActivities .activities_list .activity_koolskools_item .activities_likes button {
          position: relative;
          margin-right: 3px !important;
          width: max-content;
          min-width: inherit;
          padding-left: 25px;
          border: 0; }
          .OverlayTextbook.OverlayActivities .activities_list .activity_koolskools_item .activities_likes button:focus {
            box-shadow: unset; }
          .OverlayTextbook.OverlayActivities .activities_list .activity_koolskools_item .activities_likes button i {
            left: 10px;
            width: max-content;
            color: #ef475a;
            font-size: 18px; }
          .OverlayTextbook.OverlayActivities .activities_list .activity_koolskools_item .activities_likes button:not(.liked) i {
            color: #80808099;
            font-size: 18px; }
        .OverlayTextbook.OverlayActivities .activities_list .activity_koolskools_item .activities_likes .info-category {
          font-size: 12px;
          line-height: 30px;
          color: black;
          position: relative; }
          .OverlayTextbook.OverlayActivities .activities_list .activity_koolskools_item .activities_likes .info-category a {
            font-weight: bold;
            color: black;
            margin: 0 !important; }
          .OverlayTextbook.OverlayActivities .activities_list .activity_koolskools_item .activities_likes .info-category span {
            color: black;
            margin-left: 5px; }
      .OverlayTextbook.OverlayActivities .activities_list .activity_koolskools_item .item_name {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        line-height: 15px;
        width: 100%; }
      @media (max-width: 1199px) and (min-width: 768px) {
        .OverlayTextbook.OverlayActivities .activities_list .activity_koolskools_item {
          min-width: calc(33% - 20px);
          max-width: calc(33% - 20px); } }
      @media (max-width: 767px) {
        .OverlayTextbook.OverlayActivities .activities_list .activity_koolskools_item {
          min-width: calc(50% - 20px);
          max-width: calc(50% - 20px); } }
    .OverlayTextbook.OverlayActivities .activities_list .activity_theme_item {
      margin: 10px 10px;
      cursor: pointer;
      max-height: 170px;
      min-width: calc(20% - 20px);
      max-width: calc(20% - 20px);
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
      padding: 10px;
      text-align: center;
      height: 100%;
      justify-content: space-around;
      position: relative; }
      .OverlayTextbook.OverlayActivities .activities_list .activity_theme_item .exercise-img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        box-shadow: 0px 1px 12px 1px #b1acac;
        overflow: hidden; }
      .OverlayTextbook.OverlayActivities .activities_list .activity_theme_item img {
        width: 90px;
        height: 90px;
        padding: 10px; }
      .OverlayTextbook.OverlayActivities .activities_list .activity_theme_item i {
        position: absolute;
        color: gray;
        cursor: pointer;
        right: 7px;
        top: 7px; }
        .OverlayTextbook.OverlayActivities .activities_list .activity_theme_item i:hover {
          color: #3eb6ad; }
      .OverlayTextbook.OverlayActivities .activities_list .activity_theme_item .notifs_info {
        position: absolute;
        right: 0;
        top: 0;
        color: white;
        background: red;
        border-radius: 50%;
        width: 20px; }
      .OverlayTextbook.OverlayActivities .activities_list .activity_theme_item .exercise-img {
        overflow: hidden; }
      @media (max-width: 1199px) and (min-width: 768px) {
        .OverlayTextbook.OverlayActivities .activities_list .activity_theme_item {
          min-width: calc(33% - 20px);
          max-width: calc(33% - 20px); } }
      @media (max-width: 767px) {
        .OverlayTextbook.OverlayActivities .activities_list .activity_theme_item {
          min-width: calc(50% - 20px);
          max-width: calc(50% - 20px); } }
  .OverlayTextbook.OverlayActivities .categories_activities .header {
    background-color: #8a78f8; }
  .OverlayTextbook.OverlayActivities .categories_activities .todoCompleted div {
    border-color: #8a78f8; }
    .OverlayTextbook.OverlayActivities .categories_activities .todoCompleted div a {
      color: #8a78f8; }
      .OverlayTextbook.OverlayActivities .categories_activities .todoCompleted div a.active {
        background-color: #8a78f8; }
  .OverlayTextbook.OverlayActivities .categories_activities .exercices-table table {
    border-collapse: unset; }
    .OverlayTextbook.OverlayActivities .categories_activities .exercices-table table tbody tr.border_txt {
      border: none; }
    .OverlayTextbook.OverlayActivities .categories_activities .exercices-table table tbody tr td.datefor {
      background: #8a78f8;
      min-height: 35px; }
    .OverlayTextbook.OverlayActivities .categories_activities .exercices-table table tbody tr td.next_txtb {
      min-height: 36px; }
    .OverlayTextbook.OverlayActivities .categories_activities .exercices-table table tbody tr td.next_txtb_extra {
      border: 1px solid #8a78f8;
      background: #4433ad;
      border-radius: 27px;
      padding: 10px !important;
      color: white !important;
      font-size: 12px;
      font-weight: bold;
      align-items: center;
      text-align: center; }
    .OverlayTextbook.OverlayActivities .categories_activities .exercices-table table tbody tr td:nth-child(3) .visit {
      background-color: #8a78f8; }
    .OverlayTextbook.OverlayActivities .categories_activities .exercices-table table tbody tr td:nth-child(3) .done {
      color: #8a78f8; }
  .OverlayTextbook.OverlayExams {
    z-index: 1111111111; }
    .OverlayTextbook.OverlayExams > div {
      min-height: 500px;
      max-width: 60%;
      width: 60%;
      left: 20% !important;
      margin-left: 0;
      padding: 20px !important; }
    .OverlayTextbook.OverlayExams .closeModal {
      background-color: #8bc34a; }
    .OverlayTextbook.OverlayExams .exams {
      padding: 10px; }
      .OverlayTextbook.OverlayExams .exams .header {
        background-color: #8bc34a; }
    .OverlayTextbook.OverlayExams .exams_list {
      float: left;
      margin: 20px 0px;
      padding-bottom: 25px;
      margin-bottom: 0;
      width: 100%;
      overflow: auto;
      height: calc(100% - 50px); }
      @media screen and (max-width: 1200px) {
        .OverlayTextbook.OverlayExams .exams_list {
          height: calc(100% - 205px); } }
      .OverlayTextbook.OverlayExams .exams_list .exam_item {
        margin: 10px 0px;
        min-height: 220px;
        height: 220px; }
        .OverlayTextbook.OverlayExams .exams_list .exam_item > div {
          cursor: pointer;
          margin: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #fff;
          border-radius: 10px;
          box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
          padding: 10px;
          text-align: center;
          height: 100%;
          justify-content: space-between;
          position: relative; }
          .OverlayTextbook.OverlayExams .exams_list .exam_item > div img {
            width: 120px;
            height: 80px;
            border-radius: 50%;
            margin-top: 10px; }
          .OverlayTextbook.OverlayExams .exams_list .exam_item > div i {
            position: absolute;
            color: gray;
            cursor: pointer;
            right: 7px;
            top: 7px; }
            .OverlayTextbook.OverlayExams .exams_list .exam_item > div i:hover {
              color: #3eb6ad; }
        .OverlayTextbook.OverlayExams .exams_list .exam_item:last-of-type > div {
          margin-bottom: 15px; }
        .OverlayTextbook.OverlayExams .exams_list .exam_item .exam_stats {
          font-weight: bold;
          margin-top: 5px; }
          .OverlayTextbook.OverlayExams .exams_list .exam_item .exam_stats .lavel {
            margin-right: 5px; }
        .OverlayTextbook.OverlayExams .exams_list .exam_item .corrected {
          color: #5acac3;
          margin-top: 20px;
          display: block;
          font-weight: bold; }
        .OverlayTextbook.OverlayExams .exams_list .exam_item .not_passed {
          color: #e92727;
          margin-top: 20px;
          display: block;
          font-weight: bold; }
        .OverlayTextbook.OverlayExams .exams_list .exam_item.disabled {
          pointer-events: none; }
          .OverlayTextbook.OverlayExams .exams_list .exam_item.disabled .timer_div {
            min-width: 70px; }
            .OverlayTextbook.OverlayExams .exams_list .exam_item.disabled .timer_div .timer i {
              display: none; }
            .OverlayTextbook.OverlayExams .exams_list .exam_item.disabled .timer_div .timer span {
              width: 100%;
              display: inline-block;
              font-weight: bold; }
    @media screen and (max-width: 1200px) {
      .OverlayTextbook.OverlayExams > div {
        max-height: 100%;
        max-width: 100%;
        width: 100%;
        left: 0% !important; }
        .OverlayTextbook.OverlayExams > div .backModal {
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: unset; }
        .OverlayTextbook.OverlayExams > div .header ul {
          justify-content: space-evenly; }
        .OverlayTextbook.OverlayExams > div .exams {
          height: 96%; }
          .OverlayTextbook.OverlayExams > div .exams .exams_list {
            height: calc(100% - 50px); }
            .OverlayTextbook.OverlayExams > div .exams .exams_list .exam_item {
              min-width: 33%; } }
    @media screen and (max-width: 460px) {
      .OverlayTextbook.OverlayExams > div .exams .exams_list {
        justify-content: center; }
        .OverlayTextbook.OverlayExams > div .exams .exams_list .exam_item {
          min-width: 240px; } }
    @media (orientation: landscape) and (max-width: 1199px) {
      .OverlayTextbook.OverlayExams > div .exams .exams_list .exam_item:last-of-type > div {
        margin-bottom: 140px; } }
  .OverlayTextbook.OverlayBooks {
    z-index: 1111111111; }
    .OverlayTextbook.OverlayBooks > div {
      min-height: 80%;
      max-width: 60%;
      width: 60%;
      left: 20% !important;
      margin-left: 0;
      padding: 0px !important;
      top: 10% !important; }
      @media (max-width: 1199px) {
        .OverlayTextbook.OverlayBooks > div {
          height: calc(100%);
          top: 0 !important;
          padding-top: 35px !important; } }
    .OverlayTextbook.OverlayBooks .closeModal {
      background-color: #3eb6ad; }
    .OverlayTextbook.OverlayBooks .books {
      padding: 0px; }
      .OverlayTextbook.OverlayBooks .books .header {
        justify-content: flex-end;
        padding: 10px;
        border-bottom: 1px solid #dee2e6;
        width: 100%;
        margin: 0; }
        .OverlayTextbook.OverlayBooks .books .header .select_ label {
          display: block;
          font-family: Montserrat;
          font-size: 15px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          margin-bottom: 5px; }
        .OverlayTextbook.OverlayBooks .books .header .select_ .select {
          float: left;
          width: 100%;
          height: 29px;
          border-radius: 2.6px;
          border: 0.6px solid #d1dbe2;
          background-color: rgba(245, 248, 249, 0.3);
          position: relative;
          padding-left: 3px; }
          .OverlayTextbook.OverlayBooks .books .header .select_ .select.search {
            overflow: hidden; }
          .OverlayTextbook.OverlayBooks .books .header .select_ .select input {
            width: 94%;
            border: 0px none;
            height: 100%;
            background: transparent none repeat scroll 0% 0%;
            padding-left: 5px; }
          .OverlayTextbook.OverlayBooks .books .header .select_ .select select {
            float: left;
            width: 100%;
            font-size: 12px;
            height: 28px;
            appearance: none;
            text-indent: 1px;
            text-overflow: "";
            background: transparent none repeat scroll 0% 0%;
            outline: currentcolor none 0px;
            position: relative;
            z-index: 9;
            border-radius: 4px !important;
            border: medium none !important;
            font-weight: 500;
            font-family: Montserrat;
            color: #1b2437;
            padding: 0px 5px; }
          .OverlayTextbook.OverlayBooks .books .header .select_ .select i {
            color: #d2dbe2;
            font-size: 14px;
            position: absolute;
            right: 5px;
            top: 6px; }
        @media (max-width: 1199px) {
          .OverlayTextbook.OverlayBooks .books .header {
            height: 77px !important;
            border-radius: 0 !important; } }
    .OverlayTextbook.OverlayBooks .books_list {
      float: left;
      margin: 20px 0px;
      padding: 10px;
      margin-bottom: 0;
      width: 100%;
      overflow: auto;
      height: calc(100% - 75px);
      overflow-y: auto; }
      .OverlayTextbook.OverlayBooks .books_list::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5; }
      .OverlayTextbook.OverlayBooks .books_list::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5; }
      .OverlayTextbook.OverlayBooks .books_list::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
      @media screen and (max-width: 1200px) {
        .OverlayTextbook.OverlayBooks .books_list {
          height: calc(100% - 205px); } }
      .OverlayTextbook.OverlayBooks .books_list .a_book_item {
        margin: 10px 0px;
        min-height: 220px;
        height: 230px; }
        .OverlayTextbook.OverlayBooks .books_list .a_book_item > div {
          cursor: pointer;
          margin: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: #fff;
          border-radius: 10px;
          box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
          padding: 10px;
          text-align: center;
          height: 100%;
          justify-content: space-between;
          position: relative; }
          .OverlayTextbook.OverlayBooks .books_list .a_book_item > div img {
            width: 80px;
            height: 80px;
            border-radius: 50%;
            margin-top: 10px; }
          .OverlayTextbook.OverlayBooks .books_list .a_book_item > div i {
            position: absolute;
            color: gray;
            cursor: pointer;
            right: 7px;
            top: 7px; }
            .OverlayTextbook.OverlayBooks .books_list .a_book_item > div i:hover {
              color: #3eb6ad; }
        .OverlayTextbook.OverlayBooks .books_list .a_book_item:last-of-type > div {
          margin-bottom: 15px; }
        .OverlayTextbook.OverlayBooks .books_list .a_book_item .bothbox {
          width: calc(100% - 40px);
          display: flex;
          justify-content: space-between; }
        .OverlayTextbook.OverlayBooks .books_list .a_book_item .stat_icon {
          position: absolute;
          top: 12px;
          right: 40px; }
          .OverlayTextbook.OverlayBooks .books_list .a_book_item .stat_icon i {
            color: #b6c3d0;
            font-size: 16px;
            cursor: pointer; }
        .OverlayTextbook.OverlayBooks .books_list .a_book_item .item_name.disp {
          height: 30px;
          padding-top: 5px; }
          .OverlayTextbook.OverlayBooks .books_list .a_book_item .item_name.disp .disponibility {
            min-width: 150px;
            border-radius: 15px;
            color: white;
            padding: 5px 10px; }
            .OverlayTextbook.OverlayBooks .books_list .a_book_item .item_name.disp .disponibility.disponible {
              background-image: linear-gradient(225deg, #72dcd6, #3eb6ad); }
            .OverlayTextbook.OverlayBooks .books_list .a_book_item .item_name.disp .disponibility.no_disponible {
              background-color: red; }
          .OverlayTextbook.OverlayBooks .books_list .a_book_item .item_name.disp.school_child .disponibility {
            font-size: 13px; }
        .OverlayTextbook.OverlayBooks .books_list .a_book_item .name {
          height: 50px; }
          .OverlayTextbook.OverlayBooks .books_list .a_book_item .name .item_name {
            white-space: unset !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            display: -webkit-box;
            margin-top: 5px !important; }
    @media screen and (max-width: 1200px) {
      .OverlayTextbook.OverlayBooks > div {
        max-height: 100%;
        max-width: 100%;
        width: 100%;
        left: 0% !important; }
        .OverlayTextbook.OverlayBooks > div .backModal {
          position: absolute;
          top: 0;
          left: 0;
          box-shadow: unset; }
        .OverlayTextbook.OverlayBooks > div .header ul {
          justify-content: space-evenly; }
        .OverlayTextbook.OverlayBooks > div .books {
          height: 96%; }
          .OverlayTextbook.OverlayBooks > div .books .books_list {
            height: calc(100% - 50px); }
            .OverlayTextbook.OverlayBooks > div .books .books_list .a_book_item {
              min-width: 33%; } }
    @media (orientation: landscape) and (max-width: 1199px) {
      .OverlayTextbook.OverlayBooks > div .books .books_list .a_book_item:last-of-type > div {
        margin-bottom: 140px; } }

.OverlayScholarBooks {
  background-color: rgba(100, 115, 140, 0.9);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999; }
  .OverlayScholarBooks > div {
    top: 70px !important;
    left: 30% !important;
    margin-left: -15%;
    max-width: 70%;
    bottom: auto !important;
    padding: 0 0 30px !important;
    border: none !important;
    border-radius: 10px !important;
    overflow: inherit !important;
    box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
    opacity: 0.9;
    height: 80%; }
    .OverlayScholarBooks > div > div {
      height: 100%;
      display: flex;
      flex-direction: column; }
      .OverlayScholarBooks > div > div.modal_scholar_books {
        z-index: 1;
        position: relative; }
    .OverlayScholarBooks > div .closeModal {
      position: absolute;
      top: -23px;
      right: -23px;
      border: 5px solid #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 17px;
      line-height: 28px; }
      .OverlayScholarBooks > div .closeModal img {
        max-width: 13px; }
    .OverlayScholarBooks > div .header {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 0;
      width: 100%;
      float: left; }
      .OverlayScholarBooks > div .header .slick-list {
        height: 50px; }
      .OverlayScholarBooks > div .header .slick-dots {
        display: none !important; }
      .OverlayScholarBooks > div .header .slick-arrow {
        position: absolute;
        top: 50%;
        font-size: 0;
        background: transparent;
        border: none;
        margin-top: -4px; }
        .OverlayScholarBooks > div .header .slick-arrow:before {
          font: normal normal normal 23px/1 FontAwesome;
          line-height: normal;
          color: #fff; }
        .OverlayScholarBooks > div .header .slick-arrow.slick-prev {
          left: -6px; }
          .OverlayScholarBooks > div .header .slick-arrow.slick-prev:before {
            content: "\f104"; }
        .OverlayScholarBooks > div .header .slick-arrow.slick-next {
          right: -6px; }
          .OverlayScholarBooks > div .header .slick-arrow.slick-next:before {
            content: "\f105"; }
      .OverlayScholarBooks > div .header a {
        opacity: 0.54;
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: 1.5px;
        text-align: center;
        color: #ffffff;
        text-transform: uppercase;
        line-height: 50px;
        display: block; }
        .OverlayScholarBooks > div .header a.active {
          opacity: 1;
          font-weight: 700;
          position: relative;
          outline: none; }
          .OverlayScholarBooks > div .header a.active:before {
            content: "";
            position: absolute;
            left: 50%;
            margin-left: -10px;
            bottom: 0;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 8px solid #fff; }
      .OverlayScholarBooks > div .header ul {
        display: flex;
        justify-content: space-evenly;
        margin: 0;
        padding: 0; }
        .OverlayScholarBooks > div .header ul li {
          display: inline-block; }
          .OverlayScholarBooks > div .header ul li a {
            opacity: 0.54;
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            letter-spacing: 1.5px;
            text-align: center;
            color: #ffffff;
            text-transform: uppercase;
            line-height: 50px;
            display: block; }
            .OverlayScholarBooks > div .header ul li a.active {
              opacity: 1;
              font-weight: 700;
              position: relative; }
              .OverlayScholarBooks > div .header ul li a.active:before {
                content: "";
                position: absolute;
                left: 50%;
                margin-left: -10px;
                bottom: 0;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 8px solid #fff; }
        .OverlayScholarBooks > div .header ul.activities {
          justify-content: space-evenly; }
      .OverlayScholarBooks > div .header .web_slider_subjects .slick-list {
        padding: 0 20px; }
      .OverlayScholarBooks > div .header .web_slider_subjects .slick-prev {
        left: 0px !important;
        z-index: 10; }
      .OverlayScholarBooks > div .header .web_slider_subjects .slick-next {
        right: 0px !important;
        z-index: 10; }
    .OverlayScholarBooks > div .books_list {
      height: calc(100% - 50px); }
      .OverlayScholarBooks > div .books_list > div {
        height: 100%; }
        .OverlayScholarBooks > div .books_list > div > div {
          height: 100%; }
          .OverlayScholarBooks > div .books_list > div > div .books {
            height: calc(100% - 40px);
            overflow: auto;
            padding: 10px 0px; }
    .OverlayScholarBooks > div .maths .closeModal {
      background-color: #56729f; }
    .OverlayScholarBooks > div .maths .header {
      background-color: #56729f; }
    .OverlayScholarBooks > div .maths .textbook-period ul.list-period > li > a {
      color: #56729f;
      border: 2px solid #56729f; }
    .OverlayScholarBooks > div .maths .textbook-period ul.list-period > li > ul {
      border: 2px solid #56729f; }
      .OverlayScholarBooks > div .maths .textbook-period ul.list-period > li > ul li {
        color: #56729f; }
    .OverlayScholarBooks > div .maths .textbook-period .searched_name {
      border: 2px solid #56729f; }
    .OverlayScholarBooks > div .maths .textbook-period.bibliotheque > ul li.active a {
      background: #56729f;
      color: white; }
    .OverlayScholarBooks > div .maths .todoCompleted div {
      border-color: #56729f; }
      .OverlayScholarBooks > div .maths .todoCompleted div a {
        color: #56729f; }
        .OverlayScholarBooks > div .maths .todoCompleted div a.active {
          background-color: #56729f; }
    .OverlayScholarBooks > div .maths .exercices-table table {
      border-collapse: unset; }
      .OverlayScholarBooks > div .maths .exercices-table table tbody tr.border_txt {
        border: none; }
      .OverlayScholarBooks > div .maths .exercices-table table tbody tr td.datefor {
        background: #56729f;
        min-height: 35px; }
      .OverlayScholarBooks > div .maths .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayScholarBooks > div .maths .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #56729f;
        background: #1c4381;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayScholarBooks > div .maths .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #56729f; }
      .OverlayScholarBooks > div .maths .exercices-table table tbody tr td:nth-child(3) .done {
        color: #56729f; }
    .OverlayScholarBooks > div .french .closeModal {
      background-color: #e7c26a; }
    .OverlayScholarBooks > div .french .header {
      background-color: #e7c26a; }
    .OverlayScholarBooks > div .french .textbook-period ul.list-period > li > a {
      color: #e7c26a;
      border: 2px solid #e7c26a; }
    .OverlayScholarBooks > div .french .textbook-period ul.list-period > li > ul {
      border: 2px solid #e7c26a; }
      .OverlayScholarBooks > div .french .textbook-period ul.list-period > li > ul li {
        color: #e7c26a; }
    .OverlayScholarBooks > div .french .textbook-period .searched_name {
      border: 2px solid #e7c26a; }
    .OverlayScholarBooks > div .french .textbook-period.bibliotheque > ul li.active a {
      background: #e7c26a;
      color: white; }
    .OverlayScholarBooks > div .french .todoCompleted div {
      border-color: #e7c26a; }
      .OverlayScholarBooks > div .french .todoCompleted div a {
        color: #e7c26a; }
        .OverlayScholarBooks > div .french .todoCompleted div a.active {
          background-color: #e7c26a; }
    .OverlayScholarBooks > div .french .exercices-table table {
      border-collapse: unset; }
      .OverlayScholarBooks > div .french .exercices-table table tbody tr.border_txt {
        border: none; }
      .OverlayScholarBooks > div .french .exercices-table table tbody tr td.datefor {
        background: #e7c26a;
        min-height: 35px; }
      .OverlayScholarBooks > div .french .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayScholarBooks > div .french .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #e7c26a;
        background: #dd9e0c;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayScholarBooks > div .french .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #e7c26a; }
      .OverlayScholarBooks > div .french .exercices-table table tbody tr td:nth-child(3) .done {
        color: #e7c26a; }
    .OverlayScholarBooks > div .autre .closeModal {
      background-color: #80c9ca; }
    .OverlayScholarBooks > div .autre .header {
      background-color: #80c9ca; }
    .OverlayScholarBooks > div .autre .textbook-period ul.list-period > li > a {
      color: #80c9ca;
      border: 2px solid #80c9ca; }
    .OverlayScholarBooks > div .autre .textbook-period ul.list-period > li > ul {
      border: 2px solid #80c9ca; }
      .OverlayScholarBooks > div .autre .textbook-period ul.list-period > li > ul li {
        color: #80c9ca; }
    .OverlayScholarBooks > div .autre .textbook-period .searched_name {
      border: 2px solid #80c9ca; }
    .OverlayScholarBooks > div .autre .textbook-period.bibliotheque > ul li.active a {
      background: #80c9ca;
      color: white; }
    .OverlayScholarBooks > div .autre .todoCompleted div {
      border-color: #80c9ca; }
      .OverlayScholarBooks > div .autre .todoCompleted div a {
        color: #80c9ca; }
        .OverlayScholarBooks > div .autre .todoCompleted div a.active {
          background-color: #80c9ca; }
    .OverlayScholarBooks > div .autre .exercices-table table {
      border-collapse: unset; }
      .OverlayScholarBooks > div .autre .exercices-table table tbody tr td.border_txt {
        border: none; }
      .OverlayScholarBooks > div .autre .exercices-table table tbody tr td.datefor {
        background: #80c9ca;
        min-height: 35px; }
      .OverlayScholarBooks > div .autre .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayScholarBooks > div .autre .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #80c9ca;
        background: #19abad;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayScholarBooks > div .autre .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #80c9ca; }
      .OverlayScholarBooks > div .autre .exercices-table table tbody tr td:nth-child(3) .done {
        color: #80c9ca; }
    .OverlayScholarBooks > div .arabic .closeModal {
      background-color: #7cb9de; }
    .OverlayScholarBooks > div .arabic .header {
      background-color: #7cb9de; }
    .OverlayScholarBooks > div .arabic .textbook-period ul.list-period > li > a {
      color: #7cb9de;
      border: 2px solid #7cb9de; }
    .OverlayScholarBooks > div .arabic .textbook-period ul.list-period > li > ul {
      border: 2px solid #7cb9de; }
      .OverlayScholarBooks > div .arabic .textbook-period ul.list-period > li > ul li {
        color: #7cb9de; }
    .OverlayScholarBooks > div .arabic .textbook-period .searched_name {
      border: 2px solid #7cb9de; }
    .OverlayScholarBooks > div .arabic .textbook-period.bibliotheque > ul li.active a {
      background: #7cb9de;
      color: white; }
    .OverlayScholarBooks > div .arabic .todoCompleted div {
      border-color: #7cb9de; }
      .OverlayScholarBooks > div .arabic .todoCompleted div a {
        color: #7cb9de; }
        .OverlayScholarBooks > div .arabic .todoCompleted div a.active {
          background-color: #7cb9de; }
    .OverlayScholarBooks > div .arabic .exercices-table table {
      border-collapse: unset; }
      .OverlayScholarBooks > div .arabic .exercices-table table tbody tr.border_txt {
        border: none; }
      .OverlayScholarBooks > div .arabic .exercices-table table tbody tr td.datefor {
        background: #7cb9de;
        min-height: 35px; }
      .OverlayScholarBooks > div .arabic .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayScholarBooks > div .arabic .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #7cb9de;
        background: #1f7db8;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayScholarBooks > div .arabic .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #7cb9de; }
      .OverlayScholarBooks > div .arabic .exercices-table table tbody tr td:nth-child(3) .done {
        color: #7cb9de; }
    .OverlayScholarBooks > div .anglais .closeModal {
      background-color: #e7c26a; }
    .OverlayScholarBooks > div .anglais .header {
      background-color: #e7c26a; }
    .OverlayScholarBooks > div .anglais .textbook-period ul.list-period > li > a {
      color: #e7c26a;
      border: 2px solid #e7c26a; }
    .OverlayScholarBooks > div .anglais .textbook-period ul.list-period > li > ul {
      border: 2px solid #e7c26a; }
      .OverlayScholarBooks > div .anglais .textbook-period ul.list-period > li > ul li {
        color: #e7c26a; }
    .OverlayScholarBooks > div .anglais .textbook-period .searched_name {
      border: 2px solid #e7c26a; }
    .OverlayScholarBooks > div .anglais .textbook-period.bibliotheque > ul li.active a {
      background: #e7c26a;
      color: white; }
    .OverlayScholarBooks > div .anglais .todoCompleted div {
      border-color: #e7c26a; }
      .OverlayScholarBooks > div .anglais .todoCompleted div a {
        color: #e7c26a; }
        .OverlayScholarBooks > div .anglais .todoCompleted div a.active {
          background-color: #e7c26a; }
    .OverlayScholarBooks > div .anglais .exercices-table table {
      border-collapse: unset; }
      .OverlayScholarBooks > div .anglais .exercices-table table tbody tr.border_txt {
        border: none; }
      .OverlayScholarBooks > div .anglais .exercices-table table tbody tr td.datefor {
        background: #e7c26a;
        min-height: 35px; }
      .OverlayScholarBooks > div .anglais .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayScholarBooks > div .anglais .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #e7c26a;
        background: #c5931f;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayScholarBooks > div .anglais .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #e7c26a; }
      .OverlayScholarBooks > div .anglais .exercices-table table tbody tr td:nth-child(3) .done {
        color: #e7c26a; }
    .OverlayScholarBooks > div .science .closeModal {
      background-color: #81b7ad; }
    .OverlayScholarBooks > div .science .header {
      background-color: #81b7ad; }
    .OverlayScholarBooks > div .science .textbook-period ul.list-period > li > a {
      color: #81b7ad;
      border: 2px solid #81b7ad; }
    .OverlayScholarBooks > div .science .textbook-period ul.list-period > li > ul {
      border: 2px solid #81b7ad; }
      .OverlayScholarBooks > div .science .textbook-period ul.list-period > li > ul li {
        color: #81b7ad; }
    .OverlayScholarBooks > div .science .textbook-period .searched_name {
      border: 2px solid #81b7ad; }
    .OverlayScholarBooks > div .science .textbook-period.bibliotheque > ul li.active a {
      background: #81b7ad;
      color: white; }
    .OverlayScholarBooks > div .science .todoCompleted div {
      border-color: #81b7ad; }
      .OverlayScholarBooks > div .science .todoCompleted div a {
        color: #81b7ad; }
        .OverlayScholarBooks > div .science .todoCompleted div a.active {
          background-color: #81b7ad; }
    .OverlayScholarBooks > div .science .exercices-table table {
      border-collapse: unset; }
      .OverlayScholarBooks > div .science .exercices-table table tbody tr.border_txt {
        border: none; }
      .OverlayScholarBooks > div .science .exercices-table table tbody tr td.datefor {
        background: #81b7ad;
        min-height: 35px; }
      .OverlayScholarBooks > div .science .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayScholarBooks > div .science .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #81b7ad;
        background: #3c9787;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayScholarBooks > div .science .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #81b7ad; }
      .OverlayScholarBooks > div .science .exercices-table table tbody tr td:nth-child(3) .done {
        color: #81b7ad; }
    .OverlayScholarBooks > div .géographie .closeModal {
      background-color: #81b7ad; }
    .OverlayScholarBooks > div .géographie .header {
      background-color: #81b7ad; }
    .OverlayScholarBooks > div .géographie .textbook-period ul.list-period > li > a {
      color: #81b7ad;
      border: 2px solid #81b7ad; }
    .OverlayScholarBooks > div .géographie .textbook-period ul.list-period > li > ul {
      border: 2px solid #81b7ad; }
      .OverlayScholarBooks > div .géographie .textbook-period ul.list-period > li > ul li {
        color: #81b7ad; }
    .OverlayScholarBooks > div .géographie .textbook-period .searched_name {
      border: 2px solid #81b7ad; }
    .OverlayScholarBooks > div .géographie .textbook-period.bibliotheque > ul li.active a {
      background: #81b7ad;
      color: white; }
    .OverlayScholarBooks > div .géographie .todoCompleted div {
      border-color: #81b7ad; }
      .OverlayScholarBooks > div .géographie .todoCompleted div a {
        color: #81b7ad; }
        .OverlayScholarBooks > div .géographie .todoCompleted div a.active {
          background-color: #81b7ad; }
    .OverlayScholarBooks > div .géographie .exercices-table table {
      border-collapse: unset; }
      .OverlayScholarBooks > div .géographie .exercices-table table tbody tr.border_txt {
        border: none; }
      .OverlayScholarBooks > div .géographie .exercices-table table tbody tr td.datefor {
        background: #81b7ad;
        min-height: 35px; }
      .OverlayScholarBooks > div .géographie .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayScholarBooks > div .géographie .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #81b7ad;
        background: #37a08d;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayScholarBooks > div .géographie .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #81b7ad; }
      .OverlayScholarBooks > div .géographie .exercices-table table tbody tr td:nth-child(3) .done {
        color: #81b7ad; }
    .OverlayScholarBooks > div .histoire .closeModal {
      background-color: #bf9592; }
    .OverlayScholarBooks > div .histoire .header {
      background-color: #bf9592; }
    .OverlayScholarBooks > div .histoire .textbook-period ul.list-period > li > a {
      color: #bf9592;
      border: 2px solid #bf9592; }
    .OverlayScholarBooks > div .histoire .textbook-period ul.list-period > li > ul {
      border: 2px solid #bf9592; }
      .OverlayScholarBooks > div .histoire .textbook-period ul.list-period > li > ul li {
        color: #bf9592; }
    .OverlayScholarBooks > div .histoire .textbook-period .searched_name {
      border: 2px solid #bf9592; }
    .OverlayScholarBooks > div .histoire .textbook-period.bibliotheque > ul li.active a {
      background: #bf9592;
      color: white; }
    .OverlayScholarBooks > div .histoire .todoCompleted div {
      border-color: #bf9592; }
      .OverlayScholarBooks > div .histoire .todoCompleted div a {
        color: #bf9592; }
        .OverlayScholarBooks > div .histoire .todoCompleted div a.active {
          background-color: #bf9592; }
    .OverlayScholarBooks > div .histoire .exercices-table table {
      border-collapse: unset; }
      .OverlayScholarBooks > div .histoire .exercices-table table tbody tr.border_txt {
        border: none; }
      .OverlayScholarBooks > div .histoire .exercices-table table tbody tr td.datefor {
        background: #bf3938;
        min-height: 35px; }
      .OverlayScholarBooks > div .histoire .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayScholarBooks > div .histoire .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #bf3938;
        background: #661212;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayScholarBooks > div .histoire .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #bf9592; }
      .OverlayScholarBooks > div .histoire .exercices-table table tbody tr td:nth-child(3) .done {
        color: #bf9592; }
    .OverlayScholarBooks > div .sciences .closeModal {
      background-color: #80b0a0; }
    .OverlayScholarBooks > div .sciences .header {
      background-color: #80b0a0; }
    .OverlayScholarBooks > div .sciences .textbook-period ul.list-period > li > a {
      color: #80b0a0;
      border: 2px solid #80b0a0; }
    .OverlayScholarBooks > div .sciences .textbook-period ul.list-period > li > ul {
      border: 2px solid #80b0a0; }
      .OverlayScholarBooks > div .sciences .textbook-period ul.list-period > li > ul li {
        color: #80b0a0; }
    .OverlayScholarBooks > div .sciences .textbook-period .searched_name {
      border: 2px solid #80b0a0; }
    .OverlayScholarBooks > div .sciences .textbook-period.bibliotheque > ul li.active a {
      background: #80b0a0;
      color: white; }
    .OverlayScholarBooks > div .sciences .todoCompleted div {
      border-color: #80b0a0; }
      .OverlayScholarBooks > div .sciences .todoCompleted div a {
        color: #80b0a0; }
        .OverlayScholarBooks > div .sciences .todoCompleted div a.active {
          background-color: #80b0a0; }
    .OverlayScholarBooks > div .sciences .exercices-table table {
      border-collapse: unset; }
      .OverlayScholarBooks > div .sciences .exercices-table table tbody tr.border_txt {
        border: none; }
      .OverlayScholarBooks > div .sciences .exercices-table table tbody tr td.datefor {
        background: #80b0a0;
        min-height: 35px; }
      .OverlayScholarBooks > div .sciences .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayScholarBooks > div .sciences .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #80b0a0;
        background: #2c8f6e;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayScholarBooks > div .sciences .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #80b0a0; }
      .OverlayScholarBooks > div .sciences .exercices-table table tbody tr td:nth-child(3) .done {
        color: #80b0a0; }
    .OverlayScholarBooks > div .arabe .closeModal {
      background-color: #7abade; }
    .OverlayScholarBooks > div .arabe .header {
      background-color: #7abade; }
    .OverlayScholarBooks > div .arabe .textbook-period ul.list-period > li > a {
      color: #7abade;
      border: 2px solid #7abade; }
    .OverlayScholarBooks > div .arabe .textbook-period ul.list-period > li > ul {
      border: 2px solid #7abade; }
      .OverlayScholarBooks > div .arabe .textbook-period ul.list-period > li > ul li {
        color: #7abade; }
    .OverlayScholarBooks > div .arabe .textbook-period .searched_name {
      border: 2px solid #7abade; }
    .OverlayScholarBooks > div .arabe .textbook-period.bibliotheque > ul li.active a {
      background: #7abade;
      color: white; }
    .OverlayScholarBooks > div .arabe .todoCompleted div {
      border-color: #7abade; }
      .OverlayScholarBooks > div .arabe .todoCompleted div a {
        color: #7abade; }
        .OverlayScholarBooks > div .arabe .todoCompleted div a.active {
          background-color: #7abade; }
    .OverlayScholarBooks > div .arabe .exercices-table table {
      border-collapse: unset; }
      .OverlayScholarBooks > div .arabe .exercices-table table tbody tr.border_txt {
        border: none; }
      .OverlayScholarBooks > div .arabe .exercices-table table tbody tr td.datefor {
        background: #7abade;
        min-height: 35px; }
      .OverlayScholarBooks > div .arabe .exercices-table table tbody tr td.next_txtb {
        min-height: 36px; }
      .OverlayScholarBooks > div .arabe .exercices-table table tbody tr td.next_txtb_extra {
        border: 1px solid #7abade;
        background: #2f739b;
        border-radius: 27px;
        padding: 10px !important;
        color: white !important;
        font-size: 12px;
        font-weight: bold;
        align-items: center;
        text-align: center; }
      .OverlayScholarBooks > div .arabe .exercices-table table tbody tr td:nth-child(3) .visit {
        background-color: #7abade; }
      .OverlayScholarBooks > div .arabe .exercices-table table tbody tr td:nth-child(3) .done {
        color: #7abade; }

.OverlayViewScholarBook {
  background-color: rgba(100, 115, 140, 0.9);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999; }
  .OverlayViewScholarBook > div {
    top: 0px !important;
    left: 0% !important;
    margin: 0%;
    max-width: 100%;
    width: 100%;
    height: 100%;
    bottom: auto !important;
    padding: 0 0 0px !important;
    border: none !important;
    border-radius: 10px !important;
    overflow: inherit !important;
    box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
    opacity: 0.9; }
    .OverlayViewScholarBook > div > div {
      height: 100%;
      display: flex;
      flex-direction: column; }
      .OverlayViewScholarBook > div > div.modal_view_scholar_book {
        z-index: 1;
        position: relative; }
    .OverlayViewScholarBook > div .closeModal {
      position: absolute;
      top: 0px;
      right: 0px;
      border: 2px solid #fff;
      width: 24px;
      height: 24px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 12px;
      line-height: 18px; }
      .OverlayViewScholarBook > div .closeModal img {
        max-width: 13px; }
    .OverlayViewScholarBook > div .header {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 0;
      width: 100%;
      float: left; }
      .OverlayViewScholarBook > div .header .slick-list {
        height: 50px; }
      .OverlayViewScholarBook > div .header .slick-dots {
        display: none !important; }
    .OverlayViewScholarBook > div .book_view {
      height: calc(100%);
      margin: 0; }
      .OverlayViewScholarBook > div .book_view > iframe {
        height: 100%;
        width: 100%; }

.ReactModal__Body--open {
  overflow-y: hidden !important; }

.OverlayDaillyChallenge {
  background-color: rgba(100, 115, 140, 0.9);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: initial !important;
  z-index: 99999; }
  .OverlayDaillyChallenge.overlayreclamation {
    z-index: 1000; }
    .OverlayDaillyChallenge.overlayreclamation .react-datepicker-wrapper {
      margin-left: 0px !important; }
    .OverlayDaillyChallenge.overlayreclamation > div {
      padding: 25px 25px !important; }
    .OverlayDaillyChallenge.overlayreclamation textarea {
      margin-bottom: 10px !important; }
    .OverlayDaillyChallenge.overlayreclamation .classe_radio {
      margin-top: 10px; }
      .OverlayDaillyChallenge.overlayreclamation .classe_radio .state label:before {
        top: 0; }
      .OverlayDaillyChallenge.overlayreclamation .classe_radio .state label:after {
        top: 0; }
  .OverlayDaillyChallenge.modalSchedule {
    z-index: 100000; }
  .OverlayDaillyChallenge.modaljournacard {
    z-index: 1000; }
    .OverlayDaillyChallenge.modaljournacard .all_journals {
      width: calc(100% - 16px);
      margin: 8px;
      overflow: auto;
      height: calc(100% - 16px); }
      .OverlayDaillyChallenge.modaljournacard .all_journals::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
        background-color: #ffffff; }
      .OverlayDaillyChallenge.modaljournacard .all_journals::-webkit-scrollbar {
        width: 5px;
        background-color: #ffffff; }
      .OverlayDaillyChallenge.modaljournacard .all_journals::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
    .OverlayDaillyChallenge.modaljournacard .journalbody {
      padding: 0% 1% 1%; }
      .OverlayDaillyChallenge.modaljournacard .journalbody > div.journal-details {
        height: 100%;
        display: flex;
        justify-content: space-around;
        font-size: 15px;
        font-weight: bolder; }
        .OverlayDaillyChallenge.modaljournacard .journalbody > div.journal-details > div.journal-attach {
          width: 50%; }
          @media (max-width: 767px) {
            .OverlayDaillyChallenge.modaljournacard .journalbody > div.journal-details > div.journal-attach {
              width: 100%; } }
          .OverlayDaillyChallenge.modaljournacard .journalbody > div.journal-details > div.journal-attach .exercise {
            display: flex;
            -webkit-box-pack: justify;
            justify-content: space-between;
            margin-bottom: 15px;
            box-shadow: 5px 5px 15px -2px;
            line-height: 27px;
            visibility: visible !important;
            opacity: 1 !important;
            border-width: 1px;
            border-style: solid;
            border-color: #b0becc;
            border-image: initial;
            padding: 5px;
            border-radius: 10px;
            margin-right: 10px; }
        .OverlayDaillyChallenge.modaljournacard .journalbody > div.journal-details > div.journal-note {
          width: 50%;
          float: right;
          height: 100%;
          border-left: 1px solid #ccc; }
          @media (max-width: 767px) {
            .OverlayDaillyChallenge.modaljournacard .journalbody > div.journal-details > div.journal-note {
              width: 100%;
              border: none; } }
          .OverlayDaillyChallenge.modaljournacard .journalbody > div.journal-details > div.journal-note .note {
            padding: .75em;
            margin: .75em;
            width: auto;
            background: #ffe677;
            color: #333;
            position: relative; }
        .OverlayDaillyChallenge.modaljournacard .journalbody > div.journal-details > div.journal-obj {
          float: left;
          position: relative;
          width: 50%;
          margin-left: 3%; }
        .OverlayDaillyChallenge.modaljournacard .journalbody > div.journal-details > div.journal-mtr {
          float: right;
          position: relative;
          width: 50%;
          margin-left: 3%; }
        .OverlayDaillyChallenge.modaljournacard .journalbody > div.journal-details > div.journal-mob {
          float: right;
          position: relative;
          width: 100%;
          margin-left: 5%; }
        .OverlayDaillyChallenge.modaljournacard .journalbody > div.journal-details > div.note {
          background: #ffe677;
          color: #333;
          position: relative; }
        .OverlayDaillyChallenge.modaljournacard .journalbody > div.journal-details > span.sous-details {
          font-weight: lighter; }
    @media screen and (max-width: 767px) {
      .OverlayDaillyChallenge.modaljournacard .journal-to-scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
        background-color: #ffffff; }
      .OverlayDaillyChallenge.modaljournacard .journal-to-scroll::-webkit-scrollbar {
        width: 5px;
        background-color: #ffffff; }
      .OverlayDaillyChallenge.modaljournacard .journal-to-scroll::-webkit-scrollbar-thumb {
        background-color: #ffffff; } }
    @media screen and (min-width: 767px) {
      .OverlayDaillyChallenge.modaljournacard .journal-to-scroll::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5; }
      .OverlayDaillyChallenge.modaljournacard .journal-to-scroll::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5; }
      .OverlayDaillyChallenge.modaljournacard .journal-to-scroll::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; } }
    .OverlayDaillyChallenge.modaljournacard .journal-to-scroll .rtl p {
      text-align: right; }
    .OverlayDaillyChallenge.modaljournacard .journal-card {
      margin: 0px 30px;
      background: white; }
    .OverlayDaillyChallenge.modaljournacard .jounaltop {
      display: flex;
      background-color: #e4e8ef;
      min-height: 50px;
      font-weight: bold;
      border-top-right-radius: 25px;
      border-top-left-radius: 17px;
      margin-bottom: 30px; }
      .OverlayDaillyChallenge.modaljournacard .jounaltop > span {
        margin: 5px; }
        .OverlayDaillyChallenge.modaljournacard .jounaltop > span img {
          width: 60px; }
        .OverlayDaillyChallenge.modaljournacard .jounaltop > span.journal-date {
          margin-left: 25px;
          margin-top: 13px; }
          .OverlayDaillyChallenge.modaljournacard .jounaltop > span.journal-date h3 {
            font-size: 20px;
            font-family: sans-serif;
            font-weight: bold; }
          .OverlayDaillyChallenge.modaljournacard .jounaltop > span.journal-date h6 {
            font-size: 12px; }
      @media (max-width: 767px) {
        .OverlayDaillyChallenge.modaljournacard .jounaltop {
          background-color: #e4e8ef;
          border-radius: 0px;
          min-width: 767px; }
          .OverlayDaillyChallenge.modaljournacard .jounaltop > span {
            margin: 5px; }
            .OverlayDaillyChallenge.modaljournacard .jounaltop > span img {
              width: 0px; }
            .OverlayDaillyChallenge.modaljournacard .jounaltop > span.journal-date {
              margin-left: 5px; }
              .OverlayDaillyChallenge.modaljournacard .jounaltop > span.journal-date h3 {
                font-size: 11px;
                font-family: sans-serif; }
              .OverlayDaillyChallenge.modaljournacard .jounaltop > span.journal-date h6 {
                font-size: 10px; } }
    .OverlayDaillyChallenge.modaljournacard .notetop {
      display: flex;
      background: beige;
      min-height: 50px;
      font-weight: bold;
      border-top-right-radius: 25px;
      border-top-left-radius: 25px; }
      .OverlayDaillyChallenge.modaljournacard .notetop > span {
        margin: 0px; }
        .OverlayDaillyChallenge.modaljournacard .notetop > span img {
          width: 60px; }
        .OverlayDaillyChallenge.modaljournacard .notetop > span.journal-date {
          margin-left: 25px;
          margin-top: 13px; }
          .OverlayDaillyChallenge.modaljournacard .notetop > span.journal-date h3 {
            font-size: 20px;
            font-family: sans-serif;
            font-weight: bold; }
          .OverlayDaillyChallenge.modaljournacard .notetop > span.journal-date h6 {
            font-size: 12px; }
      @media (max-width: 767px) {
        .OverlayDaillyChallenge.modaljournacard .notetop {
          background-color: white; }
          .OverlayDaillyChallenge.modaljournacard .notetop > span {
            margin: 5px;
            padding: 0px !important; }
            .OverlayDaillyChallenge.modaljournacard .notetop > span img {
              width: 0px; }
            .OverlayDaillyChallenge.modaljournacard .notetop > span.journal-date {
              margin-left: 5px; }
              .OverlayDaillyChallenge.modaljournacard .notetop > span.journal-date h3 {
                font-size: 11px;
                font-family: sans-serif; }
              .OverlayDaillyChallenge.modaljournacard .notetop > span.journal-date h6 {
                font-size: 10px; } }
    .OverlayDaillyChallenge.modaljournacard .bloc-notee {
      margin-top: 5px; }
      .OverlayDaillyChallenge.modaljournacard .bloc-notee .delete-basket {
        position: absolute;
        top: -0.375em;
        right: .75em;
        cursor: pointer;
        box-shadow: -6px -4px 0 0 rgba(0, 0, 0, 0.1);
        background: red;
        color: #fff;
        display: inline-block;
        /* background-color: #666; */
        line-height: 28px;
        width: 28px;
        font-size: 16px;
        border-radius: 14px;
        -webkit-border-radius: 14px;
        text-align: center;
        margin-right: .375em; }
      .OverlayDaillyChallenge.modaljournacard .bloc-notee .note {
        background: #ffe677;
        color: #333;
        position: relative;
        width: 80%;
        margin: 1.5em auto;
        padding: 20px; }
        @media (max-width: 767px) {
          .OverlayDaillyChallenge.modaljournacard .bloc-notee .note {
            background: #ffe677;
            color: #333;
            position: relative;
            width: 100%;
            margin: auto;
            padding: 20px; } }
      .OverlayDaillyChallenge.modaljournacard .bloc-notee > i .pin {
        background: transparent url("../img/pin.png");
        position: absolute;
        width: 32px;
        height: 32px;
        left: 48%;
        top: -16px;
        z-index: 1; }
    .OverlayDaillyChallenge.modaljournacard .bloc-note {
      height: 340px;
      overflow-y: scroll; }
      .OverlayDaillyChallenge.modaljournacard .bloc-note .delete-basket {
        position: absolute;
        top: -0.375em;
        right: .75em;
        cursor: pointer;
        box-shadow: -6px -4px 0 0 rgba(0, 0, 0, 0.1);
        background: red;
        color: #fff;
        display: inline-block;
        /* background-color: #666; */
        line-height: 28px;
        width: 28px;
        font-size: 16px;
        border-radius: 14px;
        -webkit-border-radius: 14px;
        text-align: center;
        margin-right: .375em; }
      .OverlayDaillyChallenge.modaljournacard .bloc-note .note {
        background: #ffe677;
        color: #333;
        position: relative;
        width: 80%;
        margin: 1.5em auto;
        padding: 20px; }
        @media (max-width: 1199px) {
          .OverlayDaillyChallenge.modaljournacard .bloc-note .note {
            width: 100%; } }
      .OverlayDaillyChallenge.modaljournacard .bloc-note > i .pin {
        background: transparent url("../img/pin.png");
        position: absolute;
        width: 32px;
        height: 32px;
        left: 48%;
        top: -16px;
        z-index: 1; }
    .OverlayDaillyChallenge.modaljournacard span.button.primary.new-note {
      display: inline-block;
      min-width: 130px;
      height: 30px;
      cursor: pointer;
      /* float: right; */
      /* object-fit: contain; */
      border-radius: 49.5px;
      box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
      color: #fff;
      border: none;
      text-align: center;
      font-family: Montserrat;
      font-size: 12px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 25px;
      letter-spacing: normal;
      /* margin: 70px; */
      padding: 2px; }
  .OverlayDaillyChallenge.OverlayAvailableProfessors > div {
    height: 80%;
    top: 10%; }
    .OverlayDaillyChallenge.OverlayAvailableProfessors > div > div {
      height: calc(100% - 50px);
      overflow-y: auto;
      margin-top: 25px; }
      .OverlayDaillyChallenge.OverlayAvailableProfessors > div > div::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5; }
      .OverlayDaillyChallenge.OverlayAvailableProfessors > div > div::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5; }
      .OverlayDaillyChallenge.OverlayAvailableProfessors > div > div::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
      .OverlayDaillyChallenge.OverlayAvailableProfessors > div > div .list_professors {
        padding: 0 25px;
        display: flex;
        flex-wrap: wrap; }
    @media (max-width: 1199px) {
      .OverlayDaillyChallenge.OverlayAvailableProfessors > div {
        height: 100%;
        width: 100%;
        top: 0% !important;
        max-width: 100% !important;
        left: 0 !important;
        transform: unset !important;
        margin-left: 0 !important;
        border-radius: 0 !important; }
        .OverlayDaillyChallenge.OverlayAvailableProfessors > div .closeModal {
          top: -6px;
          right: -6px; } }
  .OverlayDaillyChallenge.OverlayOpenCourseMobile {
    background-color: rgba(100, 115, 140, 0.9); }
    .OverlayDaillyChallenge.OverlayOpenCourseMobile > div {
      box-shadow: 0 6px 36px 0 rgba(85, 86, 87, 0.23);
      max-width: 340px;
      margin-left: -170px;
      top: 50% !important;
      margin-top: -70px !important;
      right: 0 !important;
      overflow: initial !important;
      height: 140px;
      border-radius: 140px !important; }
      .OverlayDaillyChallenge.OverlayOpenCourseMobile > div .openCourse-left {
        width: 170px;
        height: 160px;
        float: left;
        margin-top: -27px;
        margin-left: -25px;
        margin-right: -8px; }
        .OverlayDaillyChallenge.OverlayOpenCourseMobile > div .openCourse-left img {
          width: 170px; }
      .OverlayDaillyChallenge.OverlayOpenCourseMobile > div .openCourse-right {
        margin-top: 12px; }
        .OverlayDaillyChallenge.OverlayOpenCourseMobile > div .openCourse-right span {
          width: 160px;
          height: 40px;
          opacity: 0.9;
          border-radius: 40px;
          border: solid 1.3px #f2985a;
          font-family: "Montserrat", sans-serif;
          font-size: 11px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 1px;
          text-align: center;
          color: #f2985a;
          text-align: center;
          line-height: 38px;
          float: left;
          margin-top: 12px; }
          .OverlayDaillyChallenge.OverlayOpenCourseMobile > div .openCourse-right span.course {
            background: #f2985a;
            color: #fff; }
  .OverlayDaillyChallenge.OverlayChangePassword > div {
    max-width: 258px;
    margin-left: -129px;
    border-radius: 6px;
    opacity: 0.9;
    box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.18);
    background-color: #ffffff;
    opacity: 1; }
    .OverlayDaillyChallenge.OverlayChangePassword > div .closeModal {
      line-height: 29px; }
    .OverlayDaillyChallenge.OverlayChangePassword > div .header {
      background-color: #46bfb6;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      height: 39px;
      line-height: 39px; }
      .OverlayDaillyChallenge.OverlayChangePassword > div .header h3 {
        font-size: 11px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;
        margin: 0;
        line-height: 40px; }
    .OverlayDaillyChallenge.OverlayChangePassword > div .content {
      padding: 25px 25px 17px; }
      .OverlayDaillyChallenge.OverlayChangePassword > div .content h3 {
        font-size: 10px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #f27247;
        margin: 0; }
      .OverlayDaillyChallenge.OverlayChangePassword > div .content input {
        width: 100%;
        height: 17px;
        margin-bottom: 25px;
        border: none;
        background: transparent;
        border-bottom: solid 1px #e6e6e6; }
      .OverlayDaillyChallenge.OverlayChangePassword > div .content .textErrors {
        margin-top: -15px;
        color: #ef0707;
        font-size: 10px;
        margin-bottom: 15px; }
      .OverlayDaillyChallenge.OverlayChangePassword > div .content .password-submit {
        text-align: center; }
        .OverlayDaillyChallenge.OverlayChangePassword > div .content .password-submit button {
          width: 110px;
          height: 27px;
          opacity: 0.9;
          border-radius: 13.5px;
          background-color: #46bfb6;
          text-align: center;
          line-height: 27px;
          opacity: 0.9;
          font-family: Montserrat;
          font-size: 11px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #ffffff;
          border: none; }
  .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div {
    max-width: 782px;
    margin-left: -391px;
    opacity: 1;
    border-radius: 0 !important;
    border-bottom-left-radius: 14px !important;
    border-bottom-right-radius: 14px !important;
    box-shadow: 0 18px 36px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff; }
    .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .header {
      height: 55px;
      border-radius: 0 !important; }
      .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .header .header-back {
        display: none; }
      .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .header h3 {
        font-size: 15px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1px;
        color: #ffffff;
        line-height: 55px; }
    .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .content {
      text-align: center;
      padding-bottom: 30px;
      background: #fcfcfc; }
      .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .content .choose-avatar {
        display: inline-block;
        position: relative;
        overflow: hidden;
        height: 40px;
        border-radius: 20.3px;
        border: 1px solid #fea625;
        margin-top: 11px; }
        .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .content .choose-avatar a {
          font-family: "Montserrat", sans-serif;
          font-size: 10px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.8px;
          padding: 12.5px 25px;
          display: inline-block;
          position: relative;
          border-radius: 27px;
          overflow: hidden;
          color: #fea625; }
          .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .content .choose-avatar a.active {
            color: #ffffff;
            background: #fea625; }
            .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .content .choose-avatar a.active:before {
              content: "";
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              bottom: 0;
              border-radius: 31px;
              border: 2px solid #fff; }
      .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .content .choose-image {
        width: 100%;
        float: left;
        position: relative;
        height: 300px; }
      .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .content .choose-apply {
        text-align: center;
        margin-top: 40px; }
        .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .content .choose-apply a {
          border-radius: 16.7px;
          box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
          background-color: #3eb6ad;
          font-family: "Montserrat", sans-serif;
          font-size: 12px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.6px;
          text-align: center;
          color: #ffffff;
          padding: 9px 55px; }
      .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .content .upload {
        margin-top: 40px; }
        .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .content .upload p {
          font-family: "Montserrat", sans-serif;
          font-size: 9px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          color: #888888; }
          .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .content .upload p b {
            font-size: 11px;
            margin-top: 9px;
            display: block;
            margin-bottom: 6px; }
          .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .content .upload p i {
            color: #ccc;
            font-size: 80px; }
          .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .content .upload p a {
            font-family: "Montserrat", sans-serif;
            font-size: 15px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.8px;
            color: #3eb6ad;
            border-bottom: 2px solid #3eb6ad; }
        .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .content .upload .choose-apply {
          margin-top: 80px; }
  .OverlayDaillyChallenge.OverlayTextbookExercice > div {
    max-width: 880px;
    padding: 0 !important;
    margin-left: -440px;
    top: 55px !important;
    display: flex;
    flex-direction: column; }
    @media (min-width: 768px) {
      .OverlayDaillyChallenge.OverlayTextbookExercice > div {
        height: 80%; } }
    .OverlayDaillyChallenge.OverlayTextbookExercice > div .header-exercice {
      height: 40px;
      background-color: #3eb6ad;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      position: relative;
      display: flex;
      align-items: center; }
      .OverlayDaillyChallenge.OverlayTextbookExercice > div .header-exercice h3 {
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin: 0;
        text-align: center;
        flex: 1;
        display: flex;
        align-items: center; }
      .OverlayDaillyChallenge.OverlayTextbookExercice > div .header-exercice button:not(.btn-back) {
        position: absolute;
        top: 5px;
        left: 5px;
        height: 30px;
        width: 30px; }
    .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice {
      padding: 20px 10px;
      background: #fafbfe;
      float: left;
      width: 100%;
      height: 100%;
      overflow: auto; }
      .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-scroll {
        overflow-y: auto; }
        .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-scroll::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5; }
        .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-scroll::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5; }
        .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-scroll::-webkit-scrollbar-thumb {
          background-color: #3eb6ad; }
      .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width {
        padding: 0 10px;
        float: left;
        width: 25%;
        margin-bottom: 20px; }
        .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width .bloc-content-exercice {
          object-fit: contain;
          border-radius: 4px;
          box-shadow: 0 0 13px -1px rgba(46, 104, 208, 0.1);
          background-color: #ffffff;
          text-align: center;
          min-height: 198px;
          padding: 20px 10px 5px;
          position: relative; }
          .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width .bloc-content-exercice.bloc-content-exercice-complete {
            border: solid 1px #3eb6ad; }
            .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width .bloc-content-exercice.bloc-content-exercice-complete .exercice-active {
              display: block; }
          .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width .bloc-content-exercice.bloc-content-exercice-lock {
            object-fit: contain;
            mix-blend-mode: luminosity; }
          .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width .bloc-content-exercice .exercice-active {
            position: absolute;
            right: 5px;
            top: 5px;
            width: 27px;
            height: 27px;
            border-radius: 27px;
            background-color: #3eb6ad;
            text-align: center;
            display: none; }
            .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width .bloc-content-exercice .exercice-active i {
              color: #fff;
              font-size: 17px;
              line-height: 27px; }
          .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width .bloc-content-exercice .exercice-img img {
            width: 90px;
            height: 90px;
            margin: auto; }
          .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width .bloc-content-exercice h4 {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: center;
            color: #1b2437;
            height: 36px;
            margin: 8px 0 10px; }
          .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width .bloc-content-exercice p {
            margin: 0; }
            .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width .bloc-content-exercice p span {
              font-family: "Montserrat", sans-serif;
              font-size: 13px;
              font-weight: normal;
              font-style: normal;
              font-stretch: normal;
              letter-spacing: 0.5px;
              text-align: center;
              line-height: 27px; }
              .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width .bloc-content-exercice p span.complete {
                color: #1b2437; }
              .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width .bloc-content-exercice p span.play {
                color: #ffffff;
                width: 92px;
                height: 27px;
                text-align: center;
                background: #3eb6ad;
                display: inline-block;
                border-radius: 40.7px;
                line-height: 27px;
                cursor: pointer; }
                .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width .bloc-content-exercice p span.play.percent_1 {
                  background: #dc3545; }
                .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width .bloc-content-exercice p span.play.percent_2 {
                  background: #ffc107;
                  color: #212529; }
              .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width .bloc-content-exercice p span.lock {
                color: #ffffff;
                width: 92px;
                height: 27px;
                text-align: center;
                display: inline-block;
                border-radius: 40.7px;
                background: #3eb6ad; }
  .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div {
    max-width: 100%;
    left: 40px !important;
    margin-left: 0;
    top: 5% !important;
    height: 90%; }
    .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .header-exercice h3 {
      display: flex;
      justify-content: space-around; }
      .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .header-exercice h3 .actions {
        padding: 0 15px; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .header-exercice h3 .actions span {
          margin: 0 5px;
          background: #808080b5;
          padding: 5px;
          border-radius: 15px;
          color: white;
          cursor: pointer;
          min-width: 100px; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .header-exercice h3 .actions span.active {
            color: #3eb6ad;
            background: white; }
    .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises {
      height: calc(100% - 40px);
      overflow-y: auto;
      padding: 15px;
      background: #f8f9fc;
      border-radius: 8px; }
      .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight {
        height: 100%;
        border-width: 1px;
        border-style: solid;
        border-color: #b1bdcb;
        border-image: initial;
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 8px;
        overflow: auto; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #F5F5F5; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight::-webkit-scrollbar {
          width: 5px;
          height: 10px; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
          border-radius: 8px; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .student_name {
          width: 250px;
          display: flex;
          justify-content: center;
          flex-direction: column;
          min-width: 200px; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesHeader {
          display: flex;
          justify-content: space-evenly;
          border-bottom: 1px solid #b1bdcb;
          background: #e7eaef;
          width: max-content;
          min-width: 100%; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesHeader .items_header {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            flex: 1;
            text-align: center; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesHeader .items_header .type {
              border-bottom: 1px solid #b1bdcb; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesHeader .items_header .type {
              padding: 5px; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesHeader .items_header .items > div {
              padding: 5px;
              position: relative;
              min-width: 100px; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesHeader .items_header .items > div .post_type_icon {
                background-image: url("../img/iconProfessor.svg");
                background-size: contain;
                position: absolute;
                right: 25px;
                height: 20px;
                width: 24px;
                background-repeat: no-repeat;
                display: flex;
                flex-direction: column;
                justify-content: center;
                color: yellow;
                cursor: pointer; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesHeader .items_header .items > div .down_up {
                position: absolute;
                right: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center; }
                .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesHeader .items_header .items > div .down_up i {
                  cursor: pointer;
                  font-weight: bold;
                  color: black; }
                  .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesHeader .items_header .items > div .down_up i.deactivated {
                    color: gray;
                    cursor: not-allowed; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesHeader .items_header .items_moy {
              display: flex; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesHeader .items_header .items_moy .moy {
                width: 100px;
                padding-right: 16px; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesHeader .items_header .items_moy .items {
                flex: 1; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesHeader .student_name {
            text-align: center;
            border-right: 1px solid #b1bdcb;
            min-width: 200px; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesHeader > div {
            float: left;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 600;
            color: rgba(27, 36, 55, 0.8);
            text-align: center;
            line-height: 18px; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .items {
          display: flex;
          justify-content: flex-start; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .items > div {
            min-width: 100px;
            border-right: 1px solid #b1bdcb;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            text-align: center;
            position: relative; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .items > div:last-of-type {
              border-right: 0; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .items .post_type_icon {
            background-image: url("../img/iconProfessor.svg");
            background-size: contain;
            position: absolute;
            right: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 20px;
            width: 24px;
            background-repeat: no-repeat;
            color: yellow;
            cursor: pointer; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling {
          height: calc(100% - 58px);
          min-width: 100%;
          width: max-content;
          display: flex;
          flex-direction: column; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_exercisesRow {
            display: flex;
            float: left;
            min-width: 100%;
            width: max-content;
            height: 77px;
            background-color: white;
            position: relative;
            border-radius: 0px;
            border: 0px;
            border-bottom: 1px solid #b1bdcb; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_exercisesRow .student_name {
              height: 75px;
              display: flex;
              border-right: 1px solid #b1bdcb;
              padding: 13px 13px;
              align-items: center;
              flex-direction: row;
              width: 250px;
              justify-content: start;
              background: #e7eaef;
              min-width: 200px; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_exercisesRow .student_name img {
                width: 48px;
                height: 48px;
                float: left;
                margin-right: 10px;
                border-radius: 50%; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_exercisesRow .student_name h3 {
                font-size: 14px;
                font-weight: 500;
                color: rgba(27, 36, 55, 0.8);
                text-align: left;
                line-height: 18px;
                text-overflow: ellipsis;
                margin-top: 5px;
                overflow: hidden;
                max-height: 100%; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_exercisesRow .items {
              flex: 1; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_exercisesRow .items span {
                max-width: 92px;
                width: max-content;
                min-width: 57px;
                margin: auto;
                color: #ffffff;
                height: 27px;
                display: inline-block;
                padding: 0.25em 0.6em;
                font-size: 75%;
                font-weight: 700;
                text-align: center;
                white-space: nowrap;
                vertical-align: baseline;
                border-radius: 40.7px;
                line-height: 23px; }
                .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_exercisesRow .items span.date_completion {
                  position: absolute;
                  background: transparent !important;
                  color: black;
                  left: 50%;
                  bottom: 0;
                  transform: translateX(-50%);
                  font-size: 9px;
                  height: 20px;
                  line-height: 14px; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_exercisesRow .items .gray span {
                background-color: #ccc; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_exercisesRow .items .bad span {
                background-color: #dc3545; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_exercisesRow .items .average span {
                background-color: #ffc107; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_exercisesRow .items .good span {
                background-color: #3eb6ad; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_exercisesRow .moy {
              width: 89px; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_competencesRow {
            display: flex;
            float: left;
            width: 100%;
            height: 77px;
            background-color: white;
            position: relative;
            border-radius: 0px;
            border: 0px;
            border-bottom: 1px solid #b1bdcb; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_competencesRow .student_name {
              height: 75px;
              display: flex;
              border-right: 1px solid #b1bdcb;
              padding: 13px 13px;
              align-items: center;
              flex-direction: row;
              width: 250px;
              justify-content: start;
              background: #e7eaef;
              min-width: 200px; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_competencesRow .student_name img {
                width: 48px;
                height: 48px;
                float: left;
                margin-right: 10px;
                border-radius: 50%; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_competencesRow .student_name h3 {
                font-size: 14px;
                font-weight: 500;
                color: rgba(27, 36, 55, 0.8);
                text-align: left;
                line-height: 18px;
                text-overflow: ellipsis;
                margin-top: 5px;
                overflow: hidden;
                max-height: 100%; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_competencesRow .items {
              flex: 1; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_competencesRow .items span {
                width: 92px;
                margin: auto;
                color: #ffffff;
                height: 27px;
                display: inline-block;
                padding: 0.25em 0.4em;
                font-size: 75%;
                font-weight: 700;
                text-align: center;
                white-space: nowrap;
                vertical-align: baseline;
                border-radius: 40.7px;
                line-height: 23px; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_competencesRow .items .gray span {
                background-color: #ccc; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_competencesRow .items .bad span {
                background-color: #dc3545; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_competencesRow .items .average span {
                background-color: #ffc107; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_competencesRow .items .good span {
                background-color: #3eb6ad; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .students_exercisesScrolling .students_competencesRow .moy {
              width: 89px; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div .students_exercises .students_exercisesRight .moy {
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center; }
    @media screen and (max-width: 768px) {
      .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsTaskByExercises > div {
        left: 0 !important;
        top: 0 !important;
        height: 100%; } }
  .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div {
    max-width: 100%;
    left: 40px !important;
    margin-left: 0;
    top: 5% !important;
    height: 90%; }
    .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .header-exercice h3 {
      display: flex;
      justify-content: space-around; }
      .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .header-exercice h3 .actions {
        padding: 0 15px; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .header-exercice h3 .actions span {
          margin: 0 5px;
          background: #808080b5;
          padding: 5px;
          border-radius: 15px;
          color: white;
          cursor: pointer;
          min-width: 100px; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .header-exercice h3 .actions span.active {
            color: #3eb6ad;
            background: white; }
    .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view {
      height: calc(100% - 40px);
      overflow-y: auto;
      padding: 15px;
      background: #f8f9fc;
      border-radius: 8px; }
      .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight {
        height: 100%;
        border-width: 1px;
        border-style: solid;
        border-color: #b1bdcb;
        border-image: initial;
        background-color: rgba(255, 255, 255, 0.3);
        border-radius: 8px;
        overflow-y: auto; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight::-webkit-scrollbar {
          width: 5px; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
          border-radius: 8px; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .student_name {
          width: 15%;
          display: flex;
          justify-content: center;
          flex-direction: column; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesHeader {
          display: flex;
          justify-content: space-evenly;
          border-bottom: 1px solid #b1bdcb;
          background: #e7eaef;
          width: max-content;
          min-width: 100%; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesHeader .items_header {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            flex: 1;
            text-align: center; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesHeader .items_header .type {
              border-bottom: 1px solid #b1bdcb; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesHeader .items_header .type {
              padding: 5px; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesHeader .items_header .items > div {
              padding: 5px;
              position: relative; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesHeader .items_header .items > div .down_up {
                position: absolute;
                right: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                opacity: 1; }
                .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesHeader .items_header .items > div .down_up i {
                  cursor: pointer;
                  font-weight: bold;
                  color: black; }
                  .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesHeader .items_header .items > div .down_up i.deactivated {
                    color: gray;
                    cursor: not-allowed; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesHeader .items_header .items_moy {
              display: flex; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesHeader .items_header .items_moy .moy {
                width: 100px;
                padding-right: 16px; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesHeader .items_header .items_moy .items {
                flex: 1; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesHeader .student_name {
            text-align: center;
            border-right: 1px solid #b1bdcb; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesHeader > div {
            float: left;
            font-family: Montserrat;
            font-size: 14px;
            font-weight: 600;
            color: rgba(27, 36, 55, 0.8);
            text-align: center;
            line-height: 18px; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .items {
          display: flex;
          justify-content: flex-start; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .items > div {
            border-right: 1px solid #b1bdcb;
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1;
            text-align: center;
            position: relative;
            min-width: 100px; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .items > div:last-of-type {
              border-right: 0; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling {
          height: calc(100% - 58px);
          min-width: 100%;
          width: max-content;
          display: flex;
          flex-direction: column; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_exercisesRow {
            display: flex;
            float: left;
            min-width: 100%;
            width: max-content;
            height: 77px;
            background-color: white;
            position: relative;
            border-radius: 0px;
            border: 0px;
            border-bottom: 1px solid #b1bdcb; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_exercisesRow .student_name {
              height: 75px;
              display: flex;
              border-right: 1px solid #b1bdcb;
              padding: 13px 13px;
              align-items: center;
              flex-direction: row;
              width: calc(15%);
              justify-content: start;
              background: #e7eaef; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_exercisesRow .student_name img {
                width: 48px;
                height: 48px;
                float: left;
                margin-right: 10px;
                border-radius: 50%; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_exercisesRow .student_name h3 {
                font-size: 14px;
                font-weight: 500;
                color: rgba(27, 36, 55, 0.8);
                text-align: left;
                line-height: 18px;
                text-overflow: ellipsis;
                margin-top: 5px;
                overflow: hidden;
                max-height: 100%; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_exercisesRow .items {
              flex: 1; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_exercisesRow .items span {
                max-width: 92px;
                width: max-content;
                min-width: 57px;
                margin: auto;
                color: #000000;
                height: 27px;
                display: inline-block;
                padding: 0.25em 0.6em;
                font-size: 75%;
                font-weight: 700;
                text-align: center;
                white-space: nowrap;
                vertical-align: baseline;
                border-radius: 40.7px;
                line-height: 23px; }
                .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_exercisesRow .items span.date_completion {
                  position: absolute;
                  background: transparent !important;
                  color: black;
                  left: 50%;
                  bottom: 0;
                  transform: translateX(-50%);
                  font-size: 9px;
                  height: 20px;
                  line-height: 14px; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_exercisesRow .items .gray span {
                background-color: #ccc; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_exercisesRow .items .bad span {
                background-color: #dc3545; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_exercisesRow .items .average span {
                background-color: #ffc107; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_exercisesRow .items .good span {
                background-color: #3eb6ad; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_exercisesRow .moy {
              width: 89px; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_competencesRow {
            display: flex;
            float: left;
            width: 100%;
            height: 77px;
            background-color: white;
            position: relative;
            border-radius: 0px;
            border: 0px;
            border-bottom: 1px solid #b1bdcb; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_competencesRow .student_name {
              height: 75px;
              display: flex;
              border-right: 1px solid #b1bdcb;
              padding: 13px 13px;
              align-items: center;
              flex-direction: row;
              width: calc(15%);
              justify-content: start;
              background: #e7eaef; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_competencesRow .student_name img {
                width: 48px;
                height: 48px;
                float: left;
                margin-right: 10px;
                border-radius: 50%; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_competencesRow .student_name h3 {
                font-size: 14px;
                font-weight: 500;
                color: rgba(27, 36, 55, 0.8);
                text-align: left;
                line-height: 18px;
                text-overflow: ellipsis;
                margin-top: 5px;
                overflow: hidden;
                max-height: 100%; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_competencesRow .items {
              flex: 1; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_competencesRow .items span {
                width: 92px;
                margin: auto;
                color: #ffffff;
                height: 27px;
                display: inline-block;
                padding: 0.25em 0.4em;
                font-size: 75%;
                font-weight: 700;
                text-align: center;
                white-space: nowrap;
                vertical-align: baseline;
                border-radius: 40.7px;
                line-height: 23px; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_competencesRow .items .gray span {
                background-color: #ccc; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_competencesRow .items .bad span {
                background-color: #dc3545; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_competencesRow .items .average span {
                background-color: #ffc107; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_competencesRow .items .good span {
                background-color: #3eb6ad; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .students_exercisesScrolling .students_competencesRow .moy {
              width: 89px; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse > div .students_course_view .students_course_viewRight .moy {
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center; }
  .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide > div {
    width: 50%;
    left: 25% !important; }
    .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide > div .student_name {
      width: 30% !important; }
    .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide > div .students_exercisesRow {
      height: 40px !important; }
      .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide > div .students_exercisesRow .student_name {
        height: 40px !important;
        border-bottom: 1px solid #b1bdcb; }
    .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide > div .students_exercisesHeader {
      height: 60px !important;
      width: max-content;
      min-width: 100%; }
      .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide > div .students_exercisesHeader .items_header {
        justify-content: center !important; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide > div .students_exercisesHeader .items_header .type {
          border-bottom: 0 !important;
          height: unset; }
  .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide .header-exercice .student_name {
    height: 40px;
    display: flex;
    padding: 13px 13px;
    align-items: center;
    flex-direction: row;
    width: max-content !important;
    justify-content: start; }
    .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide .header-exercice .student_name img {
      width: 34px;
      height: 34px;
      float: left;
      margin-right: 10px;
      border-radius: 50%; }
    .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide .header-exercice .student_name h3 {
      margin-right: 5px; }
  .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide .students_exercisesHeader {
    width: max-content;
    min-width: 100%; }
    .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide .students_exercisesHeader .student_name {
      position: relative; }
      .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide .students_exercisesHeader .student_name .down_up {
        position: absolute;
        right: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        opacity: 1; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide .students_exercisesHeader .student_name .down_up i {
          cursor: pointer;
          font-weight: bold;
          color: black; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide .students_exercisesHeader .student_name .down_up i.deactivated {
            color: gray;
            cursor: not-allowed; }
    .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide .students_exercisesHeader .items_header .type {
      height: 100%; }
  .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide .students_exercisesRow .student_name {
    text-align: center;
    justify-content: center !important; }
  .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide.all > div {
    width: calc(100% - 80px);
    left: 40px !important; }
    .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide.all > div .student_name {
      width: 50% !important; }
    .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide.all > div .students_exercisesScrolling {
      border: 1px solid #b1bdcb; }
    .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide.all > div .students_course_viewRight > div {
      display: flex;
      justify-content: space-between; }
      .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide.all > div .students_course_viewRight > div .students_exercisesScrolling {
        flex: 1; }
    .OverlayDaillyChallenge.OverlayTextbookExercice.OverlyStatsCourse.studentSlide.all > div .header-exercice .student_name {
      width: max-content !important; }
  .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div {
    max-width: 336px;
    margin-left: -168px !important; }
    .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .header-exercice {
      background: #fda801;
      height: 40px; }
    .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .closeModal {
      background: #fda801; }
    .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content {
      padding: 20px 30px 0; }
      .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content p {
        font-family: "Montserrat";
        font-size: 11px;
        color: #cf112d; }
      .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content form {
        width: 100%;
        float: left; }
      .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .addFriend {
        float: left;
        position: relative;
        width: 277px;
        margin-top: 10px;
        margin-bottom: 30px; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .addFriend input {
          width: 100%;
          height: 44.6px;
          border-radius: 22.3px;
          border: solid 2px #dbdbdb;
          background-color: #f4f5f6;
          font-family: "Montserrat", sans-serif;
          font-size: 13px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #000;
          padding-left: 23px; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .addFriend input::placeholder {
            color: #000;
            opacity: 1; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .addFriend input:-ms-input-placeholder {
            color: #000; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .addFriend input::-ms-input-placeholder {
            color: #000; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .addFriend button {
          position: absolute;
          right: 5px;
          top: 5px;
          bottom: 5px;
          border: none;
          background: transparent;
          font-size: 25px;
          color: #dbdbdb;
          font-weight: bold;
          background: #fda701;
          border-radius: 44px;
          max-width: 34px !important;
          max-height: 34px !important; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .addFriend button img {
            width: 22px; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .addFriend button i {
            color: #fff; }
      .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level {
        float: left;
        width: 100%;
        margin-bottom: 25px;
        position: relative; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level.bloc-level-lock {
          object-fit: contain;
          mix-blend-mode: luminosity; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level.bloc-level-lock .bloc-level-left {
            color: #999; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level.bloc-level-lock .lock {
            position: absolute;
            width: 22px;
            height: 22px;
            right: -2px;
            top: -9px;
            border-radius: 22px;
            border: solid 1px #ffffff;
            background-color: #ececec;
            text-align: center; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level.bloc-level-lock .lock i {
              color: #999;
              font-size: 15px;
              line-height: 20px; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level.bloc-level-lock .progress-level span {
            background: #ffe7b9 !important; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level .bloc-level-left {
          float: left;
          width: 49px;
          height: 49px;
          border-radius: 49px;
          border: solid 5px #ffd88c;
          position: relative;
          z-index: 9; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level .bloc-level-left span {
            float: left;
            height: 39px;
            width: 39px;
            border: solid 1px #ffffff;
            background: #fda801;
            border-radius: 43px;
            font-family: "Montserrat", sans-serif;
            font-size: 9px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 1;
            letter-spacing: normal;
            color: #ffcb65;
            text-align: center;
            line-height: 36px; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level .bloc-level-left span b {
              font-size: 13px;
              color: #fff;
              font-weight: 600; }
        .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level .bloc-level-right {
          height: 49px;
          border-radius: 9px;
          background-color: #ffffff;
          width: calc(100% - 39px);
          margin-left: -10px;
          float: left;
          padding: 10px 15px 10px 30px; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level .bloc-level-right h5 {
            font-family: "Montserrat", sans-serif;
            font-size: 11px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #737272;
            margin: 0 0 5px; }
          .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level .bloc-level-right .progress-level {
            border-radius: 26px; }
            .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level .bloc-level-right .progress-level span {
              width: 24px;
              height: 9px;
              float: left;
              border-right: 1px solid #fff; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level .bloc-level-right .progress-level span:nth-child(1) {
                background: #fff1d5;
                border-top-left-radius: 26px;
                border-bottom-left-radius: 26px; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level .bloc-level-right .progress-level span:nth-child(2) {
                background: #ffe7b9; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level .bloc-level-right .progress-level span:nth-child(3) {
                background: #ffdd99; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level .bloc-level-right .progress-level span:nth-child(4) {
                background: #ffd582; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level .bloc-level-right .progress-level span:nth-child(5) {
                background: #ffcc68; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level .bloc-level-right .progress-level span:nth-child(6) {
                background: #ffbe3f; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level .bloc-level-right .progress-level span:nth-child(7) {
                background: #ffb625; }
              .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .content .bloc-level .bloc-level-right .progress-level span:nth-child(8) {
                background: #fda800;
                border-right: none;
                border-top-right-radius: 26px;
                border-bottom-right-radius: 26px; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div {
    border-radius: 20px !important;
    padding: 50px 15px 15px 15px !important;
    /*overflow-y: auto !important;
        height: calc(100vh - 80px) ;
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }
  
        &::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5;
        }
  
        &::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
        }*/ }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div.cardViewjouranl {
      padding: 0px !important; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .friends-bloc {
      text-align: center; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .closeModal {
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
      color: #fff;
      font-size: 40px;
      line-height: 40px;
      text-align: center;
      border: none; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlaySchoolNew > div {
    max-width: 550px;
    margin-left: -275px; }
    @media screen and (max-width: 767px) {
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlaySchoolNew > div {
        margin-left: 0px; } }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity > div {
    top: 50% !important;
    transform: translateY(-50%) !important; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity label {
    margin-bottom: 10px; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .select_image {
    text-align: left;
    width: 100%;
    padding-top: 5px;
    padding-right: 5px;
    position: relative; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .select_image span:not(.file_name) {
      color: white;
      background: #3EB6AD;
      font-weight: bold;
      border-radius: 15px;
      padding: 5px 10px;
      cursor: pointer;
      margin-right: 5px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .select_image span.file_name {
      position: absolute;
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      margin-top: 5px;
      color: gray; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .select_image input {
      visibility: hidden;
      width: 0px;
      padding: 0; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .select_image img {
      position: absolute;
      max-width: 70px;
      max-height: 70px;
      right: 0;
      top: -20px; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .activities_types .RadioLegend {
    width: 25%; }
    @media (max-width: 767px) {
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .activities_types .RadioLegend {
        width: 50%; } }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div {
    max-width: 90%;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    margin: auto;
    left: 0px !important;
    /*padding: 20px 40px 20px !important;*/
    background: #fff; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader {
      /*float: left;*/
      width: 100%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .onBack {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        width: 35px;
        height: 35px;
        border-radius: 100px;
        box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
        text-align: center;
        color: #fff;
        font-size: 30px;
        line-height: 35px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader h2 {
        opacity: 0.8;
        font-family: "Montserrat";
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #1b2437;
        margin: 0; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search {
        width: 228px;
        float: left;
        height: 28px;
        border-radius: 19px;
        border: solid 1px #b1bfcd;
        background-color: #f8f8f8;
        position: relative; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search i {
          position: absolute;
          right: 9px;
          font-size: 15px;
          top: 6px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search input {
          background: transparent;
          border: none;
          height: 26px;
          width: 100%;
          padding: 0 0 0 13px;
          font-size: 12px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter > .select {
        width: 170px !important; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight {
        float: right;
        width: calc(100% - 524px); }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight select {
          width: 100px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight div {
          float: left;
          margin-left: 20px;
          opacity: 0.8;
          font-family: "Montserrat", sans-serif;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight div img {
            max-height: 19px;
            margin-right: 3px;
            float: left;
            margin-top: 6px;
            max-width: 19px; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight div i {
            vertical-align: top; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight > div {
          width: calc(33.33% - 48.33px);
          margin-left: 5px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .select {
        float: left;
        width: max-content;
        height: 29px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        position: relative;
        padding-left: 3px;
        margin-left: 0 !important;
        width: calc(100% - 22px); }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .select select {
          float: left;
          width: 100%;
          font-size: 12px;
          height: 28px;
          border: none;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          text-indent: 1px;
          text-overflow: "";
          background: transparent;
          outline: 0;
          position: relative;
          z-index: 9;
          border-radius: 4px !important;
          border: none !important;
          font-weight: 500;
          font-family: Montserrat;
          color: #1b2437;
          padding: 0 5px;
          padding-right: 23px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .select i {
          color: #d2dbe2;
          font-size: 14px;
          position: absolute;
          right: 5px;
          top: 6px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter.students {
        position: relative;
        height: 20px;
        margin-top: 10px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter.students .search {
          left: 50%;
          position: absolute;
          transform: translateX(-50%); }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent {
      float: left;
      width: calc(100% + 20px);
      padding: 20px 0 0;
      height: calc(100% - 70px); }
      @media screen and (max-width: 1200px) {
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent {
          height: calc(100% - 185px); } }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view {
        height: 100%;
        display: flex;
        flex-direction: column; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view {
          height: 100%; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view .open_question_exercise .response_question .question {
            max-height: 50%; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view .open_question_exercise .response_question .question.h_100 {
              max-height: 100%; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view .open_question_exercise .response_question .question > p {
              height: 95%;
              overflow-y: scroll;
              padding: 15px; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view .open_question_v2_exercise .response_question .question {
            height: calc(100% - 44px); }
            .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view .open_question_v2_exercise .response_question .question > p {
              height: 95%;
              overflow-y: scroll;
              padding: 15px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .view-xblock {
        height: 100%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .loading-spinner {
        height: 100% !important;
        top: 0px !important; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item {
        /*display: flex;
                  flex-direction: column;*/
        height: 100%;
        overflow-y: auto; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item > div:not(.RadioLegend) {
          min-height: 100%;
          position: relative; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #F5F5F5; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item::-webkit-scrollbar {
          width: 5px;
          height: 3px;
          background-color: #F5F5F5; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item::-webkit-scrollbar-thumb {
          background-color: #3eb6ad; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task {
          padding: 15px;
          display: flex;
          flex-wrap: wrap;
          justify-content: start; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std {
            width: 128px !important;
            height: 160px !important;
            padding: 8px 9px !important; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std .img img {
              width: 110px !important; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std.selected {
              opacity: 1 !important; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item legend {
          display: flex;
          align-items: center;
          margin: 0px; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item legend img {
            height: 30px;
            margin-right: 5px; }
        @media screen and (max-width: 360px) {
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item {
            padding-right: 0; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std {
              width: 47% !important; } }
        @media screen and (max-width: 500px) and (min-width: 361px) {
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item {
            padding-right: 0; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std {
              width: 47% !important; } }
        @media screen and (max-width: 640px) and (min-width: 501px) {
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item {
            padding-right: 0; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std {
              width: 31% !important; } }
        @media screen and (max-width: 767px) and (min-width: 641px) {
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item {
            padding-right: 0; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std {
              width: 31% !important; } }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend {
        /*width: calc(33.33% - 20px);
                  float: left;*/
        display: flex;
        align-items: center;
        margin: 0px 10px 15px 0px;
        background: #fff;
        height: 60px;
        object-fit: contain;
        border-radius: 8px;
        box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
        /*padding: 9px;*/ }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend.selected {
          background: #cbefef; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > div {
          padding: 0;
          float: left;
          -webkit-flex: none;
          flex: none;
          display: initial;
          position: relative; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > div.badge {
            height: 20px;
            width: 20px;
            line-height: 20px;
            border-radius: 50%;
            margin: 0 10px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > span {
          float: left;
          /*margin: 10px 4px 0 0;*/ }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > span svg {
          color: #b7c3d0; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > span[class*="checked"] svg {
          color: #00b3a9; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > legend {
          /*float: left;*/
          margin-left: 0;
          opacity: 0.8;
          font-family: "Montserrat";
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          border: none;
          /*width: calc(100% - 52px);
                    padding-top: 3px;*/ }
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > legend img {
            float: left;
            margin-right: 6px;
            /*margin-top: 5px;*/ }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent.students .exercise_tree_item > div:not(.RadioLegend) {
        min-height: inherit !important; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceBottom {
      /*position: absolute;
                left: 0;
                right: 0;
                bottom: 20px;
                text-align: center;*/ }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceBottom > div {
        margin-top: 10px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceBottom button {
        display: inline-block;
        min-width: 106px;
        height: 30px;
        object-fit: contain;
        border-radius: 49.5px;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
        background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
        color: #fff;
        border: none;
        margin: 0 7px; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice.OverlayAddCourse > div .exerciceContent .student_view .left-tree-menu .view-tree-container {
    width: 0%; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice.OverlayAddCourse > div .exerciceContent .student_view .left-tree-menu .view-tree-container.open {
      width: 90px; }
  @media screen and (max-width: 360px) {
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice.OverlayAddCourse > div .exerciceContent .exercise_tree_item {
      padding-right: 0; } }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice.OverlayAddCourse > div .exerciceContent .RadioLegend > span {
    float: left;
    /*margin: 10px 4px 0 0;*/ }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice.OverlayAddCourse > div .exerciceContent .RadioLegend > legend img {
    width: 30px;
    height: 30px;
    border-radius: 50%; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div {
    max-width: 600px;
    margin-left: -300px;
    padding: 25px 25px 0 !important; }
    @media (max-width: 1199px) {
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div {
        max-width: 100%;
        margin: auto;
        top: 0px !important;
        right: 0px !important;
        left: 0px !important;
        bottom: 0px !important;
        padding: 30px 45px 0 !important;
        min-height: 100vh;
        border-radius: 0 !important; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div .closeModal {
          background: #d8dfe5;
          box-shadow: none;
          top: 15px;
          right: 15px; } }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div h2 {
      text-align: left;
      font-size: 18px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div .add-school-form {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 150px);
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0px 10px;
      float: none; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div .add-school-form::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div .add-school-form::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div .add-school-form::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div .school-ligne .fileUpload {
      display: inline-block;
      min-width: 105px;
      height: 30px;
      object-fit: contain;
      border-radius: 49.5px;
      text-align: center;
      font-family: Montserrat;
      line-height: 30px;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: -webkit-linear-gradient(54deg, #3eb6ad, #72dcd6);
      background-image: -webkit-linear-gradient(36deg, #3eb6ad, #72dcd6);
      background-image: linear-gradient(54deg, #3eb6ad, #72dcd6);
      color: #fff;
      border: none;
      float: left;
      margin-bottom: 0px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div .school-ligne .fileUpload input {
        max-width: 95px;
        visibility: hidden;
        position: absolute;
        width: auto;
        z-index: 999; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div .school-ligne .fileUpload i {
        margin-right: 3px;
        font-size: 15px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div .school-ligne .or {
      font-size: 13px;
      color: #1b2437;
      font-weight: 500;
      margin: 7px 9px 0; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div .school-ligne .inputLink {
      max-width: 170px;
      position: relative;
      float: left; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div .school-ligne .inputLink i {
        position: absolute;
        left: 8px;
        top: 8px;
        color: #b2c0cf; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div .school-ligne .inputLink input {
        font-weight: 600;
        padding-left: 30px;
        height: 30px;
        color: #626875; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div .school-ligne label .add {
      height: 30px;
      border: 1px solid #e7ecf0;
      background: #fcfcfd;
      border-radius: 2px;
      display: inline-block;
      text-align: center;
      font-size: 13px;
      line-height: 24px;
      font-weight: 500;
      color: #1b2437; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.added_overlayreclamation > div {
    padding: 25px 25px !important; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.addStudentActivityTask > div {
    padding-bottom: 20px !important; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork .field_required {
    color: red; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork .school-ligne-center {
    text-align: center; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayAnnualReviews table {
    border-color: #cccccc;
    width: 100%; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayAnnualReviews table tr th {
      padding: 5px 10px;
      text-align: center; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayAnnualReviews table tr td {
      padding: 5px 10px;
      text-align: center;
      height: 20px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayAnnualReviews table tr td .pretty {
        margin-right: 0px;
        padding-top: 10px; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayAnnualReviews > div .add-school-form {
    height: max-content !important;
    max-height: calc(100vh - 140px) !important; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayPublipostage > div {
    padding: 25px 25px 25px !important; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayPublipostage > div .add-school-form {
      padding: 0px 20px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayPublipostage > div .add-school-form .school-ligne-w50 {
        padding: 0 !important; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayPublipostage table {
    border-color: #cccccc;
    width: 100%; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayPublipostage table tr th {
      padding: 5px 10px;
      text-align: center; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayPublipostage table tr td {
      padding: 5px 10px;
      text-align: center;
      height: 20px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayPublipostage table tr td .pretty {
        margin-right: 0px;
        padding-top: 10px; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayPublipostage .table {
    width: 100%; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayPublipostage .table.overflow {
      overflow: hidden;
      overflow-x: auto; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayPublipostage .table.overflow::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayPublipostage .table.overflow::-webkit-scrollbar {
        width: 5px;
        height: 5px;
        background-color: #f5f5f5; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayPublipostage .table.overflow::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayPublipostage > div .add-school-form {
    height: max-content !important;
    max-height: calc(100vh - 140px) !important; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form {
    margin-top: 25px;
    float: left;
    height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 15px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form::-webkit-scrollbar-thumb {
      background-color: #3eb6ad; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne {
      margin-bottom: 15px;
      width: 100%;
      float: left; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne .intl-tel-input {
        width: 100%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne.school-ligne-center {
        margin: 20px 0; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne .newClassesTop {
        float: left;
        width: 100%;
        margin-bottom: 25px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne .newClassesTop img {
          max-width: 55px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne .newClassesTop span {
          width: 115px;
          height: 30px;
          text-align: center;
          object-fit: contain;
          border-radius: 49.5px;
          background-color: #3eb6ad;
          font-family: Montserrat;
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 30px;
          letter-spacing: normal;
          color: rgba(255, 255, 255, 0.8);
          display: inline-block;
          margin-left: 10px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne button.add {
        display: inline-block;
        min-width: 106px;
        height: 30px;
        object-fit: contain;
        border-radius: 49.5px;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
        background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
        color: #fff;
        border: none;
        margin: 0 7px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne button.add.cancel {
          background: #fff;
          color: #3eb6ad;
          box-shadow: none;
          border: solid 0.6px #d1dbe2; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne button.add.newStudents {
          width: 155px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne .label_select_students {
        width: 100%;
        display: inline-block; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne .RadioLegend {
        float: left;
        margin-right: 25px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne .RadioLegend:last-child {
          margin-right: 0; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne .RadioLegend > span {
          padding: 0;
          float: left;
          flex: none;
          display: initial; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne .RadioLegend > legend {
          float: left;
          margin: 0;
          font-family: Montserrat;
          font-size: 13px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          margin-left: 5px;
          margin-top: 4px;
          display: inline-block;
          border: none;
          width: auto; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne textarea {
        float: left;
        width: 100%;
        height: 50px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        resize: none; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne .select {
        float: left;
        width: 100%;
        height: 26.5px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        position: relative; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne .select select {
          float: left;
          width: 100%;
          font-size: 12px;
          height: 25.5px;
          border: none;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          text-indent: 1px;
          text-overflow: "";
          background: transparent;
          outline: 0;
          position: relative;
          z-index: 9;
          border-radius: 4px !important;
          border: none !important;
          font-weight: 500;
          font-family: Montserrat;
          color: #1b2437;
          padding: 0 10px; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne .select select::-ms-expand {
            display: none; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne .select i {
          color: #d2dbe2;
          font-size: 16px;
          position: absolute;
          right: 5px;
          top: 5px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne label {
        display: block;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1b2437;
        margin-bottom: 3px;
        text-align: left; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne input {
        float: left;
        width: 100%;
        height: 26.5px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        font-size: 14px;
        font-weight: 500; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne input:not([type='tel']) {
          padding-left: 10px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne.school-ligne-diploma {
        margin-bottom: 25px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne.school-ligne-margin {
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: 15px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 {
          width: 50%;
          float: left;
          padding: 0 10px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne .error {
        color: red; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne .RadioLegend {
        width: 31%;
        margin: 0 5px 15px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne .RadioLegend.all_classes {
          width: 98%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne .radioLevels .RadioLegend > legend {
        text-transform: uppercase; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents > div .add-school-form .school-ligne.school-ligne-classes {
        border: 2px dotted #d8dfe6;
        margin: 0 -20px 15px;
        width: calc(100% + 40px);
        padding: 10px 20px 0; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div {
    border-radius: 20px !important;
    padding: 0px !important;
    height: 500px;
    /*overflow-y: auto !important;
        height: calc(100vh - 80px) ;
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }
  
        &::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5;
        }
  
        &::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
        }*/ }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #F5F5F5; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div::-webkit-scrollbar {
      width: 5px;
      height: 3px;
      background-color: #F5F5F5; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div::-webkit-scrollbar-thumb {
      background-color: #3eb6ad; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .friends-bloc {
      text-align: center; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .closeModal {
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
      color: #fff;
      font-size: 40px;
      line-height: 40px;
      text-align: center;
      border: none; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlaySchoolNew > div {
    max-width: 550px;
    margin-left: -275px; }
    @media screen and (max-width: 767px) {
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlaySchoolNew > div {
        margin-left: 0px; } }
  .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity > div {
    top: 50% !important;
    transform: translateY(-50%) !important; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity label {
    margin-bottom: 10px; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .select_image {
    text-align: left;
    width: 100%;
    padding-top: 5px;
    padding-right: 5px;
    position: relative; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .select_image span:not(.file_name) {
      color: white;
      background: #3EB6AD;
      font-weight: bold;
      border-radius: 15px;
      padding: 5px 10px;
      cursor: pointer;
      margin-right: 5px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .select_image span.file_name {
      position: absolute;
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      margin-top: 5px;
      color: gray; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .select_image input {
      visibility: hidden;
      width: 0px;
      padding: 0; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .select_image img {
      position: absolute;
      max-width: 70px;
      max-height: 70px;
      right: 0;
      top: -20px; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .activities_types .RadioLegend {
    width: 25%; }
    @media (max-width: 767px) {
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .activities_types .RadioLegend {
        width: 50%; } }
  .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div {
    max-width: 90%;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    margin: auto;
    left: 0px !important;
    /*padding: 20px 40px 20px !important;*/
    background: #fff; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader {
      /*float: left;*/
      width: 100%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .onBack {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        width: 35px;
        height: 35px;
        border-radius: 100px;
        box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
        text-align: center;
        color: #fff;
        font-size: 30px;
        line-height: 35px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader h2 {
        opacity: 0.8;
        font-family: "Montserrat";
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #1b2437;
        margin: 0; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search {
        width: 228px;
        float: left;
        height: 28px;
        border-radius: 19px;
        border: solid 1px #b1bfcd;
        background-color: #f8f8f8;
        position: relative; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search i {
          position: absolute;
          right: 9px;
          font-size: 15px;
          top: 6px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search input {
          background: transparent;
          border: none;
          height: 26px;
          width: 100%;
          padding: 0 0 0 13px;
          font-size: 12px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight {
        float: right; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight select {
          width: 100px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight div {
          float: left;
          margin-left: 40px;
          opacity: 0.8;
          font-family: "Montserrat", sans-serif;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight div img {
            max-height: 19px;
            margin-right: 3px; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight div i {
            vertical-align: top; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter.students {
        position: relative;
        height: 20px;
        margin-top: 10px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter.students .search {
          left: 50%;
          position: absolute;
          transform: translateX(-50%); }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent {
      float: left;
      width: calc(100% + 20px);
      padding: 20px 0 0;
      height: calc(100% - 70px); }
      @media screen and (max-width: 1200px) {
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent {
          height: calc(100% - 185px); } }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view {
        height: 100%;
        display: flex;
        flex-direction: column; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view {
          height: 100%; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view .open_question_exercise .response_question .question {
            max-height: 50%; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view .open_question_exercise .response_question .question.h_100 {
              max-height: 100%; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view .open_question_exercise .response_question .question > p {
              height: 95%;
              overflow-y: scroll;
              padding: 15px; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view .open_question_v2_exercise .response_question .question {
            max-height: 50%; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view .open_question_v2_exercise .response_question .question.h_100 {
              max-height: 100%; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view .open_question_v2_exercise .response_question .question > p {
              height: 95%;
              overflow-y: scroll;
              padding: 15px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .view-xblock {
        height: 100%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .loading-spinner {
        height: 100% !important;
        top: 0px !important; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item {
        /*display: flex;
                  flex-direction: column;*/
        height: 100%;
        overflow-y: auto; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item > div:not(.RadioLegend) {
          min-height: 100%;
          position: relative; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #F5F5F5; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item::-webkit-scrollbar {
          width: 5px;
          height: 3px;
          background-color: #F5F5F5; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item::-webkit-scrollbar-thumb {
          background-color: #3eb6ad; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task {
          padding: 15px;
          display: flex;
          flex-wrap: wrap;
          justify-content: start; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std {
            width: 128px !important;
            height: 160px !important;
            padding: 8px 9px !important; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std .img img {
              width: 110px !important; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std.selected {
              opacity: 1 !important; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item legend {
          display: flex;
          align-items: center;
          margin: 0px; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item legend img {
            height: 30px;
            margin-right: 5px; }
        @media screen and (max-width: 360px) {
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item {
            padding-right: 0; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std {
              width: 47% !important; } }
        @media screen and (max-width: 500px) and (min-width: 361px) {
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item {
            padding-right: 0; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std {
              width: 47% !important; } }
        @media screen and (max-width: 640px) and (min-width: 501px) {
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item {
            padding-right: 0; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std {
              width: 31% !important; } }
        @media screen and (max-width: 767px) and (min-width: 641px) {
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item {
            padding-right: 0; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std {
              width: 31% !important; } }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend {
        /*width: calc(33.33% - 20px);
                  float: left;*/
        display: flex;
        align-items: center;
        margin: 0px 10px 15px 0px;
        background: #fff;
        height: 60px;
        object-fit: contain;
        border-radius: 8px;
        box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
        /*padding: 9px;*/ }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend.selected {
          background: #cbefef; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > div {
          padding: 0;
          float: left;
          -webkit-flex: none;
          flex: none;
          display: initial;
          position: relative; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > div.badge {
            height: 20px;
            width: 20px;
            line-height: 20px;
            border-radius: 50%;
            margin: 0 10px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > span {
          float: left;
          /*margin: 10px 4px 0 0;*/ }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > span svg {
          color: #b7c3d0; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > span[class*="checked"] svg {
          color: #00b3a9; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > legend {
          /*float: left;*/
          margin-left: 0;
          opacity: 0.8;
          font-family: "Montserrat";
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          border: none;
          /*width: calc(100% - 52px);
                    padding-top: 3px;*/ }
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > legend img {
            float: left;
            margin-right: 6px;
            /*margin-top: 5px;*/ }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent.students .exercise_tree_item > div:not(.RadioLegend) {
        min-height: inherit !important; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceBottom {
      /*position: absolute;
                left: 0;
                right: 0;
                bottom: 20px;
                text-align: center;*/ }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceBottom > div {
        margin-top: 10px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceBottom button {
        display: inline-block;
        min-width: 106px;
        height: 30px;
        object-fit: contain;
        border-radius: 49.5px;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
        background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
        color: #fff;
        border: none;
        margin: 0 7px; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div {
    max-width: 600px;
    margin-left: -300px;
    padding: 25px 25px 0 !important; }
    @media (max-width: 1199px) {
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div {
        max-width: 100%;
        margin: auto;
        top: 0px !important;
        right: 0px !important;
        left: 0px !important;
        bottom: 0px !important;
        padding: 30px 45px 0 !important;
        min-height: 100vh;
        border-radius: 0 !important; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div .closeModal {
          background: #d8dfe5;
          box-shadow: none;
          top: 15px;
          right: 15px; } }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div h2 {
      text-align: left;
      font-size: 18px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div .add-school-form {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 150px);
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0px 10px;
      float: none; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div .add-school-form::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div .add-school-form::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div .add-school-form::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div .school-ligne .fileUpload {
      display: inline-block;
      min-width: 105px;
      height: 30px;
      object-fit: contain;
      border-radius: 49.5px;
      text-align: center;
      font-family: Montserrat;
      line-height: 30px;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: -webkit-linear-gradient(54deg, #3eb6ad, #72dcd6);
      background-image: -webkit-linear-gradient(36deg, #3eb6ad, #72dcd6);
      background-image: linear-gradient(54deg, #3eb6ad, #72dcd6);
      color: #fff;
      border: none;
      float: left;
      margin-bottom: 0px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div .school-ligne .fileUpload input {
        max-width: 95px;
        visibility: hidden;
        position: absolute;
        width: auto;
        z-index: 999; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div .school-ligne .fileUpload i {
        margin-right: 3px;
        font-size: 15px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div .school-ligne .or {
      font-size: 13px;
      color: #1b2437;
      font-weight: 500;
      margin: 7px 9px 0; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div .school-ligne .inputLink {
      max-width: 170px;
      position: relative;
      float: left; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div .school-ligne .inputLink i {
        position: absolute;
        left: 8px;
        top: 8px;
        color: #b2c0cf; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div .school-ligne .inputLink input {
        font-weight: 600;
        padding-left: 30px;
        height: 30px;
        color: #626875; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div .school-ligne label .add {
      height: 30px;
      border: 1px solid #e7ecf0;
      background: #fcfcfd;
      border-radius: 2px;
      display: inline-block;
      text-align: center;
      font-size: 13px;
      line-height: 24px;
      font-weight: 500;
      color: #1b2437; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork .field_required {
    color: red; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork .school-ligne-center {
    text-align: center; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form {
    margin-top: 25px;
    float: left;
    height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 15px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form::-webkit-scrollbar-thumb {
      background-color: #3eb6ad; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne {
      margin-bottom: 15px;
      width: 100%;
      float: left; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne .intl-tel-input {
        width: 100%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne.school-ligne-center {
        margin: 20px 0; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne .newClassesTop {
        float: left;
        width: 100%;
        margin-bottom: 25px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne .newClassesTop img {
          max-width: 55px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne .newClassesTop span {
          width: 115px;
          height: 30px;
          text-align: center;
          object-fit: contain;
          border-radius: 49.5px;
          background-color: #3eb6ad;
          font-family: Montserrat;
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 30px;
          letter-spacing: normal;
          color: rgba(255, 255, 255, 0.8);
          display: inline-block;
          margin-left: 10px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne button.add {
        display: inline-block;
        min-width: 106px;
        height: 30px;
        object-fit: contain;
        border-radius: 49.5px;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
        background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
        color: #fff;
        border: none;
        margin: 0 7px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne button.add.cancel {
          background: #fff;
          color: #3eb6ad;
          box-shadow: none;
          border: solid 0.6px #d1dbe2; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne button.add.newStudents {
          width: 155px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne .label_select_students {
        width: 100%;
        display: inline-block; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne .RadioLegend {
        float: left;
        margin-right: 25px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne .RadioLegend:last-child {
          margin-right: 0; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne .RadioLegend > span {
          padding: 0;
          float: left;
          flex: none;
          display: initial; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne .RadioLegend > legend {
          float: left;
          margin: 0;
          font-family: Montserrat;
          font-size: 13px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          margin-left: 5px;
          margin-top: 4px;
          display: inline-block;
          border: none;
          width: auto; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne textarea {
        float: left;
        width: 100%;
        height: 50px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        resize: none; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne .select {
        float: left;
        width: 100%;
        height: 26.5px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        position: relative;
        margin-left: 5px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne .select select {
          float: left;
          width: 100%;
          font-size: 12px;
          height: 25.5px;
          border: none;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          text-indent: 1px;
          text-overflow: "";
          background: transparent;
          outline: 0;
          position: relative;
          z-index: 9;
          border-radius: 4px !important;
          border: none !important;
          font-weight: 500;
          font-family: Montserrat;
          color: #1b2437;
          padding: 0 10px; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne .select select::-ms-expand {
            display: none; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne .select i {
          color: #d2dbe2;
          font-size: 16px;
          position: absolute;
          right: 5px;
          top: 5px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne label {
        display: block;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1b2437;
        margin-bottom: 3px;
        text-align: left; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne input {
        float: left;
        width: 100%;
        height: 26.5px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        font-size: 14px;
        font-weight: 500; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne input:not([type='tel']) {
          padding-left: 10px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne.school-ligne-diploma {
        margin-bottom: 25px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne.school-ligne-margin {
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: 15px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 {
          width: 50%;
          float: left;
          padding: 0 10px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne .error {
        color: red; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne .RadioLegend {
        width: 30%;
        margin: 0 0 15px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne .RadioLegend.all_classes {
          width: 98%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne .radioLevels .RadioLegend > legend {
        text-transform: uppercase; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals > div .add-school-form .school-ligne.school-ligne-classes {
        border: 2px dotted #d8dfe6;
        margin: 0 -20px 15px;
        width: calc(100% + 40px);
        padding: 10px 20px 0; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayJournalsStudent {
    z-index: 10000; }
    @media screen and (max-width: 1199px) {
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournalsStudent > div {
        left: 0 !important;
        right: 0px !important; } }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournalsStudent .closeModal {
      background: #3eb6ad !important; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournalsStudent .jounaltop {
      border-top-left-radius: 22px;
      border-top-right-radius: 22px;
      width: 100%;
      min-width: inherit; }
  .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div {
    border-radius: 20px !important;
    padding: 0px !important;
    height: 80%;
    /*overflow-y: auto !important;
        height: calc(100vh - 80px) ;
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5;
        }
  
        &::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5;
        }
  
        &::-webkit-scrollbar-thumb {
          background-color: #3eb6ad;
        }*/ }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .friends-bloc {
      text-align: center; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .closeModal {
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
      color: #fff;
      font-size: 40px;
      line-height: 40px;
      text-align: center;
      border: none; }
  .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlaySchoolNew > div {
    max-width: 550px;
    margin-left: -275px; }
    @media screen and (max-width: 767px) {
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlaySchoolNew > div {
        margin-left: 0px; } }
  .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity > div {
    top: 50% !important;
    transform: translateY(-50%) !important; }
  .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity label {
    margin-bottom: 10px; }
  .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .select_image {
    text-align: left;
    width: 100%;
    padding-top: 5px;
    padding-right: 5px;
    position: relative; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .select_image span:not(.file_name) {
      color: white;
      background: #3EB6AD;
      font-weight: bold;
      border-radius: 15px;
      padding: 5px 10px;
      cursor: pointer;
      margin-right: 5px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .select_image span.file_name {
      position: absolute;
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 12px;
      margin-top: 5px;
      color: gray; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .select_image input {
      visibility: hidden;
      width: 0px;
      padding: 0; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .select_image img {
      position: absolute;
      max-width: 70px;
      max-height: 70px;
      right: 0;
      top: -20px; }
  .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .activities_types .RadioLegend {
    width: 25%; }
    @media (max-width: 767px) {
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlaySchoolNew.OverlayNewCatActivity .activities_types .RadioLegend {
        width: 50%; } }
  .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div {
    max-width: 90%;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    margin: auto;
    left: 0px !important;
    /*padding: 20px 40px 20px !important;*/
    background: #fff; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader {
      /*float: left;*/
      width: 100%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .onBack {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        width: 35px;
        height: 35px;
        border-radius: 100px;
        box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
        text-align: center;
        color: #fff;
        font-size: 30px;
        line-height: 35px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader h2 {
        opacity: 0.8;
        font-family: "Montserrat";
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #1b2437;
        margin: 0; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search {
        width: 228px;
        float: left;
        height: 28px;
        border-radius: 19px;
        border: solid 1px #b1bfcd;
        background-color: #f8f8f8;
        position: relative; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search i {
          position: absolute;
          right: 9px;
          font-size: 15px;
          top: 6px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search input {
          background: transparent;
          border: none;
          height: 26px;
          width: 100%;
          padding: 0 0 0 13px;
          font-size: 12px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight {
        float: right; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight select {
          width: 100px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight div {
          float: left;
          margin-left: 40px;
          opacity: 0.8;
          font-family: "Montserrat", sans-serif;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437; }
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight div img {
            max-height: 19px;
            margin-right: 3px; }
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight div i {
            vertical-align: top; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter.students {
        position: relative;
        height: 20px;
        margin-top: 10px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter.students .search {
          left: 50%;
          position: absolute;
          transform: translateX(-50%); }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent {
      float: left;
      width: calc(100% + 20px);
      padding: 20px 0 0;
      height: calc(100% - 70px); }
      @media screen and (max-width: 1200px) {
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent {
          height: calc(100% - 185px); } }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view {
        height: 100%;
        display: flex;
        flex-direction: column; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view {
          height: 100%; }
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view .open_question_exercise .response_question .question {
            max-height: 50%; }
            .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view .open_question_exercise .response_question .question.h_100 {
              max-height: 100%; }
            .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view .open_question_exercise .response_question .question > p {
              height: 95%;
              overflow-y: scroll;
              padding: 15px; }
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view .open_question_v2_exercise .response_question .question {
            max-height: 50%; }
            .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view .open_question_v2_exercise .response_question .question.h_100 {
              max-height: 100%; }
            .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .student_view .exercise_content_view .open_question_v2_exercise .response_question .question > p {
              height: 95%;
              overflow-y: scroll;
              padding: 15px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .view-xblock {
        height: 100%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .loading-spinner {
        height: 100% !important;
        top: 0px !important; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item {
        /*display: flex;
                  flex-direction: column;*/
        height: 100%;
        overflow-y: auto; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item > div:not(.RadioLegend) {
          min-height: 100%;
          position: relative; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #F5F5F5; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item::-webkit-scrollbar {
          width: 5px;
          height: 3px;
          background-color: #F5F5F5; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item::-webkit-scrollbar-thumb {
          background-color: #3eb6ad; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task {
          padding: 15px;
          display: flex;
          flex-wrap: wrap;
          justify-content: start; }
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std {
            width: 128px !important;
            height: 160px !important;
            padding: 8px 9px !important; }
            .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std .img img {
              width: 110px !important; }
            .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std.selected {
              opacity: 1 !important; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item legend {
          display: flex;
          align-items: center;
          margin: 0px; }
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item legend img {
            height: 30px;
            margin-right: 5px; }
        @media screen and (max-width: 360px) {
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item {
            padding-right: 0; }
            .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std {
              width: 47% !important; } }
        @media screen and (max-width: 500px) and (min-width: 361px) {
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item {
            padding-right: 0; }
            .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std {
              width: 47% !important; } }
        @media screen and (max-width: 640px) and (min-width: 501px) {
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item {
            padding-right: 0; }
            .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std {
              width: 31% !important; } }
        @media screen and (max-width: 767px) and (min-width: 641px) {
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item {
            padding-right: 0; }
            .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .list_students_for_task .std {
              width: 31% !important; } }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend {
        /*width: calc(33.33% - 20px);
                  float: left;*/
        display: flex;
        align-items: center;
        margin: 0px 10px 15px 0px;
        background: #fff;
        height: 60px;
        object-fit: contain;
        border-radius: 8px;
        box-shadow: 0 5px 45px 0 rgba(46, 104, 208, 0.1);
        /*padding: 9px;*/ }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend.selected {
          background: #cbefef; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > div {
          padding: 0;
          float: left;
          -webkit-flex: none;
          flex: none;
          display: initial;
          position: relative; }
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > div.badge {
            height: 20px;
            width: 20px;
            line-height: 20px;
            border-radius: 50%;
            margin: 0 10px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > span {
          float: left;
          /*margin: 10px 4px 0 0;*/ }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > span svg {
          color: #b7c3d0; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > span[class*="checked"] svg {
          color: #00b3a9; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > legend {
          /*float: left;*/
          margin-left: 0;
          opacity: 0.8;
          font-family: "Montserrat";
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          border: none;
          /*width: calc(100% - 52px);
                    padding-top: 3px;*/ }
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .RadioLegend > legend img {
            float: left;
            margin-right: 6px;
            /*margin-top: 5px;*/ }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent.students .exercise_tree_item > div:not(.RadioLegend) {
        min-height: inherit !important; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceBottom {
      /*position: absolute;
                left: 0;
                right: 0;
                bottom: 20px;
                text-align: center;*/ }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceBottom > div {
        margin-top: 10px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceBottom button {
        display: inline-block;
        min-width: 106px;
        height: 30px;
        object-fit: contain;
        border-radius: 49.5px;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
        background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
        color: #fff;
        border: none;
        margin: 0 7px; }
  .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div {
    max-width: 600px;
    margin-left: -300px;
    padding: 25px 25px 0 !important; }
    @media (max-width: 1199px) {
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div {
        max-width: 100%;
        margin: auto;
        top: 0px !important;
        right: 0px !important;
        left: 0px !important;
        bottom: 0px !important;
        padding: 30px 45px 0 !important;
        min-height: 100vh;
        border-radius: 0 !important; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div .closeModal {
          background: #d8dfe5;
          box-shadow: none;
          top: 15px;
          right: 15px; } }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div h2 {
      text-align: left;
      font-size: 18px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div .add-school-form {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 150px);
      overflow-y: auto;
      overflow-x: hidden;
      padding: 0px 10px;
      float: none; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div .add-school-form::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div .add-school-form::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div .add-school-form::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div .school-ligne .fileUpload {
      display: inline-block;
      min-width: 105px;
      height: 30px;
      object-fit: contain;
      border-radius: 49.5px;
      text-align: center;
      font-family: Montserrat;
      line-height: 30px;
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
      background-image: -webkit-linear-gradient(54deg, #3eb6ad, #72dcd6);
      background-image: -webkit-linear-gradient(36deg, #3eb6ad, #72dcd6);
      background-image: linear-gradient(54deg, #3eb6ad, #72dcd6);
      color: #fff;
      border: none;
      float: left;
      margin-bottom: 0px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div .school-ligne .fileUpload input {
        max-width: 95px;
        visibility: hidden;
        position: absolute;
        width: auto;
        z-index: 999; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div .school-ligne .fileUpload i {
        margin-right: 3px;
        font-size: 15px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div .school-ligne .or {
      font-size: 13px;
      color: #1b2437;
      font-weight: 500;
      margin: 7px 9px 0; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div .school-ligne .inputLink {
      max-width: 170px;
      position: relative;
      float: left; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div .school-ligne .inputLink i {
        position: absolute;
        left: 8px;
        top: 8px;
        color: #b2c0cf; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div .school-ligne .inputLink input {
        font-weight: 600;
        padding-left: 30px;
        height: 30px;
        color: #626875; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div .school-ligne label .add {
      height: 30px;
      border: 1px solid #e7ecf0;
      background: #fcfcfd;
      border-radius: 2px;
      display: inline-block;
      text-align: center;
      font-size: 13px;
      line-height: 24px;
      font-weight: 500;
      color: #1b2437; }
  .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork .field_required {
    color: red; }
  .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork .school-ligne-center {
    text-align: center; }
  .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form {
    margin-top: 25px;
    float: left;
    height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px 15px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form::-webkit-scrollbar-thumb {
      background-color: #3eb6ad; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne {
      margin-bottom: 15px;
      width: 100%;
      float: left; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne .intl-tel-input {
        width: 100%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne.school-ligne-center {
        margin: 20px 0; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne .newClassesTop {
        float: left;
        width: 100%;
        margin-bottom: 25px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne .newClassesTop img {
          max-width: 55px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne .newClassesTop span {
          width: 115px;
          height: 30px;
          text-align: center;
          object-fit: contain;
          border-radius: 49.5px;
          background-color: #3eb6ad;
          font-family: Montserrat;
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 30px;
          letter-spacing: normal;
          color: rgba(255, 255, 255, 0.8);
          display: inline-block;
          margin-left: 10px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne button.add {
        display: inline-block;
        min-width: 106px;
        height: 30px;
        object-fit: contain;
        border-radius: 49.5px;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
        background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
        color: #fff;
        border: none;
        margin: 0 7px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne button.add.cancel {
          background: #fff;
          color: #3eb6ad;
          box-shadow: none;
          border: solid 0.6px #d1dbe2; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne button.add.newStudents {
          width: 155px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne .label_select_students {
        width: 100%;
        display: inline-block; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne .RadioLegend {
        float: left;
        margin-right: 25px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne .RadioLegend:last-child {
          margin-right: 0; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne .RadioLegend > span {
          padding: 0;
          float: left;
          flex: none;
          display: initial; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne .RadioLegend > legend {
          float: left;
          margin: 0;
          font-family: Montserrat;
          font-size: 13px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          margin-left: 5px;
          margin-top: 4px;
          display: inline-block;
          border: none;
          width: auto; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne textarea {
        float: left;
        width: 100%;
        height: 50px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        resize: none; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne .select {
        float: left;
        width: 100%;
        height: 26.5px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        position: relative;
        margin-left: 5px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne .select select {
          float: left;
          width: 100%;
          font-size: 12px;
          height: 25.5px;
          border: none;
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          text-indent: 1px;
          text-overflow: "";
          background: transparent;
          outline: 0;
          position: relative;
          z-index: 9;
          border-radius: 4px !important;
          border: none !important;
          font-weight: 500;
          font-family: Montserrat;
          color: #1b2437;
          padding: 0 10px; }
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne .select select::-ms-expand {
            display: none; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne .select i {
          color: #d2dbe2;
          font-size: 16px;
          position: absolute;
          right: 5px;
          top: 5px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne label {
        display: block;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #1b2437;
        margin-bottom: 3px;
        text-align: left; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne input {
        float: left;
        width: 100%;
        height: 26.5px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        font-size: 14px;
        font-weight: 500; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne input:not([type='tel']) {
          padding-left: 10px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne.school-ligne-diploma {
        margin-bottom: 25px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne.school-ligne-margin {
        width: calc(100% + 20px);
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: 15px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 {
          width: 50%;
          float: left;
          padding: 0 10px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne .error {
        color: red; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne .RadioLegend {
        width: 30%;
        margin: 0 0 15px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne .RadioLegend.all_classes {
          width: 98%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne .radioLevels .RadioLegend > legend {
        text-transform: uppercase; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky > div .add-school-form .school-ligne.school-ligne-classes {
        border: 2px dotted #d8dfe6;
        margin: 0 -20px 15px;
        width: calc(100% + 40px);
        padding: 10px 20px 0; }
  .OverlayDaillyChallenge > div {
    background: #fff !important;
    padding: 20px 60px 35px !important;
    max-width: 845px;
    margin-left: -422.5px; }
    .OverlayDaillyChallenge > div h2 {
      font-size: 14px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #1b2437;
      margin: 0; }
    .OverlayDaillyChallenge > div .friends-switch {
      border-radius: 49.5px;
      border: solid 0.6px #d1dbe2;
      background-color: rgba(245, 248, 249, 0.3);
      height: 32px;
      display: inline-block;
      margin: auto;
      overflow: hidden; }
      .OverlayDaillyChallenge > div .friends-switch a {
        font-family: "Montserrat", sans-serif;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3eb6ad;
        padding: 8px 20px;
        float: left;
        border-radius: 49.5px; }
        .OverlayDaillyChallenge > div .friends-switch a.active {
          box-shadow: 0 -10px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: linear-gradient(259deg, #72dcd6, #3eb6ad);
          color: #fff;
          padding: 8px 30px; }
    .OverlayDaillyChallenge > div .friends-top {
      margin-top: 30px;
      float: left;
      width: 100%; }
      .OverlayDaillyChallenge > div .friends-top .friend-search {
        float: left;
        width: 228px;
        position: relative; }
        .OverlayDaillyChallenge > div .friends-top .friend-search input {
          height: 28px;
          border-radius: 19px;
          border: solid 1px #b1bfcd;
          background-color: #f8f8f8;
          width: 100%;
          padding-left: 16px; }
        .OverlayDaillyChallenge > div .friends-top .friend-search span {
          position: absolute;
          right: 11px;
          top: 3px; }
      .OverlayDaillyChallenge > div .friends-top .friend-right {
        float: right; }
        .OverlayDaillyChallenge > div .friends-top .friend-right .bloc-level {
          display: inline-block;
          margin-left: 50px;
          font-family: "Montserrat", sans-serif;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437; }
          .OverlayDaillyChallenge > div .friends-top .friend-right .bloc-level img {
            max-width: 19px;
            margin-right: 4px; }
          .OverlayDaillyChallenge > div .friends-top .friend-right .bloc-level .fa-sort-amount-desc,
          .OverlayDaillyChallenge > div .friends-top .friend-right .bloc-level .fa-university {
            color: #d3dae2; }
          .OverlayDaillyChallenge > div .friends-top .friend-right .bloc-level .fa-sort-desc {
            vertical-align: text-top; }
    .OverlayDaillyChallenge > div .friend-content {
      float: left;
      width: 100%;
      margin-top: 20px;
      overflow-y: auto; }
      .OverlayDaillyChallenge > div .friend-content .content-scroll {
        height: 280px; }
        .OverlayDaillyChallenge > div .friend-content .content-scroll::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5; }
        .OverlayDaillyChallenge > div .friend-content .content-scroll::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5; }
        .OverlayDaillyChallenge > div .friend-content .content-scroll::-webkit-scrollbar-thumb {
          background-color: #3eb6ad; }
      .OverlayDaillyChallenge > div .friend-content h5 {
        font-size: 15px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #525867;
        margin: 0; }
      .OverlayDaillyChallenge > div .friend-content .friend-content-bloc {
        float: left;
        width: 100%;
        margin: 10px 0 0; }
        .OverlayDaillyChallenge > div .friend-content .friend-content-bloc .friend-content-bloc-scroll {
          height: calc(100vh - 300px);
          overflow-y: auto;
          padding-right: 10px;
          padding-top: 10px;
          padding-left: 5px; }
          .OverlayDaillyChallenge > div .friend-content .friend-content-bloc .friend-content-bloc-scroll::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5; }
          .OverlayDaillyChallenge > div .friend-content .friend-content-bloc .friend-content-bloc-scroll::-webkit-scrollbar {
            width: 5px;
            background-color: #f5f5f5; }
          .OverlayDaillyChallenge > div .friend-content .friend-content-bloc .friend-content-bloc-scroll::-webkit-scrollbar-thumb {
            background-color: #3eb6ad; }
        .OverlayDaillyChallenge > div .friend-content .friend-content-bloc .friend-bloc {
          width: 12.5%;
          height: 110px;
          float: left;
          padding: 0 10px;
          text-align: center;
          margin-bottom: 25px;
          position: relative;
          cursor: pointer; }
          .OverlayDaillyChallenge > div .friend-content .friend-content-bloc .friend-bloc.selected:before {
            content: "";
            width: 80px;
            height: 80px;
            border-radius: 76px;
            /* background: #FFF; */
            position: absolute;
            left: 7px;
            top: -2px;
            background: url("../img/item_selected.svg") no-repeat scroll transparent;
            background-position: 0 0;
            background-size: 80px 80px;
            box-shadow: 0 7px 24px -4px #a9adb1; }
          .OverlayDaillyChallenge > div .friend-content .friend-content-bloc .friend-bloc.selected img {
            visibility: hidden; }
          .OverlayDaillyChallenge > div .friend-content .friend-content-bloc .friend-bloc img {
            width: 72px;
            height: 72px;
            box-shadow: 0 7px 24px -4px #a9adb1;
            border-radius: 72px;
            margin: auto; }
          .OverlayDaillyChallenge > div .friend-content .friend-content-bloc .friend-bloc h5 {
            font-size: 11.5px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.27;
            letter-spacing: normal;
            text-align: center;
            color: #1b2437;
            margin-top: 10px; }
    .OverlayDaillyChallenge > div .bloc-next {
      margin-top: 16px;
      text-align: center;
      width: 100%;
      float: left; }
      .OverlayDaillyChallenge > div .bloc-next a {
        box-shadow: 0 1px 20px -1px rgba(90, 202, 195, 0.5);
        background-image: -webkit-linear-gradient(54deg, #72dcd6, #3eb6ad);
        background-image: -webkit-linear-gradient(36deg, #72dcd6, #3eb6ad);
        background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
        color: #fff;
        padding: 5px 40px;
        display: inline-block;
        border-radius: 20px;
        margin-bottom: 15px;
        font-weight: 600; }
  .OverlayDaillyChallenge.OverlayPeerChallenge .header-center {
    float: left;
    width: calc(100% - 404px);
    text-align: center; }
    .OverlayDaillyChallenge.OverlayPeerChallenge .header-center .challenge-request {
      display: inline-block;
      position: relative;
      overflow: hidden;
      height: 40px;
      border-radius: 20.3px;
      border: 1px solid #3eb6ad;
      margin-top: 11px; }
      .OverlayDaillyChallenge.OverlayPeerChallenge .header-center .challenge-request a {
        font-family: "Montserrat", sans-serif;
        font-size: 10px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.8px;
        padding: 12.5px 25px;
        display: inline-block;
        position: relative;
        border-radius: 27px;
        overflow: hidden;
        color: #3eb6ad; }
        .OverlayDaillyChallenge.OverlayPeerChallenge .header-center .challenge-request a.active {
          color: #ffffff;
          background: #3eb6ad; }
          .OverlayDaillyChallenge.OverlayPeerChallenge .header-center .challenge-request a.active:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border-radius: 31px;
            border: 2px solid #fff; }
  .OverlayDaillyChallenge.OverlayPeerChallenge .myRequest {
    padding: 60px 70px 0; }
    .OverlayDaillyChallenge.OverlayPeerChallenge .myRequest .bloc-myRequest-width {
      width: 33.33%;
      float: left; }
      .OverlayDaillyChallenge.OverlayPeerChallenge .myRequest .bloc-myRequest-width .bloc-myRequest {
        margin: 0 20px 50px;
        border-radius: 14px;
        box-shadow: -3px 10px 36px 0 rgba(0, 0, 0, 0.12);
        background-color: #f8f8f8;
        float: left;
        width: calc(100% - 40px);
        padding: 22px 12px; }
        .OverlayDaillyChallenge.OverlayPeerChallenge .myRequest .bloc-myRequest-width .bloc-myRequest h3 {
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 0.6;
          letter-spacing: -0.1px;
          text-align: center;
          color: #3eb6ad;
          margin: 0 0 25px; }
        .OverlayDaillyChallenge.OverlayPeerChallenge .myRequest .bloc-myRequest-width .bloc-myRequest .request-top {
          float: left;
          width: 100%;
          margin-bottom: 10px; }
          .OverlayDaillyChallenge.OverlayPeerChallenge .myRequest .bloc-myRequest-width .bloc-myRequest .request-top .request-bloc {
            width: 50%;
            float: left;
            padding: 0 18px;
            text-align: center; }
            .OverlayDaillyChallenge.OverlayPeerChallenge .myRequest .bloc-myRequest-width .bloc-myRequest .request-top .request-bloc img {
              width: 66px;
              height: 66px;
              border-radius: 3px;
              box-shadow: 0px 0px 15px 3px #d4d3d4;
              margin: 0 auto; }
            .OverlayDaillyChallenge.OverlayPeerChallenge .myRequest .bloc-myRequest-width .bloc-myRequest .request-top .request-bloc h4 {
              font-size: 14px;
              font-weight: 600;
              font-style: normal;
              font-stretch: normal;
              line-height: normal;
              letter-spacing: normal;
              color: #1f2022;
              margin-top: 12px; }
        .OverlayDaillyChallenge.OverlayPeerChallenge .myRequest .bloc-myRequest-width .bloc-myRequest h5 {
          font-size: 15px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.29;
          letter-spacing: normal;
          text-align: center;
          color: #1b2437;
          margin: 0; }
        .OverlayDaillyChallenge.OverlayPeerChallenge .myRequest .bloc-myRequest-width .bloc-myRequest .request-bottom {
          float: left;
          width: 100%;
          text-align: center;
          margin-top: 17px; }
          .OverlayDaillyChallenge.OverlayPeerChallenge .myRequest .bloc-myRequest-width .bloc-myRequest .request-bottom a {
            border-radius: 16px;
            box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.17);
            border: solid 4px #ffffff;
            margin: 0 7px;
            text-align: center;
            padding: 5px 22px 3px; }
            .OverlayDaillyChallenge.OverlayPeerChallenge .myRequest .bloc-myRequest-width .bloc-myRequest .request-bottom a i {
              font-size: 20px;
              color: #fff; }
            .OverlayDaillyChallenge.OverlayPeerChallenge .myRequest .bloc-myRequest-width .bloc-myRequest .request-bottom a.request-valid {
              background: #02da3f; }
            .OverlayDaillyChallenge.OverlayPeerChallenge .myRequest .bloc-myRequest-width .bloc-myRequest .request-bottom a.request-remove {
              background: #fc0e09; }
  .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges {
    padding: 35px 95px 20px; }
    .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges .bloc-mychallenge-width {
      float: left;
      width: 33.33%; }
      .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges .bloc-mychallenge-width .bloc-mychallenge {
        border-radius: 8px;
        box-shadow: 2px 3px 10px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        margin: 0 25px 40px;
        padding-top: 25px;
        border-bottom: 6px solid #ec735b;
        float: left;
        width: calc(100% - 50px); }
        .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges .bloc-mychallenge-width .bloc-mychallenge.bloc-mychallenge-add {
          min-height: 305px;
          border-bottom: none;
          text-align: center;
          padding-top: 133px; }
          .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges .bloc-mychallenge-width .bloc-mychallenge.bloc-mychallenge-add .mychallenge-add {
            font-family: "Montserrat", sans-serif;
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.6px;
            text-align: center;
            color: #ffffff;
            text-transform: uppercase;
            border-radius: 50.4px;
            background-color: #fda800;
            padding: 12px 20px; }
            .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges .bloc-mychallenge-width .bloc-mychallenge.bloc-mychallenge-add .mychallenge-add i {
              font-size: 19px;
              color: #ffffff;
              margin-right: 5px;
              vertical-align: middle; }
        .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges .bloc-mychallenge-width .bloc-mychallenge h3 {
          font-size: 18px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 0.6;
          letter-spacing: -0.1px;
          text-align: center;
          color: #3eb6ad;
          margin: 0 0 28px; }
        .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges .bloc-mychallenge-width .bloc-mychallenge .mychallenge-one {
          float: left;
          width: 100%;
          margin-bottom: 17px;
          padding: 0 20px; }
          .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges .bloc-mychallenge-width .bloc-mychallenge .mychallenge-one .mychallenge-img {
            width: 66px;
            height: 66px;
            float: left;
            border-radius: 3px;
            box-shadow: 0px 0px 15px 3px #d4d3d4;
            margin-right: 15px; }
            .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges .bloc-mychallenge-width .bloc-mychallenge .mychallenge-one .mychallenge-img img {
              width: 66px;
              height: 66px;
              float: left;
              border-radius: 3px; }
          .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges .bloc-mychallenge-width .bloc-mychallenge .mychallenge-one h4 {
            font-size: 14px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1f2022; }
          .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges .bloc-mychallenge-width .bloc-mychallenge .mychallenge-one h5 {
            font-size: 12px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 0.9;
            letter-spacing: -0.1px;
            color: #3eb6ad; }
        .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges .bloc-mychallenge-width .bloc-mychallenge .mychallenge-bottom {
          margin-top: 3px;
          border-top: 2px solid #f3f3f3;
          float: left;
          width: 100%;
          text-align: center; }
          .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges .bloc-mychallenge-width .bloc-mychallenge .mychallenge-bottom h4 {
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: normal;
            text-align: center;
            color: #1b2437;
            margin-top: 12px; }
          .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges .bloc-mychallenge-width .bloc-mychallenge .mychallenge-bottom p {
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #1b2437; }
    .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group {
      display: flex;
      justify-content: flex-start; }
      .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group .challenge_item {
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        background-color: #fff;
        padding: 0.5rem 0.7rem;
        border-radius: 5px;
        box-shadow: 0 5px 48px 0 rgba(46, 104, 208, 0.1);
        flex: 0 0 24%;
        margin-right: 1%;
        max-width: 24%; }
        .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group .challenge_item .loading_image {
          position: absolute;
          width: 100%;
          height: 80%;
          left: 0%;
          top: 10%;
          background: gray;
          opacity: 0.5;
          border-radius: 12px; }
          @media (max-width: 767px) {
            .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group .challenge_item .loading_image {
              left: 25%;
              width: 50%; } }
        .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group .challenge_item:last-of-type {
          margin-right: 0%; }
        .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group .challenge_item img.medal {
          width: 40%;
          height: 80px; }
        .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group .challenge_item .c_title {
          display: block;
          display: -webkit-box;
          height: 42px;
          font-size: 15px;
          font-weight: 600;
          max-width: 80%;
          margin-bottom: 0.2rem;
          margin: 0 auto;
          font-size: 15px;
          line-height: 1.4;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis; }
        .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group .challenge_item .c_level {
          font-size: 0.8rem;
          display: block;
          width: 100%;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
          .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group .challenge_item .c_level .level {
            color: #b1bfcd; }
        .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group .challenge_item .grades {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          text-align: center;
          display: flex;
          flex-direction: column;
          font-size: 0.75rem;
          text-align: center; }
          .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group .challenge_item .grades.title {
            display: block; }
          .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group .challenge_item .grades .span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%; }
        .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group .challenge_item .all_btn {
          margin: 0.8rem 0; }
          .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group .challenge_item .all_btn .btn {
            text-transform: uppercase; }
        .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group .challenge_item .c_footer {
          width: 100%;
          text-align: right;
          /*display: flex;
              justify-content: space-between;
              align-items: flex-end;*/ }
          .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group .challenge_item .c_footer .c_btn button {
            font-size: 0.75rem; }
        .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group .challenge_item.all .all_btn, .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group .challenge_item.group .all_btn {
          margin: 0.8rem 0; }
          .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group .challenge_item.all .all_btn .btn, .OverlayDaillyChallenge.OverlayPeerChallenge .mychallenges.group .challenge_item.group .all_btn .btn {
            text-transform: uppercase;
            font-size: 0.75rem; }
  .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div {
    max-width: 880px;
    width: 100%;
    margin-left: 0;
    transform: translateX(-50%); }
    .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges {
      padding: 20px 15px; }
      .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .challenge_item {
        margin-right: 1.6%; }
        .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .challenge_item:nth-child(4n) {
          margin-right: -1%; }
      .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .content-scroll .bloc-content {
        position: relative;
        height: 210px; }
        .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .content-scroll .bloc-content .exercice-active {
          position: absolute;
          right: 5px;
          top: 5px;
          width: 27px;
          height: 27px;
          border-radius: 27px;
          background-color: #3eb6ad;
          text-align: center;
          display: block; }
          .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .content-scroll .bloc-content .exercice-active i {
            color: #fff;
            font-size: 17px;
            line-height: 27px; }
        .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .content-scroll .bloc-content > p {
          font-family: Montserrat,sans-serif;
          font-size: 13px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: .5px;
          text-align: center;
          line-height: 27px;
          color: black;
          text-transform: uppercase; }
    @media screen and (min-width: 1200px) {
      .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges {
        flex-wrap: wrap;
        height: 480px;
        overflow-y: scroll;
        margin-bottom: 10px; }
        .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .challenge_item {
          margin-bottom: 10px;
          height: 255px; }
          .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .challenge_item:last-of-type {
            margin-bottom: 20px; }
          .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .challenge_item .c_footer {
            position: absolute;
            bottom: 15px;
            padding: 0 10px; }
            .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .challenge_item .c_footer .c_btn {
              display: flex;
              justify-content: space-around; }
              .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .challenge_item .c_footer .c_btn .btn-challenge {
                max-width: 100px;
                padding: 0.2rem 0.8rem;
                width: max-content;
                min-width: 65px; }
        .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
          background-color: #F5F5F5 !important; }
        .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges::-webkit-scrollbar {
          width: 5px !important;
          height: 3px !important;
          background-color: #F5F5F5 !important; }
        .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges::-webkit-scrollbar-thumb {
          background-color: #3eb6ad !important; } }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
      .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div {
        width: 98%;
        left: 0% !important;
        margin: 1%;
        top: 0 !important;
        height: 97%;
        border-radius: 4px !important; }
        .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .closeModal {
          top: -8px;
          right: -9px; }
        .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges {
          padding: 25px 20px 20px;
          flex-wrap: wrap;
          height: 87%;
          overflow: scroll; }
          .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .challenge_item {
            margin-left: 0;
            margin-right: 2%;
            margin-bottom: 10px;
            height: 255px;
            flex: 0 0 32%; }
            .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .challenge_item:nth-child(3n+0) {
              margin-right: 0; }
            .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .challenge_item .c_footer {
              position: absolute;
              bottom: 15px;
              padding: 0 10px; }
              .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .challenge_item .c_footer .c_btn {
                display: flex;
                justify-content: space-around; }
                .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .challenge_item .c_footer .c_btn .btn-challenge {
                  max-width: 100px;
                  padding: 0.2rem 0.8rem;
                  width: 50%;
                  min-width: 70px; }
          .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .content-scroll {
            overflow-y: scroll;
            height: 100%;
            flex-wrap: wrap;
            display: flex; }
            .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .content-scroll > div {
              height: max-content !important; } }
    @media screen and (max-width: 767px) {
      .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div {
        width: 100%;
        left: 0% !important;
        margin-left: 0;
        top: 0 !important;
        height: 100%;
        border-radius: 0 !important; }
        .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .closeModal {
          top: -6px;
          right: -4px; }
        .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges {
          padding: 25px 20px 20px;
          flex-wrap: wrap;
          height: 87%;
          overflow: scroll; }
          .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .challenge_item {
            margin-left: 0;
            margin-right: 2%;
            margin-bottom: 10px;
            height: 255px;
            flex: 0 0 49%;
            padding: 0.5rem 0.3rem; }
            .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .challenge_item:nth-child(2n+0) {
              margin-right: 0; }
            .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .challenge_item .c_footer {
              position: absolute;
              bottom: 15px;
              padding: 0 0.3rem; }
              .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .challenge_item .c_footer .c_btn {
                display: flex;
                justify-content: space-around; }
                .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .challenge_item .c_footer .c_btn .btn-challenge {
                  max-width: 100px;
                  padding: 0.2rem 0.8rem;
                  width: 50%;
                  min-width: 70px; }
          .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .content-scroll .bloc-content {
            height: 180px; }
            .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .content-scroll .bloc-content > a {
              width: 60px;
              height: 60px;
              line-height: 60px;
              margin-top: 11px; }
            .OverlayDaillyChallenge.OverlayGroupClasseChallenge > div .content.mychallenges .content-scroll .bloc-content > h4 {
              margin: 7px 0 10px;
              height: 30px;
              font-size: 11px; } }
  @media (max-width: 1199px) {
    .OverlayDaillyChallenge.OverlayGroupClasseChallenge.OverlayGroupClasseChallenge2 > div {
      left: 50% !important; } }
  @media (max-width: 1000px) {
    .OverlayDaillyChallenge.OverlayGroupClasseChallenge.OverlayGroupClasseChallenge2 > div {
      width: 100%;
      left: 0% !important;
      transform: unset !important;
      top: 0 !important;
      height: 100%;
      margin: 0;
      max-width: 100%; } }
  @media (max-width: 767px) and (min-width: 551px) {
    .OverlayDaillyChallenge.OverlayGroupClasseChallenge.OverlayGroupClasseChallenge2 > div .content.mychallenges .challenge_item {
      margin-right: 2% !important;
      width: 32%;
      max-width: 32%; } }
  @media (max-width: 550px) and (min-width: 400px) {
    .OverlayDaillyChallenge.OverlayGroupClasseChallenge.OverlayGroupClasseChallenge2 > div .content.mychallenges .challenge_item {
      margin-right: 2% !important;
      width: 48%;
      max-width: 48%; } }
  @media (max-width: 399px) {
    .OverlayDaillyChallenge.OverlayGroupClasseChallenge.OverlayGroupClasseChallenge2 > div .content.mychallenges .challenge_item {
      width: 70%;
      max-width: 70%;
      flex: 1 !important;
      margin-left: 15% !important; } }
  .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div {
    max-width: initial;
    top: 20px !important;
    bottom: 20px !important;
    left: 20px !important;
    right: 20px !important;
    margin: 0px;
    display: flex;
    flex-direction: column;
    border-radius: 14px !important;
    height: auto; }
    .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .header {
      float: none;
      height: 55px;
      box-shadow: -3px 10px 36px 0 rgba(0, 0, 0, 0.04);
      background-color: #5975f7;
      padding: 0 20px !important;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }
      .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .header .headerLeft {
        font-family: Montserrat;
        font-size: 18px;
        font-weight: 500;
        color: #ffffff; }
        .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .header .headerLeft img {
          width: 50px;
          height: 50px;
          margin-right: 15px; }
      .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .header .headerRight {
        height: 30px;
        border-radius: 15px;
        border: solid 1px #ffffff; }
        .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .header .headerRight span {
          display: inline-block;
          width: 28px;
          height: 28px;
          border-radius: 28px;
          text-align: center;
          line-height: 28px;
          font-size: 12px;
          color: rgba(255, 255, 255, 0.8);
          font-weight: 400; }
          .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .header .headerRight span.active {
            background: #FFF;
            color: #5975f7;
            font-weight: 600; }
      .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .header .feedback-exercise .close_ {
        float: right; }
    .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content {
      padding: 0;
      float: none;
      flex: 1; }
      .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice {
        height: 100%;
        display: flex;
        flex-direction: column; }
        .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .exercise_xblock_content {
          flex: 1; }
          @media (min-width: 1200px) {
            .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .exercise_xblock_content {
              height: calc(100vh - 500px); } }
          .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .exercise_xblock_content .xblock_viewer {
            height: 100%; }
            .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .exercise_xblock_content .xblock_viewer .xblock-student_view.open_question_exercise .container_exercice {
              flex: unset; }
              .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .exercise_xblock_content .xblock_viewer .xblock-student_view.open_question_exercise .container_exercice .response_question .question {
                max-height: 50%; }
                .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .exercise_xblock_content .xblock_viewer .xblock-student_view.open_question_exercise .container_exercice .response_question .question.h_100 {
                  max-height: 100%; }
                .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .exercise_xblock_content .xblock_viewer .xblock-student_view.open_question_exercise .container_exercice .response_question .question > p {
                  height: 95%;
                  overflow-y: auto;
                  padding: 15px;
                  width: 100%; }
              .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .exercise_xblock_content .xblock_viewer .xblock-student_view.open_question_exercise .container_exercice .response_question .editor {
                max-height: 100%;
                min-height: 50%; }
            .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .exercise_xblock_content .xblock_viewer .xblock-student_view.open_question_exercise .description_action {
              display: none; }
            .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .exercise_xblock_content .xblock_viewer .xblock-student_view.open_question_v2_exercise .container_exercice {
              flex: unset; }
              .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .exercise_xblock_content .xblock_viewer .xblock-student_view.open_question_v2_exercise .container_exercice .response_question .question {
                max-height: 50%; }
                .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .exercise_xblock_content .xblock_viewer .xblock-student_view.open_question_v2_exercise .container_exercice .response_question .question.h_100 {
                  max-height: 100%; }
                .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .exercise_xblock_content .xblock_viewer .xblock-student_view.open_question_v2_exercise .container_exercice .response_question .question > p {
                  height: 95%;
                  overflow-y: auto;
                  padding: 15px;
                  width: 100%; }
              .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .exercise_xblock_content .xblock_viewer .xblock-student_view.open_question_v2_exercise .container_exercice .response_question .editor {
                max-height: 100%;
                min-height: 50%; }
            .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .exercise_xblock_content .xblock_viewer .xblock-student_view.open_question_v2_exercise .description_action {
              display: none; }
        .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .contentBottom {
          height: 50px;
          background-color: #fbfbfb;
          width: 100%;
          /*padding: 23px 0;*/
          text-align: center;
          border-bottom-left-radius: 14px;
          border-bottom-right-radius: 14px;
          display: flex;
          justify-content: center;
          align-items: center; }
          .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .contentBottom button {
            width: 111px;
            height: 30px;
            object-fit: contain;
            border-radius: 49.5px;
            background-color: #3eb6ad;
            margin: 0 5px;
            display: inline-block;
            font-size: 12px;
            font-weight: 600;
            color: #FFF;
            text-align: center;
            line-height: 28px; }
            .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .contentBottom button.submit {
              width: 152px;
              border: solid 1px #d1dbe2;
              background-color: #ffffff;
              color: #3eb6ad;
              line-height: 28px; }
          .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .contentBottom img.replay_ke {
            cursor: pointer;
            margin: auto !important;
            padding-right: 184px !important; }
          .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .contentBottom img.replay {
            position: absolute;
            top: 507px;
            right: 28px;
            cursor: pointer; }
        .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .contentBottomParent {
          display: flex;
          justify-content: space-between; }
  .OverlayDaillyChallenge.OverlayChallengeScore > div {
    max-width: 1100px; }
    .OverlayDaillyChallenge.OverlayChallengeScore > div .header {
      padding: 0 24px; }
    .OverlayDaillyChallenge.OverlayChallengeScore > div .content {
      padding: 35px 24px; }
      .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button {
        float: left;
        width: 100%;
        text-align: right;
        margin: 0 30px 15px 0;
        display: flex;
        justify-content: flex-end; }
        .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > a, .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > div {
          position: relative;
          height: 34px; }
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > a > div, .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > div > div {
            width: 110px;
            height: 30px;
            border-radius: 18px;
            border: solid 2px #3eb6ad;
            background-color: rgba(255, 255, 255, 0.8);
            font-family: Montserrat;
            font-size: 13px;
            font-weight: bold;
            letter-spacing: 0.86px;
            text-align: center;
            color: #3eb6ad;
            position: relative;
            margin-left: 22px;
            line-height: 26px; }
            .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > a > div.for_challenge, .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > div > div.for_challenge {
              width: 210px; }
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > a i, .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > div i {
            margin: 6px 7px 0 0;
            font-size: 14px;
            float: right;
            color: #3eb6ad; }
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > a .scoreList, .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > div .scoreList {
            margin: 0;
            padding: 0;
            border: solid 2px #3eb6ad;
            border-radius: 7px;
            position: absolute;
            right: 0;
            top: 34px;
            width: 110px;
            background: #FFF;
            overflow: hidden;
            display: none; }
            .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > a .scoreList.for_challenge, .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > div .scoreList.for_challenge {
              width: 210px; }
            .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > a .scoreList li, .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > div .scoreList li {
              margin: 0;
              padding: 0; }
              .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > a .scoreList li a, .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > div .scoreList li a {
                float: left;
                width: 100%;
                padding: 4px;
                font-family: Montserrat;
                font-size: 13px;
                font-weight: bold;
                letter-spacing: 0.86px;
                text-align: center;
                color: #3eb6ad; }
                .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > a .scoreList li a:hover, .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > div .scoreList li a:hover {
                  background: #fafafa; }
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > a:hover .scoreList, .OverlayDaillyChallenge.OverlayChallengeScore > div .content .daillyChallenge .score-button > div:hover .scoreList {
            display: block; }
      .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score {
        border-radius: 14px;
        box-shadow: -3px 10px 36px 0 rgba(0, 0, 0, 0.12);
        border: solid 3px #ffcd3d;
        background-color: #ffffff;
        background-image: url("../img/leader-board.png");
        background-repeat: no-repeat;
        background-position: 60px 260px;
        float: left;
        width: 100%;
        padding: 26px 60px 120px;
        text-align: center; }
        .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .avatar-challenge span {
          width: 97px;
          height: 97px;
          border-radius: 50%;
          box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.25);
          display: inline-block; }
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .avatar-challenge span img {
            width: 87px;
            height: 87px;
            border-radius: 50%; }
        .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-two,
        .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-three {
          padding-top: 59px; }
        .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-two,
        .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-one,
        .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-three {
          float: left;
          width: 33.33%; }
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-two p,
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-one p,
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-three p {
            margin: 0; }
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-two h5,
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-one h5,
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-three h5 {
            font-family: "Montserrat", sans-serif;
            font-size: 14px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #737272; }
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-two h6,
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-one h6,
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-three h6 {
            font-family: "Montserrat", sans-serif;
            font-size: 13px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.8px;
            color: #ffffff;
            border-radius: 11.5px;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.18);
            border: solid 1px #ffffff;
            background-color: #ffcd3d;
            display: inline-block;
            padding: 4px 15px;
            margin-top: 12px; }
        .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-two .avatar-challenge span {
          border: 5px solid #e0e0e1; }
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-two .avatar-challenge span img {
            border: 5px solid #c5c6c7; }
        .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-one .avatar-challenge span {
          border: 5px solid #f6da7c; }
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-one .avatar-challenge span img {
            border: 5px solid #f6c525; }
        .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-three .avatar-challenge span {
          border: 5px solid #fac191; }
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .challenge-score .challenge-score-three .avatar-challenge span img {
            border: 5px solid #faa864; }
      .OverlayDaillyChallenge.OverlayChallengeScore > div .content .select-challenge {
        text-align: right;
        margin-bottom: 15px; }
        .OverlayDaillyChallenge.OverlayChallengeScore > div .content .select-challenge p {
          font-family: "Montserrat", sans-serif;
          font-size: 12.1px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.9px;
          display: inline-block;
          color: #3eb6ad;
          border-radius: 17.1px;
          border: solid 2.2px #3eb6ad;
          background-color: #ffffff;
          padding: 6px 15px;
          margin-left: 15px; }
      .OverlayDaillyChallenge.OverlayChallengeScore > div .content .score-friends .content-scroll {
        padding: 10px; }
        .OverlayDaillyChallenge.OverlayChallengeScore > div .content .score-friends .content-scroll .scrolling {
          height: auto;
          overflow: hidden; }
      .OverlayDaillyChallenge.OverlayChallengeScore > div .content .score-friends .bloc-score-friends {
        margin-bottom: 15px;
        float: left;
        width: 100%; }
        .OverlayDaillyChallenge.OverlayChallengeScore > div .content .score-friends .bloc-score-friends .score-number {
          width: 31px;
          height: 31px;
          float: left;
          box-shadow: -4px 5px 13px 0 rgba(0, 0, 0, 0.06);
          border: solid 2.3px #fece50;
          background-color: #ffffff;
          border-radius: 50%;
          text-align: center;
          line-height: 26px;
          font-family: "Montserrat", sans-serif;
          font-size: 14px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: normal;
          color: #555555;
          margin-right: 20px;
          margin-top: 17px; }
        .OverlayDaillyChallenge.OverlayChallengeScore > div .content .score-friends .bloc-score-friends .score-right {
          float: left;
          padding: 5px 5px 4px;
          border-radius: 40.7px;
          box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.12);
          background-color: #ffffff;
          width: calc(100% - 51px); }
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .score-friends .bloc-score-friends .score-right .score-img {
            width: 52px;
            height: 52px;
            float: left; }
            .OverlayDaillyChallenge.OverlayChallengeScore > div .content .score-friends .bloc-score-friends .score-right .score-img img {
              width: 52px;
              height: 52px;
              border-radius: 52px; }
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .score-friends .bloc-score-friends .score-right h5 {
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.4px;
            color: #555555;
            float: left;
            margin-left: 10px;
            margin-top: 18px; }
          .OverlayDaillyChallenge.OverlayChallengeScore > div .content .score-friends .bloc-score-friends .score-right h6 {
            font-family: "Montserrat", sans-serif;
            font-size: 13px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #6c6c6c;
            border: 1px solid #fef8e8;
            float: right;
            padding: 5px;
            border-radius: 40px;
            display: inline-block;
            margin: 0; }
            .OverlayDaillyChallenge.OverlayChallengeScore > div .content .score-friends .bloc-score-friends .score-right h6 .one {
              border: 1px solid #fdefce;
              border-radius: 40px;
              padding: 4px;
              display: inline-block; }
              .OverlayDaillyChallenge.OverlayChallengeScore > div .content .score-friends .bloc-score-friends .score-right h6 .one .two {
                border: 2px solid #ffdf8e;
                border-radius: 40px;
                padding: 5px 10px;
                display: inline-block; }
  .OverlayDaillyChallenge.OverlayCategoriesActivities > div {
    max-width: 880px;
    width: 100%;
    margin-left: 0;
    transform: translateX(-50%); }
    .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities {
      padding: 20px 15px; }
      .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .category_item, .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .activity_item {
        margin-right: 1.6%; }
        .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .category_item:nth-child(4n), .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .activity_item:nth-child(4n) {
          margin-right: -1%; }
      .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .content-scroll .bloc-content {
        position: relative;
        height: 210px; }
        .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .content-scroll .bloc-content a {
          width: 100%;
          min-width: 160px;
          height: 60%;
          min-height: 125px; }
          .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .content-scroll .bloc-content a img {
            width: 80%;
            height: 100%; }
        .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .content-scroll .bloc-content h4 {
          width: 90%;
          margin-left: 5%;
          font-weight: bold;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis; }
        .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .content-scroll .bloc-content > p {
          font-family: Montserrat,sans-serif;
          font-size: 13px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: .5px;
          text-align: center;
          line-height: 27px;
          color: black;
          text-transform: uppercase; }
    @media screen and (min-width: 1200px) {
      .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities {
        flex-wrap: wrap;
        height: 480px;
        overflow-y: scroll;
        margin-bottom: 10px; }
        .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .category_item, .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .activity_item {
          margin-bottom: 10px;
          height: 255px; }
          .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .category_item:last-of-type, .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .activity_item:last-of-type {
            margin-bottom: 20px; }
          .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .category_item .c_footer, .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .activity_item .c_footer {
            position: absolute;
            bottom: 15px;
            padding: 0 10px; }
            .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .category_item .c_footer .c_btn, .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .activity_item .c_footer .c_btn {
              display: flex;
              justify-content: space-around; }
              .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .category_item .c_footer .c_btn .btn-challenge, .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .activity_item .c_footer .c_btn .btn-challenge {
                max-width: 100px;
                padding: 0.2rem 0.8rem;
                width: max-content;
                min-width: 65px; }
        .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
          background-color: #F5F5F5 !important; }
        .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities::-webkit-scrollbar {
          width: 5px !important;
          height: 3px !important;
          background-color: #F5F5F5 !important; }
        .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities::-webkit-scrollbar-thumb {
          background-color: #3eb6ad !important; } }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
      .OverlayDaillyChallenge.OverlayCategoriesActivities > div {
        width: 98%;
        left: 0% !important;
        margin: 1%;
        top: 0 !important;
        height: 97%;
        border-radius: 4px !important; }
        .OverlayDaillyChallenge.OverlayCategoriesActivities > div .closeModal {
          top: -8px;
          right: -9px; }
        .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities {
          padding: 25px 20px 20px;
          flex-wrap: wrap;
          height: 87%;
          overflow: scroll; }
          .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .category_item, .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .activity_item {
            margin-left: 0;
            margin-right: 2%;
            margin-bottom: 10px;
            height: 255px;
            flex: 0 0 32%; }
            .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .category_item:nth-child(3n+0), .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .activity_item:nth-child(3n+0) {
              margin-right: 0; }
            .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .category_item .c_footer, .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .activity_item .c_footer {
              position: absolute;
              bottom: 15px;
              padding: 0 10px; }
              .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .category_item .c_footer .c_btn, .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .activity_item .c_footer .c_btn {
                display: flex;
                justify-content: space-around; }
                .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .category_item .c_footer .c_btn .btn-challenge, .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .activity_item .c_footer .c_btn .btn-challenge {
                  max-width: 100px;
                  padding: 0.2rem 0.8rem;
                  width: 50%;
                  min-width: 70px; }
          .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .content-scroll {
            overflow-y: scroll;
            height: 100%;
            flex-wrap: wrap;
            display: flex; }
            .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .content-scroll > div {
              height: max-content !important; } }
    @media screen and (max-width: 767px) {
      .OverlayDaillyChallenge.OverlayCategoriesActivities > div {
        width: 100%;
        left: 0% !important;
        margin-left: 0;
        top: 0 !important;
        height: 100%;
        border-radius: 0 !important; }
        .OverlayDaillyChallenge.OverlayCategoriesActivities > div .closeModal {
          top: -6px;
          right: -4px; }
        .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities {
          padding: 25px 20px 20px;
          flex-wrap: wrap;
          height: 87%;
          overflow: scroll; }
          .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .category_item, .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .activity_item {
            margin-left: 0;
            margin-right: 2%;
            margin-bottom: 10px;
            height: 255px;
            flex: 0 0 49%;
            padding: 0.5rem 0.3rem; }
            .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .category_item:nth-child(2n+0), .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .activity_item:nth-child(2n+0) {
              margin-right: 0; }
            .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .category_item .c_footer, .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .activity_item .c_footer {
              position: absolute;
              bottom: 15px;
              padding: 0 0.3rem; }
              .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .category_item .c_footer .c_btn, .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .activity_item .c_footer .c_btn {
                display: flex;
                justify-content: space-around; }
                .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .category_item .c_footer .c_btn .btn-challenge, .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .activity_item .c_footer .c_btn .btn-challenge {
                  max-width: 100px;
                  padding: 0.2rem 0.8rem;
                  width: 50%;
                  min-width: 70px; }
          .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .content-scroll .bloc-content {
            height: 180px; }
            .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .content-scroll .bloc-content > a {
              width: 60px;
              height: 60px;
              line-height: 60px;
              margin-top: 11px; }
            .OverlayDaillyChallenge.OverlayCategoriesActivities > div .content.categories_activities .content-scroll .bloc-content > h4 {
              margin: 7px 0 10px;
              height: 30px;
              font-size: 11px; } }
  .OverlayDaillyChallenge.OverlayExams > div {
    max-width: 880px;
    width: 100%;
    margin-left: 0;
    transform: translateX(-50%); }
    .OverlayDaillyChallenge.OverlayExams > div .content.exams {
      padding: 20px 15px; }
      .OverlayDaillyChallenge.OverlayExams > div .content.exams .category_item, .OverlayDaillyChallenge.OverlayExams > div .content.exams .exam_item {
        margin-right: 1.6%; }
        .OverlayDaillyChallenge.OverlayExams > div .content.exams .category_item:nth-child(4n), .OverlayDaillyChallenge.OverlayExams > div .content.exams .exam_item:nth-child(4n) {
          margin-right: -1%; }
      .OverlayDaillyChallenge.OverlayExams > div .content.exams .content-scroll .bloc-content {
        position: relative;
        height: 210px; }
        .OverlayDaillyChallenge.OverlayExams > div .content.exams .content-scroll .bloc-content a {
          width: 100%;
          min-width: 160px;
          height: 60%;
          min-height: 125px; }
          .OverlayDaillyChallenge.OverlayExams > div .content.exams .content-scroll .bloc-content a img {
            width: 80%;
            height: 100%; }
        .OverlayDaillyChallenge.OverlayExams > div .content.exams .content-scroll .bloc-content h4 {
          width: 90%;
          margin-left: 5%;
          font-weight: bold;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis; }
        .OverlayDaillyChallenge.OverlayExams > div .content.exams .content-scroll .bloc-content > p {
          font-family: Montserrat,sans-serif;
          font-size: 13px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: .5px;
          text-align: center;
          line-height: 27px;
          color: black;
          text-transform: uppercase; }
    @media screen and (min-width: 1200px) {
      .OverlayDaillyChallenge.OverlayExams > div .content.exams {
        flex-wrap: wrap;
        height: 480px;
        overflow-y: scroll;
        margin-bottom: 10px; }
        .OverlayDaillyChallenge.OverlayExams > div .content.exams .category_item, .OverlayDaillyChallenge.OverlayExams > div .content.exams .exam_item {
          margin-bottom: 10px;
          height: 255px; }
          .OverlayDaillyChallenge.OverlayExams > div .content.exams .category_item:last-of-type, .OverlayDaillyChallenge.OverlayExams > div .content.exams .exam_item:last-of-type {
            margin-bottom: 20px; }
          .OverlayDaillyChallenge.OverlayExams > div .content.exams .category_item .c_footer, .OverlayDaillyChallenge.OverlayExams > div .content.exams .exam_item .c_footer {
            position: absolute;
            bottom: 15px;
            padding: 0 10px; }
            .OverlayDaillyChallenge.OverlayExams > div .content.exams .category_item .c_footer .c_btn, .OverlayDaillyChallenge.OverlayExams > div .content.exams .exam_item .c_footer .c_btn {
              display: flex;
              justify-content: space-around; }
              .OverlayDaillyChallenge.OverlayExams > div .content.exams .category_item .c_footer .c_btn .btn-challenge, .OverlayDaillyChallenge.OverlayExams > div .content.exams .exam_item .c_footer .c_btn .btn-challenge {
                max-width: 100px;
                padding: 0.2rem 0.8rem;
                width: max-content;
                min-width: 65px; }
        .OverlayDaillyChallenge.OverlayExams > div .content.exams::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
          background-color: #F5F5F5 !important; }
        .OverlayDaillyChallenge.OverlayExams > div .content.exams::-webkit-scrollbar {
          width: 5px !important;
          height: 3px !important;
          background-color: #F5F5F5 !important; }
        .OverlayDaillyChallenge.OverlayExams > div .content.exams::-webkit-scrollbar-thumb {
          background-color: #3eb6ad !important; } }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
      .OverlayDaillyChallenge.OverlayExams > div {
        width: 98%;
        left: 0% !important;
        margin: 1%;
        top: 0 !important;
        height: 97%;
        border-radius: 4px !important; }
        .OverlayDaillyChallenge.OverlayExams > div .closeModal {
          top: -8px;
          right: -9px; }
        .OverlayDaillyChallenge.OverlayExams > div .content.exams {
          padding: 25px 20px 20px;
          flex-wrap: wrap;
          height: 87%;
          overflow: scroll; }
          .OverlayDaillyChallenge.OverlayExams > div .content.exams .category_item, .OverlayDaillyChallenge.OverlayExams > div .content.exams .exam_item {
            margin-left: 0;
            margin-right: 2%;
            margin-bottom: 10px;
            height: 255px;
            flex: 0 0 32%; }
            .OverlayDaillyChallenge.OverlayExams > div .content.exams .category_item:nth-child(3n+0), .OverlayDaillyChallenge.OverlayExams > div .content.exams .exam_item:nth-child(3n+0) {
              margin-right: 0; }
            .OverlayDaillyChallenge.OverlayExams > div .content.exams .category_item .c_footer, .OverlayDaillyChallenge.OverlayExams > div .content.exams .exam_item .c_footer {
              position: absolute;
              bottom: 15px;
              padding: 0 10px; }
              .OverlayDaillyChallenge.OverlayExams > div .content.exams .category_item .c_footer .c_btn, .OverlayDaillyChallenge.OverlayExams > div .content.exams .exam_item .c_footer .c_btn {
                display: flex;
                justify-content: space-around; }
                .OverlayDaillyChallenge.OverlayExams > div .content.exams .category_item .c_footer .c_btn .btn-challenge, .OverlayDaillyChallenge.OverlayExams > div .content.exams .exam_item .c_footer .c_btn .btn-challenge {
                  max-width: 100px;
                  padding: 0.2rem 0.8rem;
                  width: 50%;
                  min-width: 70px; }
          .OverlayDaillyChallenge.OverlayExams > div .content.exams .content-scroll {
            overflow-y: scroll;
            height: 100%;
            flex-wrap: wrap;
            display: flex; }
            .OverlayDaillyChallenge.OverlayExams > div .content.exams .content-scroll > div {
              height: max-content !important; } }
    @media screen and (max-width: 767px) {
      .OverlayDaillyChallenge.OverlayExams > div {
        width: 100%;
        left: 0% !important;
        margin-left: 0;
        top: 0 !important;
        height: 100%;
        border-radius: 0 !important; }
        .OverlayDaillyChallenge.OverlayExams > div .closeModal {
          top: -6px;
          right: -4px; }
        .OverlayDaillyChallenge.OverlayExams > div .content.exams {
          padding: 25px 20px 20px;
          flex-wrap: wrap;
          height: 87%;
          overflow: scroll; }
          .OverlayDaillyChallenge.OverlayExams > div .content.exams .category_item, .OverlayDaillyChallenge.OverlayExams > div .content.exams .exam_item {
            margin-left: 0;
            margin-right: 2%;
            margin-bottom: 10px;
            height: 255px;
            flex: 0 0 49%;
            padding: 0.5rem 0.3rem; }
            .OverlayDaillyChallenge.OverlayExams > div .content.exams .category_item:nth-child(2n+0), .OverlayDaillyChallenge.OverlayExams > div .content.exams .exam_item:nth-child(2n+0) {
              margin-right: 0; }
            .OverlayDaillyChallenge.OverlayExams > div .content.exams .category_item .c_footer, .OverlayDaillyChallenge.OverlayExams > div .content.exams .exam_item .c_footer {
              position: absolute;
              bottom: 15px;
              padding: 0 0.3rem; }
              .OverlayDaillyChallenge.OverlayExams > div .content.exams .category_item .c_footer .c_btn, .OverlayDaillyChallenge.OverlayExams > div .content.exams .exam_item .c_footer .c_btn {
                display: flex;
                justify-content: space-around; }
                .OverlayDaillyChallenge.OverlayExams > div .content.exams .category_item .c_footer .c_btn .btn-challenge, .OverlayDaillyChallenge.OverlayExams > div .content.exams .exam_item .c_footer .c_btn .btn-challenge {
                  max-width: 100px;
                  padding: 0.2rem 0.8rem;
                  width: 50%;
                  min-width: 70px; }
          .OverlayDaillyChallenge.OverlayExams > div .content.exams .content-scroll .bloc-content {
            height: 180px; }
            .OverlayDaillyChallenge.OverlayExams > div .content.exams .content-scroll .bloc-content > a {
              width: 60px;
              height: 60px;
              line-height: 60px;
              margin-top: 11px; }
            .OverlayDaillyChallenge.OverlayExams > div .content.exams .content-scroll .bloc-content > h4 {
              margin: 7px 0 10px;
              height: 30px;
              font-size: 11px; } }
  .OverlayDaillyChallenge.OverlayBooks > div {
    max-width: 880px;
    width: 100%;
    margin-left: 0;
    transform: translateX(-50%); }
    .OverlayDaillyChallenge.OverlayBooks > div .content.books {
      padding: 20px 15px; }
      .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item {
        margin-right: 1.6%; }
        .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item:nth-child(4n), .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item:nth-child(4n) {
          margin-right: -1%; }
        .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item .bothbox, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item .bothbox {
          width: calc(100% - 40px);
          display: flex;
          justify-content: space-between; }
        .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item .stat_icon, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item .stat_icon {
          position: absolute;
          top: 12px;
          right: 40px; }
          .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item .stat_icon i, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item .stat_icon i {
            color: #b6c3d0;
            font-size: 16px;
            cursor: pointer; }
        .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item .item_name.disp, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item .item_name.disp {
          height: 30px;
          padding-top: 5px; }
          .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item .item_name.disp .disponibility, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item .item_name.disp .disponibility {
            min-width: 150px;
            border-radius: 15px;
            color: white;
            padding: 5px 10px; }
            .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item .item_name.disp .disponibility.disponible, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item .item_name.disp .disponibility.disponible {
              background-image: linear-gradient(225deg, #72dcd6, #3eb6ad); }
            .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item .item_name.disp .disponibility.no_disponible, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item .item_name.disp .disponibility.no_disponible {
              background-color: red; }
          .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item .item_name.disp.school_child .disponibility, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item .item_name.disp.school_child .disponibility {
            font-size: 13px; }
        .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item .name, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item .name {
          height: 50px; }
          .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item .name .item_name, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item .name .item_name {
            white-space: unset !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden !important;
            text-overflow: ellipsis !important;
            display: -webkit-box;
            margin-top: 5px !important; }
      .OverlayDaillyChallenge.OverlayBooks > div .content.books .content-scroll .bloc-content {
        position: relative;
        height: 210px; }
        .OverlayDaillyChallenge.OverlayBooks > div .content.books .content-scroll .bloc-content a {
          width: 100%;
          min-width: 160px;
          height: 60%;
          min-height: 125px; }
          .OverlayDaillyChallenge.OverlayBooks > div .content.books .content-scroll .bloc-content a img {
            width: 80%;
            height: 100%; }
        .OverlayDaillyChallenge.OverlayBooks > div .content.books .content-scroll .bloc-content h4 {
          width: 90%;
          margin-left: 5%;
          font-weight: bold;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis; }
        .OverlayDaillyChallenge.OverlayBooks > div .content.books .content-scroll .bloc-content > p {
          font-family: Montserrat,sans-serif;
          font-size: 13px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          letter-spacing: .5px;
          text-align: center;
          line-height: 27px;
          color: black;
          text-transform: uppercase; }
    @media screen and (min-width: 1200px) {
      .OverlayDaillyChallenge.OverlayBooks > div .content.books {
        flex-wrap: wrap;
        height: 480px;
        overflow-y: scroll;
        margin-bottom: 10px; }
        .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item {
          margin-bottom: 10px;
          height: 255px; }
          .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item:last-of-type, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item:last-of-type {
            margin-bottom: 20px; }
          .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item .c_footer, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item .c_footer {
            position: absolute;
            bottom: 15px;
            padding: 0 10px; }
            .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item .c_footer .c_btn, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item .c_footer .c_btn {
              display: flex;
              justify-content: space-around; }
              .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item .c_footer .c_btn .btn-challenge, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item .c_footer .c_btn .btn-challenge {
                max-width: 100px;
                padding: 0.2rem 0.8rem;
                width: max-content;
                min-width: 65px; }
        .OverlayDaillyChallenge.OverlayBooks > div .content.books::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
          background-color: #F5F5F5 !important; }
        .OverlayDaillyChallenge.OverlayBooks > div .content.books::-webkit-scrollbar {
          width: 5px !important;
          height: 3px !important;
          background-color: #F5F5F5 !important; }
        .OverlayDaillyChallenge.OverlayBooks > div .content.books::-webkit-scrollbar-thumb {
          background-color: #3eb6ad !important; } }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
      .OverlayDaillyChallenge.OverlayBooks > div {
        width: 98%;
        left: 0% !important;
        margin: 1%;
        top: 0 !important;
        height: 97%;
        border-radius: 4px !important; }
        .OverlayDaillyChallenge.OverlayBooks > div .closeModal {
          top: -8px;
          right: -9px; }
        .OverlayDaillyChallenge.OverlayBooks > div .content.books {
          padding: 25px 20px 20px;
          flex-wrap: wrap;
          height: 87%;
          overflow: scroll; }
          .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item {
            margin-left: 0;
            margin-right: 2%;
            margin-bottom: 10px;
            height: 255px;
            flex: 0 0 32%; }
            .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item:nth-child(3n+0), .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item:nth-child(3n+0) {
              margin-right: 0; }
            .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item .c_footer, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item .c_footer {
              position: absolute;
              bottom: 15px;
              padding: 0 10px; }
              .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item .c_footer .c_btn, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item .c_footer .c_btn {
                display: flex;
                justify-content: space-around; }
                .OverlayDaillyChallenge.OverlayBooks > div .content.books .category_item .c_footer .c_btn .btn-challenge, .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item .c_footer .c_btn .btn-challenge {
                  max-width: 100px;
                  padding: 0.2rem 0.8rem;
                  width: 50%;
                  min-width: 70px; }
          .OverlayDaillyChallenge.OverlayBooks > div .content.books .content-scroll {
            overflow-y: scroll;
            height: 100%;
            flex-wrap: wrap;
            display: flex; }
            .OverlayDaillyChallenge.OverlayBooks > div .content.books .content-scroll > div {
              height: max-content !important; } }
    @media screen and (max-width: 767px) {
      .OverlayDaillyChallenge.OverlayBooks > div {
        width: 100%;
        left: 0% !important;
        margin-left: 0;
        top: 0 !important;
        height: 100%;
        border-radius: 0 !important; }
        .OverlayDaillyChallenge.OverlayBooks > div .closeModal {
          top: -6px;
          right: -4px; }
        .OverlayDaillyChallenge.OverlayBooks > div .content.books {
          padding: 25px 20px 20px;
          flex-wrap: wrap;
          height: 87%;
          overflow: scroll; }
          .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item {
            margin-left: 0;
            margin-right: 2%;
            margin-bottom: 10px;
            height: 255px;
            flex: 0 0 49%;
            padding: 0.5rem 0.3rem; }
            .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item:nth-child(2n+0) {
              margin-right: 0; }
            .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item .c_footer {
              position: absolute;
              bottom: 15px;
              padding: 0 0.3rem; }
              .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item .c_footer .c_btn {
                display: flex;
                justify-content: space-around; }
                .OverlayDaillyChallenge.OverlayBooks > div .content.books .a_book_item .c_footer .c_btn .btn-challenge {
                  max-width: 100px;
                  padding: 0.2rem 0.8rem;
                  width: 50%;
                  min-width: 70px; }
          .OverlayDaillyChallenge.OverlayBooks > div .content.books .content-scroll .bloc-content {
            height: 180px; }
            .OverlayDaillyChallenge.OverlayBooks > div .content.books .content-scroll .bloc-content > a {
              width: 60px;
              height: 60px;
              line-height: 60px;
              margin-top: 11px; }
            .OverlayDaillyChallenge.OverlayBooks > div .content.books .content-scroll .bloc-content > h4 {
              margin: 7px 0 10px;
              height: 30px;
              font-size: 11px; } }
  .OverlayDaillyChallenge > div {
    max-width: 1020px;
    left: 50% !important;
    margin-left: -510px;
    border-radius: 10px !important;
    padding: 0 !important;
    border: none !important;
    overflow: inherit !important;
    bottom: auto !important; }
    .OverlayDaillyChallenge > div .closeModal {
      position: absolute;
      top: -15px;
      right: -17px;
      border: 5px solid #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 17px;
      line-height: 28px;
      background-color: #3eb6ad;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
      z-index: 200; }
      .OverlayDaillyChallenge > div .closeModal.group {
        background-color: #00b2c7; }
      .OverlayDaillyChallenge > div .closeModal.classe {
        background-color: #ffb2c7; }
      .OverlayDaillyChallenge > div .closeModal.school {
        background-color: #f6951b; }
      .OverlayDaillyChallenge > div .closeModal.group_classe {
        background-color: #ffb2c7; }
      .OverlayDaillyChallenge > div .closeModal.activities {
        background-color: #AAB2C7; }
      .OverlayDaillyChallenge > div .closeModal img {
        max-width: 13px; }
    .OverlayDaillyChallenge > div .header {
      background: #fff;
      height: 62px;
      float: left;
      width: 100%;
      padding: 0 14px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px; }
      .OverlayDaillyChallenge > div .header .bloc-title {
        float: left;
        width: 178px;
        height: 76px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        background-color: #3eb6ad;
        text-transform: uppercase;
        position: relative;
        z-index: 9; }
        .OverlayDaillyChallenge > div .header .bloc-title.group {
          background-color: #00b2c7; }
        .OverlayDaillyChallenge > div .header .bloc-title.classe {
          background-color: #ffb2c7; }
        .OverlayDaillyChallenge > div .header .bloc-title.school {
          background-color: #f6951b; }
        .OverlayDaillyChallenge > div .header .bloc-title.activities {
          background-color: #8a78f8; }
        .OverlayDaillyChallenge > div .header .bloc-title h3 {
          margin: 0;
          font-family: "Montserrat", sans-serif;
          font-size: 12px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.8px;
          color: #ffffff;
          text-align: center;
          padding: 25px 0; }
          .OverlayDaillyChallenge > div .header .bloc-title h3.title-superheror {
            padding: 23px 4px; }
      .OverlayDaillyChallenge > div .header .backHeader {
        font-family: "Montserrat", sans-serif;
        font-size: 11px;
        font-weight: 800;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1.1px;
        text-align: center;
        color: #9a9898;
        text-transform: uppercase;
        border-radius: 19px;
        border: solid 2px #3eb6ad;
        padding: 10px 14px 10px 11px;
        float: left;
        margin: 12px 23px 0 0; }
        .OverlayDaillyChallenge > div .header .backHeader img {
          margin-right: 5px; }
      .OverlayDaillyChallenge > div .header .header-exercices {
        float: left;
        margin-left: 150px;
        margin-top: 22px; }
        .OverlayDaillyChallenge > div .header .header-exercices p {
          font-family: "Montserrat", sans-serif;
          font-size: 14px;
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          text-align: center;
          color: #555555; }
      .OverlayDaillyChallenge > div .header .header-right {
        float: right;
        margin-top: 20px; }
        .OverlayDaillyChallenge > div .header .header-right .challenge-scores {
          font-family: "Montserrat", sans-serif;
          font-size: 12px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 1px;
          text-align: right;
          color: #3eb6ad;
          border-radius: 17px;
          border: 2px solid #3eb6ad;
          background-color: #ffffff;
          padding: 5px 17px; }
        .OverlayDaillyChallenge > div .header .header-right .challenge-global {
          float: right;
          margin-left: 45px;
          margin-top: 3px; }
          .OverlayDaillyChallenge > div .header .header-right .challenge-global p {
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            text-align: right;
            color: #6c6c6c; }
            .OverlayDaillyChallenge > div .header .header-right .challenge-global p span {
              padding: 5px 33px;
              border-radius: 40.7px;
              border: solid 1px #ffdf8e;
              background-color: #ffffff;
              margin-left: 20px; }
      .OverlayDaillyChallenge > div .header .header-left {
        float: left; }
        .OverlayDaillyChallenge > div .header .header-left .back {
          margin-top: 12px;
          margin-left: 17px;
          box-shadow: 0px 0px 7px -3px #c5c7c9;
          padding: 10px;
          width: 40px;
          height: 40px;
          cursor: pointer; }
          .OverlayDaillyChallenge > div .header .header-left .back img {
            width: 100%;
            height: 100%; }
    .OverlayDaillyChallenge > div .content {
      background-color: #f4f5f6;
      float: left;
      width: 100%;
      padding: 30px 60px 15px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px; }
      .OverlayDaillyChallenge > div .content .challengeexercice {
        height: 400px;
        text-align: center; }
        .OverlayDaillyChallenge > div .content .challengeexercice .view-student-xblock {
          height: 400px;
          position: relative; }
      .OverlayDaillyChallenge > div .content .content-scroll {
        height: 468px;
        overflow-y: auto; }
        .OverlayDaillyChallenge > div .content .content-scroll::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5; }
        .OverlayDaillyChallenge > div .content .content-scroll::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5; }
        .OverlayDaillyChallenge > div .content .content-scroll::-webkit-scrollbar-thumb {
          background-color: #3eb6ad; }
      .OverlayDaillyChallenge > div .content .bloc-width {
        float: left;
        width: 20%; }
        .OverlayDaillyChallenge > div .content .bloc-width .bloc-content {
          object-fit: contain;
          border-radius: 4px;
          box-shadow: 0px 0px 13px -9px #8e8e8e;
          background-color: #ffffff;
          margin: 0 10px 20px;
          text-align: center;
          padding-bottom: 7px; }
          .OverlayDaillyChallenge > div .content .bloc-width .bloc-content a {
            width: 70px;
            height: 70px;
            display: inline-block;
            line-height: 70px;
            margin-top: 17px; }
            .OverlayDaillyChallenge > div .content .bloc-width .bloc-content a img {
              max-width: 70px; }
          .OverlayDaillyChallenge > div .content .bloc-width .bloc-content h4 {
            margin: 11px 0 15px;
            font-family: "Montserrat", sans-serif;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            text-transform: uppercase; }
          .OverlayDaillyChallenge > div .content .bloc-width .bloc-content h5 {
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #1b2437;
            margin: 0 0 6px; }
          .OverlayDaillyChallenge > div .content .bloc-width .bloc-content p {
            font-family: "Montserrat", sans-serif;
            font-size: 11.5px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #b1bfcd;
            margin: 0; }
          .OverlayDaillyChallenge > div .content .bloc-width .bloc-content.bloc-maths {
            border: solid 1px #7cc2c3;
            border-bottom: 6px solid #7dc3c3; }
            .OverlayDaillyChallenge > div .content .bloc-width .bloc-content.bloc-maths h4 {
              color: black; }
          .OverlayDaillyChallenge > div .content .bloc-width .bloc-content.bloc-physique {
            border: solid 1px #7792c6;
            border-bottom: 6px solid #7792c6; }
            .OverlayDaillyChallenge > div .content .bloc-width .bloc-content.bloc-physique h4 {
              color: #7792c6; }
          .OverlayDaillyChallenge > div .content .bloc-width .bloc-content.bloc-chimie {
            border: solid 1px #ef745b;
            border-bottom: 6px solid #ef745b; }
            .OverlayDaillyChallenge > div .content .bloc-width .bloc-content.bloc-chimie h4 {
              color: #ef745b; }
          .OverlayDaillyChallenge > div .content .bloc-width .bloc-content.bloc-arabe {
            border: solid 1px #46cdf1;
            border-bottom: 6px solid #46cdf1; }
            .OverlayDaillyChallenge > div .content .bloc-width .bloc-content.bloc-arabe h4 {
              color: #46cdf1; }
          .OverlayDaillyChallenge > div .content .bloc-width .bloc-content.bloc-french {
            border: solid 1px #2858f3;
            border-bottom: 6px solid #2858f3; }
            .OverlayDaillyChallenge > div .content .bloc-width .bloc-content.bloc-french h4 {
              color: #2858f3; }
      .OverlayDaillyChallenge > div .content .bloc-next {
        margin-top: 16px;
        text-align: center;
        width: 100%;
        float: left; }
        .OverlayDaillyChallenge > div .content .bloc-next a {
          padding: 9px 58px;
          font-family: "Montserrat", sans-serif;
          font-size: 12px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: 0.6px;
          text-align: center;
          color: #ffffff;
          text-transform: uppercase;
          background: #3eb6ad;
          border-radius: 16.7px;
          display: inline-block; }

@media screen and (max-width: 1199px) {
  .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers:before {
    background: none; }
  .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div {
    border-radius: 0 !important;
    height: 100%;
    top: 0px !important;
    left: 0px !important;
    right: 0px !important;
    bottom: 0px !important;
    display: flex;
    flex-direction: column; }
    .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .header {
      float: none;
      margin: 0;
      border-radius: 0;
      width: 100%;
      height: 66px;
      padding: 8px 20px !important; }
      .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .header .headerLeft {
        padding: 0;
        font-size: 16px; }
        .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .header .headerLeft img {
          margin: 0; }
        .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .header .headerLeft .back {
          width: 31px;
          height: 31px;
          border-radius: 31px;
          border: 2px solid #3eb6ad;
          background: #FFF;
          text-align: center;
          line-height: 24px;
          float: left;
          opacity: 1;
          margin-right: 5px;
          margin-top: 9px; }
          .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .header .headerLeft .back img {
            width: 15px;
            height: auto;
            margin: 0; }
    .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content {
      float: none;
      padding: 5px;
      text-align: center;
      display: flex;
      flex-direction: column;
      height: calc(100% - 66px); }
      .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .headerRight {
        height: 30px;
        border-radius: 15px;
        border: solid 1px #3eb6ad;
        margin: 15px auto 30px auto;
        display: inline-block;
        width: max-content; }
        .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .headerRight span {
          display: inline-block;
          width: 28px;
          height: 28px;
          border-radius: 28px;
          text-align: center;
          line-height: 28px;
          font-size: 12px;
          color: #a1a9b3;
          font-weight: 400; }
          .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .headerRight span.active {
            background: #3eb6ad;
            color: #fff;
            font-weight: 600; }
      .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column; }
        .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .view-student-xblock {
          height: 100%; }
        .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice .object.focus-mobile {
          position: fixed;
          top: 0px;
          width: 95%; }
        .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice > div {
          width: 100%; }
          .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice > div:first-child {
            flex: 1;
            height: calc(100% - 40px); }
          .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice > div.contentBottom {
            background: transparent;
            height: auto;
            padding: 5px 0; } }
          @media screen and (max-width: 1199px) and (orientation: portrait) {
            .OverlayDaillyChallenge.OverlayChallengeScore.OverlayMatchAnswers > div .content .challengeexercice > div.contentBottom {
              visibility: hidden; } }

@media screen and (max-width: 1199px) {
  .selectInput {
    height: 35px; }
  .dialogOverlay.dialogFormOverlay {
    background: #fff;
    display: block;
    padding: 0; }
    .dialogOverlay.dialogFormOverlay .dialog.dialogPrint {
      max-width: 1000px;
      width: 1000px; }
    .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne.schoolServer {
      margin: 0 !important; }
    .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50.school100 {
      width: 100% !important;
      margin: 0 0 25px !important; }
    .dialogOverlay.dialogFormOverlay .dialog.dialogPrinView {
      border-left: none;
      position: relative;
      min-height: calc(100vh + 10px);
      box-shadow: none;
      border: none;
      width: 100%;
      padding: 25px 40px;
      opacity: 1; }
    .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack {
      max-width: 100%;
      width: 100%;
      min-height: 100vh;
      height: auto;
      border: none;
      box-shadow: none; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack h3 {
        font-size: 20px;
        margin: 60px 0; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack .buyPack {
        max-width: 640px;
        margin: auto; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack .buyBack {
        left: 20px;
        top: 20px; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack .buyClose {
        right: 20px;
        top: 20px; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack .buyRight {
        width: 269px;
        height: 291px; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack .buyLeft {
        width: 279px;
        height: 262px; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack.dialogBuyPackage {
        max-width: 100%;
        width: 100%;
        min-height: 100vh;
        border: none;
        box-shadow: none; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack.dialogBuyPackage .packageTop {
          max-width: 640px;
          margin: 40px auto;
          float: none;
          overflow: hidden;
          padding: 20px; }
          .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack.dialogBuyPackage .packageTop h2 {
            font-size: 25px;
            line-height: normal;
            font-weight: 400;
            color: #4c5361; }
            .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack.dialogBuyPackage .packageTop h2::before {
              display: none; }
            .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack.dialogBuyPackage .packageTop h2 img {
              display: initial;
              margin-right: 5px; }
          .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack.dialogBuyPackage .packageTop a {
            width: 200px;
            height: 47px;
            line-height: 41px;
            margin-top: -5px; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack.dialogBuyPackage .packages {
          max-width: 640px;
          margin: auto; }
    .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool {
      border-left: none;
      position: relative;
      min-height: calc(100vh + 10px);
      box-shadow: none;
      border: none;
      width: 100%;
      padding: 25px 40px;
      opacity: 1; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogAbs {
        width: 100%;
        padding: 25px 20px;
        min-width: 100%; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogParents {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .upload {
          padding-bottom: 20px; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .school-ligne .school-ligne-w100 {
          margin-bottom: 15px; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogParents.dialogParentsInformations .add-school-form .school-ligne.school-ligne-center {
          position: relative;
          left: auto;
          right: auto; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogParents::before {
          right: 20px; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogParents .schoolClose {
          box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: -webkit-linear-gradient(225deg, #72dcd6, #3eb6ad);
          background-image: linear-gradient(225deg, #72dcd6, #3eb6ad); }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogParents .add-school {
          max-width: 600px;
          margin: auto; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogAddProfessor.dialogNewClasse {
        padding: 25px 45px; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogAddProfessor.dialogNewClasse .newClassesTop {
          margin-top: 25px;
          margin-bottom: 0; }
          .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogAddProfessor.dialogNewClasse .newClassesTop img {
            max-width: 70px; }
          .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogAddProfessor.dialogNewClasse .newClassesTop span {
            width: 140px;
            margin-left: 15px; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogAddProfessor.dialogNewClasse .add-school-form .school-ligne.school-ligne-center.schoolBottom {
          position: absolute;
          bottom: 10px;
          left: 0;
          right: 0; }
          .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogAddProfessor.dialogNewClasse .add-school-form .school-ligne.school-ligne-center.schoolBottom button.add.cancel {
            background: #fff;
            color: #3eb6ad;
            box-shadow: none;
            border: solid 0.6px #d1dbe2; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogAddProfessor:before {
        display: none; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogAddProfessor .add-school-form .school-ligne {
        margin-bottom: 25px; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogAddProfessor .add-school-form .school-ligne .intl-tel-input {
          width: 100%; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogAddProfessor .add-school-form .school-ligne.school-ligne-margin {
          margin: 0 0 25px; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogAddProfessor .newClassesTop {
        float: left;
        width: 100%;
        margin-bottom: 25px; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogAddProfessor .newClassesTop img {
          max-width: 55px; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogAddProfessor .newClassesTop span {
          width: 115px;
          height: 30px;
          text-align: center;
          object-fit: contain;
          border-radius: 49.5px;
          background-color: #3eb6ad;
          font-family: Montserrat;
          font-size: 12px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 30px;
          letter-spacing: normal;
          color: rgba(255, 255, 255, 0.8);
          display: inline-block;
          margin-left: 10px; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .schoolClose {
        background: #d8dfe5;
        box-shadow: none;
        width: 35px;
        height: 35px;
        line-height: 35px;
        font-size: 34px;
        top: 15px;
        right: 15px; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool h2 {
        font-size: 16px;
        margin-bottom: 35px; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne {
        margin-bottom: 25px; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin {
          margin: 0 0 25px;
          width: 100%; }
          .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .w100 {
            width: 100% !important;
            margin: 0 0 25px !important; }
            .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .w100.legend {
              margin-bottom: 50px !important; }
          .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 {
            padding: 0;
            width: calc(50% - 10px) !important;
            margin: 0 10px 0 !important; }
            .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50:nth-child(1) {
              margin-left: 0 !important; }
            .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50:nth-child(2) {
              margin-right: 0 !important; }
            .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50:last-child {
              margin-bottom: 0; }
            .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 .flagSelect {
              height: 35px; }
              .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 .flagSelect .select {
                height: 34px;
                border: none;
                border-radius: 4px !important;
                border: none !important;
                font-weight: 500;
                font-family: Montserrat;
                font-size: 14px;
                color: #1b2437;
                padding: 0 5px; }
                .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 .flagSelect .select select {
                  height: 33.5px; }
                .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 .flagSelect .select i {
                  top: 8px; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-diploma {
          margin-bottom: 25px; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne.school-ligne-center {
          position: absolute;
          bottom: 5px;
          left: 0;
          right: 0; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne .error {
          color: red; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne button:not(.btn) {
          height: 40px; }
          .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne button:not(.btn).add, .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne button:not(.btn).cancel, .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne button:not(.btn).save {
            height: 40px; }
            .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne button:not(.btn).add.new, .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne button:not(.btn).cancel.new, .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne button:not(.btn).save.new {
              width: 100%;
              margin: 0 0 20px; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne input {
          height: 35px; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne .select {
          height: 35px; }
          .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne .select select {
            height: 34px;
            border-radius: 4px !important;
            border: none !important;
            font-weight: 500;
            font-family: Montserrat;
            font-size: 14px;
            color: #1b2437;
            padding: 0 10px; }
          .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne .select i {
            top: 10px; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .add-school-form .school-ligne .RadioLegend {
          margin: 0 0 13px;
          width: 100%; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogAddProfessor .add-school-form .school-ligne.school-ligne.school-ligne-center {
        position: relative;
        left: auto;
        bottom: auto;
        right: auto;
        margin-bottom: 20px; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogAddProfessor .add-school-form .school-ligne.school-ligne-classes {
        margin-top: 15px; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogAddProfessor .add-school-form .school-ligne .RadioLegend {
        width: 25%; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogAddProfessor .add-school-form .school-ligne .RadioLegend > legend {
          font-size: 11px;
          margin-left: 3px;
          margin-top: 4px; }
    .dialogOverlay.dialogFormOverlay.dialogFonctionalityDontExist {
      border-radius: 5%;
      background: rgba(100, 115, 140, 0.9);
      width: 100%;
      max-height: 100%;
      border-radius: 0; }
      .dialogOverlay.dialogFormOverlay.dialogFonctionalityDontExist .dialogAddSchool {
        border-left: 10px solid #00b3a9; } }
    @media screen and (max-width: 1199px) and (max-width: 479px) {
      .dialogOverlay.dialogFormOverlay.dialogFonctionalityDontExist .dialogAddSchool {
        width: 90%;
        max-height: 300px;
        min-height: 300px;
        top: calc(50% - 150px); }
        .dialogOverlay.dialogFormOverlay.dialogFonctionalityDontExist .dialogAddSchool::before {
          content: none; } }
    @media screen and (max-width: 1199px) and (max-width: 1199px) and (min-width: 480px) {
      .dialogOverlay.dialogFormOverlay.dialogFonctionalityDontExist {
        box-shadow: 1px 2px 19px 1px #bdbdbd; }
        .dialogOverlay.dialogFormOverlay.dialogFonctionalityDontExist .dialogAddSchool {
          width: 90%;
          max-height: 300px;
          min-height: 300px;
          top: calc(50% - 150px); }
          .dialogOverlay.dialogFormOverlay.dialogFonctionalityDontExist .dialogAddSchool::before {
            content: none; } }

@media screen and (max-width: 1199px) {
  .dialogOverlay.dialogOverlayAddGroup {
    background: transparent; }
  .OverlayDaillyChallenge.OverlayChangePassword > div {
    max-width: 300px;
    margin-left: -150px;
    top: 80px !important; }
    .OverlayDaillyChallenge.OverlayChangePassword > div .content {
      padding: 25px 15px 17px; }
    .OverlayDaillyChallenge.OverlayChangePassword > div .closeModal {
      top: -45px;
      right: 0; }
  .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div {
    max-width: 740px;
    margin-left: -370px;
    right: 0 !important; }
  .OverlayDaillyChallenge.OverlayTextbookExercice > div {
    margin-left: -440px; }
    .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width {
      width: 25%; }
  .Group {
    bottom: 8px;
    right: 8px;
    width: 50px; }
  .navigation-mobile {
    background: #67cbcb;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999999;
    overflow: hidden;
    height: 100vh; }
    .navigation-mobile .logo {
      text-align: center; }
    .navigation-mobile a.close-mobile {
      padding: 0 10px 1px;
      margin-left: 10px;
      border: 1px solid #ffffff;
      border-radius: 50%;
      display: inline-block;
      background: #3eb6ad;
      color: #ffffff !important;
      font-size: 22px;
      margin-top: 20px; }
    .navigation-mobile .content-mobile {
      height: calc(100vh - 150px);
      vertical-align: middle;
      display: table;
      width: 100%; }
      .navigation-mobile .content-mobile .center-mobile {
        display: table-cell;
        vertical-align: middle;
        text-align: center; }
        .navigation-mobile .content-mobile .center-mobile .translate .translate-bloc {
          background: #42b8b0;
          display: inline-block;
          margin: 0 2px;
          padding: 7px 17px;
          border-radius: 40px;
          color: #fff;
          font-size: 10px;
          font-weight: bold; }
          .navigation-mobile .content-mobile .center-mobile .translate .translate-bloc img {
            width: 10px;
            margin-left: 9px; }
        .navigation-mobile .content-mobile .center-mobile nav {
          max-width: 150px; }
          .navigation-mobile .content-mobile .center-mobile nav ul li {
            margin-left: 0;
            margin-bottom: 10px;
            width: 100%; }
            .navigation-mobile .content-mobile .center-mobile nav ul li a {
              width: 100%;
              font-size: 14px; }
  .logo img {
    width: 100px; }
  .header-right {
    text-align: right; }
  .signin {
    text-align: center;
    padding: 0;
    width: 150px;
    margin: 30px auto 0; }
    .signin li {
      background: #3eb6ad;
      width: 100%;
      padding: 4px 9px 7px;
      border-radius: 20px;
      margin-bottom: 10px;
      border: 2px solid #ffffff; }
      .signin li:last-child {
        margin-bottom: 0; }
      .signin li a {
        font-size: 10px;
        width: 100%;
        letter-spacing: 1px;
        text-transform: uppercase; }
        .signin li a img {
          width: 15px;
          margin-right: 5px; }
  section {
    overflow-x: hidden !important; }
  header {
    padding-top: 5px; }
  .dialogMobile {
    background: rgba(100, 115, 140, 0.9); }
    .dialogMobile .dialog {
      border: none;
      padding: 5px 4px 7px 4px;
      border-radius: 15px;
      background: #f3f3f3;
      float: left;
      margin-top: 40px;
      max-width: 100%; }
      .dialogMobile .dialog.dialogSignIn.dialogOubliePassword {
        min-width: 100%;
        max-width: 100%;
        padding: 20px 12px 20px 20px; }
        .dialogMobile .dialog.dialogSignIn.dialogOubliePassword .closeRegister {
          display: block; }
      .dialogMobile .dialog .closeRegister {
        display: block;
        position: absolute;
        top: -37px;
        right: 0;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        color: #ffff;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        border: 5px solid #fff;
        background: #13bbb3;
        cursor: pointer; }
      .dialogMobile .dialog .dialog-top {
        background: #13bbb3;
        border-radius: 25px;
        padding: 1px;
        float: left;
        width: 100%; }
        .dialogMobile .dialog .dialog-top a {
          width: 50%;
          float: left;
          text-align: center;
          font-size: 13px;
          letter-spacing: 1px;
          font-weight: normal;
          text-transform: uppercase;
          padding: 8px;
          border-radius: 28px;
          border: 2px solid transparent;
          color: rgba(255, 255, 255, 0.4); }
          .dialogMobile .dialog .dialog-top a.active {
            font-weight: bold;
            background: #80c9ca;
            border: 2px solid #fff;
            color: #fff; }
      .dialogMobile .dialog .dialog-content {
        float: left;
        width: 100%;
        padding: 25px 8px 25px 35px; }
        .dialogMobile .dialog .dialog-content .dialog-left h3 {
          font-size: 14px;
          line-height: 1.6; }
        .dialogMobile .dialog .dialog-content .dialog-left .textInput label {
          font-size: 10px;
          color: #949494; }
        .dialogMobile .dialog .dialog-content .dialog-left button {
          width: 100px;
          padding: 4px 0 41px; }
        .dialogMobile .dialog .dialog-content .dialog-left .password-forgot a {
          font-size: 9px; }
        .dialogMobile .dialog .dialog-content .radioLegend span {
          padding-left: 0;
          padding-right: 5px;
          padding-bottom: 0;
          float: left; }
        .dialogMobile .dialog .dialog-content .radioLegend legend {
          width: auto;
          float: left;
          display: inline-block;
          border: none;
          margin: 18px 39px 0 0; }
        .dialogMobile .dialog .dialog-content .radioLegend.radioLegendLast legend {
          margin-right: 0; }
      .dialogMobile .dialog .dialog-right {
        margin-top: 40px;
        margin-bottom: -49px;
        margin-right: 0; }
        .dialogMobile .dialog .dialog-right span {
          margin-right: 0; }
          .dialogMobile .dialog .dialog-right span:before {
            left: 43px; }
  section {
    overflow: initial; }
    section.one {
      box-shadow: 0px -10px 5px -12px rgba(0, 0, 0, 0.6);
      padding-top: 20px;
      position: relative;
      min-height: auto; }
      section.one:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 0px;
        box-shadow: 0px -2px 6px 1px rgba(0, 0, 0, 0.1);
        z-index: 999; }
      section.one .slider {
        margin: 5px 10px; }
        section.one .slider .carousel h3 {
          font-size: 25px; }
        section.one .slider .carousel .banner-anim {
          min-height: 435px; }
        section.one .slider .carousel .banner-anim-elem {
          min-height: 430px; }
          section.one .slider .carousel .banner-anim-elem .bg {
            min-height: 430px; }
      section.one .news-feed h3 {
        color: #46bfb6;
        margin: 50px 0 10px;
        text-align: center; }
      section.one .before-one {
        top: -15px;
        left: -15px;
        right: -15px;
        bottom: auto; }
      section.one .slider {
        box-shadow: -4px 22px 61px 0 rgba(0, 0, 0, 0.15); }
    section.two .subjects h2 {
      font-size: 19px; }
    section.two .subjects .subjects-bg {
      max-width: 730px;
      background-size: 730px auto; }
      section.two .subjects .subjects-bg .subjects-bbg {
        padding: 40px 0 50px 10px;
        max-width: 564px; }
    section.two .subjects .arrow-next,
    section.two .subjects .arrow-prev {
      top: 47%; }
    section.section-news-feeds {
      text-align: center;
      padding: 40px 0 85px; }
      section.section-news-feeds h3 {
        position: relative;
        margin: 0 0 53px;
        top: 0;
        border: 1.5px solid #fff;
        background: #80c9ca;
        display: inline-block;
        padding: 12px 30px;
        border-radius: 50px;
        font-size: 17px; }
      section.section-news-feeds .section-feeds {
        padding: 0 60px; }
        section.section-news-feeds .section-feeds .section-feed {
          max-width: 47%; }
          section.section-news-feeds .section-feeds .section-feed .news-article:before {
            left: 5%;
            width: 90%;
            bottom: -85px; }
          section.section-news-feeds .section-feeds .section-feed .news-article p {
            font-size: 11px;
            line-height: 12px; }
            section.section-news-feeds .section-feeds .section-feed .news-article p.news-date {
              display: none; }
            section.section-news-feeds .section-feeds .section-feed .news-article p.link a {
              font-size: 9px;
              min-width: 110px; }
          section.section-news-feeds .section-feeds .section-feed.feed-left {
            margin-right: 30px; }
          section.section-news-feeds .section-feeds .section-feed.feed-right {
            margin-left: 30px; }
    section.koolskools-notes {
      padding: 35px 0 140px; }
      section.koolskools-notes .container-koolskools {
        max-width: 780px; }
        section.koolskools-notes .container-koolskools h3 {
          margin-bottom: 43px; }
      section.koolskools-notes main:before {
        top: auto;
        bottom: 30px;
        left: -15px; }
  footer .bloc-footer {
    width: 33.33%; }
  .OverlayTextbook {
    background-color: white;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999; }
    .OverlayTextbook:after {
      content: "";
      width: 467px;
      height: 318px;
      position: absolute;
      bottom: 70px;
      left: 50%;
      margin-left: -150px;
      background: url("../img/textbook_bg02.png") no-repeat scroll transparent; }
    .OverlayTextbook.OverlayActivity > div .header {
      border-radius: 0px;
      width: 100%;
      margin: 0; }
    .OverlayTextbook > div {
      top: 0 !important;
      left: 0 !important;
      bottom: 0 !important;
      margin-left: 0;
      height: 100vh;
      max-width: 100%;
      bottom: auto !important;
      padding: 0 !important;
      border: none !important;
      border-radius: 0 !important;
      overflow: inherit !important;
      box-shadow: none;
      opacity: 1;
      float: left;
      width: 100%; }
      .OverlayTextbook > div .modal-fuseesLeft {
        width: 158px;
        height: 102px;
        position: absolute;
        bottom: 150px;
        left: 0;
        background: url("../img/textbook_bg03.png") no-repeat scroll transparent; }
      .OverlayTextbook > div .modal-fuseesRight {
        width: 94px;
        height: 122px;
        position: absolute;
        bottom: 160px;
        right: 25px;
        background: url("../img/textbook_bg04.png") no-repeat scroll transparent; }
      .OverlayTextbook > div .backModal {
        float: left;
        width: 100%;
        box-shadow: 0px 0px 7px -3px #c5c7c9;
        padding: 10px;
        margin-bottom: 30px; }
        .OverlayTextbook > div .backModal a {
          width: 30px;
          height: 30px;
          display: block;
          border-radius: 30px;
          border: 2px solid #41b4ac;
          text-align: center;
          line-height: 25px; }
          .OverlayTextbook > div .backModal a i {
            color: #41b4ac;
            font-size: 15px; }
      .OverlayTextbook > div .header {
        margin: 0 10px;
        border-radius: 35px;
        width: calc(100% - 20px);
        padding: 0 15px; }
        .OverlayTextbook > div .header ul {
          justify-content: space-between; }
          .OverlayTextbook > div .header ul li a {
            font-size: 11px; }
      .OverlayTextbook > div .todoCompleted div {
        height: 43px; }
        .OverlayTextbook > div .todoCompleted div a {
          font-size: 10.4px;
          padding: 14px 25px; }
      .OverlayTextbook > div .exercices-table table thead tr th {
        padding-bottom: 15px;
        font-size: 10.5px; }
      .OverlayTextbook > div .exercices-table table tbody tr td {
        font-size: 9.5px; }
        .OverlayTextbook > div .exercices-table table tbody tr td:nth-child(3) {
          width: 16%; }
  .OverlayScholarBooks {
    background-color: white;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999; }
    .OverlayScholarBooks:after {
      content: "";
      width: 467px;
      height: 318px;
      position: absolute;
      bottom: 70px;
      left: 50%;
      margin-left: -150px;
      background: url("../img/textbook_bg02.png") no-repeat scroll transparent; }
    .OverlayScholarBooks > div {
      top: 0 !important;
      left: 0 !important;
      bottom: 0 !important;
      margin-left: 0;
      height: 100vh;
      max-width: 100%;
      bottom: auto !important;
      padding: 0 !important;
      border: none !important;
      border-radius: 0 !important;
      overflow: inherit !important;
      box-shadow: none;
      opacity: 1;
      float: left;
      width: 100%; }
      .OverlayScholarBooks > div .modal-fuseesLeft {
        width: 158px;
        height: 102px;
        position: absolute;
        bottom: 150px;
        left: 0;
        background: url("../img/textbook_bg03.png") no-repeat scroll transparent; }
      .OverlayScholarBooks > div .modal-fuseesRight {
        width: 94px;
        height: 122px;
        position: absolute;
        bottom: 160px;
        right: 25px;
        background: url("../img/textbook_bg04.png") no-repeat scroll transparent; }
      .OverlayScholarBooks > div .backModal {
        float: left;
        width: 100%;
        box-shadow: 0px 0px 7px -3px #c5c7c9;
        padding: 10px;
        margin-bottom: 30px; }
        .OverlayScholarBooks > div .backModal a {
          width: 30px;
          height: 30px;
          display: block;
          border-radius: 30px;
          border: 2px solid #41b4ac;
          text-align: center;
          line-height: 25px; }
          .OverlayScholarBooks > div .backModal a i {
            color: #41b4ac;
            font-size: 15px; }
      .OverlayScholarBooks > div .header {
        margin: 0 10px;
        border-radius: 35px;
        width: calc(100% - 20px);
        padding: 0 15px; }
        .OverlayScholarBooks > div .header ul {
          justify-content: space-between; }
          .OverlayScholarBooks > div .header ul li a {
            font-size: 11px; }
  .OverlayViewScholarBook {
    background-color: white;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999; }
    .OverlayViewScholarBook:after {
      content: "";
      width: 467px;
      height: 318px;
      position: absolute;
      bottom: 70px;
      left: 50%;
      margin-left: -150px;
      background: url("../img/textbook_bg02.png") no-repeat scroll transparent; }
    .OverlayViewScholarBook > div {
      top: 0 !important;
      left: 0 !important;
      bottom: 0 !important;
      margin-left: 0;
      height: 100vh;
      max-width: 100%;
      bottom: auto !important;
      padding: 0 !important;
      border: none !important;
      border-radius: 0 !important;
      overflow: inherit !important;
      box-shadow: none;
      opacity: 1;
      float: left;
      width: 100%; }
      .OverlayViewScholarBook > div .modal-fuseesLeft {
        width: 158px;
        height: 102px;
        position: absolute;
        bottom: 150px;
        left: 0;
        background: url("../img/textbook_bg03.png") no-repeat scroll transparent; }
      .OverlayViewScholarBook > div .modal-fuseesRight {
        width: 94px;
        height: 122px;
        position: absolute;
        bottom: 160px;
        right: 25px;
        background: url("../img/textbook_bg04.png") no-repeat scroll transparent; }
      .OverlayViewScholarBook > div .backModal {
        float: left;
        width: 100%;
        box-shadow: 0px 0px 7px -3px #c5c7c9;
        padding: 10px;
        margin-bottom: 30px; }
        .OverlayViewScholarBook > div .backModal a {
          width: 30px;
          height: 30px;
          display: block;
          border-radius: 30px;
          border: 2px solid #41b4ac;
          text-align: center;
          line-height: 25px; }
          .OverlayViewScholarBook > div .backModal a i {
            color: #41b4ac;
            font-size: 15px; } }

@media screen and (max-width: 991px) {
  .OverlayDaillyChallenge.OverlayTextbookExercice > div {
    max-width: 700px;
    margin-left: -350px; }
    .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width {
      width: 33.33%; }
  .OverlayDaillyChallenge.OverlayFriends:not(.OverlaySchool) > div {
    padding-left: 20px;
    padding-right: 20px;
    left: 30px !important;
    right: 30px !important;
    margin-left: 0 !important; }
    .OverlayDaillyChallenge.OverlayFriends:not(.OverlaySchool) > div .friend-content .friend-content-bloc .friend-bloc {
      width: 14.285%; }
  section.section-news-feeds:before {
    width: 80%; }
  section.section-news-feeds .section-feeds {
    padding: 0; }
    section.section-news-feeds .section-feeds .section-feed .news-article p.link a {
      min-width: 92px;
      padding: 0; } }

@media screen and (max-width: 1200px) {
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div {
    padding: 20px !important;
    margin: 0px;
    max-width: 100%; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .text-right {
      text-align: center !important; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceFilterRight select {
      max-width: 60px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader h2 {
      text-align: left;
      font-size: 21px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .onClose {
      position: absolute;
      cursor: pointer;
      top: 10px;
      right: 10px;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      background: #d8dfe5;
      text-align: center;
      color: #fff;
      font-size: 40px;
      line-height: 40px;
      z-index: 20; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter {
      float: left;
      width: 100%;
      margin: 25px 0 5px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search {
        height: 32px;
        float: none;
        margin: auto;
        width: 100%; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search input {
          height: 30px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search i {
          top: 8px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight {
        text-align: center;
        width: 100%; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight > div {
          margin: 20px 0 0;
          float: none;
          display: inline-block;
          width: 33.33%; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item > div:not(.RadioLegend) {
      min-height: 100%;
      position: relative; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .RadioLegend {
      width: 100%;
      margin: 0 0 10px;
      /*overflow: hidden;*/
      padding: 4px 0; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .RadioLegend legend p {
        margin: 0;
        max-height: 50px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceBottom {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      padding: 10px; }
  .OverlayTextbook tr.empty_tr {
    height: 120px; }
  .OverlayScholarBooks tr.empty_tr {
    height: 120px; } }

@media screen and (max-width: 767px) {
  .dialogOverlay.dialogMobileGallery {
    padding: 0px; }
  .dialogOverlay .dialog.dialogRegister.dialogContinueCompte {
    min-width: 100%;
    padding-left: 17px; }
    .dialogOverlay .dialog.dialogRegister.dialogContinueCompte .dialog-left {
      width: 100%; }
    .dialogOverlay .dialog.dialogRegister.dialogContinueCompte .dialog-right {
      width: 150px;
      position: absolute;
      bottom: 33px;
      right: 10px; }
  .dialogOverlay .dialog.dialogCandid.dialogContinueCompte {
    min-width: 100%;
    padding-left: 17px; }
    .dialogOverlay .dialog.dialogCandid.dialogContinueCompte .dialog-left {
      width: 100%; }
    .dialogOverlay .dialog.dialogCandid.dialogContinueCompte .dialog-right {
      width: 150px;
      position: absolute;
      bottom: 33px;
      right: 10px; }
  .dialogOverlay.dialogOverlayAddGroup {
    padding: 5px 10px;
    background: #fff;
    opacity: 1;
    overflow: hidden; }
    .dialogOverlay.dialogOverlayAddGroup .dialog.dialogMessagerie.dialogAddGroup {
      max-width: 100%;
      height: calc(100% - 15px);
      overflow-y: scroll;
      opacity: 1; }
      .dialogOverlay.dialogOverlayAddGroup .dialog.dialogMessagerie.dialogAddGroup .closeModal {
        display: none; }
      .dialogOverlay.dialogOverlayAddGroup .dialog.dialogMessagerie.dialogAddGroup form .button {
        padding-top: 15px;
        margin-top: 0;
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        border-top: 1px solid #fbfbfb; }
        .dialogOverlay.dialogOverlayAddGroup .dialog.dialogMessagerie.dialogAddGroup form .button button {
          padding: 0;
          height: 32px;
          font-size: 13px; }
  .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .school-ligne.schoolMargin {
    margin-bottom: 0 !important; }
  .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .school-ligne.school-ligne-center {
    margin: 0 !important; }
    .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .school-ligne.school-ligne-center .add, .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .school-ligne.school-ligne-center .cancel {
      padding: 0;
      margin: 0 10px;
      width: calc(50% - 20px); }
      .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .school-ligne.school-ligne-center .add.addNew, .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool .school-ligne.school-ligne-center .cancel.addNew {
        width: calc(100% - 20px);
        margin-bottom: 20px; }
  .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogParents::before {
    bottom: 140px; } }

@media screen and (max-width: 767px) and (min-width: 1200px) {
  .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogParents .add-school .add-school-form .schoolRightForm {
    margin-right: 100px;
    width: calc(100% - 100px); } }

@media screen and (max-width: 767px) {
  .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogParents .add-school .add-school-form .school-ligne {
    margin-bottom: 0; }
    .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogParents .add-school .add-school-form .school-ligne .school-ligne-w50 {
      margin-bottom: 15px !important;
      width: 100%; }
  .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogParents .add-school .add-school-form .bloc-next {
    margin-top: 20px; }
  .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack h3 {
    margin: 0 0 50px; }
  .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack .buyPack {
    max-width: 240px; }
  .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack.dialogBuyPackage {
    padding-top: 0; }
    .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack.dialogBuyPackage .packageTop {
      margin: 4px auto 25px;
      text-align: center; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack.dialogBuyPackage .packageTop h2 {
        font-size: 22px;
        display: block;
        float: none; }
        .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack.dialogBuyPackage .packageTop h2 img {
          margin-right: 3px;
          width: 25px; }
      .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack.dialogBuyPackage .packageTop a {
        display: none; }
    .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack.dialogBuyPackage .packages {
      max-width: 250px; }
    .dialogOverlay.dialogFormOverlay .dialog.dialogBuyPack.dialogBuyPackage .addPackage {
      position: fixed;
      right: 15px;
      bottom: 15px;
      cursor: pointer;
      width: 50px;
      height: 50px;
      border-radius: 100px;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
      background-image: linear-gradient(225deg, #e2b346, #f6c965);
      color: #fff;
      font-size: 47px;
      line-height: 50px;
      text-align: center;
      z-index: 99; }
  .react-datepicker-wrapper .react-datepicker__input-container:before {
    top: 5px; }
  .OverlayDaillyChallenge {
    min-height: 100%;
    overflow-y: auto; }
    .OverlayDaillyChallenge.OverlayChangePassword > div {
      max-width: 100%;
      left: 10px !important;
      right: 10px !important;
      margin-left: 0 !important;
      overflow: initial !important; }
      .OverlayDaillyChallenge.OverlayChangePassword > div .closeModal {
        display: block; }
    .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage {
      left: -5px;
      right: -5px;
      top: -5px;
      bottom: -5px;
      background: #fff; }
      .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div {
        position: relative;
        box-shadow: none;
        top: 5px !important;
        left: 0 !important; }
        .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .closeModal {
          display: none; }
        .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .header {
          background: #fff; }
          .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .header .header-back {
            display: block;
            position: absolute;
            left: 15px;
            top: 13px;
            height: 30px;
            width: 30px;
            border-radius: 35px;
            border: 2px solid #3db7ae;
            line-height: 25px;
            text-align: center; }
          .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .header h3 {
            color: #46bfb6;
            font-size: 13px; }
        .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .content {
          background: #fff;
          padding: 0 15px 17px; }
          .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .content .choose-image {
            height: 250px; }
          .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .content .choose-apply {
            margin-top: 60px;
            float: left;
            width: 100%; }
            .OverlayDaillyChallenge.OverlayChangePassword.OverlayChangeImage > div .content .choose-apply a {
              padding: 9px 80px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div {
      padding: 20px !important;
      margin: 0px;
      max-width: 100%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .text-right {
        text-align: center !important; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceFilterRight select {
        max-width: 60px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader h2 {
        text-align: left;
        font-size: 21px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .onClose {
        position: absolute;
        cursor: pointer;
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        background: #d8dfe5;
        text-align: center;
        color: #fff;
        font-size: 40px;
        line-height: 40px;
        z-index: 20; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter {
        float: left;
        width: 100%;
        margin: 25px 0 5px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search {
          height: 32px;
          float: none;
          margin: auto;
          width: 100%; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search input {
            height: 30px; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search i {
            top: 8px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight {
          text-align: center;
          width: 100%; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight > div {
            margin: 20px 0 0;
            float: none;
            display: inline-block;
            width: 33.33%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item > div:not(.RadioLegend) {
        min-height: 100%;
        position: relative; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .RadioLegend {
        width: 100%;
        margin: 0 0 10px;
        /*overflow: hidden;*/
        padding: 4px 0; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .RadioLegend legend p {
          margin: 0;
          max-height: 50px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceBottom {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        padding: 10px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div {
      padding: 30px 10px 0 !important;
      min-height: 100vh;
      border-radius: 0 !important; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div .closeModal {
        background: #d8dfe5;
        box-shadow: none; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div .add-school-form {
        margin-top: 30px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div .add-school-form .school-ligne input {
          font-size: 12px;
          font-weight: 500;
          color: #3e4655; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div .add-school-form .school-ligne .exerciceHomeWork .RadioLegend {
          width: 50%; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div .add-school-form .school-ligne .exerciceHomeWork .RadioLegend.all_classes {
            width: 98%; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlaySchoolNew > div {
      padding: 55px 30px 0 !important; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div {
      border-radius: 0 !important;
      left: 0 !important;
      right: 23 !important;
      box-shadow: none;
      top: 0 !important;
      min-height: calc(100vh + 10px); } }
    @media screen and (max-width: 767px) and (max-width: 767px) {
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div {
        margin-left: 0px; } }

@media screen and (max-width: 767px) {
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .closeModal {
        background: #d8dfe5;
        box-shadow: none;
        right: 10px;
        top: 10px;
        height: 35px;
        width: 35px;
        line-height: 35px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .friends-top .friend-search {
        width: 230px;
        float: none;
        margin: 0 auto 15px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .friends-top .friend-right .bloc-level {
        width: 50%;
        margin-left: 0; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne.school-ligne-margin {
        margin-bottom: 25px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 {
          width: 100%;
          margin-bottom: 25px; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50:last-child {
            margin-bottom: 0 !important; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50.w50 {
            width: 50%;
            margin-bottom: 0; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne.school-ligne-classes {
        background: #fff; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne .RadioLegend {
        width: 33.33%; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne .RadioLegend.all_classes {
          width: 98%; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne .RadioLegend > legend {
          font-size: 12px;
          margin-left: 3px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne .flagSelect {
        float: left;
        width: 100%;
        height: 35px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3); }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne .flagSelect .select {
          float: left;
          width: 70px;
          height: 34px;
          position: relative;
          border: none; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne .flagSelect .select:before {
            content: "";
            position: absolute;
            right: 0;
            top: 4px;
            bottom: 4px;
            width: 1px;
            background: #b1bfcd; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne .flagSelect .select select {
            float: left;
            width: 50px;
            font-size: 12px;
            height: 34px;
            border: none;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            text-indent: 1px;
            text-overflow: "";
            background: transparent;
            outline: 0;
            position: relative;
            z-index: 9;
            border-radius: 4px !important;
            border: none !important;
            font-weight: 500;
            font-family: Montserrat;
            color: #1b2437;
            padding: 0 5px; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne .flagSelect .select select::-ms-expand {
              display: none; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne .flagSelect .select i {
            color: #d2dbe2;
            font-size: 16px;
            position: absolute;
            right: 5px;
            top: 8px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne .flagSelect input {
          border: none;
          width: calc(100% - 50px);
          padding-left: 6px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne button.add {
        height: 35px;
        width: 120px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne button.add.newStudents {
          margin-top: 20px;
          width: 100%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne input,
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne .select {
        height: 35px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne input select,
        .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne .select select {
          height: 34px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool > div .add-school-form .school-ligne .select i {
        top: 8px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div {
      padding: 20px !important;
      margin: 0px;
      max-width: 100%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .text-right {
        text-align: center !important; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceFilterRight select {
        max-width: 60px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader h2 {
        text-align: left;
        font-size: 21px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .onClose {
        position: absolute;
        cursor: pointer;
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        background: #d8dfe5;
        text-align: center;
        color: #fff;
        font-size: 40px;
        line-height: 40px;
        z-index: 20; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter {
        float: left;
        width: 100%;
        margin: 25px 0 5px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search {
          height: 32px;
          float: none;
          margin: auto;
          width: 100%; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search input {
            height: 30px; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search i {
            top: 8px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight {
          text-align: center;
          width: 100%; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight > div {
            margin: 20px 0 0;
            float: none;
            display: inline-block;
            width: 33.33%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item > div:not(.RadioLegend) {
        min-height: 100%;
        position: relative; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .RadioLegend {
        width: 100%;
        margin: 0 0 10px;
        /*overflow: hidden;*/
        padding: 4px 0; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .RadioLegend legend p {
          margin: 0;
          max-height: 50px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceBottom {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        padding: 10px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div {
      padding: 30px 45px 0 !important;
      min-height: 100vh;
      border-radius: 0 !important; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div .closeModal {
        background: #d8dfe5;
        box-shadow: none; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div .add-school-form {
        margin-top: 30px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div .add-school-form .school-ligne input {
          font-size: 12px;
          font-weight: 500;
          color: #3e4655; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div .add-school-form .school-ligne .exerciceHomeWork .RadioLegend {
          width: 50%; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlaySchoolNew > div {
      padding: 55px 30px 0 !important; }
    .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div {
      border-radius: 0 !important;
      left: 0 !important;
      right: 23 !important;
      box-shadow: none;
      top: 0 !important;
      min-height: calc(100vh + 10px); } }
    @media screen and (max-width: 767px) and (max-width: 767px) {
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div {
        margin-left: 0px; } }

@media screen and (max-width: 767px) {
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .closeModal {
        background: #d8dfe5;
        box-shadow: none;
        right: 10px;
        top: 10px;
        height: 35px;
        width: 35px;
        line-height: 35px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .friends-top .friend-search {
        width: 230px;
        float: none;
        margin: 0 auto 15px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .friends-top .friend-right .bloc-level {
        width: 50%;
        margin-left: 0; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne.school-ligne-margin {
        margin-bottom: 25px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 {
          width: 100%;
          margin-bottom: 25px; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50:last-child {
            margin-bottom: 0 !important; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50.w50 {
            width: 50%;
            margin-bottom: 0; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne.school-ligne-classes {
        background: #fff; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne .RadioLegend {
        width: 33.33%; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne .RadioLegend.all_classes {
          width: 98%; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne .RadioLegend > legend {
          font-size: 12px;
          margin-left: 3px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne .flagSelect {
        float: left;
        width: 100%;
        height: 35px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3); }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne .flagSelect .select {
          float: left;
          width: 70px;
          height: 34px;
          position: relative;
          border: none; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne .flagSelect .select:before {
            content: "";
            position: absolute;
            right: 0;
            top: 4px;
            bottom: 4px;
            width: 1px;
            background: #b1bfcd; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne .flagSelect .select select {
            float: left;
            width: 50px;
            font-size: 12px;
            height: 34px;
            border: none;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            text-indent: 1px;
            text-overflow: "";
            background: transparent;
            outline: 0;
            position: relative;
            z-index: 9;
            border-radius: 4px !important;
            border: none !important;
            font-weight: 500;
            font-family: Montserrat;
            color: #1b2437;
            padding: 0 5px; }
            .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne .flagSelect .select select::-ms-expand {
              display: none; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne .flagSelect .select i {
            color: #d2dbe2;
            font-size: 16px;
            position: absolute;
            right: 5px;
            top: 8px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne .flagSelect input {
          border: none;
          width: calc(100% - 50px);
          padding-left: 6px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne button.add {
        height: 35px;
        width: 120px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne button.add.newStudents {
          margin-top: 20px;
          width: 100%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne input,
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne .select {
        height: 35px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne input select,
        .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne .select select {
          height: 34px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool > div .add-school-form .school-ligne .select i {
        top: 8px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div {
      padding: 20px !important;
      margin: 0px;
      max-width: 100%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .text-right {
        text-align: center !important; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceFilterRight select {
        max-width: 60px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader h2 {
        text-align: left;
        font-size: 21px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .onClose {
        position: absolute;
        cursor: pointer;
        top: 10px;
        right: 10px;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        background: #d8dfe5;
        text-align: center;
        color: #fff;
        font-size: 40px;
        line-height: 40px;
        z-index: 20; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter {
        float: left;
        width: 100%;
        margin: 25px 0 5px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search {
          height: 32px;
          float: none;
          margin: auto;
          width: 100%; }
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search input {
            height: 30px; }
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .search i {
            top: 8px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight {
          text-align: center;
          width: 100%; }
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceHeader .exerciceFilter .exerciceFilterRight > div {
            margin: 20px 0 0;
            float: none;
            display: inline-block;
            width: 33.33%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item > div:not(.RadioLegend) {
        min-height: 100%;
        position: relative; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .RadioLegend {
        width: 100%;
        margin: 0 0 10px;
        /*overflow: hidden;*/
        padding: 4px 0; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceContent .exercise_tree_item .RadioLegend legend p {
          margin: 0;
          max-height: 50px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork.OverlayAddExercice > div .exerciceBottom {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        padding: 10px; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div {
      padding: 30px 45px 0 !important;
      min-height: 100vh;
      border-radius: 0 !important; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div .closeModal {
        background: #d8dfe5;
        box-shadow: none; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div .add-school-form {
        margin-top: 30px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div .add-school-form .school-ligne input {
          font-size: 12px;
          font-weight: 500;
          color: #3e4655; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div .add-school-form .school-ligne .exerciceHomeWork .RadioLegend {
          width: 50%; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlaySchoolNew > div {
      padding: 55px 30px 0 !important; }
    .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div {
      padding: 55px 35px 0 !important;
      border-radius: 0 !important;
      left: 0 !important;
      right: 23 !important;
      box-shadow: none;
      top: 0 !important;
      min-height: calc(100vh + 10px); } }
    @media screen and (max-width: 767px) and (max-width: 767px) {
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div {
        margin-left: 0px;
        padding: 20px 10px 0 !important;
        width: 100%;
        overflow: scroll !important; } }

@media screen and (max-width: 767px) {
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .closeModal {
        background: #d8dfe5;
        box-shadow: none;
        right: 10px;
        top: 10px;
        height: 35px;
        width: 35px;
        line-height: 35px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .friends-top .friend-search {
        width: 230px;
        float: none;
        margin: 0 auto 15px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .friends-top .friend-right .bloc-level {
        width: 50%;
        margin-left: 0; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne.school-ligne-margin {
        margin-bottom: 25px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50 {
          width: 100%;
          margin-bottom: 25px; }
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50:last-child {
            margin-bottom: 0 !important; }
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne.school-ligne-margin .school-ligne-w50.w50 {
            width: 50%;
            margin-bottom: 0; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne.school-ligne-classes {
        background: #fff; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne .RadioLegend {
        width: 33.33%; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne .RadioLegend.all_classes {
          width: 98%; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne .RadioLegend > legend {
          font-size: 12px;
          margin-left: 3px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne .flagSelect {
        float: left;
        width: 100%;
        height: 35px;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3); }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne .flagSelect .select {
          float: left;
          width: 70px;
          height: 34px;
          position: relative;
          border: none; }
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne .flagSelect .select:before {
            content: "";
            position: absolute;
            right: 0;
            top: 4px;
            bottom: 4px;
            width: 1px;
            background: #b1bfcd; }
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne .flagSelect .select select {
            float: left;
            width: 50px;
            font-size: 12px;
            height: 34px;
            border: none;
            outline: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            text-indent: 1px;
            text-overflow: "";
            background: transparent;
            outline: 0;
            position: relative;
            z-index: 9;
            border-radius: 4px !important;
            border: none !important;
            font-weight: 500;
            font-family: Montserrat;
            color: #1b2437;
            padding: 0 5px; }
            .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne .flagSelect .select select::-ms-expand {
              display: none; }
          .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne .flagSelect .select i {
            color: #d2dbe2;
            font-size: 16px;
            position: absolute;
            right: 5px;
            top: 8px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne .flagSelect input {
          border: none;
          width: calc(100% - 50px);
          padding-left: 6px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne button.add {
        height: 35px;
        width: 120px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne button.add.newStudents {
          margin-top: 20px;
          width: 100%; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne input,
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne .select {
        height: 35px; }
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne input select,
        .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne .select select {
          height: 34px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool > div .add-school-form .school-ligne .select i {
        top: 8px; }
    .OverlayDaillyChallenge.OverlayFriends, .OverlayDaillyChallenge.OverlayTextbookExercice {
      background: #ffffff; }
      .OverlayDaillyChallenge.OverlayFriends .closeModal, .OverlayDaillyChallenge.OverlayTextbookExercice .closeModal {
        top: -37px;
        right: -10px;
        width: 33px;
        height: 33px;
        line-height: 23px; }
        .OverlayDaillyChallenge.OverlayFriends .closeModal.modaldownload_files, .OverlayDaillyChallenge.OverlayTextbookExercice .closeModal.modaldownload_files {
          display: block !important;
          top: 6px !important;
          right: 6px !important;
          background: #d8dfe5;
          box-shadow: none;
          width: 35px;
          height: 35px;
          line-height: 26px;
          font-size: 34px;
          top: 15px;
          right: 15px;
          border-radius: 100px;
          text-align: center;
          color: #fff; }
        .OverlayDaillyChallenge.OverlayFriends .closeModal img, .OverlayDaillyChallenge.OverlayTextbookExercice .closeModal img {
          max-width: 13px; }
      .OverlayDaillyChallenge.OverlayFriends > div, .OverlayDaillyChallenge.OverlayTextbookExercice > div {
        left: 15px !important;
        right: 15px !important;
        box-shadow: 0 0 8px -1px #dedfe2;
        top: 45px !important;
        padding: 20px 40px 35px !important;
        overflow: initial !important; }
        .OverlayDaillyChallenge.OverlayFriends > div .friend-content .friend-content-bloc .friend-content-bloc-scroll, .OverlayDaillyChallenge.OverlayTextbookExercice > div .friend-content .friend-content-bloc .friend-content-bloc-scroll {
          height: auto;
          overflow-y: inherit; }
        .OverlayDaillyChallenge.OverlayFriends > div .friend-content .friend-content-bloc .friend-bloc, .OverlayDaillyChallenge.OverlayTextbookExercice > div .friend-content .friend-content-bloc .friend-bloc {
          width: 33.33%; }
          .OverlayDaillyChallenge.OverlayFriends > div .friend-content .friend-content-bloc .friend-bloc.selected:before, .OverlayDaillyChallenge.OverlayTextbookExercice > div .friend-content .friend-content-bloc .friend-bloc.selected:before {
            left: 50%;
            margin-left: -38px; }
      .OverlayDaillyChallenge.OverlayFriends.OverlayExerciceSubject, .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject {
        background-color: rgba(100, 115, 140, 0.9); }
        .OverlayDaillyChallenge.OverlayFriends.OverlayExerciceSubject > div, .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div {
          left: 50% !important;
          top: 50% !important;
          margin-top: -141px; }
          .OverlayDaillyChallenge.OverlayFriends.OverlayExerciceSubject > div .closeModal, .OverlayDaillyChallenge.OverlayTextbookExercice.OverlayExerciceSubject > div .closeModal {
            display: block;
            top: -40px;
            right: 0px; }
    .OverlayDaillyChallenge.OverlayTextbookExercice > div {
      padding: 0 !important;
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      margin-left: 0; }
      .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width {
        width: 50%; }
        .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width .bloc-content-exercice h4 {
          line-height: 1; }
      .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-scroll {
        height: calc(var(--vh, 1vh) * 100 - 56px) !important; }
    .OverlayDaillyChallenge.OverlayTextbookExercice .closeModal {
      display: none; } }
  @media screen and (max-width: 767px) and (max-width: 767.99px) {
    .OverlayDaillyChallenge.OverlayAddPrintingDocument > div {
      height: 100%;
      border-radius: 0 !important; }
      .OverlayDaillyChallenge.OverlayAddPrintingDocument > div .mobile_header {
        height: 50px;
        display: flex;
        padding: 10px 10px;
        justify-content: space-between;
        box-shadow: 1px 0px 8px -4px; }
        .OverlayDaillyChallenge.OverlayAddPrintingDocument > div .mobile_header .return {
          border: 1px solid #3eb6ad;
          width: 30px;
          height: 30px;
          cursor: pointer;
          color: #3eb6ad;
          text-align: center;
          line-height: 29px;
          border-radius: 50%;
          font-size: 16px; }
        .OverlayDaillyChallenge.OverlayAddPrintingDocument > div .mobile_header h2 {
          line-height: 30px; }
      .OverlayDaillyChallenge.OverlayAddPrintingDocument > div .form_content {
        padding: 10px;
        padding-top: 25px;
        height: calc(100% - 50px);
        display: flex;
        justify-content: space-between;
        flex-direction: column; }
        .OverlayDaillyChallenge.OverlayAddPrintingDocument > div .form_content .form_content_fields > div {
          display: flex; }
          .OverlayDaillyChallenge.OverlayAddPrintingDocument > div .form_content .form_content_fields > div label {
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 37px;
            letter-spacing: normal;
            text-align: left;
            min-width: 120px;
            color: #1b2437;
            margin-right: 5px;
            margin-bottom: 0;
            margin-top: 0; }
          .OverlayDaillyChallenge.OverlayAddPrintingDocument > div .form_content .form_content_fields > div:not(.upload_zone) > div {
            width: 100%;
            min-width: calc(100% - 125px); }
          .OverlayDaillyChallenge.OverlayAddPrintingDocument > div .form_content .form_content_fields > div .react-datepicker-wrapper {
            margin-left: 0; }
          .OverlayDaillyChallenge.OverlayAddPrintingDocument > div .form_content .form_content_fields > div input, .OverlayDaillyChallenge.OverlayAddPrintingDocument > div .form_content .form_content_fields > div select {
            height: 35px !important;
            border-radius: 9px !important;
            font-size: 13px;
            color: black !important;
            z-index: 0 !important; }
          .OverlayDaillyChallenge.OverlayAddPrintingDocument > div .form_content .form_content_fields > div input {
            padding-left: 5px; }
        .OverlayDaillyChallenge.OverlayAddPrintingDocument > div .form_content .form_content_fields .upload_zone {
          margin-top: 30px; } }

@media screen and (max-width: 767px) {
  .header-right ul li a {
    width: 40px;
    height: 40px;
    margin-left: 5px; }
    .header-right ul li a img {
      width: 17px;
      display: block; }
  .signin {
    margin: 40px auto 0;
    width: 180px; }
    .signin li {
      padding: 6px 9px 8px;
      border-radius: 52px;
      margin-bottom: 15px; }
      .signin li a {
        font-size: 12px;
        letter-spacing: 1.8px; }
        .signin li a img {
          width: 20px; }
  .navigation-mobile .logo {
    margin-top: -30px; }
    .navigation-mobile .logo img {
      width: 150px; }
  .navigation-mobile header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    right: 0;
    box-shadow: 0px 3px 16px -1px rgba(0, 0, 0, 0.4); }
    .navigation-mobile header .content-mobile .center-mobile nav {
      max-width: 180px; }
      .navigation-mobile header .content-mobile .center-mobile nav ul li a {
        width: 100%;
        font-size: 17px;
        padding: 7px;
        font-weight: 500; }
    .navigation-mobile header .content-mobile .center-mobile .translate .translate-bloc {
      padding: 9px 22px;
      font-size: 12px; }
      .navigation-mobile header .content-mobile .center-mobile .translate .translate-bloc img {
        width: 13px; }
  section.one {
    position: relative;
    padding: 24px 0 0; }
    section.one .slider {
      margin: 0 0 190px; }
      section.one .slider .carousel {
        border-radius: 5px; }
        section.one .slider .carousel h3 {
          font-size: 17px;
          line-height: 23px;
          bottom: 10px; }
        section.one .slider .carousel .banner-anim {
          min-height: 200px; }
          section.one .slider .carousel .banner-anim .banner-anim-elem {
            min-height: 200px; }
            section.one .slider .carousel .banner-anim .banner-anim-elem .bg {
              min-height: 200px; }
      section.one .slider .slider-bottom {
        bottom: -170px;
        left: 25px;
        right: 25px; }
        section.one .slider .slider-bottom a {
          margin: 0 0 22px;
          width: 100%;
          display: block;
          font-size: 12.5px;
          color: rgba(255, 255, 255, 0.9);
          padding: 14px 13px;
          border: 4px solid #fff;
          border-radius: 82px; }
    section.one .news-feed h3 {
      margin-bottom: 15px; }
    section.one .news-feed .bloc-feeds {
      margin: 10px 0 0; }
      section.one .news-feed .bloc-feeds .feed-img {
        width: 56%;
        margin-right: 12px; }
      section.one .news-feed .bloc-feeds .bloc-feed {
        margin-bottom: 15px; }
      section.one .news-feed .bloc-feeds .feeds {
        margin-top: 25px; }
    section.one .news-feed p.link {
      margin-top: 25px; }
  section.two .subjects {
    box-shadow: 0 -4px 16px -10px rgba(0, 0, 0, 0.45);
    padding-top: 15px; }
    section.two .subjects h2 {
      font-size: 17px;
      margin-bottom: 20px;
      width: 210px;
      padding-left: 0;
      padding-right: 0; }
    section.two .subjects .subjects-mobile {
      max-width: 210px;
      margin: auto; }
      section.two .subjects .subjects-mobile img {
        margin: 42px 0;
        -webkit-filter: drop-shadow(5px 22px 23px rgba(0, 0, 0, 0.15));
        -moz-filter: drop-shadow(5px 22px 23px rgba(0, 0, 0, 0.15));
        -ms-filter: drop-shadow(5px -22px 23px rgba(0, 0, 0, 0.15));
        -o-filter: drop-shadow(5px 22px 23px rgba(0, 0, 0, 0.15));
        filter: drop-shadow(5px 22px 23px rgba(0, 0, 0, 0.15)); }
  section.section-news-feeds:before {
    width: 450px;
    right: -150px; }
  section.section-news-feeds h3 {
    margin: 0 0 30px; }
  section.section-news-feeds .section-feeds {
    padding-bottom: 15px; }
    section.section-news-feeds .section-feeds .slider-frame {
      overflow: inherit !important; }
    section.section-news-feeds .section-feeds .section-feed {
      max-width: 290px;
      margin: auto;
      border-radius: 6px;
      padding-bottom: 10px;
      background: #fff; }
      section.section-news-feeds .section-feeds .section-feed .news-img {
        width: 100%;
        height: 220px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.4); }
      section.section-news-feeds .section-feeds .section-feed .news-article {
        width: 100%;
        height: auto;
        padding-bottom: 40px; }
        section.section-news-feeds .section-feeds .section-feed .news-article:before {
          left: 0;
          width: 112px;
          bottom: -83px;
          min-height: 94px; }
        section.section-news-feeds .section-feeds .section-feed .news-article:after {
          bottom: -6px; }
        section.section-news-feeds .section-feeds .section-feed .news-article p {
          font-size: 12px;
          line-height: 16px;
          text-align: left; }
          section.section-news-feeds .section-feeds .section-feed .news-article p.link {
            text-align: right; }
            section.section-news-feeds .section-feeds .section-feed .news-article p.link a {
              padding: 5px 10px;
              font-size: 10px; }
            section.section-news-feeds .section-feeds .section-feed .news-article p.link i {
              margin: 0;
              vertical-align: middle; }
  section.section-news-feeds .slider-control-bottomcenter {
    bottom: -50px !important;
    right: 31px !important;
    transform: translateX(0%) !important; }
    section.section-news-feeds .slider-control-bottomcenter ul li button {
      font-size: 53px !important;
      line-height: 38px !important;
      color: #297a74 !important;
      padding: 2px !important; }
  section.section-news-feeds .slider-control-centerright button,
  section.section-news-feeds .slider-control-centerleft button {
    background: #3eb6ad !important;
    padding: O 0 !important;
    font-size: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 5px solid #ffffff !important;
    position: relative; }
    section.section-news-feeds .slider-control-centerright button:before,
    section.section-news-feeds .slider-control-centerleft button:before {
      font: normal normal normal 14px/1 FontAwesome;
      color: #fff;
      line-height: 14px;
      font-size: 24px; }
  section.section-news-feeds .slider-control-centerright button:before {
    content: "\f105"; }
  section.section-news-feeds .slider-control-centerleft button:before {
    content: "\f104"; }
  section.koolskools-notes {
    padding: 35px 0 110px; }
    section.koolskools-notes main {
      background: #f8f6f6; }
      section.koolskools-notes main:before {
        top: auto;
        bottom: -10px;
        left: 50px;
        width: 100px;
        height: 130px; }
      section.koolskools-notes main > .pager-controls {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 25px; }
      section.koolskools-notes main .view {
        transform: scale(2);
        -webkit-transform: scale(2); }
        section.koolskools-notes main .view.current {
          -webkit-transform: scale(2.5);
          transform: scale(2.5); }
      section.koolskools-notes main .pager-controls .pager-control--prev,
      section.koolskools-notes main .pager-controls .pager-control--next {
        display: block;
        position: absolute;
        top: 50%;
        background: #3eb6ad;
        padding: 0;
        font-size: 0;
        width: 40px;
        height: 40px;
        margin-top: -25px;
        border-radius: 50%;
        border: 5px solid #ffffff;
        line-height: 45px; }
        section.koolskools-notes main .pager-controls .pager-control--prev:before,
        section.koolskools-notes main .pager-controls .pager-control--next:before {
          font: normal normal normal 14px/1 FontAwesome;
          color: #fff;
          line-height: 14px;
          font-size: 24px; }
      section.koolskools-notes main .pager-controls .pager-control--prev {
        left: 17px; }
        section.koolskools-notes main .pager-controls .pager-control--prev:before {
          content: "\f104"; }
      section.koolskools-notes main .pager-controls .pager-control--next {
        right: 17px; }
        section.koolskools-notes main .pager-controls .pager-control--next:before {
          content: "\f105"; }
    section.koolskools-notes .container-koolskools {
      max-width: 100%;
      padding: 0; }
      section.koolskools-notes .container-koolskools h3 {
        margin-bottom: 67px;
        position: relative;
        z-index: 99;
        font-size: 12px;
        line-height: 16px; }
        section.koolskools-notes .container-koolskools h3 span:before {
          right: -63px;
          top: 25px;
          width: 117px;
          height: 102px;
          z-index: 9;
          -webkit-transform: rotate(50deg);
          transform: rotate(56deg); }
    section.koolskools-notes .frame {
      margin-left: 0;
      margin-right: 0;
      overflow: inherit !important; }
      section.koolskools-notes .frame .track {
        padding: 130px 0 150px; }
        section.koolskools-notes .frame .track img {
          border: 7px solid #ffffff; }
  footer .bloc-footer {
    width: 100%; }
  footer .footer-logo {
    margin-bottom: 10px; }
    footer .footer-logo a {
      background-size: 246px auto;
      width: 246px;
      height: 127px;
      margin: -100px auto 0; }
      footer .footer-logo a img {
        width: 140px; }
  .dialogMobile {
    padding-right: 10px;
    padding-left: 10px;
    z-index: 99999;
    display: block; }
    .dialogMobile .dialog {
      padding-bottom: 60px;
      max-width: 100%;
      display: block;
      float: left;
      margin-top: 40px; }
      .dialogMobile .dialog.dialogConnexion .account_activated {
        color: green;
        font-size: 12px;
        padding: 10px; }
      .dialogMobile .dialog.dialogConnexion.dialogRegister {
        width: 100%;
        min-width: 100%;
        padding: 5px;
        background: #fff; }
        .dialogMobile .dialog.dialogConnexion.dialogRegister .closeRegister {
          background: #b0bfcd;
          font-size: 35px;
          line-height: 35px;
          border: none; }
        .dialogMobile .dialog.dialogConnexion.dialogRegister .dialog-top {
          border: 1px solid #e1e8ec;
          background: #f6f8f9;
          padding: 0;
          overflow: hidden; }
          .dialogMobile .dialog.dialogConnexion.dialogRegister .dialog-top a {
            background: #f6f8f9;
            color: #a4acb5;
            border: none;
            letter-spacing: 0.6px;
            padding: 11px 6px;
            border-radius: 0; }
            .dialogMobile .dialog.dialogConnexion.dialogRegister .dialog-top a.active {
              background: #64d1cb;
              color: #fdfefe;
              font-weight: 500;
              border-radius: 28px; }
        .dialogMobile .dialog.dialogConnexion.dialogRegister .dialog-content h2 {
          color: #222b3d; }
        .dialogMobile .dialog.dialogConnexion.dialogRegister .dialog-content .modalConnexionForm .connexionLigne .intl-tel-input {
          width: 100%; }
        .dialogMobile .dialog.dialogConnexion.dialogRegister .dialog-content .modalConnexionForm .connexionLigne label {
          color: #6c727f;
          font-weight: 500; }
        .dialogMobile .dialog.dialogConnexion.dialogRegister .dialog-content .modalConnexionForm .connexionLigne .RadioLegend {
          margin-top: 4px;
          margin-bottom: 4px; }
        .dialogMobile .dialog.dialogConnexion.dialogRegister .dialog-content .modalConnexionForm .connexionLigne input,
        .dialogMobile .dialog.dialogConnexion.dialogRegister .dialog-content .modalConnexionForm .connexionLigne select,
        .dialogMobile .dialog.dialogConnexion.dialogRegister .dialog-content .modalConnexionForm .connexionLigne .flagSelect {
          height: 35px; }
          .dialogMobile .dialog.dialogConnexion.dialogRegister .dialog-content .modalConnexionForm .connexionLigne input .select i,
          .dialogMobile .dialog.dialogConnexion.dialogRegister .dialog-content .modalConnexionForm .connexionLigne select .select i,
          .dialogMobile .dialog.dialogConnexion.dialogRegister .dialog-content .modalConnexionForm .connexionLigne .flagSelect .select i {
            top: 8px; }
        .dialogMobile .dialog.dialogConnexion.dialogRegister .dialog-content .modalConnexionForm .connexionLigne .select {
          height: 33px;
          border-radius: 4px !important;
          border: none !important;
          font-weight: 500;
          font-family: Montserrat;
          font-size: 14px;
          color: #1b2437;
          padding: 0 10px; }
        .dialogMobile .dialog.dialogConnexion.dialogRegister .dialog-content .modalConnexionForm .connexionLigne .forgot {
          color: #b7c4c8;
          font-weight: 500;
          float: left; }
        .dialogMobile .dialog.dialogConnexion.dialogRegister .dialog-content .modalConnexionForm .connexionLigne a.signin {
          float: right;
          color: #fff;
          height: 30px;
          line-height: 30px;
          margin: 0; }
      .dialogMobile .dialog.dialogConnexion.dialogCandid {
        width: 100%;
        min-width: 100%;
        padding: 5px;
        background: #fff; }
        .dialogMobile .dialog.dialogConnexion.dialogCandid .closeRegister {
          background: #b0bfcd;
          font-size: 35px;
          line-height: 35px;
          border: none; }
        .dialogMobile .dialog.dialogConnexion.dialogCandid .dialog-top {
          border: 1px solid #e1e8ec;
          background: #f6f8f9;
          padding: 0;
          overflow: hidden; }
          .dialogMobile .dialog.dialogConnexion.dialogCandid .dialog-top a {
            background: #f6f8f9;
            color: #a4acb5;
            border: none;
            letter-spacing: 0.6px;
            padding: 11px 6px;
            border-radius: 0; }
            .dialogMobile .dialog.dialogConnexion.dialogCandid .dialog-top a.active {
              background: #64d1cb;
              color: #fdfefe;
              font-weight: 500;
              border-radius: 28px; }
        .dialogMobile .dialog.dialogConnexion.dialogCandid .dialog-content h2 {
          color: #222b3d; }
        .dialogMobile .dialog.dialogConnexion.dialogCandid .dialog-content .modalConnexionForm .connexionLigne .intl-tel-input {
          width: 100%; }
        .dialogMobile .dialog.dialogConnexion.dialogCandid .dialog-content .modalConnexionForm .connexionLigne label {
          color: #6c727f;
          font-weight: 500; }
        .dialogMobile .dialog.dialogConnexion.dialogCandid .dialog-content .modalConnexionForm .connexionLigne .RadioLegend {
          margin-top: 4px;
          margin-bottom: 4px; }
        .dialogMobile .dialog.dialogConnexion.dialogCandid .dialog-content .modalConnexionForm .connexionLigne input,
        .dialogMobile .dialog.dialogConnexion.dialogCandid .dialog-content .modalConnexionForm .connexionLigne select,
        .dialogMobile .dialog.dialogConnexion.dialogCandid .dialog-content .modalConnexionForm .connexionLigne .flagSelect {
          height: 35px; }
          .dialogMobile .dialog.dialogConnexion.dialogCandid .dialog-content .modalConnexionForm .connexionLigne input .select i,
          .dialogMobile .dialog.dialogConnexion.dialogCandid .dialog-content .modalConnexionForm .connexionLigne select .select i,
          .dialogMobile .dialog.dialogConnexion.dialogCandid .dialog-content .modalConnexionForm .connexionLigne .flagSelect .select i {
            top: 8px; }
        .dialogMobile .dialog.dialogConnexion.dialogCandid .dialog-content .modalConnexionForm .connexionLigne .select {
          height: 33px;
          border-radius: 4px !important;
          border: none !important;
          font-weight: 500;
          font-family: Montserrat;
          font-size: 14px;
          color: #1b2437;
          padding: 0 10px; }
        .dialogMobile .dialog.dialogConnexion.dialogCandid .dialog-content .modalConnexionForm .connexionLigne .forgot {
          color: #b7c4c8;
          font-weight: 500;
          float: left; }
        .dialogMobile .dialog.dialogConnexion.dialogCandid .dialog-content .modalConnexionForm .connexionLigne a.signin {
          float: right;
          color: #fff;
          height: 30px;
          line-height: 30px;
          margin: 0; }
      .dialogMobile .dialog .closeRegister {
        display: block;
        position: absolute;
        top: -37px;
        right: 0;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        color: #ffff;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        border: 5px solid #fff;
        background: #13bbb3;
        cursor: pointer; }
      .dialogMobile .dialog .dialog-content {
        padding: 30px 8px 0 15px; }
        .dialogMobile .dialog .dialog-content .dialog-left {
          width: 98%; }
          .dialogMobile .dialog .dialog-content .dialog-left .textInput label {
            color: #868686; }
          .dialogMobile .dialog .dialog-content .dialog-left h3 {
            font-size: 15px; }
          .dialogMobile .dialog .dialog-content .dialog-left .password-forgot a {
            font-size: 10px; }
        .dialogMobile .dialog .dialog-content .dialog-right {
          width: 150px;
          position: absolute;
          bottom: 33px;
          right: 10px; }
          .dialogMobile .dialog .dialog-content .dialog-right span:before {
            display: none; }
      .dialogMobile .dialog .dialog-top a {
        font-size: 13px;
        padding: 6px;
        font-weight: 500; }
  .OverlayTextbook {
    overflow: auto;
    margin-bottom: 55px; }
    .OverlayTextbook tr.empty_tr {
      height: 30px; }
    .OverlayTextbook:before {
      display: none; }
    .OverlayTextbook:after {
      display: none; }
    .OverlayTextbook > div {
      right: 0 !important; }
      .OverlayTextbook > div .header {
        padding: 0 15px;
        height: 50px; }
        .OverlayTextbook > div .header .react-multi-carousel-list {
          padding: 0 0 0 33px; }
          .OverlayTextbook > div .header .react-multi-carousel-list button {
            background: transparent; }
            .OverlayTextbook > div .header .react-multi-carousel-list button.react-multiple-carousel__arrow--left {
              left: 0; }
            .OverlayTextbook > div .header .react-multi-carousel-list button.react-multiple-carousel__arrow--right {
              right: 0; }
            .OverlayTextbook > div .header .react-multi-carousel-list button:before {
              font-size: 15px; }
        .OverlayTextbook > div .header ul {
          justify-content: inherit; }
          .OverlayTextbook > div .header ul li {
            padding: 0;
            width: 147px !important; }
            .OverlayTextbook > div .header ul li a {
              font-size: 11px;
              font-weight: 500;
              line-height: 40px; }
      .OverlayTextbook > div .modal-fuseesLeft {
        display: none; }
      .OverlayTextbook > div .modal-fuseesRight {
        display: none; }
      .OverlayTextbook > div .todoCompleted {
        padding: 15px 0; }
        .OverlayTextbook > div .todoCompleted div {
          height: 37px; }
          .OverlayTextbook > div .todoCompleted div a {
            font-size: 9px;
            font-weight: 600;
            padding: 12px 21px; }
      .OverlayTextbook > div .exercices-table table thead tr th {
        padding-bottom: 0;
        font-size: 10px;
        font-weight: 600; }
        .OverlayTextbook > div .exercices-table table thead tr th:nth-child(0) {
          width: 20%; }
      .OverlayTextbook > div .exercices-table table tbody tr td:nth-child(1), .OverlayTextbook > div .exercices-table table tbody tr td:nth-child(2) {
        font-size: 13px;
        font-weight: 400; }
        .OverlayTextbook > div .exercices-table table tbody tr td:nth-child(1) .separator, .OverlayTextbook > div .exercices-table table tbody tr td:nth-child(2) .separator {
          display: none; }
        .OverlayTextbook > div .exercices-table table tbody tr td:nth-child(1) .month, .OverlayTextbook > div .exercices-table table tbody tr td:nth-child(2) .month {
          display: block; }
      .OverlayTextbook > div .exercices-table table tbody tr td:nth-child(3) .visit,
      .OverlayTextbook > div .exercices-table table tbody tr td:nth-child(3) .expired {
        font-size: 10px;
        min-width: 60px;
        padding: 3px 4px 1px;
        font-weight: 500; }
      .OverlayTextbook > div .exercices-table table tbody tr td:nth-child(3) .done {
        font-size: 10px;
        font-weight: 600; }
        .OverlayTextbook > div .exercices-table table tbody tr td:nth-child(3) .done i {
          font-size: 16px;
          float: right;
          margin-top: 4px;
          margin-left: 3px;
          margin-right: 3px;
          font-weight: bold; }
  .dialogOverlay.dialogCalendarOverlay {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: -10px;
    min-height: 100vh;
    height: 100%;
    background: #fff;
    margin: 0;
    padding: 0;
    z-index: 999;
    display: block; }
    .dialogOverlay.dialogCalendarOverlay.dialogStudentsDocumentsOverlay {
      z-index: 1040 !important; }
    .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar {
      max-width: 100%;
      border: none;
      padding: 10px 0 70px;
      float: left;
      box-shadow: none; }
      .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar .calendarHeader {
        padding: 5px 25px 15px; }
        .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar .calendarHeader .react-datepicker-wrapper {
          margin-left: 40px; }
          .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar .calendarHeader .react-datepicker-wrapper .react-datepicker__input-container:before {
            content: "\F0D7";
            font: normal normal normal 13px/1 FontAwesome;
            color: #d8dfe5;
            left: auto;
            right: 2px;
            font-weight: 500;
            top: 9px; }
          .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar .calendarHeader .react-datepicker-wrapper .react-datepicker__input-container input {
            font-size: 13px;
            font-weight: bold;
            width: 80px;
            padding-left: 0;
            border: none; }
      .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar .calendar {
        box-shadow: none;
        border-radius: 0;
        float: left;
        width: 100%;
        border: none; }
        .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar .calendar .week .day {
          padding: 24px 1px 4px; }
          .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar .calendar .week .day b {
            display: none; }
          .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar .calendar .week .day span {
            top: 0px;
            right: 0px;
            width: 21px;
            height: 21px;
            border-radius: 36px;
            line-height: 21px;
            font-size: 9px; }
          .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar .calendar .week .day .event {
            padding: 1px;
            font-size: 8px;
            line-height: 10px; }
        .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar .calendar .dayDetails {
          float: left;
          width: 100%;
          padding: 20px 10px; }
      .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar .onBack {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 10px;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
        background-image: -webkit-linear-gradient(225deg, #72dcd6, #3eb6ad);
        background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
        text-align: center;
        color: #fff;
        font-size: 27px;
        line-height: 40px; }
      .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar .calendar-switch {
        position: fixed;
        bottom: 0;
        background: #fff;
        left: 0;
        right: 0;
        margin: 0;
        padding: 12px 15px;
        height: auto;
        border-radius: 0;
        border: none;
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.3);
        width: 100%;
        z-index: 999; }
        .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar .calendar-switch > div {
          border: solid 1px #d1dbe2;
          background-color: #f5f8f9;
          float: left;
          width: 100%;
          border-radius: 15px; }
          .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar .calendar-switch > div a {
            font-size: 13px; }
      .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar header.header {
        height: 30px; }
        .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar header.header .day-names .day {
          font-size: 12px;
          line-height: 30px;
          text-transform: inherit; }
      .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar header.header-week {
        height: 52px; }
        .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar header.header-week .weekLeft,
        .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar header.header-week .weekRight {
          float: left;
          width: 20px;
          height: 50px;
          line-height: 50px;
          font-size: 22px;
          text-align: center;
          color: #b0bfcd;
          cursor: pointer; }
        .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar header.header-week .listDays {
          float: left;
          width: calc(100% - 40px); }
          .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar header.header-week .listDays span {
            float: left;
            width: 16.66%;
            line-height: 50px;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
            color: #444c5c; }
            .dialogOverlay.dialogCalendarOverlay .dialog.dialogCalendar header.header-week .listDays span b {
              background: #5bcac3;
              color: #fff;
              border-radius: 50%;
              padding: 7px; } }

.scheduler_to_print__professor {
  z-index: 11111111;
  background-color: white; }
  .scheduler_to_print__professor .schedular_header .school_info {
    border: 1px solid black;
    margin-bottom: 1px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between; }
    .scheduler_to_print__professor .schedular_header .school_info .school {
      display: flex;
      flex-direction: column;
      border-right: 1px solid black;
      width: 101px; }
      .scheduler_to_print__professor .schedular_header .school_info .school img {
        margin: auto;
        width: 100px;
        height: 100px; }
    .scheduler_to_print__professor .schedular_header .school_info .center {
      padding: 10px;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      flex-direction: column;
      width: calc(100% - 201px); }
      .scheduler_to_print__professor .schedular_header .school_info .center .scholar_year {
        text-align: center;
        font-weight: bold;
        line-height: unset; }
      .scheduler_to_print__professor .schedular_header .school_info .center .title {
        text-align: center;
        font-weight: bold;
        text-transform: uppercase; }
      .scheduler_to_print__professor .schedular_header .school_info .center .cycle {
        text-align: center;
        font-weight: bold; }
    .scheduler_to_print__professor .schedular_header .school_info .right {
      border-left: 1px solid black;
      display: flex;
      -webkit-box-pack: end;
      justify-content: flex-end;
      flex-direction: column;
      padding: 5px 10px;
      width: 100px; }
      .scheduler_to_print__professor .schedular_header .school_info .right .date {
        text-align: center;
        font-weight: bold; }
        .scheduler_to_print__professor .schedular_header .school_info .right .date span {
          margin-left: 10px;
          font-weight: bold; }
  .scheduler_to_print__professor .schedular_header .classe {
    padding-bottom: 2px;
    text-align: center; }
  .scheduler_to_print__professor table {
    border: 1px solid black;
    background-color: white;
    margin-top: 15px;
    width: 100%; }
    .scheduler_to_print__professor table td {
      text-align: left;
      border-left: 1px solid #e2e6ef;
      border-bottom: 1px solid #e2e6ef;
      padding: 2.5px;
      vertical-align: super;
      border-spacing: 10px;
      border-collapse: initial;
      position: relative;
      border-left: 1px solid black;
      border-bottom: 1px solid black; }
      .scheduler_to_print__professor table td.first {
        border-bottom: 1px solid black;
        color: black;
        vertical-align: top;
        font-size: 10px;
        text-align: center; }
      .scheduler_to_print__professor table td .cardSchedule {
        float: none;
        margin: auto;
        overflow: hidden;
        background: white;
        position: absolute;
        padding: 5px;
        color: black;
        background-color: white;
        border-radius: 0px;
        left: 0px;
        border-width: 1px 0px !important;
        border-top-style: solid !important;
        border-bottom-style: solid !important;
        border-top-color: black !important;
        border-bottom-color: black !important;
        border-image: initial !important;
        border-left-style: initial !important;
        border-left-color: initial !important;
        border-right-style: initial !important;
        border-right-color: initial !important; }
        .scheduler_to_print__professor table td .cardSchedule .time_carte {
          color: black;
          position: absolute;
          right: 2px;
          top: 0px;
          font-size: 7px;
          margin: 0px;
          width: 50px;
          text-align: center;
          -webkit-box-pack: end;
          justify-content: flex-end;
          line-height: 15px; }
        .scheduler_to_print__professor table td .cardSchedule .d_flex {
          width: calc(100% - 52px);
          margin: 0px;
          flex-direction: column;
          -webkit-box-pack: start;
          justify-content: flex-start;
          font-size: 13px; }
          .scheduler_to_print__professor table td .cardSchedule .d_flex span {
            width: 100%;
            text-align: left;
            font-size: 10px;
            white-space: normal;
            word-break: break-all;
            line-height: 15px;
            font-weight: bold; }
            .scheduler_to_print__professor table td .cardSchedule .d_flex span.subject_name {
              display: block;
              overflow: hidden;
              text-overflow: ellipsis; }
        .scheduler_to_print__professor table td .cardSchedule p {
          display: flex;
          margin: 0px;
          color: #acafb6;
          font-size: 11px; }
        .scheduler_to_print__professor table td .cardSchedule .chapters {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-top: -5px;
          width: 100%;
          color: black;
          font-size: 9px; }
        .scheduler_to_print__professor table td .cardSchedule * {
          color: black !important; }
        .scheduler_to_print__professor table td .cardSchedule.pbt0i {
          border-top: 1px solid black !important; }
    .scheduler_to_print__professor table th {
      border-left: 1px solid #e2e6ef;
      text-align: center;
      width: calc(16.66%);
      border-left: 1px solid black;
      color: black;
      border-top: 1px solid black; }

.dialogInscriptionsDocuments .dialogAddDocument {
  width: 50%;
  height: 80%;
  max-width: 500px; }
  .dialogInscriptionsDocuments .dialogAddDocument .inscriptions_documents {
    width: 100%;
    height: 100%; }
    .dialogInscriptionsDocuments .dialogAddDocument .inscriptions_documents .sectionSession {
      width: 100%;
      height: calc(100% - 80px);
      overflow-y: auto; }
      .dialogInscriptionsDocuments .dialogAddDocument .inscriptions_documents .sectionSession .docs {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px; }
        .dialogInscriptionsDocuments .dialogAddDocument .inscriptions_documents .sectionSession .docs .document > h2 {
          margin: 0 0 5px;
          padding-top: 5px; }
      .dialogInscriptionsDocuments .dialogAddDocument .inscriptions_documents .sectionSession::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f5f5f5; }
      .dialogInscriptionsDocuments .dialogAddDocument .inscriptions_documents .sectionSession::-webkit-scrollbar {
        width: 5px;
        background-color: #f5f5f5; }
      .dialogInscriptionsDocuments .dialogAddDocument .inscriptions_documents .sectionSession::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
    .dialogInscriptionsDocuments .dialogAddDocument .inscriptions_documents .action {
      margin-top: 10px; }

@media screen and (max-width: 450px) {
  .OverlayDaillyChallenge.OverlayFriends > div .friends-top {
    margin-top: 15px; }
    .OverlayDaillyChallenge.OverlayFriends > div .friends-top .friend-search {
      width: 100%;
      margin-bottom: 15px; }
    .OverlayDaillyChallenge.OverlayFriends > div .friends-top .friend-right {
      float: left;
      width: 100%; } }

@media screen and (max-width: 410px) {
  .OverlayTextbook > div .todoCompleted div a {
    padding: 12px 10px; }
  .OverlayTextbook > div .exercices-table {
    padding: 0 10px; }
  .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool {
    padding: 25px 20px; }
    .dialogOverlay.dialogFormOverlay .dialog.dialogAddSchool.dialogAddProfessor .add-school .add-school-form .school-ligne.school-ligne-classes {
      margin: 0 -10px 15px;
      width: calc(100% + 20px);
      padding: 10px 10px 0; }
  .dialogOverlay.dialogFormOverlay .dialog.dialogAddVehiculeRepair .add-school-form .school-ligne > div .select {
    width: 100%;
    height: 29px;
    border-radius: 2.6px;
    border: 0.6px solid #d1dbe2;
    background-color: rgba(245, 248, 249, 0.3);
    position: relative;
    padding-left: 3px; }
    .dialogOverlay.dialogFormOverlay .dialog.dialogAddVehiculeRepair .add-school-form .school-ligne > div .select select {
      width: 100%;
      font-size: 12px;
      height: 28px;
      border: none;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      text-indent: 1px;
      text-overflow: "";
      background: transparent;
      outline: 0;
      position: relative;
      z-index: 9;
      border-radius: 4px !important;
      border: none !important;
      font-weight: 500;
      font-family: Montserrat;
      color: #1b2437;
      padding: 0 5px; }
    .dialogOverlay.dialogFormOverlay .dialog.dialogAddVehiculeRepair .add-school-form .school-ligne > div .select i {
      color: #d2dbe2;
      font-size: 14px;
      position: absolute;
      right: 5px;
      top: 6px; } }

@media (max-width: 370px) {
  .OverlayDaillyChallenge.OverlayTextbookExercice > div .content-exercice .content-exercice-width {
    width: 100%; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayStudents.OverlaySchool.OverlayHomeWork > div .school-ligne .inputLink {
    max-width: 115px; }
  .OverlayDaillyChallenge.OverlayFriends.OverlayJournals.OverlaySchool.OverlayHomeWork > div .school-ligne .inputLink {
    max-width: 115px; }
  .OverlayDaillyChallenge.OverlayFriends.OverlaySticky.OverlaySchool.OverlayHomeWork > div .school-ligne .inputLink {
    max-width: 115px; }
  .header-right ul li a {
    width: 32px;
    height: 32px; } }

@media screen and (max-height: 700px) {
  .OverlayTextbook:before {
    display: none; }
  .OverlayTextbook:after {
    display: none; }
  .OverlayTextbook .modal-fuseesLeft,
  .OverlayTextbook .modal-fuseesRight {
    display: none; }
  .OverlayScholarBooks:before {
    display: none; }
  .OverlayScholarBooks:after {
    display: none; }
  .OverlayScholarBooks .modal-fuseesLeft,
  .OverlayScholarBooks .modal-fuseesRight {
    display: none; }
  .OverlayViewScholarBook:before {
    display: none; }
  .OverlayViewScholarBook:after {
    display: none; }
  .OverlayViewScholarBook .modal-fuseesLeft,
  .OverlayViewScholarBook .modal-fuseesRight {
    display: none; } }

.sticky {
  top: 10px !important; }

.stageModal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 100000;
  background: #fff; }

.stageModal > div {
  top: 0px !important;
  left: 0px !important;
  right: 0px !important;
  bottom: 0px !important;
  border: 0px !important;
  padding: 0px !important; }

.stage_modal {
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
  min-height: 250px; }

.iti-mobile .intl-tel-input.iti-container {
  z-index: 10000000; }

@media (max-width: 767px) {
  #viewerBackdrop > button {
    display: block; }
    #viewerBackdrop > button span {
      padding: 2px 2px 4px 2px;
      border: 2px solid;
      border-radius: 4px; } }

.xblock_koolskool {
  height: 100% !important; }

.score_animate {
  position: absolute;
  z-index: 900000;
  visibility: hidden;
  display: flex;
  height: 40px;
  line-height: 40px;
  width: 100px;
  transform-origin: top left;
  display: none; }
  .score_animate.animate {
    animation: dollar_animate 1.5s ease-in forwards;
    visibility: visible;
    display: block; }
  .score_animate > img {
    width: 40px; }
  .score_animate > span {
    color: #84cacd;
    font-size: 20px;
    font-weight: bold; }

/* Standard syntax */
@keyframes dollar_animate {
  0% {
    transfrom: translate(-50%, -50%);
    left: 50%;
    top: 50%; }
  25% {
    transfrom: translate(-50%, -50%);
    left: 50%;
    top: 50%; }
  100% {
    left: 95%;
    top: 0%;
    transfrom: translate(0%, 0%); } }

.btn-challenge {
  border-radius: 14px;
  color: #fff;
  border-radius: 40px;
  padding: 0.2rem 1.5rem;
  font-size: 0.8rem;
  border: none !important; }
  .btn-challenge:hover {
    border: none !important; }

.footer_btns {
  margin-top: 2rem;
  text-align: center; }
  .footer_btns:not(.mobile) {
    margin-bottom: 2rem; }
  .footer_btns .btn-yellow, .footer_btns .container-cms .item-list .item-block .add_item, .container-cms .item-list .item-block .footer_btns .add_item, .footer_btns .item-list.list_books .item-block .add_item, .item-list.list_books .item-block .footer_btns .add_item {
    margin-right: 2rem;
    padding: 0.7rem 2rem;
    font-size: 0.7rem;
    background-image: linear-gradient(258deg, #fdd271, #d5a433);
    box-shadow: 0 5px 24px 0 rgba(239, 194, 91, 0.5);
    height: auto;
    min-width: auto; }
    .footer_btns .btn-yellow:hover, .footer_btns .container-cms .item-list .item-block .add_item:hover, .container-cms .item-list .item-block .footer_btns .add_item:hover, .footer_btns .item-list.list_books .item-block .add_item:hover, .item-list.list_books .item-block .footer_btns .add_item:hover {
      background-image: linear-gradient(258deg, #fdd271, #d5a433);
      box-shadow: 0 5px 24px 0 rgba(239, 194, 91, 0.5);
      border: none; }
    .footer_btns .btn-yellow span, .footer_btns .container-cms .item-list .item-block .add_item span, .container-cms .item-list .item-block .footer_btns .add_item span, .footer_btns .item-list.list_books .item-block .add_item span, .item-list.list_books .item-block .footer_btns .add_item span {
      margin-left: 0.5rem; }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 0.2 !important; }

::-moz-placeholder {
  opacity: 0.2 !important; }

:-ms-input-placeholder {
  opacity: 0.2 !important; }

:-moz-placeholder {
  opacity: 0.2 !important; }

.pretty.checkbox-form .state label:before, .pretty.checkbox-form .state label:after {
  top: 0px !important; }

.display_flex {
  display: flex;
  flex-wrap: wrap; }

button:disabled {
  opacity: 0.2; }

.icon {
  cursor: pointer; }

.text-add {
  /* display: inline-block; */
  text-align: center;
  min-width: 106px;
  height: 30px;
  object-fit: contain;
  border-radius: 49.5px;
  /* text-align: center; */
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
  background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
  color: #fff;
  border: none;
  margin: 0 7px;
  float: right; }

.badge-dark, .btn-dark {
  background-color: #a1a3a5; }

.badge-info {
  color: #fff;
  background-color: #c13727;
  border-radius: 50%;
  padding: 8px;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 90%;
  left: 90%;
  display: flex;
  font-size: 11px;
  align-items: center;
  justify-content: center; }

.select2-container--open {
  z-index: 99999; }

.add-school-form .select2 {
  width: max-content !important;
  min-width: 100px;
  border-radius: 2.6px;
  border: solid 0.6px #d1dbe2;
  padding-right: 2px; }

.dialogOverlayViewImage .dialogViewImage {
  width: 500px;
  height: 300px; }
  @media (max-width: 767px) {
    .dialogOverlayViewImage .dialogViewImage {
      width: 100%;
      height: 300px; } }
  .dialogOverlayViewImage .dialogViewImage img {
    position: absolute;
    width: 100%;
    height: 100%; }

.langue_toggle {
  height: 35px !important;
  border-radius: 15px; }
  .langue_toggle .btn {
    color: #212529;
    background-color: #80c9ca;
    border-color: #80c9ca; }
    .langue_toggle .btn.toggle-handle {
      background-color: #d1dbe2;
      border-color: #d1dbe2;
      border-radius: 50%;
      padding: 0.375rem 1rem; }

.month_day {
  font-weight: bold; }

.select.multiple_select {
  z-index: 1;
  padding-left: 0px !important; }
  .select.multiple_select .rw-sr {
    display: none !important; }
  .select.multiple_select .rw-widget-input ul {
    display: flex;
    margin: 0;
    padding: 2px;
    flex-wrap: wrap; }
    .select.multiple_select .rw-widget-input ul li {
      display: flex;
      margin-right: 5px; }
      .select.multiple_select .rw-widget-input ul li button {
        border-radius: 50%;
        width: 24px;
        height: 24px; }
  .select.multiple_select .rw-widget-input .rw-select {
    display: none !important; }
  .select.multiple_select .rw-list-empty {
    display: none !important; }
  .select.multiple_select .rw-popup-container {
    background: white;
    border-right: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    border-left: 1px solid #d6d6d6; }
    .select.multiple_select .rw-popup-container .rw-list {
      padding-left: 5px;
      margin-bottom: 0; }
      .select.multiple_select .rw-popup-container .rw-list li {
        border-bottom: 1px solid #e4e4e4; }
  .select.multiple_select.hide_input .rw-popup-container {
    display: none; }
  .select.multiple_select.hide_input .rw-widget-input input {
    display: none; }

.row.school-ligne.select_multiple {
  margin-bottom: 6px; }
  .row.school-ligne.select_multiple .select_multiple {
    z-index: 10; }

input.arabic_name {
  text-align: right; }

.appreciation input:disabled {
  background-color: transparent; }

.connexion-custom-select input {
  height: auto !important; }

.add_appreciation_input input {
  border: 0 !important; }

.dialogAddGradThreshold input {
  border: 0 !important; }

#choose_image.modal .modal-body {
  max-height: calc(100% - 135px); }

#choose_image.modal .list-images {
  flex-wrap: wrap;
  overflow-y: auto; }
  #choose_image.modal .list-images img {
    width: 100px;
    height: 100px;
    padding: 5px;
    margin: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    cursor: pointer; }

#choose_image.modal .btn-choose-img {
  position: relative;
  overflow: hidden; }
  #choose_image.modal .btn-choose-img input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px; }

#crop_image img {
  flex: 1; }

.content-exercise .viewer_video, .content-helpers .viewer_video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 91;
  background: white; }
  .content-exercise .viewer_video .btn, .content-helpers .viewer_video .btn {
    position: absolute;
    right: 0px; }
  .content-exercise .viewer_video iframe, .content-helpers .viewer_video iframe {
    width: 100%;
    height: 100%;
    border: none;
    padding: 15px; }

.content-exercise .asset-img, .content-helpers .asset-img {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 20px;
  left: 0px;
  cursor: grab;
  border: 1px solid #ccc; }
  .content-exercise .asset-img.viewer, .content-helpers .asset-img.viewer {
    border: none; }
  .content-exercise .asset-img .remove-asset, .content-exercise .asset-img .crop-asset, .content-helpers .asset-img .remove-asset, .content-helpers .asset-img .crop-asset {
    position: absolute;
    right: -25px;
    top: 0px;
    border-radius: 50%;
    border: 1px solid #ccc;
    background: #ccc;
    color: white;
    /* padding: 4px; */
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
  .content-exercise .asset-img .crop-asset, .content-helpers .asset-img .crop-asset {
    top: 25px; }
  .content-exercise .asset-img .doc-asset, .content-helpers .asset-img .doc-asset {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 50%;
    background: #4dc1b9;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer; }
  .content-exercise .asset-img .video-asset, .content-helpers .asset-img .video-asset {
    cursor: pointer;
    width: 100%;
    height: 100%; }
    .content-exercise .asset-img .video-asset img, .content-helpers .asset-img .video-asset img {
      width: 100%;
      height: 100%; }
    .content-exercise .asset-img .video-asset i.fa, .content-helpers .asset-img .video-asset i.fa {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: red;
      background: #fff;
      font-size: 25px; }
  .content-exercise .asset-img img, .content-helpers .asset-img img {
    width: 100%;
    height: 100%; }

.content-exercise .ui-resizable-handle, .content-helpers .ui-resizable-handle {
  position: absolute;
  display: block;
  background: #7c7c7c;
  width: 8px;
  height: 8px; }
  .content-exercise .ui-resizable-handle.ui-resizable-e, .content-helpers .ui-resizable-handle.ui-resizable-e {
    cursor: e-resize;
    right: -4px;
    top: 50%;
    transform: translateY(-50%); }
  .content-exercise .ui-resizable-handle.ui-resizable-w, .content-helpers .ui-resizable-handle.ui-resizable-w {
    cursor: w-resize;
    left: -4px;
    top: 50%;
    transform: translateY(-50%); }
  .content-exercise .ui-resizable-handle.ui-resizable-s, .content-helpers .ui-resizable-handle.ui-resizable-s {
    cursor: s-resize;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%); }
  .content-exercise .ui-resizable-handle.ui-resizable-sw, .content-helpers .ui-resizable-handle.ui-resizable-sw {
    cursor: sw-resize;
    bottom: -4px;
    left: -4px; }
  .content-exercise .ui-resizable-handle.ui-resizable-se, .content-helpers .ui-resizable-handle.ui-resizable-se {
    cursor: se-resize;
    bottom: -4px;
    right: -4px; }
  .content-exercise .ui-resizable-handle.ui-resizable-n, .content-helpers .ui-resizable-handle.ui-resizable-n {
    cursor: s-resize;
    top: -4px;
    left: 50%;
    transform: translateX(-50%); }
  .content-exercise .ui-resizable-handle.ui-resizable-ne, .content-helpers .ui-resizable-handle.ui-resizable-ne {
    cursor: ne-resize;
    top: -4px;
    right: -4px; }
  .content-exercise .ui-resizable-handle.ui-resizable-nw, .content-helpers .ui-resizable-handle.ui-resizable-nw {
    cursor: nw-resize;
    top: -4px;
    left: -4px; }

.view_image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: gray; }
  .view_image > img {
    position: absolute;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .view_image.cms {
    width: 80%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100; }
    .view_image.cms .closeModal {
      position: absolute;
      top: -15px;
      right: -17px;
      border: 6px solid #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 37px;
      line-height: 23px;
      background-color: #47bfb6;
      z-index: 99;
      box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3); }
    .view_image.cms > img {
      position: absolute;
      left: 50%;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      top: 50%;
      transform: translate(-50%, -50%); }
  .view_image.act {
    z-index: 990; }
    @media (max-width: 1200px) {
      .view_image.act {
        width: 100%;
        height: 100%; }
        .view_image.act .closeModal {
          top: 0px;
          right: 0px; } }

.view_video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: gray; }
  .view_video.cms {
    width: 80%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100; }
    .view_video.cms .closeModal {
      position: absolute;
      top: -15px;
      right: -17px;
      border: 6px solid #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 37px;
      line-height: 23px;
      background-color: #47bfb6;
      z-index: 99;
      box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3); }
  .view_video.act {
    z-index: 990; }
    @media (max-width: 1200px) {
      .view_video.act {
        width: 100%;
        height: 100%; }
        .view_video.act .closeModal {
          top: 0px;
          right: 0px; } }
  .view_video .react-player2 {
    position: absolute;
    left: 0;
    width: 100%;
    top: 50%;
    transform: translateY(-50%); }

.view_description {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #cbcbcb;
  border-radius: 15px;
  padding: 25px; }
  .view_description.cms {
    width: 80%;
    height: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000; }
    .view_description.cms .closeModal {
      position: absolute;
      top: -15px;
      right: -17px;
      border: 6px solid #fff;
      width: 40px;
      height: 40px;
      text-align: center;
      color: #fff;
      border-radius: 50%;
      font-size: 37px;
      line-height: 23px;
      background-color: #47bfb6;
      z-index: 99;
      box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.3); }
  .view_description .description {
    width: 100%; }

.input-group {
  flex-wrap: nowrap; }

.fa-emoji {
  color: #ffda51;
  font-size: 20px; }

.replacement_seance {
  border-bottom: 2px solid red; }

.mq-math-mode {
  direction: ltr;
  cursor: default;
  /*.mq-supsub{
    .mq-sup{
      margin-bottom: 10px!important;
    }
    .mq-sub{
      margin-top: 20px!important;
    }
  }*/ }
  .mq-math-mode.mq_selected {
    cursor: not-allowed;
    outline: 3px solid #b4d7ff; }
  .mq-math-mode .mq-dot-recurring {
    font-weight: bold; }
  .mq-math-mode * {
    font-family: Symbola, "Times New Roman", serif !important; }

/*.mq-math-mode var, .mq-math-mode i, .mq-math-mode i.mq-font, .mq-math-mode .mq-sqrt-stem{
  display: inline-flex;
}*/
body .tox-silver-sink {
  z-index: 111111111111; }

.pos_rel {
  position: relative; }

.p_l_14 {
  padding-left: 14px; }

.w_600 {
  width: 600px !important; }

.w_500 {
  width: 500px !important; }

.post_type_icon {
  background-image: url("../img/iconProfessor.svg");
  color: yellow;
  width: 24px;
  background-repeat: no-repeat;
  padding-left: 3px;
  cursor: pointer;
  background-size: contain;
  height: 20px;
  margin: 3px; }

.h_100 {
  height: 100%; }
  .h_100 p.p_render {
    height: calc(100% - 15px);
    overflow-y: auto;
    margin-top: 15px; }
  .h_100 .tox-toolbar__primary {
    width: 100%;
    padding-right: 20px; }

.d_flex {
  display: flex; }

.jc_sb {
  justify-content: space-between; }

.h_0 {
  height: 0 !important; }

.school_validation_params .modal-content {
  height: 100%; }
  .school_validation_params .modal-content > div {
    height: 100%; }
    .school_validation_params .modal-content > div .modal-body {
      height: calc(100% - 40px); }
      .school_validation_params .modal-content > div .modal-body .list_classes_controls {
        height: calc(100% - 56px);
        overflow: auto; }

.dialogAddGradThreshold .choosed_color {
  width: 50px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
  border: 2px solid; }

.dialogAddReinscriptionBlockageReason {
  height: 90%; }
  .dialogAddReinscriptionBlockageReason .add-school-form {
    height: calc(80vh - 70px); }
    .dialogAddReinscriptionBlockageReason .add-school-form .school-ligne-margin {
      height: calc(100% - 40px);
      margin: auto;
      width: 100%;
      overflow: auto; }
      .dialogAddReinscriptionBlockageReason .add-school-form .school-ligne-margin::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5; }
      .dialogAddReinscriptionBlockageReason .add-school-form .school-ligne-margin::-webkit-scrollbar {
        width: 5px;
        height: 3px;
        background-color: #F5F5F5; }
      .dialogAddReinscriptionBlockageReason .add-school-form .school-ligne-margin::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
      .dialogAddReinscriptionBlockageReason .add-school-form .school-ligne-margin .school-ligne-w100 {
        padding: 0 12px; }

.dialogGenerateControls {
  max-width: 90%;
  width: 90%;
  height: 95%;
  display: flex;
  flex-direction: column; }
  .dialogGenerateControls .generate_controls {
    height: 100%; }
    .dialogGenerateControls .generate_controls .header {
      text-align: center; }
    .dialogGenerateControls .generate_controls .form {
      height: calc(100% - 47px); }
      .dialogGenerateControls .generate_controls .form .add-school-form {
        height: calc(100% - 45px);
        margin-bottom: 15px; }
      .dialogGenerateControls .generate_controls .form .actions {
        width: 100%; }
      .dialogGenerateControls .generate_controls .form .controls_table {
        height: calc(100% - 87px); }
    .dialogGenerateControls .generate_controls .table_controls {
      height: 100%;
      overflow-y: auto;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 8px;
      padding: 10px 5px;
      border: 1px solid #d1dbe2; }
      .dialogGenerateControls .generate_controls .table_controls table {
        max-width: 100%; }
        .dialogGenerateControls .generate_controls .table_controls table.table_content {
          border-collapse: separate;
          border-spacing: 0px; }
          .dialogGenerateControls .generate_controls .table_controls table.table_content tr:not(.spacer) th {
            padding: 10px;
            text-align: center;
            background-color: #f5f6fa;
            border: 1px solid #e8e8e8;
            border-width: 1px 0px 1px 1px; }
            .dialogGenerateControls .generate_controls .table_controls table.table_content tr:not(.spacer) th:first-child {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px; }
            .dialogGenerateControls .generate_controls .table_controls table.table_content tr:not(.spacer) th:last-child {
              border-right-width: 1px;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px; }
            .dialogGenerateControls .generate_controls .table_controls table.table_content tr:not(.spacer) th.subject {
              width: 55%; }
          .dialogGenerateControls .generate_controls .table_controls table.table_content tr:not(.spacer) td {
            background-color: #f5f6fa;
            border: 1px solid #e8e8e8;
            border-width: 1px 0px 1px 1px;
            height: 50px;
            padding: 10px; }
            .dialogGenerateControls .generate_controls .table_controls table.table_content tr:not(.spacer) td:first-child {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px; }
            .dialogGenerateControls .generate_controls .table_controls table.table_content tr:not(.spacer) td:last-child {
              border-right-width: 1px;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px; }
            .dialogGenerateControls .generate_controls .table_controls table.table_content tr:not(.spacer) td.subject .RadioLegend, .dialogGenerateControls .generate_controls .table_controls table.table_content tr:not(.spacer) td.chapter .RadioLegend {
              float: right; }
            .dialogGenerateControls .generate_controls .table_controls table.table_content tr:not(.spacer) td.control {
              position: relative; }
              .dialogGenerateControls .generate_controls .table_controls table.table_content tr:not(.spacer) td.control .RadioLegend {
                margin-right: 1px; }
              .dialogGenerateControls .generate_controls .table_controls table.table_content tr:not(.spacer) td.control div {
                display: flex; }
                .dialogGenerateControls .generate_controls .table_controls table.table_content tr:not(.spacer) td.control div:not(.field_error):not(:last-of-type) {
                  margin-bottom: 5px; }
                .dialogGenerateControls .generate_controls .table_controls table.table_content tr:not(.spacer) td.control div.field_error {
                  font-size: 10px;
                  position: absolute;
                  bottom: -3px; }
                .dialogGenerateControls .generate_controls .table_controls table.table_content tr:not(.spacer) td.control div input.error {
                  border: 1px solid red; }
              .dialogGenerateControls .generate_controls .table_controls table.table_content tr:not(.spacer) td.control input {
                min-width: 50px; }
          .dialogGenerateControls .generate_controls .table_controls table.table_content tr.spacer {
            height: 10px; }
      .dialogGenerateControls .generate_controls .table_controls::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5; }
      .dialogGenerateControls .generate_controls .table_controls::-webkit-scrollbar {
        width: 5px;
        height: 3px;
        background-color: #F5F5F5; }
      .dialogGenerateControls .generate_controls .table_controls::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
    .dialogGenerateControls .generate_controls .field_error {
      color: red; }

.dialogAttachObjectifsToControls {
  max-width: 90%;
  width: 90%;
  height: 95%;
  display: flex;
  flex-direction: column; }
  .dialogAttachObjectifsToControls .attach_objectifs {
    height: 100%; }
    .dialogAttachObjectifsToControls .attach_objectifs .header {
      text-align: center; }
    .dialogAttachObjectifsToControls .attach_objectifs .form {
      height: calc(100% - 47px); }
      .dialogAttachObjectifsToControls .attach_objectifs .form .add-school-form {
        height: calc(100% - 45px);
        margin-bottom: 15px; }
      .dialogAttachObjectifsToControls .attach_objectifs .form .actions {
        width: 100%; }
      .dialogAttachObjectifsToControls .attach_objectifs .form .objectifs_table {
        height: calc(100% - 87px); }
    .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs {
      height: 100%;
      overflow-y: auto;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 8px;
      padding: 10px 5px;
      border: 1px solid #d1dbe2; }
      .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs table {
        width: 100%; }
        .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs table.table_content {
          border-collapse: separate;
          border-spacing: 0px; }
          .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs table.table_content tr:not(.spacer) th {
            padding: 10px;
            text-align: center;
            background-color: #f5f6fa;
            border: 1px solid #e8e8e8;
            border-width: 1px 0px 1px 1px; }
            .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs table.table_content tr:not(.spacer) th:first-child {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px; }
            .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs table.table_content tr:not(.spacer) th:last-child {
              border-right-width: 1px;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px; }
            .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs table.table_content tr:not(.spacer) th.attach {
              width: 120px; }
          .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs table.table_content tr:not(.spacer) td {
            background-color: #f5f6fa;
            border: 1px solid #e8e8e8;
            border-width: 1px 0px 1px 1px;
            height: 50px;
            padding: 10px; }
            .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs table.table_content tr:not(.spacer) td:first-child {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px; }
            .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs table.table_content tr:not(.spacer) td:last-child {
              border-right-width: 1px;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px; }
            .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs table.table_content tr:not(.spacer) td.attach {
              width: 120px; }
              .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs table.table_content tr:not(.spacer) td.attach .RadioLegend {
                margin: auto;
                float: unset;
                margin-left: 36px; }
            .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs table.table_content tr:not(.spacer) td.control {
              position: relative; }
              .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs table.table_content tr:not(.spacer) td.control .RadioLegend {
                margin-right: 10px; }
              .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs table.table_content tr:not(.spacer) td.control div {
                display: flex; }
                .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs table.table_content tr:not(.spacer) td.control div:not(.field_error):not(:last-of-type) {
                  margin-bottom: 5px; }
                .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs table.table_content tr:not(.spacer) td.control div.field_error {
                  font-size: 10px;
                  position: absolute;
                  bottom: -3px; }
                .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs table.table_content tr:not(.spacer) td.control div input.error {
                  border: 1px solid red; }
          .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs table.table_content tr.spacer {
            height: 10px; }
      .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5; }
      .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs::-webkit-scrollbar {
        width: 5px;
        height: 3px;
        background-color: #F5F5F5; }
      .dialogAttachObjectifsToControls .attach_objectifs .table_objectifs::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
    .dialogAttachObjectifsToControls .attach_objectifs .field_error {
      color: red; }

.dialogGenerateControlsAcquis {
  max-width: 90%;
  width: 90%;
  height: 95%;
  display: flex;
  flex-direction: column; }
  .dialogGenerateControlsAcquis .generate_controls_acquis {
    height: 100%; }
    .dialogGenerateControlsAcquis .generate_controls_acquis .header {
      text-align: center; }
    .dialogGenerateControlsAcquis .generate_controls_acquis .form {
      height: calc(100% - 47px); }
      .dialogGenerateControlsAcquis .generate_controls_acquis .form .add-school-form {
        height: calc(100% - 45px);
        margin-bottom: 15px; }
      .dialogGenerateControlsAcquis .generate_controls_acquis .form .actions {
        width: 100%; }
      .dialogGenerateControlsAcquis .generate_controls_acquis .form .controls_table {
        height: calc(100% - 87px); }
    .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls {
      height: 100%;
      overflow-y: auto;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 8px;
      padding: 10px 5px;
      border: 1px solid #d1dbe2; }
      .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls table {
        max-width: 100%; }
        .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls table.table_content {
          border-collapse: separate;
          border-spacing: 0px; }
          .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls table.table_content tr:not(.spacer) th {
            padding: 10px;
            text-align: center;
            background-color: #f5f6fa;
            border: 1px solid #e8e8e8;
            border-width: 1px 0px 1px 1px; }
            .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls table.table_content tr:not(.spacer) th:first-child {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px; }
            .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls table.table_content tr:not(.spacer) th:last-child {
              border-right-width: 1px;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px; }
            .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls table.table_content tr:not(.spacer) th.subject {
              width: 55%; }
          .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls table.table_content tr:not(.spacer) td {
            background-color: #f5f6fa;
            border: 1px solid #e8e8e8;
            border-width: 1px 0px 1px 1px;
            height: 50px;
            padding: 10px; }
            .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls table.table_content tr:not(.spacer) td:first-child {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px; }
            .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls table.table_content tr:not(.spacer) td:last-child {
              border-right-width: 1px;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px; }
            .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls table.table_content tr:not(.spacer) td.control {
              position: relative; }
              .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls table.table_content tr:not(.spacer) td.control .RadioLegend {
                margin-right: 10px; }
              .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls table.table_content tr:not(.spacer) td.control div {
                display: flex; }
                .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls table.table_content tr:not(.spacer) td.control div:not(.field_error):not(:last-of-type) {
                  margin-bottom: 5px; }
                .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls table.table_content tr:not(.spacer) td.control div.field_error {
                  font-size: 10px;
                  position: absolute;
                  bottom: -3px; }
                .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls table.table_content tr:not(.spacer) td.control div input.error {
                  border: 1px solid red; }
            .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls table.table_content tr:not(.spacer) td.control_acquis > div {
              text-align: center; }
              .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls table.table_content tr:not(.spacer) td.control_acquis > div .RadioLegend {
                margin: auto; }
          .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls table.table_content tr.spacer {
            height: 10px; }
      .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5; }
      .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls::-webkit-scrollbar {
        width: 5px;
        height: 3px;
        background-color: #F5F5F5; }
      .dialogGenerateControlsAcquis .generate_controls_acquis .table_controls::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
    .dialogGenerateControlsAcquis .generate_controls_acquis .field_error {
      color: red; }

.dialogAttachActivityClasseObjectifsAcquis {
  max-width: 90%;
  width: 90%;
  height: 95%;
  display: flex;
  flex-direction: column; }
  .dialogAttachActivityClasseObjectifsAcquis .objectifs_list {
    height: 100%; }
    .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .header {
      text-align: center; }
    .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .form {
      height: calc(100% - 47px); }
      .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .form .add-school-form {
        height: calc(100% - 45px);
        margin-bottom: 15px; }
      .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .form .actions {
        width: 100%; }
      .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .form .controls_table {
        height: calc(100% - 87px); }
    .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls {
      height: 100%;
      overflow-y: auto;
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 8px;
      padding: 10px 5px;
      border: 1px solid #d1dbe2; }
      .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls table {
        max-width: 100%; }
        .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls table.table_content {
          border-collapse: separate;
          border-spacing: 0px; }
          .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls table.table_content tr:not(.spacer) th {
            padding: 10px;
            text-align: center;
            background-color: #f5f6fa;
            border: 1px solid #e8e8e8;
            border-width: 1px 0px 1px 1px; }
            .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls table.table_content tr:not(.spacer) th:first-child {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px; }
            .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls table.table_content tr:not(.spacer) th:last-child {
              border-right-width: 1px;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px; }
            .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls table.table_content tr:not(.spacer) th.subject {
              width: 55%; }
          .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls table.table_content tr:not(.spacer) td {
            background-color: #f5f6fa;
            border: 1px solid #e8e8e8;
            border-width: 1px 0px 1px 1px;
            height: 50px;
            padding: 10px; }
            .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls table.table_content tr:not(.spacer) td:first-child {
              border-top-left-radius: 4px;
              border-bottom-left-radius: 4px; }
            .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls table.table_content tr:not(.spacer) td:last-child {
              border-right-width: 1px;
              border-top-right-radius: 4px;
              border-bottom-right-radius: 4px; }
            .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls table.table_content tr:not(.spacer) td.control {
              position: relative; }
              .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls table.table_content tr:not(.spacer) td.control .RadioLegend {
                margin-right: 10px; }
              .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls table.table_content tr:not(.spacer) td.control div {
                display: flex; }
                .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls table.table_content tr:not(.spacer) td.control div:not(.field_error):not(:last-of-type) {
                  margin-bottom: 5px; }
                .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls table.table_content tr:not(.spacer) td.control div.field_error {
                  font-size: 10px;
                  position: absolute;
                  bottom: -3px; }
                .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls table.table_content tr:not(.spacer) td.control div input.error {
                  border: 1px solid red; }
            .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls table.table_content tr:not(.spacer) td.control_acquis > div {
              text-align: center; }
              .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls table.table_content tr:not(.spacer) td.control_acquis > div .RadioLegend {
                margin: auto; }
          .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls table.table_content tr.spacer {
            height: 10px; }
      .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5; }
      .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls::-webkit-scrollbar {
        width: 5px;
        height: 3px;
        background-color: #F5F5F5; }
      .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .table_controls::-webkit-scrollbar-thumb {
        background-color: #3eb6ad; }
    .dialogAttachActivityClasseObjectifsAcquis .objectifs_list .field_error {
      color: red; }

.dialogSortChapters {
  overflow: unset !important; }

.upload_file_button button {
  position: relative; }
  .upload_file_button button .file_uploading {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%); }
  .upload_file_button button input {
    cursor: pointer;
    opacity: 0 !important;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }

.upload_file_button .actions {
  display: flex;
  justify-content: center;
  width: 60px; }
  .upload_file_button .actions button {
    margin: 0 5px;
    padding: 0 5px;
    width: 20px !important;
    height: 20px !important;
    font-size: 12px;
    line-height: 20px; }
    .upload_file_button .actions button i {
      font-size: 13px; }
  .upload_file_button .actions .remove_document {
    color: red;
    margin: 0 5px;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid red;
    display: block;
    width: 20px;
    height: 20px;
    line-height: 19px; }
    .upload_file_button .actions .remove_document i {
      margin-left: 4px; }

.upload_file_button.absences_modal .document {
  display: flex;
  justify-content: center; }

.upload_file_button.absences_modal .remove_document {
  margin-top: 7px; }
  .upload_file_button.absences_modal .remove_document i {
    margin-left: 3px; }

.upload_file_button.absences_modal.card img {
  margin: auto; }

.upload_file_button.absences_modal.card .remove_document {
  margin: auto; }
  .upload_file_button.absences_modal.card .remove_document i {
    margin-left: 2px; }

.upload_file_button.absences_modal.card button {
  min-width: max-content;
  border: 0;
  margin-top: 5px;
  margin-left: 5px;
  overflow: hidden; }
  .upload_file_button.absences_modal.card button input {
    z-index: 1111; }

.upload_file_button.absences_modal.professor_abs .remove_document {
  border: 0; }
  .upload_file_button.absences_modal.professor_abs .remove_document i {
    width: 100%;
    height: 100%;
    padding-top: 0; }

.dialogOverlayZIndex0 {
  z-index: -1; }

.dialogOverlayZIndex10000000 {
  z-index: 10000000; }

.modal_file_viewer > div {
  width: calc(100% - 10px) !important;
  max-width: inherit !important;
  height: calc(100% - 20px) !important;
  margin: 10px auto !important; }

.modal_file_viewer.school_modal > div {
  width: calc(30% - 10px) !important;
  max-height: 600px;
  min-height: 600px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important; }
  .modal_file_viewer.school_modal > div .contacts {
    display: flex;
    flex-direction: column;
    justify-content: center; }

.modal_file_viewer.school_modal.mobile > div {
  width: calc(90%) !important; }
  .modal_file_viewer.school_modal.mobile > div .modal-body h1 {
    font-size: 1.5rem; }
  .modal_file_viewer.school_modal.mobile > div .contacts .contact img {
    width: 11vw; }
  .modal_file_viewer.school_modal.mobile > div .contacts .contact .vals span {
    font-size: 4vw; }
  .modal_file_viewer.school_modal.mobile > div .contacts .contact .vals a {
    font-size: 4vw; }

.modal_file_viewer.school_modal.tablette > div {
  width: calc(60%) !important; }
  .modal_file_viewer.school_modal.tablette > div .contacts .contact img {
    width: 7vw; }
  .modal_file_viewer.school_modal.tablette > div .contacts .contact .vals span {
    font-size: 2vw; }
  .modal_file_viewer.school_modal.tablette > div .contacts .contact .vals a {
    font-size: 2vw; }

@media (max-width: 767px) {
  .modal_file_viewer.school_modal > div {
    width: calc(100% - 10px) !important; } }

@media (min-width: 767px) {
  .modal_file_viewer.school_modal > div {
    min-width: 540px !important; } }

.rtl {
  direction: rtl; }

.lh25 {
  line-height: 25px !important; }

.color_note_select_item {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: auto; }

.import_objectifs_input {
  display: none; }

.dialogIportOpjectifsResponse .sheet_errors {
  padding: 25px; }
  .dialogIportOpjectifsResponse .sheet_errors .error {
    padding-left: 15px; }

.dialogSubjectsColorsBulletinOverlay {
  z-index: 999 !important; }
  .dialogSubjectsColorsBulletinOverlay .dialogSubjectsColorsBulletin {
    max-width: 100%;
    max-height: 100%;
    height: max-content; }
    .dialogSubjectsColorsBulletinOverlay .dialogSubjectsColorsBulletin .subjects_test_modal {
      height: 100%;
      max-height: 100%; }
      .dialogSubjectsColorsBulletinOverlay .dialogSubjectsColorsBulletin .subjects_test_modal .subjects_colors_for_bulletin_div .table_content {
        max-height: 300px;
        overflow-y: auto;
        margin-top: 15px; }
        .dialogSubjectsColorsBulletinOverlay .dialogSubjectsColorsBulletin .subjects_test_modal .subjects_colors_for_bulletin_div .table_content::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #f5f5f5; }
        .dialogSubjectsColorsBulletinOverlay .dialogSubjectsColorsBulletin .subjects_test_modal .subjects_colors_for_bulletin_div .table_content::-webkit-scrollbar {
          width: 5px;
          background-color: #f5f5f5; }
        .dialogSubjectsColorsBulletinOverlay .dialogSubjectsColorsBulletin .subjects_test_modal .subjects_colors_for_bulletin_div .table_content::-webkit-scrollbar-thumb {
          background-color: #3eb6ad; }
        .dialogSubjectsColorsBulletinOverlay .dialogSubjectsColorsBulletin .subjects_test_modal .subjects_colors_for_bulletin_div .table_content table td > div {
          margin: auto; }

.custom_add_button {
  padding: 5px;
  cursor: pointer; }
  .custom_add_button i.fa-plus {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    box-shadow: rgba(239, 194, 91, 0.5) 0px 18px 36px 0px;
    background-image: linear-gradient(225deg, #fdd271, #d5a433);
    font-size: 15px;
    text-align: center;
    line-height: 20px;
    color: white;
    margin-bottom: 15px; }
  .custom_add_button.collaborators {
    color: white;
    background-image: linear-gradient(225deg, #fdd271, #d5a433);
    border-radius: 5px;
    display: flex; }
    .custom_add_button.collaborators i.fa-plus {
      background-image: unset;
      margin-bottom: 0; }
  .custom_add_button.supervisors {
    color: white;
    background-image: linear-gradient(225deg, #fdd271, #d5a433);
    border-radius: 5px;
    display: flex; }
    .custom_add_button.supervisors i.fa-plus {
      background-image: unset;
      margin-bottom: 0; }

.dialogAddStudent .radio_content {
  width: 100%;
  display: flex; }
  .dialogAddStudent .radio_content .opt {
    margin-left: 5px;
    width: 100%; }
  .dialogAddStudent .radio_content:first-of-type {
    margin-bottom: 7px; }
  .dialogAddStudent .radio_content .RadioLegend {
    margin: 0 !important; }

.dialogAddStudent .inline_label_select {
  display: flex;
  width: 100%; }
  .dialogAddStudent .inline_label_select label {
    margin: 0 !important;
    line-height: 27px !important;
    margin-right: 5px !important; }

.dialogAddStudent .health_info_div {
  display: flex; }
  .dialogAddStudent .health_info_div .RadioLegend {
    margin: 0 !important; }

.dialogAddStudent .parent {
  display: flex;
  flex-direction: column;
  padding: 5px;
  position: relative;
  padding-top: 10px;
  margin-bottom: 15px;
  margin-top: 15px; }
  .dialogAddStudent .parent.mt30 {
    margin-top: 30px; }
  .dialogAddStudent .parent .relation {
    display: flex; }
    .dialogAddStudent .parent .relation .radios {
      min-width: max-content;
      margin-right: 10px; }
      .dialogAddStudent .parent .relation .radios .RadioLegend {
        width: max-content !important;
        margin: 0 !important; }
  .dialogAddStudent .parent.add_parent_border {
    border: 2px solid #cecdcd;
    border-radius: 5px; }
    .dialogAddStudent .parent.add_parent_border .title {
      color: black;
      font-weight: bold;
      position: absolute;
      top: 0;
      left: 5px;
      transform: translateY(-50%);
      background: white;
      padding: 0px 5px; }
  .dialogAddStudent .parent .remove_parent {
    position: absolute;
    font-weight: bold;
    color: white;
    background: red;
    border-radius: 50%;
    border: 2px solid white;
    cursor: pointer;
    right: 0;
    top: 0;
    transform: translate(50%, -50%);
    width: 20px;
    height: 20px;
    line-height: 16px;
    text-align: center;
    padding-left: 1px; }

.dialogAddStudent .actionsAddParent {
  text-align: center; }
  .dialogAddStudent .actionsAddParent .addParent {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    box-shadow: rgba(239, 194, 91, 0.5) 0px 18px 36px 0px;
    background-image: linear-gradient(225deg, #fdd271, #d5a433);
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    font-weight: bold;
    color: white;
    margin-top: -10px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer; }

.dialogAddStudent .actionsAddBrother {
  text-align: left;
  margin-top: 5px; }
  .dialogAddStudent .actionsAddBrother .addBrother {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    box-shadow: rgba(239, 194, 91, 0.5) 0px 18px 36px 0px;
    background-image: linear-gradient(225deg, #3eb6ad, #3eb6ad);
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    font-weight: bold;
    color: white;
    margin-top: -10px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer; }

.dialogAddStudent .persons_to_contact_item {
  position: relative;
  border: 2px solid #c8c8c8;
  border-radius: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 15px !important;
  padding: 10px;
  margin-top: 15px; }
  .dialogAddStudent .persons_to_contact_item .title {
    position: absolute;
    bottom: calc(100% - 5px);
    background-color: #fff;
    padding: 0 5px;
    color: grey;
    font-weight: 700; }
  .dialogAddStudent .persons_to_contact_item .display_flex label {
    width: 100%; }
  .dialogAddStudent .persons_to_contact_item .display_flex .input_choice {
    width: 31%;
    margin: 1%; }

.dialogAddStudent .addBrothersContainer > div:not(.header):not(.footer) {
  height: 250px;
  overflow: hidden;
  overflow-y: auto;
  padding: 15px; }

.dialogAddStudent .addBrothersContainer .header {
  width: 100%;
  padding: 0 15px; }

.dialogAddStudent .addBrothersContainer .footer {
  width: 100%;
  padding: 0 15px;
  text-align: center;
  padding-top: 10px; }
  .dialogAddStudent .addBrothersContainer .footer .valid {
    width: max-content;
    height: 30px;
    object-fit: contain;
    border-radius: 49.5px;
    background-color: rgba(245, 248, 249, 0.3);
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 22px;
    letter-spacing: normal;
    margin: auto;
    padding: 5px 15px;
    box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
    background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
    color: #fff;
    border: none;
    cursor: pointer; }

.dialogAddStudent .brother {
  display: flex;
  justify-content: flex-start; }
  .dialogAddStudent .brother .name {
    font-weight: bold; }

.dialogAddStudent .student_files {
  border: 2px solid #cecdcd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 15px;
  padding: 15px; }
  .dialogAddStudent .student_files .title {
    color: #565656;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 5px;
    transform: translateY(-50%);
    background: white;
    padding: 0px 5px; }
  .dialogAddStudent .student_files .school-ligne {
    margin-bottom: 0 !important; }
    .dialogAddStudent .student_files .school-ligne .pretty label:before, .dialogAddStudent .student_files .school-ligne .pretty label:after {
      top: 0; }

.dialogAddStudent .services_for_subscription {
  border: 2px solid #cecdcd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 15px;
  padding: 15px; }
  .dialogAddStudent .services_for_subscription .title {
    color: #565656;
    font-weight: bold;
    position: absolute;
    top: 0;
    left: 5px;
    transform: translateY(-50%);
    background: white;
    padding: 0px 5px; }
  .dialogAddStudent .services_for_subscription .school-ligne {
    margin-bottom: 0 !important; }
    .dialogAddStudent .services_for_subscription .school-ligne .pretty {
      margin-bottom: 10px; }
      .dialogAddStudent .services_for_subscription .school-ligne .pretty > div {
        padding-left: 10px; }
      .dialogAddStudent .services_for_subscription .school-ligne .pretty label {
        line-height: 24px;
        margin: 0;
        color: #5a595c; }
        .dialogAddStudent .services_for_subscription .school-ligne .pretty label:before, .dialogAddStudent .services_for_subscription .school-ligne .pretty label:after {
          width: calc(1.5em + 2px);
          height: calc(1.5em + 2px);
          top: 0; }

.dialogAddStudent .integration_documents {
  border: 2px solid #cecdcd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 15px;
  padding: 15px; }
  .dialogAddStudent .integration_documents .title {
    color: #565656;
    font-weight: bold;
    padding: 0px 5px;
    text-align: center; }
  .dialogAddStudent .integration_documents .school-ligne {
    margin-bottom: 0 !important;
    margin-top: 15px !important; }
    .dialogAddStudent .integration_documents .school-ligne .intergration_icon_button {
      padding: 0 15px;
      display: flex; }
      .dialogAddStudent .integration_documents .school-ligne .intergration_icon_button .intergration_icon img {
        width: 100px; }
      .dialogAddStudent .integration_documents .school-ligne .intergration_icon_button .button {
        margin: auto; }
        .dialogAddStudent .integration_documents .school-ligne .intergration_icon_button .button > div {
          cursor: pointer;
          width: max-content;
          padding: 5px 40px;
          color: white;
          font-weight: 500;
          background: #08c2a3;
          border-radius: 26px;
          font-size: 26px; }

.dialogAddStudent .departure_certificate {
  border: 2px solid #cecdcd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 15px;
  padding: 15px; }
  .dialogAddStudent .departure_certificate .title {
    color: #565656;
    font-weight: bold;
    padding: 0px 5px; }
  .dialogAddStudent .departure_certificate .school-ligne {
    margin-bottom: 0 !important;
    margin-top: 15px !important; }
    .dialogAddStudent .departure_certificate .school-ligne .pretty label:before, .dialogAddStudent .departure_certificate .school-ligne .pretty label:after {
      top: 0; }

.dialogAddStudent .files_drag .drag_div {
  position: relative;
  width: 100%;
  margin: 0px; }
  .dialogAddStudent .files_drag .drag_div .template {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0px 5px; }
    .dialogAddStudent .files_drag .drag_div .template .name {
      width: calc(100% - 15px);
      text-align: left;
      display: block; }
    .dialogAddStudent .files_drag .drag_div .template .remove_template {
      color: red; }

.dialogAddStudent .student_infos {
  display: flex;
  margin-bottom: 10px;
  justify-content: center; }
  .dialogAddStudent .student_infos > div {
    display: flex;
    margin-left: 5px;
    justify-content: center; }
    .dialogAddStudent .student_infos > div .key {
      color: gray;
      font-weight: bold; }
    .dialogAddStudent .student_infos > div .val {
      font-weight: bold; }

.type_schools .type_school .pretty label:before, .type_schools .type_school .pretty label:after {
  top: 0; }

.dialogAddIntegrationFormular .files_drag .template {
  display: flex;
  justify-content: space-between;
  padding: 0px 5px; }
  .dialogAddIntegrationFormular .files_drag .template .name {
    width: calc(100% - 15px);
    text-align: left;
    display: block; }
  .dialogAddIntegrationFormular .files_drag .template .remove_template {
    color: red; }

.overlyPrintIntegrationFormulars {
  z-index: 0; }

.dialogConfirmAddress {
  width: 800px;
  height: 258px;
  border: solid 1px #979797 !important;
  padding: 30px; }
  .dialogConfirmAddress h2 {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 15px; }
  .dialogConfirmAddress .schoolClose {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
    background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
    text-align: center;
    color: #fff;
    font-size: 40px;
    line-height: 40px;
    transform: translate(30%, -30%); }
  .dialogConfirmAddress .transport_address_content {
    display: flex;
    justify-content: space-between; }
    .dialogConfirmAddress .transport_address_content .transport_address {
      width: 80%;
      height: 150px; }
      .dialogConfirmAddress .transport_address_content .transport_address textarea {
        width: 100%;
        height: 100%;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        font-size: 14px;
        font-weight: 400;
        color: #1b2437 !important; }
    .dialogConfirmAddress .transport_address_content .actions {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .dialogConfirmAddress .transport_address_content .actions button {
        width: 110px;
        height: 30px;
        object-fit: contain;
        border-radius: 49.5px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3eb6ad;
        margin: 5px; }
        .dialogConfirmAddress .transport_address_content .actions button.yes {
          box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
          color: #fff;
          border: none; }

.dialogEditMissingDocumentsSMS {
  width: 800px;
  height: 350px;
  border: solid 1px #979797 !important;
  padding: 30px; }
  .dialogEditMissingDocumentsSMS.add_length {
    height: 400px; }
  .dialogEditMissingDocumentsSMS.with_errors {
    height: 400px; }
  .dialogEditMissingDocumentsSMS.with_errors.add_length {
    height: 420px; }
  .dialogEditMissingDocumentsSMS h2 {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 15px; }
  .dialogEditMissingDocumentsSMS .schoolClose {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
    background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
    text-align: center;
    color: #fff;
    font-size: 40px;
    line-height: 40px;
    transform: translate(30%, -30%); }
  .dialogEditMissingDocumentsSMS .edit_sms_content {
    display: flex;
    justify-content: space-between; }
    .dialogEditMissingDocumentsSMS .edit_sms_content .sms_content {
      width: 80%;
      height: 150px; }
      .dialogEditMissingDocumentsSMS .edit_sms_content .sms_content textarea {
        width: 100%;
        height: 100%;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        font-size: 14px;
        font-weight: 400;
        color: #1b2437 !important; }
      .dialogEditMissingDocumentsSMS .edit_sms_content .sms_content .parents .radio_content {
        width: 100%;
        display: flex;
        margin: 0; }
        .dialogEditMissingDocumentsSMS .edit_sms_content .sms_content .parents .radio_content .opt {
          margin-left: 5px;
          width: 100%; }
        .dialogEditMissingDocumentsSMS .edit_sms_content .sms_content .parents .radio_content:first-of-type {
          margin-bottom: 7px; }
        .dialogEditMissingDocumentsSMS .edit_sms_content .sms_content .parents .radio_content .RadioLegend {
          margin: 0 !important; }
          .dialogEditMissingDocumentsSMS .edit_sms_content .sms_content .parents .radio_content .RadioLegend > span {
            padding: 0; }
          .dialogEditMissingDocumentsSMS .edit_sms_content .sms_content .parents .radio_content .RadioLegend legend {
            margin: 0; }
      .dialogEditMissingDocumentsSMS .edit_sms_content .sms_content .nbr_char_sms {
        width: 100%;
        padding: 0 calc(50% - 105mm);
        text-align: right;
        opacity: 0.5;
        font-size: 12px; }
    .dialogEditMissingDocumentsSMS .edit_sms_content .actions {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .dialogEditMissingDocumentsSMS .edit_sms_content .actions button {
        width: 110px;
        height: 30px;
        object-fit: contain;
        border-radius: 49.5px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3eb6ad;
        margin: 5px; }
        .dialogEditMissingDocumentsSMS .edit_sms_content .actions button.yes {
          box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
          color: #fff;
          border: none; }

.dialogEditExtraScolaireActivitiesSMS {
  width: 800px;
  height: 350px;
  border: solid 1px #979797 !important;
  padding: 30px; }
  .dialogEditExtraScolaireActivitiesSMS.add_length {
    height: 400px; }
  .dialogEditExtraScolaireActivitiesSMS.with_errors {
    height: 400px; }
  .dialogEditExtraScolaireActivitiesSMS.with_errors.add_length {
    height: 420px; }
  .dialogEditExtraScolaireActivitiesSMS h2 {
    text-align: center;
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 15px; }
  .dialogEditExtraScolaireActivitiesSMS .schoolClose {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    box-shadow: 0 10px 20px 0 rgba(90, 202, 195, 0.5);
    background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
    text-align: center;
    color: #fff;
    font-size: 40px;
    line-height: 40px;
    transform: translate(30%, -30%); }
  .dialogEditExtraScolaireActivitiesSMS .edit_sms_content {
    display: flex;
    justify-content: space-between; }
    .dialogEditExtraScolaireActivitiesSMS .edit_sms_content .sms_content {
      width: 80%;
      height: 230px; }
      .dialogEditExtraScolaireActivitiesSMS .edit_sms_content .sms_content textarea {
        width: 100%;
        height: 100%;
        border-radius: 2.6px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        font-size: 14px;
        font-weight: 400;
        color: #1b2437 !important; }
      .dialogEditExtraScolaireActivitiesSMS .edit_sms_content .sms_content .parents .radio_content {
        width: 100%;
        display: flex;
        margin: 0; }
        .dialogEditExtraScolaireActivitiesSMS .edit_sms_content .sms_content .parents .radio_content .opt {
          margin-left: 5px;
          width: 100%; }
        .dialogEditExtraScolaireActivitiesSMS .edit_sms_content .sms_content .parents .radio_content:first-of-type {
          margin-bottom: 7px; }
        .dialogEditExtraScolaireActivitiesSMS .edit_sms_content .sms_content .parents .radio_content .RadioLegend {
          margin: 0 !important; }
          .dialogEditExtraScolaireActivitiesSMS .edit_sms_content .sms_content .parents .radio_content .RadioLegend > span {
            padding: 0; }
          .dialogEditExtraScolaireActivitiesSMS .edit_sms_content .sms_content .parents .radio_content .RadioLegend legend {
            margin: 0; }
      .dialogEditExtraScolaireActivitiesSMS .edit_sms_content .sms_content .nbr_char_sms {
        width: 100%;
        padding: 0 calc(50% - 105mm);
        text-align: right;
        opacity: 0.5;
        font-size: 12px; }
    .dialogEditExtraScolaireActivitiesSMS .edit_sms_content .actions {
      display: flex;
      flex-direction: column;
      justify-content: center; }
      .dialogEditExtraScolaireActivitiesSMS .edit_sms_content .actions button {
        width: 110px;
        height: 30px;
        object-fit: contain;
        border-radius: 49.5px;
        border: solid 0.6px #d1dbe2;
        background-color: rgba(245, 248, 249, 0.3);
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #3eb6ad;
        margin: 5px; }
        .dialogEditExtraScolaireActivitiesSMS .edit_sms_content .actions button.yes {
          box-shadow: 0 6px 20px 0 rgba(90, 202, 195, 0.5);
          background-image: linear-gradient(54deg, #72dcd6, #3eb6ad);
          color: #fff;
          border: none; }

.students_missing_documents_list table tr td {
  word-break: break-all; }
  .students_missing_documents_list table tr td.sms {
    min-width: 100px !important;
    width: calc(16.6% - 4px) !important; }
    .students_missing_documents_list table tr td.sms img {
      width: 30px;
      height: 30px;
      cursor: pointer; }
    .students_missing_documents_list table tr td.sms.disabled img {
      opacity: 0.5;
      cursor: not-allowed; }
  .students_missing_documents_list table tr td.name {
    min-width: 300px !important;
    width: 25% !important; }
    .students_missing_documents_list table tr td.name img {
      border-radius: 50%;
      width: 150px;
      height: 150px; }
    .students_missing_documents_list table tr td.name > div {
      display: block !important; }
    .students_missing_documents_list table tr td.name h3 {
      color: black; }
  .students_missing_documents_list table tr td.cne {
    min-width: 150px !important;
    width: calc(16.6% - 4px) !important; }
  .students_missing_documents_list table tr td.level_classe {
    min-width: 170px !important;
    width: calc(16.6% - 4px) !important; }
  .students_missing_documents_list table tr td.docs {
    min-width: 200px !important;
    width: 25% !important; }
    .students_missing_documents_list table tr td.docs span {
      display: block;
      padding: 10px 5px;
      text-align: left; }

.students_missing_documents_list table .level_separator {
  background: gray; }

.payments_students.students_by_new_registration {
  height: calc(100% - 75px) !important; }

.students_by_reregistration_list {
  margin-left: 0 !important; }
  .students_by_reregistration_list table tr td {
    word-break: break-all; }
    .students_by_reregistration_list table tr td.name {
      min-width: 300px !important;
      width: 25% !important; }
      .students_by_reregistration_list table tr td.name img {
        border-radius: 50%;
        width: 150px;
        height: 150px; }
      .students_by_reregistration_list table tr td.name > div {
        display: block !important; }
      .students_by_reregistration_list table tr td.name h3 {
        color: black; }
    .students_by_reregistration_list table tr td.cne {
      min-width: 150px !important;
      width: calc(16.6% - 4px) !important; }
    .students_by_reregistration_list table tr td.tel_parents {
      min-width: 100px !important;
      width: calc(7% - 4px) !important; }
    .students_by_reregistration_list table tr td.level_classe {
      min-width: 170px !important;
      width: calc(16.6% - 4px) !important; }
    .students_by_reregistration_list table tr td.birthday {
      min-width: 130px !important;
      width: calc(16.6% - 4px) !important; }
    .students_by_reregistration_list table tr td.font_yellow h3 {
      color: #efc460; }
  .students_by_reregistration_list table .level_separator {
    background: gray; }
  .students_by_reregistration_list.students_by_new_registration_list {
    width: 100%;
    color: black !important; }
    .students_by_reregistration_list.students_by_new_registration_list table {
      width: calc(100% - 2px) !important; }
      .students_by_reregistration_list.students_by_new_registration_list table tr.spacer {
        height: 2px !important; }
        .students_by_reregistration_list.students_by_new_registration_list table tr.spacer td {
          padding: 1px !important;
          height: 2px !important; }
      .students_by_reregistration_list.students_by_new_registration_list table tr td {
        height: 35px !important;
        padding: 2px 5px !important; }
      .students_by_reregistration_list.students_by_new_registration_list table.to_print {
        margin-right: 2px; }
        .students_by_reregistration_list.students_by_new_registration_list table.to_print tr th {
          height: 28px !important;
          padding: 2px 5px !important;
          font-size: 15px !important;
          color: black !important; }
        .students_by_reregistration_list.students_by_new_registration_list table.to_print tr td {
          height: 28px !important;
          padding: 2px 5px !important;
          font-size: 15px !important;
          color: black !important; }

.custom_scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5; }

.custom_scroll::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5; }

.custom_scroll::-webkit-scrollbar-thumb {
  background-color: #3eb6ad; }

.custom_scroll.logo_green::-webkit-scrollbar-thumb {
  background-color: #8dcfd0; }

.disabled_style {
  opacity: 0.5; }

.dialogEditTemplate {
  height: max-content;
  border-left: 0;
  padding: 15px;
  min-width: 800px; }
  .dialogEditTemplate > div {
    height: 100%; }
    .dialogEditTemplate > div .publipostage_configuration {
      height: 100%; }
      .dialogEditTemplate > div .publipostage_configuration .configuration_content {
        height: calc(100% - 40px); }
        .dialogEditTemplate > div .publipostage_configuration .configuration_content > div {
          width: 100%;
          margin: auto;
          height: 100%; }
          .dialogEditTemplate > div .publipostage_configuration .configuration_content > div .name_edit {
            width: 100%; }
  .dialogEditTemplate.dialogEditInformationTemplate > div .publipostage_configuration .configuration_content .div_content {
    width: 100%; }
    .dialogEditTemplate.dialogEditInformationTemplate > div .publipostage_configuration .configuration_content .div_content > div {
      width: 100%; }

.print_btn {
  padding: 5px 10px;
  display: block;
  min-width: 90px;
  font-size: 10px;
  border: 1px solid #d1dbe2;
  color: white;
  box-shadow: 0 10px 20px 0 #5acac380;
  background-image: linear-gradient(225deg, #72dcd6, #3eb6ad);
  border: 0px solid #51c4bc;
  text-align: center;
  cursor: pointer;
  border-radius: 15px;
  margin: 0px 4px;
  width: max-content;
  height: max-content;
  margin-top: 5px;
  line-height: 14px;
  font-weight: bold; }

.student_reinscription_status {
  width: 100%; }
  .student_reinscription_status > div {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.13;
    color: #202726; }
  .student_reinscription_status.payment_modal {
    width: max-content;
    margin: auto; }
    .student_reinscription_status.payment_modal > div {
      padding: 8px 15px;
      font-size: 17px;
      border-radius: 5px; }
    .student_reinscription_status.payment_modal .not_reinscription_confirmed {
      color: white;
      background-color: red; }
    .student_reinscription_status.payment_modal .not_reinscription {
      color: white;
      background-color: red; }
    .student_reinscription_status.payment_modal .reinscription {
      color: #ffffff;
      background-color: #c45911; }
    .student_reinscription_status.payment_modal .reinscription_confirmed {
      color: #ffffff;
      background-color: #00b050; }

.dialogAddSurvey {
  min-width: 750px;
  width: 80%;
  height: 100%;
  background: #f5f6fa;
  background-color: #f5f6fa !important; }
  .dialogAddSurvey .schoolClose {
    z-index: 1;
    background: #8dcfd0;
    background-image: unset !important; }
  .dialogAddSurvey .add-school {
    height: 100%; }
  .dialogAddSurvey .add-school-form {
    height: 100%;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 5px; }
    .dialogAddSurvey .add-school-form button.add {
      background-image: linear-gradient(54deg, #8dcfd0, #8dcfd0) !important; }
    .dialogAddSurvey .add-school-form .school-ligne.school-ligne-margin {
      width: calc(100%) !important;
      margin-left: 0 !important;
      margin-right: 0 !important; }
    .dialogAddSurvey .add-school-form .survey_part {
      border-radius: 10px;
      border-left: 5px solid transparent;
      position: relative;
      background: white;
      padding: 15px 2px;
      padding-top: 20px; }
      .dialogAddSurvey .add-school-form .survey_part .survey_actions_list {
        display: none;
        position: absolute;
        right: 10px;
        top: 20px;
        width: 40px;
        padding: 5px;
        border-radius: 5px;
        background: #f0ebf8; }
        .dialogAddSurvey .add-school-form .survey_part .survey_actions_list > div {
          width: 30px;
          height: 30px;
          text-align: center;
          margin-bottom: 5px; }
          .dialogAddSurvey .add-school-form .survey_part .survey_actions_list > div span {
            display: block;
            border-radius: 50%;
            border: 2px solid gray;
            color: gray;
            padding: 3px;
            cursor: pointer;
            font-weight: bold;
            font-size: 21px;
            line-height: 20px; }
      .dialogAddSurvey .add-school-form .survey_part .survey_item_footer {
        display: none;
        justify-content: end;
        float: left;
        padding: 10px 15px;
        width: 100%; }
        .dialogAddSurvey .add-school-form .survey_part .survey_item_footer > div {
          margin-left: 10px; }
          .dialogAddSurvey .add-school-form .survey_part .survey_item_footer > div i {
            cursor: pointer;
            color: gray; }
      .dialogAddSurvey .add-school-form .survey_part.mode_edit.focused {
        border-left: 5px solid #efc15b;
        padding-right: 50px; }
        .dialogAddSurvey .add-school-form .survey_part.mode_edit.focused .survey_actions_list {
          display: block; }
        .dialogAddSurvey .add-school-form .survey_part.mode_edit.focused .survey_item_footer {
          display: flex; }
    .dialogAddSurvey .add-school-form .survey_users {
      border-radius: 10px;
      border-left: 5px solid transparent;
      position: relative;
      background: white;
      padding: 15px 2px;
      padding-top: 20px; }
      .dialogAddSurvey .add-school-form .survey_users .survey_target {
        float: left;
        width: 100%;
        padding: 0 12px;
        display: flex;
        flex-wrap: wrap; }
        .dialogAddSurvey .add-school-form .survey_users .survey_target > label {
          display: block;
          font-family: Montserrat;
          font-size: 15px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #1b2437;
          margin-bottom: 5px;
          width: 100%; }
        .dialogAddSurvey .add-school-form .survey_users .survey_target .target {
          display: inline-block;
          margin-right: 1em;
          white-space: nowrap;
          line-height: 1;
          height: auto;
          position: relative;
          background-color: #fff;
          overflow: hidden; }
          .dialogAddSurvey .add-school-form .survey_users .survey_target .target .state label::before, .dialogAddSurvey .add-school-form .survey_users .survey_target .target .state label::after {
            cursor: pointer;
            top: 0; }
        .dialogAddSurvey .add-school-form .survey_users .survey_target .select_survey_users {
          cursor: pointer;
          width: max-content;
          padding: 2px 10px;
          color: white;
          font-weight: bold;
          background-color: #8dcfd0;
          border-radius: 15px; }
    .dialogAddSurvey .add-school-form .survey_header {
      margin-top: 20px !important; }
      .dialogAddSurvey .add-school-form .survey_header:before {
        content: "";
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        position: absolute;
        border-top: 10px solid #8dcfd0;
        width: calc(100% + 5px);
        left: -5px;
        top: 0; }
      .dialogAddSurvey .add-school-form .survey_header .survey_name {
        margin-bottom: 7px; }
        .dialogAddSurvey .add-school-form .survey_header .survey_name input {
          font-size: 30px;
          font-weight: bold;
          height: inherit; }
        .dialogAddSurvey .add-school-form .survey_header .survey_name div.name {
          font-size: 30px;
          font-weight: bold; }
      .dialogAddSurvey .add-school-form .survey_header .survey_description {
        margin-bottom: 7px; }
        .dialogAddSurvey .add-school-form .survey_header .survey_description textarea {
          height: 40px; }
        .dialogAddSurvey .add-school-form .survey_header .survey_description div.description {
          margin-bottom: 7px; }
    .dialogAddSurvey .add-school-form .survey_item {
      margin-top: 20px !important;
      position: relative; }
      .dialogAddSurvey .add-school-form .survey_item .fa-arrows.item {
        display: none;
        position: absolute;
        left: 50%;
        cursor: pointer;
        top: 0%;
        transform: translate(-50%, -15%);
        line-height: 35px;
        font-size: 14px;
        color: #bdbdbd; }
      .dialogAddSurvey .add-school-form .survey_item .name_img .survey_item_name {
        margin-bottom: 7px; }
        .dialogAddSurvey .add-school-form .survey_item .name_img .survey_item_name input {
          font-size: 25px;
          font-weight: bold;
          height: inherit; }
        .dialogAddSurvey .add-school-form .survey_item .name_img .survey_item_name div.name {
          font-size: 25px;
          font-weight: bold;
          height: inherit; }
      .dialogAddSurvey .add-school-form .survey_item .name_img .survey_item_image_select {
        height: 100%;
        line-height: 40px;
        padding-right: 10px; }
        .dialogAddSurvey .add-school-form .survey_item .name_img .survey_item_image_select > i {
          cursor: pointer;
          color: gray;
          height: 100%;
          font-size: 20px; }
      .dialogAddSurvey .add-school-form .survey_item .survey_item_image {
        width: 100%;
        height: 300px;
        margin: 16px 0;
        display: flex; }
        .dialogAddSurvey .add-school-form .survey_item .survey_item_image.legend {
          margin-bottom: 50px; }
        .dialogAddSurvey .add-school-form .survey_item .survey_item_image > div {
          width: max-content;
          height: max-content;
          max-width: 100%;
          max-height: 100%;
          position: relative; }
          .dialogAddSurvey .add-school-form .survey_item .survey_item_image > div img {
            max-width: 100%;
            max-height: 300px;
            width: 100%; }
        .dialogAddSurvey .add-school-form .survey_item .survey_item_image .legend_image {
          position: absolute;
          top: 102%;
          left: 0;
          width: max-content;
          min-width: 100%; }
      .dialogAddSurvey .add-school-form .survey_item .survey_item_description {
        margin-bottom: 7px; }
        .dialogAddSurvey .add-school-form .survey_item .survey_item_description textarea {
          height: 40px; }
        .dialogAddSurvey .add-school-form .survey_item .survey_item_description div.description {
          height: 40px; }
        .dialogAddSurvey .add-school-form .survey_item .survey_item_description div.question {
          height: 40px; }
      .dialogAddSurvey .add-school-form .survey_item.question .name_img {
        display: flex; }
      .dialogAddSurvey .add-school-form .survey_item.paragraphe .name_img {
        display: flex; }
      .dialogAddSurvey .add-school-form .survey_item .survey_item_choices {
        display: flex;
        flex-direction: column; }
      .dialogAddSurvey .add-school-form .survey_item .survey_item_choice {
        display: flex;
        position: relative;
        flex-wrap: wrap; }
        .dialogAddSurvey .add-school-form .survey_item .survey_item_choice > div {
          display: flex;
          width: 100%; }
        .dialogAddSurvey .add-school-form .survey_item .survey_item_choice .fa-arrows {
          display: none;
          position: absolute;
          left: 0;
          cursor: pointer;
          top: 50%;
          transform: translate(-15%, -50%);
          height: 100%;
          line-height: 35px;
          font-size: 14px;
          color: #bdbdbd; }
        .dialogAddSurvey .add-school-form .survey_item .survey_item_choice .survey_item_choice_label {
          margin-bottom: 7px;
          padding: 0 !important; }
          .dialogAddSurvey .add-school-form .survey_item .survey_item_choice .survey_item_choice_label input {
            font-size: 20px;
            height: inherit; }
          .dialogAddSurvey .add-school-form .survey_item .survey_item_choice .survey_item_choice_label div.label {
            font-size: 20px;
            height: inherit; }
        .dialogAddSurvey .add-school-form .survey_item .survey_item_choice .survey_questions_type_icon {
          width: 20px;
          height: 100%;
          margin-right: 5px; }
          .dialogAddSurvey .add-school-form .survey_item .survey_item_choice .survey_questions_type_icon i {
            font-size: 20px;
            line-height: 35px;
            color: #bdbdbd; }
          .dialogAddSurvey .add-school-form .survey_item .survey_item_choice .survey_questions_type_icon.mode_view > div {
            top: -14px; }
        .dialogAddSurvey .add-school-form .survey_item .survey_item_choice .remove_survey_item_choice {
          width: 20px;
          height: 100%;
          margin-left: 5px; }
          .dialogAddSurvey .add-school-form .survey_item .survey_item_choice .remove_survey_item_choice i {
            font-size: 20px;
            line-height: 35px;
            color: #bdbdbd;
            cursor: pointer; }
        .dialogAddSurvey .add-school-form .survey_item .survey_item_choice .add_survey_item_choice_image {
          width: 20px;
          height: 100%;
          margin-left: 5px;
          margin-right: 5px;
          font-size: 20px;
          line-height: 35px;
          color: #bdbdbd;
          cursor: pointer; }
        .dialogAddSurvey .add-school-form .survey_item .survey_item_choice .survey_item_choice_image {
          width: 100%;
          height: 100px;
          margin: 16px 0;
          display: flex; }
          .dialogAddSurvey .add-school-form .survey_item .survey_item_choice .survey_item_choice_image > div {
            width: max-content;
            height: max-content;
            max-width: 100%;
            max-height: 100%;
            position: relative; }
            .dialogAddSurvey .add-school-form .survey_item .survey_item_choice .survey_item_choice_image > div img {
              max-width: 100%;
              max-height: 100px;
              width: 100%; }
            .dialogAddSurvey .add-school-form .survey_item .survey_item_choice .survey_item_choice_image > div .remove_survey_item_choice_image {
              position: absolute;
              right: 0;
              top: 0;
              transform: translate(50%, -50%);
              background: #d9d0d0bd;
              padding: 5px;
              border-radius: 50%;
              width: 24px;
              height: 24px;
              text-align: center;
              cursor: pointer; }
        .dialogAddSurvey .add-school-form .survey_item .survey_item_choice.add_options {
          flex-wrap: nowrap; }
          .dialogAddSurvey .add-school-form .survey_item .survey_item_choice.add_options .survey_item_choice_label {
            display: flex;
            justify-content: start;
            margin-bottom: 0;
            line-height: 30px; }
            .dialogAddSurvey .add-school-form .survey_item .survey_item_choice.add_options .survey_item_choice_label span {
              color: gray; }
              .dialogAddSurvey .add-school-form .survey_item .survey_item_choice.add_options .survey_item_choice_label span.add_option {
                color: #1a73e8;
                cursor: pointer;
                margin-right: 5px; }
              .dialogAddSurvey .add-school-form .survey_item .survey_item_choice.add_options .survey_item_choice_label span.add_other {
                color: #1a73e8;
                cursor: pointer;
                margin-left: 5px; }
      .dialogAddSurvey .add-school-form .survey_item.mode_edit.focused {
        padding-left: 8px; }
        .dialogAddSurvey .add-school-form .survey_item.mode_edit.focused .survey_item_choice {
          padding-left: 15px;
          margin-left: -15px; }
          .dialogAddSurvey .add-school-form .survey_item.mode_edit.focused .survey_item_choice:not(.add_options):hover .fa-arrows {
            display: block; }
      .dialogAddSurvey .add-school-form .survey_item:hover .fa-arrows.item {
        display: block; }
  .dialogAddSurvey .survey_item_choices_stats {
    position: relative; }
    .dialogAddSurvey .survey_item_choices_stats .survey_item_choices_stats_grahe {
      width: 250px;
      height: 250px;
      margin: auto; }
      .dialogAddSurvey .survey_item_choices_stats .survey_item_choices_stats_grahe .survey_item_choices_stats_grahe_key {
        position: absolute;
        right: 0;
        top: 0; }
        .dialogAddSurvey .survey_item_choices_stats .survey_item_choices_stats_grahe .survey_item_choices_stats_grahe_key > div {
          display: flex;
          width: max-content; }
          .dialogAddSurvey .survey_item_choices_stats .survey_item_choices_stats_grahe .survey_item_choices_stats_grahe_key > div span {
            width: 10px;
            height: 10px;
            margin: auto;
            margin-right: 5px; }
    .dialogAddSurvey .survey_item_choices_stats .survey_item_choices_stats_grahe_bars {
      width: 250px;
      height: 250px;
      margin: auto; }
      .dialogAddSurvey .survey_item_choices_stats .survey_item_choices_stats_grahe_bars .survey_item_choices_stats_grahe_key {
        position: absolute;
        right: 0;
        top: 0; }
        .dialogAddSurvey .survey_item_choices_stats .survey_item_choices_stats_grahe_bars .survey_item_choices_stats_grahe_key > div {
          display: flex;
          width: max-content; }
          .dialogAddSurvey .survey_item_choices_stats .survey_item_choices_stats_grahe_bars .survey_item_choices_stats_grahe_key > div span {
            width: 10px;
            height: 10px;
            margin: auto;
            margin-right: 5px; }
  .dialogAddSurvey .nbr_responses {
    font-weight: normal;
    font-size: 15px;
    margin-left: 5px; }
  .dialogAddSurvey .responses .response {
    padding-left: 10px;
    background: #f0ebf8b8;
    margin-left: 10px;
    padding-bottom: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 3px; }
  .dialogAddSurvey .pdf-container {
    display: flex;
    flex-direction: column; }
    .dialogAddSurvey .pdf-container .print-actions {
      justify-content: center; }
  @media (max-width: 767px) {
    .dialogAddSurvey {
      width: 100%;
      min-width: 100%;
      border-radius: 0; }
      .dialogAddSurvey .add-school-top .schoolClose {
        background: #8dcfd0;
        top: 0px;
        right: 0; }
      .dialogAddSurvey .add-school-form {
        margin-top: 0%; }
        .dialogAddSurvey .add-school-form .survey_header {
          margin-top: 0 !important; }
        .dialogAddSurvey .add-school-form button.add {
          height: 30px; }
        .dialogAddSurvey .add-school-form button.cancel {
          height: 30px; }
        .dialogAddSurvey .add-school-form .w100.legend {
          margin-bottom: 50px; } }

#menu-survey_item_choices_menu {
  z-index: 100000; }

#menu-sanadi_professors_gender_choices_menu {
  z-index: 100000; }

#menu-sanadi_professors_school_men_is_sanady_choices_menu {
  z-index: 100000; }

.fs_10 {
  padding-right: 15px !important; }

#menu-sanadi_professors_projects_choices_menu {
  z-index: 100000; }

#menu-sanadi_professors_programs_choices_menu {
  z-index: 100000; }

#menu-sanadi_professors_schools_choices_menu {
  z-index: 100000; }

#menu-sanadi_professors_cycles_choices_menu {
  z-index: 100000; }

#menu-sanadi_professors_levels_choices_menu {
  z-index: 100000; }

#menu-sanadi_professors_subjects_choices_menu {
  z-index: 100000; }

#menu-custom_subjects_select > div {
  padding: 5px; }

#menu-custom_subjects_select ul {
  max-height: 250px;
  overflow-y: auto;
  padding: 0px; }
  #menu-custom_subjects_select ul::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    background-color: #F5F5F5 !important; }
  #menu-custom_subjects_select ul::-webkit-scrollbar {
    width: 5px !important;
    height: 3px !important;
    background-color: #F5F5F5 !important; }
  #menu-custom_subjects_select ul::-webkit-scrollbar-thumb {
    background-color: #3eb6ad !important; }

#menu-custom_activities_classes_select > div {
  padding: 5px; }

#menu-custom_activities_classes_select ul {
  max-height: 250px;
  overflow-y: auto;
  padding: 0px; }
  #menu-custom_activities_classes_select ul::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
    background-color: #F5F5F5 !important; }
  #menu-custom_activities_classes_select ul::-webkit-scrollbar {
    width: 5px !important;
    height: 3px !important;
    background-color: #F5F5F5 !important; }
  #menu-custom_activities_classes_select ul::-webkit-scrollbar-thumb {
    background-color: #3eb6ad !important; }

#menu-select_school {
  z-index: 100001; }
  #menu-select_school > div {
    padding: 5px; }
  #menu-select_school ul {
    max-height: 250px;
    overflow-y: auto;
    padding: 0px; }
    #menu-select_school ul::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
      background-color: #F5F5F5 !important; }
    #menu-select_school ul::-webkit-scrollbar {
      width: 5px !important;
      height: 3px !important;
      background-color: #F5F5F5 !important; }
    #menu-select_school ul::-webkit-scrollbar-thumb {
      background-color: #3eb6ad !important; }

#menu-questions_type_menu {
  z-index: 100000; }

#menu-users_type {
  z-index: 1000000; }

#menu-levels_select {
  z-index: 1000000; }

#menu-search_schools {
  z-index: 1000000; }

#menu-semester_absence_file {
  z-index: 1000000; }

#menu-classes_select {
  z-index: 1000000; }

.short_text_icon {
  margin-top: -8px; }
  .short_text_icon:before {
    position: absolute;
    top: 50%; }

.modal_select_survey_users {
  z-index: 999999; }

.dialogSurveys {
  border-radius: 5%;
  background: rgba(100, 115, 140, 0.9);
  width: 100%;
  max-height: 100%;
  border-radius: 0; }
  .dialogSurveys .surveys_modal {
    width: 80%;
    padding: 35px 80px 70px;
    position: relative;
    max-width: inherit;
    min-height: 100%; }
    .dialogSurveys .surveys_modal .surveys .closeModal {
      right: 0;
      position: absolute;
      top: 0;
      transform: translate(50%, -50%);
      background: #80c9ca;
      padding: 5px;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      text-align: center;
      cursor: pointer; }
  @media screen and (max-width: 1199px) {
    .dialogSurveys .surveys_modal {
      top: 0;
      padding: 15px; }
      .dialogSurveys .surveys_modal .backModal img {
        width: 20px;
        margin-bottom: 5px; } }

.mp15 {
  margin-top: 15px;
  padding-left: 15px; }

.dialogAddVehiculeConsumption {
  max-width: inherit;
  width: 50%; }

.dialogAddVehiculeOilChange {
  max-width: inherit;
  width: 50%; }

.OverlayStaffFunction > div {
  height: 250px;
  top: 50% !important;
  transform: translateY(-50%); }

.general_appreciation_select__menu {
  height: max-content !important;
  width: max-content !important;
  min-height: max-content !important; }
  .general_appreciation_select__menu * {
    overflow: hidden !important; }

.dialogImportSeancesIssues {
  min-width: 800px; }

.activity_koolskools_item .desc {
  height: calc(100% - 25px); }

.custom_radios {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  width: 100% !important; }
  .custom_radios .radios {
    width: 100%; }
    .custom_radios .radios .RadioLegend {
      width: 100% !important; }
      .custom_radios .radios .RadioLegend > span {
        padding: 0; }
      .custom_radios .radios .RadioLegend legend {
        line-height: 24px;
        margin: 0 0 0 5px;
        font-size: 15px; }

.mw_mc {
  min-width: max-content !important; }

.ta_r {
  text-align: right; }
  .ta_r.w100 {
    width: 100%; }

.disabled_op {
  opacity: 0.5 !important; }

.react-datepicker__time-list-item.react-datepicker__time-list-item--disabled {
  opacity: 0.5; }

.pilotage_container .pilotage_sub_container {
  height: calc(100vh - 80px); }

.span_stats_color_container {
  float: right;
  width: max-content;
  padding-top: 2px;
  margin-left: 15px; }
  .span_stats_color_container .span_stats_color {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: block;
    margin-top: -3px; }

.eye_icon {
  font-size: 18px !important;
  color: #009688;
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 5px; }

#menu-type {
  z-index: 100300; }

.dialogSchoolSemPeriodsControlsNoted {
  height: max-content;
  max-height: 700px;
  min-height: 300px;
  max-width: 50%;
  width: 50%;
  min-width: 870px !important; }
  .dialogSchoolSemPeriodsControlsNoted .add-school {
    height: calc(100% - 57px); }

.dialogSchoolContacts {
  height: 100%;
  max-height: 600px;
  max-width: 38%;
  width: 38%; }
  .dialogSchoolContacts .add-school {
    height: calc(100% - 57px); }

.dialogSchoolVisits {
  height: 100%;
  max-height: 600px;
  max-width: 38%;
  width: 38%; }
  .dialogSchoolVisits .add-school {
    height: calc(100% - 57px); }

.zi_100 {
  z-index: 100 !important; }

.origin.zi_100 {
  width: max-content !important; }

.list_professors_tasks_list {
  padding: 0 15px;
  overflow: unset !important; }

.professor_seances_details {
  padding: 10px 50px; }
  .professor_seances_details > div {
    display: flex;
    justify-content: center; }
    .professor_seances_details > div.professor_seances > div {
      display: flex;
      justify-content: center;
      width: 50%; }
    .professor_seances_details > div.professor_subjects {
      display: flex;
      justify-content: center; }
  .professor_seances_details .label {
    font-weight: bold;
    margin-right: 2px; }
  .professor_seances_details .value {
    font-weight: bold;
    margin-left: 2px; }

.custom_modal.collaborators {
  z-index: 111050;
  background: #64738ce6; }

.custom_modal.supervisors {
  z-index: 111050;
  background: #64738ce6; }

.dialogSchoolsFilter {
  width: 80%;
  max-width: 80%; }

.users_select_type .rs__menu {
  z-index: 1; }

.rs_cities__menu {
  box-shadow: 0 5px 45px 0 rgba(153, 153, 153, 0.5);
  border: 0px;
  border-radius: 8px;
  padding: 5px;
  z-index: 100000;
  width: max-content;
  min-width: 100%; }
  .rs_cities__menu .rs__menu-list::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 5px; }
  .rs_cities__menu .rs__menu-list::-webkit-scrollbar {
    width: 10px;
    /*height: 3px;*/
    background-color: #F5F5F5;
    border-radius: 5px; }
  .rs_cities__menu .rs__menu-list::-webkit-scrollbar-thumb {
    background-color: #3eb6ad;
    border-radius: 5px; }
  .rs_cities__menu .rs__menu-list .rs__option {
    border-bottom: 1px dashed #ccc;
    cursor: pointer; }
  .rs_cities__menu .rs__menu-list .rs__group .rs__group-heading {
    padding: 8px 12px; }
    .rs_cities__menu .rs__menu-list .rs__group .rs__group-heading:hover {
      background-color: #86e6df4a;
      cursor: pointer; }
  .rs_cities__menu .rs__menu-list .rs__group .rs__option {
    padding-left: 25px;
    cursor: pointer; }

.students_details_cards {
  display: flex;
  flex-wrap: wrap; }
  .students_details_cards > div.std {
    height: 230px; }

.select {
  width: max-content;
  height: 24px;
  border-radius: 10px;
  border: solid 0.6px #d1dbe2;
  background-color: rgba(224, 227, 228, 0.3);
  position: relative;
  padding-left: 3px;
  margin: auto; }
  .select select {
    width: 100%;
    font-size: 12px;
    height: 22px;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-indent: 1px;
    text-overflow: "";
    background: transparent;
    outline: 0;
    position: relative;
    z-index: 9;
    border-radius: 4px !important;
    border: none !important;
    font-weight: 500;
    font-family: Montserrat;
    color: #1b2437;
    padding: 0 5px;
    cursor: pointer;
    padding-right: 20px; }
  .select i {
    color: #d2dbe2;
    font-size: 14px;
    position: absolute;
    right: 5px;
    top: 4px; }

.dialogGetProfInfo > div {
  height: max-content !important; }
  .dialogGetProfInfo > div .textErrors {
    color: red; }

.dialogExportViewerInsuranceAndDelegationList2 {
  margin-left: auto;
  margin-right: auto; }
  .dialogExportViewerInsuranceAndDelegationList2 .payments_students {
    height: auto; }

.payments_students {
  height: calc(100% - 166px); }
  .payments_students.effectifs_students_by_project, .payments_students.effectifs_students_by_school, .payments_students.effectifs_students_by_cycle, .payments_students.effectifs_students_by_level, .payments_students.effectifs_students_by_classe {
    height: calc(100% - 100px) !important; }
  .payments_students::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 5px;
    position: absolute;
    right: -50;
    /* Position the scrollbar on the right of the element*/ }
  .payments_students::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #F5F5F5;
    border-radius: 5px;
    position: absolute;
    right: -50;
    /* Position the scrollbar on the right of the element*/ }
  .payments_students::-webkit-scrollbar-thumb {
    background-color: #3eb6ad;
    border-radius: 5px;
    position: absolute;
    right: -50;
    /* Position the scrollbar on the right of the element*/ }
  .payments_students [data-test-id]::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
    border-radius: 5px;
    position: absolute;
    right: -50;
    /* Position the scrollbar on the right of the element*/ }
  .payments_students [data-test-id]::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #F5F5F5;
    border-radius: 5px;
    position: absolute;
    right: -50;
    /* Position the scrollbar on the right of the element*/ }
  .payments_students [data-test-id]::-webkit-scrollbar-thumb {
    background-color: #3eb6ad;
    border-radius: 5px;
    position: absolute;
    right: -50;
    /* Position the scrollbar on the right of the element*/ }
  .payments_students .students_list {
    height: 100%;
    width: 100%;
    background-color: #f5f6fa;
    margin: 18px 15px;
    border-radius: 16px;
    padding: 10px 5px;
    border-width: 1px;
    border-style: solid;
    border-color: #d1dbe2;
    border-image: initial; }
    .payments_students .students_list.students_list2 {
      flex: 1;
      width: max-content;
      min-width: 100%;
      margin: 0px;
      backdrop-filter: blur(8px);
      background-color: rgba(222, 230, 234, 0.64); }
      .payments_students .students_list.students_list2 table tr th.checkbox_col, .payments_students .students_list.students_list2 table tr td.checkbox_col {
        width: 10px; }
      .payments_students .students_list.students_list2 table tr.header td:not(.spacer_td), .payments_students .students_list.students_list2 table tr.header th {
        background-image: linear-gradient(to bottom, #cad3f2 3%, #b5c4e3 96%);
        font-size: 13px;
        font-weight: 600;
        line-height: 1.64;
        color: #202726;
        width: auto;
        white-space: nowrap; }
        .payments_students .students_list.students_list2 table tr.header td:not(.spacer_td):first-child, .payments_students .students_list.students_list2 table tr.header th:first-child {
          border-top-left-radius: 14px;
          border-bottom-left-radius: 14px; }
        .payments_students .students_list.students_list2 table tr.header td:not(.spacer_td):last-child, .payments_students .students_list.students_list2 table tr.header th:last-child {
          border-right-width: 1px;
          border-top-right-radius: 14px;
          border-bottom-right-radius: 14px; }
      .payments_students .students_list.students_list2 table tr.header.header2 td:not(.spacer_td), .payments_students .students_list.students_list2 table tr.header.header2 th {
        background-image: linear-gradient(to bottom, #cad3f2aa 3%, #b5c4e3aa 96%); }
      .payments_students .students_list.students_list2 table tr.header2 td {
        border-radius: 6px;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.45;
        color: #6e6e6e;
        height: 50px;
        padding: 10px 5px;
        background-color: white;
        border-style: solid;
        border-color: #e8e8e8;
        border-image: initial;
        border-width: 1px 0px 1px 1px;
        width: auto;
        white-space: nowrap; }
        .payments_students .students_list.students_list2 table tr.header2 td.header_level {
          background-image: linear-gradient(to bottom, #cad3f2 3%, #b5c4e3 96%);
          font-size: 11px;
          font-weight: 600;
          line-height: 1.64;
          color: #202726; }
          .payments_students .students_list.students_list2 table tr.header2 td.header_level:first-child {
            border-top-left-radius: 14px;
            border-bottom-left-radius: 14px; }
          .payments_students .students_list.students_list2 table tr.header2 td.header_level:last-child {
            border-right-width: 1px;
            border-top-right-radius: 14px;
            border-bottom-right-radius: 14px; }
      .payments_students .students_list.students_list2 table tr.header_s_t td:not(.spacer_td), .payments_students .students_list.students_list2 table tr.header_s_t th {
        height: 30px;
        padding: 5px; }
        .payments_students .students_list.students_list2 table tr.header_s_t td:not(.spacer_td):first-child, .payments_students .students_list.students_list2 table tr.header_s_t th:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px; }
        .payments_students .students_list.students_list2 table tr.header_s_t td:not(.spacer_td):last-child, .payments_students .students_list.students_list2 table tr.header_s_t th:last-child {
          border-right-width: 1px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px; }
      .payments_students .students_list.students_list2 table tr.student:not(.spacer) td:not(.spacer_td) {
        border-radius: 6px;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.45;
        color: #6e6e6e;
        width: auto;
        white-space: nowrap; }
      .payments_students .students_list.students_list2 table tr.is_child_of_staff td {
        background-color: #fbe1a4 !important; }
      .payments_students .students_list.students_list2 table tr.is_partner_group td {
        background-color: #f1cdcd !important; }
    .payments_students .students_list.students_list3 {
      flex: 1;
      width: max-content;
      min-width: 100%;
      margin: 0px;
      backdrop-filter: blur(8px);
      background-color: rgba(222, 230, 234, 0.64); }
      .payments_students .students_list.students_list3 table tr th.checkbox_col, .payments_students .students_list.students_list3 table tr td.checkbox_col {
        width: 10px; }
      .payments_students .students_list.students_list3 table tr.header td:not(.spacer_td), .payments_students .students_list.students_list3 table tr.header th {
        background-image: linear-gradient(to bottom, #cad3f2 3%, #b5c4e3 96%);
        font-size: 12px;
        font-weight: 600;
        line-height: 1.64;
        color: #000;
        width: auto;
        white-space: nowrap; }
        .payments_students .students_list.students_list3 table tr.header td:not(.spacer_td):first-child, .payments_students .students_list.students_list3 table tr.header th:first-child {
          border-top-left-radius: 14px;
          border-bottom-left-radius: 14px; }
        .payments_students .students_list.students_list3 table tr.header td:not(.spacer_td):last-child, .payments_students .students_list.students_list3 table tr.header th:last-child {
          border-right-width: 1px;
          border-top-right-radius: 14px;
          border-bottom-right-radius: 14px; }
      .payments_students .students_list.students_list3 table tr.header.header2 td:not(.spacer_td), .payments_students .students_list.students_list3 table tr.header.header2 th {
        background-image: linear-gradient(to bottom, #cad3f2aa 3%, #b5c4e3aa 96%); }
      .payments_students .students_list.students_list3 table tr.header2 td {
        border-radius: 6px;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.45;
        color: #000;
        height: 50px;
        padding: 10px 5px;
        background-color: white;
        border-style: solid;
        border-color: #e8e8e8;
        border-image: initial;
        border-width: 1px 0px 1px 1px;
        width: auto;
        white-space: nowrap; }
        .payments_students .students_list.students_list3 table tr.header2 td.header_level {
          background-image: linear-gradient(to bottom, #cad3f2 3%, #b5c4e3 96%);
          font-size: 13px;
          font-weight: 600;
          line-height: 1.64;
          color: #000; }
          .payments_students .students_list.students_list3 table tr.header2 td.header_level:first-child {
            border-top-left-radius: 14px;
            border-bottom-left-radius: 14px; }
          .payments_students .students_list.students_list3 table tr.header2 td.header_level:last-child {
            border-right-width: 1px;
            border-top-right-radius: 14px;
            border-bottom-right-radius: 14px; }
      .payments_students .students_list.students_list3 table tr.header_s_t td:not(.spacer_td), .payments_students .students_list.students_list3 table tr.header_s_t th {
        height: 30px;
        padding: 5px; }
        .payments_students .students_list.students_list3 table tr.header_s_t td:not(.spacer_td):first-child, .payments_students .students_list.students_list3 table tr.header_s_t th:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px; }
        .payments_students .students_list.students_list3 table tr.header_s_t td:not(.spacer_td):last-child, .payments_students .students_list.students_list3 table tr.header_s_t th:last-child {
          border-right-width: 1px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px; }
      .payments_students .students_list.students_list3 table tr.student:not(.spacer) td:not(.spacer_td) {
        border-radius: 6px;
        font-size: 12px;
        font-weight: 500;
        line-height: 1.45;
        color: #000;
        width: auto;
        white-space: nowrap; }
    .payments_students .students_list.students_tarl_notes table tr.header td:not(.spacer_td), .payments_students .students_list.students_tarl_notes table tr.header th {
      height: 30px;
      padding: 5px; }
      .payments_students .students_list.students_tarl_notes table tr.header td:not(.spacer_td):first-child, .payments_students .students_list.students_tarl_notes table tr.header th:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px; }
      .payments_students .students_list.students_tarl_notes table tr.header td:not(.spacer_td):last-child, .payments_students .students_list.students_tarl_notes table tr.header th:last-child {
        border-right-width: 1px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px; }
    .payments_students .students_list.cantine_garde {
      overflow: overlay !important; }
    .payments_students .students_list table {
      margin: auto;
      width: 100%;
      border-collapse: separate;
      border-spacing: 1px; }
      .payments_students .students_list table tr {
        text-align: center; }
        .payments_students .students_list table tr td:not(.spacer_td) {
          width: 6%;
          padding: 3px;
          font-size: 10px; }
          .payments_students .students_list table tr td:not(.spacer_td).large {
            width: 6.46%; }
          .payments_students .students_list table tr td:not(.spacer_td).name {
            width: calc(16% - 20px); }
        .payments_students .students_list table tr.header {
          font-weight: bold; }
          .payments_students .students_list table tr.header td:not(.spacer_td), .payments_students .students_list table tr.header th {
            padding-bottom: 20px; }
        .payments_students .students_list table tr.spacer {
          height: 10px; }
        .payments_students .students_list table tr.student:not(.spacer) td:not(.spacer_td), .payments_students .students_list table tr.student:not(.spacer) th, .payments_students .students_list table tr.header td:not(.spacer_td), .payments_students .students_list table tr.header th {
          background-color: white;
          height: 50px;
          border-style: solid;
          border-color: #e8e8e8;
          border-image: initial;
          border-width: 1px 0px 1px 1px;
          padding: 10px; }
          .payments_students .students_list table tr.student:not(.spacer) td:not(.spacer_td):first-child, .payments_students .students_list table tr.student:not(.spacer) th:first-child, .payments_students .students_list table tr.header td:not(.spacer_td):first-child, .payments_students .students_list table tr.header th:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px; }
          .payments_students .students_list table tr.student:not(.spacer) td:not(.spacer_td):last-child, .payments_students .students_list table tr.student:not(.spacer) th:last-child, .payments_students .students_list table tr.header td:not(.spacer_td):last-child, .payments_students .students_list table tr.header th:last-child {
            border-right-width: 1px;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px; }
          .payments_students .students_list table tr.student:not(.spacer) td:not(.spacer_td).gray, .payments_students .students_list table tr.student:not(.spacer) th.gray, .payments_students .students_list table tr.header td:not(.spacer_td).gray, .payments_students .students_list table tr.header th.gray {
            background: #F5F5F5; }
          .payments_students .students_list table tr.student:not(.spacer) td:not(.spacer_td).red, .payments_students .students_list table tr.student:not(.spacer) th.red, .payments_students .students_list table tr.header td:not(.spacer_td).red, .payments_students .students_list table tr.header th.red {
            background: #FF0000; }
          .payments_students .students_list table tr.student:not(.spacer) td:not(.spacer_td).greeen, .payments_students .students_list table tr.student:not(.spacer) th.greeen, .payments_students .students_list table tr.header td:not(.spacer_td).greeen, .payments_students .students_list table tr.header th.greeen {
            background: #00FF00; }
          .payments_students .students_list table tr.student:not(.spacer) td:not(.spacer_td).gray2, .payments_students .students_list table tr.student:not(.spacer) th.gray2, .payments_students .students_list table tr.header td:not(.spacer_td).gray2, .payments_students .students_list table tr.header th.gray2 {
            background: gray; }
          .payments_students .students_list table tr.student:not(.spacer) td:not(.spacer_td).p_em, .payments_students .students_list table tr.student:not(.spacer) th.p_em, .payments_students .students_list table tr.header td:not(.spacer_td).p_em, .payments_students .students_list table tr.header th.p_em {
            max-width: 100px;
            word-break: break-all; }
        .payments_students .students_list table tr td.spacer_td {
          width: 10px;
          border-left: 1px;
          border-left-style: solid;
          border-left-color: #e8e8e8; }
        .payments_students .students_list table tr td.name_ {
          background-color: #f5f6fa !important; }
        .payments_students .students_list table tr td.name.header_level {
          background-color: #e8e8e8 !important; }
      .payments_students .students_list table.mobile {
        margin: auto;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0px; }
        .payments_students .students_list table.mobile tr {
          text-align: center; }
          .payments_students .students_list table.mobile tr td:not(.spacer_td) {
            width: 25%; }
            .payments_students .students_list table.mobile tr td:not(.spacer_td).large {
              width: 25%; }
            .payments_students .students_list table.mobile tr td:not(.spacer_td).name {
              width: calc(50% - 10px); }
    .payments_students .students_list.no_scroll {
      overflow: unset; }
  .payments_students.viewer .students_list {
    height: 100%;
    width: 100%;
    background-color: #f5f6fa;
    margin: 18px 15px;
    border-radius: 8px;
    padding: 10px 5px;
    border-width: 1px;
    border-style: solid;
    border-color: #d1dbe2;
    border-image: initial; }
    .payments_students.viewer .students_list.students_list2 {
      flex: 1;
      width: max-content;
      min-width: 100%;
      margin: 0px;
      backdrop-filter: none;
      background-color: #FFF;
      border: 0px;
      border-radius: 0px;
      padding: 0px; }
      .payments_students.viewer .students_list.students_list2 .parent_body {
        border: 3px solid #000; }
      .payments_students.viewer .students_list.students_list2 table tr th.checkbox_col, .payments_students.viewer .students_list.students_list2 table tr td.checkbox_col {
        width: 10px; }
      .payments_students.viewer .students_list.students_list2 table tr.header td:not(.spacer_td), .payments_students.viewer .students_list.students_list2 table tr.header th {
        background-image: none;
        background-color: #b5c4e3;
        font-size: 12px;
        font-weight: 600;
        color: #000;
        width: auto;
        white-space: nowrap;
        border-color: #000;
        padding: 2px; }
        .payments_students.viewer .students_list.students_list2 table tr.header td:not(.spacer_td):first-child, .payments_students.viewer .students_list.students_list2 table tr.header th:first-child {
          border-radius: 0px;
          border-bottom-left-radius: 0px; }
        .payments_students.viewer .students_list.students_list2 table tr.header td:not(.spacer_td):last-child, .payments_students.viewer .students_list.students_list2 table tr.header th:last-child {
          border-right-width: 1px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px; }
      .payments_students.viewer .students_list.students_list2 table tr.header2 td {
        border-radius: 0px;
        font-size: 10px;
        font-weight: 500;
        color: #000;
        width: auto;
        height: auto;
        white-space: nowrap;
        border-color: #000;
        padding: 2px; }
        .payments_students.viewer .students_list.students_list2 table tr.header2 td.header_level {
          background-image: none;
          background-color: #b5c4e3;
          font-size: 12px;
          font-weight: 600;
          color: #000;
          width: auto;
          white-space: nowrap;
          border-color: #000;
          padding: 2px; }
          .payments_students.viewer .students_list.students_list2 table tr.header2 td.header_level:first-child {
            border-radius: 0px;
            border-bottom-left-radius: 0px; }
          .payments_students.viewer .students_list.students_list2 table tr.header2 td.header_level:last-child {
            border-right-width: 1px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px; }
      .payments_students.viewer .students_list.students_list2 table tr.student:not(.spacer) th {
        background-image: none;
        background-color: #b5c4e3;
        font-size: 12px;
        font-weight: 600;
        color: #000;
        width: auto;
        white-space: nowrap;
        border-color: #000;
        padding: 2px; }
        .payments_students.viewer .students_list.students_list2 table tr.student:not(.spacer) th:first-child {
          border-radius: 0px;
          border-bottom-left-radius: 0px; }
        .payments_students.viewer .students_list.students_list2 table tr.student:not(.spacer) th:last-child {
          border-right-width: 1px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px; }
      .payments_students.viewer .students_list.students_list2 table tr.student:not(.spacer) td:not(.spacer_td) {
        border-radius: 0px;
        font-size: 10px;
        font-weight: 500;
        color: #000;
        width: auto;
        white-space: nowrap;
        border-color: #000;
        padding: 2px; }
      .payments_students.viewer .students_list.students_list2 table tr.is_child_of_staff td {
        background-color: #fbe1a4 !important; }
      .payments_students.viewer .students_list.students_list2 table tr.is_partner_group td {
        background-color: #f1cdcd !important; }
    .payments_students.viewer .students_list.students_list4 .students_box {
      border: 1px solid #000; }
    .payments_students.viewer .students_list.students_list4 table {
      border: 0px !important;
      border-collapse: separate !important;
      border-spacing: 2px !important; }
      .payments_students.viewer .students_list.students_list4 table tr td, .payments_students.viewer .students_list.students_list4 table tr th {
        border: 0px !important; }
    .payments_students.viewer .students_list.students_list3 {
      flex: 1;
      width: max-content;
      min-width: 100%;
      margin: 0px;
      backdrop-filter: none;
      background-color: #FFF;
      border: 0px;
      border-radius: 0px;
      padding: 0px; }
      .payments_students.viewer .students_list.students_list3 table tr th.checkbox_col, .payments_students.viewer .students_list.students_list3 table tr td.checkbox_col {
        width: 10px; }
      .payments_students.viewer .students_list.students_list3 table tr.header td:not(.spacer_td), .payments_students.viewer .students_list.students_list3 table tr.header th {
        background-image: none;
        background-color: #b5c4e3;
        font-size: 16px;
        font-weight: 600;
        color: #000;
        width: auto;
        white-space: nowrap;
        border-color: #000;
        padding: 2px; }
        .payments_students.viewer .students_list.students_list3 table tr.header td:not(.spacer_td):first-child, .payments_students.viewer .students_list.students_list3 table tr.header th:first-child {
          border-radius: 0px;
          border-bottom-left-radius: 0px; }
        .payments_students.viewer .students_list.students_list3 table tr.header td:not(.spacer_td):last-child, .payments_students.viewer .students_list.students_list3 table tr.header th:last-child {
          border-right-width: 1px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px; }
        .payments_students.viewer .students_list.students_list3 table tr.header td:not(.spacer_td).black_back, .payments_students.viewer .students_list.students_list3 table tr.header th.black_back {
          background-color: #000;
          border-color: #FFF;
          color: #FFF;
          font-weight: bold; }
        .payments_students.viewer .students_list.students_list3 table tr.header td:not(.spacer_td).gray_back, .payments_students.viewer .students_list.students_list3 table tr.header th.gray_back {
          background-color: #D3D3D3;
          color: #FFF;
          font-weight: bold; }
      .payments_students.viewer .students_list.students_list3 table tr.header.blue_back td:not(.spacer_td), .payments_students.viewer .students_list.students_list3 table tr.header.blue_back th {
        background-color: #ADD8E6;
        color: #000;
        font-weight: bold; }
      .payments_students.viewer .students_list.students_list3 table tr.header2 td {
        border-radius: 0px;
        font-size: 16px;
        font-weight: 500;
        color: #000;
        width: auto;
        height: auto;
        white-space: nowrap;
        border-color: #000;
        padding: 2px; }
        .payments_students.viewer .students_list.students_list3 table tr.header2 td.header_level {
          background-image: none;
          background-color: #b5c4e3;
          font-size: 16px;
          font-weight: 600;
          color: #000;
          width: auto;
          white-space: nowrap;
          border-color: #000;
          padding: 2px; }
          .payments_students.viewer .students_list.students_list3 table tr.header2 td.header_level:first-child {
            border-radius: 0px;
            border-bottom-left-radius: 0px; }
          .payments_students.viewer .students_list.students_list3 table tr.header2 td.header_level:last-child {
            border-right-width: 1px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px; }
          .payments_students.viewer .students_list.students_list3 table tr.header2 td.header_level.gray_back {
            background-color: #D3D3D3;
            color: #000;
            font-weight: bold; }
      .payments_students.viewer .students_list.students_list3 table tr.student:not(.spacer) th {
        background-image: none;
        background-color: #b5c4e3;
        font-size: 16px;
        font-weight: 600;
        color: #000;
        width: auto;
        white-space: nowrap;
        border-color: #000;
        padding: 2px; }
        .payments_students.viewer .students_list.students_list3 table tr.student:not(.spacer) th:first-child {
          border-radius: 0px;
          border-bottom-left-radius: 0px; }
        .payments_students.viewer .students_list.students_list3 table tr.student:not(.spacer) th:last-child {
          border-right-width: 1px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px; }
      .payments_students.viewer .students_list.students_list3 table tr.student:not(.spacer) td:not(.spacer_td) {
        border-radius: 0px;
        font-size: 16px;
        font-weight: 500;
        color: #000;
        width: auto;
        white-space: nowrap;
        border-color: #000;
        padding: 2px; }
        .payments_students.viewer .students_list.students_list3 table tr.student:not(.spacer) td:not(.spacer_td):last-child {
          border-right-width: 1px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px; }
    .payments_students.viewer .students_list.students_tarl_notes {
      width: max-content;
      min-width: 100%;
      margin: 0px;
      backdrop-filter: none;
      background-color: #FFF;
      border: 0px;
      border-radius: 0px;
      padding: 0px; }
      .payments_students.viewer .students_list.students_tarl_notes table tr.header td:not(.spacer_td), .payments_students.viewer .students_list.students_tarl_notes table tr.header th {
        background-image: none;
        background-color: #b5c4e3;
        font-size: 13px;
        font-weight: 600;
        color: #000;
        width: auto;
        white-space: nowrap;
        border-color: #000;
        height: 30px; }
        .payments_students.viewer .students_list.students_tarl_notes table tr.header td:not(.spacer_td):first-child, .payments_students.viewer .students_list.students_tarl_notes table tr.header th:first-child {
          border-radius: 0px;
          border-bottom-left-radius: 0px; }
        .payments_students.viewer .students_list.students_tarl_notes table tr.header td:not(.spacer_td):last-child, .payments_students.viewer .students_list.students_tarl_notes table tr.header th:last-child {
          border-right-width: 0px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px; }
    .payments_students.viewer .students_list.cantine_garde {
      overflow: overlay !important; }
    .payments_students.viewer .students_list table {
      margin: auto;
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0px;
      border: 1px solid #000; }
      .payments_students.viewer .students_list table tr {
        text-align: center; }
        .payments_students.viewer .students_list table tr td:not(.spacer_td) {
          width: 6%;
          padding: 3px;
          font-size: 10px; }
          .payments_students.viewer .students_list table tr td:not(.spacer_td).large {
            width: 6.46%; }
          .payments_students.viewer .students_list table tr td:not(.spacer_td).name {
            width: calc(16% - 20px); }
        .payments_students.viewer .students_list table tr.header {
          font-weight: bold; }
          .payments_students.viewer .students_list table tr.header td:not(.spacer_td), .payments_students.viewer .students_list table tr.header th {
            padding-bottom: 20px; }
        .payments_students.viewer .students_list table tr.spacer {
          height: 10px; }
        .payments_students.viewer .students_list table tr.student:not(.spacer) td:not(.spacer_td), .payments_students.viewer .students_list table tr.student:not(.spacer) th, .payments_students.viewer .students_list table tr.header td:not(.spacer_td), .payments_students.viewer .students_list table tr.header th {
          background-color: white;
          height: auto;
          border-style: solid;
          border-color: #e8e8e8;
          border-image: initial;
          border-width: 1px 0px 1px 1px;
          padding: 10px; }
          .payments_students.viewer .students_list table tr.student:not(.spacer) td:not(.spacer_td):first-child, .payments_students.viewer .students_list table tr.student:not(.spacer) th:first-child, .payments_students.viewer .students_list table tr.header td:not(.spacer_td):first-child, .payments_students.viewer .students_list table tr.header th:first-child {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px; }
          .payments_students.viewer .students_list table tr.student:not(.spacer) td:not(.spacer_td):last-child, .payments_students.viewer .students_list table tr.student:not(.spacer) th:last-child, .payments_students.viewer .students_list table tr.header td:not(.spacer_td):last-child, .payments_students.viewer .students_list table tr.header th:last-child {
            border-right-width: 0px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px; }
          .payments_students.viewer .students_list table tr.student:not(.spacer) td:not(.spacer_td).gray, .payments_students.viewer .students_list table tr.student:not(.spacer) th.gray, .payments_students.viewer .students_list table tr.header td:not(.spacer_td).gray, .payments_students.viewer .students_list table tr.header th.gray {
            background: #F5F5F5; }
          .payments_students.viewer .students_list table tr.student:not(.spacer) td:not(.spacer_td).red, .payments_students.viewer .students_list table tr.student:not(.spacer) th.red, .payments_students.viewer .students_list table tr.header td:not(.spacer_td).red, .payments_students.viewer .students_list table tr.header th.red {
            background: #FF0000; }
          .payments_students.viewer .students_list table tr.student:not(.spacer) td:not(.spacer_td).greeen, .payments_students.viewer .students_list table tr.student:not(.spacer) th.greeen, .payments_students.viewer .students_list table tr.header td:not(.spacer_td).greeen, .payments_students.viewer .students_list table tr.header th.greeen {
            background: #00FF00; }
          .payments_students.viewer .students_list table tr.student:not(.spacer) td:not(.spacer_td).gray2, .payments_students.viewer .students_list table tr.student:not(.spacer) th.gray2, .payments_students.viewer .students_list table tr.header td:not(.spacer_td).gray2, .payments_students.viewer .students_list table tr.header th.gray2 {
            background: gray; }
          .payments_students.viewer .students_list table tr.student:not(.spacer) td:not(.spacer_td).p_em, .payments_students.viewer .students_list table tr.student:not(.spacer) th.p_em, .payments_students.viewer .students_list table tr.header td:not(.spacer_td).p_em, .payments_students.viewer .students_list table tr.header th.p_em {
            max-width: 100px;
            word-break: break-all; }
        .payments_students.viewer .students_list table tr td.spacer_td {
          width: 10px;
          border-left: 1px;
          border-left-style: solid;
          border-left-color: #e8e8e8; }
        .payments_students.viewer .students_list table tr td.name_ {
          background-color: #f5f6fa !important; }
        .payments_students.viewer .students_list table tr td.name.header_level {
          background-color: #e8e8e8 !important; }
      .payments_students.viewer .students_list table.mobile {
        margin: auto;
        width: 100%;
        border-collapse: separate;
        border-spacing: 0px; }
        .payments_students.viewer .students_list table.mobile tr {
          text-align: center; }
          .payments_students.viewer .students_list table.mobile tr td:not(.spacer_td) {
            width: 25%; }
            .payments_students.viewer .students_list table.mobile tr td:not(.spacer_td).large {
              width: 25%; }
            .payments_students.viewer .students_list table.mobile tr td:not(.spacer_td).name {
              width: calc(50% - 10px); }
    .payments_students.viewer .students_list.no_scroll {
      overflow: unset; }
  .payments_students .loading {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 15px; }
    .payments_students .loading .loading_img {
      position: relative;
      width: 100%;
      height: 100%; }
      .payments_students .loading .loading_img img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 50%;
        max-height: 50%; }

.custom_height .react-datepicker__day, .custom_height .react-datepicker__time-name {
  height: 1rem;
  line-height: 1rem; }

.custom_height .react-datepicker__year-read-view, .custom_height .react-datepicker__month-read-view, .custom_height .react-datepicker__month-year-read-view {
  width: 75px; }

.home_page_btn_connexion {
  background-size: 100% 100%;
  position: relative;
  width: 232px;
  width: 13vw;
  height: 73px;
  height: 4.1vw;
  margin: 38px 5px 48px 51px;
  margin: 0 5px 2.7vw 2.8vw;
  object-fit: contain;
  border-radius: 35.7px;
  cursor: pointer; }
  .home_page_btn_connexion img {
    position: absolute;
    left: -3px;
    top: -3px;
    width: 100%;
    height: 100%; }
  .home_page_btn_connexion span {
    position: absolute;
    width: max-content;
    height: 16px;
    font-family: QuicksandLight;
    font-size: 1.12vw;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #042a45;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -60%); }

.home_btn_connexion {
  color: #fff !important;
  background-color: #ffa827 !important;
  border-radius: 9px !important;
  box-shadow: unset !important;
  background-image: unset !important;
  height: 68px !important;
  height: 3vw !important;
  width: 300px !important;
  width: 16vw !important;
  font-family: QuicksandBook !important;
  padding: 0.4vw 2vw !important;
  font-size: 2vw !important;
  font-weight: normal !important;
  line-height: 2vw !important; }

.rs_cities__single-value {
  margin-left: 4px !important;
  padding-left: 2px !important; }

.current_scholar_year_title {
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px; }
